import { createSlice } from "@reduxjs/toolkit";

export const SessionStatus = {
  PENDING: "PENDING",
  READY: "READY",
  RENEWING: "RENEWING",
  PROVIDED: "PROVIDED",
} as const;

type SessionStatusKeys = keyof typeof SessionStatus;
export type SessionStatusType = (typeof SessionStatus)[SessionStatusKeys];

export interface SessionState {
  authenticated: boolean;
  status: SessionStatusType;
}

const initialState: SessionState = {
  authenticated: false,
  status: SessionStatus.PENDING,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    invalidate: (state) => {
      if (state.status === SessionStatus.READY && state.authenticated) {
        state.status = SessionStatus.RENEWING;
        state.authenticated = false;
      }
    },
    ready: (state, action) => {
      if (state.status !== SessionStatus.PROVIDED || !action) {
        state.status = SessionStatus.READY;
        state.authenticated = !!action.payload;
      }
    },
    provide: (state) => {
      state.status = SessionStatus.PROVIDED;
      state.authenticated = true;
    },
  },
});

export default sessionSlice;
