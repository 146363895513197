import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { PancoSelect, PancoSelectOption } from "cosmos-components";
import { propertyType, valuesetSource } from "cosmos-config/generator";
import _ from "lodash";
import React, { useMemo, useState, useContext } from "react";
import { Card, CardColumns } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SimpleFormGroup from "../../../../components/SimpleFormGroup";
import propertyTypeIconMap from "../../constants/propertyTypeIconMap";
import ProjectEditorPropertiesContext from "../../contexts/ProjectEditorPropertiesContext";
import {
  Property,
  SelectableAttributeProperty,
} from "cosmos-config/lib/property/property";

type PropertyTypeCardProps = {
  propertyName?: string;
  typeName: string;
  onClick?: (typeName: string) => void;
  active?: boolean;
};

const PropertyTypeCard = ({
  propertyName,
  typeName,
  onClick,
  active,
}: PropertyTypeCardProps) => {
  const { t } = useTranslation();
  const { propertyDefinitions } = useContext(ProjectEditorPropertiesContext);

  const propertyDefinition = useMemo(() => {
    return propertyName != null ? propertyDefinitions[propertyName] : null;
  }, [propertyDefinitions, propertyName]);

  const available = useMemo(() => {
    const tp = propertyDefinition?.type;

    if (tp == null) {
      return true;
    }

    switch (typeName) {
      case propertyType.DATE:
        return tp.dateType || tp.timestampType;
      case propertyType.CHECKBOX:
      case propertyType.YESNO:
        return tp.booleanType;
      case propertyType.NUMERIC:
        return tp.doubleType || tp.integerType || tp.longType;
      default:
        return tp.stringType;
    }
  }, [propertyDefinition, typeName]);

  return (
    <Card
      body
      onClick={() => {
        if (available && onClick != null) {
          onClick(typeName);
        }
      }}
      className={classNames({
        "text-primary": active,
        "border-primary": active,
        "text-muted": !available,
      })}
    >
      <FontAwesomeIcon icon={propertyTypeIconMap[typeName]} className="mr-2" />
      {t(`metadata.property_type_${String(typeName).toLowerCase()}`, {
        defaultValue: typeName,
      })}
    </Card>
  );
};

export interface PropertTypeSelectorProps {
  propertyName?: string;
  onChange?: (property: Partial<Property>) => void;
  className?: string;
  resource?: Partial<Property>;
}

const PropertyTypeSelector = ({
  propertyName,
  onChange,
  className,
  resource,
}: PropertTypeSelectorProps) => {
  const [typeValue, setTypeValue] = useState<Partial<Property>>({});
  const { t } = useTranslation();

  const handleValueUpdate = (propertyName: string, value: any) => {
    setTypeValue((t) => {
      const newValue = { ...t, [propertyName]: value };

      if (onChange != null) {
        onChange(newValue);
      }

      return newValue;
    });
  };

  const valuesetTypesOptions = useMemo(() => {
    return Object.values(valuesetSource).map((type) => ({
      label: t(`metadata.valueset_type_${type}`, { defaultValue: type }),
      value: type,
    }));
  }, [t]);

  return (
    <div className={classNames(className)}>
      <CardColumns>
        {Object.values(propertyType).map((typeName) => (
          <PropertyTypeCard
            propertyName={propertyName}
            typeName={typeName}
            active={resource?.type === typeName}
            onClick={() => handleValueUpdate("type", typeName)}
          />
        ))}
      </CardColumns>
      {typeValue?.type != null &&
        [propertyType.SELECT, propertyType.RADIO].includes(typeValue?.type) && (
          <SimpleFormGroup label="Valueset Source" inline>
            <PancoSelect
              options={valuesetTypesOptions}
              value={_.compact([
                (resource as SelectableAttributeProperty)?.valuesetSource,
              ])}
              onChange={({ value }) => {
                handleValueUpdate("valuesetSource", _.first(value));
              }}
            />
          </SimpleFormGroup>
        )}
    </div>
  );
};

export default PropertyTypeSelector;
