import Api from './conf/Api';

const api = Api.build();

const getTeamspaces = () => {
  return api
    .get('/collaboration/teamspaces')
    .then((response) => Promise.resolve(response.data));
};

const getTeamspace = (teamspaceId) => {
  return api
    .get(`/collaboration/teamspaces/id/${teamspaceId}`)
    .then((response) => Promise.resolve(response.data));
};

const getTeamspaceFolders = (docarea) => {
  return api
    .get(`/collaboration/teamspaces/docarea/${docarea}`)
    .then((response) => {
      const { data } = response;
      return Promise.resolve(data.resourceKeys || []);
    });
};

const postTeamSpace = (teamspace) => {
  return api
    .post('/collaboration/teamspaces', teamspace)
    .then((response) => Promise.resolve(response.data));
};

const putTeamspace = (principalId, teamspace) => {
  return api
    .put(`/collaboration/teamspaces/id/${principalId}`, teamspace)
    .then((response) => Promise.resolve(response.data));
};

const deleteTeamspace = (principalId) => {
  return api
    .delete(`/collaboration/teamspaces/id/${principalId}`)
    .then((response) => Promise.resolve(response.data));
};

const postGroupAdmin = (groupPrincipalId, principalIds) => {
  return api
    .post(`/collaboration/teamspaces/id/${groupPrincipalId}/groupadmin`, {
      elements: principalIds,
    })
    .then((response) => Promise.resolve(response.data));
};

const deleteGroupAdmin = (groupPrincipalId, principalIds) => {
  return api
    .delete(`/collaboration/teamspaces/id/${groupPrincipalId}/groupadmin`, {
      data: {
        elements: principalIds,
      },
    })
    .then((response) => Promise.resolve(response.data));
};

export default {
  getTeamspaces,
  getTeamspace,
  getTeamspaceFolders,
  postTeamSpace,
  putTeamspace,
  deleteTeamspace,
  postGroupAdmin,
  deleteGroupAdmin,
};
