import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Collapse, Alert } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { AccessManagement } from "../../../modules/access-management";
import { CosmosFormGroupProps, MayUpdateResource } from "./CosmosFormGroup";

export interface PermissionsFormGroupProps
  extends CosmosFormGroupProps,
    MayUpdateResource {}

const PermissionsFormGroup = ({
  className,
  group,
  defaultCollapse,
  resource,
  onUpdateResource,
}: PermissionsFormGroupProps) => {
  const [collapsed, setCollapsed] = useState(defaultCollapse);

  const { t } = useTranslation("property");

  return (
    <Card
      border="dark"
      className={classNames(className)}
      key={`group-${group?.name}`}
    >
      <Card.Header
        onClick={() => setCollapsed(!collapsed)}
        style={{ cursor: "pointer" }}
      >
        {group?.translation != null ? t(group.translation) : group?.label}
      </Card.Header>
      <Collapse in={!collapsed}>
        <Card.Body>
          <Alert variant="info" className="mb-0">
            <p>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                size="2x"
                className="float-left mr-3 mt-2"
              />
              {t("repository.grant_permissions_select_members_label", {
                defaultValue:
                  "Please select those project members whom you want to grant access to this document. Please finalize this selection by clicking on 'Grant Permission'. These persons will appear in the table 'Granted Permissions'. With the 'Edit' function in the context-menu (right-mouse click) you can set the individual permissions per individual person.",
              })}
            </p>
          </Alert>

          <AccessManagement
            resource={resource}
            onChange={(propertyName, value) => {
              if (onUpdateResource != null) {
                onUpdateResource("", propertyName, value);
              }
            }}
          />
        </Card.Body>
      </Collapse>
    </Card>
  );
};

PermissionsFormGroup.defaultProps = {
  defaultCollapse: true,
};

export default PermissionsFormGroup;
