import { AccessRequest } from "../types/accessRequest";
import CacheApi from "./conf/CacheApi";
import GatewayApi from "./conf/GatewayApi";

const BASE_URL = process.env.REACT_APP_GATEWAY_URL;

const gatewayApi = GatewayApi.build();
const cacheApi = CacheApi.build(BASE_URL);

const getUsersCacheKey = (projectCode: string) =>
  `PROJECT_USERS:${projectCode}`;

const getProjects = (docarea: string) => {
  return cacheApi
    .get(
      `/docarea/${docarea}/projects`,
      CacheApi.options({}, false, "PROJECTS")
    )
    .then((response) => Promise.resolve(response.data));
};

const getUsers = (projectCode: string) => {
  return cacheApi
    .get(
      `/project/${projectCode}/users`,
      CacheApi.options(
        {
          params: {
            subtree: true,
          },
        },
        false,
        getUsersCacheKey(projectCode)
      )
    )
    .then((response) => Promise.resolve(response.data));
};

const createProject = (docarea: string, project: any) => {
  return gatewayApi
    .post(`/docarea/${docarea}/project`, project)
    .then((response) => Promise.resolve(response.data));
};

const getProjectProperties = (projectCode: string) => {
  return gatewayApi
    .get(`/project/${projectCode}/properties`)
    .then((response) => Promise.resolve(response.data));
};

const createProperty = (sectorCode: string, property: any) => {
  return gatewayApi
    .post(`/sector/${sectorCode}/property/`, property)
    .then((response) => Promise.resolve(response.data));
};

const createChildProperty = (
  sectorCode: string,
  parentId: string,
  property: any
) => {
  return gatewayApi
    .post(`/sector/${sectorCode}/property/${parentId}/child`, property)
    .then((response) => Promise.resolve(response.data));
};

const updateProperty = (
  sectorCode: string,
  propertyId: string,
  property: any
) => {
  return gatewayApi
    .put(`/sector/${sectorCode}/property/${propertyId}`, property)
    .then((response) => Promise.resolve(response.data));
};

const deleteProperty = (sectorCode: string, propertyId: string) => {
  return gatewayApi
    .delete(`/sector/${sectorCode}/property/${propertyId}`)
    .then((response) => Promise.resolve(response.data));
};

const requestProjectAccess = (
  projectCode: string,
  accessRequest: AccessRequest
) => {
  return gatewayApi
    .post(`/project/${projectCode}/request-access`, accessRequest)
    .then((response) => Promise.resolve(response.data));
};

const getProjectAccessRequests = (projectCode: string) => {
  return gatewayApi
    .get(`/project/${projectCode}/access-requests`)
    .then((response) => Promise.resolve(response.data));
};

const deleteProjectAccessRequest = (
  projectCode: string,
  principalId: string
) => {
  return gatewayApi
    .delete(`/project/${projectCode}/access-request/${principalId}`)
    .then((response) => Promise.resolve(response.data));
};

const addProjectMember = (
  projectCode: string,
  groupName: string,
  principalId: string
) => {
  return cacheApi
    .post(`/project/${projectCode}/groups/${groupName}/members`, {
      principalId,
    })
    .then((response) => {
      CacheApi.removeItem(getUsersCacheKey(projectCode));
      return Promise.resolve(response.data);
    });
};

const addProjectMembers = (
  projectCode: string,
  groupName: string,
  principalIds: string[]
) => {
  return cacheApi
    .post(
      `/project/${projectCode}/groups/${groupName}/members/batch`,
      principalIds.map((principalId) => ({ principalId }))
    )
    .then((response) => {
      CacheApi.removeItem(getUsersCacheKey(projectCode));
      return Promise.resolve(response.data);
    });
};

const createProjectSubGroup = (
  projectCode: string,
  domainName: string,
  groupName: string,
  group: any
) => {
  return cacheApi
    .post(
      `/project/${projectCode}/domain/${domainName}/groups/${groupName}/groups`,
      group
    )
    .then((response) => {
      CacheApi.removeItem(getUsersCacheKey(projectCode));
      return Promise.resolve(response.data);
    });
};

const updateProjectSubGroup = (
  projectCode: string,
  domainName: string,
  groupName: string,
  group: any
) => {
  return cacheApi
    .put(
      `/project/${projectCode}/domain/${domainName}/groups/${groupName}`,
      group
    )
    .then((response) => {
      CacheApi.removeItem(getUsersCacheKey(projectCode));
      return Promise.resolve(response.data);
    });
};

const deleteProjectSubGroup = (
  projectCode: string,
  domainName: string,
  groupName: string
) => {
  return cacheApi
    .delete(`/project/${projectCode}/domain/${domainName}/groups/${groupName}`)
    .then((response) => {
      CacheApi.removeItem(getUsersCacheKey(projectCode));
      return Promise.resolve(response.data);
    });
};

const removeProjectMember = (
  projectCode: string,
  groupName: string,
  principalId: string
) => {
  return cacheApi
    .delete(
      `/project/${projectCode}/groups/${groupName}/members/${principalId}`
    )
    .then((response) => {
      CacheApi.removeItem(getUsersCacheKey(projectCode));
      return Promise.resolve(response.data);
    });
};

const getProjectSpocs = (projectCode: string) => {
  return gatewayApi
    .get(`/project/${projectCode}/spocs`)
    .then((response) => Promise.resolve(response.data));
};

const createProjectSpoc = (projectCode: string, spoc: any) => {
  return gatewayApi
    .post(`/project/${projectCode}/spoc`, spoc)
    .then((response) => Promise.resolve(response.data));
};

const createProjectSpocRequest = (projectCode: string, spoc: any) => {
  return gatewayApi
    .post(`/project/${projectCode}/spoc/request`, spoc)
    .then((response) => Promise.resolve(response.data));
};

const updateProjectSpoc = (projectCode: string, spocId: string, spoc: any) => {
  return gatewayApi
    .put(`/project/${projectCode}/spoc/${spocId}`, spoc)
    .then((response) => Promise.resolve(response.data));
};

const toggleActiveProjectSpoc = (
  projectCode: string,
  departmentSpocId: string
) => {
  return gatewayApi
    .put(`/project/${projectCode}/spoc/${departmentSpocId}/toggle-active`)
    .then((response) => Promise.resolve(response.data));
};

const deleteProjectSpoc = (projectCode: string, departmentSpocId: string) => {
  return gatewayApi
    .delete(`/project/${projectCode}/spoc/${departmentSpocId}`)
    .then((response) => Promise.resolve(response.data));
};

const getProjectMessages = (projectCode: string) => {
  return gatewayApi
    .get(`/project/${projectCode}/messages`)
    .then((response) => Promise.resolve(response.data));
};

const createProjectMessage = (projectCode: string, message: any) => {
  return gatewayApi
    .post(`/project/${projectCode}/message`, message)
    .then((response) => Promise.resolve(response.data));
};

const updateProjectMessage = (
  projectCode: string,
  messageId: string,
  message: any
) => {
  return gatewayApi
    .put(`/project/${projectCode}/message/${messageId}`, message)
    .then((response) => Promise.resolve(response.data));
};

const deleteProjectMessage = (projectCode: string, messageId: string) => {
  return gatewayApi
    .delete(`/project/${projectCode}/message/${messageId}`)
    .then((response) => Promise.resolve(response.data));
};

const createContactMessage = (projectCode: string, message: any) => {
  return gatewayApi
    .post(`/project/${projectCode}/contact`, message)
    .then((response) => Promise.resolve(response.data));
};

const getWidgets = (projectCode: string) => {
  return gatewayApi
    .get(`/project/${projectCode}/widgets`)
    .then((response) => Promise.resolve(response.data));
};

const saveAllWidgets = (projectCode: string, widgets: any[]) => {
  return gatewayApi
    .post(`/project/${projectCode}/widgets`, widgets)
    .then((response) => Promise.resolve(response.data));
};

const removeAllWidgets = (projectCode: string) => {
  return gatewayApi
    .delete(`/project/${projectCode}/widgets`)
    .then((response) => Promise.resolve(response.data));
};

const getQueries = (projectCode: string) => {
  return gatewayApi
    .get(`/project/${projectCode}/queries`)
    .then((response) => Promise.resolve(response.data));
};

const createRepositoryQuery = (projectCode: string, query: string) => {
  return gatewayApi
    .post(`/project/${projectCode}/query`, query)
    .then((response) => Promise.resolve(response.data));
};

const inviteUser = (
  projectCode: string,
  domainName: string,
  groupName: string,
  email: string
) => {
  return gatewayApi
    .post(
      `/project/${projectCode}/domain/${domainName}/groups/${groupName}/invite`,
      { email }
    )
    .then((response) => {
      CacheApi.removeItem(getUsersCacheKey(projectCode));
      return Promise.resolve(response.data);
    });
};

const createUser = (
  projectCode: string,
  domainName: string,
  groupName: string,
  user: any
) => {
  return gatewayApi
    .post(
      `/project/${projectCode}/domain/${domainName}/groups/${groupName}/users`,
      user
    )
    .then((response) => Promise.resolve(response.data));
};

const getUserTasks = (projectCode: string) => {
  return gatewayApi
    .get(`/project/${projectCode}/tasks`)
    .then((response) => Promise.resolve(response.data));
};

const getProjectSubItems = (projectCode: string) => {
  return gatewayApi
    .get(`/project/${projectCode}/sub-items`)
    .then((response) => Promise.resolve(response.data));
};

const projectApi = {
  getProjects,
  getUsers,

  createProject,

  getProjectProperties,
  createChildProperty,
  createProperty,
  updateProperty,
  deleteProperty,

  requestProjectAccess,
  getProjectAccessRequests,
  deleteProjectAccessRequest,

  addProjectMember,
  addProjectMembers,
  createProjectSubGroup,
  updateProjectSubGroup,
  deleteProjectSubGroup,
  removeProjectMember,

  getProjectSpocs,
  createProjectSpoc,
  createProjectSpocRequest,
  updateProjectSpoc,
  toggleActiveProjectSpoc,
  deleteProjectSpoc,

  getProjectMessages,
  createProjectMessage,
  updateProjectMessage,
  deleteProjectMessage,
  createContactMessage,

  getWidgets,
  saveAllWidgets,
  removeAllWidgets,

  getQueries,
  createRepositoryQuery,

  inviteUser,
  createUser,
  getUserTasks,

  getProjectSubItems,
};

export default projectApi;
