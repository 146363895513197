import { useState, useEffect, useCallback, useMemo } from "react";
import useColumns from "../hooks/useColumns";
import useRepositorySearch from "../hooks/useRepositorySearch";
import _ from "lodash";
import Resource from "../types/resource";
import { PancoPaginationProps } from "cosmos-components";
import { RepositoryTableProps } from "./repository-table/RepositoryTable";
import { useProperties } from "../hooks";

type ResourceType = 1 | 2 | 3;

const resolveResourceType = (resourceTypes: ResourceType[]) => {
  if (
    resourceTypes.includes(2) &&
    resourceTypes.includes(3) &&
    resourceTypes.length === 2
  ) {
    return 2;
  }

  if (resourceTypes.length === 1) {
    return resourceTypes[0];
  }

  return null;
};

export interface UseRepositoryTableProps {
  resourceId: string;
  filter?: Record<string, any>;
  searchQuery?: string;
  subtree?: boolean;
  onSelect?: (resources: Resource[]) => void;
  pageSize?: number;
  fulltextSearch?: boolean;
}

const useRepositoryTable = ({
  resourceId,
  filter,
  subtree,
  searchQuery,
  onSelect,
  pageSize,
  fulltextSearch,
}: UseRepositoryTableProps) => {
  const { defaultColumnOrder, documentTableColumnOrder } = useColumns();
  const { tableProperties, properties } = useProperties();
  const [selectedResources, setSelectedResources] = useState<Resource[]>([]);
  const [orderBy, setOrderBy] = useState({});
  const [paginator, setPaginator] = useState<{
    number: number;
    size: number;
  }>({
    number: 0,
    size: pageSize || 10,
  });
  const [resourceType, setResourceType] = useState<ResourceType | null>(null);

  const handleSelect = useCallback((resources: Resource[]) => {
    setSelectedResources((currentSelection) => {
      if (currentSelection.length !== resources.length) {
        return resources;
      }

      const idsOf = (rs: Resource[]) => rs.map((r) => r.id).sort();

      if (_.isEqual(idsOf(resources), idsOf(currentSelection))) {
        return currentSelection;
      }

      return resources;
    });
  }, []);

  useEffect(() => {
    if (onSelect != null) {
      onSelect(selectedResources);
    }
  }, [onSelect, selectedResources]);

  useEffect(() => {
    setPaginator((p) => ({ ...p, number: 0 }));
  }, [resourceId]);

  const { data, loading, count, refetch } = useRepositorySearch({
    resourceId,
    orderBy,
    filter,
    paginator,
    substructure: true,
    subtree,
    searchQuery,
    fulltextSearch,
  });

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      setResourceType((prevCt) => {
        const resourceTypes = _(data).map("resourcetype").uniq().value();
        const ct = resolveResourceType(resourceTypes);
        return (ct !== prevCt ? ct : prevCt) || null;
      });
    }
  }, [data]);

  const columnPropertiesProps = useMemo(() => {
    switch (resourceType) {
      case 2:
      case 3:
        return {
          properties: properties,
          columnOrder: documentTableColumnOrder,
        };
      case 1:
      default:
        return {
          properties: tableProperties,
          columnOrder: defaultColumnOrder,
        };
    }
  }, [
    defaultColumnOrder,
    documentTableColumnOrder,
    tableProperties,
    properties,
    resourceType,
  ]);

  const gotoPage = useCallback((page: number) => {
    setPaginator((p) => ({
      ...p,
      number: page,
    }));
  }, []);

  return {
    getTableProps: useCallback(
      (
        props?: Partial<RepositoryTableProps>
      ): Partial<RepositoryTableProps> => {
        const propertiesProps = _.omitBy(
          _.pick(props, "properties", "columnOrder"),
          _.isNil
        );

        return {
          ...(props || {}),
          data,
          setOrderBy,
          orderBy,
          onSelect: handleSelect,
          ...columnPropertiesProps,
          ...propertiesProps,
        };
      },
      [data, columnPropertiesProps, handleSelect, orderBy]
    ),
    getPaginatorProps: useCallback(
      (props?: Partial<PancoPaginationProps>): PancoPaginationProps => {
        return {
          ...(props || {}),
          pageIndex: paginator?.number,
          pageCount: Math.ceil(count / paginator?.size),
          gotoPage,
        };
      },
      [count, gotoPage, paginator]
    ),
    loading,
    refetch,
  };
};

export default useRepositoryTable;
