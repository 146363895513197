import createSagaMiddleware from "redux-saga";
import docareaApi from "../apis/docareaApi";
import projectApi from "../apis/projectApi";
import {
  configureStore,
  createSerializableStateInvariantMiddleware,
  combineReducers,
  Middleware,
} from "@reduxjs/toolkit";
import projectSlice from "../slices/projectSlice";
import docareaSlice from "../slices/docareaSlice";
import rootSaga from "../sagas/rootSaga";
import projectService from "../services/projectService";
import userManagementSlice from "../slices/userManagementSlice";
import domainSlice from "../slices/domainSlice";
import sessionSlice from "../slices/sessionSlice";

import * as projectActionsCreators from "../actions/projectActions";

const sagaMiddleware = createSagaMiddleware({
  context: {
    docareaApi,
    projectApi,
    projectService,
  },
});

const sagaCompleteMiddleware: Middleware = () => (next) => (action) => {
  return new Promise((resolve) => {
    next({
      //@ts-ignore
      ...action,
      onComplete: (arg: any) => resolve(arg),
    });
  });
};

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  ignoredActionPaths: ["onComplete"],
});

const rootReducer = combineReducers({
  docarea: docareaSlice.reducer,
  project: projectSlice.reducer,
  usermanagement: userManagementSlice.reducer,
  domain: domainSlice.reducer,
  session: sessionSlice.reducer,
});

export type CosmosCoreRootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  devTools: {
    name: "Cosmos Core",
    actionCreators: projectActionsCreators,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false })
      .concat(sagaCompleteMiddleware)
      .concat(sagaMiddleware)
      .concat(serializableMiddleware),
  preloadedState: {},
});

sagaMiddleware.run(rootSaga);

export default store;
