import React, { createElement as h, useMemo, useCallback } from "react";
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  PancoInput,
  DatePicker,
  PancoTooltip,
  PancoArea,
  PancoSelect,
  RadioSelect,
  NumericInput,
  PancoSwitch,
  HelperTooltip,
  SuggestiveInput,
  PancoRichTextArea,
  Wrap,
  InputValueHandler,
  OnChangeParam,
  FormControlProps,
  useManualFilterFuzzy,
} from "cosmos-components";
import { propertyType as PropertyType } from "cosmos-config/generator";
import classNames from "classnames";
import useValueset from "../../hooks/useValueset";
import useProject from "../../hooks/useProject";
import { ValuesetItem } from "../../contants/valuesetItem";
import PrincipalSelect from "./PrincipalSelect";
import MemberSelect from "./MemberSelect";
import ModuleElementSelect from "./ModuleElementSelect";
import ResourcePicker from "./ResourcePicker";
import usePropertyOptions from "../../hooks/usePropertyOptions";
import type {
  Property,
  SelectableAttributeProperty,
  PrincipalAttributeProperty,
  NumericAttributeProperty,
  DateAttributeProperty,
} from "cosmos-config/lib/property/property";
import type { ConditionalProperty } from "cosmos-config/lib/property/conditionalProperty";
import {
  BaseProps,
  getDateProps,
  getDependencyValue,
  getElementSelectProps,
  getMemberSelectProps,
  getNumericProps,
  getPrincipalSelectProps,
} from "./elementPropsHelper";
import { PancoSelectOption } from "cosmos-components";
import styled from "styled-components";
import { QuickReminder } from "../../modules/reminder-management";
import Resource from "../../types/resource";
import { OnOpenResourceCallback } from "../../types/callbacks";
import QuickUpload from "../resource/QuickUpload";
import { QuickAccessManagement } from "../../modules/access-management";
import useFormComponentProps from "./useFormComponentProps";

type StyledRowProps = {
  highlighted?: boolean;
};

const StyledRow = styled(Row)<StyledRowProps>`
  background-color: ${(props) => (props.highlighted ? "#ffecb4" : "initial")};
`;

interface FormPropertyProps extends InputValueHandler<any>, FormControlProps {
  property: Property;
  filter?: Record<string, any>;
  forced?: boolean;
  controlOnly?: boolean;
  className?: string;
  id?: string;
  inline?: boolean;
  valid?: boolean;
  validationMessage?: string;
  validation?: boolean;
  highlighted?: boolean;
  wide?: boolean;
  onOpenResource?: OnOpenResourceCallback;
  renderAction?: (property: Property) => React.ReactNode;
}

const FormProperty = ({
  property,
  value,
  onChange,
  filter,
  forced,
  controlOnly,
  className,
  disabled,
  id,
  inline,
  valid,
  validationMessage,
  validation,
  highlighted,
  wide,
  onOpenResource,
  renderAction,
  ...props
}: FormPropertyProps) => {
  const { t } = useTranslation("property");
  const { addValuesetItem } = useValueset();
  const { addProjectMember } = useProject();
  const { getProps } = useFormComponentProps({ property });

  const { options, addOption } = usePropertyOptions({
    property: property as SelectableAttributeProperty,
    filter,
    contextResourceId: filter?.parentresourceid,
    includeObsolete: true,
  });

  const {
    filteredOptions: fuzzyFilteredOptions,
    handleFilter: handleFuzzyFilter,
  } = useManualFilterFuzzy({
    options: options.map((valueOption) => ({
      value: valueOption.value.toString(),
      label: valueOption.label,
      filter: valueOption.filter,
      obsolete: valueOption.obsolete,
      keywords: [],
    })),
  });

  const handleUpdateField = useCallback(
    (name: string, val: any, ref?: string | null) => {
      if (onChange != null) {
        onChange({
          name,
          value: val,
          ref,
        });
      }
    },
    [onChange]
  );

  const handleOnChange = useCallback(
    (e: OnChangeParam<any>) => {
      const { value: v, ref } = e;
      handleUpdateField(property.name, v, ref);
    },
    [handleUpdateField, property]
  );

  const getFormControl = () => {
    const attributes = {
      ...props,
      id: id || property.name,
      className: classNames(className, {
        "forced-value": forced,
        "non-forced-value": !forced,
        "has-error": !valid,
      }),
      name: property.name,
      readOnly: property.disabled,
      key: `${property.name}-input`,
      value,
      placement: "bottom",
      clearable: !property.required,
      multiple: property.multiple,
      options,
      disabled: property.disabled || !!disabled,
      limit: property.characterLimit,
      boundary: property.boundary,
      onChange: handleOnChange,
    } as BaseProps;

    const createInsertableButton = () => {
      return property.addingOptionsAllowed ? (
        <Button
          variant="light"
          block
          className="text-left"
          onClick={() => {
            if (property.type === PropertyType.MEMBER_SELECT) {
              const principalProperty = property as PrincipalAttributeProperty;
              if (
                Array.isArray(principalProperty.groupNames) &&
                principalProperty.groupNames.length > 0
              ) {
                const [groupName] = principalProperty.groupNames;
                addProjectMember(groupName, "Users").then((principalId) => {
                  if (principalId != null) {
                    const valueToUpdate = property.multiple
                      ? [...(value || []), principalId]
                      : [principalId];
                    handleUpdateField(property.name, valueToUpdate);
                  }
                });
              }
            } else if (property.type === PropertyType.SELECT) {
              addValuesetItem(
                {
                  propertyName: property.name,
                  reference: getDependencyValue(property, filter || {}),
                  advanced: false,
                },
                (entry: ValuesetItem) => {
                  if (entry != null && entry.value != null) {
                    const valueToUpdate = property.multiple
                      ? _(value).concat(entry.value).reject(_.isNull).value()
                      : [entry.value];
                    handleUpdateField(
                      property.name,
                      valueToUpdate,
                      entry.filter
                    );
                  }
                }
              );
            }
          }}
        >
          <span className="mr-2">{t("common.add")}</span>
          {property.label}
        </Button>
      ) : (
        []
      );
    };

    switch (property.type) {
      case PropertyType.SELECT:
        return h(
          PancoSelect,
          {
            ...attributes,
            manualFilter: true,
            options: fuzzyFilteredOptions,
            onFilter: handleFuzzyFilter,
            optionRow: (valuesetItem: PancoSelectOption) => {
              if (property.cellRender != null) {
                const { value, label, obsolete } = valuesetItem;
                return property.cellRender(value, label, obsolete);
              }

              return valuesetItem.label;
            },
          },
          createInsertableButton()
        );
      case PropertyType.DATE:
        return h(
          DatePicker,
          getDateProps(attributes, property as DateAttributeProperty)
        );
      case PropertyType.PRINCIPAL_SELECT:
        return h(
          PrincipalSelect,
          getPrincipalSelectProps(
            attributes,
            property as PrincipalAttributeProperty
          )
        );
      case PropertyType.MEMBER_SELECT:
        return h(
          MemberSelect,
          getMemberSelectProps(
            attributes,
            property as PrincipalAttributeProperty
          ),
          createInsertableButton()
        );
      case PropertyType.SUGGEST:
        return h(SuggestiveInput, {
          ...attributes,
          onInsert: addOption,
        });
      case PropertyType.TEXTAREA:
        return h(PancoArea, attributes);
      case PropertyType.RICH_TEXTAREA:
        return h(PancoRichTextArea, attributes);
      case PropertyType.CHECKBOX:
        return h(
          "div",
          {},
          //@ts-ignore
          h(Form.Check, {
            type: "checkbox",
            id: id || property.name,
            disabled: property.disabled || disabled,
            checked: !!value,
            onChange: (e) => handleUpdateField(property.name, e.target.checked),
          })
        );
      case PropertyType.YESNO:
        return h(PancoSwitch, getProps(attributes));
      case PropertyType.RADIO:
        return h(RadioSelect, attributes);
      case PropertyType.NUMERIC:
        return h(
          NumericInput,
          getNumericProps(attributes, property as NumericAttributeProperty)
        );
      case PropertyType.RESOURCE_SELECT:
        return h(ResourcePicker, {
          ...attributes,
          onInsert: addOption,
          folderId: filter?.parentresourceid || filter?.folderId,
          showLink: true,
          resourceType: 2,
          folderType: filter?.parentfoldertype,
          onOpenResource,
        });
      case PropertyType.MODULE_ELEMENT_SELECT:
        return h(
          ModuleElementSelect,
          getElementSelectProps(attributes, property, filter || {})
        );
      case PropertyType.ACCESS_MANAGEMENT:
        return h(QuickAccessManagement, {
          ...attributes,
          resource: filter as Resource,
          onChange: (level) => [console.log(level)],
        });
      default:
        return h(PancoInput, attributes);
    }
  };

  const label = useMemo(() => {
    const translatedLabel =
      property.translation != null ? t(property.translation) : property.label;

    if (property.disabled) {
      return translatedLabel;
    }

    return property.required && validation
      ? `${translatedLabel}*`
      : translatedLabel;
  }, [property, t, validation]);

  const renderControl = () => (
    <Wrap
      with={(children: React.ReactNode) => (
        <PancoTooltip text={property.getTooltip()}>{children}</PancoTooltip>
      )}
      when={property.isDependant()}
    >
      {getFormControl()}
      {!valid &&
        h(
          "div",
          { className: "field-error", key: "error-feedback" },
          (validationMessage || "").length
            ? validationMessage
            : "This field is required therefore must contain a value."
        )}
    </Wrap>
  );

  const labelProps = useMemo(() => {
    const conditionalProperty = property as ConditionalProperty;
    const offset = conditionalProperty.conditional
      ? _.clamp(conditionalProperty.level, 2)
      : 0;

    const spanBy = (base: number) => ({
      span: base - offset,
      offset,
    });

    return {
      sm: spanBy(4),
      md: spanBy(wide ? 2 : 4),
    };
  }, [property, wide]);

  if (controlOnly) {
    return renderControl();
  }

  if (inline) {
    return (
      <FormGroup
        as={StyledRow}
        key={property.name}
        highlighted={highlighted}
        className="form-property"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-${label}`}>{label}</Tooltip>}
        >
          <FormLabel column {...labelProps} className="text-truncate">
            {label}
          </FormLabel>
        </OverlayTrigger>
        <Col xs={10} sm={6} md={wide ? 9 : 6}>
          {renderControl()}
        </Col>
        <Col xs={2} md={wide ? 1 : 2} className="col-form-label">
          {/* @ts-ignore */}
          {property.type === PropertyType.DATE && property.reminderCreator && (
            <QuickReminder
              onOpenResource={onOpenResource}
              propertyName={property.name}
              name={`${
                property.translation != null
                  ? t(property.translation)
                  : property.label
              } Reminder`}
              timestamp={value}
              resource={filter as Resource}
            />
          )}
          {property.type === PropertyType.RESOURCE_SELECT && (
            <QuickUpload
              label={label}
              hide={value != null && Array.isArray(value) && value.length > 0}
              resource={filter as Resource}
              onOpenResource={onOpenResource}
              onInsert={(option) => {
                const { value } = option;
                handleUpdateField(property.name, [value]);
                addOption(option);
              }}
            />
          )}
          {renderAction && renderAction(property)}
          {property.description != null && (
            <HelperTooltip title={property.label} text={property.description} />
          )}
        </Col>
      </FormGroup>
    );
  }

  return (
    <FormGroup
      key={property.name}
      className={classNames("form-property", { highlighted })}
    >
      <Row>
        <Col xs={8}>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-${label}`}>{label}</Tooltip>}
          >
            <FormLabel>{label}</FormLabel>
          </OverlayTrigger>
        </Col>
        <Col xs={4}>
          {property.description != null && (
            <HelperTooltip title={property.label} text={property.description} />
          )}
        </Col>
      </Row>
      {renderControl()}
    </FormGroup>
  );
};

FormProperty.defaultProps = {
  inline: true,
  valid: true,
  validation: true,
};

export default React.memo(FormProperty, (next, prev) => {
  if (next == null) {
    return true;
  }

  const baseEqual = _(next)
    .omit(["filter", "onChange"])
    .isEqual(_(prev).omit(["filter", "onChange"]).value());

  const { property } = next;
  const dependencyValuePath = `filter.${property.dependency}`;

  return (
    baseEqual &&
    _.isEqual(
      _(next).result(dependencyValuePath),
      _(prev).result(dependencyValuePath)
    ) &&
    _.isEqual(_(next).get("filter.id"), _(prev).get("filter.id"))
  );
});
