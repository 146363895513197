import { Property } from "cosmos-config/lib/property/property";
import { createContext } from "react";
import { Valueset } from "../slices/docareaSlice";
import { ValuesetItem } from "../contants/valuesetItem";

type EditValuesetItemParam = {
  propertyName: string;
  valuesetEntry: ValuesetItem;
  advanced: boolean;
};

type AddValuesetItemParam = {
  propertyName: string;
  reference: string;
  advanced: boolean;
};

interface ValuesetContextValue {
  editValuesetItem: (
    param: EditValuesetItemParam,
    callback: () => void
  ) => void;
  addValuesetItem: (
    param: AddValuesetItemParam,
    callback: (valuesetItem: ValuesetItem) => void
  ) => void;
  getValuesetByName: (valuesetName: string) => ValuesetItem[];
  getValuesetByProperty: (propertyName: string) => ValuesetItem[];
  registerProperties: (propertiesMap: Record<string, Property>) => void;
  addTempValuesetItem: (
    valuesetName: string,
    valuesetItem: Partial<ValuesetItem>
  ) => void;
  valuesets: Valueset[];
  masterValuesets: Valueset[];
  loading: boolean;
  exportAllValuesetsExcel: () => void;
}

const ValuesetContext = createContext<ValuesetContextValue>({
  editValuesetItem: () => {},
  addValuesetItem: () => {},
  getValuesetByName: () => {
    console.warn("Call to default context method!");
    return [];
  },
  getValuesetByProperty: () => {
    console.warn("Call to default context method!");
    return [];
  },
  registerProperties: () => {},
  addTempValuesetItem: () => {},
  valuesets: [],
  masterValuesets: [],
  loading: false,
  exportAllValuesetsExcel: () => {},
} as ValuesetContextValue);

export default ValuesetContext;
