import { useCallback, useMemo } from "react";
import { Property } from "cosmos-config/lib/property/property";
import useCurrentUser from "./useCurrentUser";
import repositoryApi, { Version } from "../apis/repositoryApi";
import useSearchOptions from "./useSearchOptions";
import { useQuery } from "@tanstack/react-query";
import versionReviewProperties from "../contants/versionReviewProperties";
import { resourceCorrectionOfGateway } from "cosmos-config/utils";
import _ from "lodash";

export interface UseResourceVersionsProps {
  resourceId?: string;
  projectCode?: string;
  properties?: Property[];
}

const useResourceVersions = ({
  resourceId,
  projectCode,
  properties,
}: UseResourceVersionsProps) => {
  const { authenticated } = useCurrentUser();

  const fetchResourceVersions = useCallback(() => {
    if (resourceId == null) {
      return Promise.reject();
    }

    if (projectCode != null) {
      return repositoryApi.getProjectResourceVersions(projectCode, resourceId);
    }

    const propertyNames = _.compact(
      (properties || []).filter((p) => p.editable).map((p) => p.query)
    );

    if (propertyNames.length > 0) {
      return repositoryApi.getResourceVersions(
        resourceId,
        propertyNames,
        false
      );
    }

    return Promise.reject();
  }, [projectCode, properties, resourceId]);

  const { propertiesHash } = useSearchOptions({
    resourceId: resourceId || "",
    properties: properties || [],
  });

  const { data } = useQuery({
    queryKey: ["version-history", resourceId, projectCode || propertiesHash],
    queryFn: fetchResourceVersions,
    enabled: resourceId != null && authenticated,
    refetchOnWindowFocus: false,
  });

  const versions = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.map((version) => {
      const { properties, ...rest } = version;
      const res = _(properties).keyBy("name").mapValues("value").value();
      return {
        ...rest,
        resource: resourceCorrectionOfGateway(res, versionReviewProperties),
      } as Version;
    });
  }, [data]);

  return versions;
};

export default useResourceVersions;
