import { ResourceProperty } from "../utils/resourcePrepUtils";
import GatewayApi from "./conf/GatewayApi";

const gatewayApi = GatewayApi.build();

const createDocument = (
  resourceId: string,
  files: File[],
  properties?: ResourceProperty[],
  progressCallback?: (loadedBytes: number) => void
) => {
  const data = new FormData();
  data.append("properties", JSON.stringify(properties));

  for (let i = 0; i < Array.from(files).length; i++) {
    const file = files[i];
    data.append(`files`, file, file.name);
  }

  return gatewayApi
    .post(`/repository/folder/${resourceId}/children/document`, data, {
      responseType: "json",
      onUploadProgress: (e) => {
        if (progressCallback != null) {
          progressCallback(e.loaded);
        }
      },
      // cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const repositoryFolderApi = {
  createDocument,
};

export default repositoryFolderApi;
