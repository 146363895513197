import React, { useEffect, useState, createElement as h } from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import ContentLayout from "../content-layout/ContentLayout";
import LoadingOverlay from "../loading-overlay/LoadingOverlay";

const MarkdownReader = ({ input, hideToc }) => {
  const [markdown, setMarkdown] = useState();
  const [loading, setLoading] = useState(false);
  const [outline, setOutline] = useState([]);

  const generateSlug = (title = "") => {
    return title.replace(/\s/g, "-").replace(/\./, "").toLowerCase();
  };

  useEffect(() => {
    const addHeader = (header, source = []) => {
      const last = source[source.length - 1];

      if (last == null || last.level >= header.level) {
        return [...source, header];
      }

      return [
        ...source.slice(0, source.length - 1),
        {
          ...last,
          subHeaders: addHeader(header, last.subHeaders),
        },
      ];
    };

    setLoading(true);
    fetch(input)
      .then((response) => response.text())
      .then((md) => {
        setLoading(false);
        setMarkdown(md);

        const headers = md.match(/[^\(](#{1,6}(.*))/g);
        const o = headers.reduce((acc, cur, index) => {
          const title = cur.replace(/^\n#{1,6}\s*/, "");
          const header = {
            title,
            anchor: generateSlug(title),
            level: cur.match(/#/g).length - 1,
            subHeaders: [],
            index,
          };

          return addHeader(header, acc);
        }, []);

        setOutline(o);
      });
  }, [input]);

  const renderOutlineList = (outlineArr) => {
    return (
      <ul className="list-unstyled">
        {outlineArr.map((item) => (
          <li
            style={{ paddingLeft: item.level * 10 }}
            key={item.index}
            className="mb-2"
          >
            <>
              <a href={`#${item.anchor}`} className="text-dark mb-2 d-block">
                {item.title}
              </a>
              {item.subHeaders.length > 0 && renderOutlineList(item.subHeaders)}
            </>
          </li>
        ))}
      </ul>
    );
  };

  const flattenNodes = (text, child) => {
    return typeof child === "string"
      ? text + child
      : React.Children.toArray(child.props.children).reduce(flattenNodes, text);
  };

  const renderHeading = ({ level, children, value, ...attrs }) => {
    const chldrn = React.Children.toArray(children);
    const title = chldrn.reduce(flattenNodes, "");
    return h(`h${level}`, { id: generateSlug(title) }, chldrn);
  };

  const components = {
    img: ({ src }) => {
      return (
        <div className="text-center">
          <img
            src={src}
            alt=""
            className="img-fluid rounded img-thumbnail"
            style={{ maxWidth: 900 }}
          />
        </div>
      );
    },
    h1: renderHeading,
    h2: renderHeading,
    h3: renderHeading,
    h4: renderHeading,
    h5: renderHeading,
    h6: renderHeading,
  };

  return (
    <ContentLayout>
      {!hideToc && (
        <ContentLayout.Sidebar>
          {renderOutlineList(outline)}
        </ContentLayout.Sidebar>
      )}
      <ContentLayout.Content>
        <LoadingOverlay loading={loading}>
          <ReactMarkdown children={markdown} components={components} />
        </LoadingOverlay>
      </ContentLayout.Content>
    </ContentLayout>
  );
};

MarkdownReader.propTypes = {
  input: PropTypes.string.isRequired,
  hideToc: PropTypes.bool,
};

MarkdownReader.defaultProps = {
  hideToc: false,
};

export default MarkdownReader;
