import {
  faFilePdf,
  faFileImage,
  faFileWord,
  faFilePowerpoint,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileCode,
  faFileArchive,
  faFile,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

const imageFile = {
  icon: faFileImage,
  color: "#e79627",
};

const pdfFile = {
  icon: faFilePdf,
  color: "#d52a29",
};

const wordFile = {
  icon: faFileWord,
  color: "#1e9cef",
};

const powerpointFile = {
  icon: faFilePowerpoint,
  color: "#ea7130",
};

const excelFile = {
  icon: faFileExcel,
  color: "#6aab20",
};

const file = {
  icon: faFile,
  color: "#7a8387",
};

const audioFile = {
  icon: faFileAudio,
  color: "#7963e6",
};

const videoFile = {
  icon: faFileVideo,
  color: "#7963e6",
};

const archiveFile = {
  icon: faFileArchive,
  color: "#e79627",
};

const codeFile = {
  icon: faFileCode,
  color: "#7a8387",
};

const mailFile = {
  icon: faEnvelope,
  color: "#0072C6",
};

const extensionMapping: Record<string, FontAwesomeIconProps> = {
  gif: imageFile,
  jpeg: imageFile,
  jpg: imageFile,
  png: imageFile,

  pdf: pdfFile,

  doc: wordFile,
  docx: wordFile,

  ppt: powerpointFile,
  pptx: powerpointFile,
  pptm: powerpointFile,

  xls: excelFile,
  xlsx: excelFile,
  xlsm: excelFile,

  csv: file,

  aac: audioFile,
  mp3: audioFile,
  ogg: audioFile,

  avi: videoFile,
  flv: videoFile,
  mkv: videoFile,
  mp4: videoFile,

  gz: archiveFile,
  zip: archiveFile,

  css: codeFile,
  html: codeFile,
  js: codeFile,

  txt: file,

  msg: mailFile,
  eml: mailFile,
};

const extensionIconMapping = (extension?: string): FontAwesomeIconProps => {
  if (extension == null) {
    return file;
  }

  const iconProps = extensionMapping[extension];

  return iconProps != null ? iconProps : file;
};

export default extensionIconMapping;
