import GatewayApi from "../../../apis/conf/GatewayApi";
import masterdataApi from "../../../apis/masterdataApi";

const gatewayApi = GatewayApi.build();
const RESOURCE_PERMISSIONS_SCOPE = "RESOURCE_PERMISSIONS";

const getPermissions = (
  docarea: string,
  resourceId: string,
  properties: string[]
) => {
  return masterdataApi.getMasterData(
    docarea,
    RESOURCE_PERMISSIONS_SCOPE,
    properties,
    {
      where: { resourceId },
      orWhere: undefined,
      orderBy: {},
      paging: undefined,
    }
  );
};

const grantPermission = (
  docarea: string,
  resourceId: string,
  principalId: string
) => {
  return gatewayApi
    .post(`/repository/res/${resourceId}/grant-permission/${principalId}`)
    .then((response) => Promise.resolve(response.data));
};

const grantAll = (
  resourceIds: string[],
  principalIds: string[],
  resourcePermission: any
) => {
  return gatewayApi
    .put(`/repository/grant-all`, {
      resourceIds,
      principalIds,
      resourcePermission,
    })
    .then((response) => Promise.resolve(response.data));
};

const importPermissions = (
  docarea: string,
  resourceId: string,
  permissions: any[]
) => {
  return gatewayApi
    .post(`/repository/res/${resourceId}/permissions/import`, permissions)
    .then((response) => Promise.resolve(response.data));
};

const deletePermission = (docarea: string, permissionId: string) => {
  return gatewayApi
    .delete(`/resource-permission/${permissionId}`)
    .then((response) => Promise.resolve(response.data));
};

const revokeAll = (resourceIds: string[], principalIds: string[]) => {
  return gatewayApi
    .put(`/repository/revoke-all`, {
      resourceIds,
      principalIds,
    })
    .then((response) => Promise.resolve(response.data));
};

const updatePermission = (
  docarea: string,
  permissionId: string,
  permission: any
) => {
  return gatewayApi
    .put(`/resource-permission/${permissionId}`, permission)
    .then((response) => Promise.resolve(response.data));
};

const requestPermission = (resourceId: string, reason?: string) => {
  return gatewayApi
    .post(`/repository/res/${resourceId}/request-permission`, {
      reason,
    })
    .then((response) => Promise.resolve(response.data));
};

const toggleActive = (permissionId: string) => {
  return gatewayApi
    .put(`/resource-permission/${permissionId}/toggle-active`)
    .then((response) => Promise.resolve(response.data));
};

const getUsersResourcePermissions = () => {
  return gatewayApi
    .get(`/user/resource-permissions`)
    .then((response) => Promise.resolve(response.data));
};

const resourcePermissionsApi = {
  getPermissions,
  grantPermission,
  grantAll,
  importPermissions,
  deletePermission,
  revokeAll,
  updatePermission,
  requestPermission,
  toggleActive,
  getUsersResourcePermissions,
};

export default resourcePermissionsApi;
