import GatewayApi from "./conf/GatewayApi";

const gatewayApi = GatewayApi.build();

const replaceAllValues = (
  folderId: string,
  resourceIds: string[],
  propertyName: string,
  value: any,
  newValue: any
) => {
  return gatewayApi
    .put(`/repository/folder/${folderId}/children/replace`, {
      resourceIds,
      propertyName,
      value,
      newValue,
    })
    .then((response) => Promise.resolve(response.data));
};

const getResourceProperties = (resourceId: string, properties: string[]) => {
  return gatewayApi
    .get(`/repository/res/${resourceId}`, {
      params: {
        properties: properties.join(","),
      },
    })
    .then((response) => Promise.resolve(response.data));
};

export interface VersionChange {
  name: string;
  value: any;
  oldValue: any;
}

export interface Version {
  changes: VersionChange[];
  resourceId: string;
  objectClass: string;
  privateResource: boolean;
  permittedusers: any[];
  displayName: string;
  initialCreator: string;
  initialCreatorCommonName: string;
  identifier: number;
  resourceType: number;
  department: string;
  pathIdentifiers: number[];
  fileExtension: string;
  linkedDocumentIds: [];
  parentResourceId: string;
  contentItemsHashes: string[];
  contentType: string;
  versionIdentifier: number;
  filename: string;
  initialCreation: number;
  openCommentsCount: number;
  hidden: boolean;
  parentDocumentId: null;
  contentItemCount: number;
  lastModifiedDate: number;
  creator: string;
  creatorCommonName: string;
  versionMerge: null;
  versionNumber: number;
  properties: any[];
  contentChanged: boolean;
  renditionResourceId: string;
  docarea: string;
  resource: Record<string, any>;
}

const getResourceVersions = (
  resourceId: string,
  properties: string[],
  merging: boolean = true
) => {
  return gatewayApi
    .get<Version[]>(`/repository/res/${resourceId}/versions`, {
      params: {
        properties: properties.join(","),
        merging,
      },
    })
    .then((response) => Promise.resolve(response.data));
};

const getProjectResourceVersions = (
  projectCode: string,
  resourceId: string
) => {
  return gatewayApi
    .get<Version[]>(`/repository/res/${resourceId}/versions`, {
      params: {
        projectCode,
      },
    })
    .then((response) => Promise.resolve(response.data));
};

export interface ProcessInstance {
  active: boolean;
  id: string;
  name: string;
  processDefinitionKey: string;
  processDefinitionVersion: number;
  startTime: number;
  endTime: number | null;
  variables: Record<string, any>;
}

const getResourceBusinessProcessHistory = (resourceId: string) => {
  return gatewayApi
    .get<ProcessInstance[]>(
      `/repository/res/${resourceId}/business-process/history`
    )
    .then((response) => Promise.resolve(response.data));
};

const repositoryApi = {
  replaceAllValues,
  getResourceProperties,
  getResourceVersions,
  getProjectResourceVersions,
  getResourceBusinessProcessHistory,
};

export default repositoryApi;
