import {
  property,
  systemProperty,
  valuesetSource,
} from "cosmos-config/generator";

const projectPortalProperties = [
  systemProperty("id"),
  systemProperty("rules"),
  systemProperty("documentArea"),
  systemProperty("targetProjectResourceId"),
  property("Target ProjectCode")
    .selectableAttribute("targetProjectCode", "test", valuesetSource.VALUESET)
    .excludeForm(),
  property("Native Search", "nativeSearch")
    .booleanAttribute("nativeSearch")
    .useYesNo(),
  property("Active", "active").booleanAttribute("active").useYesNo(),
  property("Ignore Structures", "ignoreStructures")
    .booleanAttribute("ignoreStructures")
    .useYesNo(),
];

export default projectPortalProperties;
