import { property } from "cosmos-config/generator";

const group = [
  property("Display Name", "commonname"),
  property("Description", "description").textArea(255).optional(),
  property("Active")
    .booleanAttribute("active")
    .useYesNo()
    .optional()
    .excludeForm(),
];

export default group;
