import React, { useCallback, useEffect, useMemo, useState } from "react";
import authService from "../../../services/authService";
import { azureadOpenidClient, b2cLoginClient } from "../msalClient";
import SessionContext, { CosmosSession } from "../contexts/SessionContext";
import sessionSlice, { SessionStatus } from "../../../slices/sessionSlice";
import useCoreSelector from "../../../hooks/useCoreSelector";
import { CosmosCoreRootState } from "../../../store";
import useCoreDispatch from "../../../hooks/useCoreDispatch";
import sps, { CosmosAuthState } from "../services/sessionPersistanceService";
import { IPublicClientApplication, PopupRequest } from "@azure/msal-browser";
import userApi from "../../../apis/userApi";
import { DateTime, Duration } from "luxon";

const logger = {
  info: (message: string) => {
    console.info(
      `${DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)} : ${message}`
    );
  },
};

const SESSION_INVALIDATION_PERIOD = Duration.fromObject({
  minutes: 15,
}).toMillis();

const getMsalClient = (domainName?: string | null) => {
  return domainName === "ExternalUsers" ? b2cLoginClient : azureadOpenidClient;

  // throw new Error(`Domain name ${domainName} not supported!`);
};

const initialSessionState: CosmosSession = {
  accessToken: null,
  domainName: "Users",
  tokenType: "Bearer",
  username: null,
};
export interface SessionProviderProps {
  children?: React.ReactNode;
  defaultUsername?: string;
}

const checkIframeWrapped = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const SessionProvider = ({
  children,
  defaultUsername,
}: SessionProviderProps) => {
  const dispatch = useCoreDispatch();

  const [session, setSession] = useState<CosmosSession>(initialSessionState);
  const [entryReferer, setEntryReferer] = useState<string | null>(null);
  const sessionStatus = useCoreSelector<CosmosCoreRootState>(
    (state) => state.session.status
  );

  const accessToken: string | null = useMemo(() => {
    return session.accessToken != null
      ? `${session.tokenType} ${session.accessToken}`
      : null;
  }, [session]);

  const handleSession = useCallback(
    (cosmosSession?: CosmosSession | null) => {
      if (cosmosSession != null) {
        setSession(cosmosSession);
        sps.updateCosmosSsoDefaults(cosmosSession);
      }

      dispatch(sessionSlice.actions.ready(cosmosSession?.accessToken != null));
    },
    [dispatch]
  );

  const logout = useCallback(
    (force?: boolean) => {
      if (force) {
        userApi.logout().catch((err) => {
          console.error(err);
        });

        // const persistedSession = sps.getCosmosSsoDefaults();
        // if (persistedSession != null) {
        //   const { username, domainName } = persistedSession;

        //   if (domainName != null && username != null) {
        //     const msalClient = getMsalClient(domainName);

        //     const account = msalClient.getAccount({
        //       username,
        //     });

        //     msalClient.logoutRedirect({
        //       account,
        //     });
        //   }
        // }

        sps.clearCosmosSsoDefaults();
      }

      setSession(initialSessionState);
      dispatch(sessionSlice.actions.ready(false));
    },
    [dispatch]
  );

  useEffect(() => {
    authService.update(accessToken, session.domainName);
  }, [accessToken, session]);

  const renewSilently = useCallback((): Promise<CosmosSession> => {
    logger.info("Attempt to renew the cosmos user session.");

    const persistedSession = sps.getCosmosSsoDefaults({
      username: defaultUsername,
    });

    if (persistedSession.tokenType === "Basic") {
      return Promise.resolve(persistedSession);
    }

    const loginHint = persistedSession?.username;

    if (loginHint != null && loginHint.length > 0) {
      const msalClient = getMsalClient(persistedSession.domainName);
      const account = msalClient.getAccount({
        username: loginHint,
      });

      if (account != null) {
        logger.info("Running msal sso silent method.");
        return msalClient
          .ssoSilent({
            scopes: ["openid", "profile", "email"],
            account,
          })
          .then((result) => {
            logger.info(
              "Authentication response received! Non-null=" +
                String(result != null)
            );
            return Promise.resolve({
              ...persistedSession,
              accessToken: result.idToken,
            });
          });
      }
    }

    return Promise.reject(
      `Login hint '${loginHint}' is insufficient or account is unavailable! Manual login required.`
    );
  }, [defaultUsername]);

  useEffect(() => {
    if (sessionStatus === SessionStatus.RENEWING) {
      renewSilently()
        .catch((error) => {
          console.warn(error);
          return Promise.resolve(null);
        })
        .then((cosmosSession) => {
          handleSession(cosmosSession);

          if (cosmosSession == null) {
            logout(true);
          }
        });
    }
  }, [
    accessToken,
    dispatch,
    logout,
    renewSilently,
    sessionStatus,
    handleSession,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      logger.info("Invalidating cosmos session on purpose...");

      dispatch(sessionSlice.actions.invalidate());
    }, SESSION_INVALIDATION_PERIOD);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    const getRedirectHandler = (client: IPublicClientApplication) =>
      client.initialize().then(() => client.handleRedirectPromise());

    Promise.all([
      getRedirectHandler(azureadOpenidClient),
      getRedirectHandler(b2cLoginClient),
    ])
      .then((responses) => responses[0] || responses[1])
      .then((tokenResponse): Promise<CosmosSession> => {
        if (tokenResponse?.idToken != null) {
          const authenticationState: CosmosAuthState =
            tokenResponse.state != null ? JSON.parse(tokenResponse.state) : {};
          setEntryReferer(authenticationState.referer || null);

          return sps.handleTokenResponse(tokenResponse);
        } else {
          return renewSilently();
        }
      })
      .catch((error) => {
        console.warn(error);
        return Promise.resolve(null);
      })
      .then(handleSession);
  }, [dispatch, renewSilently, handleSession]);

  const loginOpenId = useCallback(
    (domainName: string, referer?: string) => {
      const persistedSession = sps.getCosmosSsoDefaults({
        username: defaultUsername,
      });

      const loginOptions: PopupRequest = {
        scopes: ["openid", "profile", "email"],
        loginHint: persistedSession.username || undefined,
        state: JSON.stringify({
          domainName,
          referer,
        }),
      };

      const msalClient = getMsalClient(domainName);
      msalClient.initialize().then(() => {
        if (checkIframeWrapped()) {
          msalClient
            .loginPopup(loginOptions)
            .then(sps.handleTokenResponse)
            .catch((error) => {
              console.warn(error);
              return Promise.resolve(null);
            })
            .then(handleSession);
        } else {
          msalClient.loginRedirect(loginOptions);
        }
      });
    },
    [defaultUsername, handleSession]
  );

  return (
    <SessionContext.Provider
      value={{
        ready:
          sessionStatus === SessionStatus.READY ||
          sessionStatus === SessionStatus.PROVIDED,
        domainName: session.domainName,
        accessToken,
        entryReferer,
        loginOpenId,
        loginBasic: useCallback(
          (username: string, password: string) => {
            handleSession({
              accessToken: btoa(`${username}:${password}`),
              tokenType: "Basic",
              domainName: "Users",
              username: null,
            });
          },
          [handleSession]
        ),
        invalidate: () => {
          dispatch(sessionSlice.actions.invalidate());
        },
        logout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
