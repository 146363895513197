import { createElement as h, useMemo } from "react";
import { trimUINumber } from "cosmos-config/utils";
import {
  PancoSelectOption,
  PancoSelectProps,
  PancoSelect,
} from "cosmos-components";
import useProjectMembers from "../../hooks/useProjectMembers";

export interface MemberSelectProps extends PancoSelectProps {
  groupNames?: string[];
  principalType?: string | number;
  flattenGroups?: boolean;
}

const MemberSelect = ({
  groupNames,
  principalType,
  onChange,
  multiple,
  flattenGroups,
  ...props
}: MemberSelectProps) => {
  const { getHierarchicalMembers, getPrincipalIds, loading } =
    useProjectMembers();

  const options: PancoSelectOption[] = useMemo(() => {
    return getHierarchicalMembers(
      groupNames,
      multiple ? null : parseInt(principalType as string, 10)
    ).map((u) => {
      const label = u.group ? u.commonname : trimUINumber(u.commonname);
      return {
        label: label || "",
        value: u.principalId,
      };
    });
  }, [getHierarchicalMembers, groupNames, multiple, principalType]);

  return h(PancoSelect, {
    ...props,
    loading,
    options,
    multiple,
    onChange: ({ name, value: onChangeValues }) => {
      if (onChange != null) {
        onChange({
          name,
          value: getPrincipalIds(onChangeValues, flattenGroups),
        });
      }
    },
  });
};

MemberSelect.defaultProps = {
  principalType: 2,
  flattenGroups: true,
};

export default MemberSelect;
