import property, { Property } from "./property";

/**
 * @category Property
 * @author Patrik Pancisin
 * @class folderProperty
 * @classdesc Generates a property which is only used by a folder resource.
 * @extends property
 * @param {string} label - The label of the group displayed in cosmos.
 * @param {string} name - The name of the group.
 */
export default (label: string, name: string, folderType?: string): Property => {
  const folderProperty = property(label, name, 1);

  const handler = {
    get: (target: any, prop: string) => {
      switch (prop) {
        case "editable":
        case "filterable":
        case "information":
        case "tableColumn":
          return false;
        case "widgets":
          return [];
        case "folderProperty":
          return {
            ...folderProperty,
            resourceType: 1,
            folderType,
          } as Property;
        case "asFolderProperty":
          return () => folderProperty;
        default:
          return target[prop];
      }
    },
  };

  return new Proxy(folderProperty, handler);
};
