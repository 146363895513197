/**
 * @typedef structureType
 * @memberof structure
 * @description Structure type defines usage in cosmos.
 * @property {string} MAIN Structured in project root explorer.
 * @property {string} FOLDER Structured within each subfolder of the project.
 */
enum StructureType {
  /** Structured in project root explorer. */
  MAIN = "main",
  FOLDER = "in_folder",
}

export default StructureType;
