import CacheApi from './conf/CacheApi';
import GatewayApi from './conf/GatewayApi';

const gatewayApi = GatewayApi.build();

const getProjects = (docarea) => {
  return gatewayApi
    .get(
      `/docarea/${docarea}/projects`,
      CacheApi.options({}, false, 'PROJECTS')
    )
    .then((response) => Promise.resolve(response.data));
};

const createProject = (docarea, project) => {
  return gatewayApi
    .post(`/docarea/${docarea}/project`, project)
    .then((response) => Promise.resolve(response.data));
};

const getProjectMessages = (projectCode) => {
  return gatewayApi
    .get(`/project/${projectCode}/messages`)
    .then((response) => Promise.resolve(response.data));
};

const createProjectMessage = (projectCode, message) => {
  return gatewayApi
    .post(`/project/${projectCode}/message`, message)
    .then((response) => Promise.resolve(response.data));
};

const updateProjectMessage = (projectCode, messageId, message) => {
  return gatewayApi
    .put(`/project/${projectCode}/message/${messageId}`, message)
    .then((response) => Promise.resolve(response.data));
};

const deleteProjectMessage = (projectCode, messageId) => {
  return gatewayApi
    .delete(`/project/${projectCode}/message/${messageId}`)
    .then((response) => Promise.resolve(response.data));
};

const createContactMessage = (projectCode, message) => {
  return gatewayApi
    .post(`/project/${projectCode}/contact`, message)
    .then((response) => Promise.resolve(response.data));
};

const getWidgets = (projectCode) => {
  return gatewayApi
    .get(`/project/${projectCode}/widgets`)
    .then((response) => Promise.resolve(response.data));
};

const saveAllWidgets = (projectCode, widgets) => {
  return gatewayApi
    .post(`/project/${projectCode}/widgets`, widgets)
    .then((response) => Promise.resolve(response.data));
};

const removeAllWidgets = (projectCode) => {
  return gatewayApi
    .delete(`/project/${projectCode}/widgets`)
    .then((response) => Promise.resolve(response.data));
};

const getQueries = (projectCode) => {
  return gatewayApi
    .get(`/project/${projectCode}/queries`)
    .then((response) => Promise.resolve(response.data));
};

const createRepositoryQuery = (projectCode, query) => {
  return gatewayApi
    .post(`/project/${projectCode}/query`, query)
    .then((response) => Promise.resolve(response.data));
};

const createBusinessProcess = (projectCode, process) => {
  return gatewayApi
    .post(`/project/${projectCode}/business-process`, process)
    .then((response) => Promise.resolve(response.data));
};

const getUserTasks = (projectCode) => {
  return gatewayApi
    .get(`/project/${projectCode}/tasks`)
    .then((response) => Promise.resolve(response.data));
};

const getUserTaskHistory = (projectCode) => {
  return gatewayApi
    .get(`/project/${projectCode}/task-history`)
    .then((response) => Promise.resolve(response.data));
};

const getUserProcesses = (projectCode) => {
  return gatewayApi
    .get(`/project/${projectCode}/business-processes`)
    .then((response) => Promise.resolve(response.data));
};

export default {
  getProjects,

  createProject,

  getProjectMessages,
  createProjectMessage,
  updateProjectMessage,
  deleteProjectMessage,
  createContactMessage,

  getWidgets,
  saveAllWidgets,
  removeAllWidgets,

  getQueries,
  createRepositoryQuery,

  createBusinessProcess,
  getUserTasks,
  getUserTaskHistory,
  getUserProcesses,
};
