import { Button, Modal } from "react-bootstrap";
import React, { useCallback, useMemo, useState } from "react";
import { Project } from "../../contexts/ProjectContext";
import { AccessRequest } from "../../types/accessRequest";
import { ProjectCommandModalProps } from "../../contexts/ProjectCommandContext";
import ProjectAccessRequest from "./ProjectAccessRequest";

export interface RequestAccessModalProps extends ProjectCommandModalProps {
  onRequest?: (accessRequest: Partial<AccessRequest>) => void;
  project?: Project;
}

const RequestAccessModal = ({
  show,
  onRequest,
  onClose,
  project,
}: RequestAccessModalProps) => {
  const [accessRequest, setAccessRequest] = useState<Partial<AccessRequest>>(
    {}
  );

  const valid = useMemo(() => {
    if (accessRequest == null) {
      return false;
    }

    const { resourceId, reason, validatorPrincipalId } = accessRequest;

    return (
      resourceId != null &&
      validatorPrincipalId != null &&
      (reason || "").length > 5
    );
  }, [accessRequest]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Request Access to {project?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProjectAccessRequest
          project={project}
          onChange={useCallback(
            (ar: Partial<AccessRequest>) => setAccessRequest(ar),
            []
          )}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!valid}
          onClick={() => {
            if (onRequest != null) {
              onRequest(accessRequest);
            }
          }}
        >
          Request
        </Button>
        <Button variant="link" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestAccessModal;
