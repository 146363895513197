import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { property } from "cosmos-config/generator";
import { createElement as h } from "react";

const userProperties = [
  property("", "active")
    .includeTable(true, 30)
    .tableCell((v) => {
      return h(FontAwesomeIcon, {
        icon: faUser,
        color: v ? "green" : "none",
      });
    }),
  property("ID", "name").includeTable(true, 100),
  property("Department", "departmentTeam")
    // .selectableAttribute(
    //   "departmentTeam",
    //   "master_team",
    //   valuesetSource.CUSTOM_VALUESET
    // )
    .includeTable(true, 100),
  property("Name", "formattedName").tableCell((v) => {
    return h("b", {}, v);
  }),
  property("Email", "email"),
];

export default userProperties;
