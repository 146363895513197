import Api from "./conf/Api";
import {
  select,
  andOperation,
  orOperation,
  queryOperand,
  operation,
  existsOperation,
} from "cosmos-config/nql";

const api = Api.build();

const requestTasks = (docarea: string, query: string) => {
  return api
    .get(`/workflow/docarea/${docarea}/tasks/search`, {
      params: {
        query,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const getAssignedTasks = (
  docarea: string,
  processDefinition: string,
  properties: string[] = []
) => {
  const filter = andOperation(
    orOperation(
      existsOperation(
        operation(
          "eq",
          queryOperand("assignmentorgentityid"),
          queryOperand("%currentDefaultPositionPrincipalId")
        )
      ),
      operation(
        "in",
        queryOperand("assignedpositionid"),
        queryOperand("%currentProxiedOrgEntityPrincipalIds")
      )
    ),
    operation("eq", queryOperand("processcanceled"), false),
    operation("eq", queryOperand("processended"), false),
    operation("eq", queryOperand("processdefinition"), processDefinition)
  );

  const query = select(properties)
    .filter(filter)
    // .orWhere([
    //   {
    //     name: "assignmentorgentityid",
    //     value: isCurrentDefaultPositionId(),
    //     multi: true,
    //   },
    //   {
    //     name: "assignedpositionid",
    //     value: isCurrentProxyPositionId(),
    //   },
    // ])
    // .where({
    //   processcanceled: isFalse(),
    //   processended: isFalse(),
    //   processdefinition: processDefinition,
    // })
    .toString();

  return requestTasks(docarea, query);
};

const workflowApi = {
  getAssignedTasks,
};

export default workflowApi;
