import { Property } from "cosmos-config/lib/property/property";
import {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from "cosmos-config/utils";
import { useCallback, useEffect, useState } from "react";
import { UniversalFormProps } from "../components/SimpleUniversalForm";
import { Group } from "cosmos-config/lib/group/group";

export interface UseFormProps<T> {
  properties?: Property[];
  resource?: T;
  onSubmit?: (resource: T) => void;
}

export interface FormProps extends UniversalFormProps {
  properties?: Property[];
  groups?: Group[];
}

type FormResource = Record<string, any>;

const useForm = <T>({
  properties,
  resource,
  onSubmit,
}: UseFormProps<T>): FormProps => {
  const [res, setRes] = useState<FormResource>({});

  useEffect(() => {
    if (
      Array.isArray(properties) &&
      properties.length > 0 &&
      resource != null
    ) {
      const formRes = resourceCorrectionOfGateway(resource, properties);
      setRes(formRes);
    }
  }, [properties, resource]);

  return {
    resource: res,
    properties,
    onUpdateResource: useCallback((id: string, name: string, value: any) => {
      setRes((r) => ({
        ...r,
        [name]: value,
      }));
    }, []),
    onSubmit: useCallback(
      (resourceId?: string) => {
        if (
          Array.isArray(properties) &&
          properties.length > 0 &&
          onSubmit != null
        ) {
          const subRes = resourceCorrectionForGateway(res, properties);
          onSubmit(subRes as T);
        }
      },
      [onSubmit, properties, res]
    ),
  };
};

export default useForm;
