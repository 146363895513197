import { createContext } from "react";
import { GridMapping } from "./sidebarGridMapping";

export const componentType = {
  CONTENT: "CONTENT",
  SIDEBAR: "SIDEBAR",
  BLOCKER: "BLOCKER",
};

export interface ContentLayoutContextValue {
  /**
   * @deprecated
   * Shoudl be used for content layout content. But its better to not specify the
   * column width and use dynamic.
   * @param uuid
   * @returns
   */
  getComponentProps: (uuid: string) => GridMapping;
  registerComponent: (uuid: string, type: string) => Promise<number>;
  toggleCollapse: (uuid: string) => void;
  isCollapsed: (uuid: string) => boolean;
  unregisterComponent: (uuid: string) => void;
}

const ContentLayoutContext = createContext<ContentLayoutContextValue>({
  getComponentProps: () => ({
    sm: 12,
    md: 12,
    lg: 12,
  }),
  registerComponent: () => Promise.reject(),
  toggleCollapse: () => {},
  isCollapsed: () => false,
  unregisterComponent: () => {},
});

export default ContentLayoutContext;
