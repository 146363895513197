/**
 * @enum {string}
 * @category Property
 * @readonly
 */
enum PropertyType {
  TEXT = "text",
  DATE = "date",
  SELECT = "select",
  PRINCIPAL_SELECT = "principal-select",
  SUGGEST = "suggest",
  TEXTAREA = "textarea",
  RICH_TEXTAREA = "rich-textarea",
  CHECKBOX = "checkbox",
  YESNO = "yesno",
  RADIO = "radio",
  MEMBER_SELECT = "member-select",
  NUMERIC = "numeric",
  RESOURCE_SELECT = "resource-select",
  MODULE_ELEMENT_SELECT = "module-element-select",
  ACCESS_MANAGEMENT = "access-management",
}

export default PropertyType;
