import React, { useCallback, useContext, useMemo } from "react";
import { accessManagementType } from "cosmos-config/generator";
import _ from "lodash";
import { Accordion, LoadingOverlay } from "cosmos-components";
import ResourceAccessTable from "./ResourceAccessTable";
import AccessGrantInput from "./AccessGrantInput";
import { Button, Card, Col, Row } from "react-bootstrap";
import PermissionsImporter from "./PermissionsImporter";
import { useTranslation } from "react-i18next";
import useProject from "../../../hooks/useProject";
import useFolderTypePermissions from "../../../hooks/permissions/useFolderTypePermissions";
import Resource from "../../../types/resource";
import AccessManagementContext from "../contexts/AccessManagementContext";
import AccessLevelSlider from "./levelEditors/AccessLevelSlider";
import RestrictedLevelOnly from "./levelEditors/RestrictedLevelOnly";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import useProjectCompetence from "../../../hooks/useProjectCompetence";

export interface ResourceAccessManagementProps {
  resource?: Resource;
  disabled?: boolean;
  onChange?: (propertyName: string, value: any) => void;
  onResourceNeeded?: () => Promise<string>;
}

const ResourceAccessManagement = ({
  resource,
  disabled,
  onChange,
}: ResourceAccessManagementProps) => {
  const { loading, grantPermission } = useContext(AccessManagementContext);
  const { loading: competenceLoading } = useProjectCompetence();
  const { hasAccessManagement } = useFolderTypePermissions(resource);
  const { t } = useTranslation("module");
  const { project, createProjectGroup } = useProject();

  const supportsExternal = useMemo(() => {
    return _(project?.groups).some((g) => g.domainName === "ExternalUsers");
  }, [project]);

  const getAccessLevel = useCallback(
    (propertyName = "accesslevel") => {
      return _.isNumber(resource?.[propertyName])
        ? resource?.[propertyName]
        : 10;
    },
    [resource]
  );

  const renderTimelineAccordion = (
    title: string,
    propertyName: string,
    parentPropertyName: string
  ) => {
    return (
      // @ts-ignore
      <Accordion.Content title={title}>
        <AccessLevelSlider
          accessLevel={getAccessLevel(propertyName)}
          onChange={(level) => {
            if (onChange != null) {
              onChange(propertyName, level);

              if (resource?.resourcetype === 1) {
                onChange(parentPropertyName, level);
              }
            }
          }}
        />
      </Accordion.Content>
    );
  };

  return (
    <div className="position-relative">
      <LoadingOverlay loading={loading || competenceLoading}>
        {hasAccessManagement(accessManagementType.COMPLETE) && (
          <Accordion collapsed>
            {renderTimelineAccordion(
              t("accessmanagement.internal", { defaultValue: "Access Level" }),
              "accesslevel",
              "parentaccesslevel"
            )}
            {supportsExternal &&
              renderTimelineAccordion(
                t("accessmanagement.external", {
                  defaultValue: "External Access Level",
                }),
                "accesslevelexternal",
                "parentaccesslevelexternal"
              )}
          </Accordion>
        )}

        {!hasAccessManagement(accessManagementType.COMPLETE) &&
          resource?.resourcetype === 2 && (
            <RestrictedLevelOnly
              accessLevel={getAccessLevel("accesslevel")}
              onChange={(level) => {
                if (onChange != null) {
                  onChange("accesslevel", level);

                  if (resource?.resourcetype === 1) {
                    onChange("parentaccesslevel", level);
                  }
                }
              }}
            />
          )}

        {(getAccessLevel() < 10 ||
          getAccessLevel("accesslevelexternal") < 10 ||
          resource?.private) && (
          <Card className="mb-3">
            <Card.Body>
              <Row className="mb-3">
                <Col>
                  <h4>
                    {t("accessmanagement.granted_access", {
                      defaultValue: "Granted Access",
                    })}
                  </h4>
                </Col>
                <Col md="8" className="d-flex align-items-start">
                  <AccessGrantInput
                    className="flex-grow-1"
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <ResourceAccessTable disabled={disabled} />
            </Card.Body>
            <Card.Footer>
              <div className="ml-auto d-flex justify-content-end">
                <div className="border-right">
                  <Button
                    variant="link"
                    onClick={() => {
                      createProjectGroup().then((principalId) => {
                        if (principalId != null) {
                          grantPermission([principalId]);
                        }
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faUsers} className="mr-2" />
                    Create Group
                  </Button>
                </div>

                <PermissionsImporter
                  disabled={disabled}
                  folderType={
                    resource?.resourcetype === 1 ? resource?.foldertype : null
                  }
                />
              </div>
            </Card.Footer>
          </Card>
        )}
      </LoadingOverlay>
    </div>
  );
};

export default ResourceAccessManagement;
