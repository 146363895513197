import { useCallback, useMemo } from "react";
import _ from "lodash";
import useProject from "./useProject";
import { Property } from "cosmos-config/lib/property/property";
import useCurrentUser from "./useCurrentUser";
import Resource from "../types/resource";

const sanitizeValue = (v: any) => {
  if (Array.isArray(v) && v.length === 1) {
    return _.first(v);
  }

  return v;
};

const isEqual = (value1: any, value2: any) =>
  _.isEqual(sanitizeValue(value1), sanitizeValue(value2));

const includes = (arr: Array<any>, value: any) => {
  const sanitized = sanitizeValue(value);

  if (!Array.isArray(arr) || arr.length === 0) {
    return false;
  }

  if (Array.isArray(sanitized)) {
    return sanitized.every((d) => arr.includes(d));
  }

  return arr.includes(value);
};

/**
 * @module hooks/usePropertyRender
 * @category Hooks
 */

/**
 * @callback renderPropertyValue
 * @param {string} propertyName - Name of a property (which is passed as relevant
 * property in a hook options). Valueset of this property is loaded.
 * @param {string} value - Value of a property which valueset was loaded.
 * @returns {string} Label of the property value.
 */

/**
 * @callback renderPrivatePropertyValue
 * @param {string} propertyName - Name of a property (which is passed as relevant
 * property in a hook options). Valueset of this property is loaded.
 * @param {string} value - Value of a property which valueset was loaded.
 * @param {string} initialcreator - Document creator principal id so that access
 * permissions are calculated.
 * @param {string} department - Department code for which document was uploaded.
 * @returns {string} Label of the property value.
 */

/**
 * @typedef {object} UsePropertyRenderHook
 * @property {renderPropertyValue} render - Function to render property value into its label.
 * @property {renderPrivatePropertyValue} renderPrivate - Function to render private property.
 * Base on project configuration as it have a render method which is called by this callback.
 * Mostly as a result should be lock symbol with a color depending on the access rights
 * of the current user.
 * @property {renderPropertyValue} getKeywords - Retrieve keywords of a property valueset item.
 * Item keywords are used to extends filtering items in option lists by additional alternative names
 * and also for automatic matching when parsing metadata of file name.
 */

/**
 * Major hook to cover simple value -> label transition during rendering
 * of properties.
 *
 * @deprecated
 * @param {Property[]} relevantProperties
 * @returns {UsePropertyRenderHook}
 */
const usePropertyRenderPrivate = (relevantProperties: Property[]) => {
  const { resourcePermissions, userData, groupNames } = useCurrentUser();
  const { project } = useProject();

  const spocDepartments = useMemo(
    () => project?.spocDepartments || [],
    [project]
  );

  const propertiesMap = useMemo(
    () => _(relevantProperties).keyBy("name").value(),
    [relevantProperties]
  );

  const isSpoc = useCallback(
    (resource: Resource) => {
      const { Category: department, Segment: segment, objectclass } = resource;
      return (
        includes(spocDepartments, department) ||
        includes(spocDepartments, "$ALL$") ||
        (isEqual("LCA_SIGNEDDOC", objectclass) &&
          ((isEqual("O", segment) &&
            includes(groupNames || [], "FNC6P_Lawyer_OFJ")) ||
            (isEqual("E", segment) &&
              includes(groupNames || [], "FNC6P_Lawyer_EFJ"))))
      );
    },
    [spocDepartments, groupNames]
  );

  const isPrivate = useCallback(
    (resource: Resource) => {
      const {
        accesslevel,
        accesslevelexternal,
        parentaccesslevel,
        parentaccesslevelexternal,
        parentresourceid,
      } = resource;
      const domainName = userData?.domainName;
      const parentPermissions = resourcePermissions?.[parentresourceid];

      return (
        resource.private ||
        (accesslevel != null && accesslevel <= 3) ||
        (parentaccesslevel != null &&
          parentaccesslevel <= 3 &&
          !parentPermissions?.read) ||
        (domainName === "ExternalUsers" &&
          accesslevelexternal != null &&
          accesslevelexternal <= 3) ||
        (domainName === "ExternalUsers" &&
          parentaccesslevelexternal != null &&
          parentaccesslevelexternal <= 3 &&
          !parentPermissions?.read)
      );
    },
    [userData, resourcePermissions]
  );

  return useCallback(
    (resource: Resource) => {
      const { id, initialcreator } = resource;
      const privateResource = isPrivate(resource);

      if (propertiesMap?.private?.cellRender != null) {
        return (
          propertiesMap.private.cellRender(
            privateResource,
            "",
            resourcePermissions?.[id],
            isEqual(initialcreator, userData?.principalId),
            isSpoc(resource)
          ) || privateResource
        );
      }
      return "-";
    },
    [userData, resourcePermissions, propertiesMap.private, isSpoc, isPrivate]
  );
};

export default usePropertyRenderPrivate;
