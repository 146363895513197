import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Card } from "react-bootstrap";
import classNames from "classnames";
import useValueset from "../../../hooks/useValueset";
import SimpleTable from "../../SimpleTable";
import { Property } from "cosmos-config/lib/property/property";
import { DataTableGroup } from "cosmos-config/lib/group/dataTableGroup";
import { CosmosFormGroupProps } from "./CosmosFormGroup";

export interface DataTableFormGroupProps extends CosmosFormGroupProps {
  group: DataTableGroup;
}

const DataTableFormGroup = ({
  group,
  className,
  resource,
}: DataTableFormGroupProps) => {
  const [properties, setProperties] = useState<Property[]>([]);

  const { registerProperties } = useValueset();

  useEffect(() => {
    setProperties((oldProperties): Property[] => {
      return _(oldProperties)
        .map("name")
        .isEqual(_(group.properties).map("name").value())
        ? oldProperties
        : group.properties;
    });
  }, [group]);

  useEffect(() => {
    const propertiesMap = _.keyBy(properties, "name");
    registerProperties(propertiesMap);
  }, [properties, registerProperties]);

  const data = useMemo(
    () => _.get(resource, group?.path) || [],
    [resource, group]
  );

  if (data.length <= 0) {
    return null;
  }

  return (
    <Card
      border="dark"
      className={classNames(className)}
      key={`group-${group.name}`}
    >
      <SimpleTable
        className="mb-0"
        properties={properties}
        data={data}
        condensed={false}
        columnOptions={{
          filter: false,
        }}
      />
    </Card>
  );
};

DataTableFormGroup.propTypes = {
  className: PropTypes.string,
  group: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  defaultCollapse: PropTypes.bool,
  filter: PropTypes.shape({}),
  resource: PropTypes.shape({}),
};

DataTableFormGroup.defaultProps = {
  className: null,
  group: null,
  defaultCollapse: true,
  filter: {},
  resource: {},
};

export default DataTableFormGroup;
