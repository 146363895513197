/* eslint-disable react/jsx-props-no-spreading */
import { faChevronRight, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  MouseEvent,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useDebounce from "../../hooks/useDebounce";
import useContextMenu from "../../hooks/useContextMenu";
import useViewPlacement from "../../hooks/useViewPlacement";
import styled, { css } from "styled-components";
import { shadeColor } from "../../utils";
import { transparentize } from "polished";

type ContextMenuItemContainerProps = {
  disabled?: boolean;
  separator?: boolean;
};

const ContextMenuItemContainer = styled.div<ContextMenuItemContainerProps>`
  display: flex;
  align-items: center;
  padding: 0.6rem 10px;
  color: ${(props) => props.theme.body};
  user-select: none;

  ${(props) =>
    props.separator &&
    css`
      padding: 0;
      border-bottom: 1px solid
        ${(props) => shadeColor(0.1, props.theme.background)};
    `}

  cursor: pointer;

  &:hover {
    background-color: ${(props) => transparentize(0.9, props.theme.base)};
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${(props) => props.theme.muted};
      cursor: default;
      pointer-events: none;
    `};
`;

const ContextMenuItemsWrapper = styled.div`
  display: none;
`;

type ContextMenuActionsWrapperProps = {
  disabled?: boolean;
};

const ContextMenuActionsWrapper = styled.div<ContextMenuActionsWrapperProps>`
  ${(props) =>
    props.disabled &&
    css`
      color: #ccc;
      pointer-events: none;
    `}
`;

type ContextMenuContainerProps = {
  submenu?: boolean;
  active?: boolean;
};

const ContextMenuContainer = styled.div<ContextMenuContainerProps>`
  position: fixed;
  background-color: ${(props) => props.theme.background};
  z-index: 9;
  box-shadow: 0px 0px 8px 0px
    ${(props) => shadeColor(0.15, props.theme.background)};
  max-height: 98vh;
  overflow: auto;

  ${(props) =>
    props.active &&
    css`
      & > ${ContextMenuItemsWrapper} {
        display: block;
      }
    `}

  ${ContextMenuActionsWrapper} {
    padding: 1rem 10px;
    color: ${(props) => props.theme.primary};
    display: flex;
    justify-content: space-evenly;
    background-color: ${(props) => shadeColor(0.2, props.theme.background)};
  }

  @media (min-width: 768px) {
    border: 1px solid ${(props) => shadeColor(0.25, props.theme.background)};
    border-radius: 0.25rem;

    ${ContextMenuActionsWrapper} {
      display: none;
    }
  }

  @media (max-width: 767.98px) {
    left: 0 !important;
    width: 100%;

    ${(props) =>
      !props.submenu &&
      css`
        top: auto !important;
        bottom: 0;
      `}
  }
`;

const ContextMenuContext = createContext({
  collapse: () => {},
});

export type ContextMenuActionProps = {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

export const ContextMenuAction = ({
  children,
  onClick,
  disabled,
}: ContextMenuActionProps) => (
  <div
    role="button"
    tabIndex={0}
    onKeyDown={() => {}}
    onClick={onClick}
    className="fa-lg"
    // disabled={!!disabled}
  >
    {children}
  </div>
);

export interface ContextMenuItemProps {
  onClick?: (e: MouseEvent) => void;
  children?: React.ReactNode;
  separator?: boolean;
  disabled?: boolean;
}

export const ContextMenuItem = ({
  onClick,
  children,
  separator = false,
  disabled,
}: ContextMenuItemProps) => {
  const { collapse } = useContext(ContextMenuContext);

  return (
    <ContextMenuItemContainer
      separator={separator}
      disabled={disabled}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={(e: MouseEvent) => {
        collapse();
        if (onClick != null) {
          onClick(e);
        }
      }}
    >
      {!separator && children}
    </ContextMenuItemContainer>
  );
};

export interface ContextMenuSubMenuProps {
  children?: React.ReactNode;
  label?: string | (() => string);
  disabled?: boolean;
}

export const ContextMenuSubMenu = ({
  children,
  label,
  disabled,
}: ContextMenuSubMenuProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [mouseOverMenu, setMouseOverMenu] = useState(false);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  const [height, setHeight] = useState<number>(0);

  const childRef = useRef<HTMLDivElement | null>(null);
  const contextMenuRef = useRef<HTMLDivElement | null>(null);

  const active = useMemo(
    () => (showMenu || mouseOverMenu) && !disabled,
    [showMenu, mouseOverMenu, disabled]
  );
  const debouncedActive = useDebounce(active, 50);

  const { placement, recalculatePlacement } = useViewPlacement({
    placement: "bottom",
    height,
  });

  useEffect(() => {
    if (debouncedActive) {
      setHeight((current) => {
        const h = contextMenuRef.current?.offsetHeight || 0;
        return h !== current ? h : current;
      });

      const bounding = childRef.current?.getBoundingClientRect();
      if (bounding != null) {
        recalculatePlacement(bounding.y);
      }
    }
  }, [debouncedActive, recalculatePlacement]);

  useEffect(() => {
    const bounding = childRef.current?.getBoundingClientRect();

    if (contextMenuRef.current == null || bounding == null) {
      return;
    }

    if (placement === "top") {
      const height = contextMenuRef.current.offsetHeight;
      setPosition({
        x: bounding.right,
        y: bounding.top - height + (childRef.current?.offsetHeight || 0),
      });
    } else {
      setPosition({
        x: bounding.right,
        y: bounding.top,
      });
    }
  }, [placement, debouncedActive]);

  return (
    <>
      <ContextMenuItemContainer
        ref={childRef}
        onMouseEnter={() => {
          setShowMenu(true);
        }}
        onMouseLeave={() => setShowMenu(false)}
        disabled={disabled}
      >
        <span className="mr-2">
          {typeof label === "function" ? label() : label}
        </span>
        <FontAwesomeIcon icon={faChevronRight} className="ml-auto" />
      </ContextMenuItemContainer>
      <ContextMenuContainer
        ref={contextMenuRef}
        submenu
        active={debouncedActive}
        onMouseEnter={() => setMouseOverMenu(true)}
        onMouseLeave={() => setMouseOverMenu(false)}
        style={{
          top: position.y,
          left: position.x,
        }}
      >
        <ContextMenuItemsWrapper>{children}</ContextMenuItemsWrapper>
      </ContextMenuContainer>
    </>
  );
};

export type OnOpenParam = {
  x: number;
  y: number;
};

export interface ContextMenuProps {
  renderMenu?: (callback: () => void) => React.ReactNode;
  renderActions?: () => React.ReactNode;
  children?: React.ReactNode;
  disabled?: boolean;
  onOpen?: (param: OnOpenParam) => void;
  className?: string;
}

const ContextMenu = ({
  renderMenu,
  renderActions,
  children,
  disabled,
  onOpen,
  className,
}: ContextMenuProps) => {
  const childRef = useRef<HTMLDivElement | null>(null);
  const contextMenuRef = useRef<HTMLDivElement | null>(null);

  const { xPos, yPos, showMenu, toggleShowMenu } = useContextMenu(
    childRef,
    contextMenuRef
  );

  useEffect(() => {
    if (showMenu && childRef.current != null) {
      const rect = childRef.current.getBoundingClientRect();
      const x = xPos - rect.left;
      const y = yPos - rect.top;

      if (onOpen != null) {
        onOpen({
          x,
          y,
        });
      }
    }
  }, [showMenu, xPos, yPos, onOpen]);

  if (disabled) {
    return <div className={className}>{children}</div>;
  }

  return (
    <>
      <div ref={childRef} className={className}>
        {children}
      </div>
      <ContextMenuContainer
        ref={contextMenuRef}
        active={showMenu}
        style={{
          top: yPos,
          left: xPos,
        }}
      >
        <ContextMenuContext.Provider
          value={{
            collapse: () => toggleShowMenu(false),
          }}
        >
          <ContextMenuItemsWrapper>
            {showMenu &&
              renderMenu != null &&
              renderMenu(() => toggleShowMenu(false))}
          </ContextMenuItemsWrapper>
          <ContextMenuActionsWrapper>
            {renderActions != null && renderActions()}

            <ContextMenuAction onClick={() => toggleShowMenu()}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </ContextMenuAction>
          </ContextMenuActionsWrapper>
        </ContextMenuContext.Provider>
      </ContextMenuContainer>
    </>
  );
};

ContextMenu.Item = ContextMenuItem;
ContextMenu.Action = ContextMenuAction;
ContextMenu.SubMenu = ContextMenuSubMenu;

export default ContextMenu;
