import { Accordion } from "cosmos-components";
import _ from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import localeApi from "../apis/locale.api";
import TranslationsExportImport from "./TranslationsExportImport";
import TranslationScope from "./TranslationScope";
import LocaleEditorProvider from "./LocaleEditorProvider";

type TranslationModule = {
  translationsType: string;
  name: string;
};

const LocaleEditor = () => {
  const [editingTranslations, setEditingTranslations] =
    useState<TranslationModule | null>(null);
  const [modules, setModules] = useState([]);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    localeApi.getLocalesInfo().then((info) => {
      if (info != null) {
        const { module, property } = info;
        setModules(module || []);
        setProperties(property || []);
      }
    });
  }, []);

  const renderTranslationsEditor = () => {
    if (editingTranslations != null) {
      const type = editingTranslations.translationsType;
      return <TranslationScope scope={type} name={editingTranslations.name} />;
    }
  };

  const renderNavigation = (translationsType: string, names: string[]) => (
    <Nav className="flex-column py-3" variant="pills">
      {names.map((name) => (
        <Nav.Item>
          <Nav.Link
            active={
              editingTranslations?.translationsType === translationsType &&
              editingTranslations?.name === name
            }
            onClick={() =>
              setEditingTranslations({
                translationsType,
                name,
              })
            }
          >
            {translationsType === "property" ? name : _.capitalize(name)}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );

  return (
    <div className="position-relative overflow-hidden h-100">
      <LocaleEditorProvider>
        <Row className="h-100">
          <Col lg={4} xl={3} className="h-100 overflow-auto">
            <Accordion variant="primary">
              {/* @ts-ignore */}
              <Accordion.Content title="General">
                {renderNavigation("module", modules)}
              </Accordion.Content>
              {/* @ts-ignore */}
              <Accordion.Content title="Module">
                {renderNavigation("property", properties)}
              </Accordion.Content>
            </Accordion>
          </Col>
          <Col lg={8} xl={9} className="h-100">
            <div className="d-flex flex-column h-100">
              {editingTranslations != null &&
                editingTranslations.translationsType != null &&
                editingTranslations.name != null && (
                  <div className="border-bottom pb-3 d-flex">
                    <TranslationsExportImport
                      className="ml-auto"
                      translationsType={editingTranslations.translationsType}
                      name={editingTranslations.name}
                    />
                  </div>
                )}
              <div className="overflow-hidden flex-grow-1">
                {renderTranslationsEditor()}
              </div>
            </div>
          </Col>
        </Row>
      </LocaleEditorProvider>
    </div>
  );
};

export default LocaleEditor;
