import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { ContextMenu, OnChangeParam } from "cosmos-components";
import { resourceCorrectionOfGateway } from "cosmos-config/utils";
import FormGroupBase from "./FormGroupBase";
import SimpleTable from "../../SimpleTable";
import FormProperty from "../FormProperty";
import { Property } from "cosmos-config/lib/property/property";
import Resource from "../../../types/resource";
import { CosmosFormGroupProps } from "./CosmosFormGroup";

type MultiAttributeGroupOnChangeParam = {
  multiValueIndex: string;
} & OnChangeParam<any>;

type onRemoveParam = {
  names: string[];
  multiValueIndexes: string[];
};

export interface MultiAttributeGroupProps extends CosmosFormGroupProps {
  onChange?: (param: MultiAttributeGroupOnChangeParam) => void;
  wrapped?: boolean;
  onRemove?: (patam: onRemoveParam) => void;
}

const MultiAttributeGroup = ({
  className,
  group,
  defaultCollapse,
  resource,
  onChange,
  wrapped,
  onRemove,
}: MultiAttributeGroupProps) => {
  const [properties, setProperties] = useState<Property[]>([]);

  useEffect(() => {
    if (group != null) {
      const newProperties = group.properties.filter(
        (c) => !c.isHidden(resource || {})
      );

      setProperties((oldProperties) => {
        return _(oldProperties)
          .map("name")
          .isEqual(_(newProperties).map("name").value())
          ? oldProperties
          : newProperties;
      });
    }
  }, [group, resource]);

  const data = useMemo(() => {
    const scoped = _.pick(resource, _.map(properties, "name"));

    const itemsCount =
      _(scoped)
        .valuesIn()
        .map((a) => (Array.isArray(a) ? a?.length : 0))
        .max() || 0;

    const plasticProperties = properties.map((p) => {
      if (p.multiple || p.handleAsMulti) {
        return {
          ...p,
          multiple: false,
          handleAsMulti: false,
        };
      }

      return p;
    });

    const result = _.range(itemsCount + 1).map((id) => {
      const item = _.mapValues(scoped, (valueArray) => {
        return _.get(valueArray, id);
      });

      const correctedItem = resourceCorrectionOfGateway(
        item,
        plasticProperties
      );

      return {
        ...correctedItem,
        parentresourceid: _.compact([resource?.parentresourceid]),
        id,
      };
    });

    return result;
  }, [resource, properties]);

  const propertiesMap = useMemo(
    () => _.keyBy(properties, "name"),
    [properties]
  );

  const renderMenu = (selectedItems: any[]) => {
    return (
      <>
        <ContextMenu.Item
          disabled={selectedItems.length === 0}
          onClick={() => {
            if (onRemove != null) {
              onRemove({
                names: properties.map((p) => p.name),
                multiValueIndexes: selectedItems.map((i) => i.id),
              });
            }
          }}
        >
          Delete {group?.label}
        </ContextMenu.Item>
      </>
    );
  };

  const renderCell = useCallback(
    (propertyName: string, value: any, label: string, item: Resource) => {
      const property = propertiesMap[propertyName];

      if (property != null) {
        return (
          <FormProperty
            id={`${property.name}_${item.id}`}
            className="border-0"
            value={Array.isArray(value) ? _.compact(value) : value}
            property={property}
            filter={item}
            controlOnly
            onChange={({ name, value }) => {
              if (onChange != null) {
                onChange({ name, value, multiValueIndex: item.id });
              }
            }}
          />
        );
      }

      return label;
    },
    [onChange, propertiesMap]
  );

  const renderTable = () => (
    <SimpleTable
      properties={properties}
      data={data}
      renderCell={renderCell}
      renderContextMenu={renderMenu}
    />
  );

  if (wrapped) {
    return (
      <FormGroupBase
        group={group}
        defaultCollapse={defaultCollapse}
        className={className}
      >
        {renderTable()}
      </FormGroupBase>
    );
  }

  return renderTable();
};

MultiAttributeGroup.defaultProps = {
  className: null,
  defaultCollapse: true,
  filter: null,
  resource: null,
  onChange: () => {},
  wrapped: true,
  onRemove: () => {},
};

MultiAttributeGroup.propTypes = {
  className: PropTypes.string,
  group: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    translation: PropTypes.string,
  }).isRequired,
  defaultCollapse: PropTypes.bool,
  filter: PropTypes.shape({}),
  resource: PropTypes.shape({
    parentresourceid: PropTypes.string,
  }),
  onChange: PropTypes.func,
  wrapped: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default MultiAttributeGroup;
