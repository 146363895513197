/* eslint-disable func-names */
import JSZip from 'jszip';
import { DateTime } from 'luxon';
import { PublicClientApplication } from '@azure/msal-browser';
import {
  all,
  cancel,
  fork,
  put,
  select,
  spawn,
  take,
  call,
} from 'redux-saga/effects';
import {
  setHomeAccountId,
  setLoadingRenditionSharepoint,
  setLoadingSharepoint,
  setRenditionSharepoint,
  setSearchResults,
  setSharepointDrives,
  logoutSharepoint as logoutSharepointAction,
} from '../Actions/sharepoint';
import sharepointApi from '../Api/sharepoint';
import * as actions from '../Actions/types';
import { importFiles } from '../Actions/repository';

import { notify } from '../Actions/ui';
import _ from 'lodash';
import callBatch from './Effects/callBatch';
import { channel } from 'redux-saga';

const clientId = process.env.REACT_APP_GRAPH_API_CLIENT_ID;
const authority = process.env.REACT_APP_GRAPH_API_AUTHORITY;
const redirectUri = process.env.REACT_APP_GRAPH_API_REDIRECT_URL;

const msalConfig = {
  auth: {
    clientId,
    authority,
    redirectUri,
  },
};

const loginRequest = {
  scopes: [
    'User.Read',
    'Files.Read',
    'Files.Read.All',
    // 'Mail.Send',
    // 'Mail.Read',
    'Files.ReadWrite',
    'Files.ReadWrite.All',
    'Sites.Read.All',
    'Team.ReadBasic.All',
  ],
};

function* loginSharepoint(msalInstance) {
  try {
    const response = yield call(
      [msalInstance, msalInstance.loginPopup],
      loginRequest
    );

    if (response != null) {
      const { account, accessToken } = response;

      localStorage.setItem('msalAccessToken', accessToken);

      yield put(setHomeAccountId(account.homeAccountId));
      // msalInstance.setActiveAccount(account);
    }
  } catch (err) {
    yield put(
      notify(
        `Error has occured during sharepoint login. Reason: ${err.message}.`,
        'error'
      )
    );
  }
}

function* logoutSharepoint() {
  yield put(setHomeAccountId(null));
  localStorage.removeItem('msalAccessToken');
}

function* renewAccessToken(msalInstance) {
  const homeAccountId = yield select((state) => state.sharepoint.homeAccountId);
  const account = msalInstance.getAccountByHomeId(homeAccountId);

  if (account != null) {
    try {
      localStorage.removeItem('msalAccessToken');

      const { accessToken } = yield call(
        [msalInstance, msalInstance.acquireTokenSilent],
        { ...loginRequest, account }
      );
      localStorage.setItem('msalAccessToken', accessToken);
      return accessToken;
    } catch (err) {
      console.error(err);
    }
  }

  yield put(logoutSharepointAction());

  return null;
}

const latestSharepointApi = (patternOrChannel, msalInstance, saga, ...args) =>
  fork(function* latestSharepoint() {
    let lastTask;
    while (true) {
      const action = yield take(patternOrChannel);
      if (lastTask) {
        yield cancel(lastTask);
      }

      lastTask = yield fork(function* renewFlow() {
        yield put(setLoadingSharepoint(true));

        try {
          yield call(saga, ...args.concat(action));
        } catch (error) {
          const { code } = error;
          if (code === 401) {
            const token = yield call(renewAccessToken, msalInstance);
            if (token != null) {
              yield call(saga, ...args.concat(action));
            }
          } else {
            // throw error;
            yield put(notify(error.message, 'error'));
          }
        } finally {
          yield put(setLoadingSharepoint(false));
        }
      });
    }
  });

function* searchFiles({ searchQuery }) {
  if (searchQuery != null && searchQuery !== '') {
    const items = yield call(sharepointApi.searchInUserFiles, searchQuery);
    const files = items.filter((i) => i.folder == null);
    yield put(setSearchResults(files));
  } else {
    yield put(setSearchResults([]));
  }
}

function* downloadContent(file) {
  const {
    id,
    parentReference: { driveId },
  } = file;
  const blob = yield call(sharepointApi.getFileContent, driveId, id);
  blob.name = file.name;

  // const hash = yield call(calculateMd5, blob);

  return blob;
}

// function* fetchRendition({ driveId, itemId }) {
//   const blob = yield call(sharepointApi.getFileContent, driveId, itemId);
//   const blobUrl = URL.createObjectURL(blob);

//   const hash = yield call(calculateMd5, blob);
//   console.warn(hash);

//   yield put(setRenditionSharepoint(blobUrl));
//   return blobUrl;
// }

export default function* sharepointSaga() {
  const msalInstance = new PublicClientApplication(msalConfig);

  while (true) {
    const homeAccountId = yield select(
      (state) => state.sharepoint.homeAccountId
    );

    if (homeAccountId != null) {
      try {
        // yield call(renewAccessToken, msalInstance);
        // const result = yield call(sharepointApi.getUserFiles, 'Trumpet');
        // const result = yield call(
        //   sharepointApi.getDriveFiles,
        //   'b!Qw3avxY7HU2AVR2yI6d-9-iscp39lmdIr1wHED6bjQYWPjx5KhUQSqfaYnlEK_jc'
        // );
        // console.log(result);
      } catch (err) {
        console.error(err);
      }

      const sharepointTasks = yield all([
        latestSharepointApi(
          actions.sharepoint.SEARCH_FILES_SHAREPOINT,
          msalInstance,
          searchFiles
        ),
        latestSharepointApi(
          [
            actions.repository.UPDATE_SEARCH_QUERY,
            actions.repository.CLEAR_REPOSITORY_QUERY,
          ],
          msalInstance,
          searchFiles
        ),
        latestSharepointApi(
          actions.sharepoint.SELECT_DOCUMENTS_SHAREPOINT,
          msalInstance,
          function* selectDocuments({ documents }) {
            if (documents.length > 0) {
              yield fork(function* selectionFlow() {
                while (true) {
                  const { type, callback: imDowCallback } = yield take([
                    actions.sharepoint.IMPORT_FROM_SHAREPOINT,
                    actions.sharepoint.DOWNLOAD_FROM_SHAREPOINT,
                  ]);

                  const blobs = yield all(
                    documents.map((d) => call(downloadContent, d))
                  );

                  if (type === actions.sharepoint.IMPORT_FROM_SHAREPOINT) {
                    yield put(importFiles(blobs));
                  } else if (blobs.length === 1) {
                    const [blob] = blobs;
                    const contentUrl = URL.createObjectURL(blob);
                    yield spawn(imDowCallback, contentUrl, blob.name);
                  } else {
                    const zip = new JSZip();
                    blobs.forEach((blob) => {
                      zip.file(blob.name, blob);
                    });
                    const resultBlob = yield call([zip, zip.generateAsync], {
                      type: 'blob',
                    });
                    const contentUrl = URL.createObjectURL(resultBlob);
                    yield spawn(
                      imDowCallback,
                      contentUrl,
                      `sharepoint_${DateTime.now().toSQLDate()}.zip`
                    );
                  }
                }
              });
            }

            const [first] = documents;
            if (first != null) {
              yield put(setLoadingRenditionSharepoint());
              const blob = yield call(downloadContent, first);
              const blobUrl = URL.createObjectURL(blob);
              yield put(setRenditionSharepoint(blobUrl));
              yield put(setLoadingRenditionSharepoint(false));
            }
          }
        ),
        latestSharepointApi(
          actions.sharepoint.FETCH_SHAREPOINT_ITEM_CHILDREN,
          msalInstance,
          function* ({ driveId, itemId, callback }) {
            const items = yield call(
              sharepointApi.getDriveItemChildren,
              driveId,
              itemId
            );

            yield spawn(callback, items);
          }
        ),
        latestSharepointApi(
          actions.sharepoint.FETCH_SHAREPOINT_DRIVES,
          msalInstance,
          function* ({ callback }) {
            // const teams = yield call(sharepointApi.getUserJoinedTeams);
            // const teamDriveSagas = teams.map((team) =>
            //   // eslint-disable-next-line redux-saga/yield-effects
            //   call(sharepointApi.getGroupDrive, team.id)
            // );
            // const teamDrives = yield all(teamDriveSagas);

            // const groups = yield call(sharepointApi.getUserMemberOf);

            // const groupsDrivesSagas = groups.map((g) =>
            //   // eslint-disable-next-line redux-saga/yield-effects
            //   call(function* () {
            //     try {
            //       return yield call(sharepointApi.getGroupDrive, g.id);
            //       // eslint-disable-next-line no-empty
            //     } catch (err) {}

            //     return null;
            //   })
            // );

            // const gruopsDrives = yield all(groupsDrivesSagas);
            const userDrives = yield call(sharepointApi.getUserDrives);

            // const drives = _(userDrives).concat(gruopsDrives).compact().value();

            yield put(setSharepointDrives(userDrives));

            yield spawn(callback, userDrives);
          }
        ),
        latestSharepointApi(
          actions.sharepoint.SEARCH_SHAREPOINT_SITES,
          msalInstance,
          function* ({ query, callback }) {
            const result = yield call(sharepointApi.searchSites, `${query}*`);
            yield spawn(callback, result);
          }
        ),
        latestSharepointApi(
          actions.sharepoint.GET_SHAREPOINT_SITE_DRIVE,
          msalInstance,
          function* ({ siteId, callback }) {
            const drive = yield call(sharepointApi.getSiteDrive, siteId);
            yield put(setSharepointDrives([drive]));
            yield spawn(callback, drive);
          }
        ),
        latestSharepointApi(
          actions.sharepoint.DOWNLOAD_SHAREPOINT_DRIVE_ITEMS,
          msalInstance,
          function* ({ driveId, itemId, query, callback, progressCallback }) {
            const results = yield call(
              sharepointApi.searchDriveItems,
              driveId,
              itemId,
              query
            );

            let foldersMap = _(results).reject('file').keyBy('id').value();

            const buildPath = (folderId) => {
              const folder = foldersMap[folderId];

              if (folder != null) {
                return `${buildPath(folder.parentReference?.id)}/${
                  folder.name
                }`;
              }

              return '';
            };

            const foldersPaths = _.mapValues(foldersMap, (folder, id) =>
              buildPath(id)
            );

            const documents = _(results).filter('file').value();

            const progressChannel = yield channel();
            yield fork(function* () {
              let importedDocumentIds = [];

              while (true) {
                const { type, document } = yield take(progressChannel);

                if (type === 'DOCUMENT_IMPORTED') {
                  importedDocumentIds = [...importedDocumentIds, document.id];
                  yield spawn(
                    progressCallback,
                    importedDocumentIds,
                    documents.length
                  );
                }
              }
            });

            const documentsSagas = documents.map((d) =>
              call(function* () {
                const blob = yield call(downloadContent, d);

                progressChannel.put({
                  type: 'DOCUMENT_IMPORTED',
                  document: d,
                });

                const parentId = d.parentReference?.id;
                const path = foldersPaths[parentId];
                blob.webkitRelativePath = _.trimStart(`${path}/${d.name}`, '/');
                return blob;
              })
            );

            const data = yield callBatch(5, documentsSagas);

            yield spawn(callback, data);
          }
        ),
      ]);

      // console.warn(yield call(sharepointApi.getUserEmails));

      yield take(actions.sharepoint.LOGOUT_SHAREPOINT);
      yield call(logoutSharepoint, msalInstance);
      yield cancel(sharepointTasks);
    }

    yield take(actions.sharepoint.LOGIN_SHAREPOINT);
    yield call(loginSharepoint, msalInstance);
  }
}
