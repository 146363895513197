import React, { useCallback, useContext, useState } from "react";
import FolderTypeResourcePicker from "../../../components/FolderTypeResourcePicker";
import _ from "lodash";
import { Button } from "react-bootstrap";
import { LoadingOverlay, OnChangeParam } from "cosmos-components";
import AccessManagementContext, {
  ImmediatePermission,
} from "../contexts/AccessManagementContext";
import { Permission } from "../constants/Permission";
import AccessManagementProvider from "../providers/AccessManagementProvider";
import SimpleAccessTable from "./SimpleAccessTable";
import { resourceCorrectionForGateway } from "cosmos-config/utils";
import resourcePermissionProperties from "../constants/resourcePermissions";

type ChangeableProps = {
  onChange?: (param: OnChangeParam<Permission[]>) => void;
};

type FolderPermissionsImporterPickerProps = {
  resourceId?: string;
} & ChangeableProps;

const FolderPermissionsImporterPicker = ({
  resourceId,
  onChange,
}: FolderPermissionsImporterPickerProps) => {
  const [resultsCount, setResultsCount] = useState<number>(0);

  if (resourceId != null) {
    return (
      <AccessManagementProvider resourceId={resourceId}>
        <div className="mt-3">
          {resultsCount > 0 ? (
            <p>
              <b>Preview:</b>
            </p>
          ) : (
            <p className="text-center  text-muted">Nothing to import</p>
          )}

          <SimpleAccessTable
            className="mb-3"
            selectable
            disabled
            onSelectionChanged={(permissions) => {
              setResultsCount(permissions.length);

              if (onChange != null) {
                onChange({ value: permissions });
              }
            }}
          />
        </div>
      </AccessManagementProvider>
    );
  }

  return null;
};

export interface ModuleFolderPermissionsImporterProps {
  folderType?: string;
  onImportComplete?: () => void;
}

const ModuleFolderPermissionsImporter = ({
  folderType,
  onImportComplete,
}: ModuleFolderPermissionsImporterProps) => {
  const { importPermissions, loading } = useContext(AccessManagementContext);

  const [selectedResourceId, setSelectedResourceId] = useState<
    string | undefined
  >();
  const [permissions, setPermissions] = useState<ImmediatePermission[]>([]);

  const handleChange = useCallback(({ value }: OnChangeParam<Permission[]>) => {
    if (value != null) {
      const preparedPermissions = value.map(
        (r) =>
          _.omit(
            resourceCorrectionForGateway(r, resourcePermissionProperties),
            ["id", "resourceId", "permittedusercommonname"]
          ) as ImmediatePermission
      );

      setPermissions(preparedPermissions);
    }
  }, []);

  return (
    <div className="position-relative">
      <LoadingOverlay loading={loading}>
        <FolderTypeResourcePicker
          boxed
          folderType={folderType}
          onChange={({ value }) => setSelectedResourceId(_.first(value))}
          value={_.compact([selectedResourceId])}
        />

        <FolderPermissionsImporterPicker
          resourceId={selectedResourceId}
          onChange={handleChange}
        />

        <div className="text-right">
          <Button
            disabled={permissions.length <= 0}
            onClick={() => {
              importPermissions(permissions).then(() => {
                if (onImportComplete != null) {
                  onImportComplete();
                }
              });
            }}
          >
            Import
          </Button>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default ModuleFolderPermissionsImporter;
