import styled, { css } from "styled-components";
import { shadeColor } from "../utils";

const borderBottomStyle = css`
  border: none;
  border-bottom: 1px solid
    ${(props) => shadeColor(0.15, props.theme.background)};
  background: none;
  box-shadow: none;

  &:focus {
    border-color: ${(props) => props.theme.primary};
  }
`;

const boxedStyle = css`
  border-radius: 0.25rem;
  border: 1px solid ${(props) => shadeColor(0.15, props.theme.background)};
  background-color: ${(props) => props.theme.background};
`;

export interface FormControlProps {
  boxed?: boolean;
  disabled?: boolean;
  condensed?: boolean;
}

export const FormControlContent = styled.div``;

export const FormControlFilter = styled.input``;

export const FormControlField = styled.input``;

export const FormControlAction = styled.span``;

const disabledStyle = css`
  //should be greyed out
  background: none;

  ${FormControlContent} {
    color: lighten(#000, 60%);
    cursor: default;

    /* input.panco-input-field {
      color: lighten(#000, 60%);
      background: none;
    }

    .panco-select-badge,
    .value-picker-content-item {
      border-color: lighten(#000, 60%);
    } */
  }
`;

const FormControl = styled.div<FormControlProps>`
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  position: relative;
  padding: ${(props) =>
    props.condensed ? "0.2rem 0.75rem" : "0.375rem 0.75rem"};
  min-width: 100px;

  ${(props) => (props.boxed ? boxedStyle : borderBottomStyle)}

  &.has-error {
    border-color: #e7343f;
    background-color: rgba(#e7343f, 0.1);

    ${FormControlAction} {
      color: #e7343f;
    }
  }

  ${(props) => props.disabled && disabledStyle}

  ${FormControlContent} {
    display: flex;
    flex-grow: 1;

    height: 100%;
    color: ${(props) => props.theme.base};
    position: relative;
    cursor: text;
    min-height: calc(1.5em + 1px);

    flex-wrap: wrap;
    row-gap: 5px;

    .placeholder {
      color: ${(props) => props.theme.base};
      opacity: 0.5;
    }

    ${FormControlField}, ${FormControlFilter} {
      border: none;
      width: 100%;
      background: ${(props) => props.theme.background};
      color: ${(props) => props.theme.base};
      opacity: 0;
      max-width: 100%;
      z-index: 1;
      pointer-events: none;

      &.has-focus {
        opacity: 1;
        pointer-events: all;
      }

      &:focus {
        outline: none;
      }
    }

    ${FormControlFilter} {
      position: absolute;
    }

    ${FormControlField} {
      flex-grow: 1;
      flex-basis: 10%;
    }
  }

  ${FormControlAction} {
    margin-left: auto;
    color: ${(props) => props.theme.primary};
    cursor: pointer;
  }
`;

export default FormControl;
