import { Permission } from "../constants/Permission";
import { useMutation } from "@tanstack/react-query";
import resourcePermissionsApi from "../apis/resourcePermissionsApi";
import { resourceCorrectionForGateway } from "cosmos-config/utils";
import permissionConstant from "../constants/resourcePermissions";

type batchMutationVariable = {
  principalIds: string[];
  permission?: Partial<Permission>;
};

export interface UseAccessManagementHook {
  loading: boolean;
  grantPermissionsBatch: (
    principalIds: string[],
    permission: Partial<Permission>
  ) => Promise<any>;
  revokePermissionsBatch: (principalIds: string[]) => Promise<any>;
}

export interface UseAccessManagementProps {
  resourceIds: string[];
}

const useAccessManagement = ({
  resourceIds,
}: UseAccessManagementProps): UseAccessManagementHook => {
  const grantBatchMutation = useMutation({
    mutationFn: ({ principalIds, permission }: batchMutationVariable) => {
      const correctPermission = resourceCorrectionForGateway(
        permission,
        permissionConstant
      ) as Permission;
      return resourcePermissionsApi.grantAll(
        resourceIds,
        principalIds,
        correctPermission
      );
    },
  });

  const revokeBatchMutation = useMutation({
    mutationFn: ({ principalIds }: batchMutationVariable) => {
      return resourcePermissionsApi.revokeAll(resourceIds, principalIds);
    },
  });

  return {
    loading: grantBatchMutation.isPending || revokeBatchMutation.isPending,
    grantPermissionsBatch: (principalIds, permission) => {
      return grantBatchMutation.mutateAsync({
        principalIds,
        permission,
      });
    },
    revokePermissionsBatch: (principalIds) => {
      return revokeBatchMutation.mutateAsync({
        principalIds,
      });
    },
  };
};

export default useAccessManagement;
