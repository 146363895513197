import { createSelector } from 'reselect';

export const getFilterFolder = (state) => state.filterFolder.filterFolder || {};

export const isFilterFolderActive = createSelector(
  getFilterFolder,
  (filterFolder) => {
    return filterFolder != null && Object.keys(filterFolder).length > 0;
  }
);
