/* eslint-disable no-param-reassign, no-underscore-dangle */
import isString from "lodash/isString";
import getValueQueryRepr from "./getValueQueryRepr";
import { multiContains } from ".";

type WhereCondition = {
  name: string;
  value: string | Function;
  multi: boolean;
  multiMode?: "AND" | "OR";
  negate: boolean;
};

export interface NqlWhereClause {
  where(clauses: Record<string, any>, groupOp?: string): NqlWhereClause;
  orWhere(clauses: Record<string, any>, groupOp?: string): NqlWhereClause;
  toString(): string;
}

class Where implements NqlWhereClause {
  whereString: string | null = null;

  private handleWhere(clauses = {}, op: string) {
    return Object.entries(clauses)
      .map(([propName, value]) => {
        if (typeof value === "function") {
          return `${propName}${value()}`;
        }

        return `${propName}=${getValueQueryRepr(value)}`;
      })
      .join(` ${op} `);
  }

  private handleWhereArray(arr: Array<WhereCondition> = [], op: string) {
    return arr
      .map(({ name, value, multi, multiMode, negate }) => {
        if (Array.isArray(value) && multi && multiMode === "AND") {
          const exp = value.map((v) => multiContains(name, v)()).join(" & ");
          return negate ? `not ${exp}` : exp;
        }

        let condition = `${name}=${getValueQueryRepr(value)}`;

        // value ist hier bereits "in ("DE", "GB")" --> Countries in ("DE", "GB")
        if (typeof value === "function") {
          condition = `${name}${value()}`;
        }

        const exp = multi ? `exists(${condition})` : condition;
        return negate ? `not ${exp}` : exp;
      })
      .join(` ${op} `);
  }

  private _generateWhereString(
    clauses: Record<string, any> | Array<WhereCondition>,
    op: string
  ) {
    if (isString(clauses)) {
      return clauses;
    }

    if (Array.isArray(clauses)) {
      return this.handleWhereArray(clauses, op);
    }

    return this.handleWhere(clauses, op);
  }

  private _setWhere(whereString: string, op: string) {
    if (whereString?.length > 0) {
      if (this.whereString != null && this.whereString.length > 0) {
        this.whereString += ` ${op} (${whereString})`;
      } else {
        this.whereString = `(${whereString})`;
      }
    }
  }

  where(clauses = {}, groupOp = "&") {
    if (clauses != null) {
      const op = "&";
      this._setWhere(this._generateWhereString(clauses, op), groupOp);
    }
    return this;
  }

  orWhere(clauses = {}, groupOp = "|") {
    if (clauses != null) {
      const op = "|";
      this._setWhere(this._generateWhereString(clauses, op), groupOp);
    }

    return this;
  }

  toString() {
    if (this.whereString == null) {
      return "";
    }

    return "" + this.whereString;
  }
}

export default (whereClauses = {}, operator = "&"): NqlWhereClause => {
  const whereObj = new Where();

  if (operator === "&") {
    whereObj.where(whereClauses);
  } else if (operator === "|") {
    whereObj.orWhere(whereClauses);
  }

  return whereObj;
};
