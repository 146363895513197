import { useContext } from "react";
import ColumnsContext from "../contexts/ColumnsContext";

/**
 * @module hooks/useColumns
 * @category Hooks
 */

/**
 * @typedef {object} TableColumn
 * @property {string} Header - Header of the table column.
 * @property {string} accessor - Property name as column accessor.
 * @property {string} sortType - Type of the column sorting.
 * @property {boolean} information - Whether property is informational for the resource.
 * @property {boolean} sortable - Whether column is sortable.
 * @property {function} cellRender - Cell rendering function.
 * @property {function} render - Function to render property value.
 * @property {string} type - Type of the property.
 * @property {string} valuesetName - Name of the valueset of the property.
 * @property {string} valuesetSource - Type of the valueset source.
 * @property {function} format - Property value formatting function.
 * @property {string} name - Name of the property.
 * @property {string} description - Description of the property.
 * @property {string} columnWidth - Width of the table column.
 * @property {string} masterValuesetName - Name of the master valueset.
 * @property {object[]} options - List of property valueset options.
 * @property {string} translation - Property translation path string.
 * @property {boolean} defaultSort - Whether column is sorted by default.
 */

/**
 * @typedef {object} TableColumnOrder
 * @property {string} id - Property name/Column accessor that should be visible.
 * @property {number} width - Table column width.
 */

/**
 * @callback prepareCustomPropertiesCallback
 * @param {Property[]} customProperties - List of properties that should be turned into table columns.
 * @param {object} options
 * @param {boolean} options.alternate - Whether use property alternate name and label.
 * @param {boolean} options.filter - Whether apply properties filtering. Default is true.
 * When filter is enabled only (tableColumn = true) and (!system) properties are displayed.
 * @returns {TableColumn[]} Table columns.
 */

/**
 * @typedef {object} UseColumnsHook
 * @property {TableColumn[]} tableColumns - List of all table columns for home page.
 * @property {TableColumn[]} documentTableColumns - List of all document table columns.
 * @property {TableColumnOrder[]} documentTableColumnOrder - Column order for documents view.
 * @property {prepareCustomPropertiesCallback} prepareCustomProperties - Callback function
 * for transfering project properties into table columns.
 * @property {TableColumn[]} taskHistoryColumns - Columns of task history table.
 * @property {TableColumnOrder[]} defaultColumnOrder - Default table column order.
 */

/**
 * This hooks creates an access into column context which supplies sub-components
 * with project table columns and functions to properly prepare table columns
 * for react-table component.
 * @returns {UseColumnsHook}
 */
const useColumns = () => {
  return useContext(ColumnsContext);
};

export default useColumns;
