import * as actions from './types';

/**
 * Websocket action generators.
 * @module actions/websocket
 * @category Actions
 */

/**
 * Action to subscribe comments.
 * @function
 * @param {} resourceId - id of the resource
 */
export const subscribeComments = (resourceId) => ({
  type: actions.websocket.WS_SUBSCRIBE_COMMENTS,
  resourceId,
});

/**
 * Action for received comment.
 * @function
 * @param {} comment -
 */
export const receivedComment = (comment) => ({
  type: actions.websocket.WS_RECEIVED_COMMENT,
  comment,
});

/**
 * Action for reloading websocket.
 * @function
 */
export const reloadWebsocket = () => ({
  type: actions.websocket.WS_RELOAD,
});

/**
 * Action to subscribe resource events.
 * @function
 * @param {} resourceId - id of the resource
 */
export const subscribeResourceEvents = (resourceId) => ({
  type: actions.websocket.WS_SUBSCRIBE_RESOURCE_EVENTS,
  resourceId,
});
