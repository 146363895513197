import { Folder } from "cosmos-config/lib/group/folder";
import type { Group } from "cosmos-config/lib/group/group";
import { PermissionsGroup } from "cosmos-config/lib/group/permissionsGroup";
import type { Property } from "cosmos-config/lib/property/property";
import type { Widget } from "cosmos-config/lib/widget/widget";
import { createContext } from "react";

export interface StructureItem {
  level: number;
  name: string;
  property: Property;
  resourceType: number;
}

export interface StructuresMap
  extends Record<string, Record<number, StructureItem>> {}

interface PropertiesContextValue {
  properties: Property[];
  tableProperties: Property[];
  propertiesMap: Record<string, Property>;
  filterFolders: Array<any>;
  structures: StructuresMap;
  widgets: Widget[];
  groups: Group[];
  folderGroups: Group[];
  permissionsGroup: PermissionsGroup | null;
  folders: Folder[];
  folderProperties: Property[];
  extendedTaskHistoryProperties: Property[];
  getFolderGroups: (folderType: string) => Group[];
  getFolderProperties: (folderType: string) => Property[];
  getBusinessProcessGroups: (
    processDefinitionKey: string,
    taskDefinitionKey?: string
  ) => Group[];
  getBusinessProcessProperties: (
    processDefinitionKey: string,
    taskDefinitionKey?: string
  ) => Property[];

  getNscaleWorkflowGroups: (
    processDefinitionKey: string,
    taskDefinitionKey?: string
  ) => Group[];
  getNscaleWorkflowProperties: (
    processDefinitionKey: string,
    taskDefinitionKey?: string
  ) => Property[];
}

const PropertiesContext = createContext<PropertiesContextValue>({
  properties: [],
  tableProperties: [],
  propertiesMap: {},
  filterFolders: [],
  structures: {},
  widgets: [],
  groups: [],
  folderGroups: [],
  permissionsGroup: null,
  folders: [],
  folderProperties: [],
  extendedTaskHistoryProperties: [],
  getFolderGroups: () => [],
  getFolderProperties: () => [],
  getBusinessProcessGroups: () => [],
  getBusinessProcessProperties: () => [],
  getNscaleWorkflowGroups: () => [],
  getNscaleWorkflowProperties: () => [],
} as PropertiesContextValue);

export default PropertiesContext;
