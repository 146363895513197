import React, { useCallback, useMemo } from "react";
import AccessManagementContext, {
  ImmediatePermission,
} from "../contexts/AccessManagementContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import resourcePermissionsApi from "../apis/resourcePermissionsApi";
import permissionConstant from "../constants/resourcePermissions";
import { resourceCorrectionForGateway } from "cosmos-config/utils";
import { parseExcelFile } from "../../../utils/fileUtils";
import { writeFile, utils } from "xlsx";
import { DateTime } from "luxon";
import { Permission } from "../constants/Permission";
import useResourceAccessPermissions from "../hooks/useResourceAccessPermissions";
import _ from "lodash";

declare type MutationPayload = {
  mutationType: "update" | "grant" | "import" | "revoke" | "toggleActive";

  permissionId?: string;
  principalId?: string;
  permissions?: ImmediatePermission[];
  resourceId?: string;
  permission?: Permission;
};

export interface AccessManagementProps {
  resourceId?: string;
  children: React.ReactNode;
  onResourceNeeded?: () => Promise<string>;
}

const AccessManagementProvider = ({
  resourceId,
  children,
  onResourceNeeded,
}: AccessManagementProps) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => ["permissions", resourceId], [resourceId]);

  const { loading, resourcePermissions } = useResourceAccessPermissions({
    resourceId,
  });

  const mutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (payload: MutationPayload) => {
      const { mutationType, resourceId, permissions, principalId } = payload;

      if (mutationType === "grant") {
        if (resourceId == null || principalId == null) {
          throw new Error("Resource ID and principal ID cannot be null!");
        }

        return resourcePermissionsApi.grantPermission(
          "RWE",
          resourceId,
          principalId
        );
      }

      if (mutationType === "import") {
        if (resourceId == null) {
          throw new Error("Resource ID cannot be null!");
        }

        return resourcePermissionsApi.importPermissions(
          "RWE",
          resourceId,
          permissions || []
        );
      }

      const { permissionId, permission } = payload;

      if (permissionId == null) {
        throw new Error("Permission ID cannot be null!");
      }

      if (mutationType === "update") {
        return resourcePermissionsApi.updatePermission(
          "RWE",
          permissionId,
          permission
        );
      }

      if (mutationType === "revoke") {
        return resourcePermissionsApi.deletePermission("RWE", permissionId);
      }

      if (mutationType === "toggleActive") {
        return resourcePermissionsApi.toggleActive(permissionId);
      }

      return Promise.resolve();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (err) => console.error(err),
  });

  const ensureResourceAvailable = (
    callback: (resid: string) => Promise<any>
  ) => {
    if (resourceId != null) {
      return callback(resourceId);
    }

    if (onResourceNeeded != null) {
      return onResourceNeeded().then(callback);
    }

    return Promise.reject("Resource id is not available!");
  };

  const importPermissions = useCallback(
    (permissions: ImmediatePermission[]) => {
      if (resourceId != null) {
        return mutation.mutateAsync({
          mutationType: "import",
          resourceId,
          permissions: permissions.map((p) => ({
            ...p,
            id: null,
          })),
        });
      }

      return Promise.reject("Resource id is not available!");
    },
    [mutation, resourceId]
  );

  return (
    <AccessManagementContext.Provider
      value={{
        loading: useMemo(
          () => loading || mutation.isPending,
          [loading, mutation.isPending]
        ),
        resourcePermissions,
        updatePermission: (permissionId, permission) => {
          const correctPermission = resourceCorrectionForGateway(
            permission,
            permissionConstant
          ) as Permission;
          return mutation.mutateAsync({
            mutationType: "update",
            permissionId,
            permission: correctPermission,
          });
        },
        grantPermission: (principalIds) => {
          return ensureResourceAvailable((resid) => {
            return Promise.all(
              principalIds.map((pId) =>
                mutation.mutateAsync({
                  mutationType: "grant",
                  resourceId: resid,
                  principalId: pId,
                })
              )
            );
          });
        },
        revokePermission: (permissionIds) => {
          if (resourceId != null) {
            return Promise.all(
              permissionIds.map((permissionId) =>
                mutation.mutateAsync({
                  mutationType: "revoke",
                  permissionId,
                })
              )
            );
          }

          return Promise.reject("Resource id is not available!");
        },
        togglePermissionActive: (permissionIds, active) => {
          if (resourceId != null) {
            return Promise.all(
              permissionIds.map((permissionId) =>
                mutation.mutateAsync({
                  mutationType: "toggleActive",
                  permissionId,
                })
              )
            );
          }

          return Promise.reject("Resource id   is not available!");
        },
        exportPermissions: () => {
          const exportablePropertyNames = permissionConstant
            .filter((property) => property.information)
            .map((property) => property.name);

          const permissionsToExport = resourcePermissions.map((p: Permission) =>
            _.pick(
              resourceCorrectionForGateway(p, permissionConstant),
              exportablePropertyNames
            )
          );

          const wb = utils.book_new();
          const ws = utils.json_to_sheet(permissionsToExport);

          const sheetName = "resource-permissions";
          utils.book_append_sheet(wb, ws, sheetName);

          const fileName = `${sheetName}_${DateTime.now().toSQLDate()}.xlsx`;

          writeFile(wb, fileName);
          return Promise.resolve();
        },
        importPermissions,
        importPermissionsFiles: (files) => {
          if (resourceId != null) {
            const [importFile] = files;
            return parseExcelFile(importFile).then((data) => {
              return importPermissions(data as Permission[]);
            });
          }

          return Promise.reject("Resource id is not available!");
        },
        permittedPrincipalIds: useMemo(() => {
          return _(resourcePermissions).map("principalId").uniq().value();
        }, [resourcePermissions]),
      }}
    >
      {children}
    </AccessManagementContext.Provider>
  );
};

export default AccessManagementProvider;
