import { count, select } from 'cosmos-config/nql';
import CacheApi from './conf/CacheApi';
import Api from './conf/Api';

const cacheApi = CacheApi.build();
const api = Api.build();

const getMasterdateCacheKey = (scope, key) =>
  key != null ? `${scope}:${key}` : scope;

const queryMasterdataAggregate = (docarea, scope, query) => {
  return api
    .get(`/masterdata/docarea/${docarea}/scope/${scope}/aggregate`, {
      params: {
        query,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data.items);
    });
};

const getMasterData = (
  docarea,
  scope,
  properties = ['*'],
  { where, orWhere, orderBy, paging } = {},
  cacheKey = null
) => {
  const query = select(properties)
    .orWhere(orWhere)
    .where(where)
    .orderBy(orderBy)
    .paging(paging)
    .toString();

  const options = CacheApi.options(
    {
      params: {
        query,
      },
    },
    cacheKey == null,
    getMasterdateCacheKey(scope, cacheKey)
  );

  return cacheApi
    .get(`/masterdata/docarea/${docarea}/scope/${scope}`, options)
    .then((response) => {
      return Promise.resolve(response.data.items);
    });
};

const getCountMasterData = (docarea, scope, where) => {
  const query = select([count('resourceId')])
    .where(where)
    .toString();

  return queryMasterdataAggregate(docarea, scope, query).then((items) => {
    if (items.length > 0) {
      const prop = items[0].properties.find((p) => p.name === 'resourceId');
      return Promise.resolve(prop.value);
    }

    return Promise.resolve(0);
  });
};

const getSingleMasterDataEntry = (docarea, scope, id, properties = ['*']) => {
  return api
    .get(`/masterdata/docarea/${docarea}/scope/${scope}/id/${id}`, {
      params: {
        query: select(properties).toString(),
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const postMasterDataProperties = (
  docarea,
  scope,
  properties,
  cacheKey = null
) => {
  const data = {
    properties,
  };

  return api
    .post(`/masterdata/docarea/${docarea}/scope/${scope}`, data)
    .then((response) => {
      if (cacheKey != null) {
        CacheApi.removeItem(getMasterdateCacheKey(scope, cacheKey));
      }
      return Promise.resolve(response.data.id);
    });
};

const postMasterDataEntry = (docarea, scope, entry, cacheKey = null) => {
  const properties = Object.entries(entry).map(([name, value]) => ({
    name,
    value: value || null,
  }));

  return postMasterDataProperties(docarea, scope, properties, cacheKey);
};

const updateMasterDataProperties = (
  docarea,
  scope,
  id,
  properties,
  cacheKey = null
) => {
  const data = {
    properties,
  };

  return api
    .put(`/masterdata/docarea/${docarea}/scope/${scope}/id/${id}`, data, {
      params: {
        ignoreCache: true,
      },
    })
    .then((response) => {
      if (cacheKey != null) {
        CacheApi.removeItem(getMasterdateCacheKey(scope, cacheKey));
      }
      return Promise.resolve(response.data);
    });
};

const updateMasterDataEntry = (docarea, scope, id, entry, cacheKey = null) => {
  const properties = Object.entries(entry).map(([name, value]) => ({
    name,
    value: value || null,
  }));

  return updateMasterDataProperties(docarea, scope, id, properties, cacheKey);
};

const removeMasterDataEntry = (docarea, scope, id, cacheKey = null) => {
  return api
    .delete(`/masterdata/docarea/${docarea}/scope/${scope}/id/${id}`)
    .then((response) => {
      if (cacheKey != null) {
        CacheApi.removeItem(getMasterdateCacheKey(scope, cacheKey));
      }
      return Promise.resolve(response.data);
    });
};

export default {
  getMasterData,
  getSingleMasterDataEntry,
  postMasterDataEntry,
  postMasterDataProperties,
  updateMasterDataProperties,
  updateMasterDataEntry,
  removeMasterDataEntry,
  queryMasterdataAggregate,
  getCountMasterData,
};
