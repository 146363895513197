import React, { useEffect, useRef, useState } from "react";
import { Button, Overlay } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Resource from "../../types/resource";
import styled from "styled-components";
import {
  FieldActionIcon,
  LoadingOverlay,
  PancoSelectOption,
} from "cosmos-components";
import useUploadAdvisor from "../../hooks/useUploadAdvisor";
import UpladAdvisorStatus from "../UploadAdvisorStatus";
import { UploadAdvisorStatus } from "../../contexts/UploadAdvisorContext";
import UploadAdvisorFilesList from "../upload/UploadAdvisorFilesList";
import { useTranslation } from "react-i18next";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { OnOpenResourceCallback } from "../../types/callbacks";
import { parseResourceId } from "../../utils/resourceUtils";

const Popover = styled.div`
  min-width: 20vw;
  background-color: ${(props) => props.theme.background};
  position: relative;
  max-width: 30vw;
`;

type FilePickerProps = {
  onChange?: (files: File[]) => void;
  className?: string;
  multiple?: boolean;
};

const FilePicker = ({ onChange, className, multiple }: FilePickerProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation("module");
  const { options } = useUploadAdvisor();

  return (
    <div className={className}>
      <Button
        block
        onClick={() => {
          if (inputRef.current != null) {
            inputRef.current.click();
          }
        }}
      >
        {options.multipleFiles
          ? t("contextmenu.upload_files", { defaultValue: "Upload Files" })
          : t("contextmenu.upload_single_file", {
              defaultValue: "Upload File",
            })}
      </Button>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        multiple={multiple}
        onChange={(e) => {
          const { files } = e.target;
          if (onChange != null && files != null) {
            onChange(Array.from(files));
          }
        }}
      />
    </div>
  );
};

type SimpleUploaderProps = {
  onComplete?: (resources: Partial<Resource>[]) => void;
};

const SimpleUploader = ({ onComplete }: SimpleUploaderProps) => {
  const {
    addFiles,
    uploadAllowed,
    executeUpload,
    status,
    progress,
    filesCount,
  } = useUploadAdvisor();
  const { t } = useTranslation("module");

  return (
    <LoadingOverlay
      progress={progress * 100}
      loading={status === UploadAdvisorStatus.UPLOADING}
    >
      <div className="p-3">
        {filesCount > 0 && <UploadAdvisorFilesList className="table-sm" />}

        <FilePicker
          onChange={(files) => addFiles(files, { multipleFiles: false })}
          multiple={false}
        />

        <div className="d-flex align-items-center my-3">
          <UpladAdvisorStatus />
        </div>

        <div className="text-right">
          <div className="mt-2">
            <Button
              disabled={!uploadAllowed}
              className=""
              onClick={() => {
                executeUpload().then((resources) => {
                  if (onComplete != null) {
                    onComplete(resources);
                  }
                });
              }}
            >
              {t("button.continue", {
                defaultValue: "Continue",
              })}
            </Button>
            <Button
              variant="link"
              onClick={() => {
                if (onComplete != null) {
                  onComplete([]);
                }
              }}
            >
              {t("button.cancel", { defaultValue: "Cancel" })}
            </Button>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export interface QuickUploadProps {
  label?: string;
  resource?: Resource;
  onInsert?: (option: PancoSelectOption) => void;
  hide?: boolean;
  onOpenResource?: OnOpenResourceCallback;
}

const QuickUpload = ({
  label,
  resource,
  onInsert,
  hide,
  onOpenResource,
}: QuickUploadProps) => {
  const [show, setShow] = useState(false);
  const targetRef = useRef<HTMLDivElement>(null);
  const [uploadedIdentifier, setUploadedIdentifier] = useState<number | null>(
    null
  );

  const { status, setPresetResource, reset } = useUploadAdvisor();
  const { t } = useTranslation("module");

  useEffect(() => {
    if (
      resource != null &&
      show &&
      status !== UploadAdvisorStatus.NOT_AVAILABLE
    ) {
      setPresetResource(resource);
    }
  }, [resource, show, setPresetResource, status]);

  useEffect(() => {
    if (!show && status !== UploadAdvisorStatus.NOT_AVAILABLE) {
      reset();
    }
  }, [show, reset, status]);

  if (
    (hide && status !== UploadAdvisorStatus.FINALIZED) ||
    status === UploadAdvisorStatus.NOT_AVAILABLE
  ) {
    return null;
  }

  return (
    <>
      <FieldActionIcon
        ref={targetRef}
        onClick={() => {
          setShow((s) => !s);
        }}
        className="text-primary"
      >
        <FontAwesomeIcon icon={faPlus} />
      </FieldActionIcon>

      <Overlay target={targetRef.current} show={show} placement="bottom-end">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div {...props}>
            <Popover className="border border-secondary rounded shadow">
              <div className="px-3 py-2 bg-light">
                <span className="h6 mb-0">{label}</span>
              </div>

              {status !== UploadAdvisorStatus.FINALIZED && (
                <SimpleUploader
                  onComplete={(resources) => {
                    if (resources.length === 0) {
                      setShow(false);
                    } else {
                      const [res] = resources;

                      if (res.id != null) {
                        const { identifier } = parseResourceId(res.id);
                        setUploadedIdentifier(identifier);
                      }

                      if (
                        onInsert != null &&
                        res.id != null &&
                        res.displayname != null
                      ) {
                        onInsert({
                          value: res.id,
                          label: res.displayname,
                        });
                      }
                    }
                  }}
                />
              )}

              {status === UploadAdvisorStatus.FINALIZED && (
                <div className="px-3 pb-2 text-center">
                  <h3 className="my-4">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                    {t("upload.quick_upload_done", {
                      defaultValue: "Document successfully uploaded.",
                    })}
                  </h3>

                  <p>
                    {t("upload.quick_upload_done_sub", {
                      defaultValue: "Please adapt its metadata.",
                    })}
                  </p>

                  <Button
                    block
                    onClick={() => {
                      if (
                        onOpenResource != null &&
                        uploadedIdentifier != null
                      ) {
                        onOpenResource(2, uploadedIdentifier, {
                          route: "metadata",
                        });
                      }
                    }}
                  >
                    {t("contextmenu.edit_metadata", {
                      defaultValue: "Edit Metadata",
                    })}
                  </Button>
                  <Button variant="link" block onClick={() => setShow(false)}>
                    {t("button.leave", { defaultValue: "Leave" })}
                  </Button>
                </div>
              )}
            </Popover>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default QuickUpload;
