import * as actions from './types/businessProcess';

/**
 * Business process action generators.
 * @module actions/businessProcess
 * @category Actions
 */

/**
 * Action to start business process.
 * @function
 * @param {string} processDefinitionKey - definition key of the process that is started
 * @param {Map.<string, any>} variables - initial process variables
 * @param {object} options - additional options
 * @param {boolean} options.useFolder - whether folder is used as a resource in the workflow
 * @param {function} options.callback - action results callback
 */
export const startBusinessProcess = (
  processDefinitionKey,
  variables,
  options = {}
) => ({
  type: actions.START_BUSINESS_PROCESS,
  payload: {
    processDefinitionKey,
    variables,
    options: {
      useFolder: options?.useFolder || false,
    },
  },
});

/**
 * Action to submit business process task.
 * @function
 * @param {string} processDefinitionKey - definition key of the process that is started
 * @param {string} taskDefinitionKey - definition key of the current task
 * @param {string} taskId - id of the task
 * @param {Map.<string, any>} variables - initial process variables
 * @param {object} options - additional options
 * @param {function} options.callback - action results callback
 */
export const submitBusinessProcessTask = (
  processDefinitionKey,
  taskDefinitionKey,
  taskId,
  variables
) => ({
  type: actions.SUBMIT_BUSINESS_PROCESS_TASK,
  payload: {
    taskId,
    processDefinitionKey,
    taskDefinitionKey,
    variables,
  },
});

/**
 * Action for fetching user business process tasks.
 * @function
 */
export const fetchUserBusinessProcessTasks = () => ({
  type: actions.FETCH_USER_BUSINESS_PROCESS_TASKS,
  payload: {},
});

/**
 * Action for opening business process task.
 * @function
 * @param {string} taskId - id of the task
 */
export const openBusinessProcessTask = (taskId) => ({
  type: actions.OPEN_BUSINESS_PROCESS_TASK,
  payload: {
    taskId,
  },
});

/**
 * Action for setting business process tasks.
 * @function
 * @param {} tasks - business process tasks
 */
export const setBusinessProcessTasks = (tasks) => ({
  type: actions.SET_BUSINESS_PROCESS_TASKS,
  payload: {
    tasks,
  },
});

/**
 * Action for fetching business process defenitions.
 * @function
 */
export const fetchBusinessProcessDefinitions = () => ({
  type: actions.FETCH_BUSINESS_PROCESS_DEFINITIONS,
});

/**
 * Action for setting business process definitions.
 * @function
 * @param {} processDefinitions - business process definitions
 */
export const setBusinessProcessDefinitions = (processDefinitions) => ({
  type: actions.SET_BUSINESS_PROCESS_DEFINITIONS,
  payload: { processDefinitions },
});

/**
 * Action for setting business process loading.
 * @function
 * @param {boolean} loading - status flag of loading process
 */
export const setBusinessProcessLoading = (loading) => ({
  type: actions.SET_BUSINESS_PROCESS_LOADING,
  payload: {
    loading,
  },
});

/**
 * Action for fetching business process task history.
 * @function
 * @param {object} options - additional options
 */
export const fetchBusinessProcessTaskHistory = () => ({
  type: actions.FETCH_BUSINESS_PROCESS_TASK_HISTORY,
});

/**
 * Action for fetching business processes.
 * @function
 * @param {object} options - additional options
 */
export const fetchBusinessProcesses = () => ({
  type: actions.FETCH_BUSINESS_PROCESSES,
});

/**
 * Action for canceling business process.
 * @function
 * @param {string} processId - id of the process
 * @param {object} options - additional options
 */
export const cancelBusinessProcess = (processId) => ({
  type: actions.CANCEL_BUSINESS_PROCESS,
  payload: {
    processId,
  },
});

/**
 * Action for setting business processes.
 * @function
 * @param {Array} processes - current business processes
 */
export const setBusinessProcesses = (processes = []) => ({
  type: actions.SET_BUSINESS_PROCESSES,
  payload: {
    processes,
  },
});

/**
 * Action for opening business process.
 * @function
 * @param {string} processId - id of business process
 */
export const openBusinessProcess = (processId) => ({
  type: actions.OPEN_BUSINESS_PROCESS,
  payload: {
    processId,
  },
});

/**
 * Action for updating business process.
 * @function
 * @param {string} processDefinitionKey - definition key of the process that is started
 * @param {string} processId - id of business process
 * @param {Array | {}} variables - additional variables
 * @param {object} options - additional options
 */
export const updateBusinessProcess = (
  processDefinitionKey,
  processId,
  variables = {}
) => ({
  type: actions.UPDATE_BUSINESS_PROCESS,
  payload: {
    processDefinitionKey,
    processId,
    variables,
  },
});

/**
 * Action for setting business process validation errors.
 * @function
 * @param {Array} validationErrors - an array structure to store errors
 */
export const setBusinessProcessValidationErrors = (validationErrors = []) => ({
  type: actions.SET_BUSINESS_PROCESS_VALIDATION_ERRORS,
  payload: {
    validationErrors,
  },
});
