import Axios from "axios";

const BASE_URL = process.env.REACT_APP_CONFIG_PROVIDER_URL;

const api = {
  build() {
    const axios = Axios.create({
      baseURL: BASE_URL,
    });

    // axios.interceptors.request.use(authInterceptor);
    // axios.interceptors.response.use(
    //   (response) => response,
    //   errorResponseInterceptor
    // );

    return axios;
  },
};

export default api;
