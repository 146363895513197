import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import useValueset from "./useValueset";
import {
  SelectableAttributeProperty,
  ValuesetOption,
} from "cosmos-config/lib/property/property";
import { ValuesetItem } from "../contants/valuesetItem";

export interface usePropertyOptionsProps {
  property?: SelectableAttributeProperty;
  filter?: any;
  contextResourceId?: string;
  includeObsolete?: boolean;
}

const usePropertyOptions = ({
  property,
  filter,
  contextResourceId,
  includeObsolete,
}: usePropertyOptionsProps) => {
  const { getValuesetByName, registerProperties, addTempValuesetItem } =
    useValueset();

  const [options, setOptions] = useState<ValuesetOption[]>([]);

  useEffect(() => {
    if (property?.name != null) {
      registerProperties({
        [property.name]: property,
      });
    }
  }, [property, registerProperties]);

  const valueset = useMemo(() => {
    if (property != null) {
      return getValuesetByName(property.valuesetName).map(
        (v) => v as ValuesetOption
      );
    }

    return [];
  }, [getValuesetByName, property]);

  useEffect(() => {
    if (property != null) {
      setOptions((oldOptions: ValuesetOption[]) => {
        const newOptions = _(
          property.buildOptions(valueset, filter, contextResourceId || "") || []
        )
          .filter((option) => !(!includeObsolete && option.obsolete))
          .compact()
          .value();

        return _.isEqual(oldOptions, newOptions) ? oldOptions : newOptions;
      });
    }
  }, [property, valueset, filter, contextResourceId, includeObsolete]);

  return {
    options,
    valueset,
    addOption: useCallback(
      (valuesetItem: Partial<ValuesetItem>) => {
        if (property?.valuesetName != null) {
          addTempValuesetItem(property.valuesetName, {
            ...valuesetItem,
            filter: contextResourceId,
          });
        }
      },
      [addTempValuesetItem, property, contextResourceId]
    ),
  };
};

export default usePropertyOptions;
