import { useCallback, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import commentApi, { Comment } from "../apis/commentApi";
import useCurrentUser from "./useCurrentUser";

export interface UseResourceCommentsProps {
  resourceId?: string;
}

type PostMutationParam = {
  resourceId: string;
  comment: Comment;
  nodeId?: string;
};

type UpdateMutationParam = {
  commentId: string;
  comment: Comment;
};

export type PostCommentOptions = {
  mentions?: string[];
  parameter?: string | null;
  nodeId?: string | null;
  position?: any;
};

export type PostCommentParam = {
  resourceId: string;
  message: string;
  options?: PostCommentOptions;
};

const useResourceComments = ({ resourceId }: UseResourceCommentsProps) => {
  const { authenticated } = useCurrentUser();
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => ["resource-comments", resourceId],
    [resourceId]
  );

  const { data, isLoading } = useQuery<Comment[]>({
    queryKey,
    queryFn: () => {
      if (resourceId == null) {
        throw new Error("Resource id cannot be null!");
      }

      return commentApi.getComments(resourceId);
    },
    enabled: resourceId != null && authenticated,
  });

  const postMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: ({ resourceId, comment, nodeId }: PostMutationParam) => {
      if (resourceId == null) {
        throw new Error("Resource id cannot be null!");
      }

      return commentApi.postComment(resourceId, "", comment, nodeId);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: ({ comment, commentId }: UpdateMutationParam) => {
      if (commentId == null) {
        throw new Error("Comment id cannot be null!");
      }

      return commentApi.updateComment(commentId, comment);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (commentId: string) => {
      if (commentId == null) {
        throw new Error("Comment id cannot be null!");
      }

      return commentApi.deleteComment(commentId);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  return {
    comments: data || [],
    loading: isLoading,
    updateComment: useCallback(
      (commentId: string, comment: Comment) => {
        return updateMutation.mutateAsync({ commentId, comment });
      },
      [updateMutation]
    ),
    postComment: useCallback(
      (param: PostCommentParam) => {
        if (resourceId == null) {
          throw new Error("Parent resource id of the comment cannot be null!");
        }

        const { message, options } = param;

        const comment: Comment = {
          id: null,
          message,
          mentions: options?.mentions || [],
          position: options?.position || null,
          parameter: null,
          createdAt: 0,
          comments: [],
        };

        return postMutation.mutateAsync({
          resourceId,
          nodeId: options?.nodeId || undefined,
          comment,
        });
      },
      [postMutation, resourceId]
    ),
    deleteComment: useCallback(
      (commentId: string) => {
        return deleteMutation.mutateAsync(commentId);
      },
      [deleteMutation]
    ),
  };
};

export default useResourceComments;
