import { createElement } from "react";
import { property, systemProperty } from "cosmos-config/generator";

const taskHistoryProperties = [
  property("Workflow ID", "Process_Key")
    .tableCell((v) => {
      if (v == null) {
        return "";
      }

      const identifier = v.split("$")[2];
      return createElement("b", {}, `#${identifier}`);
    })
    .translate("default_task.workflow_id"),
  systemProperty("Process_Name").excludeTable(),
  systemProperty("Task Name", "Task_Name"),
  property("Assigned At").dateAttribute("Process_Startdate"),
  systemProperty("Process_Starter"),
  property("Assigned By", "initiator")
    .resolveUsername("Process_Starter")
    .excludeTable(),
  property("Resource Number", "resourceId")
    .tableCell((v) => {
      if (v == null) {
        return "";
      }

      const identifier = v.split("$")[2];
      const padded = String(identifier).padStart(9, "0");
      return `${padded}-01`;
    })
    .excludeTable(),
  // property('Additional Information', 'Task_Instruction').excludeTable(),
  systemProperty("Task_Finisher"),
  systemProperty("Task_Assignment"),
  systemProperty("reviewer").resolveUsername("Reviewer_Lead"),
  property("responsible").resolveUsername("Task_Finisher").excludeTable(),
  property("assignment").resolveUsername("Task_Assignment").excludeTable(),
  systemProperty("Task_Key"),
  property("Process_Enddate").dateAttribute("Process_Enddate").excludeTable(),

  property("Finished At").dateAttribute("Task_Enddate"),
  property("Task_Startdate").dateAttribute("Task_Startdate").excludeTable(),
  systemProperty("reviewers"),
  systemProperty("resourceId"),
  // property('Resource Name', 'resourceName')
  //   .selectableAttribute(
  //     'resourceName',
  //     'passo_projectname',
  //     valuesetSource.CUSTOM_VALUESET
  //   )
  //   .resolveReference('resourceId', 1, 'ProjectName'),
  property("Decision").excludeTable(),
  property("Remarks").excludeTable(),
];

export default taskHistoryProperties;
