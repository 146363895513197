import React, { useContext } from "react";
import { LoadingOverlay } from "cosmos-components";
import DashboardContext from "../../contexts/DashboardContext";
import useAggregatedData from "../../hooks/useAggregatedData";
import { ChartDataEntry } from "../../types/CosmosChart";

export type ChildrenParam = {
  data: ChartDataEntry[];
};

export interface DistinctPropertyDataProps {
  propertyName?: string | null;
  resourceType?: number;
  children?: (param: ChildrenParam) => React.ReactNode;
}

const DistinctPropertyData = ({
  propertyName,
  resourceType,
  children,
}: DistinctPropertyDataProps) => {
  const { resourceId, filter } = useContext(DashboardContext);

  const { data, loading } = useAggregatedData({
    propertyName,
    resourceType: resourceType || 2,
    resourceId: resourceId || "",
    subtree: true,
    filter,
    substructure: false,
  });

  if (children == null) {
    return <></>;
  }

  return (
    <LoadingOverlay loading={loading}>{children({ data })}</LoadingOverlay>
  );
};

export default DistinctPropertyData;
