import React, { useState } from "react";
import { Card, Collapse, Badge } from "react-bootstrap";

export interface FormGroupWrapperProps {
  children?: React.ReactNode;
  title?: string;
  className?: string;
  defaultCollapsed?: boolean;
  activeElements?: number;
}

const FormGroupWrapper = ({
  children,
  title,
  className,
  defaultCollapsed,
  activeElements,
}: FormGroupWrapperProps) => {
  const [collapsed, setCollapsed] = useState(
    defaultCollapsed == null ? true : defaultCollapsed
  );

  return (
    <Card border="dark" className={className}>
      <Card.Header
        onClick={() => setCollapsed(!collapsed)}
        style={{ cursor: "pointer" }}
      >
        {activeElements != null && activeElements > 0 && (
          <Badge pill variant="success" className="mr-2">
            {activeElements}
          </Badge>
        )}

        {title}
      </Card.Header>
      <Collapse in={!collapsed}>
        <Card.Body>{children}</Card.Body>
      </Collapse>
    </Card>
  );
};

export default FormGroupWrapper;
