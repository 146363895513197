import { Property } from "cosmos-config/lib/property/property";
import Resource from "../types/resource";
import { getKeywords } from "./resourceUtils";

import _ from "lodash";
import { resourceCorrectionForGateway } from "cosmos-config/utils";
import { ValuesetItem } from "../contants/valuesetItem";
import { UserInfo } from "../contexts/UserDataContext";

export type ResourceProperty = {
  name: string;
  value: any;
};

export const applyDefaultValues = (
  resource: Partial<Resource>,
  properties: Property[],
  userdata?: UserInfo | null
) => {
  const defaultValues = _(properties)
    .keyBy("name")
    .mapValues((p) =>
      _.isFunction(p.getDefaultValue) ? p.getDefaultValue(userdata) : null
    )
    .omitBy(_.isNull)
    .value();

  const { displayname, ...rest } = defaultValues;
  if (displayname != null) {
    return { ...rest, ...resource, displayname };
  }

  return { ...defaultValues, ...resource };
};

export const prepareResourceForGateway = (
  resource: Partial<Resource>,
  properties: Property[],
  initial?: boolean,
  userdata?: UserInfo | null
) => {
  const editableProperties = properties
    .filter((p) => (p.editable && p.updatable) || p.systemUpdatable)
    .map((p) => p.name);

  const resourceDefaults = initial
    ? applyDefaultValues(resource, properties, userdata)
    : resource;

  const correctedResource = resourceCorrectionForGateway(
    resourceDefaults,
    properties
  );

  return _.map(
    correctedResource,
    (value, propertyName) =>
      ({
        name: propertyName,
        value,
      } as ResourceProperty)
  ).filter((p) => editableProperties.includes(p.name));
};

export type ValuesetsMap = Record<string, ValuesetItem[]>;

export const resourceToProperties = (
  resource: Resource,
  properties: Property[],
  valuesetsMap: ValuesetsMap
) => {
  const keywords = getKeywords(resource, properties, valuesetsMap);

  const preparedResource = {
    ...resource,
    BasicKeywords: keywords,
    displayname: resource.displayname || resource.filename || null,
  } as Resource;

  return prepareResourceForGateway(preparedResource, properties);
};
