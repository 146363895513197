import { darken, getLuminance, lighten } from "polished";

export const shadeColor = (amount: number, color: string) => {
  if (color == null || typeof color !== "string") {
    return color;
  }

  const shadingFunc = getLuminance(color) > 0.5 ? darken : lighten;
  return shadingFunc(amount, color);
};

export const blendColor = (amount: number, color: string) => {
  if (color == null || typeof color !== "string") {
    return color;
  }

  const shadingFunc = getLuminance(color) > 0.5 ? lighten : darken;
  return shadingFunc(amount, color);
};
