import React, { useEffect, useMemo, useReducer } from "react";
import UserDataContext, {
  ResourcePermission,
  UserInfo,
} from "../contexts/UserDataContext";
import { useQuery } from "@tanstack/react-query";
import userApi from "../apis/userApi";
import { hashCode } from "cosmos-config/utils";
import _ from "lodash";
import { useSession } from "../modules/authentication";

const actions = {
  LOGIN_SUCCESS: "user/loginSuccess",
  USERDATA_SUCCESS: "user/userdataSuccess",
  RESOURCE_PERMISSIONS_SUCCESS: "user/resourcePermissionsSuccess",
  INVALIDATE_USER_DATA: "user/invalidateData",
};

type UserStateAction = {
  type: string;
  payload?: any;
};

export type FolderPermissions = {
  customPermissions: string[];
  readProperties: boolean;
  writeProperties: boolean;
  changeObjectclass: boolean;
  readNote: boolean;
  writeNote: boolean;
  lock: boolean;
  delete: boolean;
  finalize: boolean;
  archive: boolean;
  move: boolean;
  deleteNote: boolean;
  revertToVersion: boolean;
  physicallyDelete: boolean;
  withdraw: boolean;
  createFolder: boolean;
  createDocument: boolean;
  createLink: boolean;
  invite: boolean;
};

export type UserPermissionsType = {
  metadataPermissions: Record<string, any>;
  documentPermissions: Record<string, any>;
  systemPermissions: Record<string, any>;
  linkPermissions: Record<string, any>;
  workflowPermissions: Record<string, any>;
  folderPermissions: Record<string, FolderPermissions>;
  documentAreaPermissions: Record<string, any>;
  domainPermissions: Record<string, any>;
};

type LoginSuccessAction = {
  payload: {
    principalId: string;
    defaultPositionId: string;
    permissions: UserPermissionsType;
  };
} & UserStateAction;

declare type UserDataState = {
  authenticated: boolean;
  principalId: string | null;
  defaultPositionId: string | null;
  userData: UserInfo | null;
  resourcePermissions: ResourcePermission[];
  permissions: UserPermissionsType;
  groupNames: string[];
};

const userReducer = (
  state: UserDataState,
  action: UserStateAction
): UserDataState => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        principalId: action.payload?.principalId,
        defaultPositionId: action.payload?.defaultPositionId,
        permissions: action.payload?.permissions,
        groupNames: action.payload?.groupNames,
      };
    case actions.USERDATA_SUCCESS:
      return {
        ...state,
        userData: action.payload?.userData,
      };
    case actions.RESOURCE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        resourcePermissions: action.payload?.resourcePermissions,
      };
    case actions.INVALIDATE_USER_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const initialState: UserDataState = {
  authenticated: false,
  principalId: null,
  defaultPositionId: null,
  userData: null,
  resourcePermissions: [],
  permissions: {
    metadataPermissions: {},
    documentPermissions: {},
    systemPermissions: {},
    linkPermissions: {},
    workflowPermissions: {},
    folderPermissions: {},
    documentAreaPermissions: {},
    domainPermissions: {},
  },
  groupNames: [],
};

type UserDataProviderProps = {
  children: React.ReactNode;
};

const UserDataProvider = ({ children }: UserDataProviderProps) => {
  const [localState, localDispatch] = useReducer(userReducer, initialState);
  const { accessToken, domainName, ready } = useSession();

  const { data: loginResponse, isLoading } = useQuery({
    queryKey: ["user-login", hashCode(accessToken || "")],
    queryFn: () => {
      if (accessToken == null || domainName == null) {
        return Promise.reject();
      }

      return userApi.login(accessToken, domainName);
    },
    enabled: accessToken != null && domainName != null,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (loginResponse != null) {
      const { groupNames, defaultPositionId, principalId, permissions } =
        loginResponse;
      localDispatch({
        type: actions.LOGIN_SUCCESS,
        payload: { defaultPositionId, principalId, permissions, groupNames },
      } as LoginSuccessAction);
    }
  }, [loginResponse]);

  const { data: userDataResponse } = useQuery<UserInfo>({
    queryKey: ["user-data", localState.principalId],
    queryFn: () => {
      if (localState.principalId == null) {
        throw new Error("User principalId cannot be null!");
      }

      return userApi.getUserdata(localState.principalId);
    },
    enabled: localState.principalId != null,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (userDataResponse != null) {
      localDispatch({
        type: actions.USERDATA_SUCCESS,
        payload: { userData: userDataResponse },
      });
    }
  }, [userDataResponse]);

  const { data: resourcePermissionsResponse } = useQuery({
    queryKey: ["resource-permissions", localState.principalId],
    queryFn: () => userApi.getUsersResourcePermissions(),
    enabled: localState.principalId != null && localState.authenticated,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (resourcePermissionsResponse != null) {
      localDispatch({
        type: actions.RESOURCE_PERMISSIONS_SUCCESS,
        payload: { resourcePermissions: resourcePermissionsResponse },
      });
    }
  }, [resourcePermissionsResponse]);

  const systemAdmin = useMemo(() => {
    const docareaPermissions = localState.permissions?.documentAreaPermissions;
    return !!_.get(docareaPermissions, "RWE.docAreaAdmin");
  }, [localState.permissions]);

  useEffect(() => {
    if (accessToken == null) {
      localDispatch({
        type: actions.INVALIDATE_USER_DATA,
      });
    }
  }, [accessToken]);

  return (
    <UserDataContext.Provider
      value={{
        authenticated: localState.authenticated,
        loginLoading: isLoading || !ready,
        principalId: localState.principalId,
        defaultPositionId: localState.defaultPositionId,
        userData: localState.userData,
        resourcePermissions: useMemo(
          () =>
            _(localState.resourcePermissions)
              .keyBy("resourceId")
              .mapValues((v) => _.omit(v, "resourceId") as ResourcePermission)
              .value(),
          [localState.resourcePermissions]
        ),
        permissions: localState.permissions,
        groupNames: localState.groupNames,
        systemAdmin,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataProvider;
