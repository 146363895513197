import { useEffect, useMemo, useState } from "react";
import {
  Button,
  CloseButton,
  Modal,
  FormGroup,
  Row,
  Col,
  FormLabel,
  Alert,
} from "react-bootstrap";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import {
  PancoSelect,
  LoadingOverlay,
  PancoInput,
  PancoSelectBadge,
} from "cosmos-components";
import { valuesetSource } from "cosmos-config/generator";
import _ from "lodash";
import {
  addCustomValuesetItem,
  updateCustomValuesetItems,
} from "../../actions/docareaActions";
import useCoreDispatch from "../../hooks/useCoreDispatch";
import SimpleUniversalForm from "../../components/SimpleUniversalForm";
import valuesetItem from "../../contants/valuesetItem";
import useValueset from "../../hooks/useValueset";

const EditValuesetEntryModal = ({
  show,
  onClose,
  entry,
  property,
  reference,
  advanced,
}) => {
  const dispatch = useCoreDispatch();

  const [innerEntry, setInnerEntry] = useState({});
  const { getValuesetByProperty, getValuesetByName, loading } = useValueset();

  const dependencyOptions = useMemo(() => {
    return getValuesetByProperty(property.dependency)
      .map((option) => ({
        ...option,
        label: `${option.value} - ${option.label}`,
      }))
      .sort((a, b) => String(a.label).localeCompare(b.label));
  }, [property, getValuesetByProperty]);

  const options = useMemo(() => {
    return getValuesetByName(
      property.valuesetName,
      property.valuesetSource
    ).filter(
      (option) =>
        !property.isDependant() ||
        option.filter === reference ||
        innerEntry.filter === option.filter
    );
  }, [property, getValuesetByName, reference, innerEntry.filter]);

  const fuse = useMemo(() => {
    return new Fuse(options, {
      keys: ["label"],
      useExtendedSearch: true,
    });
  }, [options]);

  const suggestions = useMemo(() => {
    const query = innerEntry.label;

    if (query != null && query !== "") {
      const results = fuse.search(`^"${innerEntry.label}"`);
      return results.map((r) => r.item);
    }

    return [];
  }, [innerEntry.label, fuse]);

  useEffect(() => {
    if (show) {
      if (reference != null) {
        setInnerEntry({ ...entry, filter: reference });
      } else {
        setInnerEntry({ ...entry });
      }
    }
  }, [entry, show, reference]);

  const handleSubmit = () => {
    const trimmedEntry = {
      ...innerEntry,
      label: innerEntry.label != null ? innerEntry.label.trim() : null,
      keywords: Array.isArray(innerEntry.keywords)
        ? [...innerEntry.keywords].map((keyword) => keyword.trim())
        : [],
    };

    if (property.isDependant() && innerEntry.filter == null) {
      // dispatch(notify(t("valueset_entry_modal.reference_not_empty"), "error"));
      return;
    }

    if (innerEntry.id != null) {
      dispatch(
        updateCustomValuesetItems({
          valuesetName: property.valuesetName,
          valuesetItems: [trimmedEntry],
        })
      ).then(() => onClose());
    } else {
      dispatch(
        addCustomValuesetItem({
          valuesetName: property.valuesetName,
          valuesetItem: trimmedEntry,
          generateKey: property.masterValuesetName == null,
        })
      ).then((result) => onClose(result));
    }
  };

  const updateInnerEntryValue = (name, value) => {
    setInnerEntry((ie) => ({
      ...ie,
      [name]: value,
    }));
  };

  const getValue = (fieldName) =>
    innerEntry[fieldName] != null ? [innerEntry[fieldName]] : [];

  const valuesetItemProperties = useMemo(() => {
    return valuesetItem.filter(
      (itemProperty) =>
        itemProperty.name !== "obsolete" || property?.invalidatingOptions
    );
  }, [property]);

  const renderLabelComponent = () => {
    if (property.masterValuesetName != null) {
      const existingValues = _(options).map("value").value();

      const valueset = getValuesetByName(
        property.masterValuesetName,
        valuesetSource.CUSTOM_VALUESET
      );

      const slaveOptions = valueset
        .filter(
          (item) =>
            (!existingValues.includes(item.value) ||
              innerEntry.value === item.value) &&
            (!property.isDependant() ||
              (item.filter === reference && reference != null) ||
              innerEntry.filter === item.filter)
        )
        .map(({ value, label }) => ({ value, label }));

      return (
        <PancoSelect
          value={getValue("value")}
          onChange={({ value }) => {
            const [v] = value;
            updateInnerEntryValue("value", v);
          }}
          required
          options={slaveOptions}
        />
      );
    }

    if (property.addingOptions.length > 0) {
      return (
        <PancoSelect
          value={getValue("label")}
          onChange={({ value }) => {
            const [v] = value;
            updateInnerEntryValue("label", v);
          }}
          required
          options={property.addingOptions.map((o) => ({
            label: o,
            value: o,
          }))}
        />
      );
    }

    return (
      <PancoInput
        value={getValue("label")}
        onChange={({ value }) => {
          updateInnerEntryValue("label", value);
        }}
        required
      />
    );
  };

  return (
    <Modal show={show} className="location-modal" onHide={onClose}>
      <LoadingOverlay loading={loading}>
        <Modal.Header>
          <Modal.Title>Valueset Entry</Modal.Title>
          <CloseButton className="close-icon" onClick={onClose} />
        </Modal.Header>
        <Modal.Body className="position-relative">
          {(entry == null || entry.id == null) &&
            suggestions.length > 0 &&
            suggestions.length < 15 &&
            property.masterValuesetName == null && (
              <Alert variant="info">
                <div className="mb-3">
                  <i>
                    There are some similar values in the list. Please make sure
                    that you are not creating duplicated entries! Did you mean
                    one of following ?
                  </i>
                </div>
                {suggestions.map((option) => (
                  <PancoSelectBadge
                    key={`suggestion-${option.value}`}
                    onClick={() => {
                      onClose(option);
                    }}
                  >
                    {option.label}
                  </PancoSelectBadge>
                ))}
              </Alert>
            )}

          <FormGroup as={Row}>
            <FormLabel column sm={4}>
              Label
            </FormLabel>
            <Col sm={6} xs={10}>
              {renderLabelComponent()}
            </Col>
          </FormGroup>

          {advanced && (
            <SimpleUniversalForm
              inline
              properties={valuesetItemProperties}
              resource={innerEntry}
              onUpdateResource={(id, name, value) => {
                updateInnerEntryValue(name, value);
              }}
            />
          )}

          {property.isDependant() && reference == null && (
            <FormGroup as={Row}>
              <FormLabel column sm={4}>
                Reference
              </FormLabel>
              <Col sm={8}>
                <PancoSelect
                  options={dependencyOptions}
                  value={getValue("filter")}
                  onChange={({ value }) => {
                    updateInnerEntryValue("filter", value[0]);
                  }}
                  disabled={reference != null}
                  required
                />
              </Col>
            </FormGroup>
          )}

          <div className="text-right">
            <Button onClick={handleSubmit}>Save</Button>
          </div>
        </Modal.Body>
      </LoadingOverlay>
    </Modal>
  );
};

EditValuesetEntryModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  entry: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    keywords: PropTypes.string,
    favourite: PropTypes.bool,
  }),
  property: PropTypes.shape({
    isDependant: PropTypes.func,
    addingOptions: PropTypes.arrayOf(PropTypes.string),
    valuesetName: PropTypes.string,
    dependency: PropTypes.string,
    valuesetSource: PropTypes.string,
    masterValuesetName: PropTypes.string,
  }).isRequired,
  reference: PropTypes.string,
  advanced: PropTypes.bool,
};

EditValuesetEntryModal.defaultProps = {
  show: true,
  onClose: () => {},
  reference: null,
  entry: null,
  advanced: false,
};

export default EditValuesetEntryModal;
