import { OPERATION_TYPE, Operation } from "./operation";
import where, { NqlWhereClause } from "./where";

type Paging = {
  number: number | null;
  size: number | null;
};

/**
 * @interface
 * @category NqlQuery
 */
interface NqlSelect {
  whereObj: NqlWhereClause;
  attributesString: string;
  orderByString: string | null;
  pagingString: string | null;
  scopeString: string | null;
  count: boolean;
  includeHidden: boolean;

  operation: Operation | null;

  where(whereClauses: Record<string, any>): NqlSelect;
  orWhere(whereClauses: Record<string, any>): NqlSelect;
  filter(operation: Operation): NqlSelect;
  isNotDeleted(): NqlSelect;
  isDeleted(): NqlSelect;
  orderBy(orderClause: Record<string, any>): NqlSelect;
  paging(paging: Paging): NqlSelect;
  subtree(state: boolean): NqlSelect;
  oneLevel(state: boolean): NqlSelect;
  hidden(): NqlSelect;
  toString(): string;
}

/**
 * NQL select query.
 * @category NqlQuery
 * @author Patrik Pancisin
 * @class select
 * @classdesc Building a query starts with this generator.
 * @param {string[]} attributes - List of nscale properties that are fetched.
 */
const select = (attributes: string[]): NqlSelect => /** @lends select */ ({
  whereObj: where(),
  attributesString: attributes.join(","),
  orderByString: null,
  pagingString: null,
  scopeString: null,
  count: false,
  includeHidden: false,

  operation: null,

  /**
   * Select where clause (AND operator).
   * @instance
   * @param whereClauses - Object of where conditions.
   * @returns Select instance.
   */
  where(whereClauses = {}) {
    this.whereObj.where(whereClauses);
    return this;
  },

  /**
   * Select where clause (OR operator).
   * @instance
   * @param whereClauses - Object of where conditions.
   * @param groupOp - Group operator
   * @returns Select instance.
   */
  orWhere(whereClauses = {}, groupOp = "|") {
    this.whereObj.orWhere(whereClauses, groupOp);
    return this;
  },

  filter(operation: Operation) {
    if (
      operation != null &&
      operation._type === OPERATION_TYPE &&
      operation._uuid != null
    ) {
      this.operation = operation;
      return this;
    }

    throw new Error("Invalid operation object provided as a filter!");
  },

  /**
   * Add "delete" state - not deleted where clause.
   * @returns Select instance.
   */
  isNotDeleted() {
    return this.where({ deletestate: () => "=$none" });
  },

  /**
   * Add "deletete" state - is deleted where clause.
   * @returns Select instance.
   */
  isDeleted() {
    return this.where({ deletestate: () => "=$logically" });
  },

  /**
   * Specify order by query clause.
   * @param orderClause - Object specifying order by conditions.
   * @returns Select instance.
   */
  orderBy(orderClause = {}) {
    this.orderByString = Object.entries(orderClause).reduce(
      (acc, [propName, value], idx) => {
        return `${acc}${idx !== 0 ? ", " : " "}${propName} ${value}`;
      },
      "orderby"
    );

    this.orderByString =
      Object.keys(orderClause).length > 0 ? this.orderByString : "";

    return this;
  },

  /**
   * Define query result paging.
   * @param {object} paging
   * @param {number} paging.number - The page number of the result paging.
   * @param {number} paging.size - The number of results per page.
   * @returns Select instance.
   */
  paging({ number, size } = { number: null, size: null }) {
    if (number != null && size != null) {
      this.pagingString = `paging(number=${number}, size=${size})`;
      this.count = true;
    }

    return this;
  },

  /**
   * Execute subtree query - oposite of onelevel.
   * @param state - Whether subtree is enabled.
   * @returns Select instance.
   */
  subtree(state = true) {
    if (state) {
      this.scopeString = "scope subtree";
    } else {
      this.scopeString = "";
    }

    return this;
  },

  /**
   * Eecute one level query - oposite of subtree.
   * @param state - Whether onelevel query is enabled.
   * @returns Select instance.
   */
  oneLevel(state = true) {
    if (state) {
      this.scopeString = "scope onelevel";
    } else {
      this.scopeString = "";
    }

    return this;
  },

  /**
   * Return hidden results.
   * @returns - Select instance.
   */
  hidden() {
    this.includeHidden = true;
    return this;
  },

  /**
   * Turn NQLSelect instance into nql query string.
   * @returns {string} Nql query string.
   */
  toString() {
    let query = `select ${this.attributesString}`;

    const filterString = this.operation != null ? this.operation.toNql() : null;
    const whereString = this.whereObj.toString();

    const whereClause = [whereString, filterString]
      .filter((str) => str != null && str != "")
      .map((str) => `(${str})`)
      .join("&");

    if (whereClause != null && whereClause != "") {
      query += ` where ${whereClause}`;
    }

    if (this.orderByString != null) {
      query += ` ${this.orderByString}`;
    }

    if (this.pagingString != null) {
      query += ` ${this.pagingString}`;
    }

    if (this.scopeString != null) {
      query += ` ${this.scopeString}`;
    }

    if (this.count) {
      query += " count";
    }

    if (this.includeHidden) {
      query += " hidden";
    }

    return query;
  },
});

export default select;
