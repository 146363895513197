const projectFolderTabs = {
    METADATA: "METADATA",
    ACCESS_MANAGEMENT: "ACCESS_MANAGEMENT",
    SPOCS: "SPOCS",
    DASHBOARD: "DASHBOARD",
    REMINDERS: "REMINDERS",
    WORKFLOW_STATUS: "WORKFLOW_STATUS",
    CHAT: "CHAT",
    DOCUMENTS: "DOCUMENTS",
    EXPLORER: "EXPLORER",
}

export default projectFolderTabs;