import React, { useCallback, useContext, useMemo, useState } from "react";
import versionReviewProperties from "../../../contants/versionReviewProperties";
import { ProcessInstance, Version } from "../../../apis/repositoryApi";
import { Button, Card, CloseButton, Modal, Nav, Tab } from "react-bootstrap";
import useProperties from "../../../hooks/useProperties";
import { groupType } from "cosmos-config/generator";
import SimpleGroupedUniversalForm from "../../SimpleGroupedUniversalForm";
import SimpleTable from "../../SimpleTable";
import { ReviewHistoryContext } from "./ReviewHistory";
import { trimUINumber, resourceCorrectionOfGateway } from "cosmos-config/utils";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import CommentsList from "../comments/CommentsList";

interface ReviewFormProps {
  processInstance?: ProcessInstance;
}

const ReviewForm = ({ processInstance }: ReviewFormProps) => {
  const { getBusinessProcessGroups, getBusinessProcessProperties } =
    useProperties();

  const resource = useMemo(() => {
    if (processInstance == null) {
      return {};
    }

    const properties = getBusinessProcessProperties(
      processInstance.processDefinitionKey
    );

    return resourceCorrectionOfGateway(
      {
        ...(processInstance?.variables || {}),
        ...processInstance,
      },
      properties
    );
  }, [processInstance, getBusinessProcessProperties]);

  const groups = useMemo(() => {
    if (processInstance?.processDefinitionKey != null) {
      const businessProcessGroups = getBusinessProcessGroups(
        processInstance.processDefinitionKey
      );

      return businessProcessGroups.filter(
        (g) =>
          g.type === groupType.DATATABLE ||
          ["start", "overview"].includes(g.dedicated || "")
      );
    }
  }, [getBusinessProcessGroups, processInstance]);

  return (
    <SimpleGroupedUniversalForm
      resource={resource}
      groups={groups}
      grouping={false}
      disabled
      columns={2}
    />
  );
};

type ResourceVersionCardProps = {
  version?: Version | null;
};

const ResourceVersionCard = ({ version }: ResourceVersionCardProps) => {
  const { groups, properties } = useProperties();

  const changedProps = useMemo(
    () => (version?.changes || []).map((c) => c.name),
    [version]
  );

  const resource = useMemo(() => {
    if (version?.resource == null) {
      return {};
    }

    return resourceCorrectionOfGateway(version?.resource, properties);
  }, [version, properties]);

  return (
    <SimpleGroupedUniversalForm
      groups={groups}
      grouping
      columns={2}
      resource={resource}
      highlightedProperties={changedProps}
      disabled
    />
  );
};

type ResourceVersionModalProps = {
  show: boolean;
  onClose: () => void;
  versionTime?: number | null;
};

function* generateTitle(version: Version | null) {
  yield "Reviewed Metadata";

  if (version == null) {
    return;
  }

  if (version.creatorCommonName != null) {
    yield " by ";
    yield trimUINumber(version.creatorCommonName);
  }

  if (version.lastModifiedDate != null) {
    yield " at ";
    yield DateTime.fromMillis(version.lastModifiedDate).toLocaleString(
      DateTime.DATETIME_MED
    );
  }

  yield ".";
}

const ResourceVersionModal = ({
  show,
  onClose,
  versionTime,
}: ResourceVersionModalProps) => {
  const { getResourceVersion } = useContext(ReviewHistoryContext);

  const version = useMemo(() => {
    if (versionTime == null) {
      return null;
    }

    return getResourceVersion(versionTime);
  }, [versionTime, getResourceVersion]);

  return (
    <Modal show={show} size="xl">
      <Modal.Header>
        <Modal.Title>{[...generateTitle(version)]}</Modal.Title>
        <CloseButton className="" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <ResourceVersionCard version={version} />
      </Modal.Body>
    </Modal>
  );
};

interface ReviewOverviewProps {
  processInstance?: ProcessInstance;
}

const ReviewOverview = ({ processInstance }: ReviewOverviewProps) => {
  const { getVersionResources, getReviewComments } =
    useContext(ReviewHistoryContext);
  const [selectedVersion, setSelectedVersion] = useState<number | undefined>();

  const versions = useMemo(
    () => getVersionResources(processInstance),
    [getVersionResources, processInstance]
  );

  const comments = useMemo(
    () => getReviewComments(processInstance),
    [getReviewComments, processInstance]
  );

  const renderVersionsCell = useCallback(
    (id: string, value: any, label: string, version: any) => {
      let correctLabel = label;
      if (
        id === "ReviewContributor" &&
        (value == null || (Array.isArray(value) && value.length === 0))
      ) {
        correctLabel = trimUINumber(version.creatorCommonName) as string;
      }

      if (!version.resourceVersion) {
        return <div className="text-muted">{correctLabel}</div>;
      }

      if (id === "ReviewContributor") {
        return (
          <span>
            <Button
              className="p-0"
              variant="link"
              onClick={() => setSelectedVersion(version.lastModifiedDate)}
            >
              <FontAwesomeIcon icon={faEye} className="mr-2" />
              {correctLabel}
            </Button>
          </span>
        );
      }

      return correctLabel;
    },
    []
  );

  return (
    <Card>
      <Tab.Container defaultActiveKey="overview">
        <Card.Header>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="overview">Overview</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="metadata">Metadata/Status Updates</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="comments">
                Review Comments ({comments?.length})
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          <Tab.Content>
            <Tab.Pane eventKey="overview">
              <ReviewForm processInstance={processInstance} />
            </Tab.Pane>
            <Tab.Pane eventKey="metadata">
              <SimpleTable
                condensed
                data={versions}
                properties={versionReviewProperties}
                renderCell={renderVersionsCell}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="comments">
              <CommentsList comments={comments} disabled />
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>

      <ResourceVersionModal
        show={selectedVersion != null}
        versionTime={selectedVersion}
        onClose={() => setSelectedVersion(undefined)}
      />
    </Card>
  );
};

export default ReviewOverview;
