import Axios from 'axios';
import authInterceptor from './authInterceptor';
import errorResponseInterceptor from './errorResponseInterceptor';

const BASE_URL = process.env.REACT_APP_GATEWAY_URL;

export default {
  build() {
    const axios = Axios.create({
      baseURL: BASE_URL,
      auth: false,
    });

    axios.interceptors.request.use(authInterceptor);
    axios.interceptors.response.use(
      (response) => response,
      errorResponseInterceptor
    );

    return axios;
  },
};
