import React, { useMemo } from "react";
import { ResourceAccessManagementProps } from "./components/ResourceAccessManagement";
import AccessManagementProvider from "./providers/AccessManagementProvider";
import { Alert } from "react-bootstrap";
import _ from "lodash";
import useResourcePermissions from "../../hooks/permissions/useResourcePermissions";
import SimpleAccessTable from "./components/SimpleAccessTable";

const QuickAccessManagement = ({
  disabled,
  onChange,
  resource,
  onResourceNeeded,
}: ResourceAccessManagementProps) => {
  const { isMaintainer } = useResourcePermissions(resource || []);

  const resourceId = useMemo(() => resource?.id, [resource]);

  if (resourceId == null) {
    return (
      <Alert className="mb-0" variant="info">
        Access Management available after save.
      </Alert>
    );
  }

  return (
    <AccessManagementProvider
      resourceId={resourceId}
      onResourceNeeded={onResourceNeeded}
    >
      <SimpleAccessTable disabled={disabled || !isMaintainer()} />
    </AccessManagementProvider>
  );
};

export default QuickAccessManagement;
