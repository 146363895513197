export const FETCH_ALL_PROJECT_MEMBERS = "project/fetchAllProjectMembers";
export const ADD_PROJECT_MEMBER = "project/addProjectMember";
export const IMPORT_PROJECT_MEMBERS = "project/importProjectMembers";
export const FETCH_PROJECT_ACCESS_REQUESTS =
  "project/fetchProjectAccessRequests";
export const REFUSE_PROJECT_ACCESS_REQUEST =
  "project/refuseProjectAccessRequest";
export const REQUEST_PROJECT_ACCESS = "project/requestProjectAccess";

export const FETCH_PROJECT_SPOCS = "project/fetchProjectSpocs";
export const CREATE_PROJECT_SPOC = "project/createProjectSpoc";
export const CREATE_PROJECT_SPOC_REQUEST = "project/createProjectSpocRequest";
export const UPDATE_PROJECT_SPOC = "project/updateProjectSpoc";
export const TOGGLE_ACTIVE_PROJECT_SPOC = "project/toggleActiveProjectSpoc";
export const DELETE_PROJECT_SPOCS = "project/deleteProjectSpocs";
export const EXPORT_PROJECT_SPOCS_EXCEL = "project/exportProjectSpocsExcel"
export const FETCH_PROJECTS = "project/fetchProjects";
export const INVITE_PROJECT_MEMBER = "project/inviteMember";
export const CREATE_PROJECT_MEMBER = "project/createMember";
