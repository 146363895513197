/* eslint-disable func-names */
import {
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {
  setComments,
  setCollaborationLoading,
  setLatestChatComments,
  reloadComments,
} from '../../Actions/collaboration';
import * as actions from '../../Actions/types';
import commentApi from '../../Api/comment';
import { notify } from '../../Actions/ui';
import callApi from '../Effects/callApi';
import { subscribeComments } from '../../Actions/websocket';
import teamspaceSaga from './teamspaceSaga';
import { exportData } from '../repository/exportExcelSaga';
import { getComments } from '../../Selectors/collaboration';
import _ from 'lodash';
import comment from '../../Constants/comment';
import { uuidCode } from '../../Utils';
import { DateTime } from 'luxon';
import complete from '../Effects/complete';

function* fetchComments(resourceId, parameter) {
  try {
    yield put(setComments([]));
    yield put(
      setCollaborationLoading(true, 'Loading comments for opened resource.')
    );
    const results = yield callApi(
      commentApi.getComments,
      resourceId,
      parameter
    );
    yield put(setComments(results));

    return results;
  } catch (err) {
    yield put(setComments([]));
    yield put(notify('Failed to load comments.', 'error'));
  } finally {
    yield put(setCollaborationLoading(false));
  }
}

function* postComment(action) {
  const { resourceId, message, options } = action.payload;
  const { mentions, parameter, nodeId, position } = options;

  const comment = {
    message,
    mentions,
    position,
  };

  try {
    yield put(setCollaborationLoading(true, 'Saving submitted comment.'));
    yield callApi(
      commentApi.postComment,
      resourceId,
      parameter,
      comment,
      nodeId
    );

    yield complete(action);

    // to ensure that you see latest comments.
    yield put(reloadComments());
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setCollaborationLoading(false));
    // yield put(fetchCommentsAction(resourceId, parameter));
  }
}

export default function* collaborationSaga() {
  yield takeEvery(actions.comment.POST_COMMENT, postComment);
  yield takeLatest(actions.comment.FETCH_LATEST_CHAT_COMMENTS, function* () {
    try {
      yield put(setCollaborationLoading(true, 'Loading chat messages'));
      const comments = yield callApi(commentApi.getUserLatestChatComments);
      yield put(setLatestChatComments(comments));
    } catch (err) {
      console.err(err);
      yield put(notify(`Error while loading chat message: ${err.message}`));
    } finally {
      yield put(setCollaborationLoading(false));
    }
  });

  yield fork(function* () {
    let commentsTask;

    while (true) {
      const action = yield take(actions.comment.FETCH_COMMENTS);
      const { payload } = action;
      const { resourceId, options } = payload;
      const { parameter, subscribe } = options;

      if (subscribe) {
        yield put(subscribeComments(resourceId));
      }

      if (commentsTask != null) {
        yield cancel(commentsTask);
      }

      commentsTask = yield fork(function* () {
        while (true) {
          const comments = yield call(fetchComments, resourceId, parameter);

          yield complete(action, comments);

          const { type } = yield take([
            actions.comment.CLOSE_COMMENTS,
            actions.comment.RELOAD_COMMENTS,
          ]);

          if (type === actions.comment.CLOSE_COMMENTS) {
            break;
          }
        }
      });
    }
  });

  yield takeLeading(
    [actions.comment.UPDATE_COMMENT, actions.comment.DELETE_COMMENT],
    function* (action) {
      const { type, payload } = action;
      const { commentId } = payload;

      try {
        yield put(setCollaborationLoading(true));
        if (type === actions.comment.DELETE_COMMENT) {
          yield callApi(commentApi.deleteComment, commentId);
        } else {
          const { comment } = payload;
          yield callApi(commentApi.updateComment, commentId, comment);
        }

        yield complete(action);

        yield put(reloadComments());
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setCollaborationLoading(false));
      }
    }
  );

  yield takeLeading(actions.comment.EXPORT_COMMENTS_EXCEL, function* () {
    const comments = yield select(getComments);

    const sortedComments = _.orderBy(
      comments,
      [
        'position.pageNumber',
        'position.y',
        (c) => {
          return DateTime.fromMillis(c.createdAt).toMillis();
        },
      ],
      ['asc', 'asc', 'desc']
    );

    const allComments = _.flatMap(sortedComments, (c) => [
      c,
      ..._(c.comments || []),
    ]).map((c) => ({
      ...c,
      message: c.message.replaceAll(/<[^>]*>/g, ''),
      code: uuidCode(c.id, 6),
    }));

    yield call(exportData, allComments, comment, 'Comments', []);
  });

  yield fork(teamspaceSaga);
}
