export const SET_UPLOAD_IN_PROGRESS = 'upload/setInProgress';
export const SET_UPLOAD_PROGRESS = 'upload/setProgress';
export const UPDATE_UPLOAD_PROGRESS = 'upload/updateProgress';
export const ADD_FILES_TO_UPLOAD = 'upload/addFiles';
export const UNZIP_FILES_TO_UPLOAD = 'upload/unzipFiles';
export const SET_IS_UNZIPPING = 'upload/setIsUnzipping';
export const REMOVE_FILES_FROM_UPLOAD = 'upload/removeFiles';
export const TOGGLE_UPLOAD_FILE_CONFIDENTIAL = 'upload/toggleFileConfidential';
export const TOGGLE_UPLOAD_FILE_REFERENCE = 'upload/toggleFileReference';
export const SET_UPLOAD_FILES = 'upload/setFiles';
export const CLEAR_UPLOAD_DATA = 'upload/clearData';
export const UPLOAD_PROCESS_SUCCESS = 'upload/processSuccess';
export const UPLOAD_PROCESS_CANCEL = 'upload/processCancel';
export const SET_UPLOAD_LOADING = 'upload/setLoading';
export const SET_UPLOAD_CHECK_LOADING = 'upload/setCheckLoading';
export const ADD_EXISTING_DOCUMENTS = 'upload/addExistingDocuments';
export const SET_DUPLICATES = 'upload/setDuplicates';
export const ADD_UPLOADED_DOCUMENT_ID = 'upload/addUploadedDocumentId';
export const START_METADATA_ASSIGNMENT = 'upload/startMetadataAssignment';
export const SKIP_METADATA_ASSIGNMENT = 'upload/skipMetadataAssignment';
export const UPDATE_UPLOAD_FILE_ORDER = 'upload/updateFileOrder';
export const UPDATE_UPLOAD_FILE_META = 'upload/updateFileMeta';
export const ADD_PLACEHOLDER_TO_UPLOAD = 'upload/addPlaceholder';
export const UPLOAD_FILES = 'upload/uploadFiles';
export const UPDATE_FILE_CONTENT = 'upload/updateFileContent';
export const UPDATE_DOCUMENT_CONTENT = 'upload/updateDocumentContent';
export const UPLOAD_FILES_NEW = 'upload/uploadFilesNew';
export const UPDATE_UPLOAD_OPTIONS = 'upload/updateOptions';
export const UPDATE_UPLOAD_STATS = 'upload/updateUploadStats';
export const UPDATE_FILE_HASH = 'upload/updateFileHash';
