import React, { useCallback } from "react";
import { Provider } from "react-redux";
import ValuesetProvider from "./ValuesetProvider";
import CoreReduxContext from "../contexts/CoreReduxContext";
import store from "../store";
import ProjectProvider from "./ProjectProvider";
import { I18nextProvider } from "react-i18next";
import CosmosCoreContext from "../contexts/CosmosCoreContext";
import i18n from "../i18n";
import { Project } from "../contexts/ProjectContext";
import UserDataProvider from "../providers/UserDataProvider";
import UserSettingsProvider from "../providers/UserSettingsProvider";
import UserRelationProvider from "../modules/user-relation/providers/UserRelationProvider";
import ProjectCommandProvider from "../providers/ProjectCommandProvider";
import SessionProvider from "../modules/authentication/providers/SessionProvider";
import QueryClientInjector from "./QueryClientInjector";
import { CosmosThemeProvider } from "cosmos-components";

export interface CosmosCoreProviderProps {
  children?: React.ReactNode;

  /**
   * @description Default project code to open when initialize.
   */
  defaultProjectCode?: string;

  /**
   * @description Callback triggered when project selection happens.
   * @param project
   * @returns
   */
  onProjectOpen?: (project: Project) => void;

  /**
   * @description Ask Patrik for more information.
   * @param url
   * @returns
   */
  onCallImport: (url: string) => Promise<any>;

  /**
   * Provide default username which could
   * be used as a login hint for fluent sso.
   */
  defaultUsername?: string;
}

/**
 * Core Cosmos functionality provider.
 */
const CosmosCoreProvider = ({
  children,
  defaultProjectCode,
  onProjectOpen,
  onCallImport,
  defaultUsername,
}: CosmosCoreProviderProps) => {
  return (
    <CosmosThemeProvider>
      <Provider store={store} context={CoreReduxContext}>
        <I18nextProvider i18n={i18n}>
          <CosmosCoreContext.Provider
            value={{
              callImport: onCallImport,
            }}
          >
            <SessionProvider defaultUsername={defaultUsername}>
              <QueryClientInjector>
                <UserDataProvider>
                  <ProjectCommandProvider>
                    <ProjectProvider
                      defaultProjectCode={defaultProjectCode}
                      onProjectOpen={useCallback(
                        (project: Project) => {
                          if (onProjectOpen != null) {
                            onProjectOpen(project);
                          }
                        },
                        [onProjectOpen]
                      )}
                    >
                      <UserRelationProvider>
                        <UserSettingsProvider>
                          <ValuesetProvider>{children}</ValuesetProvider>
                        </UserSettingsProvider>
                      </UserRelationProvider>
                    </ProjectProvider>
                  </ProjectCommandProvider>
                </UserDataProvider>
              </QueryClientInjector>
            </SessionProvider>
          </CosmosCoreContext.Provider>
        </I18nextProvider>
      </Provider>
    </CosmosThemeProvider>
  );
};

export default CosmosCoreProvider;
