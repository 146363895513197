import { DateTime } from 'luxon';
import {
  group,
  property,
  systemProperty,
  conditionalGroup,
  valuesetSource,
  principalType,
} from 'cosmos-config/generator';

export default [
  group('Adjust', 'Adjust Sign-Off')
    .dedicatedTo('Adjust Signoff')
    .children([
      systemProperty('responsible').resolveUsername('assignedpositionid'),
      systemProperty('WF_Providers', 'WF_Providers', true).multiAttribute(
        'WF_Providers'
      ),
      property('End').booleanAttribute('WF_AbortWF').useYesNo().optional(),
    ]),
  conditionalGroup('Adjustments', 'adjustments')
    .hiddenUntil('WF_AbortWF', false, null)
    .translate('common.adjustments')
    .dedicatedTo('Adjust Signoff')
    .children([
      property('Providers')
        .selectableMemberAttribute(
          'RV_New',
          principalType.POSITION,
          'PASSO_PROVIDER'
        )
        .insertable()
        .optional()
        .multiAttribute('RV_New'),
      property('Viewers')
        .selectableMemberAttribute(
          'floatingfileprincipalid',
          principalType.POSITION
        )
        .optional()
        .multiAttribute('floatingfileprincipalid'),
      property('Due Date')
        .dateAttribute('WF_DueDate', 'UTC+2')
        .minValue(() => {
          return DateTime.now().minus({ days: 1 }).toMillis();
        })
        .onUpdate((value) => {
          if (value != null) {
            return DateTime.fromMillis(value, { zone: 'UTC+2' })
              .endOf('day')
              .toMillis();
          }

          return value;
        })
        .excludeTime(),
    ]),
  conditionalGroup('Abortion', 'abortion')
    .hiddenUntil('WF_AbortWF', true)
    .translate('common.abortion')
    .dedicatedTo('Adjust Signoff')
    .children([
      property('Final Decision')
        .selectableAttribute(
          'WF_Decision',
          'Final_Decision',
          valuesetSource.STATIC_VALUESET
        )
        .radio()
        .valueset([
          { label: 'Sign-Off', value: 'signoff' },
          { label: 'No Sign-Off', value: 'nosignoff' },
        ])
        .excludeTable(),
      property('Reason', 'WFD_ReviewRemarks').textArea().optional(),
    ]),
];
