import React, { useEffect, useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { Button } from "react-bootstrap";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import ValuePickerWrapper, {
  ValuesetPickerWrapperProps,
} from "../value-picker-wrapper/ValuePickerWrapper";
import {
  InputValueHandler,
  PancoInputElementProps,
} from "../../types/PancoInputElemenProps";

declare type UpDownPickerProps = {
  children: React.ReactNode;
  onIncrease: () => void;
  onDecrease: () => void;
};

const UpDownPicker = ({
  children,
  onIncrease,
  onDecrease,
}: UpDownPickerProps) => {
  return (
    <div className="flex-grow-1 text-center border-right">
      <Button
        variant="link"
        block
        className="p-3"
        onClick={() => {
          onIncrease();
        }}
      >
        <FontAwesomeIcon icon={faChevronUp} size="lg" />
      </Button>

      <div className="p-2">{children}</div>

      <Button
        variant="link"
        block
        className="p-3"
        onClick={() => {
          onDecrease();
        }}
      >
        <FontAwesomeIcon icon={faChevronDown} size="lg" />
      </Button>
    </div>
  );
};

UpDownPicker.propTypes = {
  children: PropTypes.node,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
};

UpDownPicker.defaultProps = {
  children: null,
  onIncrease: () => {},
  onDecrease: () => {},
};

export interface YearMonthPickerProps
  extends Omit<ValuesetPickerWrapperProps<number[]>, "children">,
  PancoInputElementProps,
  InputValueHandler<number[]> {}

declare type SelectionState = {
  year: number | null;
  month: number | null;
};

const YearMonthPicker = ({
  name,
  value,
  onChange,
  ...props
}: YearMonthPickerProps) => {
  const dateTime = useRef(DateTime.now());

  const [state, setState] = useState<SelectionState>({
    year: dateTime.current.year,
    month: null,
  });

  const monthsActive = useMemo(() => {
    if (Array.isArray(value) && value.length === 2) {
      const dt = DateTime.fromMillis(value[0]);
      const { months } = dt.diff(DateTime.fromMillis(value[1]), "months");
      return Math.abs(months) < 2;
    }

    return false;
  }, [value]);

  const refreshState = (updateMonth = false, clearMonth = false) => {
    const { year, month } = dateTime.current;

    if (updateMonth) {
      setState({
        year,
        month,
      });
    } else if (clearMonth) {
      setState({ year, month: null });
    } else {
      setState((s) => ({ year, month: s.month }));
    }
  };

  useEffect(() => {
    if (Array.isArray(value) && value.length === 2) {
      dateTime.current = DateTime.fromMillis(value[0]) as DateTime;
      refreshState(monthsActive);
    }
  }, [value, monthsActive]);

  const update = (property: string, amount = 1) => {
    dateTime.current = dateTime.current.plus({ [property]: amount });
    refreshState(property === "month");
  };

  return (
    <ValuePickerWrapper<number[]>
      {...props}
      onClear={() => {
        if (onChange != null) {
          onChange({ name, value: [] });
        }

        dateTime.current = DateTime.now();
        refreshState(false, true);
      }}
      content={() => {
        if (Array.isArray(value) && value.length === 2) {
          const dt = DateTime.fromMillis(value[0]);
          return monthsActive ? `${dt.year}/${dt.month}` : dt.year;
        }

        return null;
      }}
      selection={Array.isArray(value) && value.length > 0}
      clearable
      actionIcon={() => <FontAwesomeIcon icon={faCalendar} />}
      onSelect={(v) => {
        if (onChange != null) {
          onChange({ name, value: v });
        }
      }}
    >
      {({ onSelect }) => (
        <div>
          <div className="d-flex">
            <UpDownPicker
              onIncrease={() => update("year")}
              onDecrease={() => update("year", -1)}
            >
              {state.year || <small className="text-muted">Year</small>}
            </UpDownPicker>
            <UpDownPicker
              onIncrease={() => update("month")}
              onDecrease={() => update("month", -1)}
            >
              {state.month || <small className="text-muted">Month</small>}
            </UpDownPicker>
          </div>
          <Button
            variant="dark"
            block
            className="p-3"
            onClick={() => {
              if (state.month == null) {
                onSelect([
                  dateTime.current.startOf("year").toMillis(),
                  dateTime.current.endOf("year").toMillis(),
                ]);
              } else {
                onSelect([
                  dateTime.current.startOf("month").toMillis(),
                  dateTime.current.endOf("month").toMillis(),
                ]);
              }
            }}
          >
            Done
          </Button>
        </div>
      )}
    </ValuePickerWrapper>
  );
};

YearMonthPicker.defaultProps = {
  value: [],
};

export default YearMonthPicker;
