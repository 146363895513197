import { createContext } from "react";

export interface DropzoneContextValue {
  contextPresent: boolean;
  activeZone: string | null;
  registerSubzone: (zoneId: string, metadata?: Record<string, any>) => void;
  unregisterSubzone: (zoneId: string) => void;
}

const DropzoneContext = createContext<DropzoneContextValue>({
  contextPresent: false,
  activeZone: null,
  registerSubzone: () => {},
  unregisterSubzone: () => {},
});

DropzoneContext.displayName = "DropzoneContext";

export default DropzoneContext;
