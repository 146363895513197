import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Collapse, Row } from "react-bootstrap";
import _ from "lodash";
import { Wrap } from "cosmos-components";
import classNames from "classnames";
import { propertyType } from "cosmos-config/generator";
import { useMemo } from "react";
import { Property } from "cosmos-config/lib/property/property";
import { CosmosFormGroupProps } from "./CosmosFormGroup";

export interface UniversalFormGroupProps extends CosmosFormGroupProps {
  wrapped?: boolean;
  columns?: number;
  filter?: Record<string, any>;
  children?: (property: Property, wide?: boolean) => React.ReactNode;
}

const UniversalFormGroup = ({
  className,
  group,
  children,
  defaultCollapse,
  wrapped,
  columns,
  filter,
}: UniversalFormGroupProps) => {
  const [collapsed, setCollapsed] = useState(defaultCollapse);
  const [properties, setProperties] = useState<Property[]>([]);
  const { t } = useTranslation("property");

  useEffect(() => {
    if (group == null) {
      return;
    }

    const newProperties = group.properties.filter(
      (c) => !c.isHidden(filter || {})
    );

    setProperties((oldProperties) => {
      return _(oldProperties)
        .map("name")
        .isEqual(_(newProperties).map("name").value())
        ? oldProperties
        : newProperties;
    });
  }, [group, filter]);

  const wrapWithCard = (content: React.ReactNode) => {
    return (
      <Card
        border="dark"
        className={classNames(className)}
        key={`group-${group?.name}`}
      >
        <Card.Header
          onClick={() => setCollapsed(!collapsed)}
          style={{ cursor: "pointer" }}
        >
          {group?.translation ? t(group.translation) : group?.label}
        </Card.Header>
        <Collapse in={!collapsed}>
          <Card.Body>{content}</Card.Body>
        </Collapse>
      </Card>
    );
  };

  const textAreaProperty = useMemo(() => {
    const lastProperty = _(properties).last();
    if (lastProperty?.type === propertyType.TEXTAREA) {
      return lastProperty;
    }

    return null;
  }, [properties]);

  const columnsCount = useMemo(() => columns || 2, [columns]);

  const splitPoint = useMemo(() => {
    const substractBy = Number(textAreaProperty != null);
    return Math.ceil((properties.length - substractBy) / columnsCount);
  }, [textAreaProperty, properties.length, columnsCount]);

  return (
    <Wrap with={wrapWithCard} when={group?.name != null && wrapped}>
      <Row>
        {new Array(columns).fill(1).map((v, idx) => {
          const startIndex = idx * splitPoint;

          return (
            <Col md={12 / columnsCount} key={`column-${idx}`}>
              {properties
                .filter((property, idx, arr) => {
                  return (
                    arr.length > idx + 1 ||
                    property.type !== propertyType.TEXTAREA
                  );
                })
                .slice(startIndex, startIndex + splitPoint)
                .map((prop) => {
                  if (children != null) {
                    return children(prop);
                  }

                  return React.Fragment({});
                })}
            </Col>
          );
        })}
      </Row>

      {textAreaProperty != null &&
        children != null &&
        children(textAreaProperty, true)}
    </Wrap>
  );
};

UniversalFormGroup.defaultProps = {
  defaultCollapse: true,
  columns: 2,
};

export default UniversalFormGroup;
