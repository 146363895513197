import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { propertyType } from "cosmos-config/generator";
import ValuesetContext from "../contexts/ValuesetContext";
import useDebounce from "../hooks/useDebounce";
import {
  addItemToValueset,
  exportValuesetsExcel,
  fetchValuesets,
} from "../actions/docareaActions";
import useCoreSelector from "../hooks/useCoreSelector";
import {
  getLoadingValueset,
  getMasterValuesets,
  getValuesets,
  getValuesetsMap,
} from "../selectors/docareaSelector";
import useCoreDispatch from "../hooks/useCoreDispatch";
import EditValuesetEntryModal from "../modules/valueset-editor/EditValuesetEntryModal";
import useProject from "../hooks/useProject";
import useProperties from "../hooks/useProperties";
import {
  Property,
  SelectableAttributeProperty,
} from "cosmos-config/lib/property/property";
import { ValuesetItem } from "../contants/valuesetItem";

const actions = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
};

interface ValuesetProviderState {
  showModal: boolean;
  property: Property | null;
  reference: string | null;
  valuesetEntry: ValuesetItem | null;
  advanced: boolean;
}

const initialState = {
  showModal: false,
  property: null,
  reference: null,
  valuesetEntry: null,
  advanced: true,
} as ValuesetProviderState;

const reducer = (state: ValuesetProviderState, action: any) => {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return {
        ...state,
        showModal: true,
        property: action.property,
        reference: action.reference,
        valuesetEntry: action.valuesetEntry,
        advanced: action.advanced,
      };
    case actions.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
        property: null,
        reference: null,
      };
    default:
      return state;
  }
};

interface ValuesetProviderProps {
  children: React.ReactNode;
}

const ValuesetProvider = ({ children }: ValuesetProviderProps) => {
  const dispatch = useCoreDispatch();
  const [localState, localDispatch] = useReducer(reducer, initialState);
  const clbk = useRef<Function | null>(null);
  const { project } = useProject();

  const { propertiesMap: projectPropertiesMap, properties } = useProperties();

  const valuesetsMap = useCoreSelector(getValuesetsMap);
  const debouncedValuesetsMap = useDebounce(valuesetsMap, 250);

  const [propertiesMap, setPropertiesMap] = useState<Record<string, Property>>(
    {}
  );

  const registerProperties = useCallback((map: Record<string, Property>) => {
    setPropertiesMap((pm) => ({
      ...pm,
      ...map,
    }));
  }, []);

  useEffect(() => {
    registerProperties(projectPropertiesMap);
  }, [projectPropertiesMap, registerProperties]);

  useEffect(() => {
    const valuesetMeta = _(propertiesMap)
      .valuesIn()
      .filter(
        (p) =>
          p.type === propertyType.SELECT ||
          p.type === propertyType.SUGGEST ||
          p.type === propertyType.DATE ||
          p.type === propertyType.RADIO ||
          p.type === propertyType.RESOURCE_SELECT
      )
      .map((p) => p as SelectableAttributeProperty)
      .filter((p) => p.valuesetName != null)
      .map((p) => ({
        valuesetName: p.valuesetName,
        valuesetSource: p.valuesetSource,
        masterValuesetName: p.masterValuesetName,
        data: p.options,
      }))
      .value();

    if (valuesetMeta.length > 0 && project != null) {
      dispatch(fetchValuesets(valuesetMeta));
    }
  }, [propertiesMap, dispatch, project]);

  const getValuesetByName = useCallback(
    (vlstName: string) => {
      if (vlstName == null || vlstName === "") {
        return [];
      }

      const valueset = debouncedValuesetsMap[vlstName];

      return valueset || [];
    },
    [debouncedValuesetsMap]
  );

  const getValuesetByProperty = useCallback(
    (propertyName: string) => {
      const property = propertiesMap[propertyName] || {};

      const { valuesetName, type } = property as SelectableAttributeProperty;

      if (type === propertyType.SELECT) {
        return getValuesetByName(valuesetName);
      }

      return [];
    },
    [getValuesetByName, propertiesMap]
  );

  return (
    <>
      <ValuesetContext.Provider
        value={{
          valuesets: useCoreSelector(getValuesets),
          masterValuesets: useCoreSelector(getMasterValuesets),
          loading: useCoreSelector(getLoadingValueset),
          editValuesetItem: (
            { propertyName, valuesetEntry, advanced },
            callback
          ) => {
            const prop = propertiesMap[propertyName];
            if (prop != null) {
              clbk.current = callback;
              localDispatch({
                type: actions.OPEN_MODAL,
                property: prop,
                valuesetEntry,
                advanced: !!advanced,
              });
            }
          },
          addValuesetItem: (
            { propertyName, reference, advanced },
            callback
          ) => {
            const prop = propertiesMap[propertyName];
            if (prop != null) {
              clbk.current = callback;
              localDispatch({
                type: actions.OPEN_MODAL,
                property: prop,
                reference,
                advanced: !!advanced,
              });
            }
          },
          getValuesetByName,
          getValuesetByProperty,
          registerProperties,
          addTempValuesetItem: useCallback(
            (valuesetName, valuesetItem) => {
              dispatch(addItemToValueset({ valuesetName, valuesetItem }));
            },
            [dispatch]
          ),
          exportAllValuesetsExcel: useCallback(() => {
            const valuesetNames = properties
              .filter((p) =>
                [propertyType.SELECT, propertyType.SUGGEST].includes(p.type)
              )
              .map((p) => (p as SelectableAttributeProperty).valuesetName);

            // @ts-ignore
            dispatch(exportValuesetsExcel({ valuesetNames })).then(
              (exportedContent: { contentUrl: string; fileName: string }) => {
                const { contentUrl, fileName } = exportedContent;
                const link = document.createElement("a");
                link.href = contentUrl;
                link.download = fileName;
                link.dispatchEvent(new MouseEvent("click"));
              }
            );
          }, [dispatch, properties]),
        }}
      >
        {children}

        {localState.property != null && (
          <EditValuesetEntryModal
            show={localState.showModal}
            property={localState.property}
            onClose={(entry: ValuesetItem) => {
              if (clbk.current != null) {
                clbk.current(entry);
              }

              localDispatch({ type: actions.CLOSE_MODAL });
            }}
            reference={localState.reference}
            entry={localState.valuesetEntry}
            advanced={localState.advanced}
          />
        )}
      </ValuesetContext.Provider>
    </>
  );
};

ValuesetProvider.propTypes = {
  children: PropTypes.node,
};

ValuesetProvider.defaultProps = {
  children: null,
};

export default ValuesetProvider;
