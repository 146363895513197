import { DateTime } from 'luxon';
import { property, systemProperty } from 'cosmos-config/generator';

export default [
  property('Delegate')
    .selectableMemberAttribute('principalId')
    .excludeTable()
    .translate('delegate.delegate'),
  property('Delegate', 'displayName').excludeForm(),
  property('Start')
    .dateAttribute('start', 'utc')
    .minValue(() => {
      return DateTime.now().minus({ days: 1 }).toMillis();
    })
    .excludeTime()
    .optional()
    .translate('delegate.start'),
  property('End')
    .dateAttribute('end', 'utc')
    .onUpdate((v) => {
      if (v != null) {
        return DateTime.fromMillis(v, { zone: 'utc' }).endOf('day').toMillis();
      }

      return v;
    })
    .minValue(() => {
      return DateTime.now().minus({ days: 1 }).toMillis();
    })
    .excludeTime()
    .optional()
    .translate('delegate.end'),
  systemProperty('Active', 'active', true)
    .booleanAttribute('active')
    .default(true)
    .useYesNo(),
];
