import React from "react";
import { PropertyEditorModalProps } from "../../types";
import { CloseButton, Modal } from "react-bootstrap";
import ProjectPropertyForm from "../ProjectPropertyForm";
import { Property } from "cosmos-config/lib/property/property";

export interface PropertyEditModalProps extends PropertyEditorModalProps {
  property?: Property;
}

const PropertyEditModal = ({
  show,
  onClose,
  property,
}: PropertyEditModalProps) => {
  return (
    <Modal show={show} size="xl" onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Add Property</Modal.Title>
        <CloseButton className="close-icon" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <ProjectPropertyForm property={property} onUpdate={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export default PropertyEditModal;
