import * as uploadTypes from './upload';
import * as sharepointTypes from './sharepoint';
import * as teamspacesTypes from './teamspaces';
import * as userSettingsTypes from './userSettings';
import * as workflowTypes from './workflow';
import * as uiTypes from './ui';
import * as reportingTypes from './reporting';
import * as importTypes from './import';
import * as businessProcessTypes from './businessProcess';
import * as commentTypes from './comment';
import * as repositoryTypes from './repository';
import * as userdataTypes from './userdata';
import * as documentTypes from './document';
import * as filterFolderTypes from './filterFolder';
import * as websocketTypes from './websocket';
import * as messageTypes from './message';
import * as projectTypes from './project';
import * as versionImportTypes from './versionImport';

export const upload = uploadTypes;
export const sharepoint = sharepointTypes;
export const teamspaces = teamspacesTypes;
export const userSettings = userSettingsTypes;
export const workflow = workflowTypes;
export const ui = uiTypes;
export const reporting = reportingTypes;
export const repositoryImport = importTypes;
export const businessProcess = businessProcessTypes;
export const comment = commentTypes;
export const repository = repositoryTypes;
export const userdata = userdataTypes;
export const document = documentTypes;
export const filterFolder = filterFolderTypes;
export const websocket = websocketTypes;
export const message = messageTypes;
export const project = projectTypes;
export const versionImport = versionImportTypes;
