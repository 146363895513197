import { Col } from "react-bootstrap";
import styled, { css } from "styled-components";

export const contentLayoutColumnStyle = css`
  @media (min-width: 768px) {
    height: 100%;
    overflow-y: auto;
  }
`;

const ContentLayoutColumn = styled(Col)`
  ${contentLayoutColumnStyle}
`;

export default ContentLayoutColumn;
