import React, { useEffect, useMemo, useState } from "react";
import { Button, CloseButton, Modal, Nav } from "react-bootstrap";
import PropTypes from "prop-types";
import SimpleUniversalForm from "../SimpleUniversalForm";
import groupProperties from "../../contants/group";
import useProject from "../../hooks/useProject";
import { ProjectCommandModalProps } from "../../contexts/ProjectCommandContext";
import { ProjectMember } from "../../contexts/ProjectContext";
import _ from "lodash";
import useProjectCompetence from "../../hooks/useProjectCompetence";
import { LoadingOverlay } from "cosmos-components";

export interface GroupEditModalProps extends ProjectCommandModalProps {
  groupName?: string;
  domainName?: string;
}

type ParentGroupType = {
  groupName?: string;
  domainName?: string;
};

const GroupEditModal = ({
  show,
  groupName,
  domainName,
  onClose,
}: GroupEditModalProps) => {
  const { projectName, memberGroups } = useProject();
  const [innerGroup, setInnerGroup] = useState<Partial<ProjectMember> | null>(
    null
  );
  const { loading, createProjectGroup, updateProjectGroup, manageableGroups } =
    useProjectCompetence();
  const [groupData, setGroupData] = useState<ParentGroupType>({
    groupName,
    domainName,
  });

  const groupMap = useMemo(() => _.keyBy(memberGroups, "name"), [memberGroups]);
  const manageableGroupsMap = useMemo(
    () => _.keyBy(manageableGroups, "name"),
    [manageableGroups]
  );

  useEffect(() => {
    if (groupName == null && manageableGroups.length > 0) {
      const g = _.first(manageableGroups);
      setGroupData({
        groupName: g?.name,
        domainName: g?.domainName,
      });
    } else {
      setGroupData({ groupName, domainName });
    }
  }, [domainName, groupName, manageableGroups]);

  useEffect(() => {
    if (show) {
      if (groupData.groupName == null) {
        setInnerGroup({});
      } else {
        setInnerGroup(groupMap[groupData.groupName] || {});
      }
    }
  }, [groupMap, groupData, show]);

  const handleSubmit = () => {
    if (
      innerGroup == null ||
      groupData.groupName == null ||
      groupData.domainName == null
    ) {
      return;
    }

    const closeItself = (member: ProjectMember) => {
      if (onClose != null) {
        onClose(member?.principalId);
      }
    };

    if (innerGroup.name == null) {
      createProjectGroup(
        groupData.groupName,
        groupData.domainName,
        innerGroup
      ).then(closeItself);
    } else {
      updateProjectGroup(
        groupData.groupName,
        groupData.domainName,
        innerGroup
      ).then(closeItself);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        if (onClose != null) {
          onClose();
        }
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <span className="mr-2">Edit Group in</span>
          {projectName}
        </Modal.Title>
        <CloseButton
          className="close-icon"
          onClick={() => {
            if (onClose != null) {
              onClose();
            }
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <LoadingOverlay loading={loading}>
          {groupName == null && (
            <div className="text-center mb-3">
              <Nav
                className="justify-content-center"
                variant="pills"
                activeKey={groupData.groupName}
                onSelect={(name) => {
                  if (name != null) {
                    const g = manageableGroupsMap[name];
                    setGroupData({
                      groupName: g.name,
                      domainName: g.domainName,
                    });
                  }
                }}
              >
                {manageableGroups.map((group) => (
                  <Nav.Item>
                    <Nav.Link eventKey={group.name}>
                      {group.formattedName}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          )}

          <SimpleUniversalForm
            inline
            properties={groupProperties}
            resource={innerGroup || {}}
            onUpdateResource={(id, name, value) => {
              setInnerGroup((g) => ({
                ...(g || {}),
                [name]: value,
              }));
            }}
          />
        </LoadingOverlay>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right">
          <Button onClick={handleSubmit}>
            {innerGroup?.name == null ? "Create Group" : "Update"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

GroupEditModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

GroupEditModal.defaultProps = {
  show: false,
  onClose: () => {},
};

export default GroupEditModal;
