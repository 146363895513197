import {
  conditionalProperty,
  group,
  property,
  systemProperty,
  valuesetSource,
  principalType,
} from "cosmos-config/generator";

export default [
  group("Review", "review")
    .translate("common.review")
    .dedicatedTo("User SignOff")
    .children([
      systemProperty("Providers").multiAttribute("WF_Providers"),
      systemProperty("responsible").resolveUsername("assignedpositionid"),
      property("Due Date")
        .dateAttribute("WF_DueDate")
        .excludeTime()
        .excludeForm(),
      property("Project")
        .selectableAttribute(
          "WF_ProjectName",
          "passo_projectname",
          valuesetSource.CUSTOM_VALUESET
        )
        .informational()
        .excludeForm(),
      property("PASSO Stage")
        .selectableAttribute(
          "WF_PassoStage",
          "passo_stage",
          valuesetSource.CUSTOM_VALUESET
        )
        .informational()
        .excludeForm(),
      property("Decision")
        .selectableAttribute(
          "WF_Decision",
          "Decision",
          valuesetSource.STATIC_VALUESET
        )
        .radio()
        .valueset([
          { label: "Sign-Off", value: "signoff" },
          { label: "No Sign-Off", value: "nosignoff" },
          { label: "Not correct Authority", value: "authoritychange" },
          { label: "Delegate to", value: "delegate" },
        ])
        .excludeTable(),
      property("Requester", "processinitiatorcommonname")
        .useAlternateProperty("Sign-Off Providers", "responsible")
        .excludeForm()
        .excludeUINumber(),
      conditionalProperty("Delegate to")
        .selectableMemberAttribute(
          "WF_User_New",
          principalType.POSITION,
          "PASSO_PROVIDER"
        )
        .hiddenUntil("WF_Decision", "delegate")
        .optional()
        .excludeTable(),
      property("Comments", "WFD_ReviewRemarks")
        // .predefineValue((resource) => {
        //   const { WF_Decision: decision } = resource;

        //   if (Array.isArray(decision) && decision.includes('delegate')) {
        //     // eslint-disable-next-line no-template-curly-in-string
        //     return '${responsible} delegated the task to ${delegate}.';
        //   }

        //   return null;
        // })
        .optional()
        .textArea(1000)
        .excludeTable(),
    ]),
];
