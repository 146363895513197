import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { getMemberGroups, getOpenedProject } from "./projectSelector";
import { CosmosCoreRootState } from "../store";

export const getSelectedGroupName = (state: CosmosCoreRootState) =>
  state.usermanagement.selectedGroup;

export const getSelectedGroup = createSelector(
  [getOpenedProject, getSelectedGroupName, getMemberGroups],
  (project, groupName, memberGroups) => {
    if (project != null) {
      const { groups } = project;
      const groupsMap = _.keyBy(groups, "name");

      if (groupName == null) {
        return null;
      }

      if (groupsMap[groupName] != null) {
        return groupsMap[groupName];
      }

      const memberGroupsMap = _.keyBy(memberGroups, "name");
      return memberGroupsMap[groupName];
    }
  }
);
