import { property } from "cosmos-config/generator";
import { trimUINumber } from "cosmos-config/utils";

export interface ValuesetItem {
  id: string;
  label: string;
  value: string;
  usedCount?: number;
  filter: string | null;
  keywords: string[];
  favourite: boolean;
  defaultItem: boolean;
  obsolete: boolean;
  createdBy: string;
  createdAt: number;
  lastModifiedAt: number;
  lastModifiedBy: string;
}

const valuesetItem = [
  property("Label", "label").excludeForm().translate("valueset_item.label"),
  property("Key", "value").excludeForm().translate("valueset_item.key"),
  property("Used Count", "usedCount").excludeForm(),
  property("Reference", "filter")
    .excludeForm()
    .translate("valueset_item.reference"),
  property("Keywords")
    .multiAttribute("keywords")
    .optional()
    .translate("valueset_item.keywords"),
  property("Favourite")
    .booleanAttribute("favourite")
    .useYesNo()
    .optional()
    .translate("valueset_item.favourite"),
  property("Default")
    .booleanAttribute("defaultItem")
    .useYesNo()
    .default(false)
    .describe(
      "When activated the value is used as a default filter if no other default value is specified."
    )
    .translate("valueset_item.default_item"),
  property("Inactive")
    .booleanAttribute("obsolete")
    .useYesNo()
    .default(false)
    .describe(
      "When activated the item is considered as obsolete and handled differently while dispaying in filters."
    )
    .translate("valueset_item.obsolete"),
  property("Creator", "createdBy")
    .tableCell((v) => trimUINumber(v))
    .excludeForm()
    .translate("valueset_item.creator"),
  property("Creation Date")
    .dateAttribute("createdAt")
    .excludeForm()
    .translate("valueset_item.creation_date"),
  property("Last Modified By", "lastModifiedByCommonName")
    .tableCell((v) => trimUINumber(v))
    .excludeForm()
    .translate("valueset_item.last_modified_by"),
  property("Last Modified At")
    .dateAttribute("lastModifiedAt")
    .excludeForm()
    .translate("valueset_item.last_modified_date")
];

export default valuesetItem;
