import React, { createElement } from "react";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const PaginationLink = styled.a``;

const PaginationLinkDisabled = styled.span``;

type PaginationItemProps = {
  disabled?: boolean;
  onClick?: () => void;
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
};

const LinkWrapper = ({
  disabled,
  onClick,
  children,
  className,
}: PaginationItemProps) => {
  const component = disabled ? PaginationLinkDisabled : PaginationLink;

  return createElement(
    "div",
    { className },
    createElement(component, { onClick }, children)
  );
};

const PaginationItem = styled(LinkWrapper)<PaginationItemProps>`
  &:hover ${PaginationLink} {
    background-color: ${(props) => props.theme.base};
  }

  ${PaginationLink}, ${PaginationLinkDisabled} {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: ${(props) => props.theme.background};
    border: 1px solid #dee2e6;
    border: none;
  }

  ${PaginationLinkDisabled} {
    color: ${(props) => props.theme.muted};
  }

  ${PaginationLink} {
    color: ${(props) => props.theme.background};
    background-color: ${(props) =>
      props.active ? props.theme.base : props.theme.primary};
    text-decoration: none;
    cursor: pointer;
  }
`;

const PaginationComponent = styled.ul`
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  margin: 0;
  display: inline-flex;

  ${PaginationItem}:not(:last-child) {
    margin-right: 3px;
  }
`;

export interface PancoPaginationProps {
  pageIndex: number;
  pageCount: number;
  gotoPage?: (pageNumber: number) => void;
  simple?: boolean;
}

const PancoPagination = ({
  pageIndex,
  pageCount,
  gotoPage,
  simple,
}: PancoPaginationProps) => {
  const handleNavigation = (pageNumber: number) => {
    if (gotoPage != null) {
      gotoPage(pageNumber);
    }
  };

  return (
    <PaginationComponent>
      <PaginationItem
        disabled={pageIndex <= 0}
        onClick={() => handleNavigation(pageIndex - 1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </PaginationItem>
      <PaginationItem
        onClick={() => handleNavigation(0)}
        active={pageIndex === 0}
      >
        1
      </PaginationItem>
      {pageIndex > 2 && !simple ? (
        <PaginationItem disabled>...</PaginationItem>
      ) : null}
      {Array.from(
        new Array(3)
          .fill(0)
          .map((e, i) => pageIndex + (i - 1))
          .filter((e) => e > 0 && e < pageCount - 1),
        (e) => (
          <PaginationItem
            onClick={() => handleNavigation(e)}
            key={e}
            active={pageIndex === e}
          >
            {e + 1}
          </PaginationItem>
        )
      )}
      {pageIndex < pageCount - 3 && !simple ? (
        <PaginationItem disabled>...</PaginationItem>
      ) : null}
      {pageCount > 1 && !simple ? (
        <PaginationItem
          onClick={() => handleNavigation(pageCount - 1)}
          active={pageIndex === pageCount - 1}
        >
          {pageCount}
        </PaginationItem>
      ) : null}
      <PaginationItem
        disabled={pageIndex >= pageCount - 1}
        onClick={() => handleNavigation(pageIndex + 1)}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </PaginationItem>
    </PaginationComponent>
  );
};

PancoPagination.defaultProps = {
  pageIndex: 0,
  pageCount: 0,
  simple: false,
};

export default PancoPagination;
