import { createContext } from "react";

export interface ResourceTypeContextValue {
  addProperty: (groupName: string) => void;
  createProperty: (groupName: string) => void;
  changeType: (propertyName: string) => void;
  moveProperty: (propertyName: string) => void;
  editProperty: (propertyName: string) => void;
  createGroup: () => void;
}

const ResourceTypeContext = createContext<ResourceTypeContextValue>({
  addProperty: () => {
    throw new Error("Not Implemented!");
  },
  createProperty: () => {
    throw new Error("Not Implemented!");
  },
  changeType: () => {
    throw new Error("Not Implemented!");
  },
  moveProperty: () => {
    throw new Error("Not Implemented!");
  },
  editProperty: () => {
    throw new Error("Not Implemented!");
  },
  createGroup: () => {
    throw new Error("Not Implemented!");
  },
});

export default ResourceTypeContext;
