/**
 * @enum {string}
 * @category Group
 * @readonly
 */
enum AccessManagementType {
  /**
   * Properties Group.
   */
  NONE = "none",
  COMPACT = "compact",
  SIMPLIFIED = "simplified",
  COMPLETE = "complete",
}

export default AccessManagementType;
