import { createSelector } from 'reselect';
import userdataProperties from '../Constants/userdata';

export const getPrincipalId = (state) => state.userdata.principalId;
export const getDefaultPositionId = (state) => state.userdata.defaultPositionId;

export const getUserdata = createSelector(
  (state) => state.userdata,
  (userdata) => {
    return Object.entries(userdata)
      .filter(([key]) => userdataProperties.includes(key))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }
);

export const getWorkflowPermissions = createSelector(
  (state) => state.userdata.permissions,
  (permissions) => permissions?.workflowPermissions || {}
);
