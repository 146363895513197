import { useCallback, useEffect, useMemo, useState } from "react";
import useProject from "./useProject";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import masterdataApi from "../apis/masterdataApi";
import useCurrentUser from "./useCurrentUser";
import _ from "lodash";

export interface UseUserSettingsProps {
  settingsScope?: string;
}

const USERS_SETTINGS_SCOPE = "USERS_SETTINGS";

const useUserSettings = ({ settingsScope }: UseUserSettingsProps) => {
  const queryClient = useQueryClient();
  const { project } = useProject();
  const { principalId, authenticated } = useCurrentUser();
  const projectCode = useMemo(() => project?.code, [project]);

  const [settingId, setSettingId] = useState<string | null>(null);

  const queryKey = useMemo(
    () => ["user-settings", projectCode, settingsScope],
    [projectCode, settingsScope]
  );

  const cacheKey = useMemo(
    () => `${settingsScope}:${principalId}:${projectCode}`,
    [principalId, projectCode, settingsScope]
  );

  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => {
      if (project == null) {
        throw new Error("no no");
      }

      return masterdataApi.getMasterData(
        project.docarea,
        USERS_SETTINGS_SCOPE,
        ["*"],
        {
          where: { principalId, settingsScope, projectCode },
          orderBy: {
            createdAt: "desc",
          },
          paging: {
            number: 0,
            size: 1,
          },
        },
        cacheKey
      );
    },
    enabled: authenticated && project != null,
    refetchOnWindowFocus: false,
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (userSettings) => {
      if (project == null) {
        throw new Error("no no");
      }

      let entry = {
        principalId,
        settingsScope,
        settingsData: btoa(JSON.stringify(userSettings)),
      };

      if (settingId == null) {
        return masterdataApi.postMasterDataEntry(
          project.docarea,
          USERS_SETTINGS_SCOPE,
          entry,
          cacheKey
        );
      }

      return masterdataApi.updateMasterDataEntry(
        project.docarea,
        USERS_SETTINGS_SCOPE,
        settingId,
        entry,
        cacheKey
      );
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const settings = useMemo(() => {
    return _(_.get(data, "[0].properties"))
      .keyBy("name")
      .mapValues((item: any) => {
        if (item.name === "settingsData") {
          return JSON.parse(atob(item.value));
        }

        return item.value;
      })
      .value();
  }, [data]);

  useEffect(() => {
    setSettingId(settings?.id || null);
  }, [settings]);

  return {
    loading: useMemo(
      () => updateMutation.isPending || isPending,
      [updateMutation.isPending, isPending]
    ),
    settingId,
    data: useMemo(() => settings?.settingsData, [settings]),
    update: useCallback(
      (userSettings: any) => {
        return updateMutation.mutateAsync(userSettings);
      },
      [updateMutation]
    ),
  };
};

export default useUserSettings;
