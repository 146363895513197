import { ValuesetItem } from "../contants/valuesetItem";
import CacheApi from "./conf/CacheApi";
import GatewayApi from "./conf/GatewayApi";

const BASE_URL = process.env.REACT_APP_GATEWAY_URL;

const gatewayApi = GatewayApi.build();
const gatewayCacheApi = CacheApi.build(BASE_URL);
const alCacheApi = CacheApi.build();

const getCacheKey = (valuesetName: string) =>
  `CUSTOM_VALUESETS:${valuesetName}`;

const getValuesSet = (docAreaName: string, valueSetName: string) =>
  alCacheApi
    .get(
      `/configuration/docarea/${docAreaName}/valuesets/name/${valueSetName}`,
      CacheApi.options({}, false, `VALUESET:${valueSetName}`)
    )
    .then((response) => Promise.resolve(response.data));

const getMasterValuesets = (docarea: string) => {
  return gatewayApi
    .get(`/docarea/${docarea}/master-valuesets`)
    .then((response) => Promise.resolve(response.data));
};

const getCustomValueset = (docarea: string, valuesetName: string) => {
  return gatewayCacheApi
    .get(
      `/docarea/${docarea}/custom-valueset/${valuesetName}`,
      CacheApi.options({}, false, getCacheKey(valuesetName))
    )
    .then((response) => Promise.resolve(response.data));
};

const getCustomValuesets = (docarea: string, prefix: string) => {
  return gatewayApi
    .get(`/docarea/${docarea}/custom-valueset/prefix/${prefix}`)
    .then((response) => Promise.resolve(response.data));
};

const postCustomValuesetItem = (
  docarea: string,
  valuesetName: string,
  valuesetItem: ValuesetItem
) => {
  return gatewayApi
    .post(`/docarea/${docarea}/custom-valueset/${valuesetName}`, valuesetItem)
    .then((response) => {
      CacheApi.removeItem(getCacheKey(valuesetName));
      return Promise.resolve(response.data.id);
    });
};

const importCustomValuesetItems = (
  docarea: string,
  valuesetName: string,
  valuesetItems: ValuesetItem[]
) => {
  return gatewayApi
    .post(
      `/docarea/${docarea}/custom-valueset/${valuesetName}/import`,
      valuesetItems
    )
    .then((response) => {
      CacheApi.removeItem(getCacheKey(valuesetName));
      return Promise.resolve(response.data.id);
    });
};

const deleteCustomValuesetItem = (
  docarea: string,
  valuesetName: string,
  customValuesetItemId: string
) => {
  return gatewayApi
    .delete(
      `/docarea/${docarea}/custom-valueset/${valuesetName}/item/${customValuesetItemId}`
    )
    .then((response) => {
      CacheApi.removeItem(getCacheKey(valuesetName));
      return Promise.resolve(response.data.id);
    });
};

const deleteAllCustomValuesetItems = (
  docarea: string,
  valuesetName: string,
  customValuesetItemIds: string[]
) => {
  return gatewayApi
    .delete(`/docarea/${docarea}/custom-valueset/${valuesetName}/items`, {
      params: {
        ids: customValuesetItemIds.join(","),
      },
    })
    .then((response) => {
      CacheApi.removeItem(getCacheKey(valuesetName));
      return Promise.resolve(response.data.id);
    });
};

const getPropertyDefinitions = (docarea: string) => {
  return gatewayCacheApi
    .get(
      `/docarea/${docarea}/property-definitions`,
      CacheApi.options({}, false, "PROPERTY-DEFINITIONS")
    )
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const docareaApi = {
  getValuesSet,
  getMasterValuesets,
  getCustomValueset,
  getCustomValuesets,
  postCustomValuesetItem,
  importCustomValuesetItems,
  deleteCustomValuesetItem,
  deleteAllCustomValuesetItems,
  getPropertyDefinitions,
};

export default docareaApi;
