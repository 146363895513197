import GatewayApi from "../../../apis/conf/GatewayApi";
import { Reminder } from "../constants/reminder";

const gatewayApi = GatewayApi.build();

const getReminders = (resourceId: string) => {
  return gatewayApi
    .get(`/repository/res/${resourceId}/reminders`)
    .then((response) => Promise.resolve(response.data));
};

const createReminder = (resourceId: string, reminder: Reminder) => {
  return gatewayApi
    .post(`/repository/res/${resourceId}/reminders`, reminder)
    .then((response) => Promise.resolve(response.data));
};

const updateReminder = (
  resourceId: string,
  reminderId: string,
  reminder: Reminder
) => {
  return gatewayApi
    .put(`/repository/res/${resourceId}/reminders/${reminderId}`, reminder)
    .then((response) => Promise.resolve(response.data));
};

const deleteReminder = (resourceId: string, reminderId: string) => {
  return gatewayApi
    .delete(`/repository/res/${resourceId}/reminders/${reminderId}`)
    .then((response) => Promise.resolve(response.data));
};

const remindersApi = {
  getReminders,
  createReminder,
  updateReminder,
  deleteReminder,
};

export default remindersApi;
