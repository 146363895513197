import { useEffect, useMemo, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormGroup,
  Row,
  Col,
  FormLabel,
  Card,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faFileExport,
  faFileImport,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { PancoSelect } from "cosmos-components";
import ValuesetTable from "./ValuesetTable";
import EditMasterValuesetEntryModal from "./EditMasterValuesetEntryModal";
import masterValuesetItem from "../../contants/masterValuesetItem";
import _ from "lodash";
import useDocArea from "../../hooks/useDocArea";
import useValueset from "../../hooks/useValueset";

const actions = {
  SET_SELECTED_VALUESET_NAME: "SET_SELECTED_VALUESET_NAME",
  SET_FILES: "SET_FILES",
  SET_SELECTED_VALUESET_ENTRY: "SET_SELECTED_VALUESET_ENTRY",
};

const initialState = {
  selectedValuesetName: null,
  files: [],
  selectedValuesetEntry: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_SELECTED_VALUESET_NAME:
      return {
        ...state,
        selectedValuesetName: action.valuesetName,
      };
    case actions.SET_FILES:
      return {
        ...state,
        files: action.files,
      };
    case actions.SET_SELECTED_VALUESET_ENTRY:
      return {
        ...state,
        selectedValuesetEntry: action.valuesetEntry,
      };
    default:
      return state;
  }
};

const MasterValuesetEditor = () => {
  const [localState, localDispatch] = useReducer(reducer, initialState);
  const createValuesetInput = useRef(null);
  const { t } = useTranslation("module");
  const { masterValuesets } = useValueset();
  const { getMasterValuesets, importCustomValuesetFile, exportValuesetFile } =
    useDocArea();

  useEffect(() => {
    getMasterValuesets();
  }, [getMasterValuesets]);

  useEffect(() => {
    if (masterValuesets.length > 0 && localState.selectedValuesetName == null) {
      const valuesetName = _(masterValuesets).orderBy("label").get("[0].name");
      localDispatch({ type: actions.SET_SELECTED_VALUESET_NAME, valuesetName });
    }
  }, [masterValuesets, localState.selectedValuesetName]);

  const selectedValueset = useMemo(() => {
    return masterValuesets.find(
      (cvs) => cvs.name === localState.selectedValuesetName
    );
  }, [masterValuesets, localState.selectedValuesetName]);

  const masterValuesetsOptions = useMemo(() => {
    let options = masterValuesets.map(({ name }) => ({
      label: name,
      value: name,
    }));

    if (
      options.findIndex((o) => o.value === localState.selectedValuesetName) ===
      -1
    ) {
      options = [
        ...options,
        {
          label: localState.selectedValuesetName,
          value: localState.selectedValuesetName,
        },
      ];
    }

    return options.sort((a, b) => String(a.label).localeCompare(b.label));
  }, [masterValuesets, localState.selectedValuesetName]);

  const handleFileImport = (e) => {
    e.preventDefault();
    importCustomValuesetFile(
      localState.selectedValuesetName,
      localState.files,
      true
    );
  };

  const handleFileExport = () => {
    exportValuesetFile({valuesetName: localState.selectedValuesetName, masterValueset: true});
  };

  return (
    <div>
      <Card className="mb-3 border-dark">
        <Card.Body>
          <h6>{t("common.select_master_valueset")}</h6>
          <FormGroup as={Row}>
            <FormLabel column sm={2}>
              {t("common.valueset")}
            </FormLabel>
            <Col sm={4}>
              <PancoSelect
                options={masterValuesetsOptions}
                value={[localState.selectedValuesetName]}
                onChange={({ value }) => {
                  localDispatch({
                    type: actions.SET_SELECTED_VALUESET_NAME,
                    valuesetName: value[0],
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <FormLabel column sm={2}>
              {t("common.add_master_valueset")}
            </FormLabel>
            <Col sm={4}>
              <InputGroup className="mb-3">
                <InputGroup.Text>master_</InputGroup.Text>
                <FormControl ref={createValuesetInput} />
                <Button
                  variant="primary"
                  onClick={() => {
                    const {
                      current: { value },
                    } = createValuesetInput;

                    localDispatch({
                      type: actions.SET_SELECTED_VALUESET_NAME,
                      valuesetName: `master_${value}`,
                    });

                    createValuesetInput.current.value = "";
                  }}
                >
                  Create
                </Button>
              </InputGroup>
            </Col>
          </FormGroup>
        </Card.Body>
        <Card.Footer className="d-flex">
          <Button
            onClick={() => {
              localDispatch({
                type: actions.SET_SELECTED_VALUESET_ENTRY,
                valuesetEntry: {},
              });
            }}
            className="mr-2"
            variant="link"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            {t("common.add_valueset")}
          </Button>

          <div className="ml-auto d-flex align-items-center">
            <Button onClick={handleFileExport} className="mr-2" variant="link">
              <FontAwesomeIcon icon={faFileExport} className="mr-2" />
              {t("common.export")}
            </Button>

            <FontAwesomeIcon icon={faArrowRight} className="mr-3 text-dark" />

            <Form onSubmit={handleFileImport} inline>
              <Form.File
                multiple={false}
                onChange={(e) => {
                  localDispatch({
                    type: actions.SET_FILES,
                    files: e.target.files,
                  });
                }}
              />

              <Button
                type="submit"
                disabled={localState.files.length !== 1}
                variant="link"
              >
                <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                {t("common.import")}
              </Button>
            </Form>
          </div>
        </Card.Footer>
      </Card>

      {selectedValueset != null && (
        <ValuesetTable
          entries={selectedValueset.data}
          valuesetName={localState.selectedValuesetName}
          propertiesMap={masterValuesetItem}
          masterValueset
          onEdit={(valuesetEntry) => {
            localDispatch({
              type: actions.SET_SELECTED_VALUESET_ENTRY,
              valuesetEntry,
            });
          }}
        />
      )}

      <EditMasterValuesetEntryModal
        show={localState.selectedValuesetEntry != null}
        onClose={() => {
          localDispatch({
            type: actions.SET_SELECTED_VALUESET_ENTRY,
            valuesetEntry: null,
          });
        }}
        entry={localState.selectedValuesetEntry}
        valuesetName={localState.selectedValuesetName}
      />
    </div>
  );
};

export default MasterValuesetEditor;
