import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faStar as faStarRegular,
} from "@fortawesome/free-regular-svg-icons";
import { faFolderTree, faLock } from "@fortawesome/free-solid-svg-icons";
import { PancoTooltip } from "cosmos-components";
import AccessManagementCell from "./cells/AccessManagementCell";
import FileExtensionCell from "./cells/FileExtensionCell";
import ExpanderCell from "./cells/ExpanderCell";
import FavouriteButton from "./FavouriteButton";
import { Column, UseTableHooks } from "react-table";
import Resource from "../../types/resource";
import styled from "styled-components";

const ClickableDiv = styled.div`
  cursor: pointer;
`;

const useRepositoryTableActions = <D extends object>(
  hooks: UseTableHooks<Resource>
) => {
  function* generateSystemColumns(simplify: boolean) {
    if (!simplify) {
      yield {
        id: "expander",
        width: 50,
        minWidth: 50,
        disableResizing: true,
        Header: (header) => (
          <ClickableDiv
            onClick={() => {
              header.toggleAllRowsExpanded();
            }}
          >
            <PancoTooltip text="Expand/Collapse">
              <FontAwesomeIcon icon={faFolderTree} />
            </PancoTooltip>
          </ClickableDiv>
        ),
        Cell: ExpanderCell,
      } as Column<Resource>;
    }

    yield {
      id: "fileextensionicon",
      disableResizing: true,
      width: 30,
      minWidth: 30,
      Header: () => (
        <div className="text-center">
          <PancoTooltip text="Folder/File type">
            <FontAwesomeIcon icon={faFile} />
          </PancoTooltip>
        </div>
      ),
      Cell: FileExtensionCell,
    } as Column<Resource>;

    yield {
      id: "strictlyconfidential",
      width: 30,
      minWidth: 30,
      disableResizing: true,
      Header: () => (
        <div className="text-center">
          <FontAwesomeIcon icon={faLock} />
        </div>
      ),
      Cell: AccessManagementCell,
    } as Column<Resource>;

    if (!simplify) {
      yield {
        id: "favourite",
        width: 30,
        minWidth: 30,
        disableResizing: true,
        Header: () => (
          <div className="text-center">
            <PancoTooltip text="Favourite">
              <FontAwesomeIcon icon={faStarRegular} />
            </PancoTooltip>
          </div>
        ),
        Cell: ({ row }) => <FavouriteButton document={row.original} />,
      } as Column<Resource>;
    }
  }

  hooks.visibleColumns.push((columns, { instance }) => {
    const { simplify } = instance;
    return [...generateSystemColumns(!!simplify), ...columns];
  });
};

export default useRepositoryTableActions;
