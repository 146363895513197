import { createAction } from "@reduxjs/toolkit";
import * as actions from "../contants/domainConstants";

export interface FetchPrincipalsByIdsPayload {
  principalIds: string[];
  principalType: number;
  domainNames: string[];
}

export const fetchPrincipalsByIds = createAction<FetchPrincipalsByIdsPayload>(
  actions.FETCH_PRINCIPALS_BY_IDS
);

export interface SearchPrincipalsPayload {
  searchQuery: string;
  principalType: number;
  domainNames: string[];
}

export const searchPrincipals = createAction<SearchPrincipalsPayload>(
  actions.SEARCH_PRINCIPALS
);
