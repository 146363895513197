import _ from "lodash";
import { Property } from "../property/property";
import { propertyType } from "../generator";
import {
  eqCond,
  operation,
  existsOperation,
  andOperation,
  Operation,
  notOperation,
  queryOperand,
} from "./operation";
import { isNull } from ".";

export type PrimitiveFilterValue = string | number | boolean;
export type MultiFilterValue = {
  value: PrimitiveFilterValue[];
  operator: "and" | "or" | "not" | "AND" | "OR" | "NOT";
};
export type FilterValue =
  | PrimitiveFilterValue
  | PrimitiveFilterValue[]
  | MultiFilterValue;

const getDateOperation = (propertyName: string, value: number | number[]) => {
  const toDate = (v: number) => operation("toDate", v);

  if (Array.isArray(value)) {
    if (value.length === 1) {
      return operation("eq", queryOperand(propertyName), toDate(value[0]));
    }

    return operation(
      "between",
      queryOperand(propertyName),
      toDate(value[0]),
      toDate(value[1])
    );
  }

  return operation("eq", queryOperand(propertyName), toDate(value));
};

const getResourceTypeOperation = (
  propertyName: string,
  value: number | number[]
) => {
  const resourceTypes = _.compact(Array.isArray(value) ? value : [value]);

  if (resourceTypes.length === 1) {
    if (resourceTypes[0] === 2) {
      return operation("in", queryOperand(propertyName), 2, 3);
    } else {
      return operation("eq", queryOperand(propertyName), resourceTypes[0]);
    }
  }

  if (resourceTypes.length === 0) {
    return operation("in", queryOperand(propertyName), 1, 2, 3);
  }

  return operation("in", queryOperand(propertyName), ...resourceTypes);
};

const multiFilterValueToOperation = (
  propertyName: string,
  property: Property,
  multiValue: MultiFilterValue
) => {
  const { value, operator } = multiValue;

  if (property?.type === propertyType.DATE) {
    //@ts-ignore
    return getDateOperation(propertyName, value);
  }

  if (propertyName === "resourcetype") {
    //@ts-ignore
    return getResourceTypeOperation(propertyName, value);
  }

  const op = operator == null ? "or" : operator.toLowerCase();

  if (op === "and") {
    const options = value.map((v) => {
      const optionCondition = eqCond(queryOperand(propertyName), v);

      if (property?.handleAsMulti) {
        return existsOperation(optionCondition);
      }

      return optionCondition;
    });

    if (options.length <= 1) {
      return options[0];
    }

    return andOperation(...options);
  }

  let condition = operation("in", queryOperand(propertyName), ...value);

  if (op === "not") {
    if (value.length === 1) {
      return operation("noteq", queryOperand(propertyName), value[0]);
    }

    if (property?.handleAsMulti) {
      return existsOperation(notOperation(condition));
    }

    return notOperation(condition);
  }

  if (property?.handleAsMulti) {
    if (value.length === 0) {
      return notOperation(
        existsOperation(
          notOperation(operation("isNull", queryOperand(propertyName)))
        )
      );
    }

    return existsOperation(condition);
  }

  return condition;
};

const isMultiFilterValue = (value: FilterValue) => {
  return (
    typeof value === "object" &&
    _.has(value, "value") &&
    _.has(value, "operator") &&
    Array.isArray((value as MultiFilterValue).value)
  );
};

export const filterToOperation = (
  filter: Record<string, FilterValue>,
  properties: Property[]
): Operation => {
  const propertiesMap = _.keyBy(properties, "name");

  const operations = _(filter)
    .map((value: FilterValue, propertyName: string) => {
      const property = propertiesMap[propertyName];

      if (isMultiFilterValue(value)) {
        return multiFilterValueToOperation(
          propertyName,
          property,
          value as MultiFilterValue
        );
      }

      const primitiveValue = value as
        | PrimitiveFilterValue
        | PrimitiveFilterValue[];

      if (propertyName === "resourcetype") {
        //@ts-ignore
        return getResourceTypeOperation(propertyName, primitiveValue);
      }

      if (property?.type === propertyType.DATE) {
        //@ts-ignore
        return getDateOperation(propertyName, primitiveValue);
      }

      //@ts-ignore
      let condition = eqCond(queryOperand(propertyName), primitiveValue);

      if (Array.isArray(primitiveValue) && primitiveValue.length > 1) {
        condition = operation(
          "in",
          queryOperand(propertyName),
          ...primitiveValue
        );
      }

      if (property?.handleAsMulti && condition != null) {
        return existsOperation(condition);
      }

      return condition;
    })
    .compact()
    .value();

  if (operations.length <= 1) {
    return operations[0];
  }

  return andOperation(...operations);
};
