import { SlideDown } from "cosmos-components";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import QuickTranslator from "../../../locale-editor/components/QuickTranslator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";

export interface PropertyTranslationProps {
  basePath?: string;
  translationPath?: string;
  onChange?: (translationPath: string) => void;
}

const PropertyTranslations = ({
  basePath,
  translationPath,
  onChange,
}: PropertyTranslationProps) => {
  const fixedBasePath = useMemo(() => _.toLower(basePath), [basePath]);
  const [prefix, setPrefix] = useState(fixedBasePath);
  const [translating, setTranslating] = useState(false);

  const prefixes = useMemo(() => [fixedBasePath, "common"], [fixedBasePath]);

  const value = useMemo(() => {
    if (translationPath == null) {
      return null;
    }
    const replaceExp = new RegExp(`^(${prefixes.join("|")})?\.`);
    return translationPath.replace(replaceExp, "");
  }, [translationPath, prefixes]);

  return (
    <div>
      <InputGroup className="mb-3 d-flex">
        <DropdownButton
          as={InputGroup.Prepend}
          variant="outline"
          title={`${prefix}.`}
          id="prefix-select"
        >
          {prefixes.map((p) => (
            <Dropdown.Item
              key={p}
              onClick={() => {
                if (onChange != null && value != null) {
                  setPrefix(p);
                  onChange(`${p}.${_.trim(value)}`);
                }
              }}
            >
              {p}.
            </Dropdown.Item>
          ))}
        </DropdownButton>

        <FormControl
          value={value || undefined}
          onChange={(e) => {
            if (onChange != null) {
              onChange(`${prefix}.${_.trim(e.target.value)}`);
            }
          }}
        />

        <InputGroup.Append>
          <Button variant="outline" onClick={() => setTranslating((t) => !t)}>
            Translate{" "}
            <FontAwesomeIcon
              icon={translating ? faAngleDoubleUp : faAngleDoubleDown}
              size="xs"
            />
          </Button>
        </InputGroup.Append>
      </InputGroup>

      <SlideDown collapsed={!translating}>
        <QuickTranslator
          scope="property"
          translationPath={translationPath}
          disabled={prefix === "common"}
        />
      </SlideDown>
    </div>
  );
};

export default PropertyTranslations;
