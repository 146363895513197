import * as actions from '../Actions/types';
import { uuidv4 } from 'cosmos-config/utils';

const resultType = {
  FIELD: 'FIELD',
  FUNCTION: 'FUNCTION',
};

const initialState = {
  groups: [
    {
      id: uuidv4(),
      operator: '&',
      conditions: [],
    },
  ],
  results: [],
  documents: [],
  loading: false,
};

const filterFolder = (state = initialState, action) => {
  switch (action.type) {
    case actions.reporting.REPORTING_GROUPS_SET:
      return {
        ...state,
        groups: action.groups,
      };
    case actions.reporting.REPORTING_RESULTS_SET:
      return {
        ...state,
        results: [
          ...state.results.filter(
            (r) => action.axis != null && r.axis !== action.axis
          ),
          ...action.results,
        ],
      };
    case actions.reporting.REPORTING_DOCUMENTS_SET:
      return {
        ...state,
        documents: action.documents,
      };
    case actions.reporting.REPORTING_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.reporting.REPORTING_RESULT_ADD_FIELD:
      return {
        ...state,
        results: [
          ...state.results,
          {
            id: state.results.length,
            type: resultType.FIELD,
            name: action.name,
            axis: action.axis,
          },
        ],
      };
    case actions.reporting.REPORTING_RESULT_ADD_FUNCTION:
      return {
        ...state,
        results: [
          ...state.results,
          {
            id: state.results.length,
            type: resultType.FUNCTION,
            name: action.name,
            axis: action.axis,
          },
        ],
      };
    case actions.reporting.REPORTING_RESULT_UPDATE:
      return {
        ...state,
        results: state.results.map((r) => {
          if (r.id === action.id) {
            return {
              ...r,
              [action.name]: action.value,
            };
          }

          return r;
        }),
      };
    case actions.reporting.REPORTING_RESULT_DELETE:
      return {
        ...state,
        results: state.results.filter((r) => r.id !== action.id),
      };
    case actions.reporting.REPORTING_RESULT_MOVE:
      return {
        ...state,
        results: [
          ...state.results
            .filter((r) => r.id !== action.id)
            .slice(0, action.id + action.amount),
          state.results[action.id],
          ...state.results
            .filter((r) => r.id !== action.id)
            .slice(action.id + action.amount),
        ].map((result, id) => ({ ...result, id })),
      };
    case actions.reporting.REPORTING_CONDITION_ADD:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === action.groupId) {
            return {
              ...group,
              conditions: [
                ...group.conditions,
                { id: uuidv4(), operator: '=' },
              ],
            };
          }

          return group;
        }),
      };
    case actions.reporting.REPORTING_CONDITION_UPDATE:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === action.groupId) {
            return {
              ...group,
              conditions: group.conditions.map((condition) => {
                if (condition.id === action.id) {
                  return action.condition;
                }

                return condition;
              }),
            };
          }

          return group;
        }),
      };
    case actions.reporting.REPORTING_CONDITION_DELETE:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === action.groupId) {
            return {
              ...group,
              conditions: group.conditions.filter((c) => c.id !== action.id),
            };
          }

          return group;
        }),
      };
    case actions.reporting.REPORTING_GROUP_ADD:
      return {
        ...state,
        groups: [
          ...state.groups,
          {
            id: uuidv4(),
            operator: '&',
            conditions: [],
          },
        ],
      };
    case actions.reporting.REPORTING_GROUP_DELETE:
      return {
        ...state,
        groups: state.groups.filter((group) => group.id !== action.groupId),
      };
    default:
      return state;
  }
};

export default filterFolder;
