import React from "react";
import classNames from "classnames";
import logoImage from "../../assets/img/cosmos_logo_bold.png";
import RweLogo from "../../assets/img/rwelogo.svg";

export interface CosmosLogoProps {
  large?: boolean;
  simple?: boolean;
  className?: string;
}

const CosmosLogo = ({ large, simple, className }: CosmosLogoProps) => {
  return (
    <div
      className={classNames(
        "text-center",
        "align-items-center",
        {
          "d-flex": !large && !simple,
        },
        className
      )}
    >
      {!simple && (
        <div style={{ lineHeight: 0 }}>
          <RweLogo style={large ? {} : { height: "15px", width: "50px" }} />
        </div>
      )}

      <div
        style={{ lineHeight: 0 }}
        className={classNames({ "mt-3": large, "ml-3": !large && !simple })}
      >
        <img
          className="cosmos-logo"
          src={logoImage}
          alt="Cosmos"
          style={{
            maxHeight: large ? "72px" : "19px",
            maxWidth: "100%",
          }}
        />
      </div>
    </div>
  );
};

export default CosmosLogo;
