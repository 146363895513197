import React, { useMemo } from "react";
import { AccessLevelEditorProps } from "./AccessLevelEditor";
import { CardGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PrimaryCard from "../fragments/PrimaryCard";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";

const iconMap = {
  3: faLock,
  10: faLockOpen,
};

type AccessLevelOption = {
  label: string;
  value: 3 | 10;
  description: string;
};

const RestrictedLevelOnly = ({
  accessLevel,
  disabled,
  onChange,
}: AccessLevelEditorProps) => {
  const { t } = useTranslation("module");

  const accessLevelOptions: AccessLevelOption[] = useMemo(
    () => [
      {
        label: t("accessmanagement.level_public", { defaultValue: "Internal" }),
        value: 10,
        description: t("accessmanagement.level_public_desc", {
          defaultValue: "Everybody can see and update.",
        }),
      },
      {
        label: t("accessmanagement.level_strictly_confidential", {
          defaultValue: "Strictly Confidential",
        }),
        value: 3,
        description: t("accessmanagement.level_strictly_confidential_desc", {
          defaultValue: "Nobody can access. Strictly confidential.",
        }),
      },
    ],
    [t]
  );

  return (
    <CardGroup className="mb-3">
      {accessLevelOptions.map((op) => (
        <PrimaryCard
          key={op.value}
          icon={iconMap[op.value]}
          active={op.value === accessLevel}
          onClick={() => {
            if (onChange != null && !disabled) {
              onChange(op.value);
            }
          }}
        />
      ))}
    </CardGroup>
  );
};

export default RestrictedLevelOnly;
