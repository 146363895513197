import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { PancoSelect, PancoSelectOption } from "cosmos-components";
import { uuidv4 } from "cosmos-config/utils";
import _ from "lodash";
import { DateTime } from "luxon";
import { Col, FormCheck, Row } from "react-bootstrap";

const cronMap = ["second", "minute", "hour", "day", "month", "weekday", "year"];
const recurrenceOptions = [
  {
    label: "Hourly",
    value: "hour",
  },
  {
    label: "Daily",
    value: "day",
  },
  {
    label: "Weekly",
    value: "weekday",
  },
  {
    label: "Monthly",
    value: "month",
  },
  {
    label: "Yearly",
    value: "year",
  },
];
const generateTimeRange = (range: number) =>
  _.range(range).map(
    (v) =>
    ({
      label: _.padStart(String(v), 2, "0"),
      value: String(v),
    } as PancoSelectOption)
  );
const hourOptions = generateTimeRange(24);
const minuteOptions = generateTimeRange(60);

const cronToArray = (expression?: string | null) =>
  _(expression).split(" ").map(_.trim).reject(_.isEmpty).take(6).value();

const defaultCron = "0 0 0 1 1 *";

const updateCronList = (list: string, value: number, added = true) => {
  if (list === "*") {
    return added ? `${value}` : list;
  }

  if (list.length <= 1) {
    return added ? `${list},${value}` : "*";
  }

  const listArray = list.split(",");
  if (added) {
    return [...listArray, value].join(",");
  }

  return _.without(listArray, String(value));
};

declare type CheckBoxRangeProps = {
  className: string;
  range: number;
  value: string;
  onChange: (index: number, checked: boolean) => void;
  renderLabel: (index: number) => string;
};

const CheckBoxRange = ({
  className,
  range,
  value,
  onChange,
  renderLabel,
}: CheckBoxRangeProps) => {
  const uuid = useMemo(() => uuidv4(), []);

  return (
    <div className={classNames(className)}>
      {_.range(1, range + 1).map((i) => {
        const id = `${uuid}-${i}`;

        return (
          <FormCheck
            key={id}
            checked={value?.split(",").includes(String(i))}
            id={id}
            label={renderLabel(i)}
            className="d-inline-block mr-3"
            onChange={(e) => {
              const checked = e.target.checked;
              onChange(i, checked);
            }}
          />
        );
      })}
    </div>
  );
};

export interface CronEditorProps {
  cron?: string | null;
  className?: string;
  onChange?: (newExpression: string) => void;
}

const CronEditor = ({ cron, className, onChange }: CronEditorProps) => {
  const [expression, setExpression] = useState(cron);

  useEffect(() => {
    if (cron == null) {
      const dateTime = DateTime.local();
      const defaultCron = `0 0 0 ${dateTime.day} ${dateTime.month} *`;

      setExpression(defaultCron);
    } else {
      setExpression(cron);
    }
  }, [cron]);

  const updateExpression = useCallback(
    (cronElement: string, value: string | Function, inclusive = false) => {
      const index = cronMap.indexOf(cronElement);
      const defaultCronArray = cronToArray(defaultCron);

      setExpression((ex) => {
        const newExpression = cronToArray(ex)
          .map((v, i) => {
            if (i === index) {
              if (typeof value === "function") {
                return value(v);
              }

              return value;
            } else if (!inclusive) {
              return v;
            }

            if (
              i > index ||
              (index === 5 && i >= 3) ||
              (index === 6 && i === 5)
            ) {
              return "*";
            }

            return v === "*" ? defaultCronArray[i] : v;
          })
          .join(" ");

        if (onChange != null) {
          onChange(newExpression);
        }

        return newExpression;
      });
    },
    [onChange]
  );

  const parsedExpression = useMemo(() => {
    return _(cronToArray(expression))
      .mapKeys((v, k) => cronMap[k])
      .mapValues()
      .value();
  }, [expression]);

  const recurrenceIndex = useMemo(() => {
    const expressionArray = cronToArray(expression);

    if (expressionArray[5] !== "*") {
      return 5;
    }

    // @ts-ignore
    const index = expressionArray.findLastIndex((i) => i !== "*") + 1;

    return index === 5 ? index + 1 : index;
  }, [expression]);

  return (
    <div className={classNames(className)}>
      <Row>
        <Col sm={6}>
          <PancoSelect
            value={_.compact([cronMap[recurrenceIndex]])}
            options={recurrenceOptions}
            onChange={({ value }) => {
              if (value != null) {
                const [v] = value;
                updateExpression(v, v === "weekday" ? "1" : "*", true);
              }
            }}
            boxed
          />
        </Col>
        <Col sm={1} className="d-flex">
          <b className="m-auto">At</b>
        </Col>
        <Col sm={2}>
          {recurrenceIndex > 2 && (
            <PancoSelect
              placeholder="Hour"
              value={_.compact([parsedExpression["hour"]])}
              options={hourOptions}
              onChange={({ value }) => {
                if (value != null) {
                  const [v] = value;
                  updateExpression("hour", v);
                }
              }}
              boxed
            />
          )}
        </Col>
        <Col sm={1} className="d-flex">
          <b className="m-auto">:</b>
        </Col>
        <Col sm={2}>
          {recurrenceIndex > 1 && (
            <PancoSelect
              placeholder="Minute"
              value={_.compact([parsedExpression["minute"]])}
              options={minuteOptions}
              onChange={({ value }) => {
                if (value != null) {
                  const [v] = value;
                  updateExpression("minute", v);
                }
              }}
              boxed
            />
          )}
        </Col>
      </Row>

      {recurrenceIndex >= 4 && recurrenceIndex !== 5 && (
        <CheckBoxRange
          className="mt-3"
          range={31}
          value={parsedExpression["day"]}
          onChange={(index, added) => {
            updateExpression("day", (list: string) => {
              return updateCronList(list, index, added);
            });
          }}
          renderLabel={(index) => {
            return String(index);
          }}
        />
      )}

      {recurrenceIndex === 5 && (
        <CheckBoxRange
          className="mt-3"
          range={7}
          value={parsedExpression["weekday"]}
          onChange={(index, added) => {
            updateExpression("weekday", (list: string) => {
              return updateCronList(list, index, added);
            });
          }}
          renderLabel={(index) => {
            // @ts-ignore
            const dateTime = DateTime.fromObject({ weekday: index });
            return _.capitalize(dateTime.weekdayLong || "");
          }}
        />
      )}

      {recurrenceIndex === 6 && (
        <CheckBoxRange
          className="mt-3"
          range={12}
          value={parsedExpression["month"]}
          onChange={(index, added) => {
            updateExpression("month", (list: string) => {
              return updateCronList(list, index, added);
            });
          }}
          renderLabel={(index) => {
            const dateTime = DateTime.fromObject({ month: index });
            return _.capitalize(dateTime.monthLong || "");
          }}
        />
      )}
    </div>
  );
};

export default CronEditor;
