/* eslint-disable no-underscore-dangle */
import groupType from "./groupType";
import propertyPermission from "../property/propertyPermission";
import { Property } from "../property/property";

/**
 * @interface
 * @category Group
 */
export interface Group {
  group: boolean;
  label: string;
  name: string;
  query: string | null;
  topic?: string;
  filterable: boolean;
  editable: boolean;
  disabled: boolean;
  dedicated: string | null;
  updatable: boolean;
  tableColumn: boolean;
  type: groupType;
  permission: propertyPermission;
  required: boolean;
  description: string | null;
  usePreset: boolean;
  folderGroup: Group | null;
  _children: Property[];
  translation: string | null;
  protectedGroup: boolean;
  defaultCollapsed: boolean;
  properties: Property[];

  readPermissionProperty: string | null;
  writePermissionProperty: string | null;
  conditional: boolean;

  children(children: Property[]): Group;
  build(): Group;
  buildChildren(): Property[];
  isHidden(filter: Record<string, any>): boolean;
  dedicatedTo(attributeName: string): Group;
  readonly(updatable: boolean, disabled: boolean): Group;
  excludeFilter(filterable: boolean): Group;
  excludeTable(tableColumn: boolean): Group;
  excludeForm(exclude: boolean): Group;
  editableBy(propertyName: string): Group;
  ownerRestricted(): Group;
  optional(required: boolean): Group;
  /** Sets the description of the group. */
  describe(desciption: string): Group;
  excludePreset(excludePreset: boolean): Group;
  getDefaultCollapsed(filter: any): boolean;
  collapsed(defaultCollapsed: boolean): Group;
  asFolderGroup(build: Function): Group;
  translate(translation: string): Group;
  protect(protectedGroup: boolean): Group;
}

/**
 * Group Generator.
 * @category Group
 * @author Patrik Pancisin
 * @class group
 * @classdesc Basic building block of cosmos.
 * @param {string} label - The label of the group displayed in cosmos.
 * @param {string} name - The name of the group.
 * @param {number} topic
 */
export default (
  label: string,
  name: string,
  topic?: string
): Group => /** @lends group */ ({
  group: true,
  label,
  name,
  topic,
  filterable: true,
  editable: true,
  disabled: false,
  dedicated: null,
  updatable: true,
  tableColumn: true,
  type: groupType.PROPERTIES,
  permission: propertyPermission.EVERYBODY,
  required: true,
  description: null,
  usePreset: true,
  folderGroup: null,
  _children: [],
  translation: null,
  protectedGroup: false,
  defaultCollapsed: true,
  properties: [],

  query: name,
  conditional: false,
  readPermissionProperty: null,
  writePermissionProperty: null,

  children(children: Property[]): Group {
    this._children = [...this._children, ...children];
    return this;
  },
  build(): Group {
    const properties = this.buildChildren();

    /** @ts-ignore */
    this.children = properties;
    this.properties = properties;
    return this;
  },
  buildChildren(): Property[] {
    const buildProperty = (p: Property): Property => ({
      ...p,
      disabled: p.disabled || this.disabled,
      filterable: p.filterable && this.filterable,
      editable: p.editable && this.editable,
      updatable: p.updatable && this.updatable,
      tableColumn: p.tableColumn && this.tableColumn,
      required: p.required && this.required,
      usePreset: p.usePreset && this.usePreset,
      writePermissionProperty:
        p.writePermissionProperty || this.writePermissionProperty,
      readPermissionProperty:
        p.readPermissionProperty || this.readPermissionProperty,
      folderProperty:
        p.folderProperty != null ? buildProperty(p.folderProperty) : null,
      // protectedProperty: p.protectedProperty || this.protectedGroup,
      isHidden: (filter: Record<string, any>) =>
        this.isHidden(filter) || p.isHidden(filter),
    });

    return this._children.map(buildProperty);
  },
  isHidden(filter: Record<string, any>): boolean {
    return false;
  },
  /**
   * @instance
   * @description Sets group dedication to workflow task.
   * @param {string} taskName - Name of the workflow task.
   */
  dedicatedTo(attributeName: string): Group {
    // this.name = attributeName || this.name;
    this.query = attributeName;
    this.dedicated = attributeName;
    return this;
  },
  /**
   * @instance
   * @description Sets all properties from within the group to read only.
   * @param {boolean} updatable Sets if property should still be updatable by the system.
   */
  readonly(updatable: boolean = false, disabled: boolean = true): Group {
    this.disabled = disabled;
    this.updatable = updatable;
    return this;
  },
  /**
   * @instance
   * @description Excludes all properties from within the group form repository filters.
   * @param {boolean} filterable
   */
  excludeFilter(filterable: boolean = false): Group {
    this.filterable = filterable;
    return this;
  },
  /**
   * @instance
   * @description Excludes all properties from within the group from repository table view.
   * @param {boolean} tableColumn
   */
  excludeTable(tableColumn: boolean = false): Group {
    this.tableColumn = tableColumn;
    return this;
  },
  /**
   * @instance
   * @description Excludes all properties from within the group from resource editing.
   * @param {boolean} exclude
   */
  excludeForm(exclude: boolean = true): Group {
    this.editable = !exclude;
    return this;
  },
  /**
   * @instance
   * @description Restrict access to this group to specific user/group by resource property.
   * @param {string} propertyName Name of the property which cotain principal id.
   */
  editableBy(propertyName: string): Group {
    this.writePermissionProperty = propertyName;
    return this;
  },
  /**
   * @instance
   * @description Restrict access to this group to owner of the resource only.
   */
  ownerRestricted(): Group {
    this.permission = propertyPermission.OWNER;
    return this;
  },
  /**
   * @instance
   * @description Sets all properties from within the group to be optional
   * while editing the resource.
   * @param {boolean} required
   */
  optional(required: boolean = false): Group {
    this.required = required;
    return this;
  },
  /**
   * @instance
   * @description Sets the descrription of the group.
   * @param {string} desciption Description of the group.
   */
  describe(desciption: string): Group {
    this.description = desciption;
    return this;
  },
  /**
   * @instance
   * @description Exclude all properties form within the group
   * from preset calculation and user upload presets settings.
   * @param {boolean} excludePreset
   */
  excludePreset(excludePreset: boolean = true): Group {
    this.usePreset = !excludePreset;
    return this;
  },

  getDefaultCollapsed(filter: any): boolean {
    return !this.conditional && this.defaultCollapsed;
  },

  /**
   * @instance
   * @description Set group to not be collapsed by default if needed.
   * @param {boolean} defaultCollapsed Set collapsed state. Default is true.
   */
  collapsed(defaultCollapsed: boolean = true): Group {
    this.defaultCollapsed = defaultCollapsed;
    return this;
  },

  /**
   * @instance
   * @description Defines callback for every property from within
   * the group for setup folder property.
   * @param {callback} build Callback function
   */
  asFolderGroup(build: Function): Group {
    this.folderGroup = build({ ...this });
    return this;
  },

  /**
   * @instance
   * @description Sets translation path of the group title.
   * @param {string} translation Translation path.
   */
  translate(translation: string): Group {
    this.translation = translation;
    return this;
  },

  /**
   * @instance
   * @description Sets group to be protected. The editing functionalities in project editor are limited then.
   * @param {boolean} protectedProperty Sets group to be protected or not.
   */
  protect(protectedGroup: boolean = true): Group {
    this.protectedGroup = protectedGroup;
    return this;
  },
});
