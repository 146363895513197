import { combineReducers } from 'redux';
import userdata from './userdata';
import document from './document';
import upload from './upload';
import ui from './ui';
import filterFolder from './filterFolder';
import workflow from './workflow';
import repository from './repository';
import collaboration from './collaboration';
import sharepoint from './sharepoint';
import reporting from './reporting';
import project from './project';
import businessProcess from './businessProcess';
import importReducer from './import';
import versionImport from './versionImport';

export default combineReducers({
  userdata,
  repository,
  document,
  upload,
  ui,
  filterFolder,
  workflow,
  collaboration,
  sharepoint,
  reporting,
  project,
  businessProcess,
  import: importReducer,
  versionImport,
});
