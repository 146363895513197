/**
 * @enum {string}
 * @category Property
 * @readonly
 */
enum PropertyPermission {
  ADMIN = "admin",
  OWNER = "owner",
  EVERYBODY = "everybody",
}

export default PropertyPermission;
