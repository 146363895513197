import { widgetType } from "cosmos-config/generator";
import { Widget } from "cosmos-config/lib/widget/widget";

export type ProjectQuery = {
  id: string;
  name: string;
  query: string;
  assertResults: number;
  folderQuery: boolean;
};

export const projectQueryToWidget = (projectQuery: ProjectQuery): Widget => {
  return {
    chart: false,
    hideTitle: false,
    name: `PROJECT_QUERY:${projectQuery.id}`,
    position: {
      x: 0,
      y: 0,
      w: 5,
      h: 4,
    },
    resourceType: 2,
    table: false,
    title: projectQuery.name,
    type: widgetType.aggregate.BAR_CHART,
    query: projectQuery.id,
  } as Widget;
};
