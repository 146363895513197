import React, { useCallback, useEffect, useMemo, useState } from "react";
import UserRelationContext from "../contexts/UserRelationContext";
import useProject from "../../../hooks/useProject";
import { useQuery } from "@tanstack/react-query";
import useRepositorySearch from "../../../hooks/useRepositorySearch";
import useCurrentUser from "../../../hooks/useCurrentUser";
import userApi from "../../../apis/userApi";
import { UserAlertType } from "../constants/userAlertType";
import { ModuleSummary } from "../constants/moduleSummary";
import _ from "lodash";
import useUserTasks from "../hooks/useUserTasks";
import useAlertGenerator from "../hooks/useAlertsGenerator";
import useUserWorkflowTasks from "../hooks/useUserWorkflowTasks";
import useCoreSelector from "../../../hooks/useCoreSelector";
import { CosmosCoreRootState } from "../../../store";
import { AccessRequest } from "../../../types/accessRequest";

export interface UserRelationProviderProps {
  children: React.ReactNode;
}

type AlertDataMap = {
  [key in UserAlertType]?: any[];
};

const UserRelationProvider = ({ children }: UserRelationProviderProps) => {
  const { project } = useProject();
  const { principalId, permissions, authenticated } = useCurrentUser();
  const accessRequests = useCoreSelector<CosmosCoreRootState, AccessRequest[]>(
    (state) => state.project.accessRequests
  );

  const { alertGeneratorFactory } = useAlertGenerator();

  const [dataMap, setDataMap] = useState<AlertDataMap>({});

  const projectCode = useMemo(() => project?.code || "", [project]);
  const resourceId = useMemo(() => project?.resourceId || "", [project]);

  const { tasks, loading: loadingTasks } = useUserTasks();
  // const { messages } = useProjectMessages({ projectCode });
  // console.log(messages);
  useEffect(() => {
    setDataMap((dm) => ({ ...dm, BUSINESS_PROCESS_TASK: tasks }));
  }, [tasks]);

  const { tasks: nscaleTasks, loading: loadingNscaleTasks } =
    useUserWorkflowTasks();

  useEffect(() => {
    setDataMap((dm) => ({ ...dm, WORKFLOW_TASK: nscaleTasks }));
  }, [nscaleTasks]);

  useEffect(() => {
    setDataMap((dm) => ({ ...dm, PROJECT_ACCESS_REQUEST: accessRequests }));
  }, [accessRequests]);

  const { data: invalidDocuments, loading: loadingInvalidDocuments } =
    useRepositorySearch({
      resourceId: resourceId,
      filter: useMemo(
        () => ({
          valid: false,
          initialcreator: principalId,
          resourcetype: 2,
        }),
        [principalId]
      ),
      subtree: true,
      paginator: {
        number: 0,
        size: 100,
      },
      enabled: principalId != null && project != null,
    });

  useEffect(() => {
    setDataMap((dm) => ({
      ...dm,
      INVALID_DOCUMENT: invalidDocuments,
    }));
  }, [invalidDocuments]);

  const { data, isLoading: loadingModuleSummary } = useQuery<ModuleSummary[]>({
    queryKey: ["user-alerts", principalId],
    queryFn: () => userApi.getUserAlerts(),
    enabled: principalId != null && authenticated,
  });

  useEffect(() => {
    if (data != null) {
      const filteredSummary = data.filter(
        (alert) => alert.projectCode !== projectCode
      );
      setDataMap((dm) => ({ ...dm, MODULE_SUMMARY: filteredSummary }));
    }
  }, [data, projectCode]);

  const generateAlerts = useCallback(
    (alertType: UserAlertType, items: any[]) => {
      try {
        const alertGenerator = alertGeneratorFactory(alertType);
        return items.map((d) => alertGenerator(d));
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    [alertGeneratorFactory]
  );

  const moduleAdmin = useMemo(() => {
    if (project == null || permissions?.folderPermissions == null) {
      return false;
    }

    const { classname } = project;
    const folderPermissions = permissions.folderPermissions[classname];

    if (folderPermissions == null) {
      return false;
    }

    return _.includes(folderPermissions.customPermissions, "administrator");
  }, [project, permissions.folderPermissions]);

  return (
    <UserRelationContext.Provider
      value={{
        loadingAlerts: useMemo(
          () =>
            loadingInvalidDocuments ||
            loadingModuleSummary ||
            loadingTasks ||
            loadingNscaleTasks,
          [
            loadingInvalidDocuments,
            loadingModuleSummary,
            loadingTasks,
            loadingNscaleTasks,
          ]
        ),
        alerts: useMemo(() => {
          return _(dataMap)
            .flatMap((data, key) => {
              if (data == null) {
                return [];
              }

              return generateAlerts(key as UserAlertType, data);
            })
            .value();
        }, [dataMap, generateAlerts]),
        tasks,
        tasksCount: useMemo(() => {
          return tasks.length + nscaleTasks.length;
        }, [nscaleTasks.length, tasks.length]),
        loadingTasks,
        notifications: [],
        moduleAdmin,
      }}
    >
      {children}
    </UserRelationContext.Provider>
  );
};

export default UserRelationProvider;
