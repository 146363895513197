import { faFolderTree } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createElement } from "react";
import { CellProps, HeaderProps, UseTableHooks } from "react-table";
import TableRowExpanderButton from "../../components/TableRowExpanderButton";
import styled from "styled-components";

const ClickableDiv = styled.div`
  cursor: pointer;
`;

const useTableStructure = <D extends object>(hooks: UseTableHooks<D>) => {
  hooks.visibleColumns.push((columns, meta) => {
    if (meta.instance.structurable) {
      return [
        {
          id: "expander",
          width: 50,
          minWidth: 50,
          disableResizing: true,
          Header: ({ toggleAllRowsExpanded }: HeaderProps<D>) =>
            createElement(
              ClickableDiv,
              {
                onClick: () => {
                  toggleAllRowsExpanded();
                },
              },
              [createElement(FontAwesomeIcon, { icon: faFolderTree })]
            ),
          Cell: ({ row }: CellProps<D>) => {
            return createElement(TableRowExpanderButton, {
              expanded: row.isExpanded,
              onToggle: () => row.toggleRowExpanded(),
              canExpand: row.canExpand,
              depth: row.depth,
            });
          },
        },
        ...columns,
      ];
    }

    return columns;
  });
};

useTableStructure.pluginName = "useTableColumnOrder";

export default useTableStructure;
