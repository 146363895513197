import React from "react";
import { CloseButton } from "react-bootstrap";
import styled from "styled-components";

declare type PancoSelectBadgeProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  onClear?: () => void;
  onClick?: (() => void) | null;
  clearable?: boolean;
};

const Badge = styled.span`
  position: relative;
  border: 1px solid ${(props) => props.theme.base};
  border-radius: 3px;
  padding: 3px 10px;
  display: inline-block;
  overflow: hidden;
  max-width: calc(100% - 25px);

  color: ${(props) => props.theme.base};

  .close {
    float: none;
    color: ${(props) => props.theme.primary};
    margin-left: 5px;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

const PancoSelectBadge = ({
  children,
  disabled,
  onClear,
  onClick,
  clearable,
}: PancoSelectBadgeProps) => {
  const getAttributes = () => {
    if (onClick != null) {
      return {
        onClick,
        role: "button",
        onKeyDown: () => {},
        tabIndex: 0,
      };
    }

    return {};
  };

  return (
    <Badge {...getAttributes()}>
      {children}
      {!disabled && clearable && (
        <CloseButton
          onClick={(e) => {
            if (onClear != null) {
              onClear();
            }
            e.stopPropagation();
          }}
        />
      )}
    </Badge>
  );
};

PancoSelectBadge.defaultProps = {
  children: null,
  disabled: false,
  onClear: () => {},
  clearable: false,
  onClick: null,
};

export default PancoSelectBadge;
