import { useContext } from "react";
import ValuesetContext from "../contexts/ValuesetContext";

const useValueset = () => {
  const {
    valuesets,
    masterValuesets,
    loading,
    editValuesetItem,
    addValuesetItem,
    getValuesetByName,
    getValuesetByProperty,
    registerProperties,
    addTempValuesetItem,
    exportAllValuesetsExcel,
  } = useContext(ValuesetContext);

  return {
    valuesets,
    masterValuesets,
    loading,
    editValuesetItem,

    getValuesetByName,
    registerProperties,
    addValuesetItem,
    getValuesetByProperty,
    addTempValuesetItem,
    exportAllValuesetsExcel,
  };
};

export default useValueset;
