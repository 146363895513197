import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Overlay } from "react-bootstrap";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import _ from "lodash";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  LoadingOverlay,
  SlideDown,
  DatePicker,
  PancoSelectOptionGeneric,
  PancoSelectGeneric,
  FieldActionIcon,
  PancoArea,
} from "cosmos-components";
import { faBell as faBellSolid } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import SimpleFormGroup from "../../../components/SimpleFormGroup";
import ResourceReminderContext from "../contexts/ResourceReminderContext";
import EmailSelect from "../../../components/form/EmailSelect";
import ReminderProvider from "./ReminderProvider";
import { Reminder, ReminderUser } from "../constants/reminder";
import Resource from "../../../types/resource";
import { OnOpenResourceCallback } from "../../../types/callbacks";

const Popover = styled.div`
  min-width: 20vw;
  background-color: ${(props) => props.theme.background};
  position: relative;
  max-width: 30vw;
`;

interface ReminderSelectorProps {
  value?: number | null;
  timestamp?: number;
  onChange?: (value: number) => void;
}

const ReminderSelector = ({
  value,
  timestamp,
  onChange,
}: ReminderSelectorProps) => {
  const { t } = useTranslation();
  const [intermediateValue, setIntermediateValue] = useState(value);

  const remindAtOptions: PancoSelectOptionGeneric<number>[] = useMemo(() => {
    if (timestamp != null) {
      const dateTime = DateTime.fromMillis(timestamp);
      return [
        {
          value: timestamp,
          label: "At Time",
        },
        {
          value: dateTime.minus({ months: 1 }).toMillis(),
          label: "1 Month Upfront",
        },
        {
          value: dateTime.minus({ months: 2 }).toMillis(),
          label: "2 Month Upfront",
        },
        {
          value: dateTime.minus({ months: 3 }).toMillis(),
          label: "3 Month Upfront",
        },
        {
          value: -1,
          label: "Custom",
        },
      ];
    }

    return [];
  }, [timestamp]);

  return (
    <>
      <SimpleFormGroup
        label={t("repository.reminder_remindat", {
          defaultValue: "Reminder",
        })}
      >
        <PancoSelectGeneric<number, PancoSelectOptionGeneric<number>>
          boxed
          options={remindAtOptions}
          value={_.compact([intermediateValue])}
          onChange={({ value }) => {
            if (value != null) {
              const [v] = value;
              setIntermediateValue(v);

              if (v !== -1 && onChange != null) {
                onChange(v);
              }
            }
          }}
        />
      </SimpleFormGroup>
      <SlideDown collapsed={intermediateValue !== -1}>
        <SimpleFormGroup
          label={t("repository.reminder_remindatcustom", {
            defaultValue: "Custom Reminder",
          })}
        >
          <DatePicker
            boxed
            value={value}
            placeholder="dd.mm.yyyy"
            onChange={({ value }) => {
              if (onChange != null && value != null) {
                onChange(value);
              }
            }}
          />
        </SimpleFormGroup>
      </SlideDown>
    </>
  );
};

interface QuickReminderEditorProps {
  propertyName?: string;
  name?: string;
  timestamp?: number;
  onClose?: () => void;
}

const QuickReminderEditor = ({
  propertyName,
  name,
  timestamp,
  onClose,
}: QuickReminderEditorProps) => {
  const [users, setUsers] = useState<ReminderUser[]>([]);
  const [reminder, setReminder] = useState<Partial<Reminder>>({
    startingAt: timestamp,
    description: null,
  });
  const { t } = useTranslation("module");

  const { save, loading } = useContext(ResourceReminderContext);

  const updateUsers = useCallback(
    (emails: string[], principalId: string, name: string) => {
      setUsers((currentUsers) => {
        const email = _(currentUsers).map("email").xor(emails).first();
        if (currentUsers.length < emails.length) {
          return [...currentUsers, { email: email || "", principalId, name }];
        } else {
          return currentUsers.filter((c) => c.email !== email);
        }
      });
    },
    []
  );

  useEffect(() => {
    setReminder((r) => ({ ...r, startingAt: timestamp }));
  }, [timestamp]);

  return (
    <Popover className="border border-secondary rounded shadow">
      <LoadingOverlay loading={loading}>
        <div className="px-3 py-2 bg-light">
          <span className="h6 mb-0">
            {t("repository.remider_create", {
              defaultValue: "Create Reminder",
            })}
          </span>
        </div>
        <div className="p-3">
          <ReminderSelector
            value={reminder.startingAt}
            timestamp={timestamp}
            onChange={(v) => {
              setReminder((r) => ({ ...r, startingAt: v }));
            }}
          />

          <SimpleFormGroup
            label={t("repository.reminder_recipients", {
              defaultValue: "Recipients",
            })}
          >
            <EmailSelect
              boxed
              multiple
              principalType={2}
              value={useMemo(() => _.map(users, "email"), [users])}
              onSelect={(event) => {
                const { value, id, label } = event;
                updateUsers(value || [], id || "", label || "");
              }}
            />
          </SimpleFormGroup>

          <SimpleFormGroup
            label={t("repository.reminder_description", {
              defaultValue: "Description",
            })}
          >
            <PancoArea
              onChange={({ value }) => {
                setReminder((r) => ({ ...r, description: value }));
              }}
            />
          </SimpleFormGroup>

          <div className="text-right">
            <Button
              disabled={users.length <= 0}
              onClick={() => {
                save({
                  ...reminder,
                  propertyName,
                  name,
                  triggerType: "ONCE",
                  users,
                } as Reminder).then(onClose);
              }}
            >
              {t("button.create", { defaultValue: "Create" })}
            </Button>
            <Button
              variant="link"
              className="text-danger"
              onClick={() => {
                if (onClose != null) {
                  onClose();
                }
              }}
            >
              {t("button.cancel", { defaultValue: "Cancel" })}
            </Button>
          </div>
        </div>
      </LoadingOverlay>
    </Popover>
  );
};

export interface QuickReminderProps {
  propertyName?: string;
  name?: string;
  resource?: Resource;
  timestamp?: number;
  onOpenResource?: OnOpenResourceCallback;
}

type OpenResourceButtonProps = {
  resource: Resource;
  onOpen?: OnOpenResourceCallback;
};

const OpenResourceButton = ({ resource, onOpen }: OpenResourceButtonProps) => {
  return (
    <FieldActionIcon
      onClick={() => {
        const { resourcetype, identifier } = resource;
        if (onOpen != null) {
          onOpen(resourcetype, identifier, { route: "reminders" });
        }
      }}
    >
      <FontAwesomeIcon icon={faBellSolid} />
    </FieldActionIcon>
  );
};

type QuickReminderButtonProps = {
  propertyName?: string;
  name?: string;
  timestamp?: number;
};

const QuickReminderButton = ({
  propertyName,
  name,
  timestamp,
}: QuickReminderButtonProps) => {
  const [show, setShow] = useState(false);
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (timestamp == null) {
      setShow(false);
    }
  }, [timestamp]);

  return (
    <>
      <FieldActionIcon
        ref={targetRef}
        onClick={() => {
          if (timestamp != null) {
            setShow(true);
          }
        }}
        className={classNames({
          "text-muted": timestamp == null,
          "text-primary": timestamp != null,
        })}
      >
        <FontAwesomeIcon icon={faBell} />
      </FieldActionIcon>

      <Overlay target={targetRef.current} show={show} placement="bottom-end">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div {...props}>
            <QuickReminderEditor
              propertyName={propertyName}
              name={name}
              timestamp={timestamp}
              onClose={() => {
                setShow(false);
              }}
            />
          </div>
        )}
      </Overlay>
    </>
  );
};

const ReminderContextInjector = ({
  propertyName,
  name,
  resource,
  timestamp,
  onOpenResource,
}: QuickReminderProps) => {
  const { reminders } = useContext(ResourceReminderContext);

  const resourceReminder = useMemo(() => {
    return reminders.find((r) => r.propertyName === propertyName);
  }, [propertyName, reminders]);

  if (resourceReminder == null || resource == null) {
    return (
      <QuickReminderButton
        propertyName={propertyName}
        name={name}
        timestamp={timestamp}
      />
    );
  }

  return <OpenResourceButton resource={resource} onOpen={onOpenResource} />;
};

const QuickReminder = (props: QuickReminderProps) => {
  return (
    <div className="d-inline-block">
      <ReminderProvider resource={props.resource}>
        <ReminderContextInjector {...props} />
      </ReminderProvider>
    </div>
  );
};

export default QuickReminder;
