import { ValuesetOption } from "cosmos-config/lib/property/property";
import * as actions from "../contants/docareaConstants";
import { createAction } from "@reduxjs/toolkit";
import { ValuesetItem } from "../contants/valuesetItem";

interface FetchValuesetsPayload {
  valuesetName: string;
  valuesetSource: string;
  masterValuesetName: string | null;
  data: ValuesetOption[];
}

export const fetchValuesets = createAction<FetchValuesetsPayload[]>(
  actions.FETCH_VALUESETS
);

interface AddItemToValuesetPayload {
  valuesetName: string;
  valuesetItem: Partial<ValuesetItem>;
}

export const addItemToValueset = createAction<AddItemToValuesetPayload>(
  actions.ADD_ITEM_TO_VALUESET
);

export const reloadValueset = createAction(actions.RELOAD_VALUESET);

export const fetchMasterValuesets = createAction(
  actions.FETCH_MASTER_VALUESETS
);

export const addCustomValuesetItem = createAction<any>(
  actions.ADD_CUSTOM_VALUESET_ITEM
);

export const updateCustomValuesetItems = createAction(
  actions.UPDATE_CUSTOM_VALUESET_ITEMS
);

interface ExportValuesetFilePayload {
  valuesetName: string;
  masterValueset: string;
  exportingProps: string[];
}

export const exportValuesetFile = createAction<ExportValuesetFilePayload>(
  actions.EXPORT_VALUESET_FILE
);

export const fetchAggregateValues = createAction(
  actions.FETCH_AGGREGATE_VALUES
);

interface ImportCustomValuesetFilePayload {
  valuesetName: string;
  files: File[];
  masterValueset: string;
}

export const importCustomValuesetFile =
  createAction<ImportCustomValuesetFilePayload>(
    actions.IMPORT_CUSTOM_VALUESET_FILE
  );

export const saveMasterValuesetItem = createAction<any>(
  actions.SAVE_MASTER_VALUESET_ITEM
);

type RemoveCustomValuesetActionPayload = {
  valuesetName: string;
  itemIds: string[];
  masterValueset: boolean;
};

export const removeCustomValuesetItems =
  createAction<RemoveCustomValuesetActionPayload>(
    actions.REMOVE_CUSTOM_VALUSET_ITEMS
  );

type ReplaceAllValuesPayload = {
  propertyName: string;
  value: string;
  newValue: string | null;
  resourceIds?: string[];
};

export const replaceAllValues = createAction<ReplaceAllValuesPayload>(
  actions.REPLACE_ALL_VALUES
);

interface ExportValuesetsExcelPayload {
  valuesetNames: string[];
}

export const exportValuesetsExcel = createAction<ExportValuesetsExcelPayload>(
  actions.EXPORT_VALUESETS_EXCEL
);
