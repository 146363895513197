import {
  IconDefinition,
  faCalendar,
  faFile,
  faHandPointer,
  faSquareCheck,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  fa1,
  faAlignLeft,
  faFont,
  faHandHoldingMedical,
  faRadio,
  faShield,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { propertyType } from "cosmos-config/generator";

export type PropertyTypeIconMap = {
  [key: string]: IconDefinition;
};

const propertyTypeIconMap: PropertyTypeIconMap = {
  [propertyType.YESNO]: faToggleOn,
  [propertyType.SELECT]: faHandPointer,
  [propertyType.DATE]: faCalendar,
  [propertyType.MEMBER_SELECT]: faUser,
  [propertyType.PRINCIPAL_SELECT]: faUser,
  [propertyType.CHECKBOX]: faSquareCheck,
  [propertyType.TEXT]: faFont,
  [propertyType.RESOURCE_SELECT]: faFile,
  [propertyType.SUGGEST]: faHandHoldingMedical,
  [propertyType.RADIO]: faRadio,
  [propertyType.NUMERIC]: fa1,
  [propertyType.TEXTAREA]: faAlignLeft,
  [propertyType.ACCESS_MANAGEMENT]: faShield,
};

export default propertyTypeIconMap;
