import { useEffect, useMemo, useState } from "react";
import { useFlexLayout, useRowSelect, useTable } from "react-table";
import { usePagination } from "react-table/dist/react-table.development";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import _ from "lodash";
import {
  HelperTooltip,
  PancoInput,
  PancoPagination,
  PancoSelect,
} from "cosmos-components";
import { valuesetSource } from "cosmos-config/generator";
import {
  Body,
  Cell,
  HeaderCell,
  Row,
  TableWrapper,
} from "../../components/tableFragments";
import useValueset from "../../hooks/useValueset";
import valuesetItem from "../../contants/valuesetItem";
import FormProperty from "../../components/form/FormProperty";
import usePropertyRender from "../../hooks/usePropertyRender";
import useTableSelectMultiple from "../../hooks/table/useTableSelectMultiple";

const systemColumns = ["selection"];

const BulkEditValuesetTable = ({
  property,
  entries,
  onChangeEntry,
  onChangeSelection,
  propertiesMap,
  pageSize,
  onPageChanged,
  renderCell,
}) => {
  const { t } = useTranslation("property");

  const [selectedIds, setSelectedIds] = useState([]);
  const [presets, setPresets] = useState([]);

  const { getValuesetByName, getValuesetByProperty } = useValueset();

  const columns = useMemo(
    () => propertiesMap.filter((prop) => !prop.system && prop.tableColumn),
    [propertiesMap]
  );

  const propertyRender = usePropertyRender(columns);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    gotoPage,
    prepareRow,
    selectedFlatRows,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns: useMemo(() => columns, [columns]),
      data: useMemo(() => entries, [entries]),
      initialState: {
        pageSize,
        pageIndex: 0,
      },
      multiSelection: true,
      autoResetSelectedRows: false,
      autoResetPage: false,
    },
    useFlexLayout,
    usePagination,
    useRowSelect,
    useTableSelectMultiple
  );

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    if (!selectedFlatRows.length) {
      setPresets([]);
    }
    setSelectedIds(selectedFlatRows.map((row) => row.original.id));
  }, [selectedFlatRows]);

  const dependencyOptions = useMemo(() => {
    return getValuesetByProperty(property.dependency)
      .map((option) => ({
        ...option,
        label: `${option.value} - ${option.label}`,
      }))
      .sort((a, b) => String(a.label).localeCompare(b.label));
  }, [property, getValuesetByProperty]);

  const options = useMemo(
    () => getValuesetByName(property.valuesetName, property.valuesetSource),
    [property, getValuesetByName]
  );

  const valuesetItemProperties = useMemo(() => {
    return valuesetItem.filter(
      (itemProperty) =>
        itemProperty.name !== "obsolete" || property?.invalidatingOptions
    );
  }, [property]);

  const valueset = getValuesetByName(
    property.masterValuesetName,
    valuesetSource.CUSTOM_VALUESET
  );

  const renderEditableHeaderCell = (columnId) => {
    if (!selectedIds.length) return;

    if (columnId === "label") {
      if (property.masterValuesetName != null) {
        const filteredOptions = options.filter(
          (option) => !property.isDependant()
        );
        const existingValues = _(filteredOptions).map("value").value();
        const slaveOptions = valueset
          .filter(
            (item) =>
              !existingValues.includes(item.value) && !property.isDependant()
          )
          .map(({ value, label }) => ({ value, label }));

        return (
          <PancoSelect
            className="w-100 p-0"
            value={presets?.find((preset) => preset.key === columnId)?.value}
            onChange={({ value }) => {
              onChangeSelection(columnId, selectedIds, value);
              const newPresets = presets?.length ? presets : [];
              if (newPresets.find((preset) => preset.key === columnId)) {
                setPresets(
                  newPresets.map((preset) =>
                    preset.key === columnId ? { ...preset, value } : preset
                  )
                );
                return;
              }
              newPresets.push({ key: columnId, value });
              setPresets(newPresets);
            }}
            options={slaveOptions}
          />
        );
      }

      if (property.addingOptions.length > 0) {
        return (
          <PancoSelect
            className="w-100 p-0"
            value={presets?.find((preset) => preset.key === columnId)?.value}
            onChange={({ value }) => {
              onChangeSelection(columnId, selectedIds, value);
              const newPresets = presets?.length ? presets : [];
              if (newPresets.find((preset) => preset.key === columnId)) {
                setPresets(
                  newPresets.map((preset) =>
                    preset.key === columnId ? { ...preset, value } : preset
                  )
                );
                return;
              }
              newPresets.push({ key: columnId, value });
              setPresets(newPresets);
            }}
            required
            options={property.addingOptions.map((o) => ({
              label: o,
              value: o,
            }))}
          />
        );
      }

      return (
        <PancoInput
          className="w-100 p-0"
          value={presets?.find((preset) => preset.key === columnId)?.value}
          onChange={({ value }) => {
            onChangeSelection(columnId, selectedIds, value);
            const newPresets = presets?.length ? presets : [];
            if (newPresets.find((preset) => preset.key === columnId)) {
              setPresets(
                newPresets.map((preset) =>
                  preset.key === columnId ? { ...preset, value } : preset
                )
              );
              return;
            }
            newPresets.push({ key: columnId, value });
            setPresets(newPresets);
          }}
          required
        />
      );
    }

    if (columnId === "filter" && property.isDependant()) {
      return (
        <PancoSelect
          className="w-100 p-0"
          options={dependencyOptions}
          value={presets?.find((preset) => preset.key === columnId)?.value}
          onChange={({ value }) => {
            onChangeSelection(columnId, selectedIds, value);
            const newPresets = presets?.length ? presets : [];
            if (newPresets.find((preset) => preset.key === columnId)) {
              setPresets(
                newPresets.map((preset) =>
                  preset.key === columnId ? { ...preset, value } : preset
                )
              );
              return;
            }
            newPresets.push({ key: columnId, value });
            setPresets(newPresets);
          }}
          required
        />
      );
    }

    const editableProperties = _(valuesetItemProperties)
      .filter((prop) => prop.editable)
      .value();
    const editableProperty = editableProperties.find(
      (prop) => prop.name === columnId
    );

    if (editableProperty) {
      return (
        <FormProperty
          className="w-100 p-0"
          property={editableProperty}
          value={presets?.find((preset) => preset.key === columnId)?.value}
          onChange={({ value }) => {
            onChangeSelection(columnId, selectedIds, value);
            const newPresets = presets?.length ? presets : [];
            if (newPresets.find((preset) => preset.key === columnId)) {
              setPresets(
                newPresets.map((preset) =>
                  preset.key === columnId ? { ...preset, value } : preset
                )
              );
              return;
            }
            newPresets.push({ key: columnId, value });
            setPresets(newPresets);
          }}
          inline
          control
        />
      );
    }

    return null;
  };

  const renderEditableCell = (cell) => {
    if (systemColumns.includes(cell.column.id)) {
      return cell.render("Cell");
    }

    if (!cell.row.isSelected) {
      const renderedPropertyValue = propertyRender(cell.column.id, cell.value);
      return renderCell
        ? renderCell(cell.column.id, renderedPropertyValue, cell.row.original)
        : renderedPropertyValue;
    }

    const changingEntry = entries.find(
      (entry) => entry.id === cell.row.original.id
    );

    if (cell.column.id === "label") {
      if (property.masterValuesetName != null) {
        const filteredOptions = options.filter(
          (option) =>
            !property.isDependant() ||
            option.filter === cell.row.original.filter
        );
        const existingValues = _(filteredOptions).map("value").value();

        const slaveOptions = valueset
          .filter(
            (item) =>
              (!existingValues.includes(item.value) &&
                !property.isDependant()) ||
              cell.row.original.filter === item.filter
          )
          .map(({ value, label }) => ({ value, label }));

        return (
          <PancoSelect
            className="w-100"
            value={[changingEntry[cell.column.id]]}
            onChange={({ value }) =>
              onChangeEntry(changingEntry.id, cell.column.id, value)
            }
            required
            options={slaveOptions}
            placeholder={t("common.click_start_typing", {
              defaultValue: "Click and start typing",
            })}
          />
        );
      }

      if (property.addingOptions.length > 0) {
        return (
          <PancoSelect
            className="w-100"
            value={changingEntry[cell.column.id]}
            onChange={({ value }) =>
              onChangeEntry(changingEntry.id, cell.column.id, value)
            }
            required
            options={property.addingOptions.map((o) => ({
              label: o,
              value: o,
            }))}
            placeholder={t("common.click_start_typing", {
              defaultValue: "Click and start typing",
            })}
          />
        );
      }

      return (
        <PancoInput
          className="w-100"
          value={changingEntry[cell.column.id]}
          onChange={({ value }) =>
            onChangeEntry(changingEntry.id, cell.column.id, value)
          }
          required
          placeholder={t("common.click_start_typing", {
            defaultValue: "Click and start typing",
          })}
        />
      );
    }

    if (cell.column.id === "filter" && property.isDependant()) {
      return (
        <PancoSelect
          className="w-100"
          value={[changingEntry[cell.column.id]]}
          options={dependencyOptions}
          onChange={({ value }) =>
            onChangeEntry(changingEntry.id, cell.column.id, value)
          }
          required
          placeholder={t("common.click_start_typing", {
            defaultValue: "Click and start typing",
          })}
        />
      );
    }

    const editableProperties = _(valuesetItemProperties)
      .filter((prop) => prop.editable && !prop.isHidden(cell.row.original))
      .value();
    const editableProperty = editableProperties.find(
      (prop) => prop.name === cell.column.id
    );

    if (editableProperty) {
      return (
        <FormProperty
          className="w-100"
          property={editableProperty}
          value={changingEntry[cell.column.id]}
          onChange={({ value }) =>
            onChangeEntry(changingEntry.id, cell.column.id, value)
          }
          inline
          filter={cell.row.original}
          control
          placeholder={t("common.click_start_typing", {
            defaultValue: "Click and start typing",
          })}
        />
      );
    }

    return propertyRender(cell.column.id, cell.value);
  };

  return (
    <TableWrapper>
      <div {...getTableProps()}>
        {headerGroups.map((headerGroup) => (
          <div key="header-group" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <HeaderCell
                key={column.name}
                {...column.getHeaderProps()}
                condensed
                className={classNames("d-flex align-items-center")}
              >
                <div className="d-flex flex-column h-100">
                  <div className="d-flex align-items-center">
                    <span className="mr-2">
                      {column.translation
                        ? t(column.translation, { defaultValue: column.Header })
                        : column.render("Header")}
                    </span>
                    {column.description != null && (
                      <HelperTooltip
                        title={column.Header}
                        text={column.description}
                      />
                    )}
                  </div>
                  <div>{renderEditableHeaderCell(column.id)}</div>
                </div>
              </HeaderCell>
            ))}
          </div>
        ))}

        <Body {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Row
                key={row.original.id}
                {...row.getRowProps()}
                onClick={(e) => {
                  row.triggerSelection(e.shiftKey);
                }}
                onContextMenu={() => row.toggleRowSelected(true)}
              >
                {row.cells.map((cell) => (
                  <Cell
                    key={cell.column.id}
                    {...cell.getCellProps()}
                    onClick={(e) => {
                      if (row.isSelected) {
                        e.stopPropagation();
                      }
                    }}
                    condensed
                    className={classNames(
                      "d-flex align-items-center justify-content-start"
                    )}
                  >
                    {renderEditableCell(cell)}
                  </Cell>
                ))}
              </Row>
            );
          })}
        </Body>
      </div>

      {pageCount > 1 && (
        <div className="text-center mt-3">
          <PancoPagination
            pageIndex={pageIndex}
            pageCount={pageCount}
            gotoPage={(newPage) => {
              gotoPage(newPage);
              onPageChanged(newPage);
            }}
          />
        </div>
      )}
    </TableWrapper>
  );
};

export default BulkEditValuesetTable;
