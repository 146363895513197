/* eslint-disable func-names */
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
  take,
  fork,
  actionChannel,
  getContext,
} from "redux-saga/effects";
import { utils, writeFile } from "xlsx";
import ExcelJS from "exceljs";
import { DateTime } from "luxon";
import _, { pick } from "lodash";
import * as actions from "../contants/docareaConstants";
import { valuesetSource as ValuesetSource } from "cosmos-config/generator";
import callApi from "./effects/callApi";
import docareaApi from "../apis/docareaApi";
import repositoryApi from "../apis/repositoryApi";
import docareaSlice from "../slices/docareaSlice";
import {
  getDocareaName,
  getValuesets,
  getValuesetsMap,
} from "../selectors/docareaSelector";
import {
  reloadValueset,
  fetchMasterValuesets as fetchMasterValuesetsAction,
} from "../actions/docareaActions";
import masterdataApi from "../apis/masterdataApi";
import { generateValuesetItemKey } from "../utils";
import {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from "cosmos-config/utils";
import valuesetItem from "../contants/valuesetItem";
import {
  getOpenedProjectCode,
  getResourceFolderId,
} from "../selectors/projectSelector";
import { parseExcelFile } from "../utils/fileUtils";
import complete from "./effects/complete";
import repositorySearchApi from "../apis/repositorySearchApi";

const valuesetSorter = (a, b) => {
  if (typeof a.value === "number" && typeof b.value === "number") {
    return a.value - b.value;
  }

  if (String(a.label).match(/^other/gi)) {
    return 1;
  }

  if (String(b.label).match(/^other/gi)) {
    return -1;
  }

  if (a.favourite) {
    return -1;
  }

  if (b.favourite) {
    return 1;
  }

  return String(a.label).localeCompare(b.label);
};

function* fetchValueset(valuesetName) {
  const docAreaName = yield select(getDocareaName);

  try {
    yield put(docareaSlice.actions.valuesetsLoading(true));

    const { data, name } = yield callApi(
      docareaApi.getValuesSet,
      docAreaName,
      valuesetName
    );

    if (data == null) {
      // yield put(notify("Received data are corrupted !", "error"));
      return null;
    }

    const vsData = atob(data)
      .split(/\r?\n/)
      .map((item) => {
        const itemContent = item.split(";");

        return {
          label: itemContent[1],
          value: itemContent[0].replace(/\n$/, ""),
          filter: itemContent[2],
        };
      })
      .filter((vse) => vse.label != null && vse.label !== "")
      .sort(valuesetSorter);

    return {
      name,
      data: vsData,
    };
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));
  }

  return null;
}

function* fetchMasterdataValueset(valuesetName) {
  const docAreaName = yield select(getDocareaName);
  yield put(docareaSlice.actions.valuesetsLoading(true));
  try {
    const items = yield callApi(
      masterdataApi.getMasterData,
      docAreaName,
      valuesetName,
      ["CODE", "FILTER", "NAME"],
      {
        orderBy: { NAME: "asc" },
      }
    );

    const data = items
      .map((i) =>
        i.properties.reduce(
          (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
          {}
        )
      )
      .map(({ CODE: value, FILTER: filter, NAME: label }) => ({
        label,
        value,
        filter,
      }))
      .filter((i) => i.label != null)
      .sort(valuesetSorter);

    return {
      name: valuesetName,
      data,
    };
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));
  }

  return null;
}

function* fetchAggregatedMinMaxValueset(propertyName) {
  try {
    yield put(docareaSlice.actions.valuesetsLoading(true));
    const projectCode = yield select(getOpenedProjectCode);
    const folderId = yield select(getResourceFolderId);
    const result = yield callApi(
      repositorySearchApi.searchAggregateMinMax,
      projectCode,
      folderId,
      propertyName
    );

    const data = result
      .flatMap((item) =>
        item.properties.map((p) => ({ label: p.value, value: p.value }))
      )
      .filter((x) => x != null);

    return {
      name: propertyName,
      data,
    };
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));
  }

  return null;
}

function* fetchAggregatedSearchValueset(propertyName) {
  try {
    yield put(docareaSlice.actions.valuesetsLoading(true));

    const projectCode = yield select(getOpenedProjectCode);
    const folderId = yield select(getResourceFolderId);

    const result = yield callApi(
      repositorySearchApi.searchAggregateDistinct,
      projectCode,
      folderId,
      propertyName,
      { subtree: true }
    );

    const data = result
      .map((item) => {
        const value = item.properties.reduce((acc, cur) => {
          return cur.name === propertyName ? cur.value : acc;
        }, "");

        return value != null ? { label: value, value } : null;
      })
      .filter((x) => x != null)
      .sort(valuesetSorter);

    return {
      name: propertyName,
      data,
    };
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));
  }

  return null;
}

function* fetchAggregateResourceValueset(propertyName) {
  try {
    yield put(docareaSlice.actions.valuesetsLoading(true));

    const projectCode = yield select(getOpenedProjectCode);
    const folderId = yield select(getResourceFolderId);

    if (folderId == null) {
      return null;
    }

    const items = yield callApi(
      repositorySearchApi.searchResourceRelations,
      projectCode,
      folderId,
      ["displayname", "fileextension", "parentresourceid"],
      propertyName
    );

    const data = _(items)
      .map((i) => {
        const resource = _(i.properties)
          .keyBy("name")
          .mapValues("value")
          .value();

        return {
          value: i.id,
          label: resource?.displayname,
          fileextension: resource?.fileextension,
          filter: resource?.parentresourceid,
        };
      })
      .sort(valuesetSorter)
      .value();

    return {
      name: propertyName,
      data,
    };
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));
  }

  return null;
}

function* fetchCustomValueset(valuesetName) {
  const docarea = yield select(getDocareaName);

  try {
    yield put(docareaSlice.actions.valuesetsLoading(true));

    const data = yield callApi(
      docareaApi.getCustomValueset,
      docarea,
      valuesetName
    );

    return {
      name: valuesetName,
      data: data
        .map((d) => resourceCorrectionOfGateway(d, valuesetItem))
        .filter((i) => i.value != null)
        .sort(valuesetSorter),
    };
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));
  }

  return null;
}

function* fetchStaticValueset(propertyName, options = {}) {
  const { data } = options;
  if (Array.isArray(data) && data.length > 0) {
    const valueset = {
      name: propertyName,
      data: data.sort(valuesetSorter),
    };
    yield put(docareaSlice.actions.addValueset(valueset));
    return valueset;
  }

  return null;
}

function* addCustomValuesetItem(action) {
  const { valuesetName, valuesetItem, generateKey } = action.payload;

  const valuesets = yield select(getValuesets);
  const valueset = valuesets.find((vs) => vs.name === valuesetName);

  if (valueset != null) {
    const existingKeys = valueset.data.map((vs) => vs.value);
    const itemKey = generateValuesetItemKey(valuesetItem, existingKeys);

    const idx = valueset.data.findIndex(
      (entry) =>
        String(entry.label).toLowerCase() ===
          String(valuesetItem.label).toLowerCase() &&
        valuesetItem.filter === entry.filter
    );

    if (
      generateKey &&
      (idx !== -1 || valuesetItem.label === "" || valuesetItem.label == null)
    ) {
      // yield put(
      //   notify(
      //     `Valueset entry with label "${valuesetItem.label}" already exists.`,
      //     "user-error"
      //   )
      // );
      return;
    }

    try {
      const docAreaName = yield select(getDocareaName);
      let newValuesetItem = valuesetItem;

      if (generateKey) {
        newValuesetItem = {
          ...valuesetItem,
          value: itemKey,
        };
      }

      yield put(docareaSlice.actions.valuesetsLoading(true));

      yield callApi(
        docareaApi.postCustomValuesetItem,
        docAreaName,
        valuesetName,
        newValuesetItem
      );

      yield complete(action, newValuesetItem);
      // yield put(notify("Valueset entry has been created.", "success"));
    } catch (err) {
      console.error(err);
    } finally {
      yield put(docareaSlice.actions.valuesetsLoading(false));
      yield put(reloadValueset(valuesetName));
    }
  }
}

function* removeCustomValusetItems(action) {
  const { valuesetName, itemIds, masterValueset } = action.payload;
  const docAreaName = yield select(getDocareaName);

  try {
    yield put(docareaSlice.actions.valuesetsLoading(true));
    yield callApi(
      docareaApi.deleteAllCustomValuesetItems,
      docAreaName,
      valuesetName,
      itemIds
    );

    yield complete(action);
    // yield put(notify("Valueset entry has been removed.", "info"));
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));

    if (masterValueset) {
      yield put(fetchMasterValuesetsAction());
    } else {
      yield put(reloadValueset(valuesetName));
    }
  }
}

function* updateCustomValuesetItems(action) {
  const { valuesetName, valuesetItems } = action.payload;
  const docAreaName = yield select(getDocareaName);

  try {
    yield put(docareaSlice.actions.valuesetsLoading(true));
    if (valuesetItems.length > 1) {
      yield callApi(
        docareaApi.importCustomValuesetItems,
        docAreaName,
        valuesetName,
        valuesetItems
      );
    } else {
      const [valuesetItem] = valuesetItems;
      yield callApi(
        docareaApi.postCustomValuesetItem,
        docAreaName,
        valuesetName,
        valuesetItem
      );
    }

    yield complete(action);
    // yield put(notify("Valueset entry has been updated.", "success"));
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));
    yield put(reloadValueset(valuesetName));
  }
}

function* fetchMasterValuesets() {
  const docarea = yield select(getDocareaName);

  try {
    yield put(
      docareaSlice.actions.valuesetsLoading(true, "Loading master valuesets.")
    );

    const masterValuesets = yield callApi(
      docareaApi.getMasterValuesets,
      docarea
    );

    const addMasterValuesetSagas = masterValuesets.map((masterValueset) =>
      put(docareaSlice.actions.addMasterValueset(masterValueset))
    );
    yield all(addMasterValuesetSagas);
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));
  }
}

function* importCustomValuesetFile(action) {
  const { valuesetName, files, masterValueset } = action.payload;
  const docarea = yield select(getDocareaName);
  const reader = new FileReader();

  const results = yield all(
    Array.from(files).map((file) => call(parseExcelFile, file))
  );

  const valuesetItemMap = _.keyBy(valuesetItem, "name");
  const items = results
    .flatMap((x) => x)
    .map((x) =>
      _.mapValues(x, (value, key) => {
        const vItem = valuesetItemMap[key];

        if (!vItem?.multiple) {
          return value;
        }

        return value.split(",");
      })
    );
  const existingKeys = items.map((i) => i.value);

  try {
    const customValuesetItems = items.map((item) => ({
      ...item,
      value: item.value || generateValuesetItemKey(item, existingKeys),
    }));

    yield put(docareaSlice.actions.valuesetsLoading(true));

    yield callApi(
      docareaApi.importCustomValuesetItems,
      docarea,
      valuesetName,
      customValuesetItems
    );

    if (masterValueset) {
      yield put(fetchMasterValuesetsAction());
    } else {
      yield put(reloadValueset(valuesetName));
    }

    yield complete(action);
    // yield put(notify("Valueset has been imported from file.", "success"));
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));
  }
}

function* exportValuesetFile(action) {
  const { valuesetName, masterValueset, exportingProps } = action.payload;
  const valuesets = yield select((state) =>
    masterValueset ? state.docarea.masterValuesets : state.docarea.valuesets
  );
  const valueset = valuesets.find((vs) => vs.name === valuesetName);

  const exportingPropsMap = _.keyBy(exportingProps, "name");

  if (valueset != null) {
    let propsToExport = exportingProps?.length
      ? [...exportingProps.map((prop) => prop.name)]
      : ["id", "value", "label", "filter"];
    if (!masterValueset) {
      propsToExport = [...propsToExport, "filter", "keywords"];
    }

    const data = valueset.data
      .map((item) => pick(item, propsToExport))
      .map((item) => {
        return _.mapValues(item, (value, key) => {
          if (exportingPropsMap[key]?.type === "date" && value) {
            return new Date(value).toLocaleString();
          }

          if (Array.isArray(value)) {
            return value.join(",");
          }

          return value;
        });
      });

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);

    const fileName = `${valueset.name}_${DateTime.now().toSQLDate()}.xlsx`;

    utils.book_append_sheet(wb, ws, valueset.name);
    writeFile(wb, fileName);

    yield complete(action);
  }
}

function* saveMasterValuesetItem(action) {
  const { valuesetName, valuesetItem } = action.payload;
  try {
    const docarea = yield select(getDocareaName);

    yield put(docareaSlice.actions.valuesetsLoading(true));

    yield callApi(
      docareaApi.postCustomValuesetItem,
      docarea,
      valuesetName,
      valuesetItem
    );

    yield complete(action, valuesetItem);
    // yield put(notify("Master valueset entry has been created.", "success"));
  } catch (err) {
    console.error(err);
  } finally {
    yield put(docareaSlice.actions.valuesetsLoading(false));
    yield put(fetchMasterValuesetsAction());
  }
}

const functionsMap = {
  [ValuesetSource.VALUESET]: fetchValueset,
  [ValuesetSource.MASTERDATA]: fetchMasterdataValueset,
  [ValuesetSource.AGGREGATE]: fetchAggregatedSearchValueset,
  [ValuesetSource.AGGREGATE_MINMAX]: fetchAggregatedMinMaxValueset,
  [ValuesetSource.AGGREGATE_RESOURCE]: fetchAggregateResourceValueset,
  [ValuesetSource.CUSTOM_VALUESET]: fetchCustomValueset,
  [ValuesetSource.STATIC_VALUESET]: fetchStaticValueset,
  // [ValuesetSource.DISTINCT_RESOURCE]: fetchDistinctResourceValueset, // this is an issue
};

function* valuesetFlow({
  valuesetName,
  valuesetSource,
  masterValuesetName,
  data,
}) {
  while (true) {
    try {
      const valueset = yield call(
        functionsMap[valuesetSource] || fetchValueset,
        valuesetName,
        {
          data,
        }
      );

      if (valueset != null) {
        if (masterValuesetName != null) {
          const masterValueset = yield call(
            fetchCustomValueset,
            masterValuesetName
          );

          const masterValuesetMap = _(masterValueset.data)
            .keyBy("value")
            .mapValues("label")
            .value();

          const pairedValueset = {
            ...valueset,
            data: valueset.data
              .map((item) => {
                const masterLabel = masterValuesetMap[item.value];

                return {
                  ...item,
                  label: masterLabel || item.label,
                };
              })
              .sort(valuesetSorter),
          };

          yield put(docareaSlice.actions.addMasterValueset(masterValueset));
          yield put(docareaSlice.actions.addValueset(pairedValueset));
        } else {
          yield put(docareaSlice.actions.addValueset(valueset));
        }
      }
    } catch (err) {
      console.error(err);
    }

    yield take(
      (action) =>
        action.type === actions.RELOAD_VALUESET &&
        action.payload === valuesetName
    );
  }
}

function* addItemToValueset({ payload }) {
  const { valuesetName, valuesetItem } = payload;
  const valuesets = yield select(getValuesets);
  const valueset = valuesets.find((vs) => vs.name === valuesetName);

  if (valueset != null) {
    const newData = [...valueset.data, valuesetItem].sort(valuesetSorter);
    const newValueset = {
      ...valueset,
      data: newData,
    };
    yield put(docareaSlice.actions.addValueset(newValueset));
  }
}

export default function* docareaSaga() {
  // yield buffer(500, actions.FETCH_VALUESET, function (actions) {
  // const groupedActions = _(actions)
  //   .uniqBy('valuesetName')
  //   .filter(
  //     (action) => action?.valuesetSource === ValuesetSource.CUSTOM_VALUESET
  //   )
  //   .map((action) => {
  //     const parts = String(action.valuesetName).split('_');
  //     const prefix = parts?.length > 0 ? parts[0] : null;
  //     return {
  //       ...action,
  //       prefix,
  //     };
  //   })
  //   .groupBy('prefix')
  //   .value();
  // const valuesetSagas = Object.keys(groupedActions)
  //   .map(prefix => callApi(docareaApi.getCustomValuesets, "RWE", prefix));
  // const results = yield all(valuesetSagas);
  // });

  let valuesetFlows = [];

  yield fork(function* () {
    while (true) {
      const { payload } = yield take(actions.FETCH_VALUESETS);

      const difference = _(payload)
        .differenceBy(
          valuesetFlows.map((valuesetName) => ({ valuesetName })),
          "valuesetName"
        )
        .compact()
        .value();

      if (!_.isEmpty(difference)) {
        yield all(
          difference.map((valuesetMeta) => fork(valuesetFlow, valuesetMeta))
        );

        valuesetFlows = _.union(
          valuesetFlows,
          _(difference).map("valuesetName").value()
        );
      }
    }
  });

  yield fork(function* () {
    const fetchValuesetChannel = yield actionChannel(actions.FETCH_VALUESET);

    while (true) {
      const action = yield take(fetchValuesetChannel);
      const { valuesetName } = action;

      if (
        !valuesetFlows.includes(valuesetName) &&
        valuesetName != null &&
        valuesetName !== ""
      ) {
        yield fork(valuesetFlow, action);
        valuesetFlows.push(valuesetName);
      }
    }
  });

  yield takeLatest(actions.FETCH_MASTER_VALUESETS, fetchMasterValuesets);
  yield takeLeading(actions.SAVE_MASTER_VALUESET_ITEM, saveMasterValuesetItem);
  yield takeLeading(actions.ADD_CUSTOM_VALUESET_ITEM, addCustomValuesetItem);
  yield takeLeading(
    actions.UPDATE_CUSTOM_VALUESET_ITEMS,
    updateCustomValuesetItems
  );
  yield takeLeading(
    actions.REMOVE_CUSTOM_VALUSET_ITEMS,
    removeCustomValusetItems
  );
  yield takeLeading(
    actions.IMPORT_CUSTOM_VALUESET_FILE,
    importCustomValuesetFile
  );
  yield takeLeading(actions.EXPORT_VALUESET_FILE, exportValuesetFile);
  yield takeLatest(actions.ADD_ITEM_TO_VALUESET, addItemToValueset);
  yield takeEvery(actions.FETCH_AGGREGATE_VALUES, function* (action) {
    const { propertyName } = action.payload;
    try {
      const projectCode = yield select(getOpenedProjectCode);
      const folderId = yield select(getResourceFolderId);
      const items = yield callApi(
        repositorySearchApi.searchAggregateDistinct,
        projectCode,
        folderId,
        propertyName
      );

      const result = _(items)
        .map("properties")
        .map((props) => _(props).keyBy("name").mapValues("value").value())
        .map((item) => ({
          value: item[propertyName],
          count: item.identifier,
        }))
        .value();

      yield complete(action, result);
    } catch (err) {
      console.error(err);
    }
  });
  yield takeLeading(actions.REPLACE_ALL_VALUES, function* (action) {
    const { propertyName, value, newValue, resourceIds } = action.payload;
    yield put(docareaSlice.actions.valuesetsLoading(true));

    try {
      const projectService = yield getContext("projectService");
      const properties = yield call(projectService.getProperties);

      const resource = resourceCorrectionForGateway(
        { [propertyName]: newValue },
        properties
      );
      const folderId = yield select(getResourceFolderId);

      yield callApi(
        repositoryApi.replaceAllValues,
        folderId,
        resourceIds,
        propertyName,
        value,
        resource[propertyName]
      );
      yield complete(action);
    } catch (err) {
      console.error(err);
    }

    yield put(docareaSlice.actions.valuesetsLoading(false));
  });
  yield takeLeading(actions.EXPORT_VALUESETS_EXCEL, function* (action) {
    const { valuesetNames } = action.payload;
    try {
      const projectService = yield getContext("projectService");
      const properties = yield call(projectService.getProperties);

      const valuesetsMap = yield select(getValuesetsMap);

      const propertiesMap = _.keyBy(properties, "valuesetName");

      const dataMap = _(valuesetsMap)
        .mapKeys((v, key) => {
          const property = propertiesMap[key];
          return property?.name;
        })
        .mapValues((values) =>
          _(values).keyBy("value").mapValues("label").value()
        )
        .value();

      const data = _(valuesetsMap)
        .pick(valuesetNames)
        .map((values, valuesetName) => {
          const property = propertiesMap[valuesetName];

          return {
            propertyName: property?.name,
            propertyLabel: property?.label,
            values: _.sortBy(
              values.map((v) => {
                if (property.dependency != null) {
                  const dependencyValue =
                    dataMap[property.dependency]?.[v.filter];
                  return `[${dependencyValue}] ${v.label}`;
                }

                return v.label;
              })
            ),
          };
        })
        .value();

      const workbook = new ExcelJS.Workbook();

      const valuesetsSheet = workbook.addWorksheet("valuesets");
      valuesetsSheet.columns = _(data)
        .map((i) => ({
          header: i.propertyLabel,
          key: i.propertyName,
        }))
        .orderBy("header")
        .value();

      data.forEach(({ propertyName, propertyLabel, values }) => {
        const c = valuesetsSheet.getColumn(propertyName);
        if (c != null) {
          c.values = [propertyLabel, ...values];
        }
      });

      valuesetsSheet.getRow(1).font = { bold: true };

      const buffer = yield call([workbook.xlsx, workbook.xlsx.writeBuffer]);
      const blob = new Blob([buffer]);

      const project = yield call(projectService.getOpenedProject);
      const fileName = `${
        project.name
      }_valuesets_${DateTime.now().toSQLDate()}.xlsx`;

      yield complete(action, {
        contentUrl: URL.createObjectURL(blob),
        fileName,
      });
    } catch (err) {
      console.error(err);
    }
  });
}
