import {
  BrowserCacheLocation,
  PublicClientApplication,
} from "@azure/msal-browser";

const azureAdClientId = process.env.REACT_APP_ADAL_CLIENT_ID;
const msalRedirectUri = process.env.REACT_APP_MSAL_REDIRECT_URI;

const azureadOpenidClient = new PublicClientApplication({
  auth: {
    clientId: azureAdClientId,
    authority:
      "https://login.microsoftonline.com/f481d56f-c63d-4d74-8406-d64e74f44395",
    navigateToLoginRequestUrl: false,
    redirectUri: msalRedirectUri || undefined,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    allowRedirectInIframe: true
  }
});

const b2cLoginClient = new PublicClientApplication({
  auth: {
    clientId: "8e7781e3-08df-443c-a9be-a53cfdf5d5cc",
    authority:
      "https://rweresdms.b2clogin.com/7db2e317-ba84-4d8b-b143-be91128efcb6/b2c_1_signupandsignin",
    knownAuthorities: ["rweresdms.b2clogin.com"],
    navigateToLoginRequestUrl: false,
    redirectUri: msalRedirectUri || undefined,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    allowRedirectInIframe: true
  }
});

export { azureadOpenidClient, b2cLoginClient };
