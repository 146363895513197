import React, { useMemo } from "react";
import useProperties from "../../../hooks/useProperties";
import Resource from "../../../types/resource";
import SimpleTable from "../../../components/SimpleTable";
import { CosmosWidgetProps } from "../types/CosmosWidget";
import { property } from "cosmos-config/generator";
import _ from "lodash";
import DocumentIcon from "../../../components/DocumentIcon";
import { DateTime } from "luxon";

export interface AlertsTableProps extends CosmosWidgetProps {
  documents?: Resource[];
}

const AlertsTable = ({
  propertyName,
  documents,
  onSelect,
}: AlertsTableProps) => {
  const { propertiesMap } = useProperties();

  const dateProperty = useMemo(() => {
    if (propertyName == null) {
      return null;
    }

    return propertiesMap[propertyName];
  }, [propertiesMap, propertyName]);

  const properties = useMemo(() => {
    return _.compact([
      property("", "fileextension").includeTable(true, 30),
      property("Name", "displayname"),
      dateProperty,
    ]);
  }, [dateProperty]);

  return (
    <SimpleTable<Resource>
      data={documents}
      properties={properties}
      renderCell={(name, value, label, res) => {
        if (name === "fileextension") {
          return <DocumentIcon document={res} />;
        }

        if (propertyName != null) {
          const millis = res[propertyName];
          if (millis < DateTime.now().toMillis()) {
            return <span className="text-danger">{label}</span>;
          }
        }

        return label;
      }}
      onDoubleClick={(resource) => {
        if (onSelect != null) {
          onSelect("document", resource?.identifier);
        }
      }}
    />
  );
};

export default AlertsTable;
