import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PancoPopover from "../panco-popover/PancoPopover";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Placement } from "@popperjs/core";
import FieldActionIcon from "../FieldActionIcon";

declare type HelperTooltipProps = {
  title?: string;
  text?: any;
  placement?: Placement;
  className?: string;
};

const HelperTooltip = React.memo<HelperTooltipProps>(
  ({ title, text, placement, className }: HelperTooltipProps) => (
    <FieldActionIcon className={className}>
      <PancoPopover text={text} title={title} placement={placement || "auto"}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </PancoPopover>
    </FieldActionIcon>
  ),
  () => true
);

HelperTooltip.displayName = "HelperTooltip";

export default HelperTooltip;
