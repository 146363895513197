/* eslint-disable func-names */
import {
  takeLatest,
  select,
  put,
  retry,
  fork,
  takeLeading,
  race,
  take,
  delay,
} from "redux-saga/effects";
import projectApi from "../apis/projectApi";
import { resourceCorrectionOfGateway } from "cosmos-config/utils";
import projectSlice from "../slices/projectSlice";
import { getDocareaName } from "../selectors/docareaSelector";
import projectConstants from "../contants/project";
import userManagementSaga from "./userManagementSaga";
import projectMembersSaga from "./projectMembersSaga";
import callApi from "./effects/callApi";
import * as actions from "../contants/projectConstants";
import { fetchProjectAccessRequests as fetchProjectAccessRequestsAction } from "../actions/projectActions";
import spocSaga from "./spocSaga";
import {
  getOpenedProjectCode,
  getProjects,
} from "../selectors/projectSelector";
import complete from "./effects/complete";
import _ from "lodash";

function* fetchProjectAccessRequests(projectCode) {
  try {
    const requests = yield callApi(
      projectApi.getProjectAccessRequests,
      projectCode
    );

    yield put(projectSlice.actions.setAccessRequests(requests));
  } catch (err) {
    console.error(err);
    // yield put(
    //   notify(
    //     `Error while loading access requests, ${err.message}`,
    //     "system-error"
    //   )
    // );
  }
}

function* refuseProjectAccessRequest(projectCode, action) {
  const { principalId } = action.payload;
  try {
    yield callApi(
      projectApi.deleteProjectAccessRequest,
      projectCode,
      principalId
    );

    // yield put(
    //   notify(`Access request was refused successfuly`, "success")
    // );

    yield complete(action);
  } catch (err) {
    console.error(err);
    // yield put(
    //   notify(
    //     `Error while refusing project access, reason: ${err.message}.`,
    //     "system-error"
    //   )
    // );
  } finally {
    yield put(fetchProjectAccessRequestsAction());
  }
}

function* projectSaga() {
  const docarea = yield select(getDocareaName);

  yield takeLeading(actions.REQUEST_PROJECT_ACCESS, function* (action) {
    const { projectCode, department, options } = action.payload;
    const { accessRequest } = options;

    try {
      yield callApi(projectApi.requestProjectAccess, projectCode, {
        ...(accessRequest || {}),
        department,
      });

      // yield put(
      //   notify(
      //     `Your request was stored and would resolved as soon as possible.`,
      //     "success"
      //   )
      // );
      yield complete(action);
    } catch (err) {
      // yield put(
      //   notify(
      //     `Project access request failed, reason: ${err.message}.`,
      //     "error"
      //   )
      // );
    }
  });

  // yield takeLeading(CREATE_PROJECT, function* ({ project, callback }) {
  //   try {
  //     const projectToUpdate = resourceCorrectionForGateway(
  //       project,
  //       createProject
  //     );

  //     yield callApi(projectApi.createProject, docarea, projectToUpdate);
  //     // yield put(fetchProjects());
  //     yield spawn(callback);
  //   } catch (err) {
  //     console.error(err);
  //     yield put(
  //       notify(
  //         `Creation of project failed with following reason: ${err.message}.`,
  //         "error"
  //       )
  //     );
  //   }
  // });

  yield takeLatest(actions.FETCH_PROJECTS, function* () {
    try {
      const projects = yield retry(3, 5000, projectApi.getProjects, docarea);
      yield put(
        projectSlice.actions.setProjects(
          projects.map((p) => resourceCorrectionOfGateway(p, projectConstants))
        )
      );

      return projects;
    } catch (err) {
      console.error(err);
    }
  });

  // let flowProjectCode = null;
  yield takeLatest(
    [
      projectSlice.actions.openProject.type,
      projectSlice.actions.setProjects.type,
    ],
    function* (action) {
      const { type, payload } = action;

      let project;

      if (type === projectSlice.actions.openProject.type) {
        const projects = yield select(getProjects);
        project = projects.find((p) => p.code === payload);
      } else {
        const openedProjectCode = yield select(getOpenedProjectCode);
        project = payload.find((p) => p.code === openedProjectCode);
      }

      // if (project != null && project.code !== flowProjectCode) {
      if (project != null) {
        const { code, administrator, spoc } = project;

        yield fork(projectMembersSaga, code);
        yield fork(userManagementSaga, code);
        yield fork(spocSaga, code);

        if (administrator || spoc) {
          yield takeLatest(
            actions.FETCH_PROJECT_ACCESS_REQUESTS,
            fetchProjectAccessRequests,
            code
          );

          yield put(fetchProjectAccessRequestsAction());

          yield takeLeading(
            actions.REFUSE_PROJECT_ACCESS_REQUEST,
            refuseProjectAccessRequest,
            code
          );
        }

        // flowProjectCode = code;
      }
    }
  );

  yield takeLeading(projectSlice.actions.setProjects.type, function* () {
    const openedProjectCode = yield select(getOpenedProjectCode);

    if (openedProjectCode == null) {
      const [openProjectAction] = yield race([
        take(projectSlice.actions.openProject.type),
        delay(5000),
      ]);

      if (openProjectAction == null) {
        const projects = yield select(getProjects);
        const defaultProjectCode = _(projects)
          .filter("available")
          .map("code")
          .first();

        if (defaultProjectCode != null) {
          yield put(projectSlice.actions.openProject(defaultProjectCode));
        }
      }
    }
  });
}

export default projectSaga;
