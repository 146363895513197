import { useQuery } from "@tanstack/react-query";
import workflowApi from "../../../apis/workflowApi";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useProject from "../../../hooks/useProject";
import { useMemo } from "react";
import useProperties from "../../../hooks/useProperties";
import { resourceCorrectionOfNscale } from "cosmos-config/utils";
import _ from "lodash";

const useUserWorkflowTasks = () => {
  const { principalId, authenticated } = useCurrentUser();
  const { workflows } = useProject();
  const { getNscaleWorkflowProperties } = useProperties();

  const processDefinitionKey = useMemo(() => {
    const [defaultWorkflow] = workflows.filter((wf) => wf.default);

    if (defaultWorkflow != null) {
      return defaultWorkflow.processName;
    }

    const [firstWorkflow] = workflows;
    return firstWorkflow?.processName;
  }, [workflows]);

  const workflowProperties = useMemo(
    () => getNscaleWorkflowProperties(processDefinitionKey),
    [getNscaleWorkflowProperties, processDefinitionKey]
  );

  const { data, isLoading } = useQuery({
    queryKey: ["workflow-tasks", principalId, processDefinitionKey],
    queryFn: () => {
      const requestedProps = _.compact(
        workflowProperties.map((prop) => prop.query)
      );
      return workflowApi.getAssignedTasks(
        "RWE",
        processDefinitionKey,
        requestedProps
      );
    },
    enabled: processDefinitionKey != null && authenticated,
  });

  const tasks = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.items.map((i: any) =>
      resourceCorrectionOfNscale(i, workflowProperties)
    );
  }, [data, workflowProperties]);

  return {
    tasks,
    loading: isLoading,
  };
};

export default useUserWorkflowTasks;
