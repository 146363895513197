import { PancoInput, PancoSwitch } from "cosmos-components";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useCallback } from "react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import classNames from "classnames";

const BusinessProcesses = ({ businessProcesses, onChange, className }) => {
  const handleWidgetChange = useCallback(
    (idx, updateCallback = (w) => w) => {
      if (idx === businessProcesses.length) {
        onChange(
          [
            ...businessProcesses,
            updateCallback({
              processDefinitionKey: null,
              excludeThirdParty: false,
              resourceProperties: [],
            }),
          ].filter((w) => w != null)
        );
      } else {
        onChange(
          businessProcesses
            .map((w, i) => {
              if (idx === i) {
                return updateCallback(w);
              }

              return w;
            })
            .filter((w) => w != null)
        );
      }
    },
    [businessProcesses, onChange]
  );

  return (
    <div className={classNames(className, "table-responsive")}>
      <table className="table table-sm ">
        <thead>
          <tr>
            <th>Process Definition Key</th>
            <th>Exclude Third Party</th>
            <th>Resource Properties</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {businessProcesses.map((process, idx) => (
            <tr key={idx}>
              <td>
                <PancoInput
                  value={process.processDefinitionKey}
                  onChange={({ value }) => {
                    handleWidgetChange(idx, (w) => ({
                      ...w,
                      processDefinitionKey: value,
                    }));
                  }}
                />
              </td>

              <td>
                <PancoSwitch
                  value={process.excludeThirdParty}
                  onChange={({ value }) => {
                    handleWidgetChange(idx, (w) => ({
                      ...w,
                      excludeThirdParty: value,
                    }));
                  }}
                />
              </td>

              <td>
                <PancoInput
                  multiple
                  value={process.resourceProperties}
                  onChange={({ value }) => {
                    handleWidgetChange(idx, (w) => ({
                      ...w,
                      resourceProperties: value,
                    }));
                  }}
                />
              </td>

              <td className="text-center">
                <Button
                  variant="link"
                  className="text-danger"
                  onClick={() => {
                    handleWidgetChange(idx, () => null);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Button
        variant="link"
        onClick={() => {
          handleWidgetChange(businessProcesses.length);
        }}
        className="mt-2"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        Add Business Process
      </Button>
    </div>
  );
};

BusinessProcesses.propTypes = {
  businessProcesses: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

BusinessProcesses.defaultProps = {
  businessProcesses: [],
  onChange: () => {},
  className: null,
};

export default BusinessProcesses;
