import { faCircle as faEmptyCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Card } from "react-bootstrap";
import CronEditor from "./CronEditor";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ReminderUsersEditor from "./ReminderUsersEditor";
import { DateTime } from "luxon";
import _ from "lodash";
import reminderProperties from "../constants/reminderProperties";
import SimpleUniversalForm from "../../../components/SimpleUniversalForm";
import ResourceReminderContext from "../contexts/ResourceReminderContext";
import useProject from "../../../hooks/useProject";
import MemberSelect from "../../../components/form/MemberSelect";
import { Reminder } from "../constants/reminder";
import SimpleFormGroup from "../../../components/SimpleFormGroup";
import { ProjectMember } from "../../../contexts/ProjectContext";

export interface ReminderEditorProps {
  reminder?: Reminder;
  className?: string;
  disabled?: boolean;
  onUpdated?: () => void;
}

const ReminderEditor = ({
  reminder,
  className,
  disabled,
  onUpdated,
}: ReminderEditorProps) => {
  const { t } = useTranslation();
  const { save, cancel, resourceType, resourceName } = useContext(
    ResourceReminderContext
  );
  const { members } = useProject();

  const [resource, setResource] = useState<Reminder>({
    triggerType: "ONCE",
    status: "CANCELLED",
  } as Reminder);

  useEffect(() => {
    setResource({ ...reminder, resourceName, resourceType } as Reminder);
  }, [reminder, resourceType, resourceName]);

  const active = !!resource?.status?.includes("ACTIVE");
  const isRecurrent = !!resource?.triggerType?.includes("RECURRENT");
  const isEvent = !!resource?.triggerType?.includes("EVENT");
  const eventType = _.first(resource?.eventType);

  const onCronChange = useCallback((cron: string) => {
    setResource(
      (r) =>
        ({
          ...r,
          startingAt: null,
          cron,
        } as Reminder)
    );
  }, []);

  const projectMembersMap = useMemo(
    () => _(members).valuesIn().flatMap().keyBy("principalId").value(),
    [members]
  );
  const owner = useMemo(() => {
    if (reminder != null) {
      return projectMembersMap[reminder.createdBy] || {};
    }

    return {} as ProjectMember;
  }, [projectMembersMap, reminder]);

  return (
    <Card key={reminder?.id} border="primary" className={classNames(className)}>
      <Card.Header className="d-flex align-items-center">
        <span
          className={classNames("mr-2", {
            "text-muted": !active,
            "text-success": active,
          })}
        >
          <FontAwesomeIcon icon={active ? faCircle : faEmptyCircle} />
        </span>

        <b>{resource.name}</b>
        <small className="ml-2">{`(owner: ${owner.formattedName})`}</small>

        {isRecurrent && (
          <small className="ml-auto text-muted">{`(${resource.cron} )`}</small>
        )}
      </Card.Header>
      <Card.Body>
        <Card.Text></Card.Text>

        <SimpleUniversalForm
          properties={reminderProperties}
          resource={resource}
          disabled={disabled}
          onUpdateResource={(id, name, value) => {
            setResource((r) => {
              const updatedResource = {
                ...r,
                [name]: value,
              };

              const dateTime = DateTime.local();
              const defaultCron = `0 0 0 ${dateTime.day} ${dateTime.month} *`;

              const triggerType = _.first(updatedResource.triggerType);
              switch (triggerType) {
                case "RECURRENT":
                  return {
                    ...updatedResource,
                    startingAt: null,
                    cron:
                      updatedResource.cron == null
                        ? defaultCron
                        : updatedResource.cron,
                  };
                case "ONCE":
                  return {
                    ...updatedResource,
                    cron: null,
                  };
                case "EVENT":
                  return {
                    ...updatedResource,
                    cron: null,
                    startingAt: null,
                  };
                default:
                  return updatedResource;
              }
            });
          }}
        />

        {isEvent && eventType === "CREATED_CHILD" && (
          <SimpleFormGroup label="Trigger Group" inline>
            <MemberSelect
              principalType={3}
              value={_.compact([resource.triggerGroupId])}
              onChange={({ value }) => {
                if (value != null) {
                  const [triggerGroupId] = value;
                  setResource((r) => ({
                    ...r,
                    triggerGroupId,
                  }));
                }
              }}
              clearable
            />
          </SimpleFormGroup>
        )}

        {isRecurrent && (
          <>
            <hr />
            <h4>Period</h4>
            <CronEditor
              className="mt-3"
              cron={resource.cron}
              onChange={onCronChange}
            />
          </>
        )}

        <hr />
        <h4>
          {t("repository.reminder_recipients", {
            defaultValue: "Recipients",
          })}
        </h4>
        <ReminderUsersEditor
          className="mt-3"
          reminder={resource}
          onChange={(users) => {
            setResource((r) => ({
              ...r,
              users,
            }));
          }}
        />

        <div className="text-right mt-3">
          <Button
            variant="link"
            className="text-danger mr-2"
            onClick={() => cancel(resource).then(onUpdated)}
          >
            {t("button.cancel", { defaultValue: "Cancel" })}
          </Button>
          <Button
            onClick={() => {
              save(resource).then(onUpdated);
            }}
          >
            {t("button.save", { defaultValue: "Save" })}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

ReminderEditor.propTypes = {
  reminder: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    describe: PropTypes.string,
    cron: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
    triggerType: PropTypes.arrayOf(PropTypes.string),
    createdBy: PropTypes.string,
  }),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onUpdated: PropTypes.func,
};

ReminderEditor.defaultProps = {
  onUpdated: () => {},
};

export default ReminderEditor;
