import PropTypes from "prop-types";
import React, { useContext, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { Badge, Button, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faFileImport,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import UserManagementContext from "../contexts/UserManagementContext";
import classNames from "classnames";
import useCoreDispatch from "../../../hooks/useCoreDispatch";
import { exportGroupMembersExcel } from "../../../actions/userManagementActions";
import { SlideDown, shadeColor } from "cosmos-components";
import { ProjectGroup } from "../../../contexts/ProjectContext";
import { useProject } from "../../../hooks";
import useProjectCompetence from "../../../hooks/useProjectCompetence";

const listItemActiveCss = css`
  z-index: 2;
  color: ${(props) => props.theme.background};
  background-color: ${(props) => props.theme.base};
`;

const listItemInactiveCss = css`
  z-index: 1;
  color: ${(props) => props.theme.base};
  background-color: ${(props) => props.theme.background};

  &:hover {
    background-color: ${(props) => shadeColor(0.1, props.theme.background)};
  }
`;

const ListItemHeader = styled.div``;

const ListItemActions = styled.div``;

type ListItemProps = {
  level: number;
  active?: boolean;
};

const ListItem = styled.div<ListItemProps>`
  cursor: pointer;
  position: relative;
  padding: 0.75rem 1.25rem;
  border: none;
  padding-left: ${(props) => `calc(${props.level + 1} * 1.25rem)`};
  width: 100%;
  text-align: inherit;
  text-decoration: none;

  ${(props) => (props.active ? listItemActiveCss : listItemInactiveCss)};

  ${ListItemHeader} {
    display: flex;
    word-break: break-all;
    align-items: center;
    user-select: none;
  }

  ${ListItemActions} {
    display: inline-block;
    white-space: nowrap;
    margin-left: auto;
    padding-left: 15px;

    .btn,
    .dropdown {
      color: ${(props) => props.theme.primary};
    }
  }
`;

export interface GroupListItemProps {
  group?: ProjectGroup;
  onSelect?: (groupName: string) => void;
  level?: number;
  activeGroupName?: string | null;
}

const GroupListItem = ({
  group,
  onSelect,
  level,
  activeGroupName,
}: GroupListItemProps) => {
  const dispatch = useCoreDispatch();
  const {
    createProjectGroup,
    addProjectMember,
    importProjectMembers,
    editProjectGroup,
  } = useProject();
  const { removeProjectMembers } = useProjectCompetence();
  const { getSubGroups, matchingMembers } = useContext(UserManagementContext);

  const [collapsed] = useState(false);

  const matchingMembersCount = useMemo(() => {
    if (group?.name == null) {
      return 0;
    }

    return matchingMembers[group.name]?.length;
  }, [matchingMembers, group]);

  const subGroups = useMemo(() => {
    return group?.name != null ? getSubGroups(group.name) : [];
  }, [getSubGroups, group]);

  const active = useMemo(
    () => activeGroupName === group?.name,
    [activeGroupName, group?.name]
  );

  if (group == null) {
    return null;
  }

  return (
    <>
      <ListItem
        onClick={() => {
          // setCollapsed((c) => !c);
          if (onSelect != null && group != null) {
            onSelect(group.name);
          }
        }}
        level={level || 0}
        active={active}
      >
        <ListItemHeader>
          <span
            className={classNames({
              "font-weight-bold": level === 0,
            })}
          >
            {group.displayName || group.name}
          </span>

          {matchingMembersCount > 0 && (
            <Badge className="ml-2" variant="info">
              {matchingMembersCount}
            </Badge>
          )}

          {active && (
            <ListItemActions>
              <Button
                variant="link"
                className="p-0"
                title="Add Member to Group"
                onClick={() => {
                  if (group != null) {
                    addProjectMember(group.name, group.domainName);
                  }
                }}
              >
                <FontAwesomeIcon icon={faUserPlus} />
              </Button>
              <Button
                variant="link"
                className="p-0 ml-2"
                title="Import Members into Group (xls)"
                onClick={() => {
                  if (group != null) {
                    importProjectMembers(group.name, group.domainName);
                  }
                }}
              >
                <FontAwesomeIcon icon={faFileImport} />
              </Button>
              <Dropdown className="d-inline-block ml-2" alignRight>
                <Dropdown.Toggle variant="link" className="p-0">
                  <FontAwesomeIcon icon={faEllipsis} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      createProjectGroup(group.name, group.domainName)
                    }
                  >
                    Create Sub-Group
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      dispatch(exportGroupMembersExcel());
                    }}
                  >
                    Export Members (xls)
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={group.protected}
                    onClick={() =>
                      editProjectGroup(group.name, group.domainName)
                    }
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className={classNames({
                      "text-danger": !group.protected,
                    })}
                    onClick={() => {
                      removeProjectMembers([group]);
                    }}
                    disabled={group.protected}
                  >
                    Delete Group
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ListItemActions>
          )}
        </ListItemHeader>
        <small className="text-muted">{group.description}</small>
      </ListItem>
      <SlideDown collapsed={collapsed}>
        {subGroups.map((subGroup: ProjectGroup) => (
          <GroupListItem
            key={subGroup.name}
            group={subGroup}
            onSelect={onSelect}
            level={(level || 0) + 1}
            activeGroupName={activeGroupName}
          />
        ))}
      </SlideDown>
    </>
  );
};

GroupListItem.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
    protected: PropTypes.bool,
  }).isRequired,
  onSelect: PropTypes.func,
  level: PropTypes.number,
};

GroupListItem.defaultProps = {
  onSelect: () => {},
  level: 0,
};

export default GroupListItem;
