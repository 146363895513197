import SignOff_Sub_OneUser from "./SignOff_Sub_OneUser";
import SignOff_Main from "./SignOff_Main";
import SignOff_AddViewers from "./SignOff_AddViewers";
import SignOff_StatusTable from "./SignOff_StatusTable";
import SignOff_SendEndNotificationMail from "./SignOff_SendEndNotificationMail";
import SignOff_ReassignSubworkflow from "./SignOff_ReassignSubworkflow";
import { Group } from "cosmos-config/lib/group/group";

export interface WorkflowPropertiesMap {
  [key: string]: Group[];
}

const workflowProperties: WorkflowPropertiesMap = {
  SignOff_Sub_OneUser,
  SignOff_Main,
  SignOff_AddViewers,
  SignOff_StatusTable,
  SignOff_SendEndNotificationMail,
  SignOff_ReassignSubworkflow,
};

export default workflowProperties;
