import docxIcon from "../assets/icons/docx_32.svg";
import xlsxIcon from "../assets/icons/xlsx_32.svg";
import pptxIcon from "../assets/icons/pptx_32.svg";
import officeIcon from "../assets/icons/Office-Color-M-48.svg";
import { ImgHTMLAttributes } from "react";

const toDataUrl = (svgIcon: string) => {
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgIcon)}`;
};

const wordFile = {
  src: toDataUrl(docxIcon),
  alt: "MS Office Word Document",
};

const powerpointFile = {
  src: toDataUrl(pptxIcon),
  alt: "MS Office Power Point Document",
};

const excelFile = {
  src: toDataUrl(xlsxIcon),
  alt: "MS Office Excel Document",
};

const officeDefault = {
  src: toDataUrl(officeIcon),
  alt: "MS Office Document",
};

const extensionMapping: Record<string, ImgHTMLAttributes<HTMLImageElement>> = {
  doc: wordFile,
  docx: wordFile,

  ppt: powerpointFile,
  pptx: powerpointFile,
  pptm: powerpointFile,

  xls: excelFile,
  xlsx: excelFile,
  xlsm: excelFile,

  csv: officeDefault,
  txt: officeDefault,
};

const extensionIconMappingOffice = (extension: string) => {
  const iconProps = extensionMapping[extension];

  return iconProps != null ? iconProps : officeDefault;
};

export default extensionIconMappingOffice;
