import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { CloseButton, Modal, Nav } from "react-bootstrap";
import useProject from "../../hooks/useProject";
import ProjectSwitcher from "./ProjectSwitcher";
import { useTranslation } from "react-i18next";

export interface ProjectSelectionProps {
  onChange?: (projectCode: string) => void;
}

const ProjectSelection = ({ onChange }: ProjectSelectionProps) => {
  const [showFolderSwitcher, setShowFolderSwitcher] = useState(false);

  const { t } = useTranslation("module");

  const { projectName, projects } = useProject();

  const availableProjects = useMemo(
    () => projects.filter((p) => p.available),
    [projects]
  );

  if (availableProjects.length <= 1) {
    return <span className="text-primary px-2">{projectName}</span>;
  }

  return (
    <>
      <Nav.Link
        as="button"
        bsPrefix="btn"
        className="btn-link"
        eventKey="1"
        disabled={availableProjects.length <= 1}
        onClick={() => setShowFolderSwitcher(true)}
      >
        <span className="mr-2">{projectName}</span>
        <FontAwesomeIcon icon={faCaretDown} />
      </Nav.Link>

      <Modal
        show={showFolderSwitcher}
        onHide={() => setShowFolderSwitcher(false)}
      >
        <Modal.Header>
          <Modal.Title>
            {t("header.switch_module_title", { defaultValue: "Switch Module" })}
          </Modal.Title>
          <CloseButton
            className="close-icon"
            onClick={() => setShowFolderSwitcher(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <ProjectSwitcher
            onSelect={(projectCode) => {
              setShowFolderSwitcher(false);
              if (onChange != null) {
                onChange(projectCode);
              }
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectSelection;
