import { createContext } from "react";
import { UserPermissionsType } from "../providers/UserDataProvider";

export declare type UserInfo = {
  firstName: string;
  commonName: string;
  domainName: string;
  lastName: string;
  name: string;
  office: string | null;
  organizations: string[];
  roles: string[];
  titles: string[];
  userInfoId: string;
  department: string;
  departmentTeam: string;
  defaultPositionId: string;
  principalId: string;
  emails: string[];
};

export declare type ResourcePermission = {
  read: boolean;
  update: boolean;
  delete: boolean;
  share: boolean;
  create: boolean;
  admin: boolean;
  member: boolean;
};

export interface UserDataContextValue {
  authenticated: boolean;
  loginLoading: boolean;
  resourcePermissions: Record<string, ResourcePermission>;
  userData: UserInfo | null;
  principalId: string | null;
  defaultPositionId: string | null;
  permissions: UserPermissionsType;
  groupNames: string[];
  systemAdmin: boolean;
}

const UserDataContext = createContext<UserDataContextValue>({
  authenticated: false,
  loginLoading: false,
  resourcePermissions: {},
  principalId: null,
  defaultPositionId: null,
  userData: null,
  permissions: {
    metadataPermissions: {},
    documentPermissions: {},
    systemPermissions: {},
    linkPermissions: {},
    workflowPermissions: {},
    folderPermissions: {},
    documentAreaPermissions: {},
    domainPermissions: {},
  },
  groupNames: [],
  systemAdmin: false,
});

export default UserDataContext;
