import { Group } from "cosmos-config/lib/group/group";
import { Property } from "cosmos-config/lib/property/property";
import { createContext } from "react";

export interface ProjectEditorPropertiesContextValue {
  resourceType: 1 | 2;
  folder: any;
  loading: boolean;
  translationPathBase?: string;
  canManipulate: () => boolean;
  deleteProperty: (propertyName: string) => void;
  createGroup: (group: Group) => void;
  createProperty: (
    groupName: string,
    property: Partial<Property>,
    createNew?: boolean
  ) => void;
  updateProperty: (propertyName: string, property: Partial<Property>) => void;
  moveProperty: (propertyName: string, after: string) => void;
  updateFolder: (folderType: string, folder: any) => void;
  groupMoveProperty: (
    propertyName: string,
    targetGroup: string,
    after: string
  ) => void;
  propertyDefinitions: Record<string, any>;
}

const ProjectEditorPropertiesContext =
  createContext<ProjectEditorPropertiesContextValue>({
    resourceType: 2,
    folder: {},
    loading: false,
    canManipulate: () => false,
    deleteProperty: () => {
      throw new Error("Function not supported!");
    },
    createGroup: () => {
      throw new Error("Function not supported!");
    },
    createProperty: () => {
      throw new Error("Function not supported!");
    },
    updateProperty: () => {
      throw new Error("Function not supported!");
    },
    moveProperty: () => {
      throw new Error("Function not supported!");
    },
    updateFolder: () => {
      throw new Error("Function not supported!");
    },
    groupMoveProperty: () => {
      throw new Error("Function not supported!");
    },
    propertyDefinitions: {},
  });

export default ProjectEditorPropertiesContext;
