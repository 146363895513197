import React, { useMemo, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { trimUINumber } from "cosmos-config/utils";
import CommentArea from "./CommentArea";
import { useContext } from "react";
import styled from "styled-components";
import _ from "lodash";
import classNames from "classnames";
import { CommentsListContext } from "./CommentsList";
import { timestampToDate } from "../../../utils";
import {
  Comment,
  commentStatus,
  commentStatusType,
} from "../../../apis/commentApi";
import { lighten } from "polished";
import { shadeColor } from "cosmos-components";

type CommentStatusOptionType = {
  value: commentStatusType;
  label: string;
};

const commentStatusOptions: CommentStatusOptionType[] = [
  {
    value: commentStatus.OPEN,
    label: "Open",
  },
  {
    value: commentStatus.CLOSED,
    label: "Closed",
  },
  {
    value: commentStatus.INFORMATION,
    label: "For Information",
  },
  {
    value: commentStatus.DELETED,
    label: "Deleted",
  },
];

interface StyledCommentProps {
  root: boolean;
}

const StyledComment = styled.div<StyledCommentProps>`
  padding: ${(props) => (props.root ? "0px" : "15px")};
  padding-bottom: 0;

  & ~ & {
    border-top: 1px solid ${(props) => shadeColor(0.15, props.theme.background)};
    padding-top: 15px;
  }

  img {
    max-width: 100%;
    -webkit-user-drag: none;
  }

  & div > & {
    margin: 0 -15px;
    padding: 15px 30px;

    &:nth-child(2n) {
      background-color: ${(props) => lighten(0.68, props.theme.base)};
    }

    .comment-area {
      margin-top: 15px;
    }

    & {
      border-bottom: 1px solid #fbfbfb;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
`;

export interface CommentEntryProps {
  comment?: Comment;
  children?: React.ReactNode;
  allowReply?: boolean;
  className?: string;
  allowStatus?: boolean;
  root?: boolean;
}

const CommentEntry = ({
  comment,
  children,
  allowReply,
  className,
  allowStatus,
  root,
}: CommentEntryProps) => {
  const [showCommentArea, setShowCommentArea] = useState(false);

  const { focusComment, postComment, updateComment, disabled } =
    useContext(CommentsListContext);

  const commentStatusMap = useMemo(
    () => _(commentStatusOptions).keyBy("value").mapValues("label").value(),
    []
  );

  return (
    <StyledComment className={className} root={!!root}>
      <div className="text-dark d-flex align-items-center">
        <b className="mr-2">{trimUINumber(comment?.author || "")}</b>

        <div className="ml-auto d-flex align-items-center">
          <span className="border-left border-right px-3 mr-3">
            {allowStatus && (
              <span className="mr-2 ">
                <b>Nr. {comment?.number}</b>
              </span>
            )}

            {comment?.position != null && (
              <span>at Page {comment.position.pageNumber}</span>
            )}
          </span>

          {comment?.position != null && (
            <Button
              variant="link"
              className="p-0 mr-3"
              onClick={() => {
                if (comment?.id != null) {
                  focusComment(comment.id);
                }
              }}
            >
              Show
            </Button>
          )}

          {allowStatus && (
            <Dropdown>
              <Dropdown.Toggle
                disabled={disabled}
                variant="link"
                className={classNames("p-0", {
                  "text-danger":
                    comment?.status === commentStatus.OPEN ||
                    comment?.status == null,
                  "text-muted":
                    comment?.status === commentStatus.ARCHIVED ||
                    comment?.status === commentStatus.INFORMATION,
                  "text-success": comment?.status === commentStatus.CLOSED,
                })}
              >
                {commentStatusMap[comment?.status || commentStatus.OPEN]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Header>Comment Status</Dropdown.Header>
                {commentStatusOptions.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    onClick={() => {
                      if (comment != null && comment.id != null) {
                        updateComment(comment.id, {
                          ...comment,
                          status: option.value,
                        });
                      }
                    }}
                  >
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>

      <Row>
        <Col>
          <div dangerouslySetInnerHTML={{ __html: comment?.message || "" }} />

          <div className="align-items-center">
            <small className="text-muted">
              {comment != null ? timestampToDate(comment.createdAt) : null}
            </small>
          </div>

          {allowReply && (
            <Button
              variant="link"
              className="pl-0"
              onClick={() => setShowCommentArea(true)}
            >
              <FontAwesomeIcon icon={faReply} className="mr-2" />
              Reply
            </Button>
          )}

          {showCommentArea && (
            <CommentArea
              className="mb-3"
              onSubmit={(message, mentions) => {
                if (comment != null && comment.resourceId != null) {
                  const options = {
                    parameter: comment.parameter,
                    nodeId: comment.id,
                    mentions,
                  };

                  postComment(comment.resourceId, message, options).then(() => {
                    setShowCommentArea(false);
                  });
                }
              }}
              onCancel={() => setShowCommentArea(false)}
              replyTo={comment?.principalId}
            />
          )}

          {children}
        </Col>
      </Row>
    </StyledComment>
  );
};

CommentEntry.defaultProps = {
  allowReply: true,
};

export default CommentEntry;
