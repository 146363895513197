import ColumnsContext, {
  PreparePropertiesOptions,
} from "../contexts/ColumnsContext";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import useProperties from "../hooks/useProperties";
import projectService from "../services/projectService";
import { Property } from "cosmos-config/lib/property/property";
import { SelectableAttributeProperty } from "cosmos-config/lib/property/property";
import { Column } from "react-table";
import Resource from "../types/resource";

export type TableColumn<D extends object> = {} & Pick<
  SelectableAttributeProperty,
  | "Header"
  // | "accessor"
  | "sortType"
  | "information"
  | "sortable"
  | "cellRender"
  | "render"
  | "type"
  | "valuesetName"
  | "valuesetSource"
  | "format"
  | "name"
  | "description"
  | "columnWidth"
  | "masterValuesetName"
  | "options"
  | "translation"
  | "defaultSort"
> &
  Column<D>;

export interface TableColumnOrder<D extends object> {
  id: string;
  width: number;
  order?: number;
}

const migratableProps = [
  "Header",
  "accessor",
  "sortType",
  "information",
  "sortable",
  "cellRender",
  "render",
  "type",
  "valuesetName",
  "valuesetSource",
  "format",
  "name",
  "description",
  "columnWidth",
  "masterValuesetName",
  "options",
  "translation",
  "defaultSort",
];

const prepareProperties = <D extends object>(
  pm: Property[],
  options = {} as PreparePropertiesOptions
): TableColumn<D>[] => {
  const alternate = options.alternate || false;
  const filter = options.filter != null ? options.filter : true;

  if (!Array.isArray(pm)) {
    return [];
  }

  return pm
    .filter((c) => (!c.system && c.tableColumn) || !filter)
    .filter(
      (c, idx, arr) => arr.findIndex((b) => b.accessor === c.accessor) === idx
    )
    .map((property) => {
      return _(property)
        .pick(migratableProps)
        .mapValues((value, key) => {
          if (key === "sortable") {
            return !value;
          }

          const { alternateName, alternateLabel } = property;
          if (
            (key === "name" || key === "accessor") &&
            alternate &&
            alternateName != null
          ) {
            return alternateName;
          }

          if (key === "Header" && alternate && alternateLabel != null) {
            return alternateLabel;
          }

          return value;
        })
        .mapKeys((v, key) => {
          if (key === "sortable") {
            return "disableSortBy";
          }

          return key;
        })
        .value() as TableColumn<D>;
    });
};

interface ColumnsProviderProps {
  children: React.ReactNode;
}

const ColumnsProvider = ({ children }: ColumnsProviderProps) => {
  const { properties, folderProperties, extendedTaskHistoryProperties } =
    useProperties();

  const tableColumns = useMemo(() => {
    const folderColumnsMap = _.keyBy(
      prepareProperties<Resource>(folderProperties),
      "name"
    );
    const documentColumns = prepareProperties<Resource>(properties).filter(
      (dprop) => dprop.information || folderColumnsMap[dprop.name] == null
    );

    return _(folderColumnsMap)
      .valuesIn()
      .concat(documentColumns)
      .uniqBy("accessor")
      .value();
  }, [properties, folderProperties]);

  useEffect(() => {
    projectService.setTableColumns(tableColumns);
  }, [tableColumns]);

  const documentTableColumns = useMemo(() => {
    return prepareProperties<Resource>(properties);
  }, [properties]);

  const documentTableColumnOrder: TableColumnOrder<Resource>[] = useMemo(() => {
    return documentTableColumns
      .filter((c) => c.information)
      .map((column) => ({
        id: String(column.accessor),
        width: column.columnWidth,
      }));
  }, [documentTableColumns]);

  const defaultColumnOrder: TableColumnOrder<Resource>[] = useMemo(() => {
    return tableColumns
      .filter((c) => c.information)
      .map((c, idx) => ({
        id: String(c.accessor),
        width: c.columnWidth,
        order: idx,
      }));
  }, [tableColumns]);

  return (
    <ColumnsContext.Provider
      value={{
        tableColumns,
        documentTableColumns,
        documentTableColumnOrder,
        prepareCustomProperties: useCallback((customProperties, options) => {
          return prepareProperties(customProperties, options);
        }, []),
        taskHistoryColumns: useMemo(
          () => prepareProperties(extendedTaskHistoryProperties),
          [extendedTaskHistoryProperties]
        ),
        defaultColumnOrder,
      }}
    >
      {children}
    </ColumnsContext.Provider>
  );
};

export default ColumnsProvider;
