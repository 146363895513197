import { useCallback, useMemo } from "react";
import useProject from "../../../hooks/useProject";
import { useQuery } from "@tanstack/react-query";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useProperties from "../../../hooks/useProperties";
import _ from "lodash";

import projectApi from "../../../apis/projectApi";
import { BusinessProcessTask } from "../constants/businessProcessTask";
import { resourceCorrectionOfGateway } from "cosmos-config/utils";

const useUserTasks = () => {
  const { principalId, authenticated } = useCurrentUser();
  const { project, businessProcesses } = useProject();
  const { properties, getBusinessProcessProperties, getFolderProperties } =
    useProperties();

  const projectCode = useMemo(() => project?.code || "", [project]);

  const getBusinessProcessResourceProperties = useCallback(
    (processDefinitionKey: string, folderType?: string | null) => {
      const businessProcess = businessProcesses.find(
        (bp) => bp.processDefinitionKey === processDefinitionKey
      );

      const resourcePropertiesNames = _.compact(
        businessProcess?.resourceProperties
      );

      if (resourcePropertiesNames.length > 0) {
        return properties.filter((p) =>
          resourcePropertiesNames.includes(p.name)
        );
      }

      if (folderType != null) {
        return getFolderProperties(folderType);
      }

      return [];
    },
    [businessProcesses, getFolderProperties, properties]
  );

  const { data, isLoading } = useQuery<BusinessProcessTask[]>({
    queryKey: ["user-tasks", principalId, projectCode],
    queryFn: () => projectApi.getUserTasks(projectCode),
    enabled: principalId != null && project != null && authenticated,
  });

  const tasks = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.map((task) => {
      const { processDefinitionKey, taskDefinitionKey, variables } = task;

      const bpProperties = getBusinessProcessProperties(
        processDefinitionKey,
        taskDefinitionKey
      );

      const bpResourceProperties = getBusinessProcessResourceProperties(
        processDefinitionKey,
        variables?.folderType
      );

      const resourceVariables = variables?.resource || {};
      const correctedResource = resourceCorrectionOfGateway(
        resourceVariables,
        bpResourceProperties
      );

      return {
        ...task,
        variables: {
          ...resourceCorrectionOfGateway(task.variables || {}, bpProperties),
          resource: correctedResource,
        },
      };
    });
  }, [
    data,
    getBusinessProcessProperties,
    getBusinessProcessResourceProperties,
  ]);

  return {
    tasks,
    loading: isLoading,
  };
};

export default useUserTasks;
