import { useTranslation } from "react-i18next";
import { Button, CloseButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { HelperTooltip } from "cosmos-components";
import classNames from "classnames";
import _ from "lodash";
import React, { createContext, useContext, useMemo } from "react";
import styled from "styled-components";
import DocumentIcon from "../DocumentIcon";
import {
  getFileIdentifier,
  getFileSize,
  resolveContentType,
} from "../../utils/fileUtils";
import Resource from "../../types/resource";

export interface ExtendedFile extends File {
  path?: string;
  order?: number;
}

type FileListRowContextValue = {
  file: File | null;
  editable: boolean;
};

const FileListRowContext = createContext<FileListRowContextValue>({
  file: null,
  editable: false,
});

const VADiv = styled.div`
  vertical-align: middle;
  display: inline-block;
  white-space: normal !important;
  width: 100%;
`;

export interface FileListRowProps {
  children?: React.ReactNode;
  className?: string;
}

const FileListRow = ({ children, className }: FileListRowProps) => {
  const { file, editable } = useContext(FileListRowContext);

  const document = useMemo(() => {
    const fileextension = _(file?.name).split(".").last();

    if (file == null || fileextension == null) {
      return null;
    }

    return {
      fileextension: fileextension?.toLowerCase(),
      contenttype: resolveContentType(fileextension),
    };
  }, [file]);

  if (file == null) {
    return <span>error</span>;
  }

  const { prettySize } = getFileSize(file.size);

  return (
    <td
      className={classNames(
        "flex-grow-1 text-nowrap overflow-hidden",
        className
      )}
    >
      {document != null && (
        <DocumentIcon
          document={document as Resource}
          className="mr-2 align-middle"
        />
      )}
      <VADiv>
        {file.name}
        <span className="ml-2">{editable && `(${prettySize})`}</span>
        {children}
      </VADiv>
    </td>
  );
};

export interface FilesListProps {
  files?: ExtendedFile[];
  editable?: boolean;
  onRemove?: (
    fileIdentifier: string,
    fileName: string,
    filePath: string
  ) => void;
  enableReorder?: boolean;
  onReorder?: (fileIdentifier: string, amount?: number) => void;
  className?: string;
  renderRow?: (fileIdentifier: string, fileName: string) => React.ReactNode;
  columns?: any[];
}

const FilesList = ({
  files,
  editable,
  onRemove,
  enableReorder,
  onReorder,
  className,
  renderRow,
  columns,
}: FilesListProps) => {
  const { t } = useTranslation("module");

  const filesCount = files?.length || 0;

  const renderFileItem = (file: ExtendedFile) => {
    return (
      <tr key={file.name + file.path}>
        {editable && enableReorder && (
          <td className="d-flex">
            {file.order !== 0 && (
              <Button
                variant="link"
                size="sm"
                className="py-0 mr-auto"
                onClick={() => {
                  if (onReorder != null && file.order != null) {
                    onReorder(getFileIdentifier(file), -1);
                  }
                }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Button>
            )}

            {file.order !== filesCount - 1 && (
              <Button
                variant="link"
                size="sm"
                className="py-0 ml-auto"
                onClick={() => {
                  if (onReorder != null && file.order != null) {
                    onReorder(getFileIdentifier(file), 1);
                  }
                }}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </Button>
            )}
          </td>
        )}

        <FileListRowContext.Provider
          value={{
            file,
            editable: !!editable,
          }}
        >
          {renderRow != null && renderRow(getFileIdentifier(file), file.name)}

          {columns != null &&
            columns.map((c) => {
              //@ts-ignore
              const value = file[c.name];

              if (typeof c.Cell === "function") {
                return <td>{c.Cell({ original: file, value })}</td>;
              }

              return <td>{value}</td>;
            })}

          {editable && (
            <td>
              <CloseButton
                onClick={() => {
                  if (onRemove != null) {
                    onRemove(
                      getFileIdentifier(file),
                      file.name,
                      file.path ?? file.webkitRelativePath
                    );
                  }
                }}
              />
            </td>
          )}
        </FileListRowContext.Provider>
      </tr>
    );
  };

  return (
    <table className={classNames("table", className)}>
      <thead>
        <tr>
          {editable && enableReorder && <th>Order</th>}
          <th>{t("repository.file_name", { defaultValue: "File Name" })}</th>

          {columns != null &&
            columns.map((column) => (
              <th key={column.name}>
                <span>{column.Header}</span>
                {column.description != null && (
                  <HelperTooltip
                    text={column.description}
                    title={column.Header}
                  />
                )}
              </th>
            ))}

          <th></th>
        </tr>
      </thead>
      <tbody>{files?.map((file) => renderFileItem(file))}</tbody>
    </table>
  );
};

FilesList.defaultProps = {
  renderRow: () => <FileListRow />,
};

FilesList.Row = FileListRow;

export default FilesList;
