import { property, systemProperty } from "cosmos-config/generator";

const createPropert = [
  property("Label", "label"),
  // property("Name", "name"),
  systemProperty("Type", "type", true),
  systemProperty("Valueset Source", "valuesetSource", true),
  property("Disabled (read only)").booleanAttribute("disabled").useYesNo(),
  property("Table Column")
    .booleanAttribute("tableColumn")
    .useYesNo()
    .default(true),
  property("Multi Attribute").booleanAttribute("handleAsMulti").useYesNo(),
  // property("Protected").booleanAttribute("protectedProperty").useYesNo(),
];

export default createPropert;
