import { useState } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import SimpleUniversalForm from "../../../components/SimpleUniversalForm";
import { resourceCorrectionForGateway } from "cosmos-config/utils";
import createFolderProperties from "../constants/createFolderProperties";

const CreateFolderModal = ({ show, onClose, onSubmit }) => {
  const [folder, setFolder] = useState({});

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Create Folder Type</Modal.Title>
        <CloseButton className="close-icon" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <SimpleUniversalForm
          properties={createFolderProperties}
          resource={folder}
          onUpdateResource={(id, propertyName, value) => {
            setFolder((g) => ({
              ...g,
              [propertyName]: value,
            }));
          }}
          onSubmit={() => {
            onSubmit(
              resourceCorrectionForGateway(folder, createFolderProperties)
            );
          }}
        >
          {({ submit }) => (
            <div className="text-right">
              <Button onClick={submit}>Submit</Button>
            </div>
          )}
        </SimpleUniversalForm>
      </Modal.Body>
    </Modal>
  );
};

CreateFolderModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CreateFolderModal.defaultProps = {
  show: false,
  onClose: () => {},
  onSubmit: () => {},
};

export default CreateFolderModal;
