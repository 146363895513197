import { useCallback, useContext } from "react";
import PropertiesContext from "../contexts/PropertiesContext";
import { buildGroups, buildProperties } from "../utils";
import useConfigProvider from "./useConfigProvider";
import usePropertyRender from "./usePropertyRender";
import { Group } from "cosmos-config/lib/group/group";
import _ from "lodash";
import systemProperties from "../contants/systemProperties";

/**
 * @module hooks/useProperties
 * @category Hooks
 */

/**
 * @callback getFolderPropertiesCallback
 * @param {string} folderType - Type of a folder type of which properties to retrieve.
 * @returns {Property[]} List of properties per folder type.
 */

/**
 * @callback getFolderGroupsCallback
 * @param {string } folderType - Type of a folder type of which properties groups to retrieve.
 * @returns {Group[]} List of properties groups per folder type.
 */

/**
 * @typedef {object} UsePropertiesHook
 * @property {Property[]} properties - All project properties.
 * @property {Record<string, Property>} propertiesMap - Project properties as a map.
 * @property {Property[]} filterFolders - Get all properties marked as virtualized folders.
 * @property {Structure[]} structures - Get all structures created of project properties.
 * @property {Widget[]} widgets - Get all widgets created by properties.
 * @property {Group[]} groups - Project properties grouped so that it could be used in the forms.
 * @property {Group[]} folderGroups - Project folder properties grouped so that it could be used in the forms.
 * @property {Folder[]} folders - Project folder types declarations.
 * @property {Property[]} folderProperties - List of all folder properties.
 * @property {Property[]} extendedTaskHistoryProperties - Task history properties.
 * @property {getFolderGroupsCallback} getFolderGroups - Retrieve folder properties groups by folder type.
 * @property {getFolderPropertiesCallback} getFolderProperties - Retrieve folder properties by folder type.
 */

/**
 * Hook to access project properties context.
 *
 * @returns {UsePropertiesHook}
 */
const useProperties = () => {
  const propertiesContext = useContext(PropertiesContext);

  const { loadProjectProperties } = useConfigProvider();
  const getProjectGroups = useCallback(
    (projectCode: string): Promise<Group[]> => {
      return loadProjectProperties(projectCode).then(({ properties }) => {
        if (properties?.length > 0) {
          return Promise.resolve(buildGroups(properties));
        }

        return Promise.resolve([]);
      });
    },
    [loadProjectProperties]
  );

  const getProjectProperties = useCallback(
    (projectCode: string) => {
      return loadProjectProperties(projectCode).then(({ properties }) => {
        if (properties?.length > 0) {
          const result = _(buildProperties(properties))
            .concat(systemProperties)
            .uniqBy("name")
            .value();
          return Promise.resolve(result);
        }

        return Promise.resolve([]);
      });
    },
    [loadProjectProperties]
  );

  const render = usePropertyRender(propertiesContext.properties);

  return {
    ...propertiesContext,
    getProjectGroups,
    getProjectProperties,
    render,
  };
};

export default useProperties;
