import React, { useContext, useMemo } from "react";
import { LoadingOverlay } from "cosmos-components";
import _ from "lodash";
import DashboardContext from "../../contexts/DashboardContext";
import { ChartDataEntry } from "../../types/CosmosChart";
import useResourceComments from "../../../../hooks/useResourceComments";

export type ChildrenParam = {
  data: ChartDataEntry[];
};

export interface CommentsAggregationDataProps {
  children?: (param: ChildrenParam) => React.ReactNode;
}

const CommentsAggregationData = ({
  children,
}: CommentsAggregationDataProps) => {
  const { resourceId } = useContext(DashboardContext);

  const { comments, loading } = useResourceComments({
    resourceId: resourceId || "",
  });

  const data = useMemo(() => {
    return _(comments)
      .groupBy("status")
      .mapValues((comments) => comments.length)
      .map(
        (commentsCount, status) =>
          ({
            id: status,
            value: commentsCount,
            label: status,
          } as ChartDataEntry)
      )
      .value();
  }, [comments]);

  if (children == null) {
    return <></>;
  }

  return (
    <LoadingOverlay loading={loading}>{children({ data })}</LoadingOverlay>
  );
};

export default CommentsAggregationData;
