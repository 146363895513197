import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import {
  setFilterFolder,
  setFilterFolderProperties,
} from '../Actions/filterFolder';
import {
  FETCH_FILTER_FOLDER,
  SAVE_FILTER_FOLDER,
} from '../Actions/types/filterFolder';
import masterdata from '../Api/masterdata';
import { notify } from '../Actions/ui';

const FILTER_FOLDER_TABLE = 'FILTER_FOLDER_DATA';

function* saveFilterFolder({ id, filterName, filterValue, properties }) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  const folderId = yield select((state) => state.repository.folderId);

  if (filterName == null || filterValue == null) {
    return null;
    // return Promise.reject('Filter name and filter value must not be empty !');
  }

  const entry = {
    folderId,
    filterName,
    filterValue,
    filterData: btoa(JSON.stringify(properties)),
  };

  if (id != null) {
    yield call(
      masterdata.updateMasterDataEntry,
      docareaName,
      FILTER_FOLDER_TABLE,
      id,
      entry
    );
    yield put(notify('Entity data have been saved.', 'success'));
    return id;
  }

  const result = yield call(
    masterdata.postMasterDataEntry,
    docareaName,
    FILTER_FOLDER_TABLE,
    entry
  );

  yield put(notify('Entity data have been saved.', 'success'));
  return result.id;
}

function* fetchFilterFolder({ filterName, filterValue }) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  const folderId = yield select((state) => state.repository.folderId);

  const [result] = yield call(
    masterdata.getMasterData,
    docareaName,
    FILTER_FOLDER_TABLE,
    ['*'],
    {
      where: { filterName, filterValue, folderId },
    }
  );

  if (result == null) {
    yield put(setFilterFolder({ [filterName]: filterValue }));
    yield put(setFilterFolderProperties(null, []));

    return Promise.reject(
      new Error(
        `There is not filter ${filterName} folder ${filterValue} saved yet.`
      )
    );
  }

  const filterFolder = result.properties.reduce((acc, cur) => {
    if (cur.name === 'filterData') {
      acc[cur.name] = JSON.parse(atob(cur.value));
    } else {
      acc[cur.name] = cur.value;
    }

    return acc;
  }, {});

  yield put(
    setFilterFolderProperties(filterFolder.id, filterFolder.filterData)
  );
  yield put(setFilterFolder({ [filterName]: filterValue }));
  return filterFolder;
}

export default function* filterFolderSaga() {
  yield takeLatest(FETCH_FILTER_FOLDER, fetchFilterFolder);
  yield takeLatest(SAVE_FILTER_FOLDER, saveFilterFolder);
}
