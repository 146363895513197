import { property, valuesetSource } from "cosmos-config/generator";

const projectPortalRuleProperties = [
  // property("Operand", "operand"),
  property("Operant Type")
    .selectableAttribute(
      "operandType",
      "portal_rule_operand_type",
      valuesetSource.STATIC_VALUESET
    )
    .valueset([
      { value: "MULTIVALUE", label: "Multi Value" },
      { value: "VALUE", label: "Value" },
      { value: "PROPERTY", label: "Property" },
    ]),

  property("Operand", "operand").selectableModuleElementAttribute(
    "operand",
    "property"
  ),

  property("Operator")
    .selectableAttribute(
      "operator",
      "portal_rule_operator",
      valuesetSource.STATIC_VALUESET
    )
    .valueset([{ value: 1, label: "=" }]),

  property("Target Property", "targetProperty"),
];

export default projectPortalRuleProperties;
