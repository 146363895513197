import React, { useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { shadeColor } from "../../utils";
import DropzoneContext from "./DropzoneContext";
import _ from "lodash";

type OverlayContainerProps = {
  active?: boolean;
};

const DropzoneOverlay = styled.div``;

const OverlayContainer = styled.div<OverlayContainerProps>`
  cursor: inherit;
  height: 100%;

  & > ${DropzoneOverlay} {
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    background-color: ${(props) => transparentize(0.3, props.theme.background)};
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14 * 2.5;
    color: ${(props) => props.theme.base};

    pointer-events: none;
  }

  ${(props) =>
    !props.active &&
    css`
      & > ${DropzoneOverlay} {
        display: none;
        /* pointer-events: none; */
      }
    `}
`;

const DropzoneArea = styled.div``;

const DropzoneAreaWrapper = styled.div`
  background-color: ${(props) => props.theme.background};
  border-radius: 10px;

  ${DropzoneArea} {
    cursor: pointer;

    transition: border 0.24s ease-in-out;
    border-radius: 2px;

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    border: 2px dashed ${(props) => shadeColor(0.1, props.theme.background)};

    background-color: ${(props) => shadeColor(0.03, props.theme.background)};
    color: ${(props) => shadeColor(0.35, props.theme.background)};
    outline: none;

    span {
      text-align: center;
      user-select: none;
    }
  }
`;

export interface PancoDropzoneProps {
  onDrop?: (files: File[], metadata?: Record<string, any>) => void;
  children?: React.ReactNode;
  text?: string;
  overlayDrop?: boolean;
  className?: string;
}

const PancoDropzone = ({
  onDrop,
  children,
  overlayDrop,
  text,
  className,
}: PancoDropzoneProps) => {
  const [activeZone, setActiveZone] = useState<string | null>(null);
  const [subzoneMeta, setSubzoneMeta] = useState<
    Record<string, Record<string, any> | undefined>
  >({});
  const [activeMeta, setActiveMeta] = useState<
    Record<string, any> | undefined
  >();

  const handleDrop = (files: File[]) => {
    setActiveZone(null);

    if (onDrop != null) {
      onDrop(files, activeMeta);
    }
  };

  const registerSubzone = useCallback(
    (zoneId: string, metadata?: Record<string, any>) => {
      setSubzoneMeta((szm) => ({
        ...szm,
        [zoneId]: metadata,
      }));
    },
    []
  );

  const unregisterSubzone = useCallback((zoneId: string) => {
    setSubzoneMeta((szm) => _.omit(szm, zoneId));
  }, []);

  if (overlayDrop) {
    return (
      <DropzoneContext.Provider
        value={{
          contextPresent: true,
          activeZone,
          registerSubzone,
          unregisterSubzone,
        }}
      >
        <Dropzone
          onDragEnter={(e) => {
            const { target } = e;

            if (target != null) {
              const subZoneId = (target as HTMLElement).getAttribute(
                "data-sub-dropzone"
              );

              setActiveZone(subZoneId || "-1");
              setActiveMeta(
                subZoneId != null ? subzoneMeta[subZoneId] : undefined
              );
            }
          }}
          onDragLeave={() => {
            setActiveZone(null);
          }}
          onDrop={handleDrop}
          noClick
        >
          {({ getRootProps }) => (
            <OverlayContainer
              {...getRootProps<OverlayContainerProps>({
                active: activeZone === "-1",
              })}
            >
              {children}
              <DropzoneOverlay>{text}</DropzoneOverlay>
            </OverlayContainer>
          )}
        </Dropzone>
      </DropzoneContext.Provider>
    );
  }

  return (
    <DropzoneAreaWrapper className={className}>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <DropzoneArea {...getRootProps()}>
              <input {...getInputProps()} />
              <span>{text}</span>
            </DropzoneArea>
          </section>
        )}
      </Dropzone>
    </DropzoneAreaWrapper>
  );
};

PancoDropzone.defaultProps = {
  text: "Drag'n'drop some files anywhere on screen, or click here to select files.",
};

export default PancoDropzone;
