/* eslint-disable func-names */
import {
  put,
  call,
  select,
  fork,
  take,
  takeEvery,
  spawn,
  cancel,
  takeLeading,
  getContext,
} from 'redux-saga/effects';
import { clearFilter, setFilter } from '../Actions/repository';
import * as actions from '../Actions/types';
import {
  setUserdata,
  setPrincipalId,
  setAuthenticated,
  setFiltersets,
  setDelegatedPrincipals,
  setDelegates,
  setUserDataLoading,
  loginADFSAttempt,
  setUserPermissions,
  refreshADFSAttempt,
  setUserGroupNames,
  loginADFSSuccess,
} from '../Actions/userdata';
import masterdata from '../Api/masterdata';
import userApi from '../Api/user';
import { resourceCorrectionForGateway } from 'cosmos-config/utils';
import callApi from './Effects/callApi';
import { notify, showSplashScreen, setBackgroundLoading } from '../Actions/ui';
import adfsSaga from './authentication/adfsSaga';
import { getPrincipalId } from '../Selectors/userdata';
import userdataProperties from '../Constants/userdata';
import delegateProperties from '../Constants/delegate';
import externalAdfsSaga from './authentication/externalAdfsSaga';
import _ from 'lodash';
import localforage from 'localforage';

export function* fetchUserdata(principalId) {
  try {
    const data = yield callApi(userApi.getUserdata, principalId);

    const filteredData = Object.entries(data)
      .filter(([key]) => userdataProperties.includes(key))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    let department = null;
    let departmentTeam = null;

    if (filteredData.office != null) {
      const officeCode = String(filteredData.office.split('/')[0]).trim();
      const [dep, ...rest] = officeCode.split('-');
      department = dep;
      departmentTeam = rest.length > 0 ? rest.join('-') : null;
    }

    yield put(
      setUserdata({
        ...filteredData,
        department,
        departmentTeam,
      })
    );
    return data;
  } catch (err) {
    yield put({ type: actions.userdata.LOGIN_ERROR, authenticated: false });
    console.error('Loading userdata failed!', err);
  }

  return null;
}

function* fetchFiltersets() {
  const principalId = yield select(getPrincipalId);
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);
  const folderId = yield select((state) => state.repository.folderId);

  const result = yield call(
    masterdata.getMasterData,
    docareaName,
    'USERS_FILTERSETS',
    ['id', 'name', 'filter'],
    {
      where: { principalId, folderId },
      orderBy: { name: 'asc' },
    },
    `ALL:${principalId}`
  );

  const filtersets = result.map((res) => {
    return res.properties.reduce((acc, cur) => {
      if (cur.name === 'filter') {
        acc[cur.name] = JSON.parse(atob(cur.value));
      } else {
        acc[cur.name] = cur.value;
      }

      return acc;
    }, {});
  });

  yield put(setFiltersets(filtersets));
  return filtersets;
}

function* saveFilterset({ name, filter, callback }) {
  const principalId = yield select(getPrincipalId);
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);
  const folderId = yield select((state) => state.repository.folderId);

  const entry = {
    principalId,
    folderId,
    name,
    filter: btoa(JSON.stringify(filter)),
  };

  const masterdataKey = yield call(
    masterdata.postMasterDataEntry,
    docareaName,
    'USERS_FILTERSETS',
    entry,
    `ALL:${principalId}`
  );

  yield put(notify('Filterset has been saved.', 'success'));
  if (callback != null) {
    yield spawn(callback);
  }

  yield call(fetchFiltersets);

  const filtersetId = String(masterdataKey).split('$S').pop();
  yield call(selectFilterset, { filtersetId });
}

function* deleteFilterset({ filtersetId }) {
  const principalId = yield select(getPrincipalId);
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  yield call(
    masterdata.removeMasterDataEntry,
    docareaName,
    'USERS_FILTERSETS',
    filtersetId,
    `ALL:${principalId}`
  );

  yield put(notify('Filterset has been deleted.', 'success'));
  yield put(clearFilter());
  return yield call(fetchFiltersets);
}

function* selectFilterset({ filtersetId }) {
  const filtersets = yield select((state) => state.userdata.filtersets);

  if (filtersetId == null) {
    yield put({
      type: actions.userdata.SET_FILTERSET_ID,
      filtersetId: null,
    });
    yield put(clearFilter());
  }

  const filterset = filtersets.find((fs) => fs.id === filtersetId);
  if (filterset != null) {
    yield put({
      type: actions.userdata.SET_FILTERSET_ID,
      filtersetId,
    });
    yield put(setFilter(filterset.filter));
  }
}

export function* authorize(authToken, domain = 'Users') {
  try {
    const { groupNames, defaultPositionId, principalId, permissions } =
      yield call(userApi.login, authToken, domain);

    yield put(setUserPermissions(permissions));
    yield put(setUserGroupNames(groupNames));

    localStorage.setItem('authToken', authToken);
    localStorage.setItem('domain', domain);

    yield put(setPrincipalId(principalId, defaultPositionId));
    return principalId;
  } catch (e) {
    const reason = !_.isEmpty(e.message) ? `Reason ${e.message}.` : '';

    yield put(notify(`Please log in to Cosmos. ${reason}`, 'info'));
    yield put({ type: actions.userdata.LOGIN_ERROR, authenticated: false });
  }
}

export function* logout() {
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('domain');
  yield put(setAuthenticated(false));
  yield put(setPrincipalId(null));

  localforage.dropInstance({
    name: 'common-cache',
  });
}

function* fetchDelegateStatus() {
  try {
    yield put(setUserDataLoading(true));
    const delegateStatus = yield callApi(userApi.getDelegateStatus);

    yield put(setDelegatedPrincipals(delegateStatus.delegatedPrincipals));
    yield put(setDelegates(delegateStatus.delegates));
  } catch (err) {
    yield put(
      notify(`Failed to fetch delegated principals. ${err.message}`, 'error')
    );
    console.error(err);
  } finally {
    yield put(setUserDataLoading(false));
  }
}

function* createDelegate({ delegate, callback }) {
  try {
    yield put(setUserDataLoading(true));
    const delegateToSave = resourceCorrectionForGateway(
      delegate,
      delegateProperties
    );

    yield callApi(userApi.postDelegate, delegateToSave);

    yield call(fetchDelegateStatus);
    yield spawn(callback);
  } catch (err) {
    yield put(notify(`Failed to create delegate. ${err.message}`, 'error'));
    console.error(err);
  } finally {
    yield put(setUserDataLoading(false));
  }
}

function* deactivateDelegates({ callback }) {
  try {
    yield put(setUserDataLoading(true));
    yield callApi(userApi.deactivateDelegates);

    yield call(fetchDelegateStatus);
    yield spawn(callback);
  } catch (err) {
    yield put(notify(`Failed to create delegate. ${err.message}`, 'error'));
    console.error(err);
  } finally {
    yield put(setUserDataLoading(false));
  }
}

export default function* userdataSaga() {
  yield takeEvery(actions.userdata.SELECT_FILTERSET, selectFilterset);
  yield takeEvery(actions.userdata.DELETE_FILTERSET, deleteFilterset);
  yield takeLeading(actions.userdata.SAVE_FILTERSET, saveFilterset);
  yield takeEvery(actions.userdata.FETCH_FILTERSETS, fetchFiltersets);
  yield takeLeading(actions.userdata.CREATE_DELEGATE, createDelegate);
  yield takeLeading(actions.userdata.DEACTIVATE_DELEGATES, deactivateDelegates);
}

export const authenticate = (saga, ...args) =>
  fork(function* () {
    let cleanEntry = true;
    yield fork(adfsSaga);
    yield fork(externalAdfsSaga);

    while (true) {
      yield fork(function* () {
        const { authToken, domain } = yield take(actions.userdata.LOGIN);

        yield put(
          setBackgroundLoading(
            true,
            'Authorizing user and preparing environment.'
          )
        );
        const principalId = yield call(authorize, authToken, domain);
        if (principalId != null) {
          yield fork(fetchDelegateStatus);
          yield call(fetchUserdata, principalId);
          yield put(setAuthenticated(true));
        }

        yield put(setBackgroundLoading(false));
      });

      // const ce = cleanEntry;
      // yield fork(function* () {
      //   const authToken = localStorage.getItem('authToken');
      //   const domain = localStorage.getItem('domain');

      //   if (authToken != null && domain != null) {
      //     yield put(loginADFSSuccess());
      //     yield put(showSplashScreen(false));
      //   } else if (ce) {
      //     yield put(loginADFSAttempt());
      //   } else {
      //     yield put(showSplashScreen(false));
      //   }
      // });

      yield put(showSplashScreen(false));

      const { authenticated, type } = yield take([
        actions.userdata.AUTHENTICATED,
        actions.userdata.LOGIN_ERROR,
        actions.userdata.LOGIN_ADFS_ERROR,
      ]);

      let actionType = type;
      if (actionType === actions.userdata.LOGIN_ERROR) {
        yield put(refreshADFSAttempt());

        const { type: attemptType } = yield take([
          actions.userdata.LOGIN_ADFS_SUCCESS,
          actions.userdata.LOGIN_ADFS_ERROR,
        ]);

        actionType = attemptType;
      }

      if (
        actionType === actions.userdata.LOGIN_ERROR ||
        actionType === actions.userdata.LOGIN_ADFS_ERROR
      ) {
        console.info('Authentication failed, going to reset login flow.');
        yield call(logout);
        yield put(showSplashScreen(false));
      } else if (authenticated) {
        // localStorage.setItem('loginAttemptCounter', 0);

        const tasks = yield fork(saga, ...args);

        yield take(actions.userdata.LOGOUT);

        yield put(
          setBackgroundLoading(
            true,
            'Logging out user and safely clearing environment.'
          )
        );
        yield call(logout);
        yield cancel(tasks);
        yield put({ type: actions.filterFolder.CLEAR_STORES });
        console.info('Logged out.');

        // yield put(showLogoutScreen());
        // yield delay(5000);
        yield put(setBackgroundLoading(false));
        // yield put(showLogoutScreen(false));
      }

      cleanEntry = false;
    }
  });
