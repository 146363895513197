import React, { useMemo } from "react";
import ResourceAccessManagement, {
  ResourceAccessManagementProps,
} from "./components/ResourceAccessManagement";
import AccessManagementProvider from "./providers/AccessManagementProvider";

const AccessManagement = ({
  resource,
  disabled,
  onChange,
}: ResourceAccessManagementProps) => {
  return (
    <AccessManagementProvider
      resourceId={useMemo(() => resource?.id, [resource])}
    >
      <ResourceAccessManagement
        resource={resource}
        disabled={disabled}
        onChange={onChange}
      />
    </AccessManagementProvider>
  );
};

export default AccessManagement;
