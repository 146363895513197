import React, { useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { CosmosFormGroupProps } from "./CosmosFormGroup";

export interface FormGroupBaseProps extends CosmosFormGroupProps {
  children?: React.ReactNode;
}

const FormGroupBase = ({
  group,
  defaultCollapse,
  children,
  className,
}: FormGroupBaseProps) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(defaultCollapse);

  if (group == null) {
    return null;
  }

  return (
    <Card
      border="dark"
      className={classNames(className)}
      key={`group-${group.name}`}
    >
      <Card.Header
        onClick={() => setCollapsed(!collapsed)}
        style={{ cursor: "pointer" }}
      >
        {group.translation ? t(group.translation) : group.label}
      </Card.Header>
      <Collapse in={!collapsed}>
        <Card.Body>{children}</Card.Body>
      </Collapse>
    </Card>
  );
};

export default FormGroupBase;
