import React, {
  createElement as h,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { DateTime, Interval } from "luxon";
import { Button } from "react-bootstrap";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import styled from "styled-components";
import { mix } from "polished";
import { shadeColor } from "../../utils";

const CalendarNavigation = styled.div``;

const CalendarContainer = styled.div``;

const CalendarWrapper = styled.div`
  ${CalendarNavigation} {
    align-items: center;
    display: flex;
    justify-content: space-between;

    color: ${(props) => props.theme.body};

    .btn-link {
      color: ${(props) => props.theme.primary};
    }

    & + ${CalendarNavigation} {
      border-bottom: 1px solid
        ${(props) => shadeColor(0.2, props.theme.background)};
      padding-bottom: 10px;
    }
  }

  ${CalendarContainer} {
    padding: 10px;

    table {
      width: 100%;

      thead {
        th {
          color: ${(props) => props.theme.base};
          font-weight: normal;
          text-align: center;
        }
      }

      tbody {
        tr.calendar-week {
          td.calendar-day {
            padding: 8px;
            cursor: pointer;
            text-align: center;
            color: ${(props) => props.theme.base};
            // @include transition;

            &.outside-month {
              color: ${(props) => props.theme.muted};
            }

            &.selected {
              background-color: ${(props) =>
                mix(0.3, props.theme.base, props.theme.background)};
              border: 1px solid ${(props) => props.theme.base};
              color: ${(props) => props.theme.base};
            }

            &.range {
              background-color: ${(props) =>
                mix(0.3, props.theme.base, props.theme.background)};
            }

            &.today {
              background-color: ${(props) =>
                mix(0.3, props.theme.primary, props.theme.background)};
            }

            &:hover {
              background-color: ${(props) => props.theme.primary};
              color: ${(props) => props.theme.background};
            }

            &.disabled {
              color: ${(props) => props.theme.muted};
              pointer-events: none;
            }
          }
        }
      }
    }
  }
`;

type CalendarWeekDate = {
  day: number;
  currentMonth: boolean;
  today: boolean;
  weekDay: string;
  date: number;
  selected: boolean;
  range: boolean;
  disabled: boolean;
};

type CalendarWeek = {
  weekNumber: number;
  days: CalendarWeekDate[];
};

export type CalendarBoundary = {
  min: number;
  max: number;
};

export declare type CalendarProps = {
  onSelect?: (millis: number) => void;
  value?: number | null;
  zone?: string;
  rangeValue?: number | null;
  boundary?: CalendarBoundary;
};

type CalendarState = {
  month: number;
  year: number;
  weeks: CalendarWeek[];
};

const Calendar = ({
  onSelect,
  value,
  zone,
  rangeValue,
  boundary,
}: CalendarProps) => {
  const sqlDateValue = useMemo(() => {
    if (value == null) {
      return null;
    }

    try {
      return DateTime.fromMillis(value, { zone }).toSQLDate();
    } catch (err) {
      return null;
    }
  }, [value, zone]);

  const [state, setState] = useState<CalendarState>({
    month: DateTime.now().month,
    year: DateTime.now().year,
    weeks: [],
  });

  const calculateRangeInterval = useCallback(() => {
    if (rangeValue != null && value != null) {
      const rangeDate = DateTime.fromMillis(rangeValue);
      const valueDate = DateTime.fromMillis(value);

      if (rangeDate > valueDate) {
        return Interval.fromDateTimes(valueDate, rangeDate);
      }

      return Interval.fromDateTimes(rangeDate, valueDate);
    }

    return null;
  }, [rangeValue, value]);

  useEffect(() => {
    if (!_.isEmpty(value)) {
      const baseDate = DateTime.fromMillis(parseInt(String(value), 10), {
        zone,
      });

      setState((s) => ({
        ...s,
        month: baseDate.month,
        year: baseDate.year,
      }));
    }
  }, [value, zone]);

  const recalculate = useCallback(() => {
    const minDate =
      boundary != null && boundary.min != null
        ? DateTime.fromMillis(boundary.min)
        : null;

    const maxDate =
      boundary != null && boundary.max != null
        ? DateTime.fromMillis(boundary.max)
        : null;

    const baseDate = DateTime.fromObject(
      {
        year: state.year,
        month: state.month,
      },
      {
        zone,
      }
    );

    const range = calculateRangeInterval();

    const monthStart = baseDate.startOf("month");
    const weeks = _.range(6)
      .map((val, idx) => monthStart.plus({ weeks: idx }))
      .map((week) => {
        const weekStart = week.startOf("week");
        const days = _.range(7).map((val, idx) => {
          const date = weekStart.plus({ days: idx });
          const dateString = date.toSQLDate();

          return {
            day: date.day,
            currentMonth: date.month === state.month,
            today: dateString === DateTime.now().toSQLDate(),
            weekDay: date.weekdayShort,
            date: date.toMillis(),
            selected: dateString === sqlDateValue,
            range: range != null ? range.contains(date) : false,
            disabled:
              (minDate != null && minDate > date) ||
              (maxDate != null && maxDate < date),
          } as CalendarWeekDate;
        });

        return {
          weekNumber: week.weekNumber,
          days,
        } as CalendarWeek;
      });

    setState((s) => ({ ...s, weeks }));
  }, [
    state.year,
    state.month,
    sqlDateValue,
    calculateRangeInterval,
    zone,
    boundary,
  ]);

  useEffect(() => {
    recalculate();
  }, [recalculate]);

  const getWeek = (index: number) => {
    if (state.weeks != null && state.weeks.length > index) {
      return state.weeks[index];
    }

    return { days: [] };
  };

  const navigateMonth = (amount: number) => {
    const nextDate = DateTime.fromObject({
      year: state.year,
      month: state.month,
    }).plus({ months: amount });

    setState((s) => ({
      ...s,
      month: nextDate.month,
      year: nextDate.year,
    }));
  };

  const navigateYear = (amount: number) => {
    navigateMonth(amount * 12);
  };

  const getMonthName = () => {
    return DateTime.fromObject({
      year: state.year,
      month: state.month,
    }).monthLong;
  };

  const getDayClassNames = (date: CalendarWeekDate) => {
    const result = ["calendar-day"];

    if (date.disabled) {
      result.push("disabled");
    }

    if (!date.currentMonth) {
      result.push("outside-month");
    }

    if (date.today) {
      result.push("today");
    }

    if (date.selected) {
      result.push("selected");
    }

    if (date.range) {
      result.push("range");
    }

    return result.join(" ");
  };

  return (
    <CalendarWrapper>
      <CalendarNavigation>
        <Button variant="link" onClick={() => navigateYear(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        {state.year}
        <Button variant="link" onClick={() => navigateYear(1)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </CalendarNavigation>

      <CalendarNavigation>
        <Button variant="link" onClick={() => navigateMonth(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        {getMonthName()}
        <Button variant="link" onClick={() => navigateMonth(1)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </CalendarNavigation>
      <CalendarContainer>
        <table>
          <thead>
            <tr>
              {getWeek(0).days.map((date, idx) => {
                return h("th", { key: `calendar-header-${idx}` }, date.weekDay);
              })}
            </tr>
          </thead>
          <tbody>
            {state.weeks.map((week, idx) => (
              <tr key={`calendar-week-${idx}`} className="calendar-week">
                {week.days.map((date) => (
                  <td
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    key={`calendar-cell-${date.date}`}
                    className={getDayClassNames(date)}
                    onClick={() => {
                      if (!date.disabled && onSelect != null) {
                        onSelect(date.date);
                      }
                    }}
                  >
                    {date.day}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </CalendarContainer>
    </CalendarWrapper>
  );
};

Calendar.defaultProps = {
  zone: "local",
};

export default Calendar;
