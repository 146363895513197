import { createContext } from "react";

export type CosmosSession = {
  accessToken: string | null;
  domainName: string | null;
  tokenType: string | null;
  username: string | null;
};

export interface SessionContextValue {
  ready: boolean;
  accessToken: string | null;
  domainName: string | null;
  entryReferer: string | null;
  loginBasic: (username: string, password: string) => void;
  loginOpenId: (domainName: string, referer?: string) => void;
  logout: (force?: boolean) => void;
  invalidate: () => void;
}

const SessionContext = createContext<SessionContextValue>({
  ready: false,
  accessToken: null,
  domainName: "Users",
  entryReferer: null,
  loginBasic: () => {},
  loginOpenId: () => {},
  logout: () => {},
  invalidate: () => {},
});

export default SessionContext;
