import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Form } from "react-bootstrap";
import _ from "lodash";
import { LoadingOverlay } from "cosmos-components";
import { groupType, propertyPermission } from "cosmos-config/generator";
import classNames from "classnames";
import { Group } from "cosmos-config/lib/group/group";
import FormProperty from "./form/FormProperty";
import useCurrentUser from "../hooks/useCurrentUser";
import { Property } from "cosmos-config/lib/property/property";
import Resource from "../types/resource";
import {
  UniversalFormGroup,
  DataTableFormGroup,
  PermissionsFormGroup,
  WidgetFormGroup,
  ModulePropertiesGroup,
} from "./form/group";
import { UniversalFormProps } from "./SimpleUniversalForm";

const groupComparator = (a: Group, b: Group) => {
  return a.name === b.name && a.properties.length === b.properties.length;
};

type ValidationError = {
  property: string;
  customMessage: string;
};

export interface SimpleGroupedUniversalFormProps extends UniversalFormProps {
  groups?: Group[];
  loading?: boolean;
  grouping?: boolean;
  columns?: number;
  collapsed?: boolean;
  owner?: string;
  reference?: string;
  validationErrors?: ValidationError[];
  validation?: boolean;
  unwrapped?: boolean;
  highlightedProperties?: string[];
}

const SimpleGroupedUniversalForm = ({
  children,
  groups,
  loading,
  resource,
  onUpdateResource,
  onSubmit,
  grouping,
  columns,
  disabled,
  collapsed,
  owner,
  inline,
  reference,
  validationErrors,
  validation,
  unwrapped,
  highlightedProperties,
}: SimpleGroupedUniversalFormProps) => {
  const [innerGroups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    const newGroups = (groups || [])
      .map((group) => {
        const properties = group.properties.filter((c) => c.editable);
        return {
          ...group,
          properties,
        };
      })
      .filter(
        (g) =>
          !g.isHidden(resource || {}) &&
          g.editable &&
          (g.type !== groupType.PROPERTIES || g.properties.length > 0)
      )
      .filter(
        (g) =>
          g.permission == null ||
          g.permission === propertyPermission.EVERYBODY ||
          (g.permission === propertyPermission.OWNER && owner)
      ) as Group[];

    setGroups((oldGroups) => {
      return _(newGroups).xorWith(oldGroups, groupComparator).isEmpty()
        ? oldGroups
        : newGroups;
    });
  }, [groups, owner, resource, reference]);

  const formProperties = useMemo(
    () =>
      _(innerGroups)
        .flatMap((g) => g.properties)
        .value(),
    [innerGroups]
  );

  const { defaultPositionId } = useCurrentUser();

  const dependencyMap = useMemo(() => {
    return _(formProperties)
      .filter((prop) => prop.isDependant())
      .keyBy("name")
      .mapValues("dependency")
      .value();
  }, [formProperties]);

  const updateFuncMap = useMemo(() => {
    return _(formProperties).keyBy("name").mapValues("update").value();
  }, [formProperties]);

  const updateImpactFuncMap = useMemo(() => {
    return _(formProperties).keyBy("name").mapValues("updateImpact").value();
  }, [formProperties]);

  const handleChange = useCallback(
    ({
      name,
      value,
      ref,
    }: {
      name?: string;
      value: any[] | null;
      ref?: string | null;
    }) => {
      const isEmpty = (v: any) =>
        v == null || (Array.isArray(v) && v.length === 0);

      if (resource != null && name != null && onUpdateResource != null) {
        const depName = dependencyMap[name];
        if (depName != null && isEmpty(resource[depName])) {
          onUpdateResource(resource.id, depName, [ref || ""]);
        }

        const updatedValue =
          updateFuncMap[name] != null
            ? updateFuncMap[name](
                value,
                resource,
                defaultPositionId || undefined
              )
            : value;

        onUpdateResource(resource.id, name, updatedValue);

        if (typeof updateImpactFuncMap[name] === "function") {
          const impactedProps = updateImpactFuncMap[name](
            updatedValue,
            resource,
            defaultPositionId || ""
          );

          impactedProps.forEach((prop) => {
            onUpdateResource(resource.id, prop.name, prop.value);
          });
        }

        _(dependencyMap)
          .entriesIn()
          .filter(([, propName]) => propName === name)
          .map(([key]) => key)
          .forEach((dep) => {
            if (!isEmpty(resource[dep])) {
              onUpdateResource(resource.id, dep, []);
            }
          });
      }
    },
    [
      resource,
      dependencyMap,
      onUpdateResource,
      updateFuncMap,
      defaultPositionId,
      updateImpactFuncMap,
    ]
  );

  const renderFormProperty = (prop: Property, wide = false) => {
    if (prop.canRead(defaultPositionId || "", resource || {})) {
      return (
        <FormProperty
          key={`property-${prop.name}`}
          property={prop}
          value={_.get(resource, prop.name)}
          filter={resource}
          onChange={handleChange}
          disabled={
            !!disabled ||
            !prop.canWrite(defaultPositionId || "", resource || {}) ||
            (!!reference && !prop.editableReference)
          }
          inline={inline}
          valid={
            !(validationErrors || [])
              .map((err) => err.property)
              .includes(prop.name)
          }
          validationMessage={
            (validationErrors || []).find((err) => err.property === prop.name)
              ?.customMessage
          }
          validation={validation}
          highlighted={(highlightedProperties || []).includes(prop.name)}
          wide={wide}
        />
      );
    }

    return null;
  };

  const renderGroup = (group: Group, idx: number) => {
    const attributes = {
      key: `group-${group.name}`,
      group,
      columns,
      filter: resource,
      defaultCollapse: !(
        collapsed ||
        idx === 0 ||
        !group.getDefaultCollapsed(resource)
      ),
      className: classNames({ "border-0": unwrapped, "mb-3": !unwrapped }),
      resource: resource as Resource,
    };

    switch (group.type) {
      case groupType.PERMISSIONS:
        return (
          <PermissionsFormGroup {...attributes}>
            {(prop: Property) => renderFormProperty(prop)}
          </PermissionsFormGroup>
        );
      // case groupType.STRUCTURE:
      //   return (
      //     <FormGroupBase {...attributes}>
      //       <StructureSelector
      //         resource={resource}
      //         onSelect={(mask) => {
      //           Object.entries(mask).forEach(([propertyName, value]) => {
      //             onUpdateResource(
      //               resource.id,
      //               propertyName,
      //               value ? [value] : []
      //             );
      //           });
      //         }}
      //       />
      //     </FormGroupBase>
      //   );
      case groupType.DATATABLE:
        // @ts-ignore
        return <DataTableFormGroup {...attributes} />;
      case groupType.WIDGET:
        return <WidgetFormGroup {...attributes} />;
      case groupType.MULTI_ATTRIBUTE:
        return null;
      // return <MultiAttributeGroup {...attributes} onChange={handleChange} />;
      // case groupType.CONTENT_UPDATE:
      //   return <ContentUpdateGroup {...attributes} />;
      //@ts-ignore
      case "module-properties":
        return (
          <ModulePropertiesGroup
            {...attributes}
            onUpdateResource={(id, name, value) => {
              handleChange({ name, value });
            }}
          />
        );
      case groupType.PROPERTIES:
      default:
        return (
          <UniversalFormGroup {...attributes} wrapped={grouping}>
            {(prop, wide) => renderFormProperty(prop, wide)}
          </UniversalFormGroup>
        );
    }
  };

  return (
    <LoadingOverlay loading={loading}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {innerGroups.map((group, idx) => renderGroup(group, idx))}

        <div className="mt-3">
          {children &&
            children({
              submit: () => {
                if (onSubmit != null) {
                  onSubmit(resource?.id);
                }
              },
              disabled,
              update: (propertyName: string, value: any) => {
                handleChange({
                  name: propertyName,
                  value,
                });
              },
              resource,
            })}
        </div>
      </Form>
    </LoadingOverlay>
  );
};

SimpleGroupedUniversalForm.defaultProps = {
  columns: 1,
  inline: true,
  validation: true,
};

export default SimpleGroupedUniversalForm;
