import { useTranslation } from "react-i18next";
import { Button, CardGroup, CloseButton, Modal } from "react-bootstrap";
import { faUserMinus, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { LoadingOverlay } from "cosmos-components";
import React, { useMemo, useReducer } from "react";
import resourcePermissions from "../constants/resourcePermissions";
import MemberSelect from "../../../components/form/MemberSelect";
import SimpleUniversalForm from "../../../components/SimpleUniversalForm";
import useAccessManagement from "../hooks/useAccessManagement";
import { Permission } from "../constants/Permission";
import PrimaryCard from "./fragments/PrimaryCard";

type operationType = "grant" | "revoke";

const operations: operationType[] = ["grant", "revoke"];
const iconMap = {
  grant: faUserPlus,
  revoke: faUserMinus,
};

type grantRevokeReducerState = {
  principalIds: string[];
  operation: operationType;
  resourcePermission: Partial<Permission>;
};

type grantRevokeReducerAction = {
  type: string;
  payload?: any;
};

const initialState: grantRevokeReducerState = {
  principalIds: [],
  operation: operations[0],
  resourcePermission: {
    id: null,
    active: true,
    accessLevel: 7,
  },
};

const actions = {
  SET_PRINCIPAL_IDS: "SET_PRINCIPAL_IDS",
  SET_OPERATION: "SET_OPERATION",
  UPDATE_RESOURCE_PERMISSION: "UPDATE_RESOURCE_PERMISSION",
  CLEAR_STATE: "CLEAR_STATE",
  UPDATE_FINISHED: "UPDATE_STARTED",
};

const reducer = (
  state: grantRevokeReducerState,
  action: grantRevokeReducerAction
) => {
  switch (action.type) {
    case actions.SET_PRINCIPAL_IDS:
      return {
        ...state,
        principalIds: action.payload.principalIds,
      };
    case actions.SET_OPERATION:
      return {
        ...state,
        operation: action.payload.operation,
      };
    case actions.UPDATE_RESOURCE_PERMISSION:
      return {
        ...state,
        resourcePermission: {
          ...state.resourcePermission,
          [action.payload.propertyName]: action.payload.value,
        },
      };
    case actions.UPDATE_FINISHED:
    case actions.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export interface GrantRevokeAccessModalProps {
  show?: boolean;
  onClose?: () => void;
  resourceIds?: string[];
}

const GrantRevokeAccessModal = ({
  show,
  onClose,
  resourceIds,
}: GrantRevokeAccessModalProps) => {
  const { loading, grantPermissionsBatch, revokePermissionsBatch } =
    useAccessManagement({ resourceIds: resourceIds || [] });

  const { t } = useTranslation("module");

  const [localState, localDispatch] = useReducer(reducer, initialState);

  const closeModal = () => {
    localDispatch({
      type: actions.CLEAR_STATE,
    });

    if (onClose != null) {
      onClose();
    }
  };

  const properties = useMemo(() => {
    return resourcePermissions.filter(
      (p) => !["permittedusercommonname", "active"].includes(p.name)
    );
  }, []);

  return (
    <Modal show={show} className="location-modal" onHide={closeModal} size="lg">
      <Modal.Header>
        <Modal.Title>
          {t("contextmenu.grant_revoke_access", {
            defaultValue: "Grant/Revoke Access",
          })}
        </Modal.Title>
        <CloseButton className="close-icon" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <LoadingOverlay loading={loading}>
          <CardGroup className="mb-3">
            {operations.map((op) => (
              <PrimaryCard
                key={op}
                icon={iconMap[op]}
                active={op === localState.operation}
                onClick={() => {
                  localDispatch({
                    type: actions.SET_OPERATION,
                    payload: {
                      operation: op,
                    },
                  });
                }}
              />
            ))}
          </CardGroup>

          <MemberSelect
            className="flex-grow-1"
            value={localState.principalIds}
            onChange={({ value }) => {
              if (Array.isArray(value) && value.length > 0) {
                localDispatch({
                  type: actions.SET_PRINCIPAL_IDS,
                  payload: {
                    principalIds: value,
                  },
                });
              }
            }}
            multiple
            boxed
            flattenGroups={false}
          />

          {localState.operation === "grant" && (
            <div className="mt-3">
              <SimpleUniversalForm
                properties={properties}
                resource={localState.resourcePermission}
                onUpdateResource={(id, name, value) => {
                  localDispatch({
                    type: actions.UPDATE_RESOURCE_PERMISSION,
                    payload: {
                      propertyName: name,
                      value,
                    },
                  });
                }}
              />
            </div>
          )}
        </LoadingOverlay>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={localState.principalIds.length <= 0}
          onClick={() => {
            const callback = () => {
              localDispatch({
                type: actions.UPDATE_FINISHED,
              });

              if (onClose != null) {
                onClose();
              }
            };

            if (localState.operation === "grant") {
              grantPermissionsBatch(
                localState.principalIds,
                localState.resourcePermission
              ).then(callback);
            } else if (localState.operation === "revoke") {
              revokePermissionsBatch(localState.principalIds).then(callback);
            }
          }}
        >
          {t("button.submit", { defaultValue: "Submit" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GrantRevokeAccessModal;
