import React from "react";
import useUploadAdvisor from "../hooks/useUploadAdvisor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faCheck,
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { UploadAdvisorStatus as Status } from "../contexts/UploadAdvisorContext";

const UploadAdvisorStatus = () => {
  const { t } = useTranslation("module");
  const { status, issues, uploadAllowed, options } = useUploadAdvisor();

  if (status === Status.NOT_AVAILABLE) {
    return (
      <span className="text-danger">
        {t("upload.upload_advisor_unavailable", {
          defaultValue: "Upload advisor context is not available!",
        })}
      </span>
    );
  }

  if (status === Status.PROCESSING) {
    return (
      <>
        <span className="mr-3">
          {t("upload.check_duplicates", {
            defaultValue: "Checking for duplicates",
          })}
        </span>
        <FontAwesomeIcon icon={faSpinner} spin className="text-dark" />
      </>
    );
  }

  if (status === Status.ANALYZED) {
    if (issues.length > 0) {
      if (!uploadAllowed) {
        return (
          <>
            <span className="mr-3 text-danger">
              {options.multipleFiles
                ? t("upload.duplicates", {
                    defaultValue: "Some documents already uploaded!",
                  })
                : t("upload.single_duplicates", {
                    defaultValue: "The document is already uploaded!",
                  })}
            </span>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="text-danger"
            />
          </>
        );
      } else {
        return (
          <>
            <span className="mr-3 text-warning">
              {t("upload.duplicates_in_multi_content", {
                defaultValue: "Duplicates found in multi content documents.",
              })}
            </span>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="text-warning"
            />
          </>
        );
      }
    }

    return (
      <>
        <span className="mr-3">
          {t("upload.no_duplicates", {
            defaultValue: "No duplicates found!",
          })}
        </span>
        <FontAwesomeIcon icon={faCheck} className="text-success" />
      </>
    );
  }

  return (
    <span>
      {options.multipleFiles
        ? t("upload.please_select_files", {
            defaultValue: "Please select one or more files.",
          })
        : t("upload.please_select_file", {
            defaultValue: "Please select a file.",
          })}
    </span>
  );
};

export default UploadAdvisorStatus;
