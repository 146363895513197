import GatewayApi from './conf/GatewayApi';

const gatewayApi = GatewayApi.build();

/**
 * @module api/businessProcess
 * @category Api
 */

/**
 * @typedef Process
 * @type {Object}
 * @property {string} id - unique identifier of the process
 * @property {string} name - name of the process
 * @property {string} processDefinitionKey - key of a process definition
 * @property {Map.<String, any>} variables - intial variables of the process
 */

/**
 * @typedef Task
 * @type {Object}
 * @property {string} id - inique identifier of the process
 */

/**
 * Retrieve all available process definitions.
 * @returns {Process[]} list of process definitions
 */
const getProcessDefinitions = () => {
  return gatewayApi
    .get(`/business-process/definitions`)
    .then((response) => Promise.resolve(response.data));
};

/**
 * Initiate new business process
 * @param {any} process - business process object
 * @returns {Process} created business process object
 */
const createProcess = (process) => {
  return gatewayApi
    .post('/business-process/processes', process)
    .then((response) => Promise.resolve(response.data));
};

/**
 *
 * @param {String} taskId
 * @param {*} task
 * @returns {Task} submitted task
 */
const submitTask = (taskId, task) => {
  return gatewayApi
    .put(`/business-process/task/${taskId}/submit`, task)
    .then((response) => Promise.resolve(response.data));
};

/**
 *
 * @param {String} processId
 * @returns {Promise} promise
 */
const cancelProcess = (processId) => {
  return gatewayApi
    .delete(`/business-process/${processId}`)
    .then((response) => Promise.resolve(response.data));
};

/**
 *
 * @param {String} processId
 * @param {Process} process
 * @returns {Promise} promise
 */
const updateProcess = (processId, process) => {
  return gatewayApi
    .put(`/business-process/${processId}`, process)
    .then((response) => Promise.resolve(response.data));
};

const businessProcess = {
  getProcessDefinitions,
  createProcess,
  submitTask,
  cancelProcess,
  updateProcess,
};

export default businessProcess;
