import { DateTime } from "luxon";
import propertyType from "../property/propertyType";
import { Property } from "../property/property";
import { Group } from "../group/group";
import _ from "lodash";

type IterateeFunction = (value: any, key: string) => any;

const mapValues = (obj: Object, iteratee: IterateeFunction) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: iteratee(value, key),
    }),
    {}
  );

type FilterCallback = (prop: Property | Group) => boolean;

const filteredPropertyNames = (
  properties: Array<Property | Group>,
  filterCallback: FilterCallback
): string[] => {
  return properties
    .map((prop) => {
      const propGroup = prop as Group;
      if (propGroup.group) {
        return propGroup.buildChildren();
      }

      return [prop];
    })
    .reduce((acc, cur) => [...acc, ...cur], [])
    .filter(filterCallback)
    .map((prop: Property | Group) => prop.name);
};

const selectablePropertyTypes: string[] = [
  propertyType.SELECT,
  propertyType.MEMBER_SELECT,
  propertyType.RADIO,
  propertyType.SUGGEST,
  propertyType.RESOURCE_SELECT,
  propertyType.PRINCIPAL_SELECT,
  propertyType.MODULE_ELEMENT_SELECT,
];

const selectablePropertyFilter: FilterCallback = (prop) => {
  const p = prop as Property;
  return (
    selectablePropertyTypes.includes(prop.type) &&
    !(p.multiple || p.handleAsMulti)
  );
};

const resourceCorrectionForGateway = (
  resource: any,
  properties: Array<Property>
) => {
  const selectablePropertyNames = filteredPropertyNames(
    properties,
    selectablePropertyFilter
  );

  return mapValues(resource, (value, key) => {
    if (selectablePropertyNames.includes(key) && Array.isArray(value)) {
      const valueArray = value.filter((v) => v != null && v !== "");

      if (valueArray.length === 0) {
        return null;
      }

      return valueArray[0];
    }

    return value;
  });
};

const resourceCorrectionOfGateway = (
  resource: any,
  propertiesMap: Array<Property>
) => {
  const selectablePropertyNames = filteredPropertyNames(
    propertiesMap,
    selectablePropertyFilter
  );

  const parseObject = (obj: any, path?: String): any =>
    mapValues(obj, (value, key) => {
      const keyPath = path != null ? `${path}.${key}` : key;

      if (selectablePropertyNames.includes(keyPath)) {
        return [value].filter((x) => x != null);
      }

      if (value != null && typeof value === "object" && !Array.isArray(value)) {
        return parseObject(value, keyPath);
      }

      return value;
    });

  return parseObject(resource);
};

declare type propertyMapFunction = {
  [key: string]: (...args: any[]) => any;
};

const propertiesToPropertyMap = (
  properties: Property[],
  propertyName: string
): propertyMapFunction =>
  _(properties)
    .filter((p) => p.name != null)
    .mapKeys("name")
    .mapValues((p) => {
      const func: Function = _.get(p, propertyName);

      return (...args: any[]) => {
        return func.call(p, ...args);
      };
    })
    .value();

const buildId = (entry: any) => {
  if (entry.resourceKey != null) {
    return entry.resourceKey.id;
  }

  if (entry.workflowInstanceKey != null) {
    return entry.workflowInstanceKey.id;
  }

  if (entry.masterdataKey != null) {
    return entry.masterdataKey.id;
  }

  if (entry.id != null) {
    return entry.id;
  }

  if (entry.pid != null) {
    return entry.pid;
  }

  return null;
};

const buildResource = (item: any, parseFunctionsMap: propertyMapFunction) => {
  return _(item.properties)
    .keyBy("name")
    .mapValues("value")
    .mapValues((value, propertyName, res) => {
      const parse = parseFunctionsMap[propertyName] || ((v) => v);
      const parsedValue = parse(value);

      // if (res[propertyName] != null) {
      //   return [].concat(res.propertyName, parsedValue);
      // }

      return parsedValue;
    })
    .value();
};

const resourceCorrectionOfNscale = (item: any, properties: Property[]) => {
  const parseFunctionsMap = propertiesToPropertyMap(properties, "parseValue");

  const build = (i: any) => ({
    id: buildId(i),
    ...buildResource(i, parseFunctionsMap),
  });

  if (Array.isArray(item)) {
    return item.map((i) => build(i));
  }

  return build(item);
};

export {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
  resourceCorrectionOfNscale,
};
