import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import Quill from "quill";
import {
  InputValueHandler,
  PancoInputElementProps,
} from "../../types/PancoInputElemenProps";
import _ from "lodash";
import { trimUINumber } from "cosmos-config/utils";
import "quill/dist/quill.snow.css";
import "quill-mention";
import styled from "styled-components";
import { shadeColor } from "../../utils";
import { transparentize } from "polished";

const QlEditorWrapper = styled.div`
  .ql-toolbar {
    border-color: ${(props) =>
      shadeColor(0.2, props.theme.background)} !important;
  }

  .ql-container {
    border-color: ${(props) =>
      shadeColor(0.2, props.theme.background)} !important;

    .ql-editor {
      color: ${(props) => props.theme.body};
      .mention {
        color: ${(props) => props.theme.base};
      }
    }

    .ql-mention-list-container {
      background: ${(props) => props.theme.background};
      box-shadow: 0px 0px 8px 0px
        ${(props) => shadeColor(0.18, props.theme.background)};
      z-index: 1;

      .ql-mention-list {
        list-style-type: none;
        padding: 5px 0;
        margin: 0;

        .ql-mention-list-item {
          cursor: pointer;
          padding: 0.6rem 10px;
          color: ${(props) => props.theme.base};

          &:hover {
            background-color: ${(props) =>
              transparentize(0.9, props.theme.base)};
          }

          &.selected {
            background-color: ${(props) =>
              transparentize(0.9, props.theme.base)};
          }
        }
      }
    }
  }
`;

export type MentionableUsers = {
  principalId: string;
  commonname: string;
};

export type MentionableResource = {
  displayname: string;
  identifier: number;
};

export interface PancoRichTextAreaProps
  extends PancoInputElementProps,
    InputValueHandler<string> {
  limit?: number;

  users?: MentionableUsers[];
  resources?: MentionableResource[];
  replyTo?: string;
  onMentionChange?: (mentions: any) => void;
}

export interface PancoRichTextAreaComponent {
  focus: () => void;
}

const PancoRichTextArea = forwardRef<
  PancoRichTextAreaComponent,
  PancoRichTextAreaProps
>(
  (
    {
      name,
      value,
      onChange,
      disabled,
      className,

      limit,
      users,
      resources,
      replyTo,
      onMentionChange,
    }: PancoRichTextAreaProps,
    ref
  ) => {
    const editorContainerRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<Quill>();

    const [innerMentions, setInnerMentions] = useState<string[]>([]);
    const [innerValue, setInnerValue] = useState<string | null>(null);
    const [charactersCount, setCharactersCount] = useState<number>(0);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (quillRef.current != null) {
          quillRef.current.focus();
        }
      },
    }));

    useEffect(() => {
      if (onMentionChange) {
        onMentionChange(innerMentions);
      }
    }, [innerMentions, onMentionChange]);

    useEffect(() => {
      if (onChange != null && innerValue !== value) {
        onChange({ name, value: innerValue });
      }
    }, [innerValue, name, value]);

    const mentionOptions = useMemo(() => {
      return _(users)
        .uniqBy("principalId")
        .map((u) => ({
          value: trimUINumber(u.commonname),
          id: u.principalId,
          denotationChar: "@",
        }))
        .sortBy(["value"])
        .value();
    }, [users]);

    useEffect(() => {
      const mention = {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@", "#"],
        source: (
          searchTerm: string,
          renderList: (options: any) => void,
          mentionChar: string
        ) => {
          if (mentionChar === "@") {
            renderList(
              mentionOptions.filter((m) =>
                String(m.value)
                  .toLocaleLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
            );
          } else if (mentionChar === "#") {
            renderList(
              (resources || []).map((resources) => ({
                value: resources.displayname,
                id: resources.identifier,
                denotationChar: "#",
                // link: `${DOCUMENT_ROUTE}/${document.identifier}`,
                target: "_self",
              }))
            );
          }
        },
      };

      if (editorContainerRef.current != null) {
        editorContainerRef.current.innerHTML = "";
        const { parentElement } = editorContainerRef.current;

        if (parentElement != null) {
          for (let i = 0; i < parentElement.childNodes.length; i += 1) {
            if (
              //@ts-ignore
              parentElement.childNodes[i].className.includes("ql-toolbar")
            ) {
              parentElement.removeChild(parentElement.childNodes[i]);
            }
          }
        }

        quillRef.current = new Quill(editorContainerRef.current, {
          theme: "snow",
          placeholder: "Write a comment...",
          modules: {
            toolbar: [
              ["bold", "italic", "underline", "strike"],
              ["link", "image"],
              [{ color: [] }, { background: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["clean"],
            ],
            mention,
          },
        });

        quillRef.current.on("text-change", () => {
          if (quillRef.current != null) {
            const { ops } = quillRef.current.getContents();
            const men = ops
              //@ts-ignore
              .filter((op) => op.insert != null && !!op.insert["mention"])
              .map(
                ({
                  insert: {
                    //@ts-ignore
                    mention: { id },
                  },
                }) => id
              )
              .filter((principalId, idx, arr) => {
                return arr.indexOf(principalId) === idx;
              });

            setInnerMentions((im) => {
              if (_.isEqual(im, men)) {
                return im;
              }

              return men;
            });

            setCharactersCount(quillRef.current.getLength() - 1);
          }
        });

        if (quillRef.current != null) {
          quillRef.current.root.addEventListener("blur", () => {
            console.log("blur hapenned");
            if (quillRef.current != null) {
              setInnerValue(quillRef.current.root.innerHTML);
            }
          });
        }

        if (replyTo != null) {
          const item = mentionOptions.find((o) => o.id === replyTo);

          if (item != null && quillRef.current != null) {
            quillRef.current.getModule("mention").insertItem(item, true);
          }
        }
      }
    }, [editorContainerRef, mentionOptions, replyTo, resources]);

    // useEffect(() => {
    //   if (
    //     quillRef.current != null &&
    //     value !== quillRef.current.root.innerHTML
    //   ) {
    //     quillRef.current.root.innerHTML = value || "";
    //   }
    // }, [quillRef, value]);

    useEffect(() => {
      if (quillRef.current != null) {
        quillRef.current.enable(!disabled);
      }
    }, [quillRef, disabled]);

    return (
      <QlEditorWrapper className={className}>
        <div ref={editorContainerRef} />
        {!disabled && (limit || 0) > 0 && (
          <small
            className={
              charactersCount > (limit || 0) ? "text-danger" : "text-muted"
            }
          >
            {charactersCount}
            <span>/</span>
            {limit}
          </small>
        )}
      </QlEditorWrapper>
    );
  }
);

export default PancoRichTextArea;
