import * as actions from './types';

/**
 * Business process action generators.
 * @module actions/userSettings
 * @category Actions
 */

/**
 * Action for setting skip guide
 * @function
 * @param {boolean} skip -
 */
export const setSkipGuide = (skip = true) => ({
  type: actions.userSettings.SET_SKIP_GUIDE,
  skip,
});

/**
 * Action for setting skip GDRP
 * @function
 * @param {boolean} skip -
 */
export const setSkipGDPR = (skip = true) => ({
  type: actions.userSettings.SET_SKIP_GDPR,
  skip,
});

/**
 * Action to toggle metadata editor.
 * @function
 * @param {} metadataEditor -
 */
export const toggleMetadataEditor = (metadataEditor) => ({
  type: actions.userSettings.TOGGLE_METADATA_EDITOR,
  metadataEditor,
});

export const setUserSettings = (scope, id, data) => ({
  type: actions.userSettings.SET_USER_SETTINGS,
  payload: {
    scope,
    id,
    data,
  },
});

export const saveUserSettings = (scope, data, global = false) => ({
  type: global
    ? actions.userSettings.SAVE_USER_GLOBAL_SETTINGS
    : actions.userSettings.SAVE_USER_SETTINGS,
  payload: {
    scope,
    data,
  },
});

export const fetchUserSettings = (scope) => ({
  type: actions.userSettings.FETCH_USER_SETTINGS,
  payload: {
    scope,
  },
});
