import React, { PropsWithChildren, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Button, Dropdown, FormControl } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

interface MultiSelectItem {
    value: string;
    label: string;
    selected: boolean;
    disabled?: boolean;
}

export interface MultiSelectProps {
    onSubmit: (selectedItems: MultiSelectItem[]) => void;
    items: MultiSelectItem[];
    renderItem?: (item: MultiSelectItem) => React.ReactNode;
    toggleLabel: string;
    toggleIcon?: React.ReactNode;
    align?: "left" | "right";
    disabled?: boolean;
}

interface MultiSelectToggleProps {
    onClick: (e: React.MouseEvent) => void;
}

interface MultiSelectMenuProps extends PropsWithChildren {
    searchValue: string;
    onSearchValueChange: (value: string) => void;
    allChecked: boolean;
    onAllCheckedChange: () => void;
    className: string;
    style?: any;
}

export const MultiSelect = ({ items, onSubmit, renderItem, toggleLabel, toggleIcon, align = "left", disabled = false }: MultiSelectProps) => {
    const { t } = useTranslation("module");

    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [tempSelection, setTempSelection] = useState<MultiSelectItem[]>([]);

    const toggleRef = useRef<HTMLButtonElement>(null);
    const toggleRefWidth = toggleRef?.current?.getBoundingClientRect().width || 0;
    const toggleRefX = toggleRef?.current?.getBoundingClientRect().x || 0;

    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownMenuRef = useRef<any>(null);
    const dropdownMenuListRef = useRef<any>(null);
    const scrollPosition = useRef<number>(0);

    const [shouldRenderAbove, setShouldRenderAbove] = useState(false);

    const filteredTempSelection = useMemo(() => tempSelection
        .filter(item => !searchValue || item.label.toLowerCase().includes(searchValue.toLowerCase())),
        [tempSelection, searchValue]
    );

    const allChecked = useMemo(() => {
        // all checked should be dependent on the current selection
        return filteredTempSelection.every(i => i.selected);
    }, [filteredTempSelection]);

    useEffect(() => {
        const rect = dropdownMenuRef?.current?.getBoundingClientRect();
        if (!rect) return;
        const viewportHeight = window.innerHeight;

        // Check if element is fully visibly horizontally
        // if (rect?.left >= 0 && rect?.right <= viewportWidth) {
        //     console.log("Element is fully visible horizontally");
        // }

        // Check if element is fully visible vertically
        if (rect?.top >= 0 && rect?.bottom <= viewportHeight) {
            return
        }

        setShouldRenderAbove(true);
    }, [open])

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    }, [searchValue])

    useEffect(() => {
        if (dropdownMenuListRef?.current) {
            dropdownMenuListRef.current.scrollTop = scrollPosition.current;
        }
    }, [tempSelection])

    const handleScroll = () => {
        if (dropdownMenuListRef?.current) {
            scrollPosition.current = dropdownMenuListRef.current.scrollTop;
        }
    };

    const handleSave = () => {
        onSubmit(tempSelection);
        setOpen(false);
    }

    const renderItemLocal = (item: MultiSelectItem) => {
        if (!renderItem) {
            return <span>{item.label}</span>;
        }

        return renderItem(item);
    }

    const handleAllCheckedChange = () => {
        // should check or uncheck all items of the filteredSelection (only the items of tempSelection that are currently in the filteredSelection)
        setTempSelection(prev => prev.map(i => filteredTempSelection.some(f => f.value === i.value && !f.disabled) ? { ...i, selected: !allChecked } : i));
    }

    return (
        <Dropdown className="h-100" show={open} onToggle={(isOpen, e, metadata) => {
            if (metadata.source !== "select" && !(e.target as HTMLElement).id?.includes("multi-select-menu")) {
                setOpen(isOpen);
            }

            if (isOpen) {
                setTempSelection(items);
            }
        }}>
            <Dropdown.Toggle ref={toggleRef} as={forwardRef<any, MultiSelectToggleProps>(({ onClick }, ref) => (
                <Button
                    variant="light"
                    disabled={disabled || items.length === 0}
                    className={classNames("h-100 border shadow-none", { "active": open })}
                    ref={ref as any}
                    onClick={(e) => {
                        e.preventDefault();
                        onClick(e);
                    }}
                    data-testid="multi-select-toggle"
                >
                    {toggleIcon}
                    <span className="mr-2">{toggleLabel}</span>
                    <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} />
                </Button>
            ))}>
            </Dropdown.Toggle>
            <Dropdown.Menu
                searchValue={searchValue}
                onSearchValueChange={(val: string) => setSearchValue(val)}
                allChecked={allChecked}
                onAllCheckedChange={handleAllCheckedChange}
                className="p-0"
                ref={dropdownMenuRef}
                // Style needs to be here but ignored because of a Popper.js bug
                as={forwardRef<any, MultiSelectMenuProps>(({ children, className, style, searchValue, onSearchValueChange, allChecked, onAllCheckedChange }, ref) => (
                    <div className={className} ref={ref} style={{
                        position: "fixed",
                        top: shouldRenderAbove ? "auto" : `${(toggleRef?.current?.getBoundingClientRect().bottom || 0)}px`,
                        bottom: shouldRenderAbove ? `${window.innerHeight - (toggleRef?.current?.getBoundingClientRect().bottom || 0) + (toggleRef?.current?.getBoundingClientRect().height || 0)}px` : "auto",
                        left: align === "right" ? "unset" : `${(toggleRef?.current?.getBoundingClientRect().x || 0)}px`,
                        right: align === "right" ? `${window.innerWidth - toggleRefX - toggleRefWidth}px` : "unset",
                    }}>
                        <MenuWrapper>
                            <div className="mx-4 my-3 d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    onClick={onAllCheckedChange}
                                    checked={allChecked}
                                    readOnly
                                    className="mr-3"
                                    data-testid="multiselect-select-all-checkbox"
                                />
                                <FormControl
                                    id="multi-select-menu-search"
                                    ref={inputRef}
                                    placeholder={t("common.search", { defaultValue: "Search" })}
                                    onChange={(e) => onSearchValueChange(e.target.value)}
                                    value={searchValue}
                                />
                            </div>
                            <ul
                                ref={dropdownMenuListRef}
                                onScroll={handleScroll}
                                className="list-unstyled overflow-auto m-0"
                                style={{ maxHeight: "15rem" }}
                            >
                                {children}
                            </ul>
                            <div
                                id="multi-select-menu-actions"
                                className="d-flex align-items-center flex-wrap px-4 py-3"
                                style={{ gap: "0.5rem" }} // Utility class exists in newer bootstrap 5.x - we are using 4.x
                            >
                                <Button variant="light" onClick={() => setTempSelection(items)}>
                                    {t("button.restore", { defaultValue: "Restore Default" })}
                                </Button>
                                <Button variant="primary" onClick={handleSave} className="ml-auto">
                                    {t("button.save", { defaultValue: "Save" })}
                                </Button>
                            </div>
                        </MenuWrapper>
                    </div>
                ))}>
                {
                    filteredTempSelection.map((item) => (
                        <Dropdown.Item
                            key={`multi-select-item-${item.value}`}
                            onClick={() => setTempSelection(prev => prev.map(i => i.value === item.value ? { ...i, selected: !i.selected } : i))}
                            className="d-flex align-items-center"
                            disabled={item.disabled}
                        >
                            <input
                                type="checkbox"
                                checked={item.selected}
                                readOnly
                                className="mr-3"
                            />
                            {renderItemLocal(item)}
                        </Dropdown.Item>
                    ))
                }
            </Dropdown.Menu >
        </Dropdown >
    )
}

const MenuWrapper = styled.div`
    min-width: 300px;
    max-width: 500px;
`;