import extensionMapping from '../Constants/extensionContentTypeMap';
import GatewayApi from './conf/GatewayApi';
import CacheApi from './conf/CacheApi';

const gatewayApi = GatewayApi.build();

const getDocumentContentItem = (
  documentId,
  contentItemIndex,
  progressCallback = () => {}
) => {
  return gatewayApi
    .get(`/repository/doc/${documentId}/contentitem/${contentItemIndex}`, {
      responseType: 'blob',
      onDownloadProgress: (e) => {
        progressCallback(e.loaded);
      },
    })
    .then((response) => {
      return Promise.resolve({
        data: response.data,
        contentType: response.headers['content-type'],
      });
    });
};

const getDocumentContentItemRendition = (
  documentId,
  contentItemIndex,
  progressCallback = () => {}
) => {
  return gatewayApi
    .get(
      `/repository/doc/${documentId}/contentitem/${contentItemIndex}/rendition`,
      {
        responseType: 'blob',
        onDownloadProgress: (e) => {
          progressCallback(e.loaded);
        },
      }
    )
    .then((response) => {
      return Promise.resolve({
        data: response.data,
        contentType: response.headers['content-type'],
      });
    });
};

// const uploadDocumentContent = (
//   documentId,
//   file,
//   progressCallback = () => {}
// ) => {
//   let { type: contentType } = file;

//   if (contentType == null || contentType === '') {
//     contentType = extensionMapping(
//       file.path.substr(file.path.lastIndexOf('.') + 1)
//     );
//   }

//   return api
//     .put(`/repository/doc/${documentId}/content`, file, {
//       headers: {
//         'Content-Type': String(contentType).toLowerCase(),
//       },
//       onUploadProgress: (e) => {
//         if (e.lengthComputable) {
//           const progress = parseFloat((e.loaded / e.total) * 100);
//           progressCallback(progress.toFixed(2));
//         }
//       },
//     })
//     .then((response) => Promise.resolve(response.data));
// };

const uploadDocumentContentItems = (
  documentId,
  files,
  properties = [],
  progressCallback = () => {}
) => {
  const data = new FormData();
  data.append('properties', JSON.stringify(properties));

  for (let i = 0; i < Array.from(files).length; i++) {
    const file = files[i];
    data.append(`files`, file, file.name);
  }

  return gatewayApi
    .put(`/repository/document/${documentId}/content`, data, {
      responseType: 'json',
      onUploadProgress: (e) => {
        console.log('LOADDED PROGRESS CALLBACK', e.loaded);
        progressCallback(e.loaded);
      },
    })
    .then((response) => {
      CacheApi.clearCache('SEARCH');
      return Promise.resolve(response.data.id);
    });

  // const contentTypes = _(files)
  //   .map('type')
  //   .map((ct) => ct.toLowerCase())
  //   .uniq()
  //   .value();

  // const mh = new MultipartHelper(files, {
  //   properties: [],
  //   contentProperties: [
  //     {
  //       name: 'contentType',
  //       value: contentTypes.length === 1 ? contentTypes[0] : 'text/plain',
  //     },
  //   ],
  //   contentItemProperties: files.map((f) => ({
  //     properties: [
  //       {
  //         name: 'displayName',
  //         value: f.name,
  //       },
  //       {
  //         name: 'contentType',
  //         value: String(f.type).toLowerCase(),
  //       },
  //       {
  //         name: 'lastModified',
  //         value: f.lastModified || null,
  //       },
  //     ],
  //   })),
  // });

  // return mh.getData().then((data) =>
  //   api
  //     .put(`/repository/doc/${documentId}`, data, {
  //       headers: mh.getHeaders(),
  //       responseType: 'json',
  //       onUploadProgress: (e) => {
  //         if (e.lengthComputable) {
  //           const progress = parseFloat((e.loaded / e.total) * 100);
  //           progressCallback(progress.toFixed(2));
  //         }
  //       },
  //     })
  //     .then((response) => Promise.resolve(response.data.id))
  // );
};

const shareDocument = (documentId, resourceShare) => {
  return gatewayApi
    .put(`/repository/doc/${documentId}/share`, resourceShare)
    .then((response) => Promise.resolve(response.data));
};

const requestSignature = (documentId, signRequest) => {
  return gatewayApi
    .post(`/repository/doc/${documentId}/request-signature`, signRequest)
    .then((response) => Promise.resolve(response.data));
};

const checkOutDocument = (documentId, progressCallback = () => {}) => {
  return gatewayApi
    .put(
      `/repository/doc/${documentId}/check-out`,
      {},
      {
        responseType: 'blob',
        onDownloadProgress: (e) => {
          progressCallback(e.loaded);
        },
      }
    )
    .then((response) =>
      Promise.resolve({
        data: response.data,
        contentType: response.headers['content-type'],
      })
    );
};

const checkInDocument = (documentId, file, progressCallback = () => {}) => {
  let { type: contentType } = file;

  if (contentType == null || contentType === '') {
    contentType = extensionMapping(
      file.path.substr(file.path.lastIndexOf('.') + 1)
    );
  }

  const data = new FormData();
  data.append('file', file);

  return gatewayApi
    .put(`/repository/doc/${documentId}/check-in`, data, {
      headers: {
        'Content-Type': String(contentType).toLowerCase(),
      },
      onUploadProgress: (e) => {
        if (e.lengthComputable) {
          const progress = parseFloat((e.loaded / e.total) * 100);
          progressCallback(progress.toFixed(2));
        }
      },
    })
    .then((response) => Promise.resolve(response.data));
};

const getDocumentThumbnail = (documentId) => {
  return gatewayApi
    .get(`/repository/doc/${documentId}/thumbnail`, {
      responseType: 'blob',
    })
    .then((response) =>
      Promise.resolve({
        data: response.data,
        contentType: response.headers['content-type'],
      })
    );
};

const getDocumentCommentThumbnails = (documentId) => {
  return gatewayApi
    .get(`/repository/doc/${documentId}/comment-thumbnails`, {
      responseType: 'blob',
    })
    .then((response) =>
      Promise.resolve({
        data: response.data,
        contentType: response.headers['content-type'],
      })
    );
};

const getDocumentPreview = (documentId) => {
  return gatewayApi
    .get(`/repository/doc/${documentId}/preview`, {
      responseType: 'blob',
    })
    .then((response) =>
      Promise.resolve({
        data: response.data,
        contentType: response.headers['content-type'],
      })
    );
};

export default {
  uploadDocumentContentItems,
  shareDocument,
  requestSignature,
  getDocumentContentItem,
  getDocumentContentItemRendition,
  getDocumentThumbnail,
  getDocumentCommentThumbnails,
  getDocumentPreview,

  checkOutDocument,
  checkInDocument,
};
