import React, {
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import LocaleEditorContext from "../contexts/LocaleEditorContext";
import LocaleEditorProvider from "./LocaleEditorProvider";
import { FormControl, InputGroup } from "react-bootstrap";

interface TranslationEntryProps {
  language?: string;
  disabled?: boolean;
  value?: string;
  onUpdate?: (value: string) => void;
}

const TranslationEntry = ({
  language,
  disabled,
  value,
  onUpdate,
}: TranslationEntryProps) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <InputGroup className="mb-2">
      <InputGroup.Prepend>
        <InputGroup.Text>
          <b>{String(language).toUpperCase()}</b>
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        disabled={disabled}
        placeholder=""
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          if (onUpdate != null) {
            const v = e.target.value;
            onUpdate(v);
          }
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            //@ts-ignore
            e.target.blur();
          }
        }}
      />
    </InputGroup>
  );
};

interface ItemTranslateProps {
  scope?: string;
  translationPath?: string;
  disabled?: boolean;
}

const ItemTranslate = ({
  scope,
  translationPath,
  disabled,
}: ItemTranslateProps) => {
  const {
    getTranslations,
    translations,
    updateLanguageTranslation,
    languages,
  } = useContext(LocaleEditorContext);

  const moduleName = useMemo(
    () => String(translationPath).split(".")[0],
    [translationPath]
  );

  const translationKey = useMemo(
    () => String(translationPath).replace(moduleName, "").replace(/^\./, ""),
    [translationPath, moduleName]
  );

  useEffect(() => {
    if (scope != null) {
      getTranslations(scope, moduleName);
    }
  }, [getTranslations, moduleName, scope]);

  const getTranslation = useCallback(
    (language: string) => {
      //@ts-ignore
      return translations?.[moduleName]?.[translationKey]?.[language] || "";
    },
    [moduleName, translationKey, translations]
  );

  return (
    <div>
      {languages.map((language) => (
        <TranslationEntry
          language={language}
          disabled={disabled}
          value={getTranslation(language)}
          onUpdate={(value) => {
            if (scope != null) {
              updateLanguageTranslation(language, scope, moduleName, {
                key: translationKey,
                translation: value,
              });
            }
          }}
        />
      ))}
    </div>
  );
};

export interface QuickTranslatorProps {
  scope?: string;
  translationPath?: string;
  disabled?: boolean;
}

const QuickTranslator = ({
  scope,
  translationPath,
  disabled,
}: QuickTranslatorProps) => {
  return (
    <LocaleEditorProvider>
      <ItemTranslate
        scope={scope}
        translationPath={translationPath}
        disabled={disabled}
      />
    </LocaleEditorProvider>
  );
};

export default QuickTranslator;
