import { createContext } from "react";
import {
  TranslationItem,
  TranslationsLoadingState,
  TranslationsMap,
} from "../types";

export interface LocaleEditorContextValue {
  translations: TranslationsMap;
  loadingState: TranslationsLoadingState;
  languages: string[];
  getTranslations: (source: string, name: string) => void;
  updateLanguageTranslation: (
    languageCode: string,
    translationType: string,
    name: string,
    translation: TranslationItem
  ) => void;
  deleteTranslationKey: (
    translationsType: string,
    name: string,
    translationKey: string
  ) => void;
  exportTranslationsExcel: (translationsType: string, name: string) => void;
  importTranslationsExcel: (
    file: File,
    translationsType: string,
    name: string
  ) => void;
}

const LocaleEditorContext = createContext<LocaleEditorContextValue>({
  translations: {},
  loadingState: {},
  languages: [],
  getTranslations: () => {
    throw new Error("Context not available!");
  },
  updateLanguageTranslation: () => {
    throw new Error("Context not available!");
  },
  deleteTranslationKey: () => {
    throw new Error("Context not available!");
  },
  exportTranslationsExcel: () => {
    throw new Error("Context not available!");
  },
  importTranslationsExcel: () => {
    throw new Error("Context not available!");
  },
});

export default LocaleEditorContext;
