import Api from './conf/Api';
import CacheApi from './conf/CacheApi';

const api = Api.build();

const getLayout = (docAreaName, layoutName) =>
  api
    .get(
      `configuration/docarea/${docAreaName}/layouts/name/${layoutName}/clienttype/WEB/layouttype/HITLIST`
    )
    .then((response) => Promise.resolve(response.data));

const clearCommonCache = (prefix) => {
  CacheApi.clearCache(prefix);
};

export default {
  getLayout,
  clearCommonCache,
};
