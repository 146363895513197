/* eslint-disable func-names */
import {
  call,
  getContext,
  put,
  select,
  spawn,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {
  setTeamspaceFolders,
  setSelectedTeamspace,
  setTeamspacesLoading,
  reloadTeamspaces,
  fetchTeamspaceMembers,
  setTeamspaceMembersLoading,
} from '../../Actions/collaboration';
import * as actions from '../../Actions/types';
import { parseGatewayResources } from '../../Utils/documentUtils';
import { notify } from '../../Actions/ui';
import callApi from '../Effects/callApi';
import collaboration from '../../Api/collaboration';
import repositoryApi from '../../Api/repository';
import teamspaceFolder from '../../Constants/teamspaceFolder';
import { setProxyFolderId } from '../../Actions/repository';
import { generateFolderId } from '../../Utils';

export default function* teamspaceSaga() {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  yield takeLatest(
    [
      actions.teamspaces.RELOAD_TEAMSPACES,
      actions.teamspaces.CLEAR_TEAMSPACE_DATA,
    ],
    function* ({ type }) {
      if (type === actions.teamspaces.CLEAR_TEAMSPACE_DATA) {
        yield put(setProxyFolderId(null));
        return;
      }

      let teamspaceFolders = [];
      try {
        yield put(setTeamspacesLoading(true, 'Loading teamspace folders.'));
        const resourceKeys = yield callApi(
          collaboration.getTeamspaceFolders,
          docareaName
        );

        const folderIds = resourceKeys.map((rk) => rk.id);
        const teamspaceFolderId = generateFolderId(docareaName, -3);
        const results = yield callApi(
          repositoryApi.getDocumentsByIds,
          teamspaceFolderId,
          folderIds,
          teamspaceFolder.map((f) => f.query)
        );

        teamspaceFolders = parseGatewayResources(results, teamspaceFolder).map(
          (f) => ({
            ...f,
            displayname: f.displayname.split('@')[0],
          })
        );

        yield put(setTeamspaceFolders(teamspaceFolders));
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setTeamspacesLoading(false));
      }

      if (teamspaceFolders.length === 0) {
        return;
      }

      const foldersMap = teamspaceFolders.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: cur }),
        {}
      );

      yield takeLatest(
        actions.teamspaces.OPEN_TEAMSPACE_FOLDER,
        function* ({ folderId }) {
          const tsFolder = foldersMap[folderId];

          if (tsFolder != null) {
            const { owner } = tsFolder;

            try {
              yield put(
                setTeamspacesLoading(true, 'Opening teamspace folder.')
              );
              const teamspace = yield callApi(
                collaboration.getTeamspace,
                owner
              );
              yield put(setSelectedTeamspace(teamspace));
            } catch (err) {
              console.error(err);
            } finally {
              yield put(setTeamspacesLoading(false));
            }

            yield takeEvery(
              actions.teamspaces.ADD_TEAMSPACE_MEMBERS,
              function* ({ principalIds, callback }) {
                try {
                  yield put(
                    setTeamspaceMembersLoading(
                      true,
                      'Adding user(s) to teamspace as member(s).'
                    )
                  );
                  // FIXME: Teamspace cosmos-core update.
                  // yield callApi(usermanagement.putMembers, owner, principalIds);
                  yield put(fetchTeamspaceMembers());
                  yield spawn(callback);
                } catch (err) {
                  console.error(err);
                } finally {
                  yield put(setTeamspaceMembersLoading(false));
                }
              }
            );

            yield takeLatest(
              actions.teamspaces.DELETE_TEAMSPACE,
              function* ({ callback }) {
                try {
                  yield put(
                    setTeamspacesLoading(
                      true,
                      `Deleting ${tsFolder.displayname}.`
                    )
                  );
                  yield callApi(collaboration.deleteTeamspace, owner);
                  yield spawn(callback);
                  yield put(reloadTeamspaces());
                  yield put(
                    notify(
                      `Teamspace ${tsFolder.displayname} has been deleted.`,
                      'success'
                    )
                  );
                } catch (err) {
                  console.error(err);
                  let errorMessage = `Teamspace ${tsFolder.displayname} has not been deleted.`;
                  if (err.response.status === 403) {
                    errorMessage = `You do not have permissions to delete the team space ${tsFolder.displayname}.`;
                  }
                  yield put(notify(errorMessage, 'error'));
                } finally {
                  yield put(setTeamspacesLoading(false));
                }
              }
            );
            yield takeEvery(
              actions.teamspaces.DELETE_TEAMSPACE_MEMBERS,
              function* ({ principalIds }) {
                try {
                  yield put(
                    setTeamspaceMembersLoading(
                      true,
                      'Deleting teamspace member.'
                    )
                  );
                  // FIXME: Teamspace cosmos-core update.
                  // yield callApi(
                  //   usermanagement.deleteMembers,
                  //   owner,
                  //   principalIds
                  // );
                  yield put(fetchTeamspaceMembers());
                } catch (err) {
                  console.error(err);
                } finally {
                  yield put(setTeamspaceMembersLoading(false));
                }
              }
            );

            yield takeEvery(
              actions.teamspaces.FETCH_TEAMSPACE_MEMBERS,
              function* () {
                try {
                  yield put(
                    setTeamspaceMembersLoading(
                      true,
                      'Loading teamspace member.'
                    )
                  );

                  // FIXME: Teamspace cosmos-core update.
                  // const members = yield callApi(
                  //   usermanagement.getMembers,
                  //   owner
                  // );

                  // const users = yield callApi(
                  //   usermanagement.getUsersByIds,
                  //   members,
                  //   true
                  // );

                  // yield put(setTeamspaceMembers(users));
                } catch (err) {
                  console.error(err);
                } finally {
                  yield put(setTeamspaceMembersLoading(false));
                }
              }
            );
          }

          yield put(setProxyFolderId(folderId));
        }
      );
    }
  );

  yield takeLeading(
    actions.teamspaces.PERSIST_SELECTED_TEAMSPACE,
    function* ({ callback }) {
      const teamspace = yield select(
        (state) => state.collaboration.selectedTeamspace
      );

      try {
        yield put(setTeamspacesLoading(true, 'Creating new Teamspace.'));

        if (teamspace.principalId == null) {
          yield callApi(collaboration.postTeamSpace, {
            ...teamspace,
            domainName: 'Users',
          });
        } else {
          yield callApi(
            collaboration.putTeamspace,
            teamspace.principalId,
            teamspace
          );
        }

        yield put(reloadTeamspaces());
        yield spawn(callback);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setTeamspacesLoading(false));
      }
    }
  );
}
