import masterdata from './masterdata';
import { isCurrentDefaultPositionId } from 'cosmos-config/nql';

const TASK_HISTORY_SCOPE = 'TASK_HISTORY';

const getUserFinishedTasks = (
  docarea,
  processDefinition,
  properties,
  paging,
  orderBy
) =>
  masterdata.getMasterData(docarea, TASK_HISTORY_SCOPE, properties, {
    orWhere: {
      Task_Finisher: isCurrentDefaultPositionId(),
      Task_Assignment: isCurrentDefaultPositionId(),
    },
    where: {
      Process_Name: processDefinition,
    },
    orderBy,
    paging,
  });

const getUserFinishedTasksCount = (docarea, processDefinition) =>
  masterdata.getCountMasterData(docarea, TASK_HISTORY_SCOPE, {
    Task_Finisher: isCurrentDefaultPositionId(),
    Process_Name: processDefinition,
  });

const getByResourceId = (docarea, resourceId, properties) =>
  masterdata.getMasterData(docarea, TASK_HISTORY_SCOPE, properties, {
    where: { resourceId },
  });

const getByDocumentNumber = (docarea, documentNumber, properties) =>
  masterdata.getMasterData(docarea, TASK_HISTORY_SCOPE, properties, {
    where: {
      DocumentNumber: documentNumber,
    },
    orderBy: { Task_Enddate: 'asc' },
  });

const getUserInitiatedTasks = (
  docarea,
  processDefinition,
  properties,
  paginator
) =>
  masterdata.getMasterData(docarea, TASK_HISTORY_SCOPE, properties, {
    where: {
      Process_Starter: isCurrentDefaultPositionId(),
      Process_Name: processDefinition,
    },
    orderBy: { Task_Enddate: 'desc' },
    paging: paginator,
  });

export default {
  getUserFinishedTasks,
  getUserFinishedTasksCount,
  getByResourceId,
  getByDocumentNumber,
  getUserInitiatedTasks,
};
