import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Collapse } from "react-bootstrap";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import { faFolder, faSadTear } from "@fortawesome/free-regular-svg-icons";
import useProject from "../../hooks/useProject";
import { Project } from "../../contexts/ProjectContext";
import FolderTreeView, { FolderTreeItem } from "../FolderTreeView";

interface ProjectsListProps {
  projects: Project[];
  onSelect?: (project: Project) => void;
}

const ProjectsList = ({ projects, onSelect }: ProjectsListProps) => {
  return (
    <>
      {projects.map((project) => (
        <FolderTreeItem key={project.code}>
          <div
            onClick={() => {
              if (onSelect != null) {
                onSelect(project);
              }
            }}
            role="link"
            className="folder-item"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <FontAwesomeIcon tabIndex={0} icon={faFolder} className="mr-2" />
            {project.name}
          </div>
        </FolderTreeItem>
      ))}
    </>
  );
};

export interface ProjectSwitcherProps {
  onSelect?: (projectCode: string) => void;
}

const ProjectSwitcher = ({ onSelect }: ProjectSwitcherProps) => {
  // const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [showAllProjects, setShowAllProjects] = useState(false);

  const { openProject, projects, requestProjectAccess } = useProject();

  const { t } = useTranslation("module");

  const accessPermissionProjects = useMemo(() => {
    if (Array.isArray(projects)) {
      return projects.filter((p) => p.accessPermission);
    }

    return [];
  }, [projects]);

  useEffect(() => {
    if (accessPermissionProjects.length === 0) {
      setShowAllProjects(true);
    }
  }, [accessPermissionProjects]);

  const otherProjects = useMemo(() => {
    return projects.filter((p) => !p.accessPermission && p.available);
  }, [projects]);

  return (
    <FolderTreeView>
      <ProjectsList
        projects={accessPermissionProjects}
        onSelect={(project) => {
          openProject(project.code);

          if (onSelect != null) {
            onSelect(project.code);
          }
        }}
      />

      {accessPermissionProjects.length === 0 && (
        <div className="d-flex align-items-center mb-5 justify-content-center">
          <FontAwesomeIcon icon={faSadTear} size="4x" className="mr-3" />
          <span>You haven&apos;t got access to any project yet. </span>
        </div>
      )}

      {/* {isAdministrator && (
        <ul className="mb-0 mt-3">
          <li>
            <div
              onClick={() => setShowCreateProjectModal(true)}
              role="link"
              className="folder-item"
              tabIndex={0}
              onKeyDown={() => {}}
            >
              <FontAwesomeIcon tabIndex={0} icon={faPlus} className="mr-2" />
              {t("project.new_project")}
            </div>
          </li>
        </ul>
      )} */}

      {otherProjects.length > 0 && (
        <>
          <Collapse in={showAllProjects}>
            <div className="mt-3">
              <b>
                {t("module.request_title", { defaultValue: "Module Request" })}
              </b>
              <ProjectsList
                projects={otherProjects}
                onSelect={(project) => {
                  requestProjectAccess(project.code);
                }}
              />
            </div>
          </Collapse>
          <div className="text-center">
            <Button
              variant="link"
              onClick={() => setShowAllProjects((show) => !show)}
            >
              <span>
                {showAllProjects
                  ? t("module.hide_button", { defaultValue: "Module Hide" })
                  : t("module.show_button", { defaultValue: "Module Show" })}
              </span>
              <FontAwesomeIcon
                className="ml-2"
                icon={showAllProjects ? faAngleDoubleUp : faAngleDoubleDown}
              />
            </Button>
          </div>
        </>
      )}

      {/* <CreateProjectModal
        show={showCreateProjectModal}
        onClose={() => setShowCreateProjectModal(false)}
      /> */}
    </FolderTreeView>
  );
};

export default ProjectSwitcher;
