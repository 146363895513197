import { AuthenticationResult } from "@azure/msal-browser";
import { CosmosSession } from "../contexts/SessionContext";

const COSMOS_SSO_DEFAULTS_KEY = "cosmos-sso-defaults";

export type CosmosAuthState = {
  domainName?: string;
  referer?: string
}

const getCosmosSsoDefaults = (
  providedSession?: Partial<CosmosSession>
): CosmosSession => {
  let params = new URL(document.location.toString()).searchParams;
  const localStorageData = localStorage.getItem(COSMOS_SSO_DEFAULTS_KEY);

  if (localStorageData != null) {
    try {
      const persistedSession = JSON.parse(localStorageData) as CosmosSession;

      return {
        accessToken:
          providedSession?.accessToken || persistedSession?.accessToken || null,
        tokenType:
          providedSession?.tokenType || persistedSession?.tokenType || "Bearer",
        domainName:
          providedSession?.domainName ||
          persistedSession?.domainName ||
          "Users",
        username:
          providedSession?.username ||
          persistedSession?.username ||
          params.get("msalLoginHint"),
      };
    } catch (err) {
      console.error(err);
    }
  }

  return {
    accessToken: providedSession?.accessToken || null,
    tokenType: providedSession?.tokenType || "Bearer",
    domainName: providedSession?.domainName || "Users",
    username: providedSession?.username || null,
  };
};

const updateCosmosSsoDefaults = (cosmosSession: CosmosSession) => {
  const persistSession = (cs: CosmosSession) => {
    localStorage.setItem(COSMOS_SSO_DEFAULTS_KEY, JSON.stringify(cs));
  };

  if (cosmosSession.tokenType === "Basic") {
    persistSession(cosmosSession);
  } else {
    persistSession({
      ...cosmosSession,
      accessToken: null,
    });
  }
};

const handleTokenResponse = (tokenResponse: AuthenticationResult) => {
  const state: CosmosAuthState = tokenResponse.state != null ? JSON.parse(tokenResponse.state) : {} 

  return Promise.resolve(
    getCosmosSsoDefaults({
      tokenType: tokenResponse.tokenType,
      accessToken: tokenResponse.idToken,
      domainName: state.domainName,
      username: tokenResponse.account.username,
    })
  );
};

const clearCosmosSsoDefaults = () => {
  localStorage.removeItem(COSMOS_SSO_DEFAULTS_KEY);
};

const sessionPersistanceService = {
  getCosmosSsoDefaults,
  updateCosmosSsoDefaults,
  clearCosmosSsoDefaults,
  handleTokenResponse,
};

export default sessionPersistanceService;
