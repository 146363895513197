import React, { useState } from "react";
import { ColumnInstance, HeaderGroup, IdType } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDown,
  faSortAmountDownAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { PancoTooltip } from "cosmos-components";
import ColumnInserter from "./ColumnInserter";
import {
  ColumnResizer,
  DraggableHeader,
  HeaderCell,
  HeaderHideButton,
  TableRow,
} from "../tableFragments";
import systemColumns from "./systemColumns";
import Resource from "../../types/resource";
import { useTranslation } from "react-i18next";

export type OrderBy = {
  [key: string]: "asc" | "desc";
};

export type OnOrderByCallback = (orderBy: OrderBy) => void;

export interface RepositoryTableHeaderProps {
  headerGroup: HeaderGroup<Resource>;
  editMode?: boolean;
  onOrderByToggle?: (columnName: string) => void;
  allColumns?: ColumnInstance<Resource>[];
  selectedRowIds?: IdType<Resource>[];
}

const RepositoryTableHeader = ({
  headerGroup,
  editMode,
  onOrderByToggle,
  allColumns,
}: RepositoryTableHeaderProps) => {
  const { t } = useTranslation("property");
  const [draggedColumn, setDraggedColumn] = useState<string | null>(null);

  const renderColumnHeader = (header: HeaderGroup<Resource>) => {
    //@ts-ignore
    return header.translation ? t(header.translation) : header.render("Header");
  };
  return (
    <TableRow>
      {headerGroup.headers.map((header) => (
        <HeaderCell
          condensed
          key={header.id}
          width={header.width}
          systemColumn={systemColumns.includes(header.id)}
          action={header.canSort && !editMode}
        >
          {editMode ? (
            <div className="h-100">
              <DraggableHeader
                draggable={editMode}
                onDragStart={() => setDraggedColumn(header.id)}
                onDragEnter={() => {
                  if (draggedColumn != null) {
                    header.replacePosition(draggedColumn);
                  }
                }}
              >
                {renderColumnHeader(header)}
              </DraggableHeader>

              <HeaderHideButton
                variant="link"
                className="text-danger"
                onClick={() => {
                  header.toggleHidden(true);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </HeaderHideButton>

              {header.canResize && (
                <ColumnResizer
                  {...header.getResizerProps({
                    active: header.isResizing,
                    onDragStart: (e: React.DragEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                    },
                  })}
                />
              )}

              <ColumnInserter
                onInsert={(columnId) => {
                  header.replacePosition(columnId);
                }}
                columnNames={(allColumns || [])
                  .filter((c) => !c.isVisible)
                  .map((c) => c.id)}
              />
            </div>
          ) : (
            <div
              onClick={() => {
                if (
                  header.canSort &&
                  (!systemColumns.includes(header.id) ||
                    "fileextensionicon" === header.id)
                ) {
                  if (onOrderByToggle != null) {
                    onOrderByToggle(header.id);
                  }
                }
              }}
            >
              <span className="mr-2">
                {/* @ts-ignore */}
                {header.description != null ? (
                  //@ts-ignore
                  <PancoTooltip text={header.description}>
                    {renderColumnHeader(header)}
                  </PancoTooltip>
                ) : (
                  renderColumnHeader(header)
                )}
              </span>
              <span>
                {header.isSorted && (
                  <FontAwesomeIcon
                    icon={
                      header.isSortedDesc
                        ? faSortAmountDown
                        : faSortAmountDownAlt
                    }
                  />
                )}
              </span>
            </div>
          )}
        </HeaderCell>
      ))}
    </TableRow>
  );
};

export default RepositoryTableHeader;

// export default React.memo(RepositoryTableHeader, (prevProps, nextProps) => {
//   return false;

// const compareHeaders = (path: string) => {
//   const mapTo = (hdrs: HeaderGroup<Resource>[]) => {
//     return _.sortBy(hdrs, "id").map((h) => _.get(h, path));
//   };
//   return _.isEqual(
//     mapTo(prevProps.headerGroup.headers),
//     mapTo(nextProps.headerGroup.headers)
//   );
// };

// return (
//   _.isEqual(prevProps.selectedRowIds, nextProps.selectedRowIds) &&
//   compareHeaders("isSorted") &&
//   compareHeaders("isSortedDesc") &&
//   !nextProps.editMode &&
//   prevProps.headerGroup.headers.length ===
//     nextProps.headerGroup.headers.length
// );
// });
