import { put, takeEvery, retry, takeLeading, call } from "redux-saga/effects";
import { parseExcelFile } from "../utils/fileUtils.ts";
import projectApi from "../apis/projectApi";
import projectSlice from "../slices/projectSlice";
import * as actions from "../contants/projectConstants";
import {
  fetchAllProjectMembers,
  fetchProjectAccessRequests,
} from "../actions/projectActions";
import callApi from "./effects/callApi";
import _ from "lodash";
import complete from "./effects/complete";

function* fetchMembersOfProject(projectCode, action) {
  try {
    yield put(projectSlice.actions.setLoading(true));

    const users = yield retry(3, 5000, projectApi.getUsers, projectCode);
    const groupMap = _(users)
      .filter("group")
      .keyBy("name")
      .mapValues(() => [])
      .value();

    const userMap = users.reduce(
      (acc, user) => ({
        ...acc,
        [user.groupName]: [...(acc[user.groupName] || []), user],
      }),
      groupMap
    );

    yield put(projectSlice.actions.setProjectMembers(userMap));

    yield complete(action);
    return userMap;
  } catch (err) {
    console.error(err);

    return {};
  } finally {
    yield put(projectSlice.actions.setLoading(false));
  }
}

function* projectMembersSaga(projectCode) {
  yield takeEvery(
    actions.FETCH_ALL_PROJECT_MEMBERS,
    fetchMembersOfProject,
    projectCode
  );

  yield put(fetchAllProjectMembers());

  yield takeEvery(actions.ADD_PROJECT_MEMBER, function* (action) {
    const { groupName, principalId } = action.payload;
    try {
      yield put(projectSlice.actions.setLoading(true));
      yield callApi(
        projectApi.addProjectMember,
        projectCode,
        groupName,
        principalId
      );

      yield complete(action);
    } catch (err) {
      console.error(err);
      // yield put(notify(`Failed with reason: ${err.message}`, "error"));
    }

    yield put(projectSlice.actions.setLoading(false));
    yield put(fetchAllProjectMembers());
    yield put(fetchProjectAccessRequests());
  });

  yield takeEvery(actions.IMPORT_PROJECT_MEMBERS, function* (action) {
    const { groupName, file } = action.payload;

    try {
      yield put(projectSlice.actions.setLoading(true));

      const parsedMembers = yield call(parseExcelFile, file);
      const principalIds = parsedMembers.filter(member => member.principalId).map(member => member.principalId);

      if (!principalIds.length) {
        yield put(projectSlice.actions.setImportValidationErrors([
          "The excel file does not contain any principal ids. Please check the file and try again."
        ]))
        throw new Error();
      } else {
        yield callApi(
          projectApi.addProjectMembers,
          projectCode,
          groupName,
          principalIds
        );
      }

      yield complete(action);
    } catch (err) {
      console.error(err);
    } finally {
      yield put(projectSlice.actions.setLoading(false));
      yield put(fetchAllProjectMembers());
      yield put(fetchProjectAccessRequests());
    }

  })

  yield takeLeading(actions.INVITE_PROJECT_MEMBER, function* (action) {
    const { email, groupName, domainName } = action.payload;

    try {
      yield put(projectSlice.actions.setLoading(true));
      yield callApi(
        projectApi.inviteUser,
        projectCode,
        domainName,
        groupName,
        email
      );

      yield complete(action);
    } catch (err) {
      console.error(err);
    }

    yield put(projectSlice.actions.setLoading(false));
    yield put(fetchAllProjectMembers());
  });

  yield takeLeading(actions.CREATE_PROJECT_MEMBER, function* (action) {
    const { user, groupName, domainName } = action.payload;

    try {
      yield put(projectSlice.actions.setLoading(true));
      yield callApi(
        projectApi.createUser,
        projectCode,
        domainName,
        groupName,
        user
      );

      yield complete(action);
    } catch (err) {
      console.error(err);
    }

    yield put(projectSlice.actions.setLoading(false));
    yield put(fetchAllProjectMembers());
  });
}

export default projectMembersSaga;
