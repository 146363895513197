import { Card, CardColumns } from "react-bootstrap";
import { propertyType } from "cosmos-config/generator";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Property } from "cosmos-config/lib/property/property";
import { Group } from "cosmos-config/lib/group/group";
import { Set } from "cosmos-config/lib/set/set";

const propCharacteristics = [
  {
    label: "Selectable",
    mask: {
      type: propertyType.SELECT,
    },
  },
  {
    label: "Suggestive",
    mask: {
      type: propertyType.SUGGEST,
    },
  },
  {
    label: "Selection any User",
    mask: {
      type: propertyType.PRINCIPAL_SELECT,
    },
  },
  {
    label: "Selection Project Member",
    mask: {
      type: propertyType.MEMBER_SELECT,
    },
  },
  {
    label: "Yes-No",
    mask: {
      type: propertyType.YESNO,
    },
  },
  {
    label: "Selectable Resource",
    mask: {
      type: propertyType.RESOURCE_SELECT,
    },
  },
  {
    label: "Date Selection",
    mask: {
      type: propertyType.DATE,
    },
  },
  {
    label: "Numeric",
    mask: {
      type: propertyType.NUMERIC,
    },
  },
  {
    label: "Multi Value",
    mask: {
      multiple: true,
    },
  },
  {
    label: "Editable",
    mask: {
      editable: true,
    },
  },
  {
    label: "Sortable",
    mask: {
      sortable: true,
    },
  },
  {
    label: "Table Column",
    mask: {
      tableColumn: true,
    },
  },
  {
    label: (property: Property) => `Depending on ${property.dependencyLabel}`,
    mask: {
      dependency: (v: any) => v != null,
    },
  },
  {
    label: "Task History Column",
    mask: {
      taskHistory: true,
    },
  },
  {
    label: "Insertable",
    mask: {
      addingOptionsAllowed: true,
    },
  },
];

export interface PropertySelectorProps {
  properties?: (Property | Group)[];
  onSelect?: (propertyName: string) => void;
}

const PropertySelector = ({ properties, onSelect }: PropertySelectorProps) => {
  const getCharacteristics = (property: Property) => {
    const customizer = (objValue: any, srcValue: (v: any) => boolean) => {
      if (typeof srcValue === "function") {
        return srcValue(objValue);
      }

      return _.isEqual(objValue, srcValue);
    };

    return propCharacteristics
      .filter((char) => _.isMatchWith(property, char.mask, customizer))
      .map((char) => {
        const { label } = char;

        if (typeof label === "function") {
          return label(property);
        }

        return label;
      });
  };

  const renderCharacteristics = (property: Property | Set | Group) => {
    if ((property as Set).set) {
      return _((property as Set).properties)
        .flatMap(getCharacteristics)
        .uniq()
        .join(", ");
    }

    return getCharacteristics(property as Property).join(", ");
  };

  const renderProperties = (properties: (Property | Group | Set)[]) => {
    return properties.map((property) => {
      if ((property as Group).group) {
        return (
          <div>
            <h5>{property.label}</h5>
            {renderProperties((property as Group).properties)}
          </div>
        );
      }

      return (
        <Card className="border-primary">
          <Card.Body
            tabIndex={0}
            onKeyDown={() => {}}
            role="button"
            onClick={() => {
              if (onSelect != null) {
                onSelect(property.name);
              }
            }}
          >
            <div>
              <span className="text-primary">
                {(property as Set).set && (
                  <FontAwesomeIcon
                    icon={faBoxOpen}
                    className="mr-2"
                    size="sm"
                  />
                )}
                {property.label}
              </span>
              <small className="ml-2 text-muted">
                (
                {(property as Set).set
                  ? (property as Set).properties.map((p) => p.name).join(", ")
                  : property.name}
                )
              </small>
            </div>

            {property.description}

            <hr />

            <i>{renderCharacteristics(property)}</i>
          </Card.Body>
        </Card>
      );
    });
  };

  return (
    <div>
      <CardColumns>{renderProperties(properties || [])}</CardColumns>;
    </div>
  );
};

export default PropertySelector;
