import {
  call,
  put,
  takeEvery,
  all,
  takeLeading,
  select,
} from "redux-saga/effects";
import _, { pick } from "lodash";
import { utils, writeFile } from "xlsx";
import { DateTime } from "luxon";
import callApi from "./effects/callApi";
import projectApi from "../apis/projectApi";
import {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from "cosmos-config/utils";
import projectSlice from "../slices/projectSlice";
import spocProperties from "../contants/spoc";
import * as actions from "../contants/projectConstants";
import { fetchProjectSpocs } from "../actions/projectActions";
import { getOpenedProjectCode, getProjectSpocs } from "../selectors/projectSelector";
import complete from "./effects/complete";

export default function* spocSaga(projectCode) {
  yield takeEvery(actions.FETCH_PROJECT_SPOCS, function* (action) {
    try {
      // yield put(
      //   projectSlice.actions.setLoad(true, 'Loading all single person of contact.')
      // );
      const items = yield callApi(projectApi.getProjectSpocs, projectCode);
      const spocs = items.map((item) =>
        resourceCorrectionOfGateway(item, spocProperties)
      );

      yield put(projectSlice.actions.setSpocs(spocs));
      yield complete(action);
    } catch (err) {
      console.error(err);
    } finally {
      // yield put(setUsermanagementLoading(false));
    }
  });

  yield put(fetchProjectSpocs());

  yield takeLeading(
    [
      actions.CREATE_PROJECT_SPOC,
      actions.CREATE_PROJECT_SPOC_REQUEST,
      actions.TOGGLE_ACTIVE_PROJECT_SPOC,
      actions.UPDATE_PROJECT_SPOC,
    ],
    function* (action) {
      const { spoc, spocId } = action.payload;

      try {
        // yield put(
        //   setUsermanagementLoading(true, 'Performing action on SPOC data.')
        // );

        switch (action.type) {
          case actions.CREATE_PROJECT_SPOC:
            yield callApi(
              projectApi.createProjectSpoc,
              projectCode,
              resourceCorrectionForGateway(spoc, spocProperties)
            );
            break;
          case actions.CREATE_PROJECT_SPOC_REQUEST:
            yield callApi(
              projectApi.createProjectSpocRequest,
              projectCode,
              resourceCorrectionForGateway(spoc, spocProperties)
            );
            break;
          case actions.UPDATE_PROJECT_SPOC:
            yield callApi(
              projectApi.updateProjectSpoc,
              projectCode,
              spoc.id,
              resourceCorrectionForGateway(spoc, spocProperties)
            );
            break;
          case actions.TOGGLE_ACTIVE_PROJECT_SPOC:
            yield callApi(
              projectApi.toggleActiveProjectSpoc,
              projectCode,
              spocId
            );
            break;
          default:
            break;
        }

        yield complete(action);
      } catch (err) {
        console.error(err);
      } finally {
        // yield put(setUsermanagementLoading(false));
        yield put(fetchProjectSpocs());
      }
    }
  );

  yield takeLeading(actions.DELETE_PROJECT_SPOCS, function* (action) {
    const { spocIds } = action.payload;

    // yield put(
    //   setUsermanagementLoading(true, 'Deleting single person of contact.')
    // );

    const deleteSagas = spocIds.map((spocId) =>
      call(function* () {
        try {
          yield callApi(projectApi.deleteProjectSpoc, projectCode, spocId);
        } catch (err) {
          console.error(err);
        }
      })
    );

    yield all(deleteSagas);
    // yield put(setUsermanagementLoading(false));
    yield put(fetchProjectSpocs());
    yield complete(action);
  });

  yield takeLeading(actions.EXPORT_PROJECT_SPOCS_EXCEL, function* (action) {
    const openedProjectCode = yield select(getOpenedProjectCode);
    const spocPropertyNames = spocProperties.map(prop => prop.name);

    let spocs = yield select(getProjectSpocs);
    if (action.payload) {
      const { spocIds } = action.payload;
      spocs = spocs.filter(spoc => spocIds.includes(spoc.id));
    }

    const data = spocs.map(spoc => pick(spoc, spocPropertyNames)).map(spoc => {
      return _.mapValues(spoc, (value, key) => {
        if (Array.isArray(value)) {
          return value.join(",");
        }
        return value;
      })
    });

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);

    const filename = `${openedProjectCode}_SPOCs_${DateTime.now().toSQLDate()}.xlsx`;
    utils.book_append_sheet(wb, ws, `${openedProjectCode}_SPOCs`);
    writeFile(wb, filename);
    yield complete(action);
  });
}
