import React, { useEffect, useState } from "react";
import { ListGroup, Button, Modal, CloseButton } from "react-bootstrap";
import IssueDetails from "./IssueDetails";
import styled from "styled-components";

export interface ValuesetIssuesProps {
  issues: ValuesetIssue[];
  propertyName: string;
  onResolve?: () => void;
  loading?: boolean;
}

export interface ValuesetIssue {
  value: string;
  resourceType: 1 | 2 | 3;
  count: number;
}

const FullScreenModal = styled(Modal)`
  & > .modal-dialog {
    max-width: 90vw;
  }
`;

const ValuesetIssues = ({
  issues,
  propertyName,
  onResolve,
  loading,
}: ValuesetIssuesProps) => {
  const [selectedIssue, setSelectedIssue] = useState<ValuesetIssue | null>();

  const renderIssueItem = (issue: ValuesetIssue, idx: number) => (
    <div>
      <small className="text-muted">{idx + 1}.</small>
      <span className="text-danger">
        Value <b>{issue.value}</b> is missing
      </span>
      <span className="text-muted"> - {issue.count} document(s) affected</span>
    </div>
  );

  useEffect(() => {
    setSelectedIssue((currentSelection) => {
      if (currentSelection == null) {
        return null;
      }

      const idx = issues.findIndex((i) => i.value === currentSelection.value);

      if (idx === -1) {
        return null;
      }

      return currentSelection;
    });
  }, [issues]);

  return (
    <>
      <ListGroup variant="flush">
        {issues
          .sort((a, b) => b.count - a.count)
          .map((issue, idx) => (
            <ListGroup.Item className="d-flex py-2" key={issue.value}>
              {renderIssueItem(issue, idx)}
              <Button
                variant="link"
                className="p-0 ml-auto"
                onClick={() => {
                  setSelectedIssue(issue);
                }}
              >
                Details
              </Button>
            </ListGroup.Item>
          ))}
      </ListGroup>

      <FullScreenModal
        show={selectedIssue != null}
        onClose={() => setSelectedIssue(null)}
      >
        <Modal.Header>
          <Modal.Title>Valueset Issue '{selectedIssue?.value}'</Modal.Title>
          <CloseButton onClick={() => setSelectedIssue(null)} />
        </Modal.Header>
        <Modal.Body>
          {selectedIssue != null && (
            <IssueDetails
              propertyName={propertyName}
              issue={selectedIssue}
              onResolve={onResolve}
            />
          )}
        </Modal.Body>
      </FullScreenModal>
    </>
  );
};

export default ValuesetIssues;
