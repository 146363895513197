import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, CloseButton } from "react-bootstrap";
import SimpleUniversalForm from "../../../components/SimpleUniversalForm";
import resourcePermissions from "../constants/resourcePermissions";
import AccessManagementContext from "../contexts/AccessManagementContext";
import { LoadingOverlay } from "cosmos-components";
import { Permission } from "../constants/Permission";

export interface AccessEditModalProps {
  show?: boolean;
  onClose: () => void;
  permission: any;
}

const AccessEditModal = ({
  show,
  onClose,
  permission,
}: AccessEditModalProps) => {
  const { loading, updatePermission } = useContext(AccessManagementContext);
  const [innerPermission, setInnerPermission] =
    useState<Permission>(permission);
  const { t } = useTranslation("module");

  useEffect(() => {
    setInnerPermission(permission);
  }, [permission]);

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>{t("header.edit_permission")}</Modal.Title>
        <CloseButton onClick={onClose} className="close-icon" />
      </Modal.Header>
      <Modal.Body>
        <LoadingOverlay loading={loading}>
          <SimpleUniversalForm
            resource={innerPermission}
            properties={resourcePermissions}
            onUpdateResource={(id, name, value) => {
              setInnerPermission((p) => ({
                ...p,
                [name]: value,
              }));
            }}
          />
        </LoadingOverlay>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right">
          <Button
            disabled={loading}
            className="mr-2"
            onClick={() => {
              if (innerPermission.id != null) {
                updatePermission(innerPermission.id, innerPermission).then(() =>
                  onClose()
                );
              }
            }}
          >
            {t("button.save")}
          </Button>
          <Button variant="link" onClick={onClose}>
            {t("button.close")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AccessEditModal;
