import React from "react";
import useProjectCommands from "../hooks/useProjectCommands";
import GroupImportMembersModal from "../modules/user-management/components/GroupImportMembersModal";
import GroupAddMemberModal from "./GroupAddMemberModal";
import RequestAccessModal from "./docarea/RequestAccessModal";
import GroupEditModal from "./docarea/GroupEditModal";

const ProjectCommandModals = () => {
  const {
    getAddProjectMemberModalProps,
    getImportProjectMembersModalProps,
    getRequestProjectAccessModalProps,
    getGroupEditModalProps,
  } = useProjectCommands();

  return (
    <>
      <GroupAddMemberModal {...getAddProjectMemberModalProps()} />
      <GroupImportMembersModal {...getImportProjectMembersModalProps()} />
      <RequestAccessModal {...getRequestProjectAccessModalProps()} />
      <GroupEditModal {...getGroupEditModalProps()} />
    </>
  );
};

export default ProjectCommandModals;
