import { Button } from "react-bootstrap";
import React, { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { uuidv4 } from "cosmos-config/utils";
import ContentLayoutContext, { componentType } from "./ContentLayoutContext";
import sidebarGridMapping from "./sidebarGridMapping";
import styled, { css } from "styled-components";
import ContentLayoutColumn from "./ContentLayoutColumn";
import { shadeColor } from "../../utils";

const SidebarContent = styled.div`
  height: 100%;
`;

const PrimaryButton = styled(Button)`
  color: ${(props) => props.theme.primary};
`;

const CollapseToggleBar = styled.div`
  background-color: ${(props) => shadeColor(0.07, props.theme.background)};
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: right;
`;

type SidebarColumnProps = {
  collapsible?: boolean;
  collapsed?: boolean;
  rightPosition?: boolean;
};

const collapsibleSidebarStyle = css<SidebarColumnProps>`
  ${SidebarContent} {
    padding-bottom: 32px;
    opacity: 1;
    transition: opacity 0.1s;
  }

  ${(props) =>
    props.collapsed &&
    css`
      flex-basis: 32px;
      overflow: hidden;

      ${SidebarContent} {
        opacity: 0;
      }
    `}
`;

export const SidebarColumn = styled(ContentLayoutColumn)<SidebarColumnProps>`
  box-shadow: 0px 0px 8px 0px
    ${(props) => shadeColor(0.18, props.theme.background)};
  background-color: ${(props) => shadeColor(0.02, props.theme.background)};
  min-height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: flex-basis 0.25s ease-in-out;
  overflow-x: hidden;

  ${(props) => props.collapsible && collapsibleSidebarStyle}

  ${(props) =>
    props.rightPosition &&
    css`
      ${CollapseToggleBar} {
        /* justify-content: left; */
        transform: scale(-1, 1);
      }
    `}
`;

export interface ContentLayoutSidebarProps {
  children?: React.ReactNode;
  className?: string;
  hideXs?: boolean;
  collapsed?: boolean;
  onCollapse?: (collapsed: boolean) => void;
  collapsible?: boolean;
}

const ContentLayoutSidebar = ({
  children,
  className,
  hideXs,
  collapsed,
  onCollapse,
  collapsible,
}: ContentLayoutSidebarProps) => {
  const { registerComponent, unregisterComponent } =
    useContext(ContentLayoutContext);

  const uuid = useMemo(() => uuidv4(), []);
  const [rightPosition, setRightPosition] = useState<boolean>(false);

  useEffect(() => {
    registerComponent(uuid, componentType.SIDEBAR).then((order) =>
      setRightPosition(order > 0)
    );

    return () => unregisterComponent(uuid);
  }, [registerComponent, unregisterComponent, uuid]);

  return (
    <SidebarColumn
      {...sidebarGridMapping}
      collapsible={collapsible}
      collapsed={collapsed}
      rightPosition={rightPosition}
      className={classNames(className, {
        "d-none": hideXs,
        "d-sm-block": hideXs,
      })}
    >
      <SidebarContent>{children}</SidebarContent>

      {collapsible && (
        <CollapseToggleBar>
          <PrimaryButton
            variant="link"
            onClick={() => {
              if (onCollapse != null) {
                onCollapse(!collapsed);
              }
            }}
          >
            <FontAwesomeIcon
              icon={collapsed ? faAngleDoubleRight : faAngleDoubleLeft}
            />
          </PrimaryButton>
        </CollapseToggleBar>
      )}
    </SidebarColumn>
  );
};

export default ContentLayoutSidebar;
