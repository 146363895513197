import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ProjectContext, { Project } from "../contexts/ProjectContext";
import {
  getOpenedProject,
  getOpenedProjectCode,
  getProjectMembers,
  getProjectReducerState,
  getProjects,
} from "../selectors/projectSelector";
import useCoreSelector from "../hooks/useCoreSelector";
import useCoreDispatch from "../hooks/useCoreDispatch";
import projectSlice from "../slices/projectSlice";
import { fetchProjects } from "../actions/projectActions";
import PropertiesProvider from "./PropertiesProvider";
import ColumnsProvider from "./ColumnsProvider";
import projectService from "../services/projectService";
import useConfigProvider, {
  BusinessProcessPropertiesResponse,
} from "../hooks/useConfigProvider";
import { Group } from "cosmos-config/lib/group/group";
import { Project as ProjectSpec } from "cosmos-config/lib/project/project";
import { CosmosCoreRootState } from "../store";
import useCurrentUser from "../hooks/useCurrentUser";
import useProjectCommands from "../hooks/useProjectCommands";
import ProjectCommandModals from "../components/ProjectCommandModals";
import { useQuery } from "@tanstack/react-query";
import projectPortalsApi from "../apis/project/projectPortalsApi";
import ProjectCompetenceProvider from "./ProjectCompetenceProvider";

interface ProjectProviderProps {
  children: React.ReactNode;
  defaultProjectCode: string;
  onProjectOpen: (project: Project) => void;
}

const ProjectProvider = ({
  children,
  defaultProjectCode,
  onProjectOpen,
}: ProjectProviderProps) => {
  const dispatch = useCoreDispatch();
  const projects = useCoreSelector(getProjects);
  const project = useCoreSelector(
    getOpenedProject,
    (a, b) => a?.code === b?.code
  );
  const {
    loadProjectSpec,
    loadProjectProperties,
    loadProjectTemplates,
    loadBusinessProcessProperties,
    loadBusinessProcessSpecimen,
  } = useConfigProvider();

  const { authenticated } = useCurrentUser();
  const {
    addProjectMember,
    importProjectMembers,
    requestProjectAccess,
    editProjectGroup,
    createProjectGroup,
  } = useProjectCommands();

  const reducerState = useCoreSelector(getProjectReducerState);
  const projectCode = useCoreSelector(getOpenedProjectCode);
  const loading = useCoreSelector(
    (state: CosmosCoreRootState) => state.project.loading
  );

  const [loadingProperties, setLoadingProperties] = useState<boolean>(false);
  const [configuration, setConfiguration] = useState<ProjectSpec | null>(null);
  const [projectProperties, setProjectProperties] = useState<Group[]>([]);
  const [propertiesProjectCode, setPropertiesProjectCode] = useState<
    string | null
  >(null);
  const [bpPropertiesMap, setBpPropertiesMap] = useState<
    Record<string, Group[]>
  >({});
  const [projectSpec, setProjectSpec] = useState<ProjectSpec | null>(null);

  useEffect(() => {
    if (authenticated) {
      dispatch(fetchProjects());
    }
  }, [dispatch, authenticated]);

  const reloadSpec = useCallback(() => {
    if (projectCode == null) {
      return;
    }

    loadProjectSpec(projectCode).then((spec) => setProjectSpec(spec));
  }, [loadProjectSpec, projectCode]);

  useEffect(() => {
    reloadSpec();
  }, [loadProjectSpec, projectCode, reloadSpec]);

  const reloadProperties = useCallback(() => {
    if (projectCode != null) {
      setLoadingProperties(true);
      return loadProjectProperties(projectCode)
        .then(({ properties }) => {
          setProjectProperties(properties);
          return Promise.resolve(properties);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setLoadingProperties(false));
    }
  }, [loadProjectProperties, projectCode]);

  useEffect(() => {
    let isSubscribed = true;

    if (projectCode != null) {
      setLoadingProperties(true);
      loadProjectProperties(projectCode)
        .then(({ properties, projectCode }) => {
          if (isSubscribed) {
            setProjectProperties(properties);
            setPropertiesProjectCode(projectCode);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setLoadingProperties(false));
    }

    return () => {
      isSubscribed = false;
    };
  }, [loadProjectProperties, projectCode]);

  useEffect(() => {
    if (project != null && propertiesProjectCode === project.code) {
      onProjectOpen(project);
    }
  }, [project, onProjectOpen, propertiesProjectCode]);

  useEffect(() => {
    if (defaultProjectCode != null && reducerState === "LOADED") {
      dispatch(projectSlice.actions.openProject(defaultProjectCode));
    }
  }, [defaultProjectCode, dispatch, reducerState]);

  const reloadBusinessProcess = useCallback(
    (
      loadProjectCode: string,
      processDefinitionKey: string,
      generic = false
    ): Promise<Record<string, Group[]>> => {
      let promise = Promise.resolve({
        properties: [],
        processDefinitionKey,
      } as BusinessProcessPropertiesResponse);

      if (generic) {
        promise = loadBusinessProcessSpecimen(processDefinitionKey);
      } else {
        promise = loadBusinessProcessProperties(
          loadProjectCode,
          processDefinitionKey
        );
      }

      return promise.then((res) => {
        return new Promise((resolve) => {
          setBpPropertiesMap((map) => {
            const newMap = {
              ...map,
              [res.processDefinitionKey]: res.properties || [],
            };

            projectService.setBusinessProcessPropertiesMap(newMap);

            resolve(newMap);
            return newMap;
          });
        });
      });
    },
    [loadBusinessProcessProperties, loadBusinessProcessSpecimen]
  );

  useEffect(() => {
    if (projectSpec != null) {
      setConfiguration(projectSpec);
      const { workflows, businessProcesses, code } = projectSpec;

      projectService.setWorkflows(workflows || []);
      projectService.setBusinessProcesses(businessProcesses || []);

      setBpPropertiesMap({});
      projectService.setBusinessProcessPropertiesMap({});

      reloadBusinessProcess(code, "supportRequest", true);

      if (Array.isArray(businessProcesses)) {
        businessProcesses.forEach((bp) => {
          reloadBusinessProcess(code, bp.processDefinitionKey);
        });
      }
    }
  }, [projectSpec, reloadBusinessProcess]);

  const { data: portals, isLoading: portalsLoading } = useQuery({
    queryKey: useMemo(() => ["project-portals", projectCode], [projectCode]),
    queryFn: () => {
      if (projectCode == null) {
        throw new Error("Project code cannot be null!");
      }

      return projectPortalsApi.getProjectPortals(projectCode);
    },
    enabled: projectCode != null,
    initialData: [],
    refetchOnMount: false,
  });

  return (
    <>
      <ProjectContext.Provider
        value={{
          workflows: configuration?.workflows || [],
          businessProcesses: useMemo(
            () => configuration?.businessProcesses || [],
            [configuration]
          ),
          configuration,
          projects,
          project: project || null,
          projectName: project?.name || "Document Management",
          openProject: useCallback(
            (projectCode: string) => {
              dispatch(projectSlice.actions.openProject(projectCode));
            },
            [dispatch]
          ),
          members: useCoreSelector(getProjectMembers),

          spocs: useCoreSelector(
            (state: CosmosCoreRootState) => state.project.spocs
          ),
          reload: reloadProperties,
          reloadSpec,
          reloadBusinessProcess: useCallback(
            (processDefinitionKey: string) => {
              if (projectCode != null) {
                return reloadBusinessProcess(projectCode, processDefinitionKey);
              }

              return Promise.resolve({});
            },
            [projectCode, reloadBusinessProcess]
          ),
          loadTemplates: useCallback((): Promise<Group[]> => {
            if (projectCode != null) {
              return loadProjectTemplates(projectCode).then(
                (r) => r.properties
              );
            }

            return Promise.resolve([]);
          }, [loadProjectTemplates, projectCode]),
          loading: useMemo(
            () => loadingProperties || loading || portalsLoading,
            [loading, loadingProperties, portalsLoading]
          ),
          addProjectMember,
          importProjectMembers,
          requestProjectAccess,
          editProjectGroup,
          createProjectGroup,
          portals,
        }}
      >
        <ProjectCompetenceProvider>
          <PropertiesProvider
            projectProperties={projectProperties}
            bpPropertiesMap={bpPropertiesMap}
          >
            <ColumnsProvider>{children}</ColumnsProvider>
          </PropertiesProvider>

          <ProjectCommandModals />
        </ProjectCompetenceProvider>
      </ProjectContext.Provider>
    </>
  );
};

ProjectProvider.propTypes = {
  children: PropTypes.node,
  defaultProjectCode: PropTypes.string,
  onProjectOpen: PropTypes.func,
};

ProjectProvider.defaultProps = {
  children: null,
  defaultProjectCode: null,
  onProjectOpen: () => {},
};

export default ProjectProvider;
