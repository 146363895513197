import * as actions from '../Actions/types';

const initialState = {
  processDefinitions: [],
  tasks: [],
  loading: false,
  processes: [],
  validationErrors: [],
};

const businessProcess = (state = initialState, action) => {
  switch (action.type) {
    case actions.businessProcess.SET_BUSINESS_PROCESS_TASKS:
      return {
        ...state,
        tasks: action.payload.tasks,
      };
    case actions.businessProcess.SET_BUSINESS_PROCESS_DEFINITIONS:
      return {
        ...state,
        processDefinitions: action.payload.processDefinitions,
      };
    case actions.businessProcess.SET_BUSINESS_PROCESS_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case actions.businessProcess.SET_BUSINESS_PROCESSES:
      return {
        ...state,
        processes: action.payload.processes,
      };
    case actions.businessProcess.SET_BUSINESS_PROCESS_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.payload.validationErrors,
      };
    default:
      return state;
  }
};

export default businessProcess;
