import * as actions from './types';

/**
 * Workflow process action generators.
 * @module actions/workflow
 * @category Actions
 */

/**
 * Action to set processes.
 * @function
 * @param {} processes -
 */
export const setProcesses = (processes) => ({
  type: actions.workflow.SET_PROCESSES,
  processes,
});

/**
 * Action ending process.
 * @function
 * @param {} processId -
 * @param {function} callback - action results callback
 */
export const endProcess = (processId, callback = () => {}) => ({
  type: actions.workflow.END_PROCESS,
  processId,
  callback,
});

/**
 * Action to create process instant start.
 * @function
 * @param {} processDefinition -
 * @param {function} callback - action results callback
 */
export const createProcessInstantStart = (
  processDefinition,
  callback = () => {}
) => ({
  type: actions.workflow.CREATE_PROCESS_INSTANT_START,
  processDefinition,
  callback,
  autoStart: false,
});

/**
 * Action to set process definitions.
 * @function
 * @param {} processDefinitions -
 */
export const setProcessDefinitions = (processDefinitions) => ({
  type: actions.workflow.SET_PROCESS_DEFINITIONS,
  processDefinitions,
});

/**
 * Action for reloading process.
 * @function
 * @param {boolean} force -
 */
export const reloadProcess = (force = false) => ({
  type: actions.workflow.RELOAD_PROCESS,
  force,
});

/**
 * Action to set workflow loading status.
 * @function
 * @param {boolean} loading - status flag of workflow loading
 * @param {string} message - message text
 */
export const setWorkflowLoading = (loading, message) => ({
  type: actions.workflow.SET_WORKFLOW_LOADING,
  loading,
  message,
});

/**
 * Action to fetch process.
 * @function
 * @param {function} callback - action results callback
 */
export const fetchProcesses = (callback = () => {}) => ({
  type: actions.workflow.FETCH_PROCESSES,
  callback,
});

/**
 * Action to cancel process.
 * @function
 * @param {function} callback - action results callback
 */
export const cancelProcess = (callback = () => {}) => ({
  type: actions.workflow.CANCEL_PROCESS,
  callback,
});

/**
 * Action to open process.
 * @function
 * @param {} processIdentifier -
 * @param {boolean} openBasket -
 * @param {boolean} usePreload -
 */
export const openProcess = (
  processIdentifier,
  openBasket = true,
  usePreload = true
) => ({
  type: actions.workflow.OPEN_PROCESS,
  processIdentifier,
  openBasket,
  usePreload,
});

/**
 * Action to close process.
 * @function
 * @param {boolean} closeBasket -
 */
export const closeProcess = (closeBasket = true) => ({
  type: actions.workflow.CLOSE_PROCESS,
  closeBasket,
});

/**
 * Action for setting process.
 * @function
 * @param {} process -
 */
export const setProcess = (process) => ({
  type: actions.workflow.SET_WORKFLOW_PROCESS,
  process,
});

/**
 * Action to fulfil task.
 * @function
 * @param {} taskId - id of the task
 * @param {function} callback - action results callback
 */
export const fulfilTask = (taskId, callback = () => {}) => ({
  type: actions.workflow.FULFIL_TASK,
  taskId,
  callback,
});

/**
 * Action for setting task loading status.
 * @function
 * @param {boolean} loading - status flag of loading task
 * @param {string} message -
 */
export const setTaskLoading = (loading, message) => ({
  type: actions.workflow.SET_TASK_LOADING,
  loading,
  message,
});

/**
 * Action for setting process tasks.
 * @function
 * @param {} tasks -
 */
export const setProcessTasks = (tasks) => ({
  type: actions.workflow.SET_PROCESS_TASKS,
  tasks,
});

/**
 * Action for updating process task.
 * @function
 * @param {string} taskId - id of the task
 * @param {string} propertyName - property name
 * @param {} value -
 */
export const updateProcessTask = (taskId, propertyName, value) => ({
  type: actions.workflow.UPDATE_PROCESS_TASK,
  propertyName,
  value,
  taskId,
});

/**
 * Action for updating all processes tasks.
 * @function
 * @param {string} propertyName - property name
 * @param {} value -
 */
export const updateAllProcessTasks = (propertyName, value) => ({
  type: actions.workflow.UPDATE_ALL_PROCESS_TASKS,
  propertyName,
  value,
});

/**
 * Action for fetching task history.
 * @function
 * @param {} page -
 * @param {} size -
 * @param {} orderBy -
 * @param {function} callback - action results callback
 */
export const fetchTaskHistory = (page, size, orderBy, callback = () => {}) => ({
  type: actions.workflow.FETCH_TASK_HISTORY,
  page,
  size,
  orderBy,
  callback,
});

/**
 * Action to start business process.
 * @function
 * @param {} taskHistory - task history
 */
export const setTaskHistory = (taskHistory) => ({
  type: actions.workflow.SET_TASK_HISTORY,
  taskHistory,
});

/**
 * Action for setting opened process id.
 * @function
 * @param {} openedProcessId -
 */
export const setOpenedProcessId = (openedProcessId) => ({
  type: actions.workflow.SET_OPENED_PROCESS_ID,
  openedProcessId,
});

/**
 * Action for preloading process.
 * @function
 * @param {} processIdentifier -
 */
export const preloadProcess = (processIdentifier) => ({
  type: actions.workflow.PRELOAD_PROCESS,
  processIdentifier,
});

/**
 * Action for setting process preload.
 * @function
 * @param {} preload -
 */
export const setProcessPreload = (preload) => ({
  type: actions.workflow.SET_PROCESS_PRELOAD,
  preload,
});

/**
 * Action for setting next process id.
 * @function
 * @param {} processId - id of the process
 */
export const setNextProcessId = (processId) => ({
  type: actions.workflow.SET_NEXT_PROCESS_ID,
  processId,
});

/**
 * Action for creating folder process.
 * @function
 * @param {} processDefinition -
 * @param {boolean} autoStart -
 * @param {function} callback - action results callback
 */
export const createFolderProcess = (
  processDefinition,
  autoStart = false,
  callback = () => {}
) => ({
  type: actions.workflow.CREATE_FOLDER_PROCESS,
  processDefinition,
  autoStart,
  callback,
});

/**
 * Action for fetching folder workflow status.
 * @function
 * @param {} parameter -
 * @param {function} callback - action results callback
 */
export const fetchFolderWorkflowStatus = (parameter, callback = () => {}) => ({
  type: actions.workflow.FETCH_FOLDER_WORKFLOW_STATUS,
  parameter,
  callback,
});

/**
 * Action for fetching initiated processes.
 * @function
 */
export const fetchInitiatedProcesses = () => ({
  type: actions.workflow.FETCH_INITIATED_PROCESSES,
});

/**
 * Action to set workflow status.
 * @function
 * @param {} status -
 */
export const setWorkflowStatus = (status) => ({
  type: actions.workflow.SET_WORKFLOW_STATUS,
  payload: {
    status,
  },
});

/**
 * Action for exporting workflow status Excel.
 * @function
 * @param {} name -
 */
export const exportWorkflowStatusExcel = (name) => ({
  type: actions.workflow.EXPORT_WORKFLOW_STATUS_EXCEL,
  payload: {
    name,
  },
});

// export const createProcess = (
//   processDefinition,
//   callback = () => {},
//   options = {}
// ) => ({
//   type: actions.workflow.CREATE_PROCESS,
//   payload: {
//     processDefinition,
//     callback,
//     options: {
//       autoStart: options.autoStart || false,
//       activeFolderBasket: options.activeFolderBasket || false,
//     },
//   },
// });
//
// export const updateTask = (taskId, callback = () => {}) => ({
//   type: actions.workflow.UPDATE_TASK,
//   taskId,
//   callback,
// });
//
// /**
//  * Action to for fetching process tasks.
//  * @function
//  * @param {function} callback - action results callback
//  */
// export const fetchProcessTasks = (callback = () => {}) => ({
//   type: actions.workflow.FETCH_PROCESS_TASKS,
//   callback,
// });
//
// /**
//  * Action to resume process.
//  * @function
//
//  */
// export const resumeProcess = () => ({
//   type: actions.workflow.RESUME_PROCESS,
// });
//
// /**
//  * Action for suspending process.
//  * @function
//  */
// export const suspendProcess = () => ({
//   type: actions.workflow.SUSPEND_PROCESS,
// });
//
// /**
//  * Action for fetching process history.
//  * @function
//  * @param {function} callback - action results callback
//  */
// export const fetchProcessHistory = (callback = () => {}) => ({
//   type: actions.workflow.FETCH_PROCESS_HISTORY,
//   callback,
// });
