import { InternalCacheRequestConfig } from "axios-cache-interceptor";
import authService from "../../services/authService";

const authInterceptor = (config: InternalCacheRequestConfig) => {
  const authToken = authService.getAuthToken();
  const domain = authService.getDomainName();

  if (authToken != null) {
    config.headers.Authorization = authToken;
    config.headers.Accept = "application/json";

    if (authToken.includes("Bearer")) {
      config.params = {
        ...config.params,
        domain,
      };
    }
  }

  config.params = {
    ...config.params,
    autoclose: true,
  };

  return config;
};

export default authInterceptor;
