import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ContextMenu,
  HelperTooltip,
  PancoPagination,
  Wrap,
} from "cosmos-components";
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useFlexLayout,
  useSortBy,
  useRowSelect,
  useTable,
  useColumnOrder,
  useGlobalFilter,
  usePagination,
  Cell as ReactTableCell,
  Row as ReactTableRow,
  useExpanded,
} from "react-table";
import usePropertyRender from "../hooks/usePropertyRender";
import useTableSelectMultiple from "../hooks/table/useTableSelectMultiple";
import useTableColumnOrder from "../hooks/table/useTableColumnOrder";
import _ from "lodash";
import Fuse from "fuse.js";
import classNames from "classnames";
import {
  Body,
  Cell,
  HeaderCell,
  Row,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableWrapper,
} from "./tableFragments";
import { useTranslation } from "react-i18next";
import { useColumns } from "../hooks";
import { Property } from "cosmos-config/lib/property/property";
import { PreparePropertiesOptions } from "../contexts/ColumnsContext";
import useTableStructure from "../hooks/table/useTableStructure";

const systemColumns = ["selection", "expander"];

export type RenderCellCallback<T> = (
  propertyName: string,
  value: any,
  label: string,
  item: T
) => React.ReactNode;

export interface SimpleTableProps<T> {
  properties?: Property[];
  data?: any[];
  renderContextMenu?: (
    selectedItems: (T & RowIdentifiable)[]
  ) => React.ReactNode;
  renderCell?: RenderCellCallback<T>;
  disableContextMenu?: boolean;
  searchQuery?: string;
  onDoubleClick?: (item: any) => void;
  className?: string;
  condensed?: boolean;
  pageSize?: number;
  onPageChanged?: (pageNumber: number) => void;
  columnOptions?: PreparePropertiesOptions;
  structurable?: boolean;
}

declare type RowIdentifiable = {
  _rowIndex: number;
};

function SimpleTable<T extends object = {}>({
  properties,
  data,
  renderContextMenu,
  renderCell,
  disableContextMenu,
  searchQuery,
  onDoubleClick,
  className,
  condensed,
  pageSize = 20,
  onPageChanged,
  columnOptions,
  structurable,
}: SimpleTableProps<T>) {
  const [selectedItems, setSelectedItems] = useState<(T & RowIdentifiable)[]>(
    []
  );
  const { t } = useTranslation("property");

  const { prepareCustomProperties } = useColumns();

  const columns = useMemo(
    () => prepareCustomProperties<T>(properties || [], columnOptions),
    [prepareCustomProperties, properties, columnOptions]
  );

  const {
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    state: { pageIndex },
  } = useTable<T>(
    {
      columns: useMemo(() => columns || [], [columns]),
      data: useMemo(() => data || [], [data]),
      initialState: {
        systemColumns,
        pageSize,
        pageIndex: 0,
      },
      multiSelection: true,
      columnPositions: useMemo(
        () =>
          (properties || []).map((c, idx) => ({
            id: c.name,
            width: c.columnWidth,
            order: idx,
          })),
        [properties]
      ),
      autoResetPage: false,
      globalFilter: useCallback(
        (rows: ReactTableRow<T>[], columnIds: string[], value: any) => {
          const keys = _.map(columnIds, (id) => `original.${id}`);

          if (rows.length > 0 && !_.isEmpty(value)) {
            const fuse = new Fuse(rows, {
              keys,
              shouldSort: true,
              threshold: 0.2,
            });

            return _.map(fuse.search(value), "item");
          }

          return rows;
        },
        []
      ),
      structurable,
    },
    useFlexLayout,
    useColumnOrder,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useTableSelectMultiple,
    useTableStructure,
    useTableColumnOrder
  );

  const render = usePropertyRender(properties || []);

  const renderCellInternal = (cell: ReactTableCell<T>) => {
    if (systemColumns.includes(cell.column.id)) {
      return cell.render("Cell");
    }

    const label = render(cell.column.id, cell.value);

    if (renderCell != null) {
      return renderCell(cell.column.id, cell.value, label, cell.row.original);
    }

    return label;
  };

  useEffect(() => {
    setGlobalFilter(searchQuery);
  }, [searchQuery, setGlobalFilter, data]);

  useEffect(() => {
    setSelectedItems((items: (T & RowIdentifiable)[]) => {
      const diff = _(items)
        .map((x) => x._rowIndex)
        .sort()
        .xor(_.map(selectedFlatRows, "index"))
        .value();

      return diff.length > 0
        ? selectedFlatRows.map((r) => ({ ...r.original, _rowIndex: r.index }))
        : items;
    });
  }, [selectedFlatRows]);

  const renderMenu = useCallback(() => {
    if (Array.isArray(selectedItems) && renderContextMenu != null) {
      return renderContextMenu(selectedItems);
    }
  }, [renderContextMenu, selectedItems]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize]);

  useEffect(() => {
    if (pageIndex > pageCount && pageIndex !== 0) {
      gotoPage(0);

      if (onPageChanged != null) {
        onPageChanged(0);
      }
    }
  }, [gotoPage, onPageChanged, pageCount, pageIndex]);

  useEffect(() => {
    if (pageIndex >= pageCount) {
      gotoPage(0);
    }
  }, [gotoPage, pageCount, pageIndex]);

  return (
    <TableWrapper className={classNames(className)}>
      <Table>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableRow key="header-group">
              {headerGroup.headers.map((header) => (
                <HeaderCell
                  width={header.width}
                  key={header.id}
                  condensed={_.defaultTo(condensed, true)}
                  systemColumn={systemColumns.includes(header.id)}
                  onClick={() => {
                    if (header.canSort) {
                      header.toggleSortBy();
                    }
                  }}
                  action={header.canSort}
                >
                  {/* @ts-ignore */}
                  {header.translation
                    ? //@ts-ignore
                      t(header.translation, { defaultValue: header.Header })
                    : header.render("Header")}

                  {header.isSorted && (
                    <span className="ml-2">
                      <FontAwesomeIcon
                        icon={header.isSortedDesc ? faCaretDown : faCaretUp}
                      />
                    </span>
                  )}

                  {/* @ts-ignore */}
                  {header.description != null && (
                    <HelperTooltip
                      title={header.Header?.toString()}
                      //@ts-ignore
                      text={header.description}
                      className="float-right"
                    />
                  )}
                </HeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHeader>

        <Body>
          <Wrap
            with={(children: React.ReactNode) => (
              <ContextMenu renderMenu={renderMenu}>{children}</ContextMenu>
            )}
            when={!disableContextMenu}
          >
            <TableBody>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Row
                    key={row.id}
                    onClick={(e: MouseEvent) => {
                      if (row.canExpand && structurable) {
                        row.toggleRowExpanded();
                      } else {
                        row.triggerSelection(e.shiftKey);
                      }
                    }}
                    onContextMenu={() => row.toggleRowSelected(true)}
                    onDoubleClick={() => {
                      if (onDoubleClick != null) {
                        onDoubleClick(row.original);
                      }
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Cell
                          key={cell.column.id}
                          condensed={_.defaultTo(condensed, true)}
                          systemColumn={systemColumns.includes(cell.column.id)}
                          width={cell.column.width}
                        >
                          {renderCellInternal(cell)}
                        </Cell>
                      );
                    })}
                  </Row>
                );
              })}
            </TableBody>
          </Wrap>
        </Body>
      </Table>

      {pageCount > 1 && (
        <div className="text-center mt-3">
          <PancoPagination
            pageIndex={pageIndex}
            pageCount={pageCount}
            gotoPage={(newPage) => {
              gotoPage(newPage);

              if (onPageChanged != null) {
                onPageChanged(newPage);
              }
            }}
          />
        </div>
      )}
    </TableWrapper>
  );
}

export default SimpleTable;
