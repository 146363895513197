import { ValuesetOption } from "cosmos-config/lib/property/property";

const accessLevelValueset: ValuesetOption[] = [
  { label: "No access", value: 3 },
  { label: "Viewer (View only)", value: 5 },
  { label: "Contractor (Upload)", value: 6 },
  { label: "Team member (View, Edit, Upload)", value: 7 },
  { label: "Docu Controller (View, Edit, Upload, Delete)", value: 9 },
  { label: "Maintainer (Admin, Access Management)", value: 10 },
];

export default accessLevelValueset;
