import {
  Property,
  SelectableAttributeProperty,
} from "cosmos-config/lib/property/property";
import Resource from "../types/resource";
import _ from "lodash";
import { ValuesetItem } from "../contants/valuesetItem";
import { propertyType } from "cosmos-config/generator";

export const parseResourceId = (id?: string) => {
  const parts = String(id).split("$");

  if (parts.length < 3 || id == null) {
    return {
      valid: false,
      docArea: null,
      identifier: null,
      resourceType: null,
    };
  }

  return {
    valid: true,
    docarea: parts[0],
    identifier: parseInt(parts[2], 10),
    resourceType: parseInt(parts[3], 10),
  };
};

export const isMsOfficeDocument = (contentType?: string) => {
  if (contentType == null) {
    return false;
  }

  const matches = [
    // "application/msword",
    "application/vnd.openxmlformats-officedocument",
    // "application/vnd.ms",
  ];

  return (
    "application/vnd.ms-outlook" !== contentType &&
    matches.some((match) => contentType.startsWith(match))
  );
};

const propertiesToPropertyMap = (
  properties: Property[],
  propertyName: keyof Property
) =>
  _(properties)
    .filter((p) => p.name != null)
    .keyBy("name")
    .mapValues((property) => {
      return (...args: any[]) => {
        const func = property[propertyName] as Function;
        return func.call(property, ...args);
      };
    })
    .value();

export const getValidateFunctions = (properties: Property[]) =>
  propertiesToPropertyMap(properties, "validate");

export const validateResources = (
  resources: Resource[],
  properties: Property[]
) => {
  const validateFunctions = getValidateFunctions(properties);
  const updatableProperties = properties.filter(
    (p) => p.editable && p.updatable
  );

  const validateResource = (resource: Resource) => {
    const editableProperties = updatableProperties
      .filter((p) => !p.isHidden(resource))
      .map((p) => p.name);

    return editableProperties
      .map((propertyName) => {
        const validate = validateFunctions[propertyName];
        const valid = validate(resource[propertyName]);
        return !valid ? propertyName : null;
      })
      .filter((x) => x != null);
  };

  return _(resources)
    .mapKeys("id")
    .mapValues(validateResource)
    .omitBy((errors) => errors.length === 0)
    .map((errors, id) => ({
      id,
      errors: errors?.map((propertyName) => ({
        property: propertyName,
        customMessage: "",
      })),
    }))
    .value();
};

export const getKeywords = (
  resource: Resource,
  properties: Property[],
  valuesetsMap: Record<string, ValuesetItem[]>
) =>
  _(properties)
    .filter("keyword")
    .map((dp) => {
      const val = _.get(resource, dp.name);

      if (val != null && val !== "") {
        if (
          Array.isArray(val) &&
          dp.type !== propertyType.PRINCIPAL_SELECT &&
          dp.type !== propertyType.MEMBER_SELECT
        ) {
          const options =
            valuesetsMap[(dp as SelectableAttributeProperty).valuesetName] ||
            [];
          return _.compact(
            val.map((e) => {
              const opt = options.find((o) => o.value === e);
              return opt?.label;
            })
          );
        }

        return String(val).substring(0, 64);
      }

      return null;
    })
    .compact()
    .flatMap()
    .value();

export const getResourcePreset = (resource: any, properties: Property[]) => {
  if (resource == null) {
    return {};
  }

  const presetableProperties = _(properties)
    .filter("usePreset")
    .map("name")
    .value();

  return _.pick(resource, presetableProperties);
};

export const propertyEquals = <T = string | number>(
  resource: Record<string, any> | null | undefined,
  propertyName: string | null | undefined,
  value: T
): boolean => {
  const resourceValue = _.isNil(propertyName)
    ? resource
    : resource?.[propertyName];

  if (_.isNil(resourceValue)) {
    return _.isNil(value);
  }

  if (Array.isArray(resourceValue)) {
    if (resourceValue.length === 0) {
      return _.isNil(value);
    }

    return resourceValue.length === 1
      ? resourceValue[0] === value
      : resourceValue.includes(value);
  }

  return resourceValue === value;
};
