/* eslint-disable no-underscore-dangle */

/**
 * @interface
 */
export interface ConditionalElement {
  conditional: boolean;
  _conditionalProperty: string | null;
  _conditionalValues: Array<any>;
  _conditionalOperator: String;
  _visibilityCallback: ((filter: Record<string, any>) => boolean) | null;

  visibleOn(callback: () => boolean): ConditionalElement;
  hiddenUntil(
    propertyName: String,
    ...conditionalValues: Array<any>
  ): ConditionalElement;
  hiddenWhile(
    propertyName: String,
    ...conditionalValues: Array<any>
  ): ConditionalElement;
  isHidden(filter: Record<string, any>): boolean;
}

/**
 * Conditional Element Generator.
 * @author Patrik Pancisin
 * @class conditionalElement
 * @classdesc Basic building block of cosmos.
 */
export default (): ConditionalElement => /** @lends conditionalElement */ ({
  conditional: true,
  _conditionalProperty: null,
  _conditionalValues: [],
  _conditionalOperator: "eq",
  _visibilityCallback: null,
  /**
   * @instance
   * @description Sets a visibility callback function. Element visible if callback returns true.
   * @param {callback} propName Element visibility callback function.
   */
  visibleOn(callback: () => boolean): ConditionalElement {
    this._visibilityCallback = callback;
    return this;
  },
  /**
   * @instance
   * @description Sets element visible until condition applies
   * @param {string} propertyName The name of property in condition
   * @param {Array} conditionalValues Element is visible if none of conditionValues
   * equals property value.
   */
  hiddenUntil(
    propertyName: string,
    ...conditionalValues: Array<any>
  ): ConditionalElement {
    this._conditionalProperty = propertyName;
    this._conditionalValues = conditionalValues;
    this._conditionalOperator = "eq";
    return this;
  },
  /**
   * @instance
   * @description Sets element hidden condition white applies
   * @param {string} propertyName The name of property in condition
   * @param {Array} conditionalValues Element is visible if one of values equals property value.
   */
  hiddenWhile(
    propertyName: string,
    ...conditionalValues: Array<any>
  ): ConditionalElement {
    this._conditionalProperty = propertyName;
    this._conditionalValues = conditionalValues;
    this._conditionalOperator = "noteq";
    return this;
  },
  isHidden(filter = {}): boolean {
    if (this._visibilityCallback != null) {
      return filter == null || !this._visibilityCallback(filter);
    }

    if (
      this._conditionalProperty == null ||
      this._conditionalValues.length === 0
    ) {
      return false;
    }

    if (filter == null) {
      return true;
    }

    const filterValue = filter[this._conditionalProperty];

    if (this._conditionalOperator === "eq") {
      return (
        !(
          Array.isArray(filterValue) &&
          filterValue.some((v: string) => this._conditionalValues.includes(v))
        ) && !this._conditionalValues.includes(filterValue)
      );
    }

    if (this._conditionalOperator === "noteq") {
      if (
        this._conditionalValues.length === 1 &&
        this._conditionalValues[0] == null
      ) {
        return (
          filterValue == null ||
          filterValue === "" ||
          (Array.isArray(filterValue) && filterValue.length === 0)
        );
      }

      if (Array.isArray(filter[this._conditionalProperty])) {
        return filter[this._conditionalProperty].some((v: any) =>
          this._conditionalValues.includes(v)
        );
      } else {
        return this._conditionalValues.includes(
          filter[this._conditionalProperty]
        );
      }
    }

    return false;
  },
});
