import { property } from "cosmos-config/generator";

const projectGroup = [
  property("Label", "label").describe(
    "Set the label of the property how it appears across Cosmos."
  ),
  property("Topic", "topic"),
  property("Description", "description")
    .textArea(1000)
    .describe(
      "Set the description of the property to inform users better about how it should be used."
    ),
  // property("Translation Path", "translation"),
  property("Editable").booleanAttribute("editable").useYesNo(),
  property("Table Column").booleanAttribute("tableColumn").useYesNo(),
];

export default projectGroup;
