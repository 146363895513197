import { createElement } from 'react';
import {
  valuesetSource,
  group,
  property,
  systemProperty,
} from 'cosmos-config/generator';

export default [
  group('default').children([
    systemProperty('processinstanceid'),
    systemProperty('processcreationdate'),
    systemProperty('processdefinition'),
    systemProperty('processinitiator'),
    systemProperty('processdefinitiondisplayname'),
    systemProperty('folderId', 'folderId', true),
    property('Workflow ID', 'processidentifier')
      .excludeForm()
      .informational()
      .tableCell((v) => createElement('b', {}, `#${v}`)),
    systemProperty('Workflow task', 'taskdisplayname').excludeForm(),
    systemProperty('Name', 'taskname'),
    systemProperty('assignmentorgentityid').multiAttribute(
      'assignmentorgentityid'
    ),
    systemProperty('assignedpositionid'),
    systemProperty('Dispatchers', 'dispatchers', true).multiAttribute(
      'dispatchers'
    ),
    // systemProperty('Actors')
    //   .multiAttribute('actors')
    //   .resolveUsername('assignmentorgentityid'),

    property('Task State')
      .selectableAttribute(
        'taskstate',
        'taskstate',
        valuesetSource.STATIC_VALUESET
      )
      .valueset([
        { value: 'new', label: 'New' },
        { value: 'parcial', label: 'Partialy Finished' },
        { value: 'paused', label: 'Paused' },
      ])
      .excludeForm()
      .excludeTable(),
    property('Assigned At')
      .dateAttribute('taskcreationdate')
      .useAlternateProperty('Assigned At', 'processcreationdate')
      .excludeForm(),
    systemProperty('basketresourceid', 'basketresourceid', true),
    systemProperty('WF_AbortWF'),
  ]),
];
