import getValueQueryRepr from "./getValueQueryRepr";
import select from "./select";
import where from "./where";
import query from "./query";
import {
  andOperation,
  eqCond,
  notOperation,
  orOperation,
  operation,
  existsOperation,
  queryOperand,
} from "./operation";
import { filterToOperation } from "./filter";

const isNull = () => {
  return () => " is null";
};

const isNotNull = () => {
  return () => "!=null";
};

const isEqual = (value: any) => {
  return () => `=${getValueQueryRepr(value)}`;
};

const multiContains = (property: string, value: string) => {
  return () => `exists(${property} = "${value}")`;
};

const isIn = (value: any[]) => {
  return () => ` in (${value.map((v) => getValueQueryRepr(v)).join(",")})`;
};

const greaterThan = (value: any) => {
  return () => `>${getValueQueryRepr(value)}`;
};

const greaterThanNow = (equal: boolean = false) => {
  return () => `>${equal ? "=" : ""} %now`;
};

const lessThan = (value: any) => {
  return () => `<${getValueQueryRepr(value)}`;
};

const like = (value: string) => {
  return () => ` like "${value}"`;
};

const match = (expression: string) => {
  return () => ` *= "${expression}"`;
};

const likeIgnoreCase = (value: string) => {
  return () => ` %~ "${value}"`;
};

const between = (aValue: any, bValue: any, date: boolean = false) => {
  return () =>
    ` between (${getValueQueryRepr(aValue, date)}, ${getValueQueryRepr(
      bValue,
      date
    )})`;
};

const isTrue = () => {
  return () => "=true";
};

const isFalse = () => {
  return () => "=false";
};

const distinct = (propertyName: string) => {
  return `distinct(${propertyName})`;
};

const countDistinct = (propertyName: string) => {
  return `countDistinct(${propertyName})`;
};

const count = (propertyName: string) => {
  return `count(${propertyName})`;
};

const isEqualIgnoreCase = (value: any) => {
  return () => `%=${getValueQueryRepr(value)}`;
};

const isCurrentUserPrincipalId = () => {
  return () => ` =%currentUserPrincipalId`;
};

const isCurrentDefaultPositionId = () => {
  return () => ` =%currentDefaultPositionPrincipalId `;
};

const isCurrentProxyPositionId = () => {
  return () => ` in(%currentProxiedOrgEntityPrincipalIds)`;
};

const min = (propertyName: string) => `min(${propertyName})`;

const max = (propertyName: string) => `max(${propertyName})`;

const toDate = (value: string) => () => `=toDate(${value})`;

export {
  getValueQueryRepr,
  select,
  where,
  isNull,
  isNotNull,
  isEqual,
  multiContains,
  isIn,
  greaterThan,
  greaterThanNow,
  lessThan,
  like,
  match,
  likeIgnoreCase,
  between,
  isTrue,
  isFalse,
  distinct,
  countDistinct,
  count,
  isEqualIgnoreCase,
  isCurrentUserPrincipalId,
  isCurrentDefaultPositionId,
  isCurrentProxyPositionId,
  min,
  max,
  toDate,
  query,
  andOperation,
  eqCond,
  filterToOperation,
  notOperation,
  orOperation,
  operation,
  existsOperation,
  queryOperand,
};
