import { LoadingOverlay } from "cosmos-components";
import React, { useContext, useEffect, useMemo } from "react";
import LocaleEditorContext from "../contexts/LocaleEditorContext";
import TranslationTable from "./TranslationTable";

export interface TranslationScopeProps {
  scope?: string;
  name?: string;
}

const TranslationScope = ({ scope, name }: TranslationScopeProps) => {
  const {
    getTranslations,
    translations,
    loadingState,
    updateLanguageTranslation,
    deleteTranslationKey,
  } = useContext(LocaleEditorContext);

  const data = useMemo(
    () => (name == null ? {} : translations[name]),
    [translations, name]
  );

  const loading = useMemo(
    () => (name != null ? loadingState[name]?.loading : false),
    [loadingState, name]
  );

  useEffect(() => {
    if (scope != null && name != null) {
      getTranslations(scope, name);
    }
  }, [getTranslations, name, scope]);

  return (
    <LoadingOverlay loading={loading}>
      <TranslationTable
        className="pb-3 h-100"
        data={data}
        onUpdate={(language, key, translation) => {
          if (scope != null && name != null) {
            updateLanguageTranslation(language, scope, name, {
              key,
              translation,
            });
          }
        }}
        onDelete={(keys) => {
          keys.forEach((translationKey) => {
            if (scope != null && name != null) {
              deleteTranslationKey(scope, name, translationKey);
            }
          });
        }}
      />
    </LoadingOverlay>
  );
};

export default TranslationScope;
