/**
 * @enum {string}
 * @category Property
 * @readonly
 */
enum ValuesetSource {
  VALUESET = "valueset",
  MASTERDATA = "masterdata",
  AGGREGATE = "aggregate",
  AGGREGATE_MINMAX = "aggregate_minmax",
  AGGREGATE_RESOURCE = "aggregate_resource",
  CUSTOM_VALUESET = "custom_valueset",
  STATIC_VALUESET = "static_valueset",
  DISTINCT_RESOURCE = "distinct_resource",
}

export default ValuesetSource;
