import { useEffect, useState } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { LoadingOverlay } from "cosmos-components";
import useDocArea from "../../hooks/useDocArea";
import SimpleUniversalForm from "../../components/SimpleUniversalForm";
import masterValuesetItem from "../../contants/masterValuesetItem";

const EditMasterValuesetEntryModal = ({
  show,
  onClose,
  valuesetName,
  entry,
}) => {
  const [innerEntry, setInnerEntry] = useState({});
  const { loading, saveMasterValuesetItem } = useDocArea();

  useEffect(() => {
    if (show) {
      setInnerEntry({ ...entry });
    }
  }, [entry, show, valuesetName]);

  const handleSubmit = () => {
    saveMasterValuesetItem(valuesetName, innerEntry, (result) => {
      onClose(result);
    });
  };

  return (
    <Modal show={show} className="location-modal" onHide={() => onClose()}>
      <Modal.Header>
        <Modal.Title>Valueset entry</Modal.Title>
        <CloseButton className="close-icon" onClick={() => onClose()} />
      </Modal.Header>
      <Modal.Body className="position-relative">
        <LoadingOverlay loading={loading}>
          <SimpleUniversalForm
            inline
            properties={masterValuesetItem}
            onSubmit={handleSubmit}
            resource={innerEntry}
            onUpdateResource={(id, name, value) => {
              setInnerEntry((ie) => ({
                ...ie,
                [name]: value.trim(),
              }));
            }}
          >
            {({ submit }) => (
              <div className="text-right">
                <Button onClick={submit}>Save</Button>
              </div>
            )}
          </SimpleUniversalForm>
        </LoadingOverlay>
      </Modal.Body>
    </Modal>
  );
};

EditMasterValuesetEntryModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  entry: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  valuesetName: PropTypes.string.isRequired,
};

EditMasterValuesetEntryModal.defaultProps = {
  show: true,
  onClose: () => {},
  entry: null,
};

export default EditMasterValuesetEntryModal;
