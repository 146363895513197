import { Button } from "react-bootstrap";
import styled, { css } from "styled-components";
import { shadeColor } from "cosmos-components";

export const Body = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.base};
  flex-grow: 1;
  position: relative;
`;

export const BodyOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.background};
    opacity: 0.8;
  }

  & > * {
    z-index: 1;
  }
`;

const getColumnLayout = (width: number | string, systemColumn?: boolean) => {
  const defaultStyle = css`
    box-sizing: border-box;
    width: ${`${width}px`};
  `;

  if (systemColumn) {
    return css`
      ${defaultStyle}
      flex: 0 0 auto;
    `;
  }

  return css`
    ${defaultStyle}
    min-width: ${`${width}px`};
    flex: 1 0 ${`${width}px`};
  `;
};

export interface CellProps {
  condensed?: boolean;
  systemColumn?: boolean;
  width?: number | string;
}

export const Cell = styled.div<CellProps>`
  padding: ${(props) => (props.condensed ? "0.3rem" : "0.7rem")};
  border-top: 1px solid ${(props) => shadeColor(0.15, props.theme.background)};
  overflow: hidden;
  user-select: none;
  word-break: break-all;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props) => getColumnLayout(props.width || 200, props.systemColumn)};
`;

export const TableRow = styled.div`
  display: flex;
  flex: 1 0 auto;
  min-width: 150px;
`;

export interface RowProps {
  selected?: boolean;
  subrow?: boolean;
  textColor?: string;
  expanded?: boolean;
  expandable?: boolean;
  maxRows?: number;
}

const getRowHowerStyle = (expandable?: boolean) => css`
  background-color: ${(props) => shadeColor(0.1, props.theme.background)};

  ${expandable &&
  css`
    max-height: 500px !important;
    transition: max-height 0s ease-out 2s;
  `}
`;

const ROW_LINE_HEIGHT = 32;

const getRowHeight = (props: RowProps) => {
  const { maxRows } = props;

  if (maxRows === -1) {
    return "auto";
  }

  const rowsCount = maxRows || 2;
  const height = rowsCount * ROW_LINE_HEIGHT;

  return `${height}px`;
};

export const Row = styled(TableRow)<RowProps>`
  cursor: default;
  position: relative;
  transition: max-height 0.3s ease-out 1.5s;
  max-height: ${(props) => getRowHeight(props)};

  &:hover {
    ${(props) => getRowHowerStyle(props.expandable)}
  }

  ${Cell} {
    color: ${(props) =>
      props.textColor != null ? props.textColor : props.theme.body};
  }

  ${(props) => {
    if (props.selected) {
      return getRowHowerStyle(props.expandable);
    }
  }};

  ${(props) =>
    props.subrow &&
    css`
      background-color: rgba(247, 227, 52, 0.1);
    `}
`;

export interface HeaderCellProps extends CellProps {
  action?: boolean;
}

export const HeaderCell = styled.div<HeaderCellProps>`
  font-weight: bold;
  color: ${(props) => props.theme.base};
  vertical-align: bottom;
  border-bottom: 2px solid ${(props) => props.theme.base};
  padding: ${(props) => (props.condensed ? "0.3rem" : "0.7rem")};
  position: relative;
  word-break: "break-word";
  user-select: none;
  ${(props) => getColumnLayout(props.width || 200, props.systemColumn)};
  cursor: ${(props) => (props.action ? "pointer" : "default")};
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  flex-grow: 1;
`;

export const DraggableHeader = styled.div``;
export const HeaderHideButton = styled(Button)``;

export const ColumnResizer = styled.div`
  border-right: 5px dotted ${(props) => props.theme.base};
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
`;

export const TableHeader = styled.div`
  padding-right: 15px;
`;

export interface TableBodyProps {
  width?: number | string;
}

export const TableBody = styled.div<TableBodyProps>`
  height: 100%;
  overflow: hidden auto;
  min-width: fit-content;
  scrollbar-gutter: stable;

  ${(props) =>
    !(props.width == null || props.width === 0) &&
    css`
      width: ${`${props.width}px`};
    `}
`;

export interface TableProps {
  editing?: boolean;
}

export const Table = styled.div<TableProps>`
  height: 100%;
  padding-top: ${(props) => (props.editing ? "30px" : "0px")};

  ${(props) =>
    props.editing &&
    css<TableProps>`
      ${HeaderCell} {
        user-select: none;

        ${DraggableHeader} {
          height: 100%;
          transition: background-color 0.2s ease-in-out;
          border: 2px solid transparent;

          &:hover {
            background-color: ${(props) =>
              shadeColor(0.1, props.theme.background)};
            border: 2px dashed ${(props) => props.theme.base};
          }
        }

        ${HeaderHideButton} {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    `}

  //new
    display: flex;
  flex-direction: column;
`;

export const TableAllScreen = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;

  & > ${TableWrapper} {
    flex-grow: 1;
    height: 100%;

    & > ${Table} {
      display: flex;
      flex-direction: column;

      ${Body} {
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
