import React from "react";
import useResourceRelations from "../../hooks/useResourceRelations";
import RepositoryTableContext, {
  OnRowExpandCallback,
} from "./RepositoryTableContext";
import useFolderProperty from "../../hooks/useFolderProperty";
import { accessManagementType } from "cosmos-config/generator";
import { OnOpenResourceCallback } from "../../types/callbacks";

export interface UseRepositoryTableInjector {
  resourceId?: string;
  folderType?: string;
  children?: React.ReactNode;
  onRowExpand?: OnRowExpandCallback;
  onOpenResource?: OnOpenResourceCallback;
}

const RepositoryTableInjector = ({
  resourceId,
  folderType,
  children,
  onRowExpand,
  onOpenResource,
}: UseRepositoryTableInjector) => {
  const { parentEmailIds } = useResourceRelations({ resourceId });
  const { folderProperty } = useFolderProperty(folderType);

  return (
    <RepositoryTableContext.Provider
      value={{
        onRowExpand: onRowExpand || (() => {}),
        parentEmailIds: parentEmailIds || [],
        accessManagement:
          folderProperty?.accessManagement !== accessManagementType.NONE &&
          folderProperty?.accessManagement != null,
        onOpenResource: onOpenResource || (() => {}),
      }}
    >
      {children}
    </RepositoryTableContext.Provider>
  );
};

export default RepositoryTableInjector;
