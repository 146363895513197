import { createAction } from "@reduxjs/toolkit";
import * as actions from "../contants/projectConstants";

export const fetchAllProjectMembers = createAction(
  actions.FETCH_ALL_PROJECT_MEMBERS
);
export const addProjectMember = createAction(actions.ADD_PROJECT_MEMBER);
export const importProjectMembers = createAction(actions.IMPORT_PROJECT_MEMBERS);
export const inviteProjectMember = createAction(actions.INVITE_PROJECT_MEMBER);
export const createProjectMember = createAction(actions.CREATE_PROJECT_MEMBER);

// PROJECT ACCESS REQUEST
export const fetchProjectAccessRequests = createAction(
  actions.FETCH_PROJECT_ACCESS_REQUESTS
);
export const refuseProjectAccessRequest = createAction(
  actions.REFUSE_PROJECT_ACCESS_REQUEST
);

interface RequestProjectAccessPayload {
  projectCode: string;
  department?: string;
  options: {
    accessRequest: any;
  };
}

export const requestProjectAccess = createAction<RequestProjectAccessPayload>(
  actions.REQUEST_PROJECT_ACCESS
);

// PROJECT SPOC
export const fetchProjectSpocs = createAction(actions.FETCH_PROJECT_SPOCS);
export const createProjectSpoc = createAction(actions.CREATE_PROJECT_SPOC);
export const createProjectSpocRequest = createAction(
  actions.CREATE_PROJECT_SPOC_REQUEST
);
export const deleteProjectSpocs = createAction(actions.DELETE_PROJECT_SPOCS);
export const toggleActiveProjectSpoc = createAction(
  actions.TOGGLE_ACTIVE_PROJECT_SPOC
);
export const updateProjectSpoc = createAction(actions.UPDATE_PROJECT_SPOC);
export const fetchProjects = createAction(actions.FETCH_PROJECTS);
export const exportProjectSpocsExcel = createAction(actions.EXPORT_PROJECT_SPOCS_EXCEL);
