import { useMemo } from "react";
import _ from "lodash";
import useValueset from "../../../hooks/useValueset";
import useRepositorySearchDistinct from "../../../hooks/useRepositorySearchDistinct";
import { ChartDataEntry } from "../types/CosmosChart";

export interface UseAggregatedDataProps {
  resourceId?: string;
  propertyName?: string | null;
  filter?: Record<string, any>;
  resourceType?: number;
  subtree?: boolean;
  substructure?: boolean;
  orderBy?: any;
}

const useAggregatedData = ({
  resourceId,
  propertyName,
  filter,
  resourceType,
  subtree = false,
  substructure = false,
  orderBy,
}: UseAggregatedDataProps) => {
  const { getValuesetByProperty } = useValueset();
  const valueset = useMemo(
    () => (propertyName != null ? getValuesetByProperty(propertyName) : []),
    [propertyName, getValuesetByProperty]
  );

  const aggregationFilter = useMemo(() => {
    return _(filter)
      .omit(propertyName || "")
      .set("resourcetype", resourceType)
      .value();
  }, [filter, propertyName, resourceType]);

  const { data, loading, count } = useRepositorySearchDistinct({
    resourceId: resourceId || "",
    propertyName: propertyName || "",
    enabled: resourceId != null && propertyName != null,
    filter: aggregationFilter,
    subtree,
    orderBy,
    substructure,
  });

  const parsedData = useMemo(() => {
    return data.map((item) => {
      let label = item.value == null ? "Not Specified" : item.value;

      if (typeof item.value === "boolean") {
        label = item.value ? "Yes" : "No";
      }

      return {
        label,
        id: item.value,
        value: item.count,
      };
    });
  }, [data]);

  const labeledData = useMemo(() => {
    const getLabel = (value: string) => {
      const labelsetEntry = valueset.find((ls) => ls.value === value);
      return labelsetEntry?.label || value;
    };

    const labeled = parsedData.map(
      (entry) =>
        ({
          ...entry,
          label: getLabel(entry.label),
        } as ChartDataEntry)
    );

    // _.groupBy<LabeledEntry>(labeled, (a) => {

    // });

    const mergedLabels = labeled.reduce(
      (acc: ChartDataEntry[], cur: ChartDataEntry) => {
        const idx = acc.findIndex((a) => a.label === cur.label);
        if (idx !== -1) {
          const item = acc[idx];
          return Object.assign([], acc, {
            [idx]: {
              ...cur,
              value: (item.value || 0) + cur.value,
            },
          });
        }

        return [...acc, cur];
      },
      []
    );

    return mergedLabels;
  }, [parsedData, valueset]);

  return {
    data: labeledData,
    loading,
    docCount: count,
  };
};

export default useAggregatedData;
