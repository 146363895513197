import React, { useContext } from "react";
import DocumentsTable from "./DocumentsTable";
import { LoadingOverlay } from "cosmos-components";
import DashboardContext from "../../contexts/DashboardContext";
import useRepositorySearch from "../../../../hooks/useRepositorySearch";
import { CosmosWidget, CosmosWidgetProps } from "../../types/CosmosWidget";

const RecentlyUploadedDocuments = ({
  onSelect,
}: CosmosWidgetProps): CosmosWidget => {
  const { resourceId } = useContext(DashboardContext);

  const { data, loading } = useRepositorySearch({
    resourceId: resourceId || "",
    filter: {
      resourcetype: 2,
    },
    subtree: true,
    paginator: {
      number: 0,
      size: 10,
    },
    orderBy: {
      initialcreation: "desc",
    },
  });

  return (
    <LoadingOverlay loading={loading}>
      <DocumentsTable
        onSelect={onSelect}
        documents={data}
        dateLabel="Uploaded"
        includeDate
      />
    </LoadingOverlay>
  );
};

export default RecentlyUploadedDocuments;
