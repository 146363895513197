import React, { useEffect, useState } from "react";
import ResourcePicker, { ResourcePickerProps } from "./form/ResourcePicker";
import useProject from "../hooks/useProject";
import { PancoSelectOption } from "cosmos-components";
import useFolderProperty from "../hooks/useFolderProperty";

export interface FolderTypeResourcePickerProps extends ResourcePickerProps {
  initialValue?: string;
}

const FolderTypeResourcePicker = ({
  initialValue,
  folderType,
  ...props
}: FolderTypeResourcePickerProps) => {
  const { project } = useProject();
  const { getFolderTypeLabel } = useFolderProperty(folderType);

  const [options, setOptions] = useState<PancoSelectOption[]>([]);

  useEffect(() => {
    if (initialValue != null) {
      setOptions((opts) => {
        const filteredOptions = opts.filter((o) => o.value !== initialValue);

        return [
          ...filteredOptions,
          {
            value: initialValue,
            label: `Current ${getFolderTypeLabel(false)}`,
            resourceType: 1,
          },
        ];
      });
    }
  }, [initialValue, getFolderTypeLabel]);

  return (
    <ResourcePicker
      {...props}
      folderId={project?.resourceId}
      resourceType={1}
      options={options}
      folderType={folderType}
      onInsert={({ value, label }) => {
        setOptions((opts) => [
          ...opts,
          {
            value,
            label,
            resourceType: 1,
          },
        ]);
      }}
    />
  );
};

export default FolderTypeResourcePicker;
