import {
  darken,
  lighten,
  radialGradient,
  transparentize,
  desaturate,
} from "polished";
import { createElement as h } from "react";
import styled, { keyframes } from "styled-components";
import PancoTooltip from "../panco-tooltip/PancoTooltip";

const statusColorMap: Record<string, string> = {
  STU: "#fff",
  NRD: "#ddd",
  DRA: "#ddd",
  IFR: "#89c4f4",
  RIP: "#89c4f4",
  CRD: "#038aff",
  IFA: "#ff9914",
  ACD: "#2ecc71",
  NAD: "#e74c3c",
  PAD: "#f39c12",
  ELP: "#666",
  SUP: "#ccc",
  // new
  IFD: "#252958",
  IFI: "#0cce6b",
  PRA: "#e56399",
  IFT: "#0cce6b",
  IFC: "#e74c3c",
  CAN: "#e8ae68",

  // PASSO statuses
  ongoing: "#ff9914",
  nosignoff: "#ff0028",
  signoff: "#0cce6b",
};

const getStatusColor = (status?: string | null) => {
  const defaultColor = statusColorMap["NRD"];

  if (status == null) {
    return defaultColor;
  }

  return statusColorMap[status] || defaultColor;
};

type CircleProps = {
  color: string;
};

const Circle = styled.div<CircleProps>`
  width: 20px;
  height: 20px;
  display: inline-block;

  border-radius: 50px;

  background: ${(props) =>
    radialGradient({ colorStops: [lighten(0.25, props.color), props.color] })};
  border: 1px solid ${(props) => darken(0.05, props.color)};
  box-shadow: 0px 0px 3px ${(props) => darken(0.05, props.color)};
`;

const pulseAnimation = (color: string) => keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 ${transparentize(0.5, color)};
    background: ${radialGradient({
      colorStops: [lighten(0.25, color), color],
    })};
    border-color: ${color};
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 8px ${transparentize(0.8, color)};
  }

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 ${transparentize(1, color)};
    background: ${radialGradient({
      colorStops: [lighten(0.25, color), desaturate(0.4, color)],
    })};
    border-color: ${desaturate(0.5, color)};
  }
`;

const PulsingCircle = styled(Circle)<CircleProps>`
  animation-name: ${(props) => pulseAnimation(props.color)};
  animation-duration: 5s;
  animation-iteration-count: infinite;
`;

export interface ReviewStatusIndicatorProps {
  status?: string | null;
  title?: string | null;
  pulse?: boolean;
}

const ReviewStatusIndicator = ({
  status,
  title,
  pulse,
}: ReviewStatusIndicatorProps) => {
  return h(
    PancoTooltip,
    {
      text: title,
      placement: "bottom",
    },
    h(pulse ? PulsingCircle : Circle, {
      color: getStatusColor(status),
    })
  );
};

export default ReviewStatusIndicator;
