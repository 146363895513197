import { PancoSwitchProps } from "cosmos-components";
import {
  BooleanAttributeProperty,
  Property,
} from "cosmos-config/lib/property/property";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BaseProps } from "./elementPropsHelper";
import { propertyType } from "cosmos-config/generator";

export interface UseFormComponentPropsProps {
  property?: Property;
}

export type ElementPropsTypes = PancoSwitchProps;

const useFormComponentProps = ({ property }: UseFormComponentPropsProps) => {
  const { t } = useTranslation("property");

  const getSwitchProps = useCallback(
    (baseProps: any): PancoSwitchProps => {
      const p = property as BooleanAttributeProperty;

      const labelLeft =
        p.labelLeft == null
          ? t("common.no", { defaultValue: "No" })
          : t(p.labelLeft);

      const labelRight =
        p.labelRight == null
          ? t("common.yes", { defaultValue: "Yes" })
          : t(p.labelRight);

      return {
        ...baseProps,
        name: baseProps.id,
        neutral: p.neutral,
        labelLeft,
        labelRight,
      };
    },
    [property, t]
  );

  return {
    getProps: useCallback(
      (baseProps: BaseProps) => {
        if (property == null) {
          return baseProps;
        }

        switch (property?.type) {
          case propertyType.YESNO:
            return getSwitchProps(baseProps);
          default:
            return baseProps;
        }
      },
      [getSwitchProps, property]
    ),
  };
};

export default useFormComponentProps;
