import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createElement } from "react";
import {
  valuesetSource,
  property,
  systemProperty,
  dependencyType,
} from "cosmos-config/generator";

const spoc = [
  systemProperty("id"),
  systemProperty("Folder Id", "folderId", true).excludeTable(),
  property("Active")
    .booleanAttribute("active")
    .useYesNo()
    .tableCell((value) =>
      createElement(FontAwesomeIcon, {
        icon: value ? faCheck : faCircle,
        className: value ? "text-success" : "text-muted",
      })
    )
    .default(true)
    .translate("spoc.active"),
  property("Name", "principalId")
    .selectableMemberAttribute("principalId")
    .excludeTable()
    .translate("spoc.name"),
  property("Name", "userCommonName").excludeForm().translate("spoc.name"),
  property("All Departments")
    .booleanAttribute("allDepartments")
    .useYesNo()
    .excludeTable(),
  property("Segment")
    .selectableAttribute(
      "segment",
      "bu_lca_segment",
      valuesetSource.CUSTOM_VALUESET
    )
    .slaveOf("master_segment")
    .controlVisibility()
    .hiddenUntil("allDepartments", false),
  property("Department")
    .selectableAttribute(
      "department",
      "bu_lca_department",
      valuesetSource.CUSTOM_VALUESET
    )
    .dependingOn("segment", "Segment", dependencyType.STRICT)
    .translate("spoc.department")
    .controlVisibility()
    .hiddenUntil("allDepartments", false),
];

export default spoc;
