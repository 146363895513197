import { structureType as StructureTypeType } from "cosmos-config/generator";
import RepositoryTable, {
  RepositoryTableHandle,
  RepositoryTableProps,
} from "./RepositoryTable";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFolderProperty, useProperties, useValueset } from "../../hooks";
import Resource from "../../types/resource";
import { propertyEquals } from "../../utils";
import { StructureItem } from "../../contexts/PropertiesContext";
import _ from "lodash";
import { ValuesetItem } from "../../contants/valuesetItem";

export interface StructurableRepositoryTableProps extends RepositoryTableProps {
  structured?: boolean;
  structureType?: StructureTypeType;
  folderType?: string;
  includeOptions?: boolean;
}

const StructurableRepositoryTable = forwardRef<
  RepositoryTableHandle,
  StructurableRepositoryTableProps
>(
  (
    { data, structureType, structured, folderType, includeOptions, ...props },
    ref
  ) => {
    const { structures, render } = useProperties();
    const { folderProperty } = useFolderProperty(folderType);

    const [options, setOptions] = useState<Record<string, ValuesetItem[]>>({});

    const { valuesets } = useValueset();

    const structure = useMemo(() => {
      const st = structureType || StructureTypeType.FOLDER;

      if (structures == null || !_.has(structures, st)) {
        return null;
      }

      return structures[st];
    }, [structures, structureType]);

    const valuesetsMap = useMemo(
      () => _(valuesets).keyBy("name").mapValues("data").value(),
      [valuesets]
    );

    const titlePropertyName = useMemo(() => {
      return folderType != null && folderProperty?.titlePropertyName
        ? folderProperty?.titlePropertyName
        : "displayname";
    }, [folderProperty?.titlePropertyName, folderType]);

    useEffect(() => {
      const valuesetNames = _(structure)
        .map("property")
        .keyBy("valuesetName")
        .mapValues("name")
        .value();

      setOptions((opts) => {
        if (
          Object.values(valuesetNames).some(
            (pn) => !_.has(opts, pn) || _.isUndefined(opts[pn])
          )
        ) {
          return _(valuesetNames)
            .invert()
            .mapValues((vn) => valuesetsMap[vn])
            .value();
        }

        return opts;
      });
    }, [valuesets, structure, valuesetsMap]);

    const getLevelData = useCallback(
      (
        structure: Record<number, StructureItem>,
        level: number = 0,
        resources: Resource[] = [],
        referenceValue: string | null = null,
        levelMask: any = {}
      ): Resource[] => {
        const structureLevel = structure[level];

        const indentation = "";

        // const indentation = _.range(level)
        //   .map((idx, i, arr) => (arr.length - 1 === idx ? "⠀⠀↳" : "⠀⠀"))
        //   .join("");

        const formatTitle = (res: Resource) =>
          ({
            ...res,
            [titlePropertyName]: `${indentation} ${res?.[titlePropertyName]}`,
          } as Resource);

        if (structureLevel == null) {
          return resources.map(formatTitle);
        }

        const followingProperties = _(structure)
          .filter((s) => s.level >= level)
          .map("name")
          .value();

        const nonFollowing = (resources || [])
          .filter((res) => {
            return _(followingProperties).every((propertyName) =>
              propertyEquals(res, propertyName, null)
            );
          })
          .map(formatTitle);

        const valueset = includeOptions
          ? _.get(options, structureLevel.name, [])
          : [];

        const getFilterKey = (valueKey: string) =>
          `${structureLevel.name}-${valueKey}`;

        return _(resources)
          .groupBy(structureLevel.name)
          .omitBy((v, key) => _.isEmpty(key))
          .mapValues((resources, key) =>
            _.filter(resources, (res) =>
              propertyEquals(res, structureLevel.name, key)
            )
          )
          .map((resources, key) => {
            const folderTitle = `${render(structureLevel.name, key)} (${
              resources.length
            })`;

            const mask = {
              ...levelMask,
              [structureLevel.name]: key,
            };

            return {
              id: getFilterKey(key),
              [titlePropertyName]: folderTitle,
              subRows: getLevelData(structure, level + 1, resources, key, mask),
              valid: true,
              virtual: true,
              ...mask,
            } as Resource;
          })
          .unionBy(
            _(valueset)
              .filter((opt) => referenceValue === opt.filter)
              .map((opt) => {
                const filterKey = getFilterKey(opt.value);

                const mask = {
                  ...levelMask,
                  [structureLevel.name]: opt.value,
                };

                return {
                  id: filterKey,
                  [titlePropertyName]: `${opt.label} (0)`,
                  subRows: getLevelData(
                    structure,
                    level + 1,
                    [],
                    opt.value,
                    mask
                  ),
                  valid: true,
                  virtual: true,
                  ...mask,
                } as Resource;
              })
              .value(),
            "id"
          )
          .orderBy(titlePropertyName)
          .map(formatTitle)
          .concat(nonFollowing)
          .value();
      },
      [includeOptions, options, render, titlePropertyName]
    );

    const tableData = useMemo(() => {
      if (structure == null || !structured) {
        return data;
      }

      return getLevelData(structure, 0, data);
    }, [structure, structured, getLevelData, data]);

    const watchedColumns = useMemo(() => {
      return _(props.watchedColumns)
        .compact()
        .concat(titlePropertyName)
        .value();
    }, [props.watchedColumns, titlePropertyName]);

    return (
      <RepositoryTable
        {...props}
        data={tableData}
        watchedColumns={watchedColumns}
        ref={ref}
      />
    );
  }
);

StructurableRepositoryTable.displayName = "StructurableRepositoryTable";

export default StructurableRepositoryTable;
