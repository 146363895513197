import { DateTime } from "luxon";
import _ from "lodash";
import { uuidv4 } from "cosmos-config/utils";
import { UserAlert } from "../constants/userAlert";
import Resource from "../../../types/resource";
import alertType, { UserAlertType } from "../constants/userAlertType";
import { parseResourceId } from "../../../utils/resourceUtils";
import { ModuleSummary } from "../constants/moduleSummary";
import { BusinessProcessTask } from "../constants/businessProcessTask";
import { NscaleWorkflowTask } from "../constants/nscaleWorkflowTask";
import { useCallback } from "react";
import { generateTitleTemplate } from "../utils/resource";
import useProject from "../../../hooks/useProject";
import useProperties from "../../../hooks/useProperties";
import { Property } from "cosmos-config/lib/property/property";
import { Project } from "../../../contexts/ProjectContext";
import { AccessRequest } from "../../../types/accessRequest";

export type AlertGeneratorOptions = {
  properties: Property[];
  projects: Project[];
};

export type AlertGenerator<T> = (item: T) => UserAlert;

interface Message {
  type: ("PROJECT" | "GLOBAL")[];
  sendercommonname: string;
  text: string;
  createdAt: number;
  link: string;
}

const alertGenerator = (type: UserAlertType) => {
  const id = uuidv4();
  const translationPath = `alert.${String(type).toLowerCase()}_description`;

  return {
    id,
    type,
    translationPath,
  };
};

const invalidDocumentAlertGenerator: AlertGenerator<Resource> = (item) => {
  return {
    ...alertGenerator(alertType.INVALID_DOCUMENT),
    translationOptions: {
      defaultValue: "Some of required attributes missing!",
    },
    title: item.displayname,
    linkTo: {
      resourceType: 2,
      identifier: item.identifier,
    },
    timestamp: item.lastmodifieddate,
  };
};

const messageAlertGenerator: AlertGenerator<Message> = (item) => {
  return {
    ...alertGenerator(alertType.MESSAGE),
    translationPath: null,
    translationOptions: {},
    title:
      item.type.includes("PROJECT") || item.type.includes("GLOBAL")
        ? "Cosmos"
        : `Message from ${item.sendercommonname}`,
    description: item.text,
    timestamp: item.createdAt,
    resourceId: item.link,
  };
};

const accessRequestAlertGenerator: AlertGenerator<AccessRequest> = (item) => {
  return {
    ...alertGenerator(alertType.PROJECT_ACCESS_REQUEST),
    title: `User ${item.requestorName} requests access to this module.`,
    translationPath: null,
    translationOptions: {},
    timestamp: item.createdAt,
  };
};

const useAlertGenerator = () => {
  const { getBusinessProcessProperties, getNscaleWorkflowProperties } =
    useProperties();
  const { projects } = useProject();

  const businessProcessTakAlertGenerator: AlertGenerator<BusinessProcessTask> =
    useCallback(
      (item) => {
        const { processDefinitionKey, taskDefinitionKey } = item;

        const bpProperties = getBusinessProcessProperties(
          processDefinitionKey,
          taskDefinitionKey
        );

        return {
          ...alertGenerator(alertType.BUSINESS_PROCESS_TASK),
          translationOptions: {
            taskName: item.name,
            defaultValue:
              "A workflow task ({{ taskName }}) has been assigned to you.",
          },
          title: generateTitleTemplate(item.variables || {}, bpProperties),
          linkTo: {
            id: item.id,
          },
          timestamp: DateTime.fromMillis(item.createTime).toMillis(),
          processDefinitionKey: item.processDefinitionKey,
          taskId: item.id,
          administrative:
            item.variables?.resourceId == null ||
            item.variables?.administrative,
        };
      },
      [getBusinessProcessProperties]
    );

  const moduleSummaryAlertGenerator: AlertGenerator<ModuleSummary> =
    useCallback(
      (item) => {
        const projectsMap = _.keyBy(projects, "code");
        const title = projectsMap[item.projectCode]?.name || "Module Alerts";

        return {
          ...alertGenerator(alertType.MODULE_SUMMARY),
          translationOptions: {
            cardinality: item.cardinality,
            alertType: item.type,
            alertTypeName: `$t(alert.type_${String(
              item.type
            ).toLowerCase()}, { "count": ${item.cardinality} })`,
            defaultValue: `You have {{ cardinality }} {{ alertTypeName }}.`,
            interpolation: {
              skipOnVariables: false,
            },
          },
          title,
          description: `You have ${item.cardinality} ${item.type}.`,
          projectCode: item.projectCode,
          timestamp: item.timestamp,
        };
      },
      [projects]
    );

  const workflowTaskAlertGenerator: AlertGenerator<NscaleWorkflowTask> =
    useCallback(
      (item) => {
        const { identifier, resourceType } = parseResourceId(item.folderId);

        const workflowProperties = getNscaleWorkflowProperties(
          item.processdefinition
        );

        return {
          ...alertGenerator(alertType.WORKFLOW_TASK),
          translationOptions: {
            taskName: item.taskdisplayname,
            defaultValue:
              "A workflow task ({{ taskName }}) has been assigned to you.",
          },
          linkTo: {
            id: item.processinstanceid,
            resourceType: resourceType || undefined,
            identifier: identifier || undefined,
          },
          title: generateTitleTemplate(item, workflowProperties),
          timestamp: item.taskcreationdate,
          processDefinition: item.processdefinition,
        };
      },
      [getNscaleWorkflowProperties]
    );

  const alertGeneratorFactory = useCallback(
    (alertType: UserAlertType): AlertGenerator<any> => {
      switch (alertType) {
        case "BUSINESS_PROCESS_TASK":
          return businessProcessTakAlertGenerator;
        case "INVALID_DOCUMENT":
          return invalidDocumentAlertGenerator;
        case "MESSAGE":
          return messageAlertGenerator;
        case "MODULE_SUMMARY":
          return moduleSummaryAlertGenerator;
        case "WORKFLOW_TASK":
          return workflowTaskAlertGenerator;
        case "PROJECT_ACCESS_REQUEST":
          return accessRequestAlertGenerator;
        default:
          throw new Error(
            `Alert generator for alert type ${alertType} does not exists!`
          );
      }
    },
    [
      businessProcessTakAlertGenerator,
      moduleSummaryAlertGenerator,
      workflowTaskAlertGenerator,
    ]
  );

  return {
    alertGeneratorFactory,
  };
};

export default useAlertGenerator;
