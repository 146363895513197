import React, { useCallback, useMemo } from "react";
import UserSettingsContext from "../contexts/UserSettingsContext";
import useCurrentUser from "../hooks/useCurrentUser";
import useDocumentHistory from "../hooks/useDocumentHistory";
import _ from "lodash";

export interface UserSettingsProviderProps {
  children: React.ReactNode;
}

const UserSettingsProvider = ({ children }: UserSettingsProviderProps) => {
  const { principalId } = useCurrentUser();

  const {
    data: favouriteResources,
    loading,
    createEntry,
    deleteEntry,
  } = useDocumentHistory({
    scope: "favourite",
    principalId: principalId || undefined,
    paging: 500,
  });

  const {
    data: recentlyOpenedResources,
    createEntry: logRecentlyOpenedResource,
  } = useDocumentHistory({
    scope: "visited",
    principalId: principalId || undefined,
  });

  const favouriteResourcesMap = useMemo(
    () => _.keyBy(favouriteResources, "resourceId"),
    [favouriteResources]
  );

  const isResourceUserFavourite = useCallback(
    (resourceId: string) => {
      return favouriteResourcesMap[resourceId] != null;
    },
    [favouriteResourcesMap]
  );

  return (
    <UserSettingsContext.Provider
      value={{
        favouriteResources,
        loadingFavouriteResources: loading,
        isResourceUserFavourite,
        toggleResourceFavourite: useCallback(
          (resourceId: string) => {
            const favouriteEntry = favouriteResourcesMap[resourceId];
            if (favouriteEntry != null) {
              if (favouriteEntry.id == null) {
                throw new Error("Document history entry does not contain id!");
              }

              return deleteEntry(favouriteEntry.id);
            } else {
              return createEntry(resourceId);
            }
          },
          [createEntry, deleteEntry, favouriteResourcesMap]
        ),
        recentlyOpenedResources,
        logRecentlyOpenedResource,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsProvider;
