import { PancoInput, PancoSelect } from "cosmos-components";
import PropTypes from "prop-types";
import { widget, widgetType } from "cosmos-config/generator";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useCallback } from "react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const widgetTypeOptions = [
  {
    label: "Bar Chart",
    value: widgetType.aggregate.BAR_CHART,
  },
  {
    label: "Pie Chart",
    value: widgetType.aggregate.PIE_CHART,
  },
];

const PropertyWidgets = ({ widgets, onChange, className }) => {
  const handleWidgetChange = useCallback(
    (idx, updateCallback = (w) => w) => {
      if (idx === widgets.length) {
        onChange(
          [
            ...widgets,
            updateCallback(
              widget("New Widget", widgetType.aggregate.BAR_CHART)
            ),
          ].filter((w) => w != null)
        );
      } else {
        onChange(
          widgets
            .map((w, i) => {
              if (idx === i) {
                return updateCallback(w);
              }

              return w;
            })
            .filter((w) => w != null)
        );
      }
    },
    [widgets, onChange]
  );

  return (
    <div className={className}>
      {widgets.map((w, idx) => (
        <Row key={`${w.name} + ${idx}`}>
          <Col sm={7}>
            <PancoInput
              value={w.title}
              onChange={({ value }) => {
                handleWidgetChange(idx, (w) => ({ ...w, title: value }));
              }}
            />
          </Col>

          <Col sm={4}>
            <PancoSelect
              value={[w.type].filter((x) => x != null)}
              options={widgetTypeOptions}
              onChange={({ value }) => {
                const [v] = value;
                handleWidgetChange(idx, (w) => ({
                  ...w,
                  type: v,
                }));
              }}
            />
          </Col>

          <Col sm={1} className="text-center">
            <Button
              variant="link"
              className="text-danger"
              onClick={() => {
                handleWidgetChange(idx, () => null);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </Col>
        </Row>
      ))}

      <Button
        variant="link"
        onClick={() => {
          handleWidgetChange(widgets.length);
        }}
        className="mt-2"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        Add Widget
      </Button>
    </div>
  );
};

PropertyWidgets.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

PropertyWidgets.defaultProps = {
  widgets: [],
  onChange: () => {},
  className: null,
};

export default PropertyWidgets;
