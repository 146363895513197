import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import _ from "lodash";
import { forwardRef } from "react";
import styled, { css } from "styled-components";
import { uuidv4 } from "cosmos-config/utils";

type AccordionContextValue = {
  isCollapsed: (idx: string) => boolean;
  toggleCollapse: (
    id: string,
    collapsed?: boolean,
    preserveState?: boolean
  ) => void;
  registerItem: (id: string) => void;
  unRegisterItem: (id: string) => void;
  variant: "primary" | "complementary";
};

const AccordionContext = createContext<AccordionContextValue>({
  isCollapsed: () => true,
  toggleCollapse: () => {},
  registerItem: () => {},
  unRegisterItem: () => {},
  variant: "primary",
});

export interface AccordionLinkProps {
  title?: string;
  to?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const AccordionLink = ({
  title,
  to,
  onClick,
  disabled,
}: AccordionLinkProps) => {
  const { variant } = useContext(AccordionContext);

  return (
    <AccordionContentBody
      disabled={!!disabled}
      variant={variant}
      active={false}
    >
      <AccordionContentHeader
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        className="accordion-content-header"
        onClick={() => {
          if (!disabled) {
            if (to != null) {
              // navigate(to);
            }

            if (onClick != null) {
              onClick();
            }
          }
        }}
      >
        <h4>{title}</h4>
      </AccordionContentHeader>
    </AccordionContentBody>
  );
};

const AccordionContentHeader = styled(motion.header)``;

type AccordionContentBodyProps = {
  disabled: boolean;
  active: boolean;
  variant: "primary" | "complementary";
};

const AccordionContentBody = styled.div<AccordionContentBodyProps>`
  border-bottom: 2px solid ${(props) => props.theme.base};

  ${(props) =>
    props.disabled &&
    css`
    ${AccordionContentHeader} {
      h4 {
        color: ${(props) => props.theme.muted}
        cursor: default;
      }
    }
  `}

  ${AccordionContentHeader} {
    color: ${(props) => props.theme.base};
    cursor: pointer;
    padding: 15px;

    h4 {
      margin: 0;
    }
  }

  ${(props) =>
    !props.active &&
    css`
    border-color: ${
      props.variant === "primary"
        ? props.theme.primary
        : props.theme.complementary
    }
    transition: all 0.2s ease-in-out;

    ${AccordionContentHeader} {
      h4 {
        transition: color 0.2s easy-in-out;
        color: ${
          props.variant === "primary"
            ? props.theme.primary
            : props.theme.complementary
        }
      }
    }
  `}
`;

type AccordioWrapperProps = {
  size: "sm" | "md";
};

const AccordionWrapper = styled.div<AccordioWrapperProps>`
  ${(props) =>
    props.size === "sm" &&
    css`
      ${AccordionContentHeader} {
        background-color: ;
        padding: calc(15px / 2) 15px;

        h4 {
          font-weight: bold;
          font-size: 1rem;
        }
      }
    `}
`;

export interface AccordionContentProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  defaultOpen?: boolean;
  title?: string;
}

export const AccordionContent = forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ title, children, className, disabled, defaultOpen }, ref) => {
  const uuid = useMemo(() => uuidv4(), []);

  const { isCollapsed, toggleCollapse, registerItem, unRegisterItem, variant } =
    useContext(AccordionContext);

  useEffect(() => {
    registerItem(uuid);

    return () => unRegisterItem(uuid);
  }, [registerItem, unRegisterItem, uuid]);

  useEffect(() => {
    if (disabled) {
      toggleCollapse(uuid, true);
    } else if (defaultOpen) {
      toggleCollapse(uuid, false);
    }
  }, [uuid, disabled, toggleCollapse, defaultOpen]);

  return (
    <AccordionContentBody
      active={!isCollapsed(uuid)}
      disabled={!!disabled}
      variant={variant}
    >
      <AccordionContentHeader
        ref={ref}
        initial={false}
        onClick={() => {
          if (!disabled) {
            toggleCollapse(uuid);
          }
        }}
      >
        <h4>{title}</h4>
      </AccordionContentHeader>
      <AnimatePresence initial={false}>
        {!isCollapsed(uuid) && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5 }}
            className={classNames("overflow-hidden", className)}
          >
            {children}
          </motion.section>
        )}
      </AnimatePresence>
    </AccordionContentBody>
  );
});

export interface AccordionProps {
  children?: React.ReactNode;
  multiOpen?: boolean;
  className?: string;
  size?: "sm" | "md";
  variant?: "primary" | "complementary";
  collapsed?: boolean;
}

const Accordion = ({
  children,
  multiOpen,
  className,
  size,
  variant,
  collapsed,
}: AccordionProps) => {
  const [collapseMap, setCollapseMap] = useState<Record<string, boolean>>({});

  return (
    <AccordionWrapper className={classNames(className)} size={size || "md"}>
      <AccordionContext.Provider
        value={{
          variant: variant || "primary",
          isCollapsed: (idx) => collapseMap[idx],
          toggleCollapse: useCallback(
            (id, collapsed, preserveState = false) => {
              setCollapseMap((cm) =>
                _.mapValues(cm, (c, key) => {
                  if (id !== key) {
                    return collapsed === false && preserveState
                      ? c
                      : !multiOpen;
                  }

                  return collapsed != null ? collapsed : !c;
                })
              );
            },
            [multiOpen]
          ),
          registerItem: useCallback(
            (id) => {
              setCollapseMap((cm) => ({
                ...cm,
                [id]: Object.keys(cm).length === 0 ? !!collapsed : true,
              }));
            },
            [collapsed]
          ),
          unRegisterItem: useCallback((id) => {
            setCollapseMap((cm) => _.omit(cm, id));
          }, []),
        }}
      >
        {children}
      </AccordionContext.Provider>
    </AccordionWrapper>
  );
};

Accordion.defaultProps = {
  size: "md",
} as AccordionProps;

Accordion.Link = AccordionLink;
Accordion.Content = AccordionContent;

export default Accordion;
