import React, { useRef } from "react";
import classNames from "classnames";
import {
  InputValueHandler,
  PancoInputElementProps,
} from "../../types/PancoInputElemenProps";
import styled from "styled-components";
import _ from "lodash";

function* values(
  initialValue: boolean | null | undefined,
  neutral = false
): Generator<boolean | null> {
  if (neutral) {
    while (true) {
      if (initialValue == null) {
        yield true;
      }

      yield* [null, initialValue == null ? false : !initialValue, null];

      if (initialValue != null) {
        yield !!initialValue;
      }
    }
  } else {
    while (true) {
      yield* [!initialValue, !!initialValue];
    }
  }
}

const SwitchLabel = styled.span`
  color: ${(props) => props.theme.base};
  user-select: none;

  &:before {
    background-color: ${(props) => props.theme.background};
  }
`;

const SwitchHandle = styled.label``;

const SwitchWrapper = styled.div`
  padding: 0.375rem 0.75rem;
  white-space: nowrap;

  .custom-control-input:checked ~ ${SwitchHandle}:before {
    background-color: ${(props) => props.theme.primary};
    border-color: ${(props) => props.theme.primary};
  }
`;

type SwitchComponentProps = {
  neutral: boolean;
};

const SwitchComponent = styled.div<SwitchComponentProps>`
  display: inline;
  user-select: none;
  margin-left: 7px;

  & > ${SwitchHandle}:after {
    transform: ${(props) => (props.neutral ? "translate(0.37rem)" : "none")};
  }
`;

export interface PancoSwitchProps
  extends Omit<
      PancoInputElementProps,
      "multiple" | "placeholder" | "clearable"
    >,
    InputValueHandler<boolean> {
  neutral?: boolean;
  labelLeft?: string;
  labelRight?: string;
}

const PancoSwitch = ({
  name,
  disabled,
  value,
  onChange,
  neutral,
  labelLeft,
  labelRight,
  className,
}: PancoSwitchProps) => {
  const valuesGenerator = useRef<Generator<boolean | null>>(
    values(value, neutral)
  );

  const handleValueChange = (nextValue?: boolean) => {
    if (!disabled) {
      const nv = _.isUndefined(nextValue)
        ? valuesGenerator.current.next().value
        : nextValue;

      if (onChange != null) {
        onChange({ name, value: nv });
      }

      if (!_.isUndefined(nextValue)) {
        valuesGenerator.current = values(nextValue, neutral);
      }
    }
  };

  return (
    <SwitchWrapper className={classNames(className)}>
      <SwitchLabel
        role="button"
        onClick={() => handleValueChange(false)}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        {labelLeft || "No"}
      </SwitchLabel>
      <SwitchComponent
        className="custom-control custom-switch"
        neutral={value == null && !!neutral}
      >
        <input
          type="checkbox"
          id={name}
          className="custom-control-input"
          checked={!!value}
          onChange={() => {
            handleValueChange();
          }}
          disabled={disabled}
        />
        <SwitchHandle
          title=""
          className="custom-control-label"
          htmlFor={name}
        />
      </SwitchComponent>
      <SwitchLabel
        role="button"
        onClick={() => handleValueChange(true)}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        {labelRight || "Yes"}
      </SwitchLabel>
    </SwitchWrapper>
  );
};

export default PancoSwitch;
