export const HOME_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const OAUTH_REDIRECT_ROUTE = '/oauth/redirect';
export const DASHBOARD_ROUTE = '/dashboard';
export const SEARCH_ROUTE = '/search';
export const DOCUMENT_ROUTE = '/document';
export const DOCUMENT_REFERENCE_ROUTE = '/document-ref';
export const FOLDER_ROUTE = '/folder';
export const FOLDER_WIZARD_ROUTE = '/folder-wizard';
export const EXPLORER_ROUTE = '/explorer';
export const UPLOAD_ROUTE = '/upload';
export const REPORT_ROUTE = '/report';
export const PROCESS_ROUTE = '/process';
export const PROCESSES_ROUTE = '/processes';
export const TASKS_ROUTE = '/tasks';
export const CHAT_ROUTE = '/chat';
export const BUSINESS_PROCESS_ROUTE = '/business-process';

export const TASKS_MY_ROUTE = '/tasks/my-tasks';
export const TASKS_HISTORY_ROUTE = '/tasks/history';
export const TASKS_INITIATED_ROUTE = '/tasks/initiated';

export const TEAM_SPACES_ROUTE = '/team-spaces';
export const USER_GUIDE_ROUTE = '/user-guide';
export const DATA_PROTECTION_INFORMATION_ROUTE = '/data-protection-information';
export const CHANGELOG_ROUTE = '/changelog';
export const FAQ_ROUTE = '/faq';
export const METADATA_EDITOR_ROUTE = '/metadata-editor';
export const FOLDER_METADATA_EDITOR_ROUTE = '/folder-metadata-editor';
export const MULTITASKING_ROUTE = '/multitasking';
export const IMPORT_WIZARD_ROUTE = '/import-wizard';
export const REPOSITORY_IMPORTER = '/repository-importer';
export const STUB_REPLACEMENT = '/stub-replacement';
export const RECYCLE_BIN_ROUTE = '/recycle-bin';

// SETTINGS
export const SETTINGS_ROUTE = '/settings';
export const APPEARANCE_SETTINGS_ROUTE = '/appearance';
export const UPLOAD_PRESETS_SETTINGS_ROUTE = '/upload-presets';
export const FIXED_ATTRIBUTES_SETTINGS_ROUTE = '/fixed-attributes';
export const DEFAULT_FILTERS_SETTINGS_ROUTE = '/default-filters';
export const FOLDER_SWITCHER_SETTINGS_ROUTE = '/folder-switcher';
export const ACCESS_REQUESTS_SETTINGS_ROUTE = '/access-requests';
export const VALUESET_SETTINGS_ROUTE = '/valuesets';
export const MASTER_VALUESET_SETTINGS_ROUTE = '/master-valuesets';
export const MAIN_PROJECT_SETTINGS_ROUTE = '/project-main';
export const PROJECT_PROPERTIES_SETTINGS_ROUTE = '/project/properties';
export const PROJECT_PROPERTY_SETTINGS_ROUTE = '/property';
export const RECYCLE_BIN_SETTINGS_ROUTE = '/recycle-bin';
export const SHAREPOINT_SETTINGS_ROUTE = '/sharepoint';
export const MESSAGES_SETTINGS_ROUTE = '/messages';
export const MEMBERS_SETTINGS_ROUTE = '/team-members';
export const SPOC_SETTINGS_ROUTE = '/spoc';
export const QUERY_BUILDER_SETTINGS_ROUTE = '/query-builder';
export const REPOSITORY_IMPORT_SETTINGS_ROUTE = '/repository-import';
export const LANGUAGE_SETTINGS_ROUTE = '/language';
export const LOCALE_SETTINGS_ROUTE = '/locale';
export const EMAIL_PREFERENCES_ROUTE = '/email-preferences';
export const LINKED_DOCUMENTS_ROUTE = '/linked-documents';
export const DOCUMENT_STRUCTURE_ROUTE = '/document-structure';
