import { motion } from "framer-motion";
import PropTypes from "prop-types";

const variants = {
  open: { height: "auto", opacity: 1, overflow: "initial" },
  closed: { height: 0, opacity: 0, overflow: "hidden" },
};

const SlideDown = ({ children, collapsed, ...rest }) => {
  return (
    <motion.div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      variants={variants}
      initial="closed"
      animate={collapsed ? "closed" : "open"}
      transition={{ ease: "easeInOut", duration: 0.25 }}
    >
      <div>{children}</div>
    </motion.div>
  );
};

SlideDown.propTypes = {
  children: PropTypes.node,
  collapsed: PropTypes.bool,
};

SlideDown.defaultProps = {
  children: null,
  collapsed: true,
};

export default SlideDown;
