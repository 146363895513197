import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import { SlideDown } from "cosmos-components";
import CommentEntry from "./CommentEntry";
import { Comment } from "../../../apis/commentApi";
import { CommentsListContext } from "./CommentsList";

export interface RootCommentEntryProps {
  comment?: Comment;
  defaultCollapsed?: boolean;
  className?: string;
}

const RootCommentEntry = ({
  comment,
  defaultCollapsed,
  className,
}: RootCommentEntryProps) => {
  const { disabled } = useContext(CommentsListContext);

  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setCollapsed(!!defaultCollapsed);
  }, [defaultCollapsed]);

  const renderCommentsRecursive = (c: Comment[]) => {
    if (Array.isArray(c)) {
      return c.map((comm) => (
        <CommentEntry
          key={`comment-${comm.id}`}
          comment={comm}
          allowReply={!disabled}
        >
          {renderCommentsRecursive(comm.comments)}
        </CommentEntry>
      ));
    }

    return null;
  };

  const comments = useMemo(() => {
    return Array.isArray(comment?.comments) ? comment?.comments : [];
  }, [comment]);

  return (
    <CommentEntry
      key={`comment-${comment?.id}`}
      comment={comment}
      allowReply={!disabled && (!collapsed || comments?.length === 0)}
      className={className}
      allowStatus
      root
    >
      <SlideDown collapsed={collapsed}>
        {renderCommentsRecursive(comments || [])}
      </SlideDown>

      {comments != null && comments.length > 0 && (
        <div className="text-center">
          <Button variant="link" onClick={() => setCollapsed((c) => !c)}>
            {collapsed ? "Follow the Discussion" : "Hide the Discussion"}
            <FontAwesomeIcon
              icon={collapsed ? faAngleDoubleDown : faAngleDoubleUp}
              className="ml-2"
            />
          </Button>
        </div>
      )}
    </CommentEntry>
  );
};

RootCommentEntry.defaultProps = {
  defaultCollapsed: true,
};

export default RootCommentEntry;
