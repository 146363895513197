import { useMemo, useState } from "react";
import useProject from "../../../hooks/useProject";
import projectApi from "../apis/project.api";
import ProjectEditorPropertiesContext from "../contexts/ProjectEditorPropertiesContext";

const BusinessProcessPropertiesProvider = ({
  processDefinitionKey,
  children,
}) => {
  const [loading, setLoading] = useState(false);

  const { project, reloadBusinessProcess } = useProject();

  const projectCode = useMemo(() => project?.code, [project]);

  return (
    <ProjectEditorPropertiesContext.Provider
      value={{
        translationPathBase: useMemo(
          () => `${projectCode}_${processDefinitionKey}`,
          [processDefinitionKey, projectCode]
        ),
        loading,
        canManipulate: () => false,
        deleteProperty: (propertyName) => {
          setLoading(true);
          projectApi
            .deleteBusinessProcessProperty(
              projectCode,
              processDefinitionKey,
              propertyName
            )
            .then(() => reloadBusinessProcess(processDefinitionKey))
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
        updateProperty: (propertyName, property = {}) => {
          setLoading(true);
          projectApi
            .updateBusinessProcessProperty(
              projectCode,
              processDefinitionKey,
              propertyName,
              property
            )
            .then(() => reloadBusinessProcess(processDefinitionKey))
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
        moveProperty: (propertyName, after) => {
          setLoading(true);
          projectApi
            .moveBusinessProcessProperty(
              projectCode,
              processDefinitionKey,
              propertyName,
              after
            )
            .then(() => reloadBusinessProcess(processDefinitionKey))
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
      }}
    >
      {children}
    </ProjectEditorPropertiesContext.Provider>
  );
};

export default BusinessProcessPropertiesProvider;
