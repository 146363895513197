import { useCallback, useMemo } from "react";
import { propertyType } from "cosmos-config/generator";
import _ from "lodash";
import {
  Property,
  SelectableAttributeProperty,
} from "cosmos-config/lib/property/property";
import Resource from "../types/resource";
import { mix } from "polished";
import { useTheme } from "styled-components";
import usePropertyRender from "./usePropertyRender";

export interface UseAnticipatedValuesProps {
  properties?: Property[];
}

const valueEqual = (avValue: string | number, documentValue: any) => {
  const docV = Array.isArray(documentValue) ? documentValue : [documentValue];
  return _.compact(docV).includes(avValue);
};

const useAnticipatedValues = ({ properties }: UseAnticipatedValuesProps) => {
  const render = usePropertyRender(
    useMemo(() => properties || [], [properties])
  );
  const theme = useTheme();

  const anticipatedValues = useMemo(() => {
    return _(properties)
      .map((p) => p as SelectableAttributeProperty)
      .filter(
        (p) =>
          p.type === propertyType.SELECT &&
          Array.isArray(p.anticipatedValues) &&
          p.anticipatedValues.length > 0
      )
      .flatMap((p) => {
        return p.anticipatedValues.map((av) => ({
          ...av,
          propertyName: p.name,
        }));
      })
      .value();
  }, [properties]);

  const getResourceAnticipatedValues = useCallback(
    (resource: Resource) => {
      return anticipatedValues.filter((av) =>
        valueEqual(av.value, resource[av.propertyName])
      );
    },
    [anticipatedValues]
  );

  return {
    capablePropertyNames: useMemo(
      () => _(anticipatedValues).map("propertyName").uniq().value(),
      [anticipatedValues]
    ),
    getAnticipatedColor: useCallback(
      (propertyName: string, value: string | number) => {
        return anticipatedValues.filter(
          (av) =>
            av.propertyName === propertyName && valueEqual(av.value, value)
        );
      },
      [anticipatedValues]
    ),
    getListingColor: useCallback(
      (resource: Resource) => {
        if (resource.virtual) {
          return theme.body;
        }

        if (resource.obsolete) {
          return theme.muted;
        }

        if (!resource.valid) {
          return "#e7343f";
        }

        const textColor = getResourceAnticipatedValues(resource)
          .map((av) => av.listingColor)
          .reduce(
            (acc, cur) => (acc.length <= 0 ? cur : mix(0.5, acc, cur)),
            ""
          );

        if (textColor.length < 7) {
          return undefined;
        }

        return textColor;
      },
      [getResourceAnticipatedValues, theme]
    ),
    getWatermark: useCallback(
      (resource: Resource) => {
        return getResourceAnticipatedValues(resource).map((av) =>
          render(av.propertyName, av.value)
        );
      },
      [getResourceAnticipatedValues, render]
    ),
  };
};

export default useAnticipatedValues;
