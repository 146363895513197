import React, { useMemo } from "react";
import DocumentsTable from "./DocumentsTable";
import { CosmosWidget, CosmosWidgetProps } from "../../types/CosmosWidget";
import _ from "lodash";
import useCurrentUserSettings from "../../../../hooks/useCurrentUserSettings";

const RecentlyOpenedDocuments = ({
  onSelect,
}: CosmosWidgetProps): CosmosWidget => {
  const { recentlyOpenedResources } = useCurrentUserSettings();

  const distinctItems = useMemo(
    () => _.unionBy(recentlyOpenedResources, "resourceId"),
    [recentlyOpenedResources]
  );

  return (
    <DocumentsTable onSelect={onSelect} documents={distinctItems} includeDate />
  );
};

export default RecentlyOpenedDocuments;
