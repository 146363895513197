import { createContext } from "react";
import Resource from "../types/resource";

export enum UploadAdvisorStatus {
  NOT_AVAILABLE,
  READY,
  PROCESSING,
  ANALYZED,
  UPLOADING,
  FINALIZED,
}

export interface UploadAdvisorIssue {
  type: string;
  level: "warn" | "error";
  fileIdentifier: string;
}

export interface UploadAdvisorDuplicateIssue extends UploadAdvisorIssue {
  type: "duplicate";
  checksum: string;
  identifier?: number;
  resourceName?: string;
}

export interface UploadOptions {
  multipleFiles: boolean;
  singleDocument: boolean;
}

export interface UploadAdvisorContextValue {
  files: File[];
  reset: () => void;
  addFiles: (files: File[], options?: Partial<UploadOptions>) => void;
  removeFile: (fileIdentifier: string) => void;
  executeUpload: () => Promise<Partial<Resource>[]>;
  uploadAllowed: boolean;
  status: UploadAdvisorStatus;
  setPresetResource: (resource: Resource) => void;
  issues: UploadAdvisorIssue[];
  progress: number;
  filesCount: number;
  options: UploadOptions;
}

class ProviderNotInContextError extends Error {
  constructor() {
    super(
      "Upload Advisor provider is not in the current context! Please use the UploadAdvisor component in the application."
    );
  }
}

const UploadAdvisorContext = createContext<UploadAdvisorContextValue>({
  files: [],
  reset: () => {
    throw new ProviderNotInContextError();
  },
  addFiles: () => {
    throw new ProviderNotInContextError();
  },
  removeFile: () => {
    throw new ProviderNotInContextError();
  },
  executeUpload: () => {
    throw new ProviderNotInContextError();
  },
  uploadAllowed: false,
  status: UploadAdvisorStatus.NOT_AVAILABLE,
  setPresetResource: () => {
    throw new ProviderNotInContextError();
  },
  issues: [],
  progress: 0,
  filesCount: 0,
  options: {
    multipleFiles: false,
    singleDocument: false,
  },
});

export default UploadAdvisorContext;
