export const SET_SKIP_GUIDE = 'SET_SKIP_GUIDE';
export const SET_SKIP_GDPR = 'SET_SKIP_GDPR';
export const TOGGLE_METADATA_EDITOR = 'TOGGLE_METADATA_EDITOR';

export const FETCH_NOTIFICATION_PREFERENCES =
  'user/notificationPreferences/fetch';
export const TOGGLE_NOTIFICATION_PREFERENCE =
  'user/notificationPreference/toggle';
export const SET_NOTIFICATION_PREFERENCES = 'user/notificationPreferences/set';

export const SET_USER_SETTINGS = 'userSettings/set';
export const SAVE_USER_SETTINGS = 'userSettings/save';
export const SAVE_USER_GLOBAL_SETTINGS = 'userSettings/globalSave';
export const FETCH_USER_SETTINGS = 'userSettings/fetch';
