import styled from "styled-components";

const FieldActionIcon = styled.div`
  display: inline-block;
  color: ${(props) => props.theme.base};
  opacity: 0.5;
  transition: all 0.1s ease-in-out;

  &:hover {
    opacity: 1;
  }

  & + & {
    margin-left: 15px;
  }
`;

export default FieldActionIcon;
