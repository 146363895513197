/* eslint-disable func-names */
import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import * as actions from '../../Actions/types';
import { setBackgroundLoading } from '../../Actions/ui';
import userSettingsScope from '../../Constants/userSettingsScope';
import complete from '../Effects/complete';
import {
  saveAndFetchSettings,
  fetchAndStoreSettings,
} from '../userSettingsSaga';

function* settingsSaga(projectCode) {
  yield takeLatest(
    actions.userSettings.FETCH_USER_SETTINGS,
    function* (action) {
      const { scope } = action.payload;
      yield call(fetchAndStoreSettings, projectCode, scope);
      yield complete(action);
    }
  );

  yield takeLeading(
    actions.userSettings.SAVE_USER_SETTINGS,
    function* (action) {
      const { scope, data } = action.payload;
      yield call(saveAndFetchSettings, projectCode, scope, data);
      yield complete(action);
    }
  );

  yield put(
    setBackgroundLoading(true, 'Loading user UI preferences and settings')
  );
  yield all([
    call(
      fetchAndStoreSettings,
      projectCode,
      userSettingsScope.FIXED_ATTRIBUTES
    ),
    call(
      fetchAndStoreSettings,
      projectCode,
      userSettingsScope.TABLE_COLUMN_ORDER
    ),
    call(fetchAndStoreSettings, projectCode, userSettingsScope.UPLOAD_PRESETS),
    call(fetchAndStoreSettings, projectCode, userSettingsScope.DEFAULT_FILTERS),
  ]);

  yield put(setBackgroundLoading(false));
}

export default settingsSaga;
