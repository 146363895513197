/**
 * @interface
 */
export interface Set {
  label: string;
  name: string;
  description: string | null;
  set: boolean;
  group: boolean;
  properties: Array<any>;

  of: Function;
  describe: Function;
}

/**
 * Set generator.
 * @author Patrik Pancisin
 * @class set
 * @classdesc Basic building block of cosmos.
 * @param label - The label of the set displayed in cosmos.
 * @param name - Internal name of the structure.
 */
const set = (label: string, name: string): Set => /** @lends set */ ({
  label,
  name,
  description: null,
  set: true,
  group: false,
  properties: [],

  /**
   * @instance
   * @description test
   * @param properties
   */
  of(properties: Array<any>) {
    this.properties = properties;
    return this;
  },

  /**
   * @instance
   * @description desc
   * @param description
   */
  describe(description: string) {
    this.description = description;
    return this;
  },
});

export default set;
