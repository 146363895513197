/**
 * @enum {string}
 * @category Property
 * @readonly
 */
enum FilterGroup {
  MAIN = "main",
  ADDITIONAL = "additional",
  FOLDER = "folder",
  FOLDER_ADDITIONAL = "folder_additional",
  MAIN_FOLDER = "main_folder",
  ADDITIONAL_FOLDER_ADDITIONAL = "additional_folder_additional",
}

export default FilterGroup;
