import React, { useCallback, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { PancoInput } from "cosmos-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import UniversalValuePicker from "./UniversalValuePicker";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ValuesetOption } from "cosmos-config/lib/property/property";
import _ from "lodash";
import { useTheme } from "styled-components";

type StaticValuesetOptionRowProps = {
  option?: ValuesetOption;
  collapsed?: boolean;
  onChange?: (option: Partial<ValuesetOption> | null) => void;
  onExpand?: () => void;
  renderIdentifier?: () => React.ReactNode;
};

const StaticValuesetOptionRow = ({
  option,
  collapsed,
  onChange,
  onExpand,
  renderIdentifier,
}: StaticValuesetOptionRowProps) => {
  const theme = useTheme();
  return (
    <>
      <tr
        style={{
          backgroundColor: !collapsed ? "lightyellow" : theme.background,
        }}
      >
        <td className="text-center" style={{ verticalAlign: "middle" }}>
          {renderIdentifier != null && renderIdentifier()}
        </td>
        <td>
          <PancoInput
            value={option?.label}
            boxed
            onChange={({ value }) => {
              if (onChange != null) {
                const label =
                  (Array.isArray(value) ? value[0] : value) || undefined;
                onChange({ label });
              }
            }}
          />
        </td>
        <td style={{ width: 75 }}>
          <Button
            variant="link"
            onClick={() => {
              if (onExpand != null) {
                onExpand();
              }
            }}
          >
            {option?.value || "null"}
          </Button>
        </td>
        <td style={{ width: 30 }}>
          <Button
            variant="link"
            onClick={() => {
              if (onChange != null) {
                onChange(null);
              }
            }}
            className="text-danger"
            size="sm"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </td>
      </tr>
      {!collapsed && (
        <tr style={{ backgroundColor: "lightyellow" }}>
          <td />
          <td colSpan={2}>
            <div className="">
              <Card body>
                <b>Value Picker: </b>
                <UniversalValuePicker
                  className="mt-2"
                  onChange={(value) => {
                    if (onChange != null) {
                      onChange({ value: String(value) });
                    }
                  }}
                />
              </Card>
            </div>
          </td>
          <td />
        </tr>
      )}
    </>
  );
};

export interface StaticValuesetOptionsProps {
  valueset?: ValuesetOption[];
  onChange?: (options: Partial<ValuesetOption>[]) => void;
}

const StaticValuesetOptions = ({
  valueset,
  onChange,
}: StaticValuesetOptionsProps) => {
  const [expandedRowIdx, setExpandedRowIdx] = useState<number | null>(null);

  const handleItemChange = useCallback(
    (
      idx: number,
      updateCallback: (
        option: Partial<ValuesetOption>
      ) => Partial<ValuesetOption> | null
    ) => {
      const nonEmpty = (v: Partial<ValuesetOption> | null) =>
        v != null && v.value !== "" && v.label !== "";

      if (onChange == null) {
        return;
      }

      if (idx === valueset?.length) {
        onChange(_.compact([...valueset, updateCallback({})].filter(nonEmpty)));
      } else {
        onChange(
          _.compact(
            (valueset || [])
              .map((item, itemIndex) => {
                return idx === itemIndex ? updateCallback(item) : item;
              })
              .filter(nonEmpty)
          )
        );
      }
    },
    [onChange, valueset]
  );

  return (
    <div>
      <table className="table table-borderless">
        <thead>
          <tr>
            <th />
            <th>Label</th>
            <th>Value</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {(valueset || []).map((option, idx) => (
            <StaticValuesetOptionRow
              collapsed={idx !== expandedRowIdx}
              onExpand={() => setExpandedRowIdx(idx)}
              option={option}
              key={idx}
              renderIdentifier={() => <b>{idx + 1}.</b>}
              onChange={(changedItem) => {
                setExpandedRowIdx(null);

                if (changedItem == null) {
                  handleItemChange(idx, () => null);
                } else {
                  handleItemChange(idx, (item) => ({
                    ...item,
                    ...changedItem,
                  }));
                }
              }}
            />
          ))}

          <tr>
            <td></td>
            <td colSpan={2}>
              <Button
                variant="link"
                onClick={() => {
                  if (valueset != null) {
                    handleItemChange(valueset.length, (item) => ({}));
                  }
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Option
              </Button>
            </td>
          </tr>

          {/* <StaticValuesetOptionRow
            key={valueset.length}
            onChange={(changedItem) => {
              handleItemChange(valueset.length, (item) => ({
                ...item,
                ...changedItem,
              }));
            }}
          /> */}
        </tbody>
      </table>
    </div>
  );
};

export default StaticValuesetOptions;
