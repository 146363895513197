import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import versionReviewProperties from "../../../contants/versionReviewProperties";
import { useQuery } from "@tanstack/react-query";
import repositoryApi, {
  ProcessInstance,
  Version,
} from "../../../apis/repositoryApi";
import useCurrentUser from "../../../hooks/useCurrentUser";
import {
  filterVersionsWithin,
  generateVersionList,
  renderTimeInformation,
} from "./reviewHistoryUtils";
import useResourceVersions from "../../../hooks/useResourceVersions";
import ReviewOverview from "./ReviewOverview";
import useProject from "../../../hooks/useProject";
import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import _ from "lodash";
import { Comment, commentStatus } from "../../../apis/commentApi";
import { ReviewStatusIndicator } from "cosmos-components";
import useProjectMember from "../../../hooks/useProjectMember";
import useResourceComments from "../../../hooks/useResourceComments";

type ReviewNavCardProps = {
  active?: boolean;
};

const ReviewNavCard = styled(Card)<ReviewNavCardProps>`
  border-color: ${(props) =>
    props.active ? props.theme.primary : "inherited"} !important;
  cursor: pointer;

  & ~ & {
    margin-top: 15px;
  }
`;

type ReviewCardProps = {
  processInstance: ProcessInstance;
  onSelect: () => void;
  active: boolean;
};

const ReviewCard = ({ processInstance, active, onSelect }: ReviewCardProps) => {
  const initiatorPrincipalId = useMemo(
    () => processInstance?.variables?.initiator,
    [processInstance]
  );

  const { name } = useProjectMember({
    principalId: initiatorPrincipalId,
  });

  const reviewStatus = useMemo(
    () => processInstance.variables?.resource?.reviewStatus,
    [processInstance]
  );

  return (
    <ReviewNavCard active={active} body onClick={onSelect}>
      <h5 className="d-flex align-items-center">
        <ReviewStatusIndicator
          title={reviewStatus}
          status={reviewStatus}
          pulse={!!processInstance?.active}
        />
        <span className="ml-2">{processInstance.name}</span>
      </h5>

      <p className="text-muted">{renderTimeInformation(processInstance)}</p>

      <span>
        <b>Initiator:</b> {name}
      </span>
    </ReviewNavCard>
  );
};

type ReviewHistoryContextValue = {
  getVersions: (fromMillis: number, toMillis: number) => Version[];
  getResourceVersions: (fromMillis: number, toMillis: number) => Version[];
  getResourceVersion: (modifiedAt: number) => Version | null;
  getVersionResources: (
    processInstance?: ProcessInstance
  ) => Record<string, any>[];
  getReviewComments: (processInstance?: ProcessInstance) => Comment[];
};

export const ReviewHistoryContext = createContext<ReviewHistoryContextValue>({
  getVersions: () => [],
  getResourceVersions: () => [],
  getResourceVersion: () => null,
  getVersionResources: () => [],
  getReviewComments: () => [],
});

export interface ReviewHistoryProps {
  resourceId: string;
}

const updatableProperties = versionReviewProperties.filter((p) => p.editable);

const ReviewHistory = ({ resourceId }: ReviewHistoryProps) => {
  const { authenticated } = useCurrentUser();
  const { project } = useProject();

  const [selectedProcess, setSelectedProcess] = useState<ProcessInstance>();

  const versions = useResourceVersions({
    resourceId,
    properties: updatableProperties,
  });

  const resourceVersions = useResourceVersions({
    resourceId,
    projectCode: project?.code,
  });

  const { data } = useQuery({
    queryKey: ["business-process-history", resourceId],
    queryFn: () =>
      repositoryApi.getResourceBusinessProcessHistory(resourceId || ""),
    enabled: resourceId != null && authenticated,
    refetchOnWindowFocus: true,
  });

  const processInstances = useMemo(() => {
    return _.orderBy(data, ["startTime"], ["asc"]).map((p, idx, arr) => ({
      ...p,
      name: `${idx + 1}. ${p.name}`,
    }));
  }, [data]);

  useEffect(() => {
    setSelectedProcess((sp) => {
      const pi = processInstances[processInstances.length - 1];
      if (sp == null && pi != null) {
        return pi;
      }
      return sp;
    });
  }, [processInstances]);

  const getVersions = useCallback(
    (fromMillis: number, toMillis: number | null) =>
      filterVersionsWithin(versions, fromMillis, toMillis),
    [versions]
  );

  const getResourceVersions = useCallback(
    (fromMillis: number, toMillis: number | null) =>
      filterVersionsWithin(resourceVersions, fromMillis, toMillis),
    [resourceVersions]
  );

  const { comments } = useResourceComments({ resourceId });

  return (
    <ReviewHistoryContext.Provider
      value={{
        getVersions,
        getResourceVersions,
        getVersionResources: useCallback(
          (processInstance) => {
            if (processInstance == null) {
              return [];
            }

            const { startTime, endTime } = processInstance;

            const revVer = getVersions(startTime, endTime);
            const resVer = getResourceVersions(startTime, endTime);

            return [...generateVersionList(revVer, resVer)].map((r) => ({
              ...r.resource,
              ..._.pick(r, [
                "creatorCommonName",
                "lastModifiedDate",
                "resourceVersion",
                "contentChanged",
              ]),
            }));
          },
          [getResourceVersions, getVersions]
        ),
        getResourceVersion: useCallback(
          (modifiedAt) => {
            return (
              resourceVersions.find(
                (rv) => rv.lastModifiedDate === modifiedAt
              ) || null
            );
          },
          [resourceVersions]
        ),
        getReviewComments: useCallback(
          (processInstance) => {
            if (processInstance == null) {
              return [];
            }

            return (comments || []).filter(
              (comment) =>
                (processInstance.startTime < comment.createdAt ||
                  comment.status === commentStatus.OPEN) &&
                (processInstance.endTime == null ||
                  processInstance.endTime > comment.createdAt)
            );
          },
          [comments]
        ),
      }}
    >
      <Row>
        <Col md={3}>
          {processInstances != null &&
            processInstances.map((processInstance) => (
              <ReviewCard
                key={processInstance.id}
                processInstance={processInstance}
                onSelect={() => setSelectedProcess(processInstance)}
                active={selectedProcess?.id === processInstance.id}
              />
            ))}
        </Col>
        <Col md={9}>
          {selectedProcess && (
            <ReviewOverview processInstance={selectedProcess} />
          )}
        </Col>
      </Row>
    </ReviewHistoryContext.Provider>
  );
};

export default ReviewHistory;
