import React, { FunctionComponent, createElement as h } from "react";
import RecentlyOpenedDocuments from "./widgets/RecentlyOpenedDocuments";
import DistinctPropertyData from "./providers/DistinctPropertyData";
import RecentlyUploadedDocuments from "./widgets/RecentlyUploadedDocuments";
import DocumentsPerCountry from "./widgets/DocumentsPerCountry";
import FavouriteDocuments from "./widgets/FavouriteDocuments";
import Greeting from "./widgets/Greeting";
import MyTasks from "./widgets/MyTasks";
import { widgetType } from "cosmos-config/generator";
import ExpiringDocuments from "./widgets/ExpiringDocuments";
import ProjectQueryData from "./providers/ProjectQueryData";
import CommentsAggregationData from "./providers/CommentsAggregationData";
import PieChart from "./renderers/PieChart";
import BarChart from "./renderers/BarChart";
import { CosmosChartProps } from "../types/CosmosChart";
import {
  CosmosWidgetOnSelectCallback,
  CosmosWidgetProps,
} from "../types/CosmosWidget";
import { Widget } from "cosmos-config/lib/widget/widget";

const getChart = (type: string): FunctionComponent<CosmosChartProps> => {
  switch (type) {
    default:
    case widgetType.aggregate.PIE_CHART:
      return PieChart;
    case widgetType.aggregate.BAR_CHART:
      return BarChart;
  }
};

const getWidget = (
  type: string
): FunctionComponent<CosmosWidgetProps> | null => {
  switch (type) {
    case widgetType.data.CHOROPLETH_CHART:
      return DocumentsPerCountry;
    case widgetType.data.EXPIRING_DOCUMENTS:
      return ExpiringDocuments;
    case widgetType.general.RECENTLY_OPENED_TABLE:
      return RecentlyOpenedDocuments;
    case widgetType.general.RECENTLY_UPLOADED_TABLE:
      return RecentlyUploadedDocuments;
    case widgetType.general.FAVOURITE_TABLE:
      return FavouriteDocuments;
    case widgetType.general.GREETING:
      return Greeting;
    case widgetType.general.TASKS_TABLE:
      return MyTasks;
    default:
      return null;
  }
};

export interface CosmosWidgetComponentProps {
  widget?: Widget;
  onSelect?: CosmosWidgetOnSelectCallback;
}

const CosmosWidgetComponent = ({
  widget,
  onSelect,
}: CosmosWidgetComponentProps) => {
  if (widget == null) {
    return React.Fragment({});
  }

  const { type, propertyName, resourceType } = widget;

  if (type === "comments-status") {
    return h(CommentsAggregationData, {
      children: ({ data }) => {
        return h(PieChart, { data });
      },
    });
  }

  if (Object.values(widgetType.aggregate).includes(type)) {
    if (widget.query != null) {
      return h(ProjectQueryData, {
        queryId: widget.query,
        children: ({ data, keyProperty }) => {
          return h(getChart(type), {
            data,
            onClick: (data) => {
              if (onSelect != null) {
                onSelect("filter", data.id, keyProperty);
              }
            },
          });
        },
      });
    }

    if (widget.chart) {
      return h(DistinctPropertyData, {
        propertyName,
        resourceType,
        children: ({ data }) => {
          return h(getChart(type), {
            data,
            onClick: (data) => {
              if (onSelect != null) {
                onSelect("filter", data.id, propertyName || undefined);
              }
            },
          });
        },
      });
    }
  }

  const widgetComponent = getWidget(type);
  if (widgetComponent != null) {
    return h(widgetComponent, {
      propertyName,
      resourceType,
      onSelect,
    });
  }

  return React.Fragment({});
};

export default CosmosWidgetComponent;
