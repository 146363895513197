import * as actions from '../Actions/types';

const initialState = {
  homeAccountId: localStorage.getItem('homeAccountId'),
  searchResults: [],
  rendition: null,
  selectedDocuments: [],
  loading: false,
  loadingRendition: false,
  drives: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.sharepoint.SET_HOME_ACCOUNT_ID:
      if (action.homeAccountId == null) {
        localStorage.removeItem('homeAccountId');
      } else {
        localStorage.setItem('homeAccountId', action.homeAccountId);
      }

      return {
        ...state,
        homeAccountId: action.homeAccountId,
      };
    case actions.sharepoint.SET_SEARCH_RESULTS_SHAREPOINT:
      return {
        ...state,
        searchResults: action.searchResults,
      };
    case actions.sharepoint.SET_RENDITION_SHAREPOINT:
      return {
        ...state,
        rendition: action.rendition,
      };
    case actions.sharepoint.SELECT_DOCUMENTS_SHAREPOINT:
      return {
        ...state,
        selectedDocuments: action.documents,
      };
    case actions.sharepoint.SET_LOADING_SHAREPOINT:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.sharepoint.SET_LOADING_RENDITION_SHAREPOINT:
      return {
        ...state,
        loadingRendition: action.loading,
      };
    case actions.sharepoint.SET_SHAREPOINT_DRIVES:
      return {
        ...state,
        drives: action.drives,
      };
    default:
      return state;
  }
};
