import { darken, lighten } from "polished";
import React from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

export interface CosmosTheme {
  primary: string;
  complementary: string;
  body: string;
  base: string;
  muted: string;
  background: string;
  scheme: string;
}

const light: CosmosTheme = {
  primary: "#3ed8c3",
  complementary: "#ef7d00",
  body: "#212529",
  base: "#1d4477",
  muted: lighten(0.3, "#212529"),
  background: "#fff",
  scheme: "light",
};

const dark: CosmosTheme = {
  primary: "#4ddbc8",
  complementary: "#ef7d00",
  body: "#b2aca2",
  base: "#8fbbe4",
  muted: darken(0.3, "#b2aca2"),
  background: "#181a1b",
  scheme: "dark",
};

type ThemeName = "light" | "dark";

const getTheme = (theme?: ThemeName): CosmosTheme => {
  switch (theme) {
    case "dark":
      return dark;
    default:
    case "light":
      return light;
  }
};

export interface CosmosThemeProviderProps {
  theme?: ThemeName;
  children?: React.ReactNode;
}

const CosmosThemeProvider = ({ theme, children }: CosmosThemeProviderProps) => {
  // const inheritedTheme = useTheme();

  const inheritedTheme = React.useContext(ThemeContext);

  return (
    <ThemeProvider
      theme={inheritedTheme == null ? getTheme(theme) : inheritedTheme}
    >
      {children}
    </ThemeProvider>
  );
};

export default CosmosThemeProvider;
