import './Assets/scss/main.scss';

import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './Reducers';
import indexSaga from './Sagas';
import { docareaService, projectService, i18n } from 'cosmos-core';
import './globals';
import localPropertiesService from './Services/localPropertiesService';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createRoot } from 'react-dom/client';

import * as repositoryActions from './Actions/repository';
import { I18nextProvider } from 'react-i18next';

const sagaMiddleware = createSagaMiddleware({
  context: {
    docareaService,
    projectService,
    localPropertiesService,
  },
});

const sagaCompleteMiddleware = () => (next) => (action) => {
  return new Promise((resolve) => {
    next({ ...action, onComplete: (...args) => resolve(...args) });
  });
};

const composeEnhancers = composeWithDevTools({
  name: 'Cosmos',
  actionCreators: repositoryActions,
  trace: true,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaCompleteMiddleware, sagaMiddleware))
);
sagaMiddleware.run(indexSaga);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
