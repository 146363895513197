import _ from "lodash";
import { useCallback, useMemo } from "react";
import useProperties from "../useProperties";
import useCurrentUser from "../useCurrentUser";
import Resource from "../../types/resource";

const useMaintainerPermission = (input?: Resource | Resource[]) => {
  const { defaultPositionId, principalId } = useCurrentUser();
  const { folders: folderDefinitions } = useProperties();

  const resources = useMemo(() => {
    if (Array.isArray(input)) {
      return input;
    }

    return _.compact([input]);
  }, [input]);

  const folderDefinitionsMap = useMemo(
    () => _.keyBy(folderDefinitions, "name"),
    [folderDefinitions]
  );

  const folderTypes = useMemo(
    () =>
      _(resources)
        .map((res) => {
          const { foldertype, parentfoldertype, resourcetype } = res;
          return resourcetype === 1 ? foldertype : parentfoldertype;
        })
        .compact()
        .uniq()
        .value(),
    [resources]
  );

  const maintainedProperties = useMemo(
    () =>
      _(folderDefinitionsMap).pick(folderTypes).flatMap("maintainers").value(),
    [folderDefinitionsMap, folderTypes]
  );

  const maintainers = useMemo(() => {
    return _(resources)
      .flatMap((folder) => {
        return _(folder)
          .pick(maintainedProperties)
          .flatMap((x) => x)
          .value();
      })
      .uniq()
      .value();
  }, [resources, maintainedProperties]);

  const canUpdate = useCallback(() => {
    return _(folderDefinitionsMap).pick(folderTypes).every("updatable");
  }, [folderDefinitionsMap, folderTypes]);

  const isMaintainer = useCallback(() => {
    return _.some(maintainers, (m) =>
      [defaultPositionId, principalId].includes(m)
    );
  }, [defaultPositionId, principalId, maintainers]);

  const canControl = useCallback((): boolean => {
    return (canUpdate() && maintainedProperties.length === 0) || isMaintainer();
  }, [isMaintainer, maintainedProperties.length, canUpdate]);

  return {
    canControl,
  };
};

export default useMaintainerPermission;
