import { useContext, useMemo, useState } from "react";
import {
  faPlus,
  faCaretRight,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button, Row, Col } from "react-bootstrap";
import PropertiesGroup from "./PropertiesGroup";
import PropTypes from "prop-types";
import { LoadingOverlay, SlideDown } from "cosmos-components";
import SimpleUniversalForm from "../../../components/SimpleUniversalForm";
import folderProperties from "../constants/folderProperties";
import PropertyTranslations from "./property-editor/PropertyTranslations";
import useProjectElementEditing from "../hooks/useProjectElementEditing";
import ProjectEditorPropertiesContext from "../contexts/ProjectEditorPropertiesContext";
import ResourceTypeProvider from "../providers/ResourceTypeProvider";
import ResourceTypeContext from "../contexts/ResourceTypeContext";
import SimpleFormGroup from "../../../components/SimpleFormGroup";

const CreateGroupCard = () => {
  const { createGroup } = useContext(ResourceTypeContext);

  return (
    <Card className="border-primary">
      <Card.Body
        tabIndex={0}
        onKeyDown={() => {}}
        role="button"
        onClick={() => {
          createGroup();
        }}
        className="d-flex flex-column align-items-center justify-content-center text-primary"
      >
        <div>
          <FontAwesomeIcon icon={faPlus} size="3x" className="mb-2" />
        </div>
        <small>Create Group</small>
      </Card.Body>
    </Card>
  );
};

const ResourceTypeProperties = ({ groups }) => {
  const [optionsCollapsed, setOptionsCollapsed] = useState(true);

  const {
    folder,
    resourceType,
    loading,
    updateFolder,
    translationPathBase,
    canManipulate,
  } = useContext(ProjectEditorPropertiesContext);

  const { formResource, updateElement, getSubmitResource } =
    useProjectElementEditing({
      resource: folder,
      properties: folderProperties,
    });

  const folderType = useMemo(() => folder?.name, [folder]);

  function* generateGroups(grps, resourceType) {
    yield* grps
      // .filter((g) => g.editable)
      .map((group) => (
        <PropertiesGroup
          group={group}
          resourceType={resourceType}
          folderType={folderType}
        />
      ));

    if (canManipulate()) {
      yield <CreateGroupCard />;
    }
  }

  return (
    <div className="position-relative">
      <ResourceTypeProvider>
        <LoadingOverlay loading={loading}>
          {resourceType === 1 && folderType != null && (
            <Card className="mb-3">
              <Card.Header className="bg-info text-light">
                <div
                  onClick={() => {
                    setOptionsCollapsed((c) => !c);
                  }}
                  role="button"
                  className="d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    className="mr-3"
                    icon={optionsCollapsed ? faCaretRight : faCaretDown}
                  />
                  <b>Options</b>
                </div>
              </Card.Header>
              <SlideDown collapsed={optionsCollapsed}>
                <Card.Body>
                  <Row>
                    <Col md={{ span: 9, offset: 1 }}>
                      <SimpleUniversalForm
                        resource={formResource}
                        properties={folderProperties}
                        onSubmit={() => {
                          updateFolder(folderType, getSubmitResource());
                        }}
                        onUpdateResource={(id, name, value) => {
                          updateElement(name, value);
                        }}
                        inline
                      >
                        {({ submit }) => (
                          <div>
                            <SimpleFormGroup label="Translation" inline>
                              <PropertyTranslations
                                basePath={translationPathBase}
                                translationPath={
                                  formResource.translation || folder.translation
                                }
                                onChange={(translation) => {
                                  updateElement("translation", translation);
                                }}
                              />
                            </SimpleFormGroup>

                            <div className="text-right">
                              <Button onClick={submit} size="lg">
                                Submit
                              </Button>
                            </div>
                          </div>
                        )}
                      </SimpleUniversalForm>
                    </Col>
                  </Row>
                </Card.Body>
              </SlideDown>
            </Card>
          )}

          {[...generateGroups(groups, resourceType)]}
        </LoadingOverlay>
      </ResourceTypeProvider>
    </div>
  );
};

ResourceTypeProperties.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})),
};

ResourceTypeProperties.defaultProps = {
  groups: [],
};

export default ResourceTypeProperties;
