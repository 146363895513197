import { shadeColor } from "cosmos-components";
import { transparentize } from "polished";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    font-size: 14px;
  }

  body {
    margin: 0;
    color: ${(props) => props.theme.body};
    background: ${(props) => props.theme.background};
    color-scheme: ${(props) => props.theme.scheme};
  }
  
  .table {
    color: ${(props) => props.theme.body}
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: ${(props) => props.theme.base}
  }

  .breadcrumb, .card-header, .popover-header {
    background-color: ${(props) => shadeColor(0.05, props.theme.background)};
  }

  .bg-light {
    background-color: ${(props) =>
      shadeColor(0.05, props.theme.background)} !important;
  }

  .modal-header, .modal-footer, .dropdown-divider, .border-top, .border-right, hr {
    border-color: ${(props) =>
      shadeColor(0.15, props.theme.background)} !important;
  }

  .popover-header {
    color: ${(props) => props.theme.base}
  }

  .list-group-item {
    color: ${(props) => props.theme.body};

    &.active {
      background-color: ${(props) => props.theme.primary};
    }

    &:hover, &:focus {
      &:not(.active) {
        color: ${(props) => props.theme.body};
        background-color: ${(props) =>
          shadeColor(0.05, props.theme.background)};
      }
    }
    
    &.disabled {
      background-color: ${(props) => props.theme.background} !important;
    }
  }

  .card, .list-group-item, .list-group-item:disabled {
    border-color: ${(props) => shadeColor(0.125, props.theme.background)};
    background-color: ${(props) => props.theme.background};
  }

  .modal-content, .dropdown-menu, .popover, .custom-switch .custom-control-label:before, .input-group-text {
    background-color: ${(props) => props.theme.background};
  }

  .form-control {
    background-color: ${(props) => props.theme.background};
    border-color: ${(props) => shadeColor(0.15, props.theme.background)};

    &:focus {
      background-color: ${(props) => props.theme.background};
    }
  }

  .modal-content, .dropdown-menu, .popover-header, .popover, .table tbody tr td, .input-group-text {
    border-color: ${(props) => shadeColor(0.15, props.theme.background)}
  }
  
  .dropdown-item, .popover-body, .input-group-text {
    color: ${(props) => props.theme.body}
  }

  .dropdown-item {
    &:hover {
      background-color: ${(props) => shadeColor(0.15, props.theme.background)};
      color: ${(props) => props.theme.body}
    }
  }

  .navbar {
    .navbar-brand {
      color: ${(props) => props.theme.base} !important;
    }
  }

  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: ${(props) => transparentize(0.5, props.theme.body)};
      }
    }
  }

  .nav-tabs {
    border-color: ${(props) => shadeColor(0.15, props.theme.background)};

    .nav-link {
      &.active {
        background-color: ${(props) => props.theme.background};
        border-color: ${(props) => shadeColor(0.15, props.theme.background)};
        color: ${(props) => props.theme.body}
      }

      &:hover {
        border-color: ${(props) => shadeColor(0.15, props.theme.background)};
      }
    }
  }

  .text-dark {
    color: ${(props) => props.theme.base} !important;
  }

  .text-muted {
    color: ${(props) => props.theme.muted} !important;
  }

  .btn {
    border-radius: 0;

    &.btn-primary {
      background-color: ${(props) => props.theme.primary};
      border-color: ${(props) => props.theme.primary};

      &:hover {
        background-color: ${(props) => props.theme.base};
        border-color: ${(props) => props.theme.base};
  }
    }

    &.btn-link {
      color: ${(props) => props.theme.primary};

      &:focus {
        box-shadow: none;
      }
    }

    &.btn-link-dark {
      color: ${(props) => props.theme.base};
    }

    &.btn-outline, &.btn-outline:hover {
      color: ${(props) => props.theme.base};
      border-color: ${(props) => props.theme.base};
    }
  }

  .table thead tr th {
    color: ${(props) => props.theme.base};
    border-color: ${(props) => props.theme.base};
  }

  .gutter {
    background-color: ${(props) => props.theme.base};
    cursor: col-resize;
  }

  .border-dark {
    border-color: ${(props) => props.theme.base} !important;
  }
`;

export default GlobalStyle;
