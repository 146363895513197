import { propertyType } from 'cosmos-config/generator';
import { DateTime } from 'luxon';

const getFormatFunction = (functionName) => {
  switch (functionName) {
    case 'month':
      return (v) => {
        if (v == null) {
          return '';
        }

        return DateTime.fromObject({ month: v }).monthLong;
      };
    default:
      return (v) => v;
  }
};

export const queryResultsToProperties = (results, properties) => {
  const propertiesMapObject = properties.reduce(
    (acc, cur) => ({ ...acc, [cur.name]: cur }),
    {}
  );

  return results
    .map((r) => {
      const propertyName = r.type === 'FIELD' ? r.name : r.parameter;
      const property = propertiesMapObject[propertyName];

      if (r.alias != null) {
        if (r.type !== 'FIELD') {
          return {
            ...property,
            name: r.alias,
            accessor: r.alias,
            type: propertyType.TEXT,
            format: getFormatFunction(r.name),
          };
        }

        return {
          ...property,
          name: r.alias,
          accessor: r.alias,
        };
      }

      return property;
    })
    .filter((x) => x != null)
    .filter((x, idx, arr) => arr.indexOf(x) === idx);
};
