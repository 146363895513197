import classNames from "classnames";
import { HelperTooltip, PancoSelect, PancoSwitch } from "cosmos-components";
import { propertyType } from "cosmos-config/generator";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormProperty from "../../../../components/form/FormProperty";
import useProperties from "../../../../hooks/useProperties";

const PropertyVisibility = ({ property, onChange }) => {
  const { properties, propertiesMap } = useProperties();

  const propertiesOptions = useMemo(
    () =>
      properties.map((p) => ({
        value: p.name,
        label: `${p.label} (${p.name})`,
      })),
    [properties]
  );

  const masterProperty = useMemo(() => {
    const prop = propertiesMap[property._conditionalProperty];

    if (prop != null) {
      return {
        ...prop,
        multiple: true,
        disabled: false,
      };
    }

    return null;
  }, [propertiesMap, property._conditionalProperty]);

  const innerValue = useMemo(() => {
    if (
      Array.isArray(property._conditionalValues) &&
      [propertyType.YESNO, propertyType.CHECKBOX, propertyType.DATE].includes(
        masterProperty?.type
      )
    ) {
      const [v] = property._conditionalValues;
      return v;
    }

    return property._conditionalValues;
  }, [masterProperty, property]);

  return (
    <div>
      <Form.Group as={Row} className={classNames("form-property")}>
        <Form.Label column sm={4}>
          Conditional (Control Visibility)
        </Form.Label>
        <Col sm={6} xs={10}>
          <PancoSwitch
            value={property.conditional}
            onChange={({ value }) => onChange({ conditional: value })}
          />
        </Col>
        <Col xs={2} className="col-form-label">
          <HelperTooltip title="Conditional (Control Visibility)" text="" />
        </Col>
      </Form.Group>
      {property.conditional && (
        <Row>
          <Col sm={4}>
            <Form.Label>Visible If</Form.Label>
          </Col>
          <Col md={3}>
            <PancoSelect
              options={propertiesOptions}
              value={[property._conditionalProperty].filter((x) => x != null)}
              onChange={({ value }) => {
                const [v] = value;

                onChange({
                  _conditionalProperty: v,
                });
              }}
            />
          </Col>
          <Col md={2}>
            <PancoSelect
              value={[property._conditionalOperator].filter((x) => x != null)}
              options={[
                { label: "Is Equal", value: "eq" },
                { label: "Is Not Equal", value: "noteq" },
              ]}
              onChange={({ value }) => {
                const [v] = value;

                onChange({
                  _conditionalOperator: v,
                });
              }}
            />
          </Col>
          <Col md={3}>
            {masterProperty != null && (
              <FormProperty
                control
                property={masterProperty}
                value={innerValue}
                onChange={({ value }) => {
                  onChange({
                    _conditionalValues:
                      Array.isArray(value) || value == null ? value : [value],
                  });
                }}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

PropertyVisibility.propTypes = {
  property: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
};

PropertyVisibility.defaultProps = {
  onChange: () => {},
};

export default PropertyVisibility;
