import _ from "lodash";
import { useCallback, useMemo } from "react";
import useCurrentUser from "../useCurrentUser";
import Resource from "../../types/resource";
import { useUserRelation } from "../../modules/user-relation";

interface PermissionObject extends Record<string, boolean> {}

const generateResourcePermission = (al: number): PermissionObject => ({
  member: al >= 7,
  read: al >= 5,
  update: al >= 9,
  delete: al === 10,
  share: al >= 7,
  create: al >= 6,
  admin: al === 10,
});

const mergePermissionObjects = (
  permissions: PermissionObject[]
): PermissionObject => {
  const inital = _.first(permissions) || {};
  return permissions.reduce((acc, cur) => {
    return Object.entries(cur).reduce((innerAcc, [key, value]) => {
      return {
        ...innerAcc,
        [key]: acc[key] && value,
      };
    }, {});
  }, inital);
};

export interface UseResourcePermissionsValue {
  /**
   * The access level of the resource(s).
   */
  accessLevel: number;
  /**
   * Check whether user have a permission over the resource.
   * @param permission
   * @returns
   */
  canResourcePermission: (permission: string) => boolean;
  /**
   * Check whether resource(s) is private (accessLevel = 3).
   * @returns
   */
  isPrivateResource: () => boolean;
  /**
   * Check whether user has maintainer permission over resource(s).
   * @returns
   */
  isMaintainer: () => boolean;
  /**
   * Check whether user has document controller permission over the resource(s).
   * Warning: Should be used only to indicate whether user is document controller.
   * Its not really testing all access exceptions.
   * @returns
   */
  isDocumentController: () => boolean;
  /**
   * Check whether user has team member permission over the resource(s).
   * Warning: Should be used to check whether user has write permission over documents
   * in the folder.
   * @returns
   */
  isTeamMember: (mustOwn: boolean) => boolean;
}

const useResourcePermissions = (
  input?: Resource | Resource[]
): UseResourcePermissionsValue => {
  const { userData, principalId, resourcePermissions, systemAdmin } =
    useCurrentUser();
  const { moduleAdmin } = useUserRelation();
  const externalUser = useMemo(
    () => userData?.domainName === "ExternalUsers",
    [userData]
  );

  const resources = useMemo(() => {
    if (Array.isArray(input)) {
      return input;
    }

    return _.compact([input]);
  }, [input]);

  const getResourceAccessLevel = useCallback(
    (resource: Resource, external = false) => {
      if (resource == null) {
        return 10;
      }

      const privateAccessLevel = resource.private ? 3 : 10;

      const resourceAccessLevel = external
        ? resource.accesslevelexternal
        : resource.accesslevel;

      if (resourceAccessLevel == null) {
        return privateAccessLevel;
      }

      return privateAccessLevel < resourceAccessLevel
        ? privateAccessLevel
        : resourceAccessLevel;
    },
    []
  );

  const getAccessLevel = useCallback(
    (external = false): number => {
      return Math.min(
        ...resources.map((r) => getResourceAccessLevel(r, external))
      );
    },
    [getResourceAccessLevel, resources]
  );

  const getParentAccessLevel = useCallback(
    (external = false): number => {
      return _(resources)
        .map((resource) => {
          const {
            parentaccesslevel,
            parentaccesslevelexternal,
            pathidentifiers,
            resourcetype,
          } = resource;

          const path = pathidentifiers || [];

          if (
            (resourcetype === 1 && path.length <= 3) ||
            (resourcetype === 2 && path.length === 3)
          ) {
            return 10;
          }

          const derived = external
            ? parentaccesslevelexternal
            : parentaccesslevel;

          return derived || 10;
        })
        .min();
    },
    [resources]
  );

  const levelsLowerOrEqual = useCallback(
    (level: number) => {
      return getAccessLevel(externalUser) <= level;
    },
    [externalUser, getAccessLevel]
  );

  const privateResources = useMemo(
    () => levelsLowerOrEqual(3),
    [levelsLowerOrEqual]
  );

  const publicResources = useMemo(
    () => !levelsLowerOrEqual(9),
    [levelsLowerOrEqual]
  );

  const resourcePermissionObject = useMemo(() => {
    const permissions = resources.map((r) => {
      const resourceId = r.resourcetype === 3 ? r.refresourceid : r.id;
      const permission = resourcePermissions[resourceId];

      if (permission == null) {
        return generateResourcePermission(
          getResourceAccessLevel(r, externalUser)
        );
      }

      return permission;
    });

    return mergePermissionObjects(permissions);
  }, [externalUser, getResourceAccessLevel, resourcePermissions, resources]);

  const parentResourcePermissionObject = useMemo(() => {
    const parentResourceIds = _(resources)
      .filter((x) => x != null)
      .map("parentresourceid")
      .uniq()
      .value();
    const parentResourcePermissions = parentResourceIds
      .map((id) => resourcePermissions[id])
      .filter((x) => x != null);

    if (parentResourcePermissions.length === 0) {
      const parentAccessLevel = getParentAccessLevel(externalUser);
      return generateResourcePermission(parentAccessLevel);
    }

    return mergePermissionObjects(parentResourcePermissions);
  }, [resources, resourcePermissions, getParentAccessLevel, externalUser]);

  const creators = useMemo(
    () =>
      resources
        .filter((x) => x != null)
        .map((d) =>
          Array.isArray(d.initialcreator)
            ? d.initialcreator[0]
            : d.initialcreator
        )
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        }),
    [resources]
  );

  const isOwner = useCallback(() => {
    if (principalId == null) {
      return false;
    }

    return creators.every((c) => c === principalId);
  }, [creators, principalId]);

  const evaluatePermission = useCallback(
    (
      permissionObject: PermissionObject,
      permission: string,
      checkOwnership = true
    ) => {
      if (
        checkOwnership &&
        ((permission !== "admin" && publicResources) || isOwner())
      ) {
        return true;
      }

      if (permissionObject != null) {
        return permissionObject[permission];
      }

      return false;
    },
    [isOwner, publicResources]
  );

  const canResourcePermissionParent = useCallback(
    (permission: string) =>
      evaluatePermission(parentResourcePermissionObject, permission, false),
    [evaluatePermission, parentResourcePermissionObject]
  );

  const isFirstLevelStructure = useCallback(() => {
    const structureLevel = _(resources)
      .map("pathidentifiers")
      .compact()
      .map((x) => x.length)
      .min();

    return structureLevel != null && structureLevel === 3;
  }, [resources]);

  const canResourcePermission = useCallback(
    (permission: string) => {
      return (
        systemAdmin ||
        moduleAdmin ||
        evaluatePermission(resourcePermissionObject, permission) ||
        (permission !== "admin" &&
          canResourcePermissionParent("update") &&
          !isFirstLevelStructure())
      );
    },
    [
      systemAdmin,
      moduleAdmin,
      evaluatePermission,
      resourcePermissionObject,
      canResourcePermissionParent,
      isFirstLevelStructure,
    ]
  );

  const isParentLevel = useCallback(
    (accessLevel: 7 | 9 | 10, mustOwnResource?: boolean) => {
      if (systemAdmin || moduleAdmin || isOwner()) {
        return true;
      }

      const canParentLevel = (lvl: number) => {
        const permissionNameMap: Record<number, string> = {
          7: "member",
          8: 'update',
          9: "update",
          10: "admin",
        };
  
        const permissionName = permissionNameMap[lvl];
  
        if (permissionName == null) {
          return false;
        }

        return canResourcePermissionParent(permissionName);
      }

      return (
        canParentLevel(accessLevel) &&
        (!mustOwnResource || isOwner() || canParentLevel(accessLevel + 1))
      );
    },
    [canResourcePermissionParent, isOwner, moduleAdmin, systemAdmin]
  );

  return {
    accessLevel: useMemo(
      () => getAccessLevel(externalUser),
      [externalUser, getAccessLevel]
    ),
    canResourcePermission,
    isPrivateResource: useCallback(() => privateResources, [privateResources]),
    isMaintainer: useCallback(
      () =>
        (isParentLevel(10) && !isFirstLevelStructure()) ||
        (canResourcePermission("admin") &&
          canResourcePermissionParent("admin")),
      [
        canResourcePermission,
        canResourcePermissionParent,
        isFirstLevelStructure,
        isParentLevel,
      ]
    ),
    //not yet prepared for folders.
    isDocumentController: useCallback(
      () => canResourcePermissionParent("update") && !isFirstLevelStructure(),
      [canResourcePermissionParent, isFirstLevelStructure]
    ),
    isTeamMember: useCallback(
      (mustOwnResource) => isFirstLevelStructure() || isParentLevel(7, !!mustOwnResource),
      [isFirstLevelStructure, isParentLevel]
    ),
  };
};

export default useResourcePermissions;
