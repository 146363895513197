import { createElement as h, useEffect, useState } from "react";
import PancoSelect, {
  PancoSelectOption,
  PancoSelectProps,
} from "../panco-select/PancoSelect";

export interface SuggestiveInputProps extends PancoSelectProps {
  onInsert?: (param: PancoSelectOption) => void;
}

const SuggestiveInput = ({
  onChange,
  value,
  options,
  onInsert,
  multiple,
  ...props
}: SuggestiveInputProps) => {
  const [filterValue, setFilterValue] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    setSelectedItems(value || []);
  }, [value]);

  // const customOptions = useMemo(() => {
  //   const additionalOptions = selectedItems
  //     .filter((item) => {
  //       return !options.some((o) => o.value === item);
  //     })
  //     .map((i) => ({ label: i, value: i }));

  //   return [...options, ...additionalOptions];
  // }, [selectedItems, options]);

  const handleBlur = () => {
    if (filterValue != null && filterValue !== "") {
      if (!selectedItems.includes(filterValue)) {
        if (onInsert != null) {
          onInsert({ label: filterValue, value: filterValue });
        }

        if (onChange != null) {
          if (multiple) {
            onChange({ name: "", value: [...selectedItems, filterValue] });
          } else {
            onChange({ name: "", value: [filterValue] });
          }
        }

        setFilterValue(null);
      }
    }
  };

  return h(PancoSelect, {
    ...props,
    multiple,
    value,
    options,
    onChange: (v) => {
      setFilterValue(null);
      if (onChange != null) {
        onChange(v);
      }
    },
    onFilter: (v) => {
      if (v != null) {
        setFilterValue(v);
      }
    },
    onBlur: handleBlur,
    editableFilter: !multiple,
  });
};

export default SuggestiveInput;
