import * as actions from '../Actions/types';

const initialState = {
  openedDocumentId: null,
  loading: false,
  presets: {},
  document: null,
  versions: [],
  preload: null,
  permissions: [],
  permissionsLoading: false,
  signatureRequest: null,
  signatureRequestLoading: false,
  reminders: [],
};

// should be renamed to "resource"
const document = (state = initialState, action) => {
  switch (action.type) {
    case actions.document.FETCH_DOCUMENT_PROPERTIES:
      return {
        ...state,
        properties: [],
      };
    case actions.document.CLEAR_DOCUMENT_DATA:
    case actions.filterFolder.CLEAR_STORES:
      return {
        ...initialState,
      };
    case actions.document.SET_DOCUMENT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.repository.SET_DOCUMENT_PRESETS:
      return {
        ...state,
        presets: action.presets,
      };
    case actions.document.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
      };
    case actions.document.SET_OPENED_DOCUMENT_ID:
      return {
        ...state,
        openedDocumentId: action.documentId,
      };
    case actions.document.CLOSE_DOCUMENT:
      return {
        ...initialState,
        preload: state.preload,
        presets: state.presets,
      };
    case actions.document.SET_DOCUMENT:
      return {
        ...state,
        document: action.document,
      };
    case actions.document.SET_DOCUMENT_VERSIONS:
      return {
        ...state,
        versions: action.versions,
      };
    // case actions.document.SET_DOCUMENT_VERSIONS_CONTENT:
    //   return {
    //     ...state,
    //     versionsContent: action.versionsContent,
    //   };
    case actions.document.SET_DOCUMENT_PRELOAD:
      return {
        ...state,
        preload: action.preload,
      };
    case actions.document.SET_PRIVATE_DOCUMENT_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case actions.document.SET_PRIVATE_DOCUMENT_PERMISSIONS_LOADING:
      return {
        ...state,
        permissionsLoading: action.loading,
      };
    // case actions.document.SET_ANNOTATIONS:
    //   return {
    //     ...state,
    //     annotations: action.annotations,
    //   };
    case actions.document.SET_DOCUMENT_SIGNATURE_REQUEST:
      return {
        ...state,
        signatureRequest: action.signatureRequest,
      };
    case actions.document.SET_SIGNATURE_REQUEST_LOADING:
      return {
        ...state,
        signatureRequestLoading: action.loading,
      };
    case actions.repository.HANDLE_RESOURCE_UPDATE_EVENT:
      if (state.document.id === action.payload.resourceId) {
        return {
          ...state,
          document: {
            ...state.document,
            ...(action.payload.data || {}),
          },
        };
      }
      break;
    case actions.document.SET_RESOURCE_REMINDERS:
      return {
        ...state,
        reminders: action.payload.reminders,
      };
    default:
      return state;
  }
};

export default document;
