/* eslint-disable func-names */
import { DateTime } from 'luxon';
import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import * as actions from '../Actions/types';
import {
  addNotification,
  removeNotification,
  showWelcomeScreen,
  setStatuses,
  showSplashScreen,
  showGDPRScreen,
  setReady,
} from '../Actions/ui';
import { simpleHash } from '../Utils';
import { uuidv4 } from 'cosmos-config/utils';
import configuration from '../Api/configuration';
import { fetchAndStoreSettings } from './userSettingsSaga';
import userSettingsScope from '../Constants/userSettingsScope';

export function* notificationFlow() {
  yield takeEvery(actions.ui.NOTIFY, function* ({ message, notificationType }) {
    if (message == null) {
      return;
    }

    const notifications = yield select((state) => state.ui.notifications);

    const hash = simpleHash(message);
    if (notifications.findIndex((n) => n.hash === hash) < 0) {
      const id = uuidv4();
      yield put(addNotification({ id, message, type: notificationType, hash }));

      if (notificationType !== 'error') {
        yield delay(5000);
        yield put(removeNotification(id));
      }
    }
  });
}

export function* applicationStatusFlow() {
  yield takeEvery(
    [
      actions.document.SET_DOCUMENT_LOADING,
      actions.repository.SET_REPOSITORY_LOADING,
      actions.workflow.SET_WORKFLOW_LOADING,
      actions.workflow.SET_TASK_LOADING,
      actions.ui.SET_BACKGROUND_LOADING,
      actions.teamspaces.SET_TEAMSPACES_LOADING,
      actions.filterFolder.SET_COLLABORATION_LOADING,
      actions.upload.SET_UPLOAD_LOADING,
      actions.document.SET_LOADING_PROJECT,
      actions.userdata.SET_USERDATA_LOADING,
      actions.document.SET_SIGNATURE_REQUEST_LOADING,
    ],
    function* ({ loading, message, type }) {
      const statuses = yield select((state) => state.ui.statuses);
      if (loading && message != null) {
        const hash = simpleHash(message);
        const idx = statuses.findIndex((s) => s.hash === hash);

        const id = uuidv4();
        const status = {
          id,
          message,
          loading,
          hash,
          type,
        };

        if (idx >= 0) {
          yield put(
            setStatuses(
              statuses.map((s, i) => {
                if (i === idx) {
                  return status;
                }

                return s;
              })
            )
          );
        } else {
          yield put(setStatuses([...statuses, status]));
        }
      } else {
        yield put(
          setStatuses(statuses.filter((s) => s.type !== type && s.loading))
        );
      }
    }
  );
}

export default function* uiSaga() {
  yield takeEvery(actions.ui.RELOAD_APP, function* () {
    yield call(configuration.clearCommonCache);
    yield put(showSplashScreen(true));
    yield delay(3000);
    window.location.reload();
  });

  yield fork(function* () {
    while (true) {
      yield take(actions.repository.PROJECT_SELECTION_NEEDED);
      yield put(showWelcomeScreen(true));
    }
  });
  yield put(setReady(true));

  yield fork(function* () {
    yield take(actions.project.OPEN_PROJECT);
    const appearance = yield call(
      fetchAndStoreSettings,
      null,
      userSettingsScope.APPEARANCE
    );

    // if (appearance == null || !appearance.skipGuide) {
    //   yield put(showWelcomeScreen(true));
    //   yield take(actions.userSettings.SET_SKIP_GUIDE);
    //   yield put(showWelcomeScreen(false));
    // }

    yield put(showWelcomeScreen(false));

    if (
      appearance == null ||
      !appearance.skipGDPR ||
      (appearance.GDPRConfirmedAt != null &&
        DateTime.now().diff(
          DateTime.fromMillis(appearance.GDPRConfirmedAt),
          'years'
        ).years > 1)
    ) {
      yield put(showGDPRScreen(true));
      yield take(actions.userSettings.SET_SKIP_GDPR);
      yield put(showGDPRScreen(false));
    }

    yield put(setReady(true));
  });
}
