export default [
  'firstName',
  'commonName',
  'domainName',
  'lastName',
  'name',
  'office',
  'organizations',
  'roles',
  'titles',
  'userInfoId',
  'department',
  'departmentTeam',
  'defaultPositionId',
  'principalId',
  'emails',
  'groupNames',
];
