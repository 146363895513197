import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DateTime, Interval } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { Button } from "react-bootstrap";
import ValuePickerWrapper, {
  ValuesetPickerWrapperProps,
} from "../value-picker-wrapper/ValuePickerWrapper";
import Calendar from "../calendar/Calendar";
import "./date-range-picker.scss";
import {
  InputValueHandler,
  PancoInputElementProps,
} from "../../types/PancoInputElemenProps";

type DateRangeValue = {
  from: string | null;
  to: string | null;
};

type HandleSelectionParam = {
  from?: string;
  to?: string;
};

export interface DateRangePickerProps
  extends Omit<ValuesetPickerWrapperProps<DateRangeValue>, "children">,
  PancoInputElementProps,
  Omit<InputValueHandler<DateRangeValue>, "onBlur"> { }

const DateRangePicker = ({
  onChange,
  value,
  ...props
}: DateRangePickerProps) => {
  const { t } = useTranslation("module");

  const [dateObject, setDateObject] = useState<DateRangeValue>({
    from: value?.from ?? null,
    to: value?.to ?? null,
  });

  const handleSelection = (selectedDate: HandleSelectionParam) => {
    setDateObject((date) => {
      const newDateObject = {
        ...date,
        ...selectedDate,
      };

      const fromDate =
        newDateObject.from != null
          ? DateTime.fromISO(newDateObject.from)
          : DateTime.invalid("From date is invalid");

      const toDate =
        newDateObject.to != null
          ? DateTime.fromISO(newDateObject.to)
          : DateTime.invalid("To date is invalid");

      if (fromDate.isValid && toDate.isValid) {
        const interval = Interval.fromDateTimes(fromDate, toDate);

        if (!interval.isValid) {
          return date;
        }
      }

      return newDateObject;
    });
  };

  const clearSelection = () => {
    const selectedValue = { from: null, to: null };
    setDateObject(selectedValue);

    if (onChange != null) {
      onChange({ value: selectedValue });
    }
  };

  const formatValue = (dateObject: DateRangeValue | null | undefined) => {
    if (dateObject != null) {
      const from =
        dateObject.from != null
          ? DateTime.fromISO(dateObject.from)
            .setLocale("de-DE")
            .toLocaleString()
          : null;
      const to =
        dateObject.to != null
          ? DateTime.fromISO(dateObject.to)
            .setLocale("de-DE")
            .toLocaleString()
          : null;

      return from != null || to != null ? `${from || ""} - ${to || ""}` : "";
    }

    return "";
  };

  return (
    <ValuePickerWrapper<DateRangeValue>
      {...props}
      content={() => formatValue(value)}
      onClear={clearSelection}
      actionIcon={() => <FontAwesomeIcon icon={faCalendar} />}
      selection={value != null && value.from != null && value.to != null}
      onSelect={(v) => {
        if (onChange != null) {
          onChange({ value: v })
        }
      }}
    >
      {({ onSelect }) => {
        return (
          <div className="date-range-picker d-flex flex-column">
            <div className="d-flex">
              <div>
                <h6>{t("label.from_date", { defaultValue: "From Date" })}:</h6>
                <Calendar
                  onSelect={(v) => handleSelection({ from: DateTime.fromMillis(v).toISODate() || "" })}
                  value={dateObject?.from ? DateTime.fromISO(dateObject.from).toMillis() : 0}
                  rangeValue={dateObject?.to ? DateTime.fromISO(dateObject.to).toMillis() : 0}
                />
              </div>
              <div>
                <h6>{t("label.to_date", { defaultValue: "To Date" })}:</h6>
                <Calendar
                  onSelect={(v) => handleSelection({ to: DateTime.fromMillis(v).toISODate() || "" })}
                  value={dateObject?.to ? DateTime.fromISO(dateObject.to).toMillis() : 0}
                  rangeValue={dateObject?.from ? DateTime.fromISO(dateObject.from).toMillis() : 0}
                />
              </div>
            </div>
            <Button variant="dark" block className="p-3" disabled={!dateObject?.from || !dateObject?.to} onClick={() => onSelect(dateObject)}>
              {t('button.done', { defaultValue: 'Done' })}
            </Button>
          </div>
        );
      }}
    </ValuePickerWrapper>
  );
};

DateRangePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  className: PropTypes.string,
};

DateRangePicker.defaultProps = {
  onChange: () => { },
  value: {
    from: null,
    to: null,
  },
  className: null,
};

export default DateRangePicker;