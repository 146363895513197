import {
  property,
  valuesetSource,
  accessManagementType,
} from "cosmos-config/generator";

const folderProperties = [
  property("Label", "label"),
  property("Description", "description").textArea(1000),
  // property("Translation Path", "translation"),
  property("Title Property")
    .selectableModuleElementAttribute("titlePropertyName", "property")
    .describe(
      "Name of the property which is used as a folder name. Display name is used when left empty."
    ),
  property("Maintainers")
    .selectableModuleElementAttribute("maintainers", "property")
    .multiAttribute("maintainers")
    .describe(
      "Specify property of a folder type which contains a user/group or multiple users. Then these members will become maintainers of the folder."
    ),
  property("Creation Information")
    .booleanAttribute("displayCreationData")
    .useYesNo()
    .describe(
      "Data about who and when the folder was created will be displayed in the subheader."
    ),
  property("Upload with Metadata")
    .booleanAttribute("uploadWizard")
    .useYesNo()
    .describe(
      "When enabled upload of documents into the folder will be followed by metadata assignment procedure."
    ),
  property("Immediate Filling")
    .booleanAttribute("uploadFilesWhileCreating")
    .useYesNo()
    .describe(
      "This ensures that creation of new folder also includes upload of documents. Folder is not created when there is at least one document uploaded into it."
    ),
  property("Unique Instances")
    .booleanAttribute("uniqueInstances")
    .useYesNo()
    .describe(
      "Make every instance of folder type unique. Custom column order is not shared among folder type instances."
    ),
  property("Access Management")
    .selectableAttribute(
      "accessManagement",
      "folder_access_management",
      valuesetSource.STATIC_VALUESET
    )
    .valueset([
      {
        label: "None",
        value: accessManagementType.NONE,
      },
      {
        label: "Compact (No level slider)",
        value: accessManagementType.COMPACT,
      },
      {
        label: "Simplified",
        value: accessManagementType.SIMPLIFIED,
      },
      {
        label: "Complete",
        value: accessManagementType.COMPLETE,
      },
    ])
    .describe(
      "Whether access management should be enabled on the repository folder of this folder type and its children."
    ),
];

export default folderProperties;
