import { property, valuesetSource } from 'cosmos-config/generator';

export default [
  property('Reminder', 'reminder')
    .selectableAttribute('reminder', 'reminder', valuesetSource.STATIC_VALUESET)
    .valueset([
      {
        label: 'Daily',
        value: 'daily',
      },
      {
        label: 'Every Third Day',
        value: 'thirdDay',
      },
      {
        label: 'Weekly',
        value: 'weekly',
      },
    ])
    .optional()
    .describe('Reminder Description'),
  property('Message', 'message')
    .textArea(500)
    .optional()
    .describe('Message Description'),
];
