import styled from "styled-components";
import { darken } from "polished";

const WidgetBody = styled.div`
  overflow-y: auto;
  flex: 1;
  position: relative;

  table.table {
    tbody {
      tr {
        td {
          border-top: none;
        }
      }
    }
  }
`;

const DashboardWidget = styled.div`
  overflow: hidden;
  border: 1px solid ${(props) => darken(0.2, props.theme.background)};
  background-color: ${(props) => props.theme.background};
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px 0px ${(props) => darken(0.15, props.theme.background)};
  user-select: none;

  h5 {
    padding: 10px 15px;
    background-color: ${(props) => darken(0.02, props.theme.background)};
    margin-bottom: 0;
    color: ${(props) => props.theme.base};
  }

  &.react-grid-placeholder {
    background-color: ${(props) => darken(0.05, props.theme.background)};
  }

  /* &.react-draggable {
    &.react-draggable-dragging {
      opacity: 0;
    }
  }

  &.react-resizable {
    &.resizing {
      opacity: 0;
    }
  } */

  &.react-resizable-hide {
    .react-resizable-handle {
      display: none;
    }
  }

  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url(../images/resize-handle.svg);
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
  }
`;

const DashboardGrid = styled.div`
  position: relative;
`;

const BasicDashboard = styled.div`
  /* @include scrollable-container; */
  background-color: ${(props) => props.theme.background};
  padding-bottom: 30px;

  .dashboard-charts {
    padding-bottom: 25px;

    h3 {
      color: ${(props) => darken(0.6, props.theme.background)};
      margin-bottom: 25px;
    }
  }
`;

export interface StaticDashboardProps {
  columns: number;
}

const StaticDashboard = styled(BasicDashboard)<StaticDashboardProps>`
  & ${DashboardGrid} {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    & ${DashboardWidget} {
      flex-basis: ${(props) => `${100 / props.columns - 2}%`};
      flex-grow: 1;
      height: 37vh;
    }
  }
`;

export {
  WidgetBody,
  DashboardWidget,
  DashboardGrid,
  BasicDashboard,
  StaticDashboard,
};
