export const EXPORT_FOLDERS_TEMPLATE = 'import/exportFoldersTemplate';
export const IMPORT_FOLDERS_EXCEL = 'import/foldersExcel';
export const IMPORT_DOCUMENTS_EXCEL = 'import/documentsExcel';
export const EXPORT_DOCUMENTS_TEMPLATE = 'import/exportDocumentsTemplate';
export const SET_IMPORT_DATA = 'import/setData';
export const EXECUTE_IMPORT = 'import/execute';
export const CANCEL_IMPORT = 'import/cancel';
export const RELOAD_IMPORT = 'import/reload';
export const CLEAN_IMPORT = 'import/clean';
export const ADD_FILES_TO_IMPORT = 'import/addFiles';
export const UNZIP_FILES_TO_IMPORT = 'import/unzipFiles';
export const SET_IS_UNZIPPING = 'import/setIsUnzipping';
export const REMOVE_FILES_FROM_IMPORT = 'import/removeFiles';
export const UPDATE_IMPORT_COLUMN_MAPPING = 'import/updateColumnMapping';
export const SET_CURRENT_SCHEMA = 'import/setCurrentSchema';
export const UPDATE_IMPORT_VALUE_MAPPING = 'import/updateValueMapping';
export const SET_IMPORT_COMPLETE = 'import/setComplete';
export const UPDATE_IMPORT_STATS = 'import/updateStats';
export const GENERATE_EXCEL_STATS_REPORT = 'import/generateExcelStatsReport';
export const DOWNLOAD_LOG = 'import/downloadLog';
export const ADD_LOG_ACTIVITY = 'import/addLogActivity';
