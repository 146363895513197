/* eslint-disable func-names */
import { call, cancel, delay, fork, put, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { setDownloadProgress } from '../../Actions/document';
import callApi from './callApi';

export default (saga, ...args) =>
  call(function* () {
    const progressChannel = yield channel();
    const downloadProgressTask = yield fork(function* () {
      while (true) {
        let signal = true;

        const action = yield take(progressChannel);
        if (signal) {
          signal = false;
          yield fork(function* () {
            yield put(action);
            delay(500);
            signal = true;
          });
        }
      }
    });

    const result = yield callApi(saga, ...args, (progress) => {
      progressChannel.put(setDownloadProgress(progress));
    });

    yield cancel(downloadProgressTask);

    return result;
  });
