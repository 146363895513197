import { createContext } from "react";
import Resource from "../../types/resource";
import { OnOpenResourceCallback } from "../../types/callbacks";

export type OnRowExpandParam = {
  isVerticalMain: boolean;
  isLinked: boolean;
  isParentEmail: boolean;
  hasSubRows: boolean;
};

export type OnRowExpandCallback = (
  resource: Resource,
  param: OnRowExpandParam
) => void;

declare type RepositoryTableContextValue = {
  onRowExpand: OnRowExpandCallback;
  parentEmailIds: string[];
  accessManagement: boolean;
  onOpenResource: OnOpenResourceCallback;
};

const RepositoryTableContext = createContext<RepositoryTableContextValue>({
  onRowExpand: () => {},
  parentEmailIds: [],
  accessManagement: false,
  onOpenResource: () => {},
});

export default RepositoryTableContext;
