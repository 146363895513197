import { useCallback, useMemo } from "react";
import repositorySearchApi from "../apis/repositorySearchApi";
import useProject from "./useProject";
import useCurrentUser from "./useCurrentUser";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";

export interface UseResourceRelationsProps {
  resourceId?: string;
}

const useResourceRelations = ({ resourceId }: UseResourceRelationsProps) => {
  const queryKey = useMemo(() => ["email-relations", resourceId], [resourceId]);
  const { authenticated } = useCurrentUser();

  const { project } = useProject();

  const { data, refetch, isFetching } = useQuery({
    queryKey,
    queryFn: () => {
      const projectCode = project?.code;

      if (projectCode == null || resourceId == null) {
        throw new Error("Project and resourceId cannot be null!");
      }

      return repositorySearchApi.searchAggregateDistinct(
        projectCode,
        resourceId,
        "ParentEmailId"
      );
    },
    enabled: project != null && resourceId != null && authenticated,
    refetchOnWindowFocus: false,
  });

  return {
    parentEmailIds: useMemo(() => {
      if (data == null) {
        return [];
      }

      return data.map((item: any) => {
        return _.find(item.properties, { name: "ParentEmailId" })?.value;
      });
    }, [data]),
    loading: useMemo(() => isFetching, [isFetching]),
    refetch: useCallback(() => {
      refetch();
    }, [refetch]),
  };
};

export default useResourceRelations;
