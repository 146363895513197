import { getDocareaName, getValuesetsMap } from "../selectors/docareaSelector";
import store from "../store";

const docareaService = {
  getDocareaName: () => {
    const state = store.getState();
    return getDocareaName(state);
  },
  getValuesetsMap: () => {
    const state = store.getState();
    return getValuesetsMap(state);
  },
};

export default docareaService;
