import { group, property } from "cosmos-config/generator";

const SignOff_ReassignSubworkflow = [
  group("Reassign Subworkflow", "Reassign Subworkflow")
    .translate("common.reassign_subworkflow")
    .dedicatedTo("START")
    .children([
      property("Current Assignee")
        .selectableMemberAttribute("WF_User")
        .readonly(true, true),
      property("Reassign To").selectableMemberAttribute("WF_User_New"),
    ]),
];

export default SignOff_ReassignSubworkflow;
