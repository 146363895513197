import { createSelector } from 'reselect';

export const getImportData = (state) => state.import.data;
export const getImportIssues = (state) => state.import.issues;
export const getCurrentSchema = (state) => state.import.currentSchema;
export const getImportColumnMapping = (state) => state.import.columnMapping;
export const getImportValueMapping = (state) => state.import.valueMapping;
export const getImportStats = (state) => state.import.stats;
export const getImportInProgress = (state) => state.import.inProgress;

// getImportStats contains array of stat results
// each result contains resourceId, fileName, filePath, error boolean and complete boolean
// create selector to return file to resource mapping
// each entry should have the resourceId as key, the rest as value
// only return entries, that have no error and are complete
export const getImportedResourceToFileMap = createSelector(
  [getImportStats],
  (stats) => {
    return stats.reduce((acc, stat) => {
      if (!stat.error && stat.complete) {
        acc[stat.resourceId] = {
          id: stat.resourceId,
          fileName: stat.fileName,
          filePath: stat.filePath,
        };
      }
      return acc;
    }, {});
  }
);
