import _ from "lodash";
import { uuidv4 } from "../utils";
import { getValueQueryRepr } from ".";

declare type Operator =
  | "eq"
  | "noteq"
  | "not"
  | "and"
  | "or"
  | "in"
  | "exists"
  | "between"
  | "toDate"
  | "isNull"
  | "gt"
  | "gte"
  | "lt"
  | "lte"
  | "concat"
  | "likeIgnoreCase"
  | "toString";
declare type Operand =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | QueryBase;

export const OPERATION_TYPE = "cosmos-operation";
export const OPERAND_TYPE = "cosmos-operand";

export interface QueryBase {
  _type: string;
  _uuid: string;
  toNql: () => string;
  toString: () => string;
}

export interface Operation extends QueryBase {
  _type: typeof OPERATION_TYPE;
  operands: Operand[];
  operator: Operator;
  expectedOperandsCount: number;
}

export interface QueryOperand extends QueryBase {
  _type: typeof OPERAND_TYPE;
  operand: string;
}

class InvalidOperandsNumberError extends Error {
  constructor(
    operator: Operator,
    operandsCount: number,
    expectedOperandsCount: number
  ) {
    super(
      `Incorrect number of operands for ${operator} operation! Expected ${expectedOperandsCount} received ${operandsCount}!`
    );
  }
}

const getExpectedOperandsCount = (operator: Operator) => {
  switch (operator) {
    case "between":
      return 3;
    case "eq":
    case "noteq":
    case "gt":
    case "gte":
    case "lt":
    case "lte":
    case "likeIgnoreCase":
      return 2;
    case "not":
    case "exists":
    case "toDate":
    case "isNull":
    case "toString":
      return 1;
    case "in":
    case "concat":
    case "and":
    case "or":
    default:
      return -1;
  }
};

const validateOperandsCount = (operation: Operation) => {
  const operandsCount = operation.operands.length;
  const expectedOperandsCount = operation.expectedOperandsCount;

  if (expectedOperandsCount != -1 && operandsCount !== expectedOperandsCount) {
    throw new InvalidOperandsNumberError(
      operation.operator,
      operandsCount,
      expectedOperandsCount
    );
  }
};

const isQueryBase = (operand?: Operand) => {
  if (operand == null || typeof operand !== "object") {
    return false;
  }

  const type = (operand as QueryBase)._type;
  if (type != null && [OPERAND_TYPE, OPERATION_TYPE].includes(type)) {
    return true;
  }

  return false;
};

const operandToNql = (operand?: Operand): any => {
  if (isQueryBase(operand)) {
    const operandNql = (operand as QueryBase).toNql();

    if ((operand as QueryBase)._type === OPERATION_TYPE) {
      const operator = (operand as Operation).operator;

      if (["and", "or"].includes(operator)) {
        return `(${operandNql})`;
      }
    }

    return operandNql;
  }

  return getValueQueryRepr(operand);
};

export const queryOperand = (operand: string): QueryOperand => ({
  _type: OPERAND_TYPE,
  _uuid: uuidv4(),
  operand,
  toNql() {
    return operand;
  },
  toString() {
    return `operand(${operand})`;
  },
});

export const operation = (
  operator: Operator,
  ...operands: Operand[]
): Operation => {
  const op = {
    _type: OPERATION_TYPE,
    _uuid: uuidv4(),
    operands,
    operator,
    expectedOperandsCount: getExpectedOperandsCount(operator),
    toNql() {
      validateOperandsCount(this);
      const [operandA, operandB, operandC] = this.operands;
      switch (this.operator) {
        case "eq":
          return `${operandToNql(operandA)}=${operandToNql(operandB)}`;
        case "noteq":
          return `${operandToNql(operandA)}!=${operandToNql(operandB)}`;
        case "not":
          return `not ${operandToNql(operandA)}`;
        case "in":
          return `${operandToNql(operandA)} in (${_.drop(this.operands)
            .map(operandToNql)
            .join(",")})`;
        case "and":
          return _.compact(this.operands).map(operandToNql).join("&");
        case "or":
          return _.compact(this.operands).map(operandToNql).join("|");
        case "exists":
          return `exists(${operandToNql(operandA)})`;
        case "between":
          return `${operandToNql(operandA)} between(${operandToNql(
            operandB
          )}, ${operandToNql(operandC)})`;
        case "toDate":
          return `toDate(${operandToNql(operandA)})`;
        case "isNull":
          return `${operandToNql(operandA)} is null`;
        case "gt":
          return `${operandToNql(operandA)}>${operandToNql(operandB)}`;
        case "gte":
          return `${operandToNql(operandA)}>=${operandToNql(operandB)}`;
        case "lt":
          return `${operandToNql(operandA)}<${operandToNql(operandB)}`;
        case "lte":
          return `${operandToNql(operandA)}<=${operandToNql(operandB)}`;
        case "concat":
          return `concat(${_.compact(operands).map(operandToNql).join(",")})`;
        case "likeIgnoreCase":
          return `${operandToNql(operandA)} %~ ${operandToNql(operandB)}`;
        case "toString":
          return `toString(${operandToNql(operandA)})`;
        default:
          throw new Error(
            `Cannot recognize operation operator ${this.operator} while parsing!`
          );
      }
    },
    toString() {
      return `operation(${this.operator}|${this.operands
        .filter((op) => op != null)
        .map((op) => op.toString())
        .join(",")})`;
    },
  } as Operation;

  validateOperandsCount(op);

  if (["and", "or"].includes(operator) && _.compact(operands).length <= 1) {
    const [operand] = operands;

    if (operand != null && (operand as QueryBase)._type === OPERATION_TYPE) {
      return operand as Operation;
    }

    throw new Error(
      `Incompatible operands (${operands}) for ${operator} operation!`
    );
  }

  return op;
};

export const andOperation = (...operands: Operand[]) =>
  operation("and", ...operands);
export const orOperation = (...operands: Operand[]) =>
  operation("or", ...operands);
export const notOperation = (operand: Operand) => operation("not", operand);
export const existsOperation = (operand: Operand) =>
  operation("exists", operand);

export const eqCond = (opA: Operand, opB: Operand) => operation("eq", opA, opB);
