import { useCallback, useMemo } from "react";
import _ from "lodash";
import useProperties from "../useProperties";
import { accessManagementType } from "cosmos-config/generator";
import Resource from "../../types/resource";

export interface UseFolderTypePermissionsValue {
  /**
   * Whether folder type definition allows user management of a kind.
   * @param accessManagement - type of user management
   * @returns true if access management is allowed.
   */
  hasAccessManagement: (accessManagement: accessManagementType) => boolean;
}

/**
 *
 * @param input
 * @returns
 */
const useFolderTypePermissions = (
  input?: Resource | Resource[]
): UseFolderTypePermissionsValue => {
  const { folders } = useProperties();

  const resources = useMemo(() => {
    if (Array.isArray(input)) {
      return input;
    }

    return _.compact([input]);
  }, [input]);

  const foldersMap = useMemo(() => _.keyBy(folders, "name"), [folders]);

  const folderTypesProperties = useMemo(
    () =>
      _(resources)
        .map((r) => r.foldertype || r.parentfoldertype)
        .uniq()
        .compact()
        .map((folderType) => foldersMap[folderType])
        .filter((x) => x != null)
        .value(),
    [foldersMap, resources]
  );

  return {
    hasAccessManagement: useCallback(
      (accessManagement: accessManagementType) =>
        folderTypesProperties.length > 0 &&
        ((accessManagement == null &&
          folderTypesProperties.every(
            (folder) =>
              folder.accessManagement != null &&
              folder.accessManagement !== accessManagementType.NONE
          )) ||
          folderTypesProperties.every(
            (f) => f.accessManagement === accessManagement
          )),
      [folderTypesProperties]
    ),
  };
};

export default useFolderTypePermissions;
