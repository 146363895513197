import React, { useCallback, useMemo } from "react";
import { ItemProps, Virtuoso } from "react-virtuoso";
import { Cell, Row, TableBody } from "../tableFragments";
import Resource from "../../types/resource";
import { Row as RowType } from "react-table";
import useAnticipatedValues from "../../hooks/useAnticipatedValues";
import RepositoryTableRow, {
  RepositoryTableReactiveProps,
} from "./RepositoryTableRow";
import _ from "lodash";
import { Property } from "cosmos-config/lib/property/property";
import { usePropertyRender } from "../../hooks";
import styled from "styled-components";
import { shadeColor } from "cosmos-components";

const getRootLevelMod = (rowId?: string) => {
  if (rowId == null) {
    return 0;
  }

  const [rootId] = rowId.split(".");
  return parseInt(rootId, 10) % 2;
};

const ListItem = styled.div<ItemProps<RowType<Resource>>>`
  cursor: ${(props) =>
    props.item != null && props.item.original.virtual
      ? "pointer"
      : "inherited"};

  background-color: ${(props) =>
    props.item != null &&
    getRootLevelMod(props.item?.id) === 1 &&
    (props.item.original.virtual || props.item.depth > 0)
      ? shadeColor(0.02, props.theme.background)
      : props.theme.background};
`;

const TableItemsList = styled.div``;

export type RenderCellCallback = (
  columnId: string,
  value: string,
  formattedValue: string
) => React.ReactNode;

export interface RepositoryTableBodyProps extends RepositoryTableReactiveProps {
  children?: React.ReactNode;
  rows: RowType<Resource>[];
  prepareRow: (row: RowType<Resource>) => void;
  width?: number;
  renderCell?: RenderCellCallback;
  properties?: Property[];
}

const RepositoryTableBody = ({
  children,
  rows,
  prepareRow,
  width,
  renderCell: renderCellProp,
  editMode,
  watchedColumns,
  properties,
}: RepositoryTableBodyProps) => {
  const render = usePropertyRender(
    useMemo(() => properties || [], [properties])
  );

  const { getListingColor } = useAnticipatedValues({ properties });

  const additionalRows = useMemo(() => {
    return React.Children.toArray(children);
  }, [children]);

  const renderAdditionalRow = (index: number) => {
    const row = additionalRows[index];
    return (
      <Row key={`table-child-${index}`}>
        <Cell condensed>{row}</Cell>
      </Row>
    );
  };

  const handleRenderProperty = useCallback(
    (propertyName: string, value: any) => {
      const label = render(propertyName, value);

      if (renderCellProp != null) {
        return renderCellProp(propertyName, value, label);
      }

      return label;
    },
    [render, renderCellProp]
  );

  return (
    <Virtuoso<RowType<Resource>>
      data={additionalRows.length > 0 ? undefined : rows}
      totalCount={useMemo(
        () => rows.length + additionalRows.length,
        [additionalRows.length, rows.length]
      )}
      components={{
        Scroller: TableBody,
        Item: ListItem,
        List: TableItemsList,
      }}
      style={{
        width: width || "100%",
      }}
      itemContent={(index) => {
        if (index < additionalRows.length) {
          return renderAdditionalRow(index);
        }

        const row = rows[index - additionalRows.length];
        prepareRow(row);

        return (
          <RepositoryTableRow
            row={_.clone(row)}
            textColor={getListingColor(row.original)}
            renderPropertyValue={handleRenderProperty}
            editMode={editMode}
            watchedColumns={watchedColumns}
          />
        );
      }}
    />
  );
};

export default RepositoryTableBody;

// export default React.memo(RepositoryTableBody, (prevProps, nextProps) => {
//   return false;
// const getSubRowsCount = (props: RepositoryTableBodyProps) => {
//   if (props.rows == null) {
//     return 0;
//   }

//   return props.rows.filter((r) => r.depth !== 0).length;
// };

// return (
//   getSubRowsCount(prevProps) === getSubRowsCount(nextProps) &&
//   prevProps.width === nextProps.width &&
//   prevProps.rows.length === nextProps.rows.length
// );
// });
