(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("lodash"), require("styled-components"), require("@fortawesome/free-solid-svg-icons"), require("@fortawesome/react-fontawesome"), require("@fortawesome/free-regular-svg-icons"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "lodash", "styled-components", "@fortawesome/free-solid-svg-icons", "@fortawesome/react-fontawesome", "@fortawesome/free-regular-svg-icons", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["cosmosComponents"] = factory(require("react"), require("lodash"), require("styled-components"), require("@fortawesome/free-solid-svg-icons"), require("@fortawesome/react-fontawesome"), require("@fortawesome/free-regular-svg-icons"), require("react-dom"));
	else
		root["cosmosComponents"] = factory(root["React"], root["_"], root[undefined], root["@fortawesome/free-solid-svg-icons"], root["@fortawesome/react-fontawesome"], root["@fortawesome/free-regular-svg-icons"], root["ReactDOM"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__442__, __WEBPACK_EXTERNAL_MODULE__276__, __WEBPACK_EXTERNAL_MODULE__540__, __WEBPACK_EXTERNAL_MODULE__285__, __WEBPACK_EXTERNAL_MODULE__889__, __WEBPACK_EXTERNAL_MODULE__238__, __WEBPACK_EXTERNAL_MODULE__3__) => {
return 