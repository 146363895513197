import React, { ReactElement, forwardRef, useMemo } from "react";
import styled, { css } from "styled-components";
import { mix } from "polished";
import classNames from "classnames";

const DOT_RADIUS = 12;
const DOT_PADDING = DOT_RADIUS * 5;
const BOTTOM_PADDING = 30;
const LINE_WIDTH = 4;

const StepLabel = styled.span``;

const StepSubtitle = styled.span``;

const activatedTimelineStepStyle = css`
  color: ${(props) => props.theme.primary};

  &:after {
    border-color: ${(props) => props.theme.primary};
    background-color: ${(props) => props.theme.primary};
  }

  &.active ~ & {
    color: ${(props) => mix(0.85, props.theme.background, props.theme.base)};

    &:after {
      background-color: ${(props) =>
        mix(0.9, props.theme.background, props.theme.base)};
      background-color: ${(props) => props.theme.background};
    }
  }

  ${StepSubtitle} {
    color: ${(props) => props.theme.body};
  }
`;

type TimelineStepContainerProps = {
  active?: boolean;
};

const TimelineStepContainer = styled.div<TimelineStepContainerProps>`
  position: relative;
  width: min-content;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.theme.base};
  text-transform: uppercase;
  transition: all ease-in-out 0.2s;

  padding: 0 0 ${`${DOT_PADDING + BOTTOM_PADDING}px`} 0;
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  flex-basis: 10%;
  flex-shrink: 0;

  ${StepLabel} {
    position: relative;
    user-select: none;
    width: 100%;
  }

  ${StepSubtitle} {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: ${(props) => mix(0.7, props.theme.background, props.theme.base)};
    font-weight: normal;
    font-size: 0.8rem;
    padding: 0 20px;
    transition: all ease-in-out 0.2s;
  }

  &:before {
    content: "";
    position: absolute;
    border-bottom: ${`${LINE_WIDTH}px`} solid
      ${(props) => mix(0.9, props.theme.background, props.theme.base)};
    width: 100%;
    bottom: ${`${DOT_PADDING / 2 - LINE_WIDTH / 2 + BOTTOM_PADDING}px`};
  }

  &:after {
    content: "";
    background-color: ${(props) =>
      mix(0.9, props.theme.background, props.theme.base)};
    border: ${`${LINE_WIDTH}px`} solid
      ${(props) => mix(0.9, props.theme.background, props.theme.base)};
    box-sizing: border-box;
    border-radius: 50%;
    padding: ${`${DOT_RADIUS}px`};
    position: absolute;
    bottom: ${`${BOTTOM_PADDING}px`};
    transform: translate(-50%, -50%);
    left: 50%;
    transition: all ease-in-out 0.2s;
  }

  ${(props) => props.active && activatedTimelineStepStyle}
`;

const selectableTimelineStyle = css`
  & > ${TimelineStepContainer} {
    cursor: pointer;
    user-select: none;
  }

  & > ${TimelineStepContainer}:hover {
    ${activatedTimelineStepStyle}
  }
`;

type TimelineContainerProps = {
  selectable?: boolean;
};

const TimelineContainer = styled.div<TimelineContainerProps>`
  display: flex;
  flex-shrink: 0;
  position: relative;
  margin-bottom: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;

  ${(props) => props.selectable && selectableTimelineStyle}

  ${TimelineStepContainer}.new-version {
    color: ${(props) => props.theme.base} !important;

    &:after {
      background-color: #fee715ff !important;
    }
  }
`;

export interface TimelineStepProps {
  active?: boolean;
  children?: React.ReactNode;
  subtitle?: string;
  onClick?: () => void;
  className?: string;
}

export const TimelineStep = ({
  active,
  children,
  subtitle,
  onClick,
  className,
}: TimelineStepProps) => {
  return (
    <TimelineStepContainer
      active={active}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      className={classNames(className, { active })}
      onClick={onClick}
    >
      <StepLabel>{children}</StepLabel>
      {subtitle != null && <StepSubtitle>{subtitle}</StepSubtitle>}
    </TimelineStepContainer>
  );
};

export interface TimelineProps {
  children?: React.ReactNode;
  selectable?: boolean;
  className?: string;
}

const Timeline = forwardRef<HTMLDivElement, TimelineProps>(
  ({ children, selectable, className }, ref) => {
    const steps = useMemo(
      () =>
        React.Children.toArray(children).filter(
          (c) => (c as ReactElement).type === TimelineStep
        ),
      [children]
    );

    return (
      <TimelineContainer
        ref={ref}
        selectable={selectable}
        className={className}
      >
        {steps}
      </TimelineContainer>
    );
  }
);

//@ts-ignore
Timeline.Step = TimelineStep;

export default Timeline;
