import { LoadingOverlay, PancoSelect } from "cosmos-components";
import React, { useMemo, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import SimpleTable from "../../components/SimpleTable";
import useCoreSelector from "../../hooks/useCoreSelector";
import accessRequestProperties from "./constants/accessRequestProperties";
import useProject from "../../hooks/useProject";
import _ from "lodash";
import { useCallback } from "react";
import useCoreDispatch from "../../hooks/useCoreDispatch";
import {
  addProjectMember,
  refuseProjectAccessRequest,
} from "../../actions/projectActions";
import useOrganization from "../../hooks/useOrganization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const AccessRequests = () => {
  const dispatch = useCoreDispatch();
  const accessRequests = useCoreSelector(
    (state) => state.project.accessRequests
  );
  const projectLoading = useCoreSelector((state) => state.project.loading);
  const loading = useCoreSelector((state) => state.usermanagement.loading);
  const projectSpocs = useCoreSelector((state) => state.project.spocs);

  const spocsMap = useMemo(
    () => _.groupBy(projectSpocs, (spoc) => _.first(spoc.department)),
    [projectSpocs]
  );

  const [groupAssignment, setGroupAssignment] = useState({});

  const { project, memberGroups } = useProject();
  const { getPositionInfo } = useOrganization();

  const getOptions = useCallback(
    (domainName) => {
      const mainGroups = project?.groups || [];
      const memberGroupsMap = _.groupBy(memberGroups, "groupName");

      return _(mainGroups)
        .orderBy("displayName")
        .filter((g) => g.domainName === domainName)
        .flatMap((g) => {
          const grps = memberGroupsMap[g.name] || [];
          const children = grps.map((ig) => ({
            ...ig,
            parentDisplayName: g.displayName,
          }));
          return [g, ...children];
        })
        .map((g) => ({
          value: g.name,
          label:
            g.groupName != null
              ? `${g.displayName} (${g.parentDisplayName})`
              : g.displayName,
        }))
        .value();
    },
    [project, memberGroups]
  );

  const renderRowActions = (request) => {
    const { principalId, domainName } = request;

    return (
      <div className="">
        <PancoSelect
          options={getOptions(domainName)}
          onChange={({ value }) => {
            const [firstValue] = value;
            setGroupAssignment((ass) => ({
              ...ass,
              [principalId]: firstValue,
            }));
          }}
          className="p-0 mb-2"
          clearable
        />
        <div className="d-flex">
          <Button
            block
            size="sm"
            onClick={() => {
              dispatch(
                addProjectMember({
                  groupName: groupAssignment[principalId],
                  principalId,
                })
              );
            }}
            disabled={groupAssignment[principalId] == null}
          >
            Accept
          </Button>
          <Button
            block
            size="sm"
            variant="outline-danger"
            className="mt-0 ml-2"
            onClick={() => {
              dispatch(
                refuseProjectAccessRequest({
                  principalId: principalId,
                })
              );
            }}
          >
            Refuse
          </Button>
        </div>
      </div>
    );
  };

  return (
    <LoadingOverlay loading={projectLoading || loading}>
      <Alert variant="warning">
        <Alert.Heading>
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
          Warning
        </Alert.Heading>
        Please be cautious to whom you are granting access to the project. Make
        sure that the person is known to your department and there is a reason
        to grant him an access. Consider the target group level of permission.
        Always select a group with minimal permissions needed.
      </Alert>

      <SimpleTable
        properties={accessRequestProperties}
        data={accessRequests}
        condensed={false}
        renderCell={(id, value, label, request) => {
          if (id === "spoc") {
            const { department: dep } = request;
            const spocs = spocsMap[dep] || [];
            return spocs.map((spoc) => spoc.userCommonName).join("; ");
          }

          if (id === "department") {
            const { department } = getPositionInfo(value);
            return department;
          }

          if (id === "actions") {
            return renderRowActions(request);
          }

          return label;
        }}
      />
    </LoadingOverlay>
  );
};

export default AccessRequests;
