import React, { useContext, useMemo, useState } from "react";
import useProperties from "../../../hooks/useProperties";
import SelectPropertyModal from "../components/modals/SelectPropertyModal";
import ProjectEditorContext from "../contexts/ProjectEditorContext";
import ResourceTypeContext from "../contexts/ResourceTypeContext";
import CreatePropertyModal from "../components/modals/CreatePropertyModal";
import ProjectEditorPropertiesContext from "../contexts/ProjectEditorPropertiesContext";
import CreateGroupModal from "../components/modals/CreateGroupModal";
import ChangePropertyTypeModal from "../components/modals/ChangePropertyTypeModal";
import MovePropertyModal from "../components/modals/MovePropertyModal";
import { Property } from "cosmos-config/lib/property/property";
import { Group } from "cosmos-config/lib/group/group";
import PropertyEditModal from "../components/modals/PropertyEditModal";

type ResourceTypeProviderStateValue = {
  showCreateGroupModal?: boolean;
  showAddPropertyModal?: boolean;
  showCreatePropertyModal?: boolean;
  showChangeTypeModal?: boolean;
  showMovePropertyModal?: boolean;
  showEditPropertyModal?: boolean;
  propertyName?: string;
  groupName?: string;
};

const initialState = {};

export interface ResourceTypeProviderProps {
  children: React.ReactNode;
}

const ResourceTypeProvider = ({ children }: ResourceTypeProviderProps) => {
  const [state, setState] =
    useState<ResourceTypeProviderStateValue>(initialState);

  const { propertiesMap } = useProperties();

  const { templates } = useContext(ProjectEditorContext);

  const {
    resourceType,
    folder,
    createProperty,
    createGroup,
    updateProperty,
    groupMoveProperty,
  } = useContext(ProjectEditorPropertiesContext);

  const folderType = useMemo(() => folder?.name, [folder]);

  const availableProperties = useMemo(() => {
    const filterUsed = (property: Property) => {
      const match = propertiesMap[property.name];
      return match == null || match.resourceType !== resourceType;
    };

    return templates
      .map((property) => {
        if ((property as Group).group) {
          const group = property as Group;
          const properties = group.properties.filter(filterUsed);
          return {
            ...group,
            properties,
          };
        }

        return property as Property;
      })
      .filter((property) => {
        if ((property as Group).group) {
          return (property as Group).properties.length > 0;
        }

        return filterUsed(property as Property);
      });
  }, [propertiesMap, templates, resourceType]);

  return (
    <>
      <ResourceTypeContext.Provider
        value={{
          addProperty: (groupName) => {
            setState({
              ...initialState,
              showAddPropertyModal: true,
              groupName,
            });
          },
          createProperty: (groupName) => {
            setState({
              ...initialState,
              showCreatePropertyModal: true,
              groupName,
            });
          },
          createGroup: () => {
            setState({
              ...initialState,
              showCreateGroupModal: true,
            });
          },
          changeType: (propertyName) => {
            setState({
              ...initialState,
              showChangeTypeModal: true,
              propertyName,
            });
          },
          moveProperty: (propertyName) => {
            setState({
              ...initialState,
              showMovePropertyModal: true,
              propertyName,
            });
          },
          editProperty: (propertyName) => {
            setState({
              ...initialState,
              showEditPropertyModal: true,
              propertyName,
            });
          },
        }}
      >
        {children}
      </ResourceTypeContext.Provider>

      <SelectPropertyModal
        show={state.showAddPropertyModal}
        onClose={() => setState(initialState)}
        properties={availableProperties}
        onSelect={(propertyName) => {
          if (state.groupName != null) {
            createProperty(state.groupName, {
              name: propertyName,
              resourceType,
              folderType,
            } as Partial<Property>);
            setState(initialState);
          }
        }}
      />

      <CreatePropertyModal
        show={state.showCreatePropertyModal}
        onClose={() => setState(initialState)}
        onCreate={(property) => {
          if (state.groupName != null) {
            createProperty(
              state.groupName,
              {
                ...property,
                editable: true,
                updatable: true,
                resourceType,
                folderType,
                multiple: property.handleAsMulti,
              },
              true
            );
            setState(initialState);
          }
        }}
      />

      <CreateGroupModal
        show={state.showCreateGroupModal}
        onClose={() => setState(initialState)}
        onSubmit={(group) => {
          createGroup(group);
          setState(initialState);
        }}
      />

      <ChangePropertyTypeModal
        show={state.showChangeTypeModal}
        onClose={() => setState(initialState)}
        propertyName={state.propertyName}
        onChange={(resource) => {
          if (state.propertyName != null) {
            updateProperty(state.propertyName, {
              ...resource,
              resourceType,
              folderType,
            });
            setState(initialState);
          }
        }}
      />

      <MovePropertyModal
        show={state.showMovePropertyModal}
        onClose={() => setState(initialState)}
        onChange={(move) => {
          const { targetGroup, after } = move;

          if (
            state.propertyName != null &&
            targetGroup != null &&
            after != null
          ) {
            groupMoveProperty(state.propertyName, targetGroup, after);
            setState(initialState);
          }
        }}
      />

      <PropertyEditModal
        show={state.showEditPropertyModal}
        onClose={() => setState(initialState)}
        property={
          state.propertyName == null
            ? undefined
            : propertiesMap[state.propertyName]
        }
      />
    </>
  );
};

export default ResourceTypeProvider;
