import {
  property,
  systemProperty,
  valuesetSource,
} from 'cosmos-config/generator';

const reminderProperties = [
  property('Name', 'name'),
  property('Description', 'description').textArea().optional(),
  systemProperty('resourceType').default(2),
  systemProperty('resourceName', 'resourceName', true),
  property('Trigger Type')
    .selectableAttribute(
      'triggerType',
      'reminder_trigger_type',
      valuesetSource.STATIC_VALUESET
    )
    .valueset([
      {
        label: 'Once',
        value: 'ONCE',
      },
      {
        label: 'Recurrent',
        value: 'RECURRENT',
      },
      {
        label: 'Event',
        value: 'EVENT',
      },
    ]),
  property('Send on Event')
    .controlVisibility()
    .hiddenUntil('triggerType', 'EVENT')
    .selectableAttribute(
      'eventType',
      'reminder_event_type',
      valuesetSource.STATIC_VALUESET
    )
    .dependingOn('resourceType')
    .valueset([
      {
        label: 'All Updates',
        value: 'ALL',
        filter: 2,
      },
      {
        label: 'Metadata Updated',
        value: 'UPDATED_PROPERTIES',
        filter: 2,
      },
      {
        label: 'Document Uploaded',
        value: 'CREATED_CHILD',
        filter: 1,
      },
      {
        label: 'Content Updated',
        value: 'UPDATED_CONTENT',
        filter: 2,
      },
      {
        label: 'Deleted',
        value: 'DELETED',
        filter: 2,
      },
    ]),
  property('Send At')
    .controlVisibility()
    .hiddenUntil('triggerType', 'ONCE')
    .dateAttribute('startingAt'),
  property('Status')
    .selectableAttribute(
      'status',
      'reminder_status',
      valuesetSource.STATIC_VALUESET
    )
    .valueset([
      {
        label: 'Active',
        value: 'ACTIVE',
      },
      {
        label: 'Cancelled',
        value: 'CANCELLED',
      },
      {
        label: 'Exhausted',
        value: 'EXHAUSTED',
      },
    ])
    .excludeForm()
    .readonly(),
];

export default reminderProperties;
