import {
  HelperTooltip,
  LoadingOverlay,
  PancoArea,
  PancoSelect,
  PancoSelectOption,
  PancoSwitch,
} from "cosmos-components";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Project } from "../../contexts/ProjectContext";
import { AccessRequest } from "../../types/accessRequest";
import projectApi from "../../apis/projectApi";
import { useQuery } from "@tanstack/react-query";
import { useCurrentUser } from "../../hooks";
import PrincipalSelect from "../form/PrincipalSelect";

export interface ProjectAccessRequestProps {
  project?: Project;
  onChange?: (accessRequest: Partial<AccessRequest>) => void;
}

const ProjectAccessRequest = ({
  project,
  onChange,
}: ProjectAccessRequestProps) => {
  const { principalId } = useCurrentUser();

  const [accessRequest, setAccessRequest] = useState<Partial<AccessRequest>>({
    resourceId: project?.resourceId,
  });

  const projectCode = useMemo(() => project?.code, [project]);

  const { data, isLoading } = useQuery({
    queryKey: ["project-sub-items", projectCode, principalId],
    queryFn: () => {
      if (projectCode == null) {
        throw new Error("Project code cannot be null!");
      }

      return projectApi.getProjectSubItems(projectCode);
    },
    enabled: projectCode != null,
  });

  useEffect(() => {
    if (onChange != null) {
      onChange(accessRequest);
    }
  }, [accessRequest, onChange]);

  const subItemOptions = useMemo(
    () =>
      _(data)
        .mapValues((item) => {
          const props = _(item.properties)
            .keyBy("name")
            .mapValues("value")
            .value();

          return {
            ...props,
            ...item,
          };
        })
        .filter(
          (item: any) =>
            item.displayName != null &&
            item.accesslevel != null &&
            item.accesslevel < 10
        )
        .map(
          (item) =>
            ({
              label: item.displayName,
              value: item.id,
            } as PancoSelectOption)
        )
        .orderBy("label")
        .value(),

    [data]
  );

  return (
    <LoadingOverlay loading={isLoading}>
      <p>
        Would you like to to request an access to {project?.name}? Responsible
        people will be notified immediately.
      </p>

      {subItemOptions.length > 0 && (
        <>
          <p className="font-weight-bold">
            1. Which role are you requesting?
            <HelperTooltip
              className="ml-2"
              text={
                <div>
                  <p>
                    If you select "View All", you will have viewing access to
                    all folders and documents in this module unless access is
                    further restricted.
                  </p>
                  <p>
                    If you select "Team Member", you will be asked to select a
                    specific folder (e.g. Department/Project/Company, etc.)
                    where you can additionally upload and edit documents. You
                    will retain viewing access to all other folders.
                  </p>
                </div>
              }
            />
          </p>
          <p>
            <PancoSwitch
              labelRight="Team Member"
              labelLeft="View All"
              value={accessRequest.resourceId !== project?.resourceId}
              onChange={({ value }) => {
                setAccessRequest((ar) => ({
                  ...ar,
                  resourceId: value ? null : project?.resourceId,
                }));
              }}
            />
          </p>

          {accessRequest.resourceId !== project?.resourceId && (
            <PancoSelect
              loading={isLoading}
              options={subItemOptions}
              boxed
              onChange={({ value }) => {
                const resourceId = _.first(value) || null;
                setAccessRequest((ar) => ({
                  ...ar,
                  resourceId: resourceId || project?.resourceId,
                }));
              }}
              clearable
            />
          )}
        </>
      )}

      <p className="font-weight-bold mt-3">
        2. Please select your responsible manager. *
      </p>

      <PrincipalSelect
        boxed
        onChange={(a) => {
          setAccessRequest((ac) => ({
            ...ac,
            validatorPrincipalId: _.first(a.value),
          }));
        }}
      />

      <p className="font-weight-bold mt-3">
        3. Please give us a comprehensible reason for your access request. *
      </p>

      <PancoArea
        onChange={({ value }) => {
          setAccessRequest((ac) => ({
            ...ac,
            reason: value,
          }));
        }}
      />
    </LoadingOverlay>
  );
};

export default ProjectAccessRequest;
