import { createSlice } from "@reduxjs/toolkit";

export interface DomainState {
  loading: boolean;
}

const domainSlice = createSlice({
  name: "domain",
  initialState: {
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload == null ? true : action.payload;
    },
  },
});

export default domainSlice;
