const userSettingsScope = {
  APPEARANCE: 'APPEARANCE',
  FIXED_ATTRIBUTES: 'FIXED_ATTRIBUTES',
  UPLOAD_PRESETS: 'UPLOAD_PRESETS',
  DEFAULT_FILTERS: 'DEFAULT_FILTERS',
  DASHBOARD_LAYOUT: 'DASHBOARD_LAYOUT',
  TABLE_COLUMN_ORDER: 'TABLE_COLUMN_ORDER',
  PROJECT_SELECTION: 'PROJECT_SELECTION',

  getTableColumnOrder(
    folderType,
    subtree,
    subFolderType = null,
    identifier = null
  ) {
    const self = this;
    function* generateScope() {
      yield self.TABLE_COLUMN_ORDER;

      if (folderType != null) {
        yield 'FOLDER';
        yield folderType;

        if (identifier != null) {
          yield 'UNIQUE';
          yield identifier;
        }

        if (subFolderType != null) {
          yield 'LISTING';
          yield subFolderType;
        }
      } else if (subtree) {
        yield 'SUBTREE';
      }
    }

    return [...generateScope()].join(':');

    // if (folderType != null) {
    //   const folderTypeColumnOrder = `${this.TABLE_COLUMN_ORDER}:FOLDER:${folderType}`;

    //   if (identifier != null) {
    //     return `${folderTypeColumnOrder}:UNIQUE:${identifier}`;
    //   }

    //   return folderTypeColumnOrder;
    // }

    // return subtree
    //   ? `${this.TABLE_COLUMN_ORDER}:SUBTREE`
    //   : this.TABLE_COLUMN_ORDER;
  },
};

export default userSettingsScope;
