import React, { ChangeEvent, useMemo, useRef } from "react";
import { CloseButton, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Responsive, WidthProvider } from "react-grid-layout";
import useDashboard from "../hooks/useDashboard";
import { widget, widgetType } from "cosmos-config/generator";
import _ from "lodash";
import { uuidv4 } from "cosmos-config/utils";
import useProperties from "../../../hooks/useProperties";
import {
  BasicDashboard,
  DashboardWidget,
  WidgetBody,
} from "./widgets/dashboardFragments";
import CosmosWidgetComponent from "./CosmosWidgetComponent";
import useQueryWidgets from "../hooks/useQueryWidgets";
import { Widget } from "cosmos-config/lib/widget/widget";
import DashboardContext from "../contexts/DashboardContext";
import baseDashboardWidgets from "../constants/baseDashboardWidgets";
import { OnOpenResourceCallback } from "../../../types/callbacks";

const ResponsiveGridLayout = WidthProvider(Responsive);

export interface DashboardProps {
  resourceId?: string;
  filter?: Record<string, any>;
  allowEditing?: boolean;
  onOpenResource?: OnOpenResourceCallback;
  onFilterChange?: (propertyName: string, value: any) => void;
}

const widgetsState: Widget[] = [];

const Dashboard = ({
  resourceId,
  filter,
  allowEditing,
  onOpenResource,
  onFilterChange,
}: DashboardProps) => {
  const { queryWidgets } = useQueryWidgets();
  const { widgets } = useProperties();

  const availableWidgets = useMemo(
    () => _.concat(baseDashboardWidgets, widgets, queryWidgets),
    [queryWidgets, widgets]
  );

  const initialLayout = useMemo(() => {
    if (
      widgetsState == null ||
      (Array.isArray(widgetsState) && widgetsState.length === 0)
    ) {
      return availableWidgets.map(
        (w) =>
          ({
            ...w,
            id: uuidv4(),
          } as Widget)
      );
    }

    return widgetsState
      .map((w) => {
        const { type } = w;
        if (
          [
            ...Object.keys(widgetType.aggregate),
            ...Object.keys(widgetType.data),
          ].includes(type)
        ) {
          if (w.propertyName != null) {
            return {
              ...widget(w.title, w.type).repository(
                w.propertyName,
                w.resourceType
              ),
              ...w,
            } as Widget;
          }
        }

        if (Object.keys(widgetType.general).includes(type)) {
          return {
            ...widget(w.title, w.type).excludeTitle(
              type === widgetType.general.GREETING
            ),
            ...w,
          } as Widget;
        }

        return null;
      })
      .filter((x) => x != null) as Widget[];
  }, [widgets, queryWidgets]);

  const scrollPlaceholderRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation("module");

  const {
    addWidget,
    // toggleEditMode,
    // touched,
    layout,
    editMode,
    toggleTouched,
    // updateLayout,
    removeWidget,
  } = useDashboard({
    initialLayout,
    widgets: availableWidgets,
  });

  const handleSelect = (type: string, value: any, propertyName?: string) => {
    if (type === "document") {
      if (onOpenResource != null) {
        onOpenResource(2, value);
      }
    } else if (type === "filterset") {
      // dispatch(selectFilterset(value));
      // navigate(SEARCH_ROUTE);
    } else if (type === "filter") {
      if (
        value != null &&
        value !== "" &&
        onFilterChange != null &&
        propertyName != null
      ) {
        onFilterChange(propertyName, value);
        // navigate(SEARCH_ROUTE);
      }
    } else if (type === "process") {
      // navigate(`${PROCESS_ROUTE}/${value}`);
    }
  };

  const renderLayout = () => {
    return layout.map((w) => (
      <DashboardWidget key={w.id} data-grid={w.position}>
        {w.title != null && !w.hideTitle && (
          <h5>
            {w.title}
            {editMode && (
              <CloseButton
                onClick={() => {
                  if (w.id != null) {
                    removeWidget(w.id);
                  }
                }}
              />
            )}
          </h5>
        )}

        <WidgetBody>
          <CosmosWidgetComponent widget={w} onSelect={handleSelect} />
        </WidgetBody>
      </DashboardWidget>
    ));
  };

  return (
    <BasicDashboard>
      {allowEditing && (
        // <EditModeHeader
        //   className="my-3 p-0"
        //   touched={touched}
        //   onChange={toggleEditMode}
        //   onSave={() => {
        //     // dispatch(saveAllWidgets(layout));
        //   }}
        //   onRestore={() => {
        //     // dispatch(restoreDefaultWidgets());
        //   }}
        // >
        <FormGroup className="mb-0 mr-2">
          <select
            className="form-control"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              const {
                target: { value },
              } = e;

              if (value != null) {
                addWidget(value);

                if (scrollPlaceholderRef.current != null) {
                  scrollPlaceholderRef.current.scrollIntoView({
                    behavior: "smooth",
                  });
                  e.target.value = "";
                }
              }
            }}
          >
            <option value="" selected>
              {t("button.add_column")}
            </option>
            {widgets
              .filter((w) => !layout.map((l) => l.name).includes(w.name))
              .sort((a, b) => String(a.title).localeCompare(b.title))
              .map((widget) => (
                <option key={widget.name} value={widget.name}>
                  {widget.title}
                </option>
              ))}
          </select>
        </FormGroup>
      )}

      {/* <LoadingOverlay loading={false}> */}
      <DashboardContext.Provider
        value={{ filter: filter || {}, resourceId: resourceId || null }}
      >
        <ResponsiveGridLayout
          containerPadding={[0, 0]}
          measureBeforeMount={false}
          rowHeight={100}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 }}
          compactType="vertical"
          isResizable={editMode}
          isDraggable={editMode}
          onLayoutChange={() => {
            // updateLayout(layout);
          }}
          onDrag={() => toggleTouched(true)}
          onResize={() => toggleTouched(true)}
          margin={[15, 15]}
        >
          {renderLayout()}
        </ResponsiveGridLayout>
      </DashboardContext.Provider>
      {/* </LoadingOverlay> */}
      <div ref={scrollPlaceholderRef} />
    </BasicDashboard>
  );
};

export default Dashboard;
