import GatewayApi from './conf/GatewayApi';

const gatewayApi = GatewayApi.build();

const postComment = (resourceId, parameter, comment, nodeId) => {
  const url =
    nodeId === null
      ? `/repository/res/${resourceId}/comments`
      : `/repository/res/${resourceId}/comments/${nodeId}`;

  return gatewayApi
    .post(url, comment)
    .then((response) => Promise.resolve(response.data));
};

const getComments = (resourceId, parameter) => {
  return gatewayApi
    .get(`/repository/res/${resourceId}/comments`)
    .then((response) => Promise.resolve(response.data));
};

const getUserLatestChatComments = () => {
  return gatewayApi
    .get(`/user/comments`)
    .then((response) => Promise.resolve(response.data));
};

const updateComment = (commentId, comment) => {
  return gatewayApi
    .put(`/comment/${commentId}`, comment)
    .then((response) => Promise.resolve(response.data));
};

const deleteComment = (commentId) => {
  return gatewayApi
    .delete(`/comment/${commentId}`)
    .then((response) => Promise.resolve(response.data));
};

export default {
  postComment,
  getComments,
  getUserLatestChatComments,

  updateComment,
  deleteComment,
};
