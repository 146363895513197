import _ from "lodash";
import React, { useMemo } from "react";
import { GenericDashboard } from "../../../modules/dashboard";
import { CosmosFormGroupProps } from "./CosmosFormGroup";

export interface WidgetFormGroupProps extends CosmosFormGroupProps {}

const WidgetFormGroup = ({ group, resource }: WidgetFormGroupProps) => {
  const resourceId = useMemo(() => {
    return resource?.id || resource?.resourceId;
  }, [resource]);

  const widgets = useMemo(() => {
    if (group == null) {
      return [];
    }

    return _(group.buildChildren()).flatMap("widgets").value();
  }, [group]);

  return (
    <GenericDashboard resourceId={resourceId} widgets={widgets} columns={1} />
  );
};

export default WidgetFormGroup;
