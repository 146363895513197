import * as actions from './types';

/**
 * Filter folder action generators.
 * @module actions/filterFolder
 * @category Actions
 */

/**
 * Action for setting filter folder.
 * @function
 * @param {} filterFolder -
 */
export const setFilterFolder = (filterFolder) => ({
  type: actions.filterFolder.SET_FILTER_FOLDER,
  filterFolder,
});

/**
 * Action for setting filter folder properties.
 * @function
 * @param {string} filterFolderId - folder id
 * @param {} properties - filter folder properties
 */
export const setFilterFolderProperties = (filterFolderId, properties) => ({
  type: actions.filterFolder.SET_FILTER_FOLDER_PROPERTIES,
  filterFolderId,
  properties,
});

/**
 * Action for saving filter folder.
 * @function
 * @param {string} id - id of the folder
 * @param {string} filterName - name of the filter
 * @param {string} filterValue - filter value
 * @param {} properties -
 */
export const saveFilterFolder = (id, filterName, filterValue, properties) => ({
  type: actions.filterFolder.SAVE_FILTER_FOLDER,
  id,
  filterName,
  filterValue,
  properties,
});

/**
 * Action for fetching filter folder.
 * @function
 * @param {string} filterName - name of the filter
 * @param {string} filterValue - filter value
 */
export const fetchFilterFolder = (filterName, filterValue) => ({
  type: actions.filterFolder.FETCH_FILTER_FOLDER,
  filterName,
  filterValue,
});
