import { createElement as h } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface PancoTooltipProps {
  children?: React.ReactNode;
  text?: any;
  placement?: string;
  className?: string;
}

const PancoTooltip = ({
  children,
  text,
  placement,
  className,
}: PancoTooltipProps) => {
  return h(
    // @ts-ignore
    OverlayTrigger,
    {
      placement,
      delay: { show: 500, hide: 500 },
      overlay: (props: any) =>
        h(
          // @ts-ignore
          Tooltip,
          { ...props },
          typeof text === "function" ? text.apply() : text
        ),
    },
    h("span", { className }, children)
  );
};

PancoTooltip.defaultProps = {
  text: () => {},
  placement: "right",
};

export default PancoTooltip;
