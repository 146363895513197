import React from "react";
import { useContext } from "react";
import RepositoryTableContext from "../RepositoryTableContext";
import { Row } from "react-table";
import type Resource from "../../../types/resource";
import { UseExpandedRowProps } from "react-table";
import { propertyEquals } from "../../../utils";
import _ from "lodash";
import TableRowExpanderButton from "../../TableRowExpanderButton";

declare type ExpanderCellProps = {
  row: Row<Resource> & UseExpandedRowProps<Resource>;
};

const ExpanderCell = React.memo(
  ({ row }: ExpanderCellProps) => {
    const { original: resource } = row;

    const { onRowExpand, parentEmailIds } = useContext(RepositoryTableContext);

    const isVerticalMain = propertyEquals(
      resource,
      "parentdocumentid",
      resource.id
    );
    const isParentEmail = parentEmailIds.includes(resource.id);
    const isLinked = resource.LinkedDocs?.length > 0;

    const hasSubRows = Array.isArray(row.subRows) && row.subRows.length > 0;

    return (
      <TableRowExpanderButton
        depth={row.depth}
        canExpand={
          hasSubRows ||
          (row.depth === 0 && (isLinked || isVerticalMain || isParentEmail))
        }
        expanded={row.isExpanded}
        onToggle={() => {
          row.toggleRowExpanded();

          onRowExpand(resource, {
            isVerticalMain,
            isLinked,
            isParentEmail,
            hasSubRows,
          });
        }}
      />
    );
  },
  (prev, next) => {
    return false;
    // return _(prev.row.isExpanded).isEqual(next.row.isExpanded);
  }
);

ExpanderCell.displayName = "ExpanderCell";

export default ExpanderCell;
