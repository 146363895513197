import React, { useMemo, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import useProject from "../../../hooks/useProject";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useResourceAccessPermissions from "../hooks/useResourceAccessPermissions";
import PermissionRequestModal from "./PermissionRequestModal";

const FORBIDDEN_STRICLY_CONFIDENTIAL = "strictly-confidential";
const FORBIDDEN_ACCESS_DENIED = "access-denied";

/**
 * @category Component
 */
export interface AccessInterceptorProps {
  resourceId?: string;
  errorType?:
    | typeof FORBIDDEN_STRICLY_CONFIDENTIAL
    | typeof FORBIDDEN_ACCESS_DENIED;
  hasError?: boolean;
  children?: React.ReactNode;
}

/**
 * @category Component
 * @param props
 * @returns
 */
const AccessInterceptor = ({
  resourceId,
  errorType,
  hasError,
  children,
}: AccessInterceptorProps) => {
  const [showRequestPermissionModal, setShowRequestPermissionModal] =
    useState(false);

  const { getCurrentUserLevel, requestUserPermission } =
    useResourceAccessPermissions({ resourceId });

  const { project, requestProjectAccess } = useProject();

  const permission = useMemo(
    () => getCurrentUserLevel(true) >= 5,
    [getCurrentUserLevel]
  );

  const renderHeader = () => {
    if (errorType === FORBIDDEN_STRICLY_CONFIDENTIAL) {
      return <span className="mr-2">Access Restricted</span>;
    }

    if (errorType === FORBIDDEN_ACCESS_DENIED) {
      return <span className="mr-2">Project Access</span>;
    }
  };

  const renderBody = () => {
    if (errorType === FORBIDDEN_STRICLY_CONFIDENTIAL) {
      if (!permission) {
        return (
          <>
            <p>
              You have no permission to see this document. If you need to see
              this document please request for a permission and wait for
              response.
            </p>
            <p>Thank you for your patience.</p>

            <Button
              block
              variant="light"
              onClick={() => setShowRequestPermissionModal(true)}
            >
              Request Permission
            </Button>
          </>
        );
      } else {
        return (
          <>
            <p>
              You&apos;ve already requested for permission but it is not
              resolved yet.
            </p>
            <p>Thank you for patience.</p>
          </>
        );
      }
    }

    if (errorType === FORBIDDEN_ACCESS_DENIED) {
      return (
        <>
          <p>
            You have no permission to the documents of this project. If you need
            to see this document please request for a project access and wait
            for response.
          </p>
          <p>Thank you for your patience.</p>

          <Button
            block
            variant="light"
            onClick={() => {
              if (project != null) {
                requestProjectAccess(project?.code);
              }
            }}
          >
            Request Project Access
          </Button>
        </>
      );
    }
  };

  if (hasError) {
    return (
      <div className="permission-error-wrapper">
        <div className="blured-content">{children}</div>
        <Row className="permission-error">
          <Col md={{ span: 6, offset: 3 }} className="my-auto">
            <Card bg="danger" text="light">
              <Card.Header>
                <Card.Title className="text-light m-0">
                  {renderHeader()}
                </Card.Title>
              </Card.Header>
              <Card.Body>{renderBody()}</Card.Body>
            </Card>
          </Col>
        </Row>

        <PermissionRequestModal
          show={showRequestPermissionModal}
          onClose={() => setShowRequestPermissionModal(false)}
          onRequestPermission={(reason) => {
            requestUserPermission(reason).then(() =>
              setShowRequestPermissionModal(false)
            );
          }}
        />
      </div>
    );
  }

  return children;
};

export default AccessInterceptor;
