/**
 * @enum {number}
 * @category Property
 * @readonly
 */
enum PrincipalType {
  GROUP = 3,
  POSITION = 2,
  USER = 1,
}

export default PrincipalType;
