import { eventChannel } from 'redux-saga';
import { delay, call, race, take } from 'redux-saga/effects';
import { generateUrlParams } from '../../Utils';

const redirectUri = 'https://cosmos.apps.res.rwe.com/oauth/redirect';
const clientId = '5d3bd117-c8db-483c-8a8f-9239368d533c';
const baseUrl = 'https://account.docusign.com';

const generateConsentUrl = () => {
  const paramsList = generateUrlParams({
    response_type: 'code',
    scope: 'signature impersonation',
    client_id: clientId,
    redirect_uri: redirectUri,
  });

  return `${baseUrl}/oauth/auth?${paramsList}`;
};

function* docusignConsentSaga() {
  const tokenChannel = yield eventChannel((emitter) => {
    const messageListener = (event) => {
      const { data } = event;
      if (data?.accessToken != null) {
        emitter({ type: 'ADFS_TOKEN_RECEIVED', token: data.accessToken });
      }
    };

    window.addEventListener('message', messageListener, false);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  });

  const windowReference = window.open(
    generateConsentUrl(),
    'RWE ADFS',
    'toolbar=no, menubar=no, width=500, height=700, top=100, left=100'
  );

  function* checkWindowClose() {
    while (true) {
      if (windowReference.closed) {
        break;
      }

      yield delay(1000);
    }
  }

  const [action] = yield race([take(tokenChannel), call(checkWindowClose)]);

  windowReference.close();
  return action?.token != null;
}

export default docusignConsentSaga;
