import group, { Group } from "./group";
import GroupType from "./groupType";

/**
 * @interface
 * @extends Group
 * @category Group
 */
export interface DataTableGroup extends Group {
  path: string;
  dataPath(path: string): DataTableGroup;
}

/**
 * Data Table Group Generator.
 * @category Group
 * @author Patrik Pancisin
 * @class dataTableGroup
 * @classdesc Basic building block of cosmos.
 * @extends group
 * @extends conditionalElement
 * @param {string} label - The label of the group displayed in cosmos.
 * @param {string} name - The name of the group.
 */
const dataTableGroup = (label: string, name: string): DataTableGroup => ({
  ...group(label, name, "datatable"),
  type: GroupType.DATATABLE,
  path: "",

  dataPath(path) {
    this.path = path;
    return this;
  },
});

export default dataTableGroup;
