import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import Resource from "../types/resource";
import _ from "lodash";
import resourceContentApi, {
  GetContentItemOptions,
} from "../apis/resourceContentApi";
import { parseResourceId } from "../utils/resourceUtils";
import { generateRenditionId } from "../utils";

const getVersionIdentifier = (
  resource?: Resource,
  versionIdentifier?: number
) => {
  if (versionIdentifier != null) {
    return versionIdentifier;
  }

  if (resource != null) {
    const { resourcetype, refresourceversionidentifier, versionidentifier } =
      resource;

    const latestVersionIdentifier =
      resourcetype === 3 ? refresourceversionidentifier : versionidentifier;

    return latestVersionIdentifier;
  }

  return null;
};

const isExternalSource = (resource?: Resource) => {
  if (resource == null) {
    return true;
  }

  const { targeturl, placeholder, contenttype } = resource;

  return (
    targeturl != null ||
    placeholder ||
    [
      "application/x-zip-compressed",
      "application/zip",
      "audio/mpeg",
      "image/x-dwg",
    ].includes(contenttype)
  );
};

const getContentResourceId = (
  resource?: Resource,
  versionIdentifier?: string
) => {
  if (resource == null) {
    return null;
  }

  const { contenttype } = resource;

  const { docarea } = parseResourceId(resource.id);

  const octs = ["application/pdf", "video/mp4"];

  if (octs.includes(contenttype)) {
    return `${docarea}$${versionIdentifier}$NOTSET$2$NOTSET`;
  }

  return `${docarea}$6$NOTSET$104$${versionIdentifier}`;
};

const actions = {
  REGISTER_RENDITION: "resourceContent/registerRendition",
  UPDATE_RENDITION: "resourceContent/updateRendition",
  UPDATE_LOADING_STATE: "resourceContent/updateLoadingState",
};

const resourceContentReducerInitialState = {
  renditions: {},
  loadingState: {},
};

export type Rendition = {
  id: string;
  name: string | null;
  contentUrl: string | null;
  externalUrl: string | null;
};

export type LoadingState = {
  loading: boolean;
  progress: number;
  message: string | null;
  error: boolean | null;
};

type ResourceContentReducerState = {
  renditions: Record<string, Rendition>;
  loadingState: Record<string, LoadingState>;
};

type ResourceContentReducerAction = {
  type: string;
  payload: {
    renditionId: string;
    name?: string | null;
    externalUrl?: string | null;
    contentUrl?: string;

    loadingState?: LoadingState;
  };
};

const resourceContentReducer = (
  state: ResourceContentReducerState,
  action: ResourceContentReducerAction
) => {
  const renditionId = action.payload.renditionId;

  if (action.type === actions.UPDATE_RENDITION) {
    const rendition = state.renditions[renditionId] || {};

    return {
      ...state,
      renditions: {
        ...state.renditions,
        [renditionId]: {
          ...rendition,

          contentUrl: action.payload.contentUrl || rendition.contentUrl,
        },
      },
    };
  }

  if (action.type === actions.REGISTER_RENDITION) {
    return {
      ...state,
      renditions: {
        ...state.renditions,
        [renditionId]: {
          id: renditionId,
          name: action.payload.name || null,
          externalUrl: action.payload.externalUrl || null,
          contentUrl: null,
        },
      },
    };
  }

  if (action.type === actions.UPDATE_LOADING_STATE) {
    const loadingState = state.loadingState?.[renditionId];
    const loadedCompletely = loadingState != null && !loadingState.loading;

    if (action.payload.loadingState != null) {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [renditionId]: {
            ...action.payload.loadingState,
            loading: loadedCompletely
              ? false
              : !!action.payload.loadingState?.loading,
          },
        },
      };
    }
  }

  return state;
};

export interface UseResourceContentProps {
  resource?: Resource;
}

const useResourceContent = ({ resource }: UseResourceContentProps) => {
  const [localState, localDispatch] = useReducer(
    resourceContentReducer,
    resourceContentReducerInitialState
  );
  const abortHandles = useRef<Record<string, AbortController>>({});

  const abortAllHandles = useCallback(() => {
    _.valuesIn(abortHandles.current).forEach((handle) =>
      handle.abort("Aborted by leaving.")
    );
  }, []);

  useEffect(() => {
    return () => abortAllHandles();
  }, [abortAllHandles]);

  const updateLoadingState = useCallback(
    (
      renditionId: string,
      loading: boolean,
      loadedRation: number,
      message: string
    ) => {
      const progress = Math.round((loadedRation || 0) * 100) || 0;
      localDispatch({
        type: actions.UPDATE_LOADING_STATE,
        payload: {
          renditionId,
          loadingState: {
            loading,
            progress,
            message,
            error: null,
          },
        },
      });
    },
    []
  );

  const getContentItemRendition = useCallback(
    (itemIndex: number, versionIdentifier?: number): Promise<Rendition> => {
      if (resource == null) {
        return Promise.reject("Cannot retrieve resource rendition id!");
      }

      const verIdent = getVersionIdentifier(resource, versionIdentifier);
      const renditionId = generateRenditionId(
        resource.identifier,
        itemIndex,
        verIdent
      );

      const rendition = localState.renditions[renditionId];

      if (rendition != null) {
        return Promise.resolve(rendition);
      }

      const { itemdisplayname, targeturl } = resource;

      localDispatch({
        type: actions.REGISTER_RENDITION,
        payload: {
          renditionId,
          name: itemdisplayname?.[itemIndex],
          externalUrl: targeturl,
        },
      });

      if (isExternalSource(resource)) {
        return Promise.resolve(localState.renditions[renditionId]);
      }

      if (verIdent == null) {
        return Promise.reject("Version identifier not available!");
      }

      return new Promise((resolve, reject) => {
        const setLoading = _.curry(updateLoadingState, 4)(renditionId);

        const { itemlength } = resource;

        const length = itemlength != null ? itemlength[itemIndex] : 0;

        const contentResourceId = getContentResourceId(resource, verIdent);

        if (contentResourceId == null) {
          reject("Cannot generate content resource id!");
          return;
        }

        const abortController = new AbortController();
        abortHandles.current = {
          ...abortHandles.current,
          [renditionId]: abortController,
        };

        const requestOptions: GetContentItemOptions = {
          onProgress: (loadedBytes: number) => {
            setLoading(
              true,
              loadedBytes / length,
              "Downloading the document data..."
            );
          },
          abortSignal: abortController.signal,
        };

        resourceContentApi
          .getResourceContentItem(contentResourceId, itemIndex, requestOptions)
          .then((response) => {
            setLoading(false, 1, "Load Complete.");

            const { data, contentType } = response;
            const blob = new Blob([data], { type: contentType });
            const contentUrl = URL.createObjectURL(blob);

            localDispatch({
              type: actions.UPDATE_RENDITION,
              payload: {
                renditionId,
                contentUrl,
              },
            });

            resolve(localState.renditions[renditionId]);
          })
          .catch((err) => {
            console.log("The request has been aborted.");
            console.error(err);
          });
      });
    },
    [localState.renditions, resource, updateLoadingState]
  );

  // const getPageThumbnail = useCallback(
  //   (documentId, itemIndex, pageNumber) => {
  //     const thumbnailId = `${documentId}:${itemIndex}:${pageNumber}`;

  //     let thumbnail = localState.thumbnails[thumbnailId];

  //     if (thumbnail == null) {
  //       const document = documentsMap[documentId];

  //       if (document != null) {
  //         const { identifier } = document;

  //         const setLoading = _.curry(
  //           updateLoadingState,
  //           2
  //         )(generateRenditionId(identifier, itemIndex));

  //         setLoading(true);
  //         return new Promise((resolve, reject) => {
  //           dispatch(
  //             fetchDocumentCommentThumbnails(
  //               identifier,
  //               (thumbnails) => {
  //                 localDispatch({
  //                   type: actions.ADD_THUMBNAILS,
  //                   documentId,
  //                   thumbnails,
  //                 });

  //                 const thumbnail = thumbnails.find(
  //                   (t) =>
  //                     t.contentItemId === itemIndex &&
  //                     t.pageNumber === pageNumber
  //                 );

  //                 resolve(thumbnail);
  //                 setLoading(false);
  //               },
  //               () => {
  //                 setLoading(false);
  //               }
  //             )
  //           );
  //         });
  //       }

  //       return Promise.reject(
  //         `There is not document selection with id ${documentId}.`
  //       );
  //     }

  //     return Promise.resolve(thumbnail);
  //   },
  //   [dispatch, documentsMap, localState.thumbnails]
  // );

  return {
    loading: useMemo(() => {
      return _(localState.loadingState).valuesIn().some("loading");
    }, [localState.loadingState]),
    loadingProgress: useMemo(() => {
      return _(localState.loadingState).valuesIn().meanBy("progress");
    }, [localState.loadingState]),
    getContentItemRendition,
    getRendition: useCallback(
      (versionIdentifier?: number): Promise<Rendition[]> => {
        if (resource == null) {
          return Promise.reject("There no resource object provided!");
        }

        const {
          contentitemcount,
          refresourcecontentitemcount,
          resourcetype,
          targeturl,
          placeholder,
        } = resource;

        let itemcount =
          resourcetype === 3 ? refresourcecontentitemcount : contentitemcount;

        if (
          (itemcount == null || itemcount === 0) &&
          (placeholder || targeturl != null)
        ) {
          itemcount = 1;
        }

        const promises = _.range(itemcount).map((itemIndex) =>
          getContentItemRendition(itemIndex, versionIdentifier).catch((err) => {
            console.log(err);
            return Promise.resolve(null);
          })
        );

        return Promise.all(promises).then((renditions) => {
          return Promise.resolve(_.compact(renditions));
        });
      },
      [getContentItemRendition, resource]
    ),
    abort: useCallback(() => abortAllHandles(), [abortAllHandles]),
  };
};

export default useResourceContent;
