enum ESignatureType {
  /**
   * eSignature feature is not enabled.
   */
  NONE = "none",
  /**
   * eSignature is enabled which allows
   * to trigger eSignature process.
   */
  BASIC = "basic",
  /**
   * eSignature is enabled and
   * only signed documents are allowed in the module.
   */
  RESTRICTED = "restricted",
}

export default ESignatureType;
