import { property, valuesetSource } from "cosmos-config/generator";
import _ from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import usePropertyRender from "./usePropertyRender";
import useValueset from "./useValueset";
import { Property } from "cosmos-config/lib/property/property";

/**
 * @module hooks/useOrganization
 * @category Hooks
 */

const PropertyName = {
  SEGMENT: "segment",
  DEPARTMENT: "department",
  TEAM: "team",
};

const properties = [
  property("Segment").selectableAttribute(
    PropertyName.SEGMENT,
    "master_segment",
    valuesetSource.CUSTOM_VALUESET
  ),
  property("Department").selectableAttribute(
    PropertyName.DEPARTMENT,
    "master_department",
    valuesetSource.CUSTOM_VALUESET
  ),
  property("Team").selectableAttribute(
    PropertyName.TEAM,
    "master_team",
    valuesetSource.CUSTOM_VALUESET
  ),
];

/**
 * @typedef {object} positionInfo
 * @property {object} code Position code but parsed into parts.
 * @property {string} segment - Label of the ogranization segment.
 * @property {string} department - Label of the ogranization department.
 * @property {string} team - Label of the ogranization team.
 */

/**
 * @callback positionInfoCallback
 * @param {string} code - Position department/team code.
 * @returns {positionInfo} Position info object.
 */

/**
 * @typedef {object} UseOrganizationHook
 * @property {positionInfoCallback} getPositionInfo - Retrieves information about the position.
 */

/**
 * Simple hook for parsing the user position information and providing
 * valueset labels for the organization parts.
 * @returns {UseOrganizationHook}
 */
const useOrganization = () => {
  const { registerProperties } = useValueset();

  const propertiesMap: Record<string, Property> = useMemo(
    () => _.keyBy(properties, "name"),
    []
  );

  useEffect(() => {
    registerProperties(propertiesMap);
  }, [registerProperties, propertiesMap]);

  const render = usePropertyRender(properties);

  return {
    getPositionInfo: useCallback(
      (code: string) => {
        const segmentCode = String(code).substring(0, 1);
        const departmentCode =
          segmentCode !== code ? String(code).split("-")[0] : null;
        const teamCode = departmentCode !== code ? code : null;

        return {
          code: {
            segment: segmentCode,
            department: departmentCode,
            team: teamCode,
          },
          segment: render(PropertyName.SEGMENT, segmentCode),
          department: render(PropertyName.DEPARTMENT, departmentCode),
          team: render(PropertyName.TEAM, teamCode),
        };
      },
      [render]
    ),
  };
};

export default useOrganization;
