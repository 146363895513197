import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useCurrentUser } from "../../../hooks";
import { LoadingOverlay } from "cosmos-components";
import useSession from "../hooks/useSession";

type UserCredentials = {
  username?: string;
  password?: string;
};

const UsernamePasswordLogin = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<UserCredentials>();
  const { loginBasic } = useSession();

  return (
    <Form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        if (credentials?.username != null && credentials?.password != null) {
          loginBasic(credentials?.username, credentials?.password);
        }
      }}
    >
      <Form.Group controlId="formBasicUsername">
        <Form.Label>User name</Form.Label>
        <Form.Control
          type="text"
          placeholder="R123456"
          name="username"
          value={credentials?.username}
          onChange={(e) =>
            setCredentials((c) => ({ ...c, username: e.target.value }))
          }
        />
        <Form.Text className="text-muted">
          As internal user please use your group account
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <InputGroup>
          <Form.Control
            aria-describedby="inputgroup-fa-icon"
            type={showPassword ? "text" : "password"}
            placeholder="Zghsie0234!%"
            name="password"
            value={credentials?.password}
            onChange={(e) =>
              setCredentials((c) => ({ ...c, password: e.target.value }))
            }
          />
          <InputGroup.Text
            style={{ cursor: "pointer" }}
            onClick={() => setShowPassword((s) => !s)}
            id="inputgroup-fa-icon"
          >
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <div className="text-right">
        <Button size="lg" type="submit">
          Submit
        </Button>
      </div>
    </Form>
  );
};

export interface LoginFormProps {
  allowBasicAuth?: boolean;
  allowExternalAuth?: boolean;
  handleLogin?: (domainName?: string) => void;
  referer?: string;
}

const LoginForm = ({
  allowBasicAuth,
  allowExternalAuth,
  handleLogin,
  referer,
}: LoginFormProps) => {
  const { loginOpenId } = useSession();
  const { loginLoading } = useCurrentUser();
  const [basicLogin, setBasicLogin] = useState<boolean>(false);

  return (
    <LoadingOverlay loading={loginLoading}>
      {basicLogin ? (
        <div>
          <UsernamePasswordLogin />
          <div className="text-center">
            <Button variant="link" onClick={() => setBasicLogin(false)}>
              Other Login Options
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Button
            block
            type="button"
            onClick={() => {
              if (handleLogin != null) {
                handleLogin("Users");
              } else {
                loginOpenId("Users", referer);
              }
            }}
          >
            RWE Login
          </Button>

          <div className="text-center">
            {allowExternalAuth && (
              <div className="mt-5">
                <Button
                  size="sm"
                  type="button"
                  variant="link"
                  onClick={() => {
                    if (handleLogin != null) {
                      handleLogin("ExternalUsers");
                    } else {
                      loginOpenId("ExternalUsers", referer);
                    }
                  }}
                >
                  Guest Login
                </Button>
              </div>
            )}

            {allowBasicAuth && (
              <div>
                <Button
                  size="sm"
                  type="button"
                  variant="link"
                  onClick={() => {
                    setBasicLogin(true);
                  }}
                >
                  Username and Password Login
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};

export default LoginForm;
