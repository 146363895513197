import { hashCode } from "../utils";
import widgetType from "./widgetType";

/**
 * @typedef
 */
type WidgetPosition = {
  x: number;
  y: number;
  w: number;
  h: number;
};

/**
 * @interface
 */
export interface Widget {
  id: string | null;
  title: string;
  type: string;
  propertyName: string | null;
  resourceType: number;
  name: string;
  chart: boolean;
  table: boolean;
  position: WidgetPosition;
  hideTitle: boolean;
  query: string | null;

  defaultSize(width: number, height: number): Widget;
  excludeTitle(exclude: boolean): Widget;
  repository(propName: string, resType: number): Widget;
  hashCode(): number;
  equals(w: Widget): boolean;
}

/**
 * Widget Generator.
 * @author Patrik Pancisin
 * @class widget
 * @classdesc Basic building block of cosmos.
 * @param {string} title - Title of the widget.
 * @param {string} type - The type of the widget.
 */
export default (
  title: string,
  type: string = widgetType.aggregate.PIE_CHART
): Widget => /** @lends widget */ ({
  id: null,
  title,
  type,
  propertyName: null,
  resourceType: 2,
  name: type,
  chart: false,
  table: false,
  position: {
    x: 0,
    y: 0,
    w: 5,
    h: 4,
  },
  hideTitle: false,
  query: null,

  /**
   * @instance
   * @description Sets the default width and height of the widget.
   * @param {number} width Width of the widget.
   * @param {number} height Height of the widget.
   */
  defaultSize(width: number, height: number): Widget {
    this.position = {
      ...this.position,
      w: width,
      h: height,
    };

    return this;
  },

  /**
   * @instance
   * @description Hides widget title in the dashboard.
   * @param {boolean} exclude
   */
  excludeTitle(exclude: boolean = true): Widget {
    this.hideTitle = exclude;
    return this;
  },

  /**
   * @instance
   * @description Sets widget as repository based chart/table.
   * @param {string} propName Property name to which widget is mapped.
   * @param {number} resType Resource type to which is widget mapped.
   */
  repository(propName: string, resType: number = 2): Widget {
    this.chart = Object.keys(widgetType.aggregate).includes(
      this.type.toString()
    );
    this.table = Object.keys(widgetType.data).includes(this.type.toString());
    this.propertyName = propName;
    this.resourceType = resType;
    this.name = `${this.type}:${this.propertyName}`;
    return this;
  },

  hashCode(): number {
    const {
      chart,
      hideTitle,
      id,
      name,
      position,
      propertyName,
      resourceType,
      table,
      title,
      type,
    } = this;

    const simplified = {
      chart,
      hideTitle,
      id,
      name,
      position,
      propertyName,
      resourceType,
      table,
      title,
      type,
    };

    const pos = this.position;

    return hashCode(
      JSON.stringify({
        ...simplified,
        position: `${pos.x}:${pos.y}:${pos.w}:${pos.h}`,
      })
    );
  },

  equals(w: Widget): boolean {
    return this.hashCode() === w.hashCode();
  },
});
