import { createContext } from "react";
import Resource from "../types/resource";

export type DuplicateAnalyzeResult = {
  checksum: string;
  fileName: string;
  fileIdentifier: string;
  identifier?: number;
  resourceName?: string;
  level: "info" | "warn" | "error";
};

export type FileDownloadResults = {
  contentUrl: string;
  fileName: string;
};

export interface RepositoryFolderContextValue {
  resourceId: string | null;
  createDocument: (
    resource?: Partial<Resource>,
    files?: File[],
    onProgress?: (loadedBytes: number) => void
  ) => Promise<string>;
  loading: boolean;
  checkDuplicates: (files: File[]) => Promise<DuplicateAnalyzeResult[]>;
  deleteDocuments: (
    resourceIds: string[],
    revert?: boolean
  ) => Promise<string[]>;
  downloadDocuments: (resourceIds: string[]) => Promise<FileDownloadResults>;
}

const RepositoryFolderContext = createContext<RepositoryFolderContextValue>({
  resourceId: null,
  createDocument: () => {
    throw new Error(
      "RepositoryFolderContext is not available! Please use RepositoryFolder component in your code wrapping features related to repository folder."
    );
  },
  loading: false,
  checkDuplicates: () => {
    throw new Error(
      "RepositoryFolderContext is not available! Please use RepositoryFolder component in your code wrapping features related to repository folder."
    );
  },
  deleteDocuments: () => Promise.reject("test"),
  downloadDocuments: () => Promise.reject("test"),
});

export default RepositoryFolderContext;
