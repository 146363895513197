import { Group } from "cosmos-config/lib/group/group";
import { createContext } from "react";
import { Project as ProjectSpec } from "cosmos-config/lib/project/project";
import { ProjectPortal } from "../contants/projectPortal";

export interface ProjectGroup extends ProjectMember {
  protected?: boolean;
}

export interface ProjectMember {
  name: string;
  domainName: string;
  principalId: string;
  commonname: string;
  email: string;
  active: boolean;
  displayName: string;
  description: string;
  groupName: string;
  group: boolean;
  office: string;
  firstName: string;
  lastName: string;
  formattedName: string;
  departmentTeam: string;
  creatorPrincipalId: string;
}

export interface ProjectGroupNew {
  name: string;
  domain: string;
  administrative: boolean;
  userGroup: boolean;
}

export interface Project {
  resourceId: string;
  code: string;
  name: string;
  description: string;
  classname: string;
  groupNames: string[];
  docarea: string;
  createdBy: string;
  createdAt: number;
  accessPermission: boolean;
  administrator: true;
  available: boolean;
  spoc: boolean;
  spocDepartments: any[];
  groups: ProjectGroup[];
  projectGroups: ProjectGroupNew[];
}

interface ProjectContextValue {
  projects: Project[];
  openProject: (projectCode: string) => void;
  members: Record<string, ProjectMember[]>;
  addProjectMember: (groupName: string, domainName: string) => Promise<string>;
  importProjectMembers: (
    groupName: string,
    domainName: string
  ) => Promise<void>;
  project: Project | null;
  requestProjectAccess: (projectCode: string) => void;
  editProjectGroup: (groupName: string, domainName: string) => Promise<string>;
  createProjectGroup: (
    parentGroupName?: string,
    domainName?: string
  ) => Promise<string>;
  spocs: Array<any>;
  reload: () => void;
  reloadSpec: () => void;
  reloadBusinessProcess: (
    processDefinitionKey: string
  ) => Promise<Record<string, Group[]>>;
  loadTemplates: () => Promise<Group[]>;
  workflows: Array<any>;
  businessProcesses: Array<any>;
  configuration: ProjectSpec | null;
  projectName: string;
  loading: boolean;
  portals: ProjectPortal[];
}

export default createContext<ProjectContextValue>({
  projects: [],
  openProject: () => {
    throw new Error("Context not implemented!");
  },
  members: {},
  addProjectMember: () => {
    throw new Error("Context not implemented!");
  },
  importProjectMembers: () => {
    throw new Error("Context not implemented!");
  },
  project: null,
  spocs: [],
  requestProjectAccess: () => {
    throw new Error("Context not implemented!");
  },
  editProjectGroup: () => {
    throw new Error("Context not implemented!");
  },
  createProjectGroup: () => {
    throw new Error("Context not implemented!");
  },
  reload: () => {
    throw new Error("Context not implemented!");
  },
  reloadSpec: () => {
    throw new Error("Context not implemented!");
  },
  reloadBusinessProcess: () => {
    throw new Error("Context not implemented!");
  },
  loadTemplates: () => {
    throw new Error("Context not implemented!");
  },
  workflows: [],
  businessProcesses: [],
  configuration: null,
  projectName: "",
  loading: false,
  portals: [],
});
