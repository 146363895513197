import { Group } from "cosmos-config/lib/group/group";
import Api from "./api";
import { Folder } from "cosmos-config/lib/group/folder";
import { Property } from "cosmos-config/lib/property/property";
import { Project } from "cosmos-config/lib/project/project";

const api = Api.build();

const deleteProperty = (projectCode: string, propertyName: string) => {
  return api
    .delete(`/project/${projectCode}/properties/${propertyName}`)
    .then((response) => Promise.resolve(response.data));
};

const createGroup = (projectCode: string, group: Group) => {
  return api
    .post(`/project/${projectCode}/group`, group)
    .then((response) => Promise.resolve(response.data));
};

const createFolder = (projectCode: string, folder: Folder) => {
  return api
    .post(`/project/${projectCode}/folder`, folder)
    .then((response) => Promise.resolve(response.data));
};

const createProperty = (
  projectCode: string,
  groupName: string,
  property: Partial<Property>,
  createNew = false
) => {
  let url = `/project/${projectCode}/properties/${groupName}`;

  if (createNew) {
    url += "/new";
  }

  return api
    .post(url, property)
    .then((response) => Promise.resolve(response.data));
};

const updateProperty = (
  projectCode: string,
  propertyName: string,
  property: Partial<Property>
) => {
  return api
    .put(`/project/${projectCode}/properties/${propertyName}`, property)
    .then((response) => Promise.resolve(response.data));
};

const moveProperty = (
  projectCode: string,
  propertyName: string,
  after: string
) => {
  let url = `/project/${projectCode}/properties/${propertyName}/move-after`;

  if (after != null) {
    url += `/${after}`;
  }

  return api.put(url).then((response) => Promise.resolve(response.data));
};

const groupMoveProperty = (
  projectCode: string,
  propertyName: string,
  groupName: string,
  after: string
) => {
  let url = `/project/${projectCode}/properties/${propertyName}/move/${groupName}`;

  if (after != null) {
    url += `/${after}`;
  }

  return api.put(url).then((response) => Promise.resolve(response.data));
};

const updateFolder = (
  projectCode: string,
  folderType: string,
  folder: Folder
) => {
  return api
    .put(`/project/${projectCode}/folder/${folderType}`, folder)
    .then((response) => Promise.resolve(response.data));
};

const updateProjectSpec = (projectCode: string, project: Project) => {
  return api
    .put(`/project/${projectCode}/spec`, project)
    .then((response) => Promise.resolve(response.data));
};

const deleteBusinessProcessProperty = (
  projectCode: string,
  processDefinitionKey: string,
  propertyName: string
) => {
  return api
    .delete(
      `/project/${projectCode}/busness-process/${processDefinitionKey}/properties/${propertyName}`
    )
    .then((response) => Promise.resolve(response.data));
};

const updateBusinessProcessProperty = (
  projectCode: string,
  processDefinitionKey: string,
  propertyName: string,
  property: Property
) => {
  return api
    .put(
      `/project/${projectCode}/business-process/${processDefinitionKey}/properties/${propertyName}`,
      property
    )
    .then((response) => Promise.resolve(response.data));
};

const moveBusinessProcessProperty = (
  projectCode: string,
  processDefinitionKey: string,
  propertyName: string,
  after: string
) => {
  let url = `/project/${projectCode}/business-process/${processDefinitionKey}/properties/${propertyName}/move-after`;

  if (after != null) {
    url += `/${after}`;
  }

  return api.put(url).then((response) => Promise.resolve(response.data));
};

const projectApi = {
  deleteProperty,
  createGroup,
  createFolder,
  createProperty,
  updateProperty,
  moveProperty,
  groupMoveProperty,
  updateFolder,
  updateProjectSpec,

  deleteBusinessProcessProperty,
  updateBusinessProcessProperty,
  moveBusinessProcessProperty,
};

export default projectApi;
