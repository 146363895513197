import { useEffect, createElement as h } from 'react';
import useQueryParams from '../../Hooks/useQueryParams.js';
import SplashScreen from '../Routes/SplashScreen';
import { useNavigate } from 'react-router';
import { SEARCH_ROUTE } from '../../Constants/routes.js';

const OauthRedirect = () => {
  const query = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    const clientRequestId = query.get('client-request-id');
    const idToken = query.get('id_token');
    const accessToken = query.get('access_token');
    const code = query.get('code');
    const state = JSON.parse(query.get('state'));

    if (state?.redirect && idToken != null) {
      localStorage.setItem('authToken', `Bearer ${idToken}`);
      localStorage.setItem('domain', 'Users');

      const referer =
        state?.referer != null
          ? decodeURIComponent(state.referer)
          : SEARCH_ROUTE;

      navigate(referer, { replace: true });
    }

    if (idToken != null || accessToken != null || code != null) {
      if (window.opener != null && !window.opener.closed) {
        window.opener.postMessage(
          {
            clientRequestId,
            accessToken: idToken || accessToken || code,
          },
          '*'
        );
      } else if (window.parent != null) {
        window.parent.postMessage(
          {
            clientRequestId,
            accessToken: idToken || accessToken || code,
          },
          '*'
        );
      }
    }
  }, [query]);

  return h(SplashScreen);
};

export default OauthRedirect;
