import { Property } from "cosmos-config/lib/property/property";
import _ from "lodash";
import { useMemo } from "react";
import { RepositorySearchOptions } from "../apis/repositorySearchApi";
import useNqlQuery from "./useNqlQuery";
import { generateObjectMap, generateSearchHash } from "../utils/searchUtils";
import { generateStringHash } from "../utils/cryptoUtils";

interface useSearchOptionsProps {
  properties: Property[];
  resourceId?: string;
  filter?: Record<string, any>;
  searchQuery?: string;
  paginator?: {
    number: number;
    size: number;
  };
  orderBy?: any;
  subtree?: boolean;
  substructure?: boolean;
  fulltextSearch?: boolean;
  signatureStatus?: "COMPLETE" | "IN_PROGRESS" | "ALL";
}

const useSearchOptions = ({
  properties,
  resourceId,
  filter,
  searchQuery,
  paginator,
  orderBy,
  subtree,
  substructure,
  fulltextSearch,
  signatureStatus,
}: useSearchOptionsProps) => {
  const propertiesMap = useMemo(
    () => _.keyBy(properties, "name"),
    [properties]
  );

  const { operation } = useNqlQuery({
    filter,
    searchQuery,
    fulltextSearch,
    substructure,
    signatureStatus,
    properties,
  });

  const searchOptions: RepositorySearchOptions = useMemo(
    () => ({
      searchQuery: fulltextSearch ? searchQuery : undefined,
      paginator: {
        number: paginator?.number || 0,
        size: paginator?.size || 10,
      },
      orderBy,
      subtree: !!subtree,
      operation,
    }),
    [
      fulltextSearch,
      operation,
      orderBy,
      paginator?.number,
      paginator?.size,
      searchQuery,
      subtree,
    ]
  );

  const searchHash = useMemo(
    () => generateSearchHash(resourceId || "", searchOptions),
    [resourceId, searchOptions]
  );

  const propertiesHash = useMemo(() => {
    return `select{${generateStringHash(
      generateObjectMap(_(propertiesMap).keysIn().sort().value())
    )}}`;
  }, [propertiesMap]);

  return {
    searchOptions,
    searchHash,
    propertiesHash,
  };
};

export default useSearchOptions;
