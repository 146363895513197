import React from "react";
import DocumentsTable from "./DocumentsTable";
import { CosmosWidget, CosmosWidgetProps } from "../../types/CosmosWidget";
import useCurrentUserSettings from "../../../../hooks/useCurrentUserSettings";
import { LoadingOverlay } from "cosmos-components";

const FavouriteDocuments = ({ onSelect }: CosmosWidgetProps): CosmosWidget => {
  const { favouriteResources, loadingFavouriteResources } =
    useCurrentUserSettings();

  return (
    <LoadingOverlay loading={loadingFavouriteResources}>
      <DocumentsTable onSelect={onSelect} documents={favouriteResources} />
    </LoadingOverlay>
  );
};

export default FavouriteDocuments;
