import { useCallback } from "react";
import {
  fetchPrincipalsByIds,
  searchPrincipals,
} from "../actions/domainActions";
import useCoreDispatch from "./useCoreDispatch";
import { principalType as PrincipalType } from "cosmos-config/generator";
import { PrincipalEntity } from "../apis/domainApi";

/**
 * @module hooks/useDomain
 * @category Hooks
 */

/**
 * @typedef {object} UseDomainHook
 * @property {function} fetchPrincipalsByIds - Fetch all principals by ids and type.
 * @property {function} searchPrincipals - Search principal objects by a query.
 */

/**
 * Basic search functions for a user domain.
 * @param {string[]} domainNames - Name of the domains.
 * @returns {UseDomainHook}
 */
const useDomain = (domainNames: string[]) => {
  const dispatch = useCoreDispatch();

  return {
    fetchPrincipalsByIds: useCallback(
      (
        principalIds: string[],
        principalType = PrincipalType.POSITION,
        callback = (principals: PrincipalEntity[]) => {}
      ): Promise<PrincipalEntity[]> => {
        return dispatch(
          fetchPrincipalsByIds({
            principalIds,
            principalType,
            domainNames,
          })
          // @ts-ignore
        ).then(callback);
      },
      [dispatch, domainNames]
    ),
    searchPrincipals: useCallback(
      (
        searchQuery: string,
        principalType = PrincipalType.POSITION,
        callback = (principals: PrincipalEntity[]) => {}
      ): Promise<PrincipalEntity[]> => {
        return dispatch(
          searchPrincipals({
            searchQuery,
            principalType,
            domainNames,
          })
          // @ts-ignore
        ).then(callback);
      },
      [dispatch, domainNames]
    ),
  };
};

export default useDomain;
