import { createSelector } from 'reselect';
import { getValueQueryRepr, select, where } from 'cosmos-config/nql';
import { propertyType } from 'cosmos-config/generator';
import { projectService } from 'cosmos-core';
import { queryResultsToProperties } from '../Utils/reportingUtils';
import _ from 'lodash';

const getConditions = (state) => state.reporting.conditions;
const getGroups = (state) => state.reporting.groups;
const getResults = (state) => state.reporting.results;
const getDocuments = (state) => state.reporting.documents;

export const getReportingFilter = createSelector(
  getConditions,
  (conditions) => {
    return conditions.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.name]: cur.value,
      }),
      {}
    );
  }
);

export const getReportingColumnProperties = createSelector(
  [getResults],
  (results) => {
    const properties = projectService.getProperties();
    return queryResultsToProperties(results, properties);
  }
);

export const isReportingAggregated = createSelector(getResults, (results) =>
  results.some((r) => r.type === 'FUNCTION')
);

export const getReportingQuery = createSelector(
  [getGroups, getResults],
  (groups, results) => {
    const properties = projectService.getProperties();
    const propertiesMapObject = properties.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: cur }),
      {}
    );

    const attributes = results
      .sort((a, b) => String(a.axis).localeCompare(b.axis))
      .map((r) => {
        const withAlias = (q) =>
          r.alias != null && r.alias !== '' ? `${r.alias}=${q}` : q;
        const property = propertiesMapObject[r.name];

        if (r.type === 'FIELD') {
          return withAlias(property != null ? property.query : r.name);
        }

        return withAlias(`${r.name}(${r.parameter})`);
      });

    const conditionsToWhereArray = (conditions) =>
      conditions
        .map((c) => {
          const prop = propertiesMapObject[c.name];

          if (prop != null) {
            return {
              name: c.name,
              multi: prop.multiple,
              value: () => {
                if (c.value == null || c.value === '') {
                  return ` ${c.operator}`;
                }

                return `${c.operator}${getValueQueryRepr(
                  c.value,
                  prop.type === propertyType.DATE
                )}`;
              },
            };
          }

          return null;
        })
        .filter((c) => c != null);

    if (_.flatMap(groups, 'conditions').length === 0) {
      return select(attributes).where({ resourcetype: 2 }).subtree().toString();
    }

    const conditionsWhere = where();
    for (let i = 0; i < groups.length; i += 1) {
      conditionsWhere.where(conditionsToWhereArray(groups[i].conditions), '|');
    }

    return select(attributes)
      .where({ resourcetype: 2 })
      .where(conditionsWhere.toString())
      .subtree()
      .toString();
  }
);

export const getReportingChartData = createSelector(
  [getResults, getDocuments],
  (results, documents) => {
    const axisMapping = results.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.axis]: cur.type === 'FIELD' ? cur.name : cur.parameter,
      }),
      {}
    );

    return documents.map((d) => {
      return {
        id: d[axisMapping.x],
        value: d[axisMapping.y],
        label: d[axisMapping.x],
      };
    });
  }
);
