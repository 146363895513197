/* eslint-disable react/jsx-curly-newline */
import React, { useRef } from "react";
import {
  InputValueHandler,
  PancoInputElementProps,
} from "../../types/PancoInputElemenProps";
import FormControl, {
  FormControlContent,
  FormControlFilter,
} from "../FormControl";

export interface NumbericInputProps
  extends PancoInputElementProps,
    InputValueHandler<number> {
  min?: number;
  max?: number;
  decimalPlaces?: number;
}

const NumericInput = ({
  name,
  min,
  max,
  value,
  decimalPlaces,
  onChange,
}: NumbericInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const countDecimals = (v: number) => {
    if (v % 1 !== 0) return v.toString().split(".")[1]?.length || 0;
    return 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange != null) {
      if (String(e.target.value).length === 0) {
        onChange({ name, value: null });
      } else {
        const v = parseFloat(e.target.value);

        if (
          (min != null && v < min) ||
          (max != null && v > max) ||
          countDecimals(v) > (decimalPlaces || 0)
        ) {
          onChange({ name, value: parseFloat(String(value)) });
        } else {
          onChange({ name, value: v });
        }
      }
    }
  };

  return (
    <FormControl>
      <FormControlContent
        role="button"
        tabIndex={0}
        onKeyDown={(e) =>
          (e.keyCode === 69 ||
            (decimalPlaces === 0 && e.keyCode === 190) ||
            (min != null && min >= 0 && e.keyCode === 189) ||
            e.keyCode === 187) &&
          e.preventDefault()
        }
        onClick={() => {
          if (inputRef.current != null) {
            inputRef.current.focus();
          }
        }}
      >
        <FormControlFilter
          ref={inputRef}
          type="number"
          min={min}
          max={max}
          name={name}
          value={value == null ? undefined : value}
          className="has-focus"
          onChange={handleChange}
        />
      </FormControlContent>
    </FormControl>
  );
};

export default NumericInput;
