import { useContext } from "react";
import ProjectContext from "../contexts/ProjectContext";
import { getMemberGroups } from "../selectors/projectSelector";
import useCoreSelector from "./useCoreSelector";
import { CosmosCoreRootState } from "../store";

/**
 * @module hooks/useProject
 * @category Hooks
 */

/**
 * @typedef {object} UseProjectHook
 * @property {Project[]} projects - List of all projects.
 * @property {Project} project - Currently opened project.
 * @property {string} projectName - Name of the currently opened project.
 * @property {function} openProject - Function to open project by project code.
 * @property {User[]} members - List of project members.
 * @property {function} addProjectMember - Function to add user as a project memeber.
 * @property {Spoc[]} spocs - List of all project spocs.
 * @property {function} requestProjectAccess - Function to request projects access.
 * @property {boolean} loading - Whether project is being loaded.
 * @property {boolean} legacyLayout - Whether legacy layout is enabled for the project.
 * @property {string[]} enabledFolderTabs - List of all enabled folder tabs.
 * @property {boolean} workflowScope - Whether scope of the project is workflow - not repository.
 * @property {boolean} enableExplorer - Whether explorer feature is enabled for the project.
 * @property {function} reload - Automatically reload project properties.
 * @property {function} loadTemplates - Load project available property templates.
 * @property {boolean} eSignature - Whether eSignature feature is enabled for the project.
 * @property {Workflow[]} workflows - List of available nscale workflows for the project.
 * @property {BusinessProcess[]} businessProcesses - List of available business processes for the project.
 * @property {Group[]} memberGroups - List of all groups in the hierarchical structure flattened.
 */

/**
 * Hook for project control.
 * @returns {UseProjectHook}
 */
const useProject = () => {
  const memberGroups = useCoreSelector(getMemberGroups);
  const context = useContext(ProjectContext);

  return {
    ...context,
    memberGroups,
  };
};

export default useProject;
