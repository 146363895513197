import { group, property, valuesetSource } from 'cosmos-config/generator';

export default [
  group('Basic', 'basic').children([
    property('Decision')
      .selectableAttribute(
        'Decision',
        'passo_decision_wf_status',
        valuesetSource.STATIC_VALUESET
      )
      .valueset([
        {
          label: 'Sign Off',
          value: 'signoff',
        },
        {
          label: 'Awaiting Response',
          value: null,
        },
        { label: 'No Sign-Off', value: 'nosignoff' },
        { label: 'Not correct Authority', value: 'authoritychange' },
        { label: 'Delegated', value: 'delegate' },
      ])
      .informational()
      .queryProperty(null),
    property('Responsible', 'responsible').informational().queryProperty(null),
    property('Sign-Off Date')
      .dateAttribute('Task_Enddate')
      .informational()
      .queryProperty(null),
    property('Comments', 'Remarks').informational().queryProperty(null),
  ]),
];
