import GatewayApi from './conf/GatewayApi';

const gatewayApi = GatewayApi.build();

const createSystemMessage = (message) => {
  return gatewayApi
    .post(`/message/system`, message)
    .then((response) => Promise.resolve(response.data));
};

const updateSystemMessage = (messageId, message) => {
  return gatewayApi
    .put(`/message/system/${messageId}`, message)
    .then((response) => Promise.resolve(response.data));
};

const deleteSystemMessage = (messageId) => {
  return gatewayApi
    .delete(`/message/system/${messageId}`)
    .then((response) => Promise.resolve(response.data));
};

const getSystemMessages = () => {
  return gatewayApi
    .get('/message/system')
    .then((response) => Promise.resolve(response.data));
};

export default {
  createSystemMessage,
  updateSystemMessage,
  deleteSystemMessage,
  getSystemMessages,
};
