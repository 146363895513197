import CryptoJS from "crypto-js";

export const generateStringHash = (str: string) => {
  try {
    return CryptoJS.MD5(CryptoJS.enc.Latin1.parse(str)).toString(
      CryptoJS.enc.Hex
    );
  } catch (err) {
    console.error(err);
  }
};

const readSlice = (
  file: File,
  start: number,
  size: number
): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const slice = file.slice(start, start + size);

    fileReader.onload = () => {
      const res = fileReader.result;
      resolve(new Uint8Array(res as ArrayBuffer));
    };
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(slice);
  });
};

export const progressiveChecksum = async (file: File) => {
  let md5 = CryptoJS.algo.MD5.create();
  const sliceSize = 10_485_760; // 10 MiB
  let start = 0;

  while (start < file.size) {
    const slice = await readSlice(file, start, sliceSize);
    const wordArray = CryptoJS.lib.WordArray.create(slice);
    md5 = md5.update(wordArray);
    start += sliceSize;
  }

  return md5.finalize().toString(CryptoJS.enc.Hex);
};
