import { transparentize } from "polished";
import React from "react";
import styled, { css } from "styled-components";
import { shadeColor } from "cosmos-components";

export interface TreeItemProps {
  active?: boolean;
  selected?: boolean;
}

const selectedStyle = css`
  font-weight: bold;
  background-color: ${(props) => transparentize(0.9, props.theme.base)};
`;

const TreeItemLi = styled.li<TreeItemProps>`
  color: ${(props) => (props.active ? props.theme.primary : props.theme.base)};

  ${(props) => props.selected && selectedStyle}
`;

export const FolderTreeItem = styled(TreeItemLi)<TreeItemProps>``;

export interface DocumentTreeItemProps extends TreeItemProps {
  cut?: boolean;
  highlighted?: boolean;
}

export const DocumentTreeItem = styled(TreeItemLi)<DocumentTreeItemProps>`
  opacity: ${(props) => (props.cut ? 0.5 : 1)};
  background-color: ${(props) =>
    props.highlighted
      ? shadeColor(0.15, props.theme.background)
      : props.theme.background};
`;

export const DocumentTreeItemCell = styled.span`
  & ~ & {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
  }
`;

export const FolderTreeHeader = styled.div`
  background-color: ${(props) => props.theme.background};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: bold;
  padding: 4px 10px;
  color: ${(props) => props.theme.base};
  border-bottom: 2px solid ${(props) => props.theme.base};

  span:first-child {
    flex-grow: 1;
  }

  & ~ div {
    overflow: hidden;
  }
`;

const FolderTree = styled.div`
  color: ${(props) => props.theme.base};
  display: flex;
  flex-direction: column;
  overflow: auto;

  & > ul {
    padding: 0 !important;
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    margin-bottom: 0;
  }

  ${FolderTreeItem},
  ${DocumentTreeItem} {
    cursor: pointer;
    user-select: none;

    &:hover {
      & > div:first-child {
        background-color: ${(props) => transparentize(0.9, props.theme.base)};
      }
    }

    & > div:first-child {
      padding: 6px 10px;
      display: flex;
      align-items: center;
    }
  }

  ${DocumentTreeItem} {
    ${DocumentTreeItemCell} {
      overflow: hidden;
      display: inline-block;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
`;

export interface FolderTreeViewProps {
  children?: React.ReactNode;
  className?: string;
}

export const FolderTreeView = ({
  children,
  className,
}: FolderTreeViewProps) => {
  return (
    <FolderTree className={className}>
      <ul>{children}</ul>
    </FolderTree>
  );
};

export default FolderTreeView;
