import { createContext } from "react";
import { Permission } from "../constants/Permission";

export type ImmediatePermission = Omit<
  Permission,
  "id" | "resourceId" | "permittedusercommonname"
>;

export interface AccessManagementContextValue {
  loading: boolean;
  resourcePermissions: Permission[];
  updatePermission: (
    permissionId: string,
    permission: Permission
  ) => Promise<any>;
  grantPermission: (principalIds: string[]) => Promise<any>;
  revokePermission: (permissionId: string[]) => Promise<any>;
  exportPermissions: () => Promise<any>;
  togglePermissionActive: (
    permissionIds: string[],
    active: boolean
  ) => Promise<any>;
  importPermissionsFiles: (files: File[]) => Promise<any>;
  importPermissions: (permissions: ImmediatePermission[]) => Promise<any>;
  permittedPrincipalIds: string[];
}

const AccessManagementContext = createContext<AccessManagementContextValue>({
  loading: false,
  resourcePermissions: [],
  updatePermission: () => Promise.reject("Not Implemented!"),
  grantPermission: () => Promise.reject("Not Implemented!"),
  revokePermission: () => Promise.reject("Not Implemented!"),
  togglePermissionActive: () => Promise.reject("Not Implemented!"),

  exportPermissions: () => Promise.reject("Not Implemented!"),
  importPermissionsFiles: () => Promise.reject("Not Implemented!"),
  importPermissions: () => Promise.reject("Not Implemented!"),
  permittedPrincipalIds: [],
});

export default AccessManagementContext;
