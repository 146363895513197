import { Group } from "cosmos-config/lib/group/group";
import { Property } from "cosmos-config/lib/property/property";
import { createContext } from "react";

export interface ProjectEditorContextValue {
  templates: (Property | Group)[];
}

const ProjectEditorContext = createContext<ProjectEditorContextValue>({
  templates: [],
});

export default ProjectEditorContext;
