import { LoadingOverlay } from "cosmos-components";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import useProject from "../../../hooks/useProject";
import projectProperties from "../constants/project";
import ProjectEditorContext from "../contexts/ProjectEditorContext";
import useProjectElementEditing from "../hooks/useProjectElementEditing";
import BusinessProcesses from "./BusinessProcesses";
import SimpleGroupedUniversalForm from "../../../components/SimpleGroupedUniversalForm";
import { buildGroups, buildProperties } from "../../../utils";
import FormGroupWrapper from "./FormGroupWrapper";
import { PortalsEditor } from "./portals-editor";

const properties = buildProperties(projectProperties);

const ProjectSpec = () => {
  const { configuration } = useProject();
  const { loadingProject, updateProject } = useContext(ProjectEditorContext);

  const { formResource, updateElement, getSubmitResource } =
    useProjectElementEditing({
      resource: configuration,
      properties,
    });

  return (
    <LoadingOverlay loading={loadingProject}>
      <SimpleGroupedUniversalForm
        grouping
        groups={buildGroups(projectProperties)}
        resource={formResource}
        onUpdateResource={(id, name, value) => {
          updateElement(name, value);
        }}
        onSubmit={() => {
          updateProject(getSubmitResource());
        }}
        inline
      >
        {({ submit }) => (
          <div>
            <FormGroupWrapper title="Business Processes" className="mb-3">
              <BusinessProcesses
                businessProcesses={formResource.businessProcesses}
                onChange={(businessProcesses) => {
                  updateElement("businessProcesses", businessProcesses);
                }}
              />
            </FormGroupWrapper>

            <FormGroupWrapper title="Portals" className="mb-3">
              <PortalsEditor />
            </FormGroupWrapper>

            <div className="text-right">
              <Button onClick={submit} size="lg">
                Submit
              </Button>
            </div>
          </div>
        )}
      </SimpleGroupedUniversalForm>
    </LoadingOverlay>
  );
};

export default ProjectSpec;
