import { createContext } from "react";
import { ProjectMember } from "../../../contexts/ProjectContext";

export interface UserManagementContextValue {
  searchQuery: string | null;
  search: (query: string) => void;
  matchingMembers: Record<string, ProjectMember[]>;
  getSubGroups: (groupName: string) => ProjectMember[];
  getUsers: (groupName: string) => ProjectMember[];
}

const throwNotImplementedException = () => {
  throw new Error("User management context not present!");
};

const UserManagementContext = createContext<UserManagementContextValue>({
  searchQuery: null,
  search: throwNotImplementedException,
  matchingMembers: {},
  getSubGroups: throwNotImplementedException,
  getUsers: throwNotImplementedException,
});

export default UserManagementContext;
