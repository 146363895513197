import React from "react";
import classNames from "classnames";
import { HelperTooltip } from "cosmos-components";
import { Col, FormGroup, FormLabel, Row } from "react-bootstrap";

export interface SimpleFormGroupProps {
  label?: string;
  description?: string;
  inline?: boolean;
  children?: React.ReactNode;
}

const SimpleFormGroup = ({
  label,
  description,
  inline,
  children,
}: SimpleFormGroupProps) => {
  if (inline) {
    return (
      <FormGroup as={Row} className={classNames("form-property")}>
        <FormLabel column sm={{ span: 4, offset: 0 }}>
          {label}
        </FormLabel>
        <Col sm={6} xs={10}>
          {children}
        </Col>
        <Col xs={2} className="col-form-label">
          {description != null && (
            <HelperTooltip title={label} text={description} />
          )}
        </Col>
      </FormGroup>
    );
  }

  return (
    <FormGroup className={classNames("form-property")}>
      <Row>
        <Col xs={8}>
          <FormLabel>{label}</FormLabel>
        </Col>
        <Col xs={4}>
          {description != null && (
            <HelperTooltip title={label} text={description} />
          )}
        </Col>
      </Row>
      {children}
    </FormGroup>
  );
};

export default SimpleFormGroup;
