import { utils, read } from "xlsx";
import MsgReader from "@kenjiuno/msgreader";
import mfe from "mime-file-extension";
import PostalMime from "postal-mime";
import _ from "lodash";
import { hashCode } from "cosmos-config/utils";
import { ExtendedFile } from "../components/upload/FilesList";

export function parseExcelFile(file: File) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target) {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = read(data, { type: "array" });

        const sheet = Object.values(workbook.Sheets)[0];
        const res = utils.sheet_to_json(sheet);

        resolve(res);
      } else {
        reject();
      }
    };

    reader.readAsArrayBuffer(file);
  });
}

export const resolveContentType = (name: string, type?: string) => {
  if (type != null && type !== "") {
    return type.toLowerCase();
  }

  try {
    return mfe.getMimeType(name);
  } catch (err: any) {
    console.warn(err.message);
  }

  if (String(name).endsWith(".dwg")) {
    return "application/acad";
  }

  return "";
};

const emailToFile = (emailFile: File) => {
  return new File([emailFile], emailFile.name, {
    type: emailFile.type,
  });
};

async function* parseMsgAttachments(file: File) {
  const parsedMessage = new MsgReader(await file.arrayBuffer());
  const messageInfo = parsedMessage.getFileData();

  yield emailToFile(file);

  if (!messageInfo.error && messageInfo.attachments != null) {
    for (const att of messageInfo.attachments) {
      if (
        !att.attachmentHidden &&
        att.fileName != null &&
        att.extension != null
      ) {
        const attachment = parsedMessage.getAttachment(att);
        const attachmentFile = new File([attachment.content], att.fileName, {
          type: resolveContentType(att.extension),
        });

        yield attachmentFile;
      }
    }
  }
}

async function* parseEmlAttachments(file: File) {
  const parser = new PostalMime();
  const email = await parser.parse(file);

  yield emailToFile(file);

  for (const att of email.attachments) {
    if (att.disposition === "attachment") {
      const attachmentFile = new File([att.content], att.filename ?? "", {
        type: att.mimeType,
      });
      yield attachmentFile;
    }
  }
}

export const flatMapEmails = async (files: File[]) => {
  let results: File[] = [];
  let relationMap: Record<string, string> = {};

  const includeFile = (file: File, parentFileName?: string | null) => {
    results = [...results, file];

    if (parentFileName != null) {
      relationMap = {
        ...relationMap,
        [file.name]: parentFileName,
      };
    }
  };

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    if (file != null) {
      if ("application/vnd.ms-outlook" === file.type) {
        for await (let parsedFile of parseMsgAttachments(file)) {
          includeFile(parsedFile, file.name);
        }
      } else if ("message/rfc822" === file.type) {
        for await (let parsedFile of parseEmlAttachments(file)) {
          includeFile(parsedFile, file.name);
        }
      } else {
        includeFile(file);
      }
    }
  }

  return {
    files: results,
    relationMap,
  };
};

export const trimFileExtension = (fileName: string) =>
  _(fileName).split(".").dropRight().join(".");

export const getFileSize = (size: number | string) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  const sizeNumber = typeof size === "string" ? parseInt(size, 10) : size;

  const parsedSize = Math.floor(Math.log(sizeNumber) / Math.log(1024));
  const prettySize = (sizeNumber / 1024 ** parsedSize).toFixed(2);

  return {
    size: +prettySize,
    prettySize: `${prettySize} ${sizes[parsedSize]}`,
  };
};

export const getFileIdentifier = (file: ExtendedFile) => {
  //@ts-ignore
  if (!file.webkitRelativePath) {
    // @ts-ignore
    const pathAndName = file.path?.endsWith(file.name)
      ? file.path
      : `${file.path || ""}/${file.name}`;
    return hashCode(pathAndName).toString();
  }

  return hashCode(file.webkitRelativePath).toString();
};
