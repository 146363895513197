import classNames from "classnames";
import React, {
  ChangeEvent,
  createElement,
  createElement as h,
  useCallback,
} from "react";
import {
  ActionType,
  CellProps,
  IdType,
  Meta,
  Row,
  TableInstance,
  TableRowProps,
  TableState,
} from "react-table";
import {
  MetaBase,
  TableToggleCommonProps,
  UseTableHooks,
  actions,
} from "react-table";
import Resource from "../../types/resource";
import _ from "lodash";

export type TableSelectMultipleState<D extends object> = {
  previousRowId: IdType<D> | null;
};

actions.setSelectedRows = "SET_SELECTED_ROWS";
actions.setPreviousRowId = "SET_PREVIOUS_ROW_ID";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: TableToggleCommonProps, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      //@ts-ignore
      if (resolvedRef.current != null) {
        //@ts-ignore
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return h(
      "div",
      { className: "text-center " },
      h("input", {
        type: "checkbox",
        ref: resolvedRef,
        ...rest,
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
        },
      })
    );
  }
);

function reducer<D extends object>(state: TableState<D>, action: ActionType) {
  const previousRowId =
    action.selectedRowIds != null && action.selectedRowIds.length === 0
      ? null
      : state.previousRowId;

  if (action.type === actions.setSelectedRows) {
    const selectedRowIds = action.rowIds.reduce(
      (acc: object, cur: number) => ({
        ...acc,
        [cur]: action.value != null ? action.value : !state.selectedRowIds[cur],
      }),
      {}
    );

    if (action.clear) {
      return {
        ...state,
        selectedRowIds: {
          ...selectedRowIds,
        },
        previousRowId,
      };
    }

    return {
      ...state,
      selectedRowIds: {
        ...state.selectedRowIds,
        ...selectedRowIds,
      },
      previousRowId,
    };
  }

  if (action.type === actions.setPreviousRowId) {
    return {
      ...state,
      previousRowId: action.rowId,
    };
  }

  return state;
}

const parseRowId = (rowId: string) => {
  if (rowId != null) {
    const idString = _(rowId).split(".").last();

    if (idString != null) {
      return parseInt(idString, 10);
    }
  }

  return null;
};

const extractPath = (...rowIds: (string | null)[]) => {
  const uniquePaths = _(rowIds)
    .compact()
    .map((rowId) => _.dropRight(rowId.split("."), 1).join("."))
    .uniq()
    .value();

  if (uniquePaths.length !== 1) {
    throw new Error("Path is inconsistent!");
  }

  return uniquePaths[0];
};

function useInstance<D extends object>(instance: TableInstance<D>) {
  const { dispatch, clickThrough } = instance;
  const toggleRowsSelected = useCallback(
    (rowIds: number[], value: any, clear = false) => {
      dispatch({
        type: actions.setSelectedRows,
        rowIds,
        value: clickThrough ? true : value,
        clear,
      });
    },
    [dispatch, clickThrough]
  );

  const setPreviousRowId = useCallback(
    (rowId: number) => {
      dispatch({ type: actions.setPreviousRowId, rowId });
    },
    [dispatch]
  );

  Object.assign(instance, {
    toggleRowsSelected,
    setPreviousRowId,
  });
}

const getRowSelectProps = <D extends object>(
  props: Partial<TableRowProps>,
  { row }: Meta<D, { row: Row<D> }>
): Partial<TableRowProps>[] => {
  return [
    props,
    {
      className: classNames({ selected: row.isSelected }),
    },
  ];
};

export type UseTableSelectMultipleRowProps<D extends object> = {
  setPrevious: () => void;
  isPrevious: () => boolean;
  triggerSelection: (spreading?: boolean, forceSelect?: boolean) => void;
};

function prepareRow<D extends object>(row: Row<D>, { instance }: MetaBase<D>) {
  Object.assign(row, {
    setPrevious: () => instance.setPreviousRowId(row.id),
    isPrevious:
      instance.state.previousRowId != null &&
      instance.state.previousRowId === row.id,
    triggerSelection: (spreading?: boolean, forceSelect?: boolean) => {
      const rowId = parseRowId(row.id);
      const { previousRowId, selectedRowIds } = instance.state;
      const { multiSelection } = instance;

      const prevRowId =
        previousRowId != null ? parseRowId(previousRowId) : null;

      if (spreading && prevRowId != null && multiSelection && rowId != null) {
        try {
          const path = extractPath(row.id, previousRowId);

          const count = Math.abs(rowId - prevRowId);
          const start = Math.min(prevRowId, rowId);
          const rowsIds = new Array(count + 1)
            .fill(start)
            .map((v, i) => _.compact([path, v + i]).join("."));

          instance.toggleRowsSelected(rowsIds, true);
        } catch (err) {
          console.warn(err);
        }
      } else if (
        multiSelection &&
        (Object.values(selectedRowIds).filter(Boolean).length > 1 ||
          forceSelect)
      ) {
        const rowIds = _(row.subRows).map("id").concat(row.id).value();
        instance.toggleRowsSelected(rowIds);
        row.setPrevious();
      } else if (multiSelection) {
        instance.toggleRowsSelected([row.id], null, true);
        row.setPrevious();
      } else {
        instance.toggleRowsSelected([row.id], true, true);
      }
    },
  });
}

const useTableSelectMultiple = <D extends object>(hooks: UseTableHooks<D>) => {
  hooks.getRowProps.push(getRowSelectProps);
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.prepareRow.push(prepareRow);
  hooks.visibleColumns.push((columns) => [
    {
      id: "selection",
      width: 30,
      minWidth: 30,
      disableResizing: true,
      Header: ({ getToggleAllRowsSelectedProps }) =>
        createElement(IndeterminateCheckbox, getToggleAllRowsSelectedProps()),
      Cell: ({ row }: CellProps<D>) => {
        if ((row.original as Resource).virtual) {
          return null;
        }

        return createElement(
          IndeterminateCheckbox,
          row.getToggleRowSelectedProps({
            onChange: (e: ChangeEvent) => {
              const pointerEvent = e.nativeEvent as PointerEvent;
              row.triggerSelection(pointerEvent.shiftKey, true);
            },
          })
        );
      },
    },
    ...columns,
  ]);
};

useTableSelectMultiple.pluginName = "useTableSelectMultiple";

export default useTableSelectMultiple;
