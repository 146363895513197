import { propertyType } from "cosmos-config/generator";
import FormProperty from "../../../../components/form/FormProperty";
import { Property } from "cosmos-config/lib/property/property";
import React from "react";

export interface PropertyDefaultValueProps {
  property?: Property;
  defaultValue?: any;
  onChange?: (defaultValue: any) => void;
}

const PropertyDefaultValue = ({
  property,
  defaultValue,
  onChange,
}: PropertyDefaultValueProps) => {
  if (property == null) {
    return null;
  }

  return (
    <FormProperty
      controlOnly
      property={property}
      value={
        Array.isArray(defaultValue) ||
        (property?.type != null &&
          [
            propertyType.YESNO,
            propertyType.CHECKBOX,
            propertyType.DATE,
          ].includes(property.type))
          ? defaultValue
          : [defaultValue].filter((x) => x != null)
      }
      onChange={({ value }) => {
        if (onChange == null) {
          return;
        }

        if (Array.isArray(value) && !property?.multiple) {
          const [firstValue] = value;
          onChange(firstValue);
        } else {
          onChange(value);
        }
      }}
    />
  );
};

export default PropertyDefaultValue;
