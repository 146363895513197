import {
  useState,
  useRef,
  useCallback,
  createContext,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "lodash";

import Timeline from "../timeline/Timeline";
import { WizardStep } from "./WizardStep";

// For backward compatibility
export { WizardStep };

export const WizardContext = createContext({
  navigate: () => { },
  registerStep: () => { },
  unregisterStep: () => { },
  isActive: () => false,
});

const Wizard = forwardRef(
  (
    {
      children,
      onNavigate,
      onComplete,
      className,
      hideTimeline,
      contentClassName,
    },
    ref
  ) => {
    const [stepsMap, setStepsMap] = useState({});

    const [state, setState] = useState({
      currentStep: null,
      complete: false,
    });
    const timelineRef = useRef();

    const autoScrollTimeline = useCallback((amount) => {
      const value = amount < 0 ? 300 : 0;
      const timeline = timelineRef.current;
      const offsetLeft =
        timeline.getElementsByClassName("active")[0].offsetLeft - value;
      timeline.scroll(offsetLeft, 0);
    }, []);

    useEffect(() => {
      const stepKeys = _(stepsMap).valuesIn().sortBy("order").map("id").value();

      setState((st) => {
        const { currentStep, complete } = st;

        if (
          (currentStep == null || !stepKeys.includes(currentStep)) &&
          stepKeys.length > 0 &&
          !complete
        ) {
          return {
            currentStep: stepKeys[0],
            complete: false,
          };
        }

        return st;
      });
    }, [stepsMap]);

    const navigate = useCallback(
      (amount, payload) => {
        if (!hideTimeline) {
          autoScrollTimeline(amount);
        }

        const maxOrder = _(stepsMap).map("order").max();

        const getStepId = (destination, currentStepId) => {
          if (_.inRange(destination, maxOrder + 1)) {
            const orderMap = _(stepsMap).keyBy("order").mapValues("id").value();
            const id = orderMap[destination];

            if (id != null) {
              return id;
            }

            return getStepId(destination + 1, currentStepId);
          } else if (destination > maxOrder) {
            return null;
          }

          return currentStepId;
        };

        setState((st) => {
          const { currentStep, complete } = st;

          const destination = stepsMap[currentStep]?.order + amount;

          const id = getStepId(destination, currentStep);

          if (id == null) {
            onComplete(payload);
            return {
              currentStep,
              complete: true,
            };
          } else {
            onNavigate(id, destination);
          }

          return {
            currentStep: id,
            complete,
          };
        });
      },
      [autoScrollTimeline, hideTimeline, onComplete, onNavigate, stepsMap]
    );

    const restart = useCallback(() => {
      const stepKeys = _(stepsMap).valuesIn().sortBy("order").map("id").value();

      setState({
        complete: false,
        currentStep: stepKeys[0],
      });
    }, [stepsMap]);

    useImperativeHandle(ref, () => ({
      navigate,
      restart,
    }));

    return (
      <div className={classNames("wizard", className)} ref={ref}>
        {!hideTimeline && (
          <Timeline ref={timelineRef} className="pt-3">
            {_(stepsMap)
              .valuesIn()
              .sortBy("order")
              .map((step) => (
                <Timeline.Step
                  key={`wizard_step_${step.id}`}
                  active={step.id === state.currentStep}
                >
                  {step.title}
                </Timeline.Step>
              ))
              .value()}
          </Timeline>
        )}
        <div className={classNames("wizard-content", contentClassName)}>
          <WizardContext.Provider
            value={{
              navigate,
              restart,
              registerStep: useCallback((id, title, order = -1) => {
                setStepsMap((map) => {
                  const existingOrders = Object.values(map).map(step => step.order);
                  const maxOrder = existingOrders.length > 0 ? Math.max(...existingOrders) : -1;

                  const newStep = {
                    id,
                    title,
                    order: order !== -1 ? order : maxOrder + 1
                  };

                  // If a step with the same order exists, increment the order of all steps after it
                  if (order !== -1 && existingOrders.includes(order)) {
                    Object.values(map).forEach(step => {
                      if (step.order >= order) {
                        step.order += 1;
                      }
                    });
                  }

                  return {
                    ...map,
                    [id]: newStep
                  };
                });
              }, []),
              unregisterStep: useCallback((id) => {
                setStepsMap((map) => _(map).omit(id).value());
              }, []),
              isActive: useCallback(
                (id) => state.currentStep === id,
                [state.currentStep]
              ),
            }}
          >
            {children}
          </WizardContext.Provider>
        </div>
      </div>
    );
  }
);

Wizard.Step = WizardStep;

Wizard.propTypes = {
  children: PropTypes.node,
  onNavigate: PropTypes.func,
  onComplete: PropTypes.func,
  className: PropTypes.string,
  hideTimeline: PropTypes.bool,
  contentClassName: PropTypes.string,
};

Wizard.defaultProps = {
  children: null,
  onNavigate: () => {},
  onComplete: () => {},
  className: null,
  hideTimeline: false,
  contentClassName: null,
};

export default Wizard;
