import React from "react";
import { LoadingOverlay, PancoPagination } from "cosmos-components";
import RepositoryTable from "./repository-table/RepositoryTable";
import useRepositoryTable from "./useRepositoryTable";
import { RepositoryTableInjector } from "./repository-table";
import { OnOpenResourceCallback } from "../types/callbacks";
import { TableAllScreen } from "./tableFragments";

export interface SimpleRepositoryTableProps {
  resourceId: string;
  filter?: Record<string, any>;
  onOpenResource?: OnOpenResourceCallback;
  searchQuery?: string;
  subtree?: boolean;
  fulltextSearch?: boolean;
  editMode?: boolean;
}

const SimpleRepositoryTable = ({
  resourceId,
  filter,
  onOpenResource,
  searchQuery,
  subtree,
  fulltextSearch,
  editMode,
}: SimpleRepositoryTableProps) => {
  const { getTableProps, getPaginatorProps, loading } = useRepositoryTable({
    resourceId,
    filter,
    searchQuery,
    subtree,
    fulltextSearch,
    pageSize: 100,
  });

  return (
    <LoadingOverlay loading={loading}>
      <TableAllScreen>
        <RepositoryTableInjector
          onRowExpand={() => {}}
          resourceId={resourceId}
          onOpenResource={onOpenResource || (() => {})}
        >
          {/* @ts-ignore */}
          <RepositoryTable
            {...getTableProps({
              editMode,
              multiSelection: true,
              // simplify: true,
              // onOpenResource:
              //   onOpenResource != null ? onOpenResource : () => {},
              // onRowExpand: () => {},
            })}
          />
        </RepositoryTableInjector>

        <div className="d-flex justify-content-center">
          <PancoPagination {...getPaginatorProps()} />
        </div>
      </TableAllScreen>
    </LoadingOverlay>
  );
};

export default SimpleRepositoryTable;
