import {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from "cosmos-config/utils";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";

const useProjectElementEditing = ({ resource, properties }) => {
  const [updateMask, setUpdateMask] = useState({});

  const formResource = useMemo(() => {
    const editableProperties = properties
      .filter((p) => p.editable || p.systemUpdatable || p.updatable)
      .map((p) => p.name);
    const res = _.pick(resource, editableProperties);

    return {
      ...resourceCorrectionOfGateway(res, properties),
      ...updateMask,
    };
  }, [resource, updateMask, properties]);

  const visibilityUpdateMask = useMemo(() => {
    if (
      formResource.conditional ||
      (formResource.conditional === undefined && resource?.conditional)
    ) {
      return {
        conditional: true,
        _conditionalProperty:
          formResource._conditionalProperty || resource._conditionalProperty,
        _conditionalValues:
          formResource._conditionalValues || resource._conditionalValues,
        _conditionalOperator:
          formResource._conditionalOperator || resource._conditionalOperator,
      };
    }

    return {
      conditional: false,
    };
  }, [formResource, resource]);

  return {
    formResource,
    visibilityUpdateMask,
    updateElement: useCallback((name, value) => {
      setUpdateMask((m) => ({
        ...m,
        [name]: value,
      }));
    }, []),
    updateElementMask: useCallback((mask) => {
      setUpdateMask((m) => ({
        ...m,
        ...mask,
      }));
    }, []),
    getSubmitResource: useCallback(() => {
      return resourceCorrectionForGateway(updateMask, properties);
    }, [properties, updateMask]),
  };
};

export default useProjectElementEditing;
