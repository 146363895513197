import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { trimUINumber } from "cosmos-config/utils";
import {
  PancoSelectProps,
  PancoSelect,
  PancoSelectOption,
} from "cosmos-components";
import useProjectMembers from "../../hooks/useProjectMembers";
import { Button } from "react-bootstrap";
import useDebounce from "../../hooks/useDebounce";
import { useDomain } from "../../hooks";
import { PrincipalEntity } from "../../apis/domainApi";
import { ProjectMember } from "../../contexts/ProjectContext";
import useCoreSelector from "../../hooks/useCoreSelector";
import { CosmosCoreRootState } from "../../store";

const toOptions = (items: (PrincipalEntity | ProjectMember)[]) => {
  return items
    .filter((i) => i != null)
    .map((item): PancoSelectOption => {
      const label = (item as ProjectMember).group
        ? item.commonname
        : trimUINumber(item.commonname);
      return {
        label: label || "",
        value:
          (item as PrincipalEntity).principalid ||
          (item as ProjectMember).principalId,
      };
    });
};

export interface ExtendedMemberSelectProps extends PancoSelectProps {
  groupNames?: string[];
  principalType?: string | number;
  flattenGroups?: boolean;
  domainNames?: string[];
}

const ExtendedMemberSelect = ({
  groupNames,
  principalType,
  onChange,
  multiple,
  flattenGroups,
  domainNames,
  value,
  ...props
}: ExtendedMemberSelectProps) => {
  const { getHierarchicalMembers, getPrincipalIds, loading } =
    useProjectMembers();

  const [searchResults, setSearchResults] = useState<PrincipalEntity[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [enableSearch, setEnableSearch] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<PancoSelectOption[]>(
    []
  );

  const domainLoading = useCoreSelector<CosmosCoreRootState, boolean>(
    (state) => state.domain.loading
  );

  const debouncedSearchQuery = useDebounce(searchQuery, 200);

  const { searchPrincipals } = useDomain(
    useMemo(() => domainNames || ["Users"], [domainNames])
  );

  useEffect(() => {
    if (searchQuery == null || searchQuery.length <= 2) {
      setEnableSearch(false);
      setSearchResults([]);
    }
  }, [searchQuery]);

  const storeSelection = useCallback(
    (principalIds: string[]) => {
      const preservingOptions = toOptions(
        searchResults.filter(
          (p) => principalIds != null && principalIds.includes(p.principalid)
        )
      );

      setSelectedOptions((so) => {
        const previousSelection = so.filter((pid) =>
          principalIds.includes(pid.value)
        );
        return [...previousSelection, ...preservingOptions];
      });

      setSearchResults([]);
    },
    [searchResults]
  );

  const parsedPrincipalType = useMemo(() => {
    return principalType == null ? 2 : parseInt(principalType as string, 10);
  }, [principalType]);

  useEffect(() => {
    if (
      debouncedSearchQuery != null &&
      debouncedSearchQuery.length > 2 &&
      enableSearch
    ) {
      searchPrincipals(
        debouncedSearchQuery,
        parsedPrincipalType,
        setSearchResults
      );
    }
  }, [
    debouncedSearchQuery,
    parsedPrincipalType,
    searchPrincipals,
    domainNames,
    enableSearch,
  ]);

  const hierarchicalMembers = useMemo(
    () =>
      getHierarchicalMembers(groupNames, multiple ? null : parsedPrincipalType),
    [getHierarchicalMembers, groupNames, multiple, parsedPrincipalType]
  );

  const options = useMemo(
    () =>
      _(toOptions(hierarchicalMembers))
        .concat(toOptions(searchResults))
        .concat(selectedOptions)
        .uniqBy("value")
        .orderBy(["group", (u) => u.label.toLowerCase()], ["desc", "asc"])
        .value(),
    [hierarchicalMembers, searchResults, selectedOptions]
  );

  return (
    <PancoSelect
      {...props}
      value={value}
      loading={loading || domainLoading}
      options={options}
      // manualFilter
      onFilter={(value) => {
        setSearchQuery(value);
      }}
      multiple={multiple}
      onChange={({ name, value: onChangeValues }) => {
        if (onChange != null) {
          const principalIds = getPrincipalIds(onChangeValues);
          storeSelection(principalIds);
          onChange({ name, value: principalIds });
        }
      }}
    >
      {searchQuery != null && searchQuery.length > 2 && !enableSearch && (
        <div className="border-top">
          <Button
            block
            variant="link"
            className="text-left"
            onClick={() => setEnableSearch(true)}
          >
            Search for More
          </Button>
        </div>
      )}
    </PancoSelect>
  );
};

ExtendedMemberSelect.defaultProps = {
  flattenGroups: true,
};

export default ExtendedMemberSelect;
