import { useQuery } from "@tanstack/react-query";
import repositorySearchApi from "../apis/repositorySearchApi";
import { useMemo } from "react";
import _ from "lodash";
import useSearchOptions from "./useSearchOptions";
import useDebounce from "./useDebounce";
import useProject from "./useProject";
import useProperties from "./useProperties";
import { useCurrentUser } from ".";

interface useRepositorySearchDistinctProps {
  resourceId: string;
  propertyName: string;
  filter?: any;
  orderBy?: any;
  paginator?: {
    size: number;
    number: number;
  };
  searchQuery?: string;
  substructure?: boolean;
  subtree?: boolean;
  enabled?: boolean;
}

const useRepositorySearchDistinct = ({
  resourceId,
  propertyName,
  filter,
  orderBy,
  paginator,
  searchQuery,
  substructure,
  subtree,
  enabled,
}: useRepositorySearchDistinctProps) => {
  const { project } = useProject();
  const { properties } = useProperties();
  // const { projectCode, properties } = useResource({
  //   projectResourceId: '',
  // });

  const { authenticated } = useCurrentUser();

  const queryCapable = useMemo(
    () =>
      authenticated &&
      resourceId != null &&
      propertyName != null &&
      (enabled === undefined || enabled),
    [resourceId, propertyName, enabled, authenticated]
  );

  const debouncedSearchQuery = useDebounce(searchQuery, 250);

  const { searchOptions, searchHash } = useSearchOptions({
    properties,
    resourceId,
    filter,
    orderBy,
    paginator,
    searchQuery: debouncedSearchQuery,
    substructure,
    subtree,
  });

  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ["repository-search-distinct", propertyName, searchHash],
    queryFn: () =>
      repositorySearchApi.searchAggregateDistinct(
        project?.code || null,
        resourceId,
        propertyName,
        searchOptions
      ),
    enabled: queryCapable,
  });

  const parsedData = useMemo(() => {
    return _.map(data, (item) => {
      return _(item.properties)
        .keyBy("name")
        .mapValues("value")
        .mapKeys((v, key) => {
          switch (key) {
            case propertyName:
              return "value";
            case "identifier":
              return "count";
            default:
              return key;
          }
        })
        .value();
    });
  }, [data, propertyName]);

  const parsedCount = useMemo(
    () =>
      _.sum(
        _.map(data, (i) =>
          _(i.properties).keyBy("name").mapValues("value").get("identifier")
        )
      ),
    [data]
  );

  return {
    loading: isLoading,
    data: parsedData,
    error,
    count: parsedCount,
    refetch,
  };
};

export default useRepositorySearchDistinct;
