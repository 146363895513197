import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import masterdataApi from "../apis/masterdataApi";
import { useCallback, useMemo } from "react";
import documentHistoryProperties from "../contants/documentHistoryProperties";
import _ from "lodash";
import { resourceCorrectionOfGateway } from "cosmos-config/utils";

export interface UseDocumentHistoryProps<R> {
  queryKey?: string | string[];
  scope: string;
  paging?: number;
  filter?: Partial<R>;
}

declare type MutationPayload<R> = {
  entryId?: string;
  entry?: R;
  mutationType: "create" | "update" | "delete";
};

const useMasterdataScope = <R>({
  queryKey: key,
  scope,
  paging,
  filter,
}: UseDocumentHistoryProps<R>) => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(() => {
    if (Array.isArray(key)) {
      return key;
    }

    if (_.isString(key)) {
      return [key];
    }

    return ["masterdata", scope];
  }, [scope, key]);

  const { data, isLoading } = useQuery<R[]>({
    queryKey,
    queryFn: () => {
      return masterdataApi.getMasterData("RWE", scope, ["*"], {
        where: filter,
        orderBy: {},
        paging: { number: 1, size: paging || 20 },
      });
    },
    enabled: scope != null,
    refetchOnWindowFocus: false,
  });

  const parsedData = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.map((d: any) => {
      const item = _(d.properties).keyBy("name").mapValues("value").value();
      return resourceCorrectionOfGateway(item, documentHistoryProperties) as R;
    });
  }, [data]);

  const mutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (payload: MutationPayload<R>) => {
      const { mutationType, entry, entryId } = payload;

      if (mutationType === "create") {
        return masterdataApi.postMasterDataEntry("RWE", scope, payload.entry);
      }

      if (entryId == null) {
        throw new Error("Entry ID cannot be null!");
      }

      if (mutationType === "update") {
        return masterdataApi.updateMasterDataEntry(
          "RWE",
          scope,
          entryId,
          entry
        );
      } else {
        return masterdataApi.removeMasterDataEntry("RWE", scope, entryId);
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  return {
    data: parsedData,
    loading: useMemo(
      () => isLoading || mutation.isPending,
      [isLoading, mutation.isPending]
    ),
    createEntry: useCallback(
      (entry: R) => {
        if (entry == null) {
          throw new Error("Entry cannot be null!");
        }

        return mutation.mutateAsync({
          mutationType: "create",
          entry,
        });
      },
      [mutation]
    ),
    updateEntry: useCallback(
      (entryId: string, entry: R) => {
        return mutation.mutateAsync({
          mutationType: "update",
          entryId,
          entry,
        });
      },
      [mutation]
    ),
    deleteEntry: useCallback(
      (entryId: string) => {
        return mutation.mutateAsync({
          mutationType: "delete",
          entryId,
        });
      },
      [mutation]
    ),
  };
};

export default useMasterdataScope;
