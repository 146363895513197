import { createContext } from "react";
import { UserAlert } from "../constants/userAlert";
import { BusinessProcessTask } from "../constants/businessProcessTask";

export interface UserRelationContextValue {
  alerts: UserAlert[];
  loadingAlerts: boolean;
  notifications: any[];
  tasks: BusinessProcessTask[];
  loadingTasks: boolean;
  tasksCount: number;
  moduleAdmin: boolean;
}

const UserRelationContext = createContext<UserRelationContextValue>({
  alerts: [],
  loadingAlerts: false,
  notifications: [],
  tasks: [],
  loadingTasks: false,
  tasksCount: 0,
  moduleAdmin: false,
});

export default UserRelationContext;
