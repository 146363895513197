import group, { Group } from "./group";
import GroupType from "./groupType";

/**
 * Group Generator.
 * @category Group
 * @author Patrik Pancisin
 * @class widgetGroup
 * @classdesc Basic building block of cosmos.
 * @extends group
 * @param {string} label - The label of the group displayed in cosmos.
 * @param {string} name - The name of the group.
 */
const widgetGroup = (label: string, name: string): Group => ({
  ...group(label, name, "widget"),
  type: GroupType.WIDGET,
});

export default widgetGroup;
