import React from "react";
import { shadeColor } from "cosmos-components";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const styledCssLine = (startPoint?: boolean) => css`
  &:before {
    content: "";
    border-left: 1px solid ${(props) => shadeColor(0.3, props.theme.background)};
    position: absolute;

    top: -10px;
    bottom: -10px;

    ${startPoint &&
    css`
      left: 3px;
      top: 60%;
    `}
  }
`;

const LevelIndicator = styled.div`
  margin-right: 5px;
  display: inline-block;
  width: 5px;
  /* position: relative; */

  ${styledCssLine()}
`;

type ExpanderButtonProps = {
  expanded?: boolean;
};

const ExpanderButton = styled.div<ExpanderButtonProps>`
  text-align: center;
  display: inline-block;
  height: 100%;

  transform: translateX(-40%);

  ${(props) => props.expanded && styledCssLine(true)}
`;

const StructureWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export interface TableRowExpanderButtonProps {
  expanded?: boolean;
  onToggle?: () => void;
  depth?: number;
  canExpand?: boolean;
}

const TableRowExpanderButton = ({
  expanded,
  depth,
  onToggle,
  canExpand,
}: TableRowExpanderButtonProps) => {
  return (
    <StructureWrapper>
      {_.range(depth || 0).map((depth) => (
        <LevelIndicator />
      ))}

      {canExpand && (
        <ExpanderButton
          expanded={expanded}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={(e) => {
            e.stopPropagation();

            if (onToggle != null) {
              onToggle();
            }
          }}
        >
          <FontAwesomeIcon icon={expanded ? faCaretDown : faCaretRight} />
        </ExpanderButton>
      )}
    </StructureWrapper>
  );
};

export default TableRowExpanderButton;
