import React, { useContext, useEffect, useMemo } from "react";
import { uuidv4 } from "cosmos-config/utils";
import ContentLayoutContext, { componentType } from "./ContentLayoutContext";
import styled from "styled-components";
import ContentLayoutColumn from "./ContentLayoutColumn";

const ContentColumn = styled(ContentLayoutColumn)`
  padding-top: 15px;
  padding-bottom: 15px;
`;

export interface ContentLayoutContentProps {
  children?: React.ReactNode;
  className?: string;
}

const ContentLayoutContent = ({
  children,
  className,
}: ContentLayoutContentProps) => {
  const { registerComponent, unregisterComponent } =
    useContext(ContentLayoutContext);

  const uuid = useMemo(() => uuidv4(), []);

  useEffect(() => {
    registerComponent(uuid, componentType.CONTENT);

    return () => unregisterComponent(uuid);
  }, [registerComponent, unregisterComponent, uuid]);

  return <ContentColumn className={className}>{children}</ContentColumn>;
};

export default ContentLayoutContent;
