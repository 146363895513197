import { createContext } from "react";

declare type CosmosCoreContextValue = {
  callImport: (url: string) => Promise<any>;
};

const CosmosCoreContext = createContext<CosmosCoreContextValue>({
  callImport: () => {
    return Promise.resolve();
  },
});

export default CosmosCoreContext;
