import { useCallback, useMemo } from "react";
import useCurrentUser from "../useCurrentUser";

/**
 * @module hooks/useWorklflowPermissions
 * @category Hooks
 */

/**
 * @typedef {object} UserWorkflowPermissions
 * @property {function} canAbort - Whether current user can abort the workflow(s).
 * @property {function} canStart - Whether current user can start the workflow(s).
 * @property {function} canEnd - Whether current user can end the workflow(s).
 */

/**
 * Hook providing permissions of current user to the workflow(s).
 *
 * @param  {...Workflow} workflows - List of workflows of which permissions are calculated.
 * @returns {UserWorkflowPermissions}
 */
const useWorkflowPermissions = (...workflows: any[]) => {
  const { defaultPositionId, permissions } = useCurrentUser();
  const workflowPermissions = permissions.workflowPermissions;

  const processDefinitions = useMemo(
    () =>
      workflows
        .filter((x) => x != null)
        .map((d) => d.processdefinition)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        }),
    [workflows]
  );

  const creators = useMemo(
    () =>
      workflows
        .filter((x) => x != null)
        .map((d) => d.processinitiator)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        }),
    [workflows]
  );

  const permissionObject = useMemo(() => {
    const permissions = processDefinitions
      .filter((x) => x != null && x !== "")
      .map((ocn) => workflowPermissions[ocn])
      .filter((x) => x != null);

    return permissions.reduce((acc, cur) => {
      return Object.entries(cur).reduce((innerAcc, [key, value]) => {
        return {
          ...innerAcc,
          [key]: acc[key] && value,
        };
      }, {});
    }, permissions[0]);
  }, [processDefinitions, workflowPermissions]);

  // const can = useCallback(
  //   (wfName, permission) => {
  //     if (workflowPermissions != null) {
  //       const { [wfName]: perm } = workflowPermissions;
  //       return perm != null && !!perm[permission];
  //     }

  //     return false;
  //   },
  //   [workflowPermissions]
  // );

  const owns = useCallback(
    (pid?: string | null) => creators.every((c) => c === pid),
    [creators]
  );

  const can = useCallback(
    (permission: string) =>
      permissionObject != null ? permissionObject[permission] : false,
    [permissionObject]
  );

  const canStart = () => can("start");

  const canEnd = () => can("end");

  return {
    canAbort: useCallback(
      () => owns(defaultPositionId) && can("end"),
      [can, owns, defaultPositionId]
    ),
    canStart,
    canEnd,
  };
};

export default useWorkflowPermissions;
