export const SET_USERDATA = 'SET_USERDATA';
export const SET_PRINCIPAL_ID = 'SET_PRINCIPAL_ID';
export const SET_FILTERSET_ID = 'SET_FILTERSET_ID';
export const SET_USER_PERMISSIONS = 'userdata/setPermissions';
export const SET_USER_GROUP_NAMES = 'userdata/setGroupNames';

export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const LOGIN_ADFS = 'LOGIN_ADFS';
export const LOGIN_ADFS_ATTEMPT = 'LOGIN_ADFS_ATTEMPT';
export const LOGIN_EXTERNAL_ADFS = 'LOGIN_EXTERNAL_ADFS';
export const REFRESH_ADFS = 'REFRESH_ADFS';
export const REFRESH_ADFS_ATTEMPT = 'REFRESH_ADFS_ATTEMPT';
export const LOGIN_ADFS_SUCCESS = 'LOGIN_ADFS_SUCCESS';
export const LOGIN_ADFS_ERROR = 'LOGIN_ADFS_ERROR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SELECT_FILTERSET = 'SELECT_FILTERSET';
export const SAVE_FILTERSET = 'SAVE_FILTERSET';
export const DELETE_FILTERSET = 'DELETE_FILTERSET';
export const FETCH_FILTERSETS = 'FETCH_FILTERSETS';
export const SET_FILTERSETS = 'SET_FILTERSETS';
export const LOG_OPENED_DOCUMENT = 'LOG_OPENED_DOCUMENT';
export const SET_DELEGATES = 'SET_DELEGATES';
export const CREATE_DELEGATE = 'CREATE_DELEGATE';
export const DEACTIVATE_DELEGATES = 'DEACTIVATE_DELEGATES';
export const SET_DELEGATED_PRINCIPALS = 'SET_DELEGATED_PRINCIPALS';
export const SET_USERDATA_LOADING = 'SET_USERDATA_LOADING';
