import { Button } from "react-bootstrap";
import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import useCurrentUserSettings from "../../hooks/useCurrentUserSettings";
import Resource from "../../types/resource";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

export interface FavouriteButtonProps {
  document?: Resource;
  className?: string;
  size?: SizeProp;
}

export const FavouriteButton = ({
  document,
  className,
  size,
}: FavouriteButtonProps) => {
  const { isResourceUserFavourite, toggleResourceFavourite } =
    useCurrentUserSettings();

  const favourite = useMemo(() => {
    if (document?.id == null) {
      return false;
    }

    return isResourceUserFavourite(document.id);
  }, [document?.id, isResourceUserFavourite]);

  if (document?.virtual) {
    return null;
  }

  return (
    <Button
      variant="link"
      size="sm"
      className={classNames("p-0", className)}
      onClick={() => {
        if (document?.id != null) {
          toggleResourceFavourite(document?.id);
        }
      }}
    >
      <FontAwesomeIcon
        className={classNames({
          "text-dark": !favourite,
          "text-warning": favourite,
        })}
        icon={favourite ? faStar : faStarRegular}
        size={size}
      />
    </Button>
  );
};

export default FavouriteButton;
