import AccessManagementType from "./accessManagementType";
import group, { Group } from "./group";

/**
 * @interface
 * @category Group
 */
interface FolderView {
  groupName: string;
}

/**
 * @interface
 * @category Group
 * @extends Group
 */
export interface Folder extends Group {
  folder: boolean;
  displayCreationData: boolean;
  uploadWizard: boolean;
  startWorkflowDefinition: string | null;
  uploadFilesWhileCreating: boolean;
  titlePropertyName: string | null;
  structured: boolean;
  template: string | null;
  views: FolderView[];
  maintainers: string[];
  accessManagement: AccessManagementType;
  uniqueInstances: boolean;
  subFolderTypes: string[];

  pendingCallback(): boolean;
  titleProperty(propertyName: string): Folder;
  excludeDescription(displayCreationData: boolean): Folder;
  excludeUploadWizard(exclude: boolean): Folder;
  startsWorkflow(
    workflowDefinition: string,
    pendingCallback: () => boolean
  ): Folder;
  excludeFilesUploadWhileCreating(exclude: boolean): Folder;
  useStructure(structured: boolean): Folder;
  useTemplate(template: string): Folder;
  useView(groupName: string): Folder;
  maintained(...maintainers: string[]): Folder;
  manageableAccess(type: AccessManagementType): Folder;
  uniquifyInstance(uniqueInstances: boolean): Folder;
  allowSubFolder(folderType: string): Folder;
}

/**
 * Folder Generator.
 * @category Group
 * @author Patrik Pancisin
 * @class folder
 * @classdesc Basic building block of cosmos.
 * @extends group
 * @param {string} label - The label of the folder displayed in cosmos.
 * @param {string} name - The name of the folder.
 */
export default (label: string, name: string): Folder => ({
  ...group(label, name),
  folder: true,
  displayCreationData: true,
  uploadWizard: true,
  startWorkflowDefinition: null,
  uploadFilesWhileCreating: true,
  titlePropertyName: null,
  structured: false,
  template: null,
  views: [],
  maintainers: [],
  accessManagement: AccessManagementType.NONE,
  uniqueInstances: false,
  subFolderTypes: [],

  pendingCallback: () => true,
  titleProperty(propertyName) {
    this.titlePropertyName = propertyName;
    return this;
  },
  excludeDescription(displayCreationData = false) {
    this.displayCreationData = displayCreationData;
    return this;
  },
  excludeUploadWizard(exclude = true) {
    this.uploadWizard = !exclude;
    return this;
  },
  startsWorkflow(workflowDefinition, pendingCallback = () => true) {
    this.startWorkflowDefinition = workflowDefinition;
    this.pendingCallback = pendingCallback;
    return this;
  },
  excludeFilesUploadWhileCreating(exclude = true) {
    this.uploadFilesWhileCreating = !exclude;
    return this;
  },
  useStructure(structured = true) {
    this.structured = structured;
    return this;
  },
  useTemplate(template) {
    this.template = template;
    return this;
  },
  useView(groupName) {
    this.views = [
      ...this.views,
      {
        groupName,
      },
    ];

    return this;
  },
  maintained(...maintainers) {
    this.maintainers = maintainers;
    return this;
  },
  manageableAccess(accessManagement) {
    this.accessManagement = accessManagement;
    return this;
  },
  uniquifyInstance(uniqueInstances) {
    this.uniqueInstances = uniqueInstances;
    return this;
  },
  allowSubFolder(folderType) {
    this.subFolderTypes = [...this.subFolderTypes, folderType];
    return this;
  },
});
