import React from "react";
import { Button } from "react-bootstrap";
import { usePropertyRender } from "../../../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import projectPortalRuleProperties from "../../../../contants/projectPortalRuleProperties";
import {
  ProjectPortal,
  ProjectPortalRule,
} from "../../../../contants/projectPortal";
import classNames from "classnames";

export interface PortalsTableProps {
  portals?: ProjectPortal[];
  projectCode?: string;
  onEdit?: (portalId: string) => void;
  onDelete?: (portalId: string) => void;
}

const PortalsTable = ({
  portals,
  projectCode,
  onEdit,
  onDelete,
}: PortalsTableProps) => {
  const render = usePropertyRender(projectPortalRuleProperties);

  const getPortalName = (portal: ProjectPortal, index: number) => {
    function* generateParts() {
      yield `#${index + 1}`;
      yield portal.targetProjectCode;
      yield "->";
      // yield <FontAwesomeIcon icon={faArrowRight} />;
      yield projectCode;

      if (portal.nativeSearch) {
        yield "(native)";
      } else {
        yield "(non-native)";
      }

      yield "Portal";
    }

    return [...generateParts()].join(" ");
  };

  const getRuleDescription = (
    rule: ProjectPortalRule,
    targetProjectCode: string
  ) => {
    function* generateParts() {
      yield render("operandType", rule.operandType);

      yield "[";

      if (rule.operandType === "VALUE") {
        yield `"${rule.operand}"`;
      } else {
        yield `${rule.operand} (${projectCode})`;
      }

      yield render("operator", rule.operator);
      yield `${rule.targetProperty} (${targetProjectCode})`;

      yield "]";
    }

    return [...generateParts()].join(" ");
  };

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Rules</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {(portals || []).map((portal, idx) => (
          <tr>
            <td>
              <FontAwesomeIcon
                icon={faCircle}
                className={classNames("mr-2", {
                  "text-success": portal.active,
                  "text-muted": !portal.active,
                })}
              />
            </td>
            <td>{getPortalName(portal, idx)}</td>
            <td>
              {portal.rules.map((rule) => (
                <p>{getRuleDescription(rule, portal.targetProjectCode)}</p>
              ))}
            </td>
            <td>
              <Button
                variant="link"
                className="p-0"
                onClick={() => {
                  if (onEdit != null) {
                    onEdit(portal.id);
                  }
                }}
              >
                Edit
              </Button>

              <Button
                variant="link"
                className="text-danger"
                onClick={() => {
                  if (onDelete != null) {
                    onDelete(portal.id);
                  }
                }}
              >
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PortalsTable;
