import _ from "lodash";
import GatewayApi from "./conf/GatewayApi";

const gatewayApi = GatewayApi.build();

export type ProgressCallback = (progress: number) => void;

const downloadDocuments = (
  resourceIds: string[],
  progressCallback?: ProgressCallback
) => {
  return gatewayApi
    .get(`/repository/download`, {
      responseType: "blob",
      params: {
        resourceIds: resourceIds.join(),
      },
      onDownloadProgress: (e) => {
        if (e.lengthComputable && progressCallback != null) {
          const totalBytes = e.total || 0;

          const progress = (e.loaded / totalBytes) * 100;
          progressCallback(_.round(progress, 2));
        }
      },
    })
    .then((response) => {
      const contentDisposition = response.headers["content-disposition"];
      var fileName = contentDisposition.match(/filename\s*=\s*"(.+)"/i)[1];

      return Promise.resolve({
        data: response.data,
        contentType: response.headers["content-type"],
        fileName,
      });
    });
};

const deleteResources = (resourceIds: string[]) => {
  return gatewayApi
    .put(`/repository/delete-all`, {
      resourceIds,
    })
    .then(() => Promise.resolve(resourceIds));
};

const revertDeleteResources = (resourceIds: string[]) => {
  return gatewayApi
    .put(`/repository/revert-all`, {
      resourceIds,
    })
    .then(() => Promise.resolve(resourceIds));
};

const resourceApi = {
  downloadDocuments,
  deleteResources,
  revertDeleteResources,
};

export default resourceApi;
