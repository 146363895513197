import { uuidv4 } from "cosmos-config/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import DropzoneContext from "./DropzoneContext";
import styled, { css } from "styled-components";

type SubZoneWrapperProps = {
  active?: boolean;
  paralyzed?: boolean;
};

const SubZoneWrapper = styled.div<SubZoneWrapperProps>`
  ${(props) =>
    props.active &&
    css`
      font-weight: bold;
    `}

  & > * {
    pointer-events: ${(props) => (props.paralyzed ? "none" : "all")};
  }
`;

export interface PancoSubDropzoneProps {
  children?: React.ReactNode;
  className?: string;
  metadata?: Record<string, any>;
}

const PancoSubDropzone = ({
  children,
  className,
  metadata,
}: PancoSubDropzoneProps) => {
  const uuid = useMemo(() => uuidv4(), []);

  const { contextPresent, registerSubzone, unregisterSubzone, activeZone } =
    useContext(DropzoneContext);

  useEffect(() => {
    if (contextPresent) {
      registerSubzone(uuid, metadata);
    }

    return () => unregisterSubzone(uuid);
  }, [contextPresent, metadata, registerSubzone, unregisterSubzone, uuid]);

  if (!contextPresent) {
    return <>{children}</>;
  }

  return (
    <SubZoneWrapper
      className={className}
      data-sub-dropzone={uuid}
      active={activeZone === uuid}
      paralyzed={activeZone != null}
    >
      {children}
    </SubZoneWrapper>
  );
};

export default PancoSubDropzone;
