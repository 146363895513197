import CacheApi from "./conf/CacheApi";
import { select } from "cosmos-config/nql";

const cacheApi = CacheApi.build();

const getMasterdateCacheKey = (scope: string, key?: string) =>
  key != null ? `${scope}:${key}` : scope;

interface NscaleQuery {
  where: any;
  orWhere?: any;
  orderBy: Record<string, string>;
  paging: any;
}

const getMasterData = (
  docarea: string,
  scope: string,
  properties = ["*"],
  { where, orWhere, orderBy, paging }: NscaleQuery = {} as NscaleQuery,
  cacheKey?: string
) => {
  const query = select(properties)
    .where(where)
    .orWhere(orWhere)
    .orderBy(orderBy)
    .paging(paging)
    .toString();

  const options = CacheApi.options(
    {
      params: {
        query,
      },
    },
    cacheKey == null,
    getMasterdateCacheKey(scope, cacheKey)
  );

  return cacheApi
    .get(`/masterdata/docarea/${docarea}/scope/${scope}`, options)
    .then((response) => {
      return Promise.resolve(response.data.items);
    });
};

type MasterdataProperty = {
  name: string;
  value: any;
};

const postMasterDataProperties = (
  docarea: string,
  scope: string,
  properties: MasterdataProperty[],
  cacheKey?: string
) => {
  const data = {
    properties,
  };

  return cacheApi
    .post(`/masterdata/docarea/${docarea}/scope/${scope}`, data)
    .then((response) => {
      if (cacheKey != null) {
        CacheApi.removeItem(getMasterdateCacheKey(scope, cacheKey));
      }
      return Promise.resolve(response.data.id);
    });
};

const postMasterDataEntry = (
  docarea: string,
  scope: string,
  entry: any,
  cacheKey?: string
) => {
  const properties = Object.entries(entry).map(([name, value]) => ({
    name,
    value: value || null,
  }));

  return postMasterDataProperties(docarea, scope, properties, cacheKey);
};

const updateMasterDataEntry = (
  docarea: string,
  scope: string,
  id: string,
  entry: any,
  cacheKey?: string
) => {
  const properties = Object.entries(entry).map(([name, value]) => ({
    name,
    value: value || null,
  }));

  const data = {
    properties,
  };

  return cacheApi
    .put(`/masterdata/docarea/${docarea}/scope/${scope}/id/${id}`, data, {
      params: {
        ignoreCache: true,
      },
    })
    .then((response) => {
      if (cacheKey != null) {
        CacheApi.removeItem(getMasterdateCacheKey(scope, cacheKey));
      }
      return Promise.resolve(response.data);
    });
};

const removeMasterDataEntry = (
  docarea: string,
  scope: string,
  id: string,
  cacheKey = null
) => {
  return cacheApi
    .delete(`/masterdata/docarea/${docarea}/scope/${scope}/id/${id}`)
    .then((response) => {
      if (cacheKey != null) {
        CacheApi.removeItem(getMasterdateCacheKey(scope, cacheKey));
      }
      return Promise.resolve(response.data);
    });
};

const masterdataApi = {
  getMasterData,
  postMasterDataEntry,
  updateMasterDataEntry,
  removeMasterDataEntry,
};

export default masterdataApi;
