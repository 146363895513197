import property, { Property } from "./property";

/**
 * Property Generator.
 * @category Property
 * @author Patrik Pancisin
 * @class documentProperty
 * @classdesc Basic building block of cosmos.
 * @extends property
 * @param {string} label - The label of the group displayed in cosmos.
 * @param {string} name - The name of the group.
 */
export default (label: string, name: string): Property => {
  const documentProperty = property(label, name, 2);

  const folderProperty = new Proxy(documentProperty, {
    get: (t: any, p: string) => {
      switch (p) {
        case "editable":
        case "filterable":
        case "information":
        case "tableColumn":
          return false;
        case "resourceType":
          return 1;
        default:
          return t[p];
      }
    },
  });

  const handler = {
    get: (target: any, prop: string) => {
      if (prop === "folderProperty") {
        return folderProperty;
      }

      return target[prop];
    },
  };

  return new Proxy(documentProperty, handler);
};
