import ESignatureType from "./eSignatureType";
import projectFolderTabs from "./projectFolderTabs";
import projectMainScope from "./projectMainScope";

interface WorkflowOptions {
  default: boolean;
  workflowStatus: boolean;
}

interface BusinessProcessOptions {
  excludeThirdParty: boolean;
  resourceProperties: Array<string>;
}

/**
 * @interface
 * @category Project
 */
export interface Project {
  code: string;
  scope: string;
  workflows: Array<any>;
  businessProcesses: Array<any>;
  enabledFolderTabs: Array<string>;
  enableExplorer: boolean;
  legacyLayout: boolean;
  eSignature: ESignatureType;
  officeOnline: boolean;

  mainScopeFolder(): Project;
  mainScopeWorkflow(): Project;
  enableWorkflow(processName: string, options: WorkflowOptions): Project;
  enableBusinessProcess(
    processDefinitionKey: string,
    options: BusinessProcessOptions
  ): Project;
  explorable(enableExplorer: boolean): Project;
  useLegacyLayout(legacyLayout: boolean): Project;
  enableESignature(eSignature: ESignatureType): Project;
  enableOfficeOnline(officeOnline: boolean): Project;
  enableFolderTabs(tabs: Array<string>): Project;
}

const project = (code: string): Project => ({
  code,
  scope: projectMainScope.DOCUMENT,
  workflows: [],
  businessProcesses: [],
  enabledFolderTabs: Object.values(projectFolderTabs),
  enableExplorer: false,
  legacyLayout: false,
  eSignature: ESignatureType.NONE,
  officeOnline: false,

  mainScopeFolder() {
    this.scope = projectMainScope.FOLDER;
    return this;
  },

  mainScopeWorkflow() {
    this.scope = projectMainScope.WORKFLOW;
    return this;
  },

  enableWorkflow(processName, options) {
    const isFirstAdded = this.workflows.length === 0;

    this.workflows = [
      ...this.workflows.map((wf, idx, array) => {
        const newlyAddedDefault = options?.default;
        const defaultExists = array.filter((wf) => wf.default).length > 0;

        return {
          ...wf,
          default:
            !newlyAddedDefault && ((idx === 0 && !defaultExists) || wf.default),
        };
      }),
      {
        processName,
        default: options?.default || isFirstAdded,
        workflowStatus: options?.workflowStatus || false,
      },
    ];
    return this;
  },

  enableBusinessProcess(
    processDefinitionKey,
    options = {} as BusinessProcessOptions
  ) {
    this.businessProcesses = [
      ...this.businessProcesses,
      {
        processDefinitionKey,
        excludeThirdParty: !!options?.excludeThirdParty,
        resourceProperties: options?.resourceProperties || [],
      },
    ];

    return this;
  },

  explorable(enableExplorer = true) {
    this.enableExplorer = enableExplorer;
    return this;
  },

  useLegacyLayout(legacyLayout = true) {
    this.legacyLayout = legacyLayout;
    return this;
  },

  enableESignature(eSignature) {
    this.eSignature = eSignature || ESignatureType.BASIC;
    return this;
  },

  enableOfficeOnline(officeOnline = true) {
    this.officeOnline = officeOnline;
    return this;
  },

  enableFolderTabs(tabs: Array<string>) {
    this.enabledFolderTabs = tabs;
    return this;
  }
});

export default project;
