import { property, valuesetSource } from "cosmos-config/generator";

const accessRequestProperties = [
  property("Requestor", "requestorName"),
  property("Department").selectableAttribute(
    "department",
    "master_department",
    valuesetSource.CUSTOM_VALUESET
  ),
  property("Manager", "validatorPrincipalId")
    .disableSorting()
    .selectablePrincipalAttribute("validatorPrincipalId", 2),
  property("Maintainer")
    .multiAttribute("requestResponsibles")
    .disableSorting()
    .selectablePrincipalAttribute("requestResponsibles", 2),
  property("Reason for Request", "reason"),
  property("Requested At").dateAttribute("createdAt"),
  property("", "actions").includeTable(true, 250).disableSorting(),
];

export default accessRequestProperties;
