import { useCallback, useEffect, useState } from "react";
import useClickOutside from "./useClickOutside";
import useViewPlacement from "./useViewPlacement";

const useContextMenu = (elementRef, contextMenuRef) => {
  const [state, setState] = useState({
    show: false,
    xPos: 0,
    yPos: 0,
  });
  const [height, setHeight] = useState(0);

  const { placement, offset, recalculatePlacement } = useViewPlacement({
    height,
    placement: "bottom",
  });
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  const toggleShowMenu = useCallback((show) => {
    setState((cur) => ({ ...cur, show: show != null ? show : !cur.show }));
  }, []);

  useEffect(() => {
    if (placement === "top") {
      setPosition({
        x: state.xPos,
        y: state.yPos - height + offset,
      });
    } else {
      setPosition({
        x: state.xPos,
        y: state.yPos - offset,
      });
    }
  }, [contextMenuRef, placement, state, offset, height]);

  const handleContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      setState({
        show: true,
        xPos: e.clientX,
        yPos: e.clientY,
      });

      setTimeout(() => {
        setHeight((current) => {
          const h = contextMenuRef.current?.offsetHeight;
          return h !== current ? h : current;
        });
      }, 10);
    },
    [contextMenuRef]
  );

  useEffect(() => {
    if (state.show) {
      recalculatePlacement(state.yPos);
    }
  }, [recalculatePlacement, state]);

  useClickOutside(contextMenuRef, () => {
    setState({
      show: false,
    });
  });

  useEffect(() => {
    if (elementRef?.current != null) {
      elementRef.current.addEventListener("contextmenu", handleContextMenu);
    }

    const elementRefVar = elementRef.current;

    return () => {
      if (elementRefVar != null) {
        elementRefVar.removeEventListener("contextmenu", handleContextMenu);
      }
    };
  }, [elementRef, handleContextMenu]);

  return {
    xPos: position.x,
    yPos: position.y,
    showMenu: state.show,
    toggleShowMenu,
  };
};

export default useContextMenu;
