import Api from './conf/Api';
import CacheApi from './conf/CacheApi';
import GatewayApi from './conf/GatewayApi';
import {
  isCurrentDefaultPositionId,
  isEqual,
  isFalse,
  isIn,
  isCurrentProxyPositionId,
  select,
} from 'cosmos-config/nql';

const cacheApi = CacheApi.build();
const api = Api.build();
const gatewayApi = GatewayApi.build();

const requestProcesses = (docarea, query) => {
  return api
    .get(`/workflow/docarea/${docarea}/processes/search`, {
      params: {
        query,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const requestTasks = (docarea, query) => {
  return api
    .get(`/workflow/docarea/${docarea}/tasks/search`, {
      params: {
        query,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const getProcesses = (docarea, properties = []) => {
  const query = select(properties).toString();
  return requestProcesses(docarea, query);
};

const getProcess = (docarea, processId, properties = []) => {
  return api
    .get(`/workflow/docarea/${docarea}/process/${processId}`, {
      params: {
        properties: properties.join(','),
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const getTask = (docarea, taskId, properties = []) => {
  return api
    .get(`/workflow/docarea/${docarea}/task/${taskId}`, {
      params: {
        properties: properties.join(','),
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const getAllProcessTasks = (docarea, processId, properties = []) => {
  const query = select(properties)
    .where({
      processidentifier: isEqual(processId),
      taskended: isFalse(),
    })
    .toString();

  return requestTasks(docarea, query);
};

const getProcessTasks = (docarea, processIds, properties = []) => {
  let pids = processIds;
  if (!Array.isArray(processIds)) {
    pids = [processIds];
  }

  pids = pids.map((id) => parseInt(String(id).split('$')[2], 10));

  const query = select(properties)
    .orWhere([
      {
        name: 'assignmentorgentityid',
        value: isCurrentDefaultPositionId(),
        multi: true,
      },
      { name: 'assignedpositionid', value: isCurrentDefaultPositionId() },
      {
        name: 'assignedpositionid',
        value: isCurrentProxyPositionId(),
      },
    ])
    .where({
      // processinstanceid: isEqual(processId),
      processidentifier: isIn(pids),
      taskended: isFalse(),
    })
    .where([
      {
        name: 'dispatchers',
        value: isCurrentDefaultPositionId(),
        multi: true,
        negate: true,
      },
    ])
    .toString();

  return requestTasks(docarea, query);

  // return api.get(`/workflow/docarea/${docarea}/process/${processId}/tasks`, {
  //   params: {
  //     properties: properties.join(','),
  //   },
  // }).then((response) => {
  //   return Promise.resolve(response.data);
  // });
};

const getUserProcesses = (docarea, processDefinition, properties = []) => {
  const query = select(properties)
    .orWhere([
      // {
      //   name: 'assignmentorgentityid',
      //   value: isCurrentDefaultPositionId(),
      //   multi: true,
      // },
      { name: 'processinitiator', value: isCurrentDefaultPositionId() },
    ])
    .where({
      processcanceled: isFalse(),
      processended: isFalse(),
      processdefinition: processDefinition,
    })
    .toString();

  return requestProcesses(docarea, query).then((response) =>
    Promise.resolve(response.items)
  );
};

const getAssignedTasks = (docarea, processDefinition, properties = []) => {
  const query = select(properties)
    .orWhere([
      {
        name: 'assignmentorgentityid',
        value: isCurrentDefaultPositionId(),
        multi: true,
      },
      {
        name: 'assignedpositionid',
        value: isCurrentProxyPositionId(),
      },
    ])
    .where({
      processcanceled: isFalse(),
      processended: isFalse(),
      processdefinition: processDefinition,
    })
    // .where([
    //   {
    //     name: 'dispatchers',
    //     value: isCurrentDefaultPositionId(),
    //     multi: true,
    //     negate: true,
    //   },
    // ])
    .toString();

  return requestTasks(docarea, query);
};

const getProcessDefinitions = (docarea) => {
  return cacheApi
    .get(
      `/configuration/docarea/${docarea}/processdefinitions/metadata`,
      CacheApi.options({}, false, 'PROCESS_DEFINITIONS')
    )
    .then((response) => {
      return Promise.resolve(response.data.processMetadatas);
    });
};

const postProcess = (docarea, name, properties) => {
  return api
    .post(
      `/workflow/docarea/${docarea}/processes`,
      {
        name,
        properties,
      },
      {
        params: {
          creator: null,
          processkey: true,
        },
      }
    )
    .then((response) => Promise.resolve(response.data.id));
};

const getTasks = (docarea) => {
  return api.get(`/workflow/docarea/${docarea}/tasks`).then((response) => {
    return Promise.resolve(response.data);
  });
};

const resumeProcess = (docarea, processId) => {
  return api
    .put(`/workflow/docarea/${docarea}/process/${processId}/resume`)
    .then((response) => Promise.resolve(response.data));
};

const suspendProcess = (docarea, processId) => {
  return api
    .put(`/workflow/docarea/${docarea}/process/${processId}/suspend`)
    .then((response) => Promise.resolve(response.data));
};

const endProcess = (docarea, processId) => {
  return api
    .put(`/workflow/docarea/${docarea}/process/${processId}/end`, {
      properties: [],
    })
    .then((response) => Promise.resolve(response.data));
};

const endProcessPeacefully = (docarea, processId) => {
  return gatewayApi
    .put(`/workflow/docarea/${docarea}/process/${processId}/end`)
    .then((response) => Promise.resolve(response.data));
};

const startTask = (docarea, taskId, properties) => {
  return api
    .put(`/workflow/docarea/${docarea}/task/${taskId}/start`, {
      properties,
    })
    .then((response) => Promise.resolve(response.data));
};

const updateTask = (docarea, taskId, properties) => {
  return api
    .put(`/workflow/docarea/${docarea}/task/${taskId}`, {
      properties,
    })
    .then((response) => Promise.resolve(response.data));
};

const endTask = (docarea, taskId, properties) => {
  return api
    .put(`/workflow/docarea/${docarea}/task/${taskId}/end`, {
      properties,
    })
    .then((response) => Promise.resolve(response.data));
};

const getProcessesByFolder = (
  docarea,
  folderId,
  processparam,
  properties = []
) => {
  const query = select(properties)
    .where({
      folderId,
      processparam,
    })
    .toString();

  return requestProcesses(docarea, query);
};

const getTasksByFolder = (docarea, folderId, processparam, properties = []) => {
  const query = select(properties)
    .where({
      folderId,
      processparam,
    })
    .toString();

  return requestTasks(docarea, query);
};

export default {
  getProcesses,
  getProcess,
  getUserProcesses,
  getProcessDefinitions,
  postProcess,
  getTasks,
  getTask,
  getProcessTasks,
  resumeProcess,
  suspendProcess,
  endProcess,
  endProcessPeacefully,
  startTask,
  updateTask,
  endTask,
  getAssignedTasks,
  getAllProcessTasks,
  getProcessesByFolder,
  getTasksByFolder,
};
