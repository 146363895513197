import * as actions from './types';

/**
 * Sharepoint action generators.
 * @module actions/sharepoint
 * @category Actions
 */

/**
 * Action to set home account id.
 * @function
 * @param {} homeAccountId -
 */
export const setHomeAccountId = (homeAccountId) => ({
  type: actions.sharepoint.SET_HOME_ACCOUNT_ID,
  homeAccountId,
});

/**
 * Action to set login sharepoint.
 * @function
 */
export const loginSharepoint = () => ({
  type: actions.sharepoint.LOGIN_SHAREPOINT,
});

/**
 * Action to set logout sharepoint.
 * @function
 */
export const logoutSharepoint = () => ({
  type: actions.sharepoint.LOGOUT_SHAREPOINT,
});

/**
 * Action to set search results.
 * @function
 * @param {} searchResults -
 */
export const setSearchResults = (searchResults) => ({
  type: actions.sharepoint.SET_SEARCH_RESULTS_SHAREPOINT,
  searchResults,
});

/**
 * Action to set rendition sharepoint.
 * @function
 * @param {} rendition -
 */
export const setRenditionSharepoint = (rendition) => ({
  type: actions.sharepoint.SET_RENDITION_SHAREPOINT,
  rendition,
});

/**
 * Action to select documents sharepoint.
 * @function
 * @param {} documents -
 */
export const selectDocumentsSharepoint = (documents) => ({
  type: actions.sharepoint.SELECT_DOCUMENTS_SHAREPOINT,
  documents,
});

/**
 * Action to import from sharepoint.
 * @function
 * @param {function} callback - action results callback
 */
export const importFromSharepoint = (callback = () => {}) => ({
  type: actions.sharepoint.IMPORT_FROM_SHAREPOINT,
  callback,
});

/**
 * Action to download from sharepoint.
 * @function
 * @param {function} callback - action results callback
 */
export const downloadFromSharepoint = (callback = () => {}) => ({
  type: actions.sharepoint.DOWNLOAD_FROM_SHAREPOINT,
  callback,
});

/**
 * Action to set loading of sharepoint.
 * @function
 * @param {boolean} loading - status of loading sharepoint
 */
export const setLoadingSharepoint = (loading = true) => ({
  type: actions.sharepoint.SET_LOADING_SHAREPOINT,
  loading,
});

/**
 * Action to set rendition loading of sharepoint.
 * @function
 * @param {boolean} loading - status of rendition loading sharepoint
 */
export const setLoadingRenditionSharepoint = (loading = true) => ({
  type: actions.sharepoint.SET_LOADING_RENDITION_SHAREPOINT,
  loading,
});

/**
 * Action to set sharepoint drives.
 * @function
 * @param {} drives -
 */
export const setSharepointDrives = (drives) => ({
  type: actions.sharepoint.SET_SHAREPOINT_DRIVES,
  drives,
});

/**
 * Action to set fetch sharepoint item children.
 * @function
 * @param {string} driveId -
 * @param {string} itemId -
 * @param {function} callback - action results callback
 */
export const fetchSharepointItemChildren = (
  driveId,
  itemId,
  callback = () => {}
) => ({
  type: actions.sharepoint.FETCH_SHAREPOINT_ITEM_CHILDREN,
  driveId,
  itemId,
  callback,
});

/**
 * Action to fetch sharepoint drives.
 * @function
 * @param {function} callback - action results callback
 */
export const fetchSharepointDrives = (callback = () => {}) => ({
  type: actions.sharepoint.FETCH_SHAREPOINT_DRIVES,
  callback,
});

/**
 * Action to download sharepoint drives items.
 * @function
 * @param {string} driveId -
 * @param {string} itemId -
 * @param {function} callback - action results callback
 * @param {function} progressCallback - progress callback
 */
export const downloadSharepointDriveItems = (
  driveId,
  itemId,
  callback = () => {},
  progressCallback = () => {}
) => ({
  type: actions.sharepoint.DOWNLOAD_SHAREPOINT_DRIVE_ITEMS,
  driveId,
  itemId,
  callback,
  progressCallback,
});

/**
 * Action to search sharepoint sites.
 * @function
 * @param {} query -
 * @param {function} callback - action results callback
 */
export const searchSharepontSites = (query, callback = () => {}) => ({
  type: actions.sharepoint.SEARCH_SHAREPOINT_SITES,
  query,
  callback,
});

/**
 * Action to get sharepoint site drive.
 * @function
 * @param {string} siteId - id of the site
 * @param {function} callback - action results callback
 */
export const getSharepointSiteDrive = (siteId, callback = () => {}) => ({
  type: actions.sharepoint.GET_SHAREPOINT_SITE_DRIVE,
  siteId,
  callback,
});

// export const searchFilesSharepoint = (searchQuery) => ({
//   type: actions.sharepoint.SEARCH_FILES_SHAREPOINT,
//   searchQuery,
// });
