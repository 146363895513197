export type GridMapping = {
  md: number;
  lg: number;
  sm: number;
};

const sidebarGridMapping: GridMapping = {
  md: 3,
  lg: 2,
  sm: 4,
};

export default sidebarGridMapping;
