import _ from "lodash";
import { principalType } from "cosmos-config/generator";
import Api from "./conf/Api";
import {
  andOperation,
  operation,
  queryOperand,
  select,
} from "cosmos-config/nql";

const api = Api.build();

export interface PrincipalEntity {
  commonname: string;
  principalid: string;
  email?: string;
}

interface PrincipalEntityItem {
  properties: [
    {
      name: string;
      value: any;
    }
  ];
}

interface PrincipalRequestResponse {
  count: number;
  items: PrincipalEntityItem[];
}

const requestUsers = (query: string): Promise<PrincipalRequestResponse> => {
  return api
    .get(`/usermanagement/nqlsearch`, {
      params: {
        query,
      },
    })
    .then((response) => {
      const { count, principalEntityItems: items } = response.data;
      return Promise.resolve({ count, items } as PrincipalRequestResponse);
    });
};

const parsePrincipalItems = (items: PrincipalEntityItem[]) =>
  items.map(({ properties }) => {
    return _(properties)
      .keyBy("name")
      .mapValues("value")
      .value() as PrincipalEntity;
  });

const getPrincipalSearchOperation = (
  domainNames: string[],
  principalTypes: principalType[]
) => {
  return andOperation(
    operation("in", queryOperand("domainname"), ...domainNames),
    operation("in", queryOperand("type"), ...principalTypes)
  );
};

const searchPrincipals = (
  fulltextQuery: string,
  domainNames = ["Users"],
  principalTypes = [principalType.USER, principalType.GROUP]
) => {
  const filter = andOperation(
    operation(
      "likeIgnoreCase",
      queryOperand("commonname"),
      `*${String(fulltextQuery).trim()}*`
    ),
    getPrincipalSearchOperation(domainNames, principalTypes)
  );

  const query = select(["commonname", "principalid", "email"])
    // .where({
    //   commonname: likeIgnoreCase(`*${String(fulltextQuery).trim()}*`),
    //   domainname: isIn(domainNames),
    //   type: isIn(principalTypes),
    //   // active: true,
    //   // type: isEqual(position ? 2 : 1), // sould be 2
    // })
    .filter(filter)
    .orderBy({ commonname: "asc" })
    .toString();

  return requestUsers(query).then(({ items }) =>
    Promise.resolve(parsePrincipalItems(items))
  );
};

const getPrincipalsByIds = (
  principalIds: string[],
  domainNames = ["Users"],
  principalTypes = [principalType.USER, principalType.GROUP]
) => {
  const filter = andOperation(
    operation("in", queryOperand("principalid"), ...principalIds),
    getPrincipalSearchOperation(domainNames, principalTypes)
  );

  const query = select(["commonname", "principalid", "email"])
    .filter(filter)
    // .where({
    //   principalid: isIn(principalIds),
    //   domainname: isIn(domainNames),
    //   type: isIn(principalTypes),
    //   // type: isEqual(position ? 2 : 1), // sould be 2
    // })
    .toString();

  return requestUsers(query).then(({ items }) =>
    Promise.resolve(parsePrincipalItems(items))
  );
};

const domainApi = {
  searchPrincipals,
  getPrincipalsByIds,
};

export default domainApi;
