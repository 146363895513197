import { useReducer } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { LoadingOverlay } from "cosmos-components";
import { principalType } from "cosmos-config/generator";
import useCoreSelector from "../hooks/useCoreSelector";
import useProject from "../hooks/useProject";
import EmailSelect from "./form/EmailSelect";
import { useEffect } from "react";
import {
  addProjectMember,
  createProjectMember,
  inviteProjectMember,
} from "../actions/projectActions";
import useCoreDispatch from "../hooks/useCoreDispatch";

const MemberAction = {
  ADD: "add",
  INVITE: "invite",
  CREATE: "create",
};

const actions = {
  RESET: "member/reset",
  UPDATE_USER: "member/updateUser",
  UPDATE_USER_IDENTIFIER: "member/updateUserIdentifier",
  SET_ACTION: "member/setAction",
};

const initialState = {
  valid: false,
  action: MemberAction.ADD,
  user: {},
  userIdentifier: null,
  activeTabKey: MemberAction.ADD,
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.RESET:
      return {
        ...initialState,
      };
    case actions.UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          [action.name]: action.value,
        },
      };
    case actions.UPDATE_USER_IDENTIFIER:
      return {
        ...state,
        userIdentifier: action.principalId || action.email,
        valid: action.principalId != null || /^(.+)@(.+)$/.test(action.email),
        action:
          action.principalId != null ? MemberAction.ADD : MemberAction.INVITE,
        activeTabKey: MemberAction.ADD,
      };
    case actions.SET_ACTION:
      return {
        ...state,
        action: action.action,
        activeTabKey: action.action,
        user: {},
        userIdentifier: null,
      };
    default:
      return state;
  }
};

const GroupAddMemberModal = ({
  show,
  groupName,
  domainName,
  allowInsert,
  onClose,
}) => {
  const dispatch = useCoreDispatch();
  const { projectName } = useProject();

  const [localState, localDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localDispatch({
      type: actions.RESET,
    });
  }, [show]);

  const loading = useCoreSelector((state) => state.project.loading);

  const handleSubmit = () => {
    const callback = () => {
      onClose(localState.userIdentifier);
      localDispatch({
        type: actions.RESET,
      });
    };

    switch (localState.action) {
      default:
      case MemberAction.ADD:
        dispatch(
          addProjectMember({
            groupName: groupName,
            principalId: localState.userIdentifier,
          })
        ).then(callback);
        break;
      case MemberAction.INVITE:
        dispatch(
          inviteProjectMember({
            email: localState.userIdentifier,
            domainName: domainName,
            groupName: groupName,
          })
        ).then(callback);
        break;
      case MemberAction.CREATE:
        dispatch(
          createProjectMember({
            user: localState.user,
            domainName: domainName,
            groupName: groupName,
          })
        ).then(callback);
        break;
    }
  };

  const renderEmailSelect = () => {
    return (
      <EmailSelect
        inline
        domainNames={[domainName]}
        principalType={principalType.POSITION}
        onSelect={({ value, id }) => {
          const [v] = value;
          if (id != null || allowInsert) {
            localDispatch({
              type: actions.UPDATE_USER_IDENTIFIER,
              principalId: id,
              email: v,
            });
          }
        }}
      />
    );
  };

  // const renderModalContent = () => {
  //   if (allowInsert) {
  //     return (
  //       <Tab.Container
  //         activeKey={localState.activeTabKey}
  //         onSelect={(key) => {
  //           console.log("setting action");
  //           localDispatch({
  //             type: actions.SET_ACTION,
  //             action: key,
  //           });
  //         }}
  //       >
  //         <Modal.Header className="pb-0 border-bottom-0 p-0 pt-3">
  //           <Nav variant="tabs" className="w-100">
  //             <Nav.Item>
  //               <Nav.Link eventKey="add">Add/Invite Member</Nav.Link>
  //             </Nav.Item>
  //             <Nav.Item>
  //               <Nav.Link eventKey="create">Create User</Nav.Link>
  //             </Nav.Item>
  //           </Nav>
  //         </Modal.Header>
  //         <Modal.Body>
  //           <Tab.Content className="overflow-hidden flex-grow-1">
  //             <LoadingOverlay loading={loading}>
  //               <Tab.Pane eventKey="add">{renderEmailSelect()}</Tab.Pane>
  //               <Tab.Pane eventKey="create">
  //                 <SimpleUniversalForm
  //                   properties={userCreateProperties}
  //                   resource={localState.user}
  //                   onUpdateResource={(id, name, value) => {
  //                     localDispatch({
  //                       type: actions.UPDATE_USER,
  //                       name,
  //                       value,
  //                     });
  //                   }}
  //                 />
  //               </Tab.Pane>
  //             </LoadingOverlay>
  //           </Tab.Content>
  //         </Modal.Body>
  //       </Tab.Container>
  //     );
  //   }

  //   return (
  //     <>
  //       <Modal.Header>
  //         <Modal.Title>
  //           <span className="mr-2">Add Member to</span>
  //           {projectName}
  //         </Modal.Title>
  //         <CloseButton className="close-icon" onClick={() => onClose()} />
  //       </Modal.Header>
  //       <Modal.Body>{renderEmailSelect()}</Modal.Body>
  //     </>
  //   );
  // };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header>
        <Modal.Title>
          <span className="mr-2">Add Member to</span>
          {projectName}
        </Modal.Title>
        <CloseButton className="close-icon" onClick={() => onClose()} />
      </Modal.Header>
      <Modal.Body>
        <LoadingOverlay loading={loading}>{renderEmailSelect()}</LoadingOverlay>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right">
          <Button
            className="mr-2"
            onClick={() => handleSubmit()}
            disabled={
              !localState.valid || localState.action !== MemberAction.INVITE
            }
          >
            Invite
          </Button>
          <Button
            onClick={() => handleSubmit()}
            disabled={
              localState.userIdentifier == null ||
              localState.action !== MemberAction.ADD
            }
          >
            Add Member
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

GroupAddMemberModal.propTypes = {
  show: PropTypes.bool,
  groupName: PropTypes.string,
  onClose: PropTypes.func,
  domainName: PropTypes.string,
  allowInsert: PropTypes.bool,
};

GroupAddMemberModal.defaultProps = {
  show: false,
  groupName: null,
  onClose: () => {},
  domainName: "Users",
  allowInsert: false,
};

export default GroupAddMemberModal;
