import React, { useCallback, useReducer, useRef } from "react";
import ProjectCommandContext from "../contexts/ProjectCommandContext";
import useCoreDispatch from "../hooks/useCoreDispatch";
import { requestProjectAccess } from "../actions/projectActions";
import useCoreSelector from "../hooks/useCoreSelector";
import { getProjects } from "../selectors/projectSelector";
import { useCurrentUser } from "../hooks";

export const commandState = {
  PENDING: "project/pending",
  ADDING_PROJECT_MEMBER: "project/addingMember",
  IMPORTING_PROJECT_MEMBER: "project/importingMembers",
  REQUESTING_PROJECT_ACCESS: "project/requestingAccess",
  EDITING_PROJECT_GROUP: "project/editGroup",
  CREATING_PROJECT_GROUP: "project/createGroup",
};

const actions = {
  ADD_PROJECT_MEMBER: "project/addMember",
  IMPORT_PROJECT_MEMBERS: "project/importMembers",
  REQUEST_PROJECT_ACCESS: "project/requestAccess",
  RESET: "project/reset",
  EDIT_PROJECT_GROUP: "project/editGroup",
  CREATE_PROJECT_GROUP: "project/creategroup",
};

interface ProjectProviderState {
  activity: string;
  groupName: string | null;
  domainName: string;
  projectCode: string | null;
}

const initialState = {
  activity: commandState.PENDING,
  groupName: null,
  domainName: "Users",
  projectCode: null,
} as ProjectProviderState;

interface ProjectProviderAction {
  type: string;
  payload?: any;
}

const reducer = (
  state: ProjectProviderState,
  action: ProjectProviderAction
) => {
  switch (action.type) {
    case actions.ADD_PROJECT_MEMBER:
      return {
        ...state,
        activity: commandState.ADDING_PROJECT_MEMBER,
        groupName: action.payload.groupName,
        domainName: action.payload.domainName || "Users",
      };
    case actions.IMPORT_PROJECT_MEMBERS:
      return {
        ...state,
        activity: commandState.IMPORTING_PROJECT_MEMBER,
        groupName: action.payload.groupName,
        domainName: action.payload.domainName || "Users",
      };
    case actions.REQUEST_PROJECT_ACCESS:
      return {
        ...state,
        activity: commandState.REQUESTING_PROJECT_ACCESS,
        projectCode: action.payload.projectCode,
      };
    case actions.CREATE_PROJECT_GROUP:
      return {
        ...state,
        activity: commandState.CREATING_PROJECT_GROUP,
        groupName: action.payload.parentGroupName,
        domainName: action.payload.domainName,
      };
    case actions.EDIT_PROJECT_GROUP:
      return {
        ...state,
        activity: commandState.EDITING_PROJECT_GROUP,
        groupName: action.payload.groupName,
        domainName: action.payload.domainName,
      };
    case actions.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export interface ProjectCommandProviderProps {
  children: React.ReactNode;
}

const ProjectCommandProvider = ({ children }: ProjectCommandProviderProps) => {
  const dispatch = useCoreDispatch();
  const [localState, localDispatch] = useReducer(reducer, initialState);
  const completetionCallbackRef = useRef<Function | null | undefined>(null);

  const { userData } = useCurrentUser();

  const projects = useCoreSelector(getProjects);

  const getModalProps = useCallback(
    (...state: string[]) => {
      return {
        show: state.includes(localState.activity),
        onClose: (...args: any[]) => {
          if (completetionCallbackRef?.current) {
            completetionCallbackRef.current(...args);
          }

          localDispatch({
            type: actions.RESET,
          });
        },
      };
    },
    [localState.activity]
  );

  function getCompletionPromise<T>() {
    return new Promise<T>((resolve) => {
      completetionCallbackRef.current = (arg: T) => {
        resolve(arg);
      };
    });
  }

  return (
    <ProjectCommandContext.Provider
      value={{
        getAddProjectMemberModalProps: () => {
          return {
            ...getModalProps(commandState.ADDING_PROJECT_MEMBER),
            groupName: localState.groupName,
            domainName: localState.domainName,
            allowInsert: localState.domainName === "ExternalUsers",
          };
        },
        getImportProjectMembersModalProps: () => {
          return {
            ...getModalProps(commandState.IMPORTING_PROJECT_MEMBER),
            groupName: localState.groupName,
          };
        },
        getRequestProjectAccessModalProps: () => {
          return {
            ...getModalProps(commandState.REQUESTING_PROJECT_ACCESS),
            onRequest: (accessRequest) => {
              dispatch(
                requestProjectAccess({
                  projectCode: localState.projectCode,
                  department: userData?.department,
                  options: {
                    accessRequest,
                  },
                })
                //@ts-ignore
              ).then(() => {
                localDispatch({
                  type: actions.RESET,
                });
              });
            },
            project: projects.find((p) => p.code === localState.projectCode),
          };
        },
        getGroupEditModalProps: () => {
          return {
            ...getModalProps(
              commandState.EDITING_PROJECT_GROUP,
              commandState.CREATING_PROJECT_GROUP
            ),
            groupName: localState.groupName,
            domainName: localState.domainName,
          };
        },
        addProjectMember: (groupName: string, domainName: string) => {
          if (groupName != null) {
            localDispatch({
              type: actions.ADD_PROJECT_MEMBER,
              payload: {
                groupName,
                domainName: domainName || "Users",
              },
            });

            return getCompletionPromise<string>();
          }

          return Promise.reject();
        },
        importProjectMembers: (groupName: string, domainName: string) => {
          if (groupName != null) {
            localDispatch({
              type: actions.IMPORT_PROJECT_MEMBERS,
              payload: {
                groupName,
                domainName: domainName || "Users",
              },
            });

            return getCompletionPromise<void>();
          }

          return Promise.reject();
        },
        requestProjectAccess: useCallback((projectCode: string) => {
          localDispatch({
            type: actions.REQUEST_PROJECT_ACCESS,
            payload: {
              projectCode,
            },
          });

          return getCompletionPromise<void>();
        }, []),
        editProjectGroup: useCallback(
          (groupName: string, domainName: string) => {
            localDispatch({
              type: actions.EDIT_PROJECT_GROUP,
              payload: {
                groupName,
                domainName,
              },
            });

            return getCompletionPromise<string>();
          },
          []
        ),
        createProjectGroup: useCallback(
          (parentGroupName?: string, domainName?: string) => {
            localDispatch({
              type: actions.CREATE_PROJECT_GROUP,
              payload: {
                parentGroupName,
                domainName,
              },
            });

            return getCompletionPromise<string>();
          },
          []
        ),
      }}
    >
      {children}
    </ProjectCommandContext.Provider>
  );
};

export default ProjectCommandProvider;
