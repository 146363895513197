import { useCallback, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useProject from "./useProject";
import projectApi from "../apis/projectApi";
import { ProjectQuery } from "../modules/dashboard/utils";

const useProjectQueries = () => {
  const queryClient = useQueryClient();
  const { project } = useProject();
  const projectCode = useMemo(() => project?.code, [project]);

  const queryKey = useMemo(
    () => ["project-queries", projectCode],
    [projectCode]
  );

  const { data, isLoading } = useQuery<ProjectQuery[]>({
    queryKey,
    queryFn: () => projectApi.getQueries(projectCode || ""),
    enabled: projectCode != null,
  });

  const saveMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (query: ProjectQuery) => {
      if (projectCode == null) {
        throw new Error("Project code must be specified!");
      }

      return projectApi.createRepositoryQuery(projectCode, query.query);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  return {
    loading: isLoading,
    queries: useMemo(() => data || [], [data]),
    saveQuery: useCallback(
      (query: ProjectQuery) => {
        return saveMutation.mutateAsync(query);
      },
      [saveMutation]
    ),
  };
};

export default useProjectQueries;
