export const FETCH_VALUESETS = "docarea/fetchValuesets";
export const FETCH_VALUESET = "docarea/fetchValueset";
export const ADD_ITEM_TO_VALUESET = "docarea/addItemToValueset";
export const REPLACE_ALL_VALUES = "docarea/replaceAllValues";
export const REMOVE_CUSTOM_VALUSET_ITEMS = "docarea/removeCustomValuesetItems";
export const UPDATE_CUSTOM_VALUESET_ITEMS = "docarea/updateCustomValuesetItems";
export const IMPORT_CUSTOM_VALUESET_FILE = "docarea/importCustomValuesetFile";
export const RELOAD_VALUESET = "docarea/reloadValueset";
export const EXPORT_VALUESET_FILE = "docarea/exportValuesetFile";
export const FETCH_AGGREGATE_VALUES = "docarea/fetchAggregateValues";
export const FETCH_MASTER_VALUESETS = "docarea/fetchMasterValuesets";
export const SAVE_MASTER_VALUESET_ITEM = "docarea/saveMasterValuesetItem";
export const ADD_CUSTOM_VALUESET_ITEM = "docarea/addCustomValuesetItem";
export const EXPORT_VALUESETS_EXCEL = "docarea/exportValuesetsExcel";
