import React, { useState } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import SimpleUniversalForm from "../../../../components/SimpleUniversalForm";
import projectGroupCreate from "../../constants/projectGroupCreate";
import { resourceCorrectionForGateway } from "cosmos-config/utils";
import { PropertyEditorModalProps } from "../../types";
import { Group } from "cosmos-config/lib/group/group";

export interface CreateGroupModalProps extends PropertyEditorModalProps {
  onSubmit?: (group: Group) => void;
}

const CreateGroupModal = ({
  show,
  onClose,
  onSubmit,
}: CreateGroupModalProps) => {
  const [group, setGroup] = useState({});

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Create Group</Modal.Title>
        <CloseButton className="close-icon" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <SimpleUniversalForm
          inline
          properties={projectGroupCreate}
          resource={group}
          onUpdateResource={(id, propertyName, value) => {
            setGroup((g) => ({
              ...g,
              [propertyName]: value,
            }));
          }}
          onSubmit={() => {
            if (onSubmit != null) {
              onSubmit(
                resourceCorrectionForGateway(group, projectGroupCreate) as Group
              );
            }
          }}
        >
          {({ submit }) => (
            <div className="text-right">
              <Button onClick={submit}>Submit</Button>
            </div>
          )}
        </SimpleUniversalForm>
      </Modal.Body>
    </Modal>
  );
};

export default CreateGroupModal;
