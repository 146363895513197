import * as actions from './types/import';

/**
 * Import action generators.
 * @module actions/import
 * @category Actions
 */

/**
 * Action for exporting folders template.
 * @function
 * @param {string} folderType - type of the folder
 */
export const exportFoldersTemplate = (folderType) => ({
  type: actions.EXPORT_FOLDERS_TEMPLATE,
  folderType,
});

/**
 * Action for importing folders Excel.
 * @function
 * @param {string} foldertype - type of importing folder
 * @param {} files -
 */
export const importFoldersExcel = (foldertype, files) => ({
  type: actions.IMPORT_FOLDERS_EXCEL,
  foldertype,
  files,
});

/**
 * Action for exporting documents template.
 * @function
 * @param {string} subfolderPropertyName -
 */
export const exportDocumentsTemplate = (subfolderPropertyName) => ({
  type: actions.EXPORT_DOCUMENTS_TEMPLATE,
  subfolderPropertyName,
});

/**
 * Action for importing document excel.
 * @function
 * @param {} files -
 * @param {object} options - additional options
 * @param {} options.subfolderType -
 * @param {} options.subfolderPropertyName -
 * @param {} options.resourceId -
 * @param {} options.createStubs -
 * @param {} options.fileNameResolve -
 * @param {} options.multiContent -
 * @param {} options.skipTemplate -
 */
export const importDocumentsExcel = (files, options) => ({
  type: actions.IMPORT_DOCUMENTS_EXCEL,
  payload: {
    files,
    options: {
      subfolderType: options.subfolderType,
      subfolderPropertyName: options.subfolderPropertyName,
      resourceId: options.resourceId,
      createStubs: options.createStubs,
      fileNameResolve: options.fileNameResolve,
      multiContent: options.multiContent,
      skipTemplate: options.skipTemplate,
    },
  },
});

/**
 * Action for adding files to import.
 * @function
 * @param {} files -
 */
export const addFilesToImport = (files) => ({
  type: actions.ADD_FILES_TO_IMPORT,
  files,
});

/**
 * Action for unzipping currently to be imported files
 */
export const unzipFilesToImport = () => ({
  type: actions.UNZIP_FILES_TO_IMPORT,
});

/**
 * Action for setting the current unzipping loading state
 * @function
 * @param isUnzipping
 */
export const setIsUnzipping = (isUnzipping) => ({
  type: actions.SET_IS_UNZIPPING,
  isUnzipping,
});

/**
 * Action for removing files from import.
 * @param filesToRemove
 * @returns {{payload: {filesToRemove}, type: object[]}}
 */
export const removeFilesFromImport = (
  filesToRemove,
  callback = ({ fileName, filePath }) => {}
) => ({
  type: actions.REMOVE_FILES_FROM_IMPORT,
  payload: {
    filesToRemove,
    callback,
  },
});

/**
 * Action for setting import data.
 * @function
 * @param {} importData -
 * @param {} importIssues -
 */
export const setImportData = (importData = [], importIssues = []) => ({
  type: actions.SET_IMPORT_DATA,
  importData,
  importIssues,
});

/**
 * Action for executing import.
 * @function
 */
export const executeImport = () => ({
  type: actions.EXECUTE_IMPORT,
});

/**
 * Action to cancel import.
 * @function
 */
export const cancelImport = () => ({
  type: actions.CANCEL_IMPORT,
});

/**
 * Action to reload import.
 * @function
 */
export const reloadImport = () => ({
  type: actions.RELOAD_IMPORT,
});

/**
 * Action to clean import.
 * @function
 */
export const cleanImport = () => ({
  type: actions.CLEAN_IMPORT,
});

/**
 * Action for updating import column mapping.
 * @function
 * @param {string} columnName - name of the column
 * @param {string} propertyName - name of the property
 */
export const updateImportColumnMapping = (columnName, propertyName) => ({
  type: actions.UPDATE_IMPORT_COLUMN_MAPPING,
  payload: {
    columnName,
    propertyName,
  },
});

/**
 * Action for updating import values mapping.
 * @function
 * @param {string} propertyName -
 * @param {} value -
 * @param {string} label -
 */
export const updateImportValueMapping = (propertyName, value, label) => ({
  type: actions.UPDATE_IMPORT_VALUE_MAPPING,
  payload: {
    propertyName,
    value,
    label,
  },
});

export const setImportComplete = (stats) => ({
  type: actions.SET_IMPORT_COMPLETE,
  payload: {
    stats,
  },
});

export const updateImportStats = (importId, data) => ({
  type: actions.UPDATE_IMPORT_STATS,
  payload: {
    importId,
    data,
  },
});

export const generateExcelStatsReport = () => ({
  type: actions.GENERATE_EXCEL_STATS_REPORT,
});

export const downloadLog = () => ({
  type: actions.DOWNLOAD_LOG,
});

export const setCurrentSchema = (schema) => ({
  type: actions.SET_CURRENT_SCHEMA,
  payload: {
    schema,
  },
});

export const addLogActivity = (type, message) => ({
  type: actions.ADD_LOG_ACTIVITY,
  payload: {
    type,
    message,
  },
});
