import { CloseButton, Modal } from "react-bootstrap";
import PropertySelector from "../property-editor/PropertySelector";
import { PropertyEditorModalProps } from "../../types";
import { Property } from "cosmos-config/lib/property/property";
import React from "react";
import { Group } from "cosmos-config/lib/group/group";

export interface SelectPropertyModalProps extends PropertyEditorModalProps {
  properties?: (Property | Group)[];
  onSelect?: (propertyName: string) => void;
}

const SelectPropertyModal = ({
  show,
  onClose,
  properties,
  onSelect,
}: SelectPropertyModalProps) => {
  return (
    <Modal show={show} size="xl" onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Add Property</Modal.Title>
        <CloseButton className="close-icon" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <PropertySelector onSelect={onSelect} properties={properties} />
      </Modal.Body>
    </Modal>
  );
};

export default SelectPropertyModal;
