import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faStream } from "@fortawesome/free-solid-svg-icons";
import DocumentIcon from "../../DocumentIcon";
import _ from "lodash";
import { Row } from "react-table";
import Resource from "../../../types/resource";

declare type FileExtensionCellProps = {
  row: Row<Resource>;
};

const FileExtensionCell = React.memo(
  ({ row }: FileExtensionCellProps) => {
    if (row.original.virtual) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon={faFolder} color="orange" />
        </div>
      );
    }

    if (row.original.resourcetype === 1) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon={faStream} color="grey" />
        </div>
      );
    }

    return (
      <DocumentIcon document={row.original} className="text-center d-block" />
    );
  },
  (prevProps, nextProps) => {
    const idPath = "row.original.id";
    return _.isEqual(_.get(prevProps, idPath), _.get(nextProps, idPath));
  }
);

FileExtensionCell.displayName = "FileExtensionCell";

export default FileExtensionCell;
