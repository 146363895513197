import React, { isValidElement, useMemo, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import PropTypes from "prop-types";
import useClickOutside from "../../hooks/useClickOutside";
import { uuidv4 } from "cosmos-config/utils";
import { Placement } from "@popperjs/core";

declare type PancoPopoverProps = {
  title?: string;
  text?: any;
  placement?: Placement;
  children: React.ReactElement;
};

const PancoPopover = ({
  title,
  text,
  placement,
  children,
}: PancoPopoverProps) => {
  const [show, setShow] = useState(false);
  const popoverId = useMemo(() => uuidv4(), []);
  const target = useRef<HTMLDivElement>(null);

  useClickOutside(target, () => {
    setShow(false);
  });

  const renderText = () => {
    if (!text) {
      return "";
    }

    if (typeof text === "function") {
      return text.apply();
    }

    if (isValidElement(text)) {
      return text;
    }

    return text
      .split("\n")
      .map((str: string, idx: number) => <p key={`par-${idx}`}>{str}</p>);
  };

  const popover = (
    <Popover id={popoverId}>
      <Popover.Title as="h3">
        {title}
        {/* <CloseButton onClick={() => setShow(false)} /> */}
      </Popover.Title>
      <Popover.Content>{renderText()}</Popover.Content>
    </Popover>
  );

  return (
    <div ref={target}>
      <OverlayTrigger
        trigger="click"
        placement={placement}
        overlay={popover}
        onToggle={(s) => setShow(s)}
        show={show}
      >
        {children}
      </OverlayTrigger>
    </div>
  );
};

PancoPopover.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  placement: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

PancoPopover.defaultProps = {
  text: "",
  title: "",
  placement: null,
  children: [],
};

export default PancoPopover;
