import React, { useCallback, useMemo, useState } from "react";
import RepositoryFolderContext, {
  DuplicateAnalyzeResult,
} from "../contexts/RepositoryFolderContext";
import { useMutation } from "@tanstack/react-query";
import Resource from "../types/resource";
import repositoryFolderApi from "../apis/repositoryFolderApi";
import {
  ResourceProperty,
  prepareResourceForGateway,
} from "../utils/resourcePrepUtils";
import useResource from "../hooks/useResource";
import { progressiveChecksum } from "../utils/cryptoUtils";
import repositorySearchApi from "../apis/repositorySearchApi";
import { operation, queryOperand, select } from "cosmos-config/nql";
import _ from "lodash";
import UploadAdvisor from "../providers/UploadAdvisor";
import { getFileIdentifier } from "../utils/fileUtils";
import useCurrentUser from "../hooks/useCurrentUser";
import resourceApi from "../apis/resourceApi";

export interface RepositoryFolderProviderProps {
  resourceId?: string;
  children?: React.ReactNode;
}

export type CreateDocumentMutationParam = {
  resource?: Partial<Resource>;
  files?: File[];
  onProgress?: (loadedBytes: number) => void;
};

export type DeleteResourcesMutationParam = {
  resourceIds?: string[];
  revert?: boolean;
};

const RepositoryFolderProvider = ({
  resourceId,
  children,
}: RepositoryFolderProviderProps) => {
  const { properties } = useResource({ resourceId });
  const { userData } = useCurrentUser();

  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);

  const createDocumentMutation = useMutation({
    mutationFn: ({
      resource,
      files,
      onProgress,
    }: CreateDocumentMutationParam) => {
      if (resourceId == null) {
        throw new Error("");
      }

      let resourceProperties: ResourceProperty[] = [];

      if (resource != null) {
        resourceProperties = prepareResourceForGateway(
          resource,
          properties,
          true,
          userData
        );
      }

      return repositoryFolderApi.createDocument(
        resourceId,
        files || [],
        resourceProperties,
        onProgress
      );
    },
  });

  const deleteDocumentMutation = useMutation({
    mutationFn: ({ resourceIds, revert }: DeleteResourcesMutationParam) => {
      if (resourceIds == null || resourceIds.length <= 0) {
        throw new Error("Resource IDs cannot be null!");
      }

      const mutationFunction = revert
        ? resourceApi.revertDeleteResources
        : resourceApi.deleteResources;

      return mutationFunction(resourceIds);
    },
  });

  const checkDuplicates = useCallback(
    async (files: File[]) => {
      if (resourceId == null) {
        throw new Error("Resource id cannot be null!");
      }

      const promises = files.map((file) =>
        progressiveChecksum(file).then((checksum) => ({
          fileName: file.name,
          fileIdentifier: getFileIdentifier(file),
          checksum,
        }))
      );

      const filesData = await Promise.all(promises);

      const localDuplicates = _(filesData)
        .xorBy(_.uniqBy(filesData, "checksum"), "fileIdentifier")
        .map(
          (fileData): DuplicateAnalyzeResult => ({
            ...fileData,
            level: "error",
          })
        )
        .value();

      const filter = operation(
        "exists",
        operation(
          "in",
          queryOperand("itemhash"),
          ..._.map(filesData, "checksum")
        )
      );
      const query = select(["displayname", "itemhash", "identifier"])
        .filter(filter)
        .subtree(true)
        .toString();

      const { items } = await repositorySearchApi.queryFolderSearch(
        resourceId,
        query
      );

      const resources = items.map((i) =>
        _(i.properties).keyBy("name").mapValues("value").value()
      );

      const results = _.flatMap(filesData, (fileData) => {
        const issues = resources
          .filter((i) => i.itemhash.includes(fileData.checksum))
          .map(
            (i): DuplicateAnalyzeResult => ({
              ...fileData,
              identifier: i.identifier,
              resourceName: i.displayname,
              level: i.itemhash.length > 1 ? "warn" : "error",
            })
          );

        if (issues.length === 0) {
          const infoResult: DuplicateAnalyzeResult = {
            ...fileData,
            level: "info",
          };

          return [infoResult];
        }

        return issues;
      });

      return Promise.resolve(_.concat(results, localDuplicates));
    },
    [resourceId]
  );

  return (
    <RepositoryFolderContext.Provider
      value={{
        resourceId: resourceId || null,
        loading: useMemo(
          () =>
            createDocumentMutation.isPending ||
            deleteDocumentMutation.isPending ||
            downloadInProgress,
          [
            createDocumentMutation.isPending,
            deleteDocumentMutation.isPending,
            downloadInProgress,
          ]
        ),
        createDocument: (resource, files, onProgress) => {
          return createDocumentMutation.mutateAsync({
            resource,
            files,
            onProgress,
          });
        },
        checkDuplicates,
        deleteDocuments: (resourceIds, revert) => {
          return deleteDocumentMutation.mutateAsync({
            resourceIds,
            revert,
          });
        },
        downloadDocuments: (resourceIds: string[]) => {
          setDownloadInProgress(true);
          return resourceApi.downloadDocuments(resourceIds).then((response) => {
            const { data, contentType, fileName } = response;

            const blob = new Blob([data], { type: contentType });

            return Promise.resolve({
              contentUrl: URL.createObjectURL(blob),
              fileName,
            }).finally(() => {
              setDownloadInProgress(false);
            });
          });
        },
      }}
    >
      <UploadAdvisor>{children}</UploadAdvisor>
    </RepositoryFolderContext.Provider>
  );
};

export default RepositoryFolderProvider;
