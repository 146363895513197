import { useEffect, useMemo, useState } from "react";
import useProject from "../../hooks/useProject";
import ProjectProperties from "./components/ProjectProperties";
import ProjectEditorContext from "./contexts/ProjectEditorContext";

import projectApi from "./apis/project.api";
import _ from "lodash";

const ProjectEditor = () => {
  const { project, reload, loadTemplates, reloadSpec } = useProject();

  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);

  const projectCode = useMemo(() => project?.code, [project]);

  useEffect(() => {
    loadTemplates().then((groups) => {
      const parsedGroups = _(groups)
        .map((p) => {
          if (p.group) {
            return p.build();
          }

          return p;
        })
        .value();
      setTemplates(parsedGroups);
    });
  }, [projectCode, loadTemplates]);

  return (
    <ProjectEditorContext.Provider
      value={{
        loadingProject: loading,
        projectCode,
        updateProject: (project) => {
          setLoading(true);
          projectApi
            .updateProjectSpec(projectCode, project)
            .then(reloadSpec)
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
        createFolder: (folder) => {
          setLoading(true);
          projectApi
            .createFolder(projectCode, folder)
            .then(reload)
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
        templates,
      }}
    >
      <ProjectProperties />
    </ProjectEditorContext.Provider>
  );
};

export default ProjectEditor;
