import Axios from 'axios';
import api from './conf/sharepointApi';

const getUserdata = () => {
  return api.get('/me').then((response) => Promise.resolve(response.data));
};

const getUserJoinedTeams = () => {
  const properties = ['id', 'displayName', 'description'];
  return api
    .get(`/me/joinedTeams?$select=${properties.join()}`)
    .then((response) => Promise.resolve(response.data.value));
};

const searchSites = (query) => {
  const properties = ['id', 'displayName', 'description'];
  return api
    .get(`/sites?&$select=${properties.join()}&search=${query}`)
    .then((response) => Promise.resolve(response.data.value));
};

const getUserDrives = () =>
  api
    .get('/me/drives')
    .then((response) => Promise.resolve(response.data.value));

const getDriveFiles = (driveId) =>
  api
    .get(`/drives/${driveId}/items/root/children`)
    .then((response) => Promise.resolve(response.data.value));

const getUserFiles = (relativePath) => {
  const url =
    relativePath != null
      ? `/me/drive/root:${relativePath}:/children`
      : `/me/drive/root/children`;

  return api.get(url).then((response) => Promise.resolve(response.data.value));
};

const searchInDrive = (driveId, searchQuery) =>
  api
    .get(`/drives/${driveId}/root/search(q='${searchQuery}')`)
    .then((response) => Promise.resolve(response.data.value));

const getDriveItem = (driveId, itemId) =>
  api
    .get(`/drives/${driveId}/items/${itemId}`)
    .then((response) => Promise.resolve(response.data));

const getFileContent = (driveId, itemId) =>
  getDriveItem(driveId, itemId).then((item) => {
    const contentUrl = item['@microsoft.graph.downloadUrl'];
    const contentType = item.file.mimeType;

    return Axios.get(contentUrl, { responseType: 'arraybuffer' }).then(
      ({ data }) => {
        const blob = new Blob([data], { type: contentType });
        return Promise.resolve(blob);
      }
    );
  });

const searchInUserFiles = (searchQuery) =>
  api
    .get(`/me/drive/search(q='${searchQuery}')?select=*`)
    .then((response) =>
      Promise.resolve(response != null ? response.data.value : [])
    );

const getUserEmails = () =>
  api
    .get('/me/messages')
    .then((response) => Promise.resolve(response.data.value));

const getDriveItemChildren = (driveId, itemId) => {
  const relativeUrl = itemId == null ? 'root' : `items/${itemId}`;
  const properties = ['id', 'name', 'file', 'folder'];

  return api
    .get(
      `/drives/${driveId}/${relativeUrl}/children?$select=${properties.join()}`
    )
    .then((response) => Promise.resolve(response.data.value || []));
};

const getDrives = () =>
  api.get('/drives').then((response) => Promise.resolve(response.data.value));

const getGroupDrive = (groupId) => {
  const properties = ['id', 'name', 'owner', 'driveType'];

  return api
    .get(`/groups/${groupId}/drive?$select=${properties.join()}`)
    .then((response) => Promise.resolve(response.data));
};

const getSiteDrive = (siteId) => {
  const properties = ['id', 'name', 'owner', 'driveType'];

  return api
    .get(`/sites/${siteId}/drive?$select=${properties.join()}`)
    .then((response) => Promise.resolve(response.data));
};

const getUserMemberOf = () =>
  api
    .get(
      `/me/memberOf/microsoft.graph.group?$select=id` //&$expand=owners($select=mail)`
    )
    .then((response) => Promise.resolve(response.data.value));

const searchDriveItems = (driveId, itemId, query = '') => {
  const properties = ['id', 'name', 'parentReference', 'file'];

  return api
    .get(
      `/drives/${driveId}/items/${itemId}/search(q='${query}')?$select=${properties.join()}`
    )
    .then((response) => Promise.resolve(response.data.value));
};

export default {
  getFileContent,
  searchSites,
  getUserJoinedTeams,
  getUserdata,
  getUserDrives,
  getDriveFiles,
  getUserFiles,
  searchInDrive,
  searchInUserFiles,
  getUserEmails,
  getDriveItem,
  getDriveItemChildren,
  getDrives,
  getGroupDrive,
  getSiteDrive,
  getUserMemberOf,
  searchDriveItems,
};
