import { property } from "cosmos-config/generator";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import SimpleTable from "../../../components/SimpleTable";
import LocaleEditorContext from "../contexts/LocaleEditorContext";
import { ContextMenu } from "cosmos-components";
import { TranslationItem, TranslationScope } from "../types";

const CustomInput = styled.div`
  margin: 0;
  border: 0;
  width: 100%;
`;

interface EditableCellProps {
  value?: string;
  onUpdate?: (value: string) => void;
}

const EditableCell = ({ value, onUpdate }: EditableCellProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elementRef.current != null) {
      elementRef.current.innerText = value != null ? value : "";
    }
  }, [value]);

  const handleBlur = () => {
    if (onUpdate != null && elementRef.current != null) {
      const value = _.trim(elementRef.current.innerText);
      onUpdate(value);
    }
  };

  return (
    <CustomInput
      contentEditable
      ref={elementRef}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        if (e.keyCode === 13 && elementRef.current != null) {
          e.preventDefault();
          elementRef.current.blur();
        }
      }}
    />
  );
};

export interface TranslationTableProps {
  data?: TranslationScope;
  onUpdate?: (language: string, key: string, translation: string) => void;
  onDelete?: (translationKeys: string[]) => void;
  className?: string;
}

const TranslationTable = ({
  data,
  onUpdate,
  onDelete,
  className,
}: TranslationTableProps) => {
  const { languages } = useContext(LocaleEditorContext);

  const rows = useMemo(() => {
    return _(data)
      .map((value, key) => ({
        key,
        ...value,
      }))
      .value();
  }, [data]);

  const properties = useMemo(() => {
    return [
      property("Key", "key"),
      ...languages.map((l) => property(_.capitalize(l), l)),
    ];
  }, [languages]);

  const renderMenu = (selectedTranslations: TranslationItem[]) => {
    const atLeastOneSelected = selectedTranslations.length > 0;

    return (
      <>
        <ContextMenu.Item
          disabled={!atLeastOneSelected}
          onClick={() => {
            if (onDelete != null) {
              onDelete(
                selectedTranslations.map((translation) => translation.key)
              );
            }
          }}
        >
          Delete
        </ContextMenu.Item>
      </>
    );
  };

  return (
    <SimpleTable<TranslationItem>
      className={className}
      properties={properties}
      data={rows}
      renderContextMenu={renderMenu}
      renderCell={(language, value, label, row) => {
        if (language === "key") {
          return <b>{label}</b>;
        }

        return (
          <EditableCell
            value={value}
            onUpdate={(translation) => {
              if (onUpdate != null) {
                const { key } = row;
                onUpdate(language, key, translation);
              }
            }}
          />
        );
      }}
    />
  );
};

export default TranslationTable;
