import React, { useState } from "react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import UnauthorizedError from "../apis/conf/UnauthorizedError";
import sessionSlice from "../slices/sessionSlice";
import useCoreDispatch from "../hooks/useCoreDispatch";

export type QueryClientInjectorProps = {
  children?: React.ReactNode;
};

const QueryClientInjector = ({ children }: QueryClientInjectorProps) => {
  const dispatch = useCoreDispatch();

  const [queryClient] = useState<QueryClient>(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
        },
      },
      queryCache: new QueryCache({
        onError: (error) => {
          if (error instanceof UnauthorizedError) {
            dispatch(sessionSlice.actions.invalidate());
          }
        },
      }),
      mutationCache: new MutationCache({
        onError: (error) => {
          if (error instanceof UnauthorizedError) {
            dispatch(sessionSlice.actions.invalidate());
          }
        },
      }),
    })
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryClientInjector;
