import { useState } from "react";
import { Alert, Button, CloseButton, Form, Modal } from "react-bootstrap";
import { LoadingOverlay } from "cosmos-components";
import useProject from "../../../hooks/useProject";
import useCoreDispatch from "../../../hooks/useCoreDispatch";
import useCoreSelector from "../../../hooks/useCoreSelector";
import { importProjectMembers } from "../../../actions/projectActions";
import projectSlice from "../../../slices/projectSlice";
import PropTypes from "prop-types";

const GroupImportMembersModal = ({ show, groupName, onClose }) => {
  const dispatch = useCoreDispatch();
  const { projectName } = useProject();
  const loading = useCoreSelector((state) => state.project.loading);
  const importValidationErrors = useCoreSelector(
    (state) => state.project.import.validationErrors
  );

  const [file, setFile] = useState();

  const handleClose = () => {
    setFile(null);
    dispatch(projectSlice.actions.setImportValidationErrors([]));
    onClose();
  };

  const handleImportExcel = async (e) => {
    e.preventDefault();

    if (file) {
      dispatch(
        importProjectMembers({
          groupName: groupName,
          file: file,
        })
      ).then(() => {
        if (!importValidationErrors.length) {
          handleClose();
        }
      });
    }
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header>
        <Modal.Title>
          <span className="mr-2">Add Member to</span>
          {projectName}
        </Modal.Title>
        <CloseButton className="close-icon" onClick={() => handleClose()} />
      </Modal.Header>
      <Modal.Body>
        <LoadingOverlay loading={loading}>
          <Form>
            <Form.File
              multiple={false}
              accept=".xls, .xlsx"
              onChange={(e) => {
                dispatch(projectSlice.actions.setImportValidationErrors([]));
                setFile(e.target.files[0]);
              }}
            />
          </Form>
        </LoadingOverlay>
        {importValidationErrors.map((error) => (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          disabled={!file || importValidationErrors.length}
          onClick={handleImportExcel}
        >
          Import Members
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

GroupImportMembersModal.propTypes = {
  show: PropTypes.bool,
  groupName: PropTypes.string,
  onClose: PropTypes.func,
};

export default GroupImportMembersModal;
