/* eslint-disable func-names */
import { call, getContext, select, spawn, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';
import repositoryApi from '../../Api/repository';
import * as actions from '../../Actions/types';
import callApi from '../Effects/callApi';
import { query as parseQuery } from 'cosmos-config/nql';
import { getProjectQueries } from '../../Selectors/project';
import { resourceCorrectionOfNscale } from 'cosmos-config/utils';

export default function* (projectCode, folderId) {
  yield takeEvery(
    actions.repository.PROJECT_QUERY_RUN,
    function* ({ queryId, callback }) {
      const queries = yield select(getProjectQueries);
      const queriesMap = _(queries).keyBy('id').value();

      const query = queriesMap[queryId];

      if (query?.query != null) {
        const projectService = yield getContext('projectService');
        const properties = yield call(projectService.getProperties);

        try {
          const parsedQuery = parseQuery(query.query);
          const updatedQuery = parsedQuery.select(
            properties.map((p) => p.query)
          );

          const { results } = parsedQuery;

          // const apiFunction = results.some((r) => r.type === 'FUNCTION')
          //   ? repositoryApi.aggregateSearchDocuments
          //   : repositoryApi.queryFolderSearch;

          let items = [];
          if (results.some((r) => r.type === 'FUNCTION')) {
            const res = yield callApi(
              repositoryApi.aggregateSearchDocuments,
              folderId,
              updatedQuery,
              projectCode
            );
            items = res.items;
          } else {
            const res = yield callApi(
              repositoryApi.queryFolderSearch,
              folderId,
              updatedQuery,
              null,
              projectCode
            );
            items = res.items;
          }

          const resources = resourceCorrectionOfNscale(items, properties);

          yield spawn(callback, resources);
        } catch (err) {
          console.error(err);
        }
      }
    }
  );
}
