export const FETCH_SYSTEM_MESSAGES = 'system-messages/fetch';
export const CREATE_SYSTEM_MESSAGE = 'system-message/create';
export const UPDATE_SYSTEM_MESSAGE = 'system-message/update';
export const DELETE_SYSTEM_MESSAGE = 'system-message/delete';

export const FETCH_PROJECT_MESSAGES = 'FETCH_PROJECT_MESSAGES';
export const SET_PROJECT_MESSAGES = 'SET_PROJECT_MESSAGES';
export const DELETE_PROJECT_MESSAGE = 'DELETE_PROJECT_MESSAGE';
export const CREATE_PROJECT_MESSAGE = 'CREATE_PROJECT_MESSAGE';
export const UPDATE_PROJECT_MESSAGE = 'UPDATE_PROJECT_MESSAGE';
export const CREATE_PROJECT_CONTACT_MESSAGE = 'CREATE_PROJECT_CONTACT_MESSAGE';
