import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import useMaintainerPermission from "./useMaintainerPermission";
import useResourcePermissions from "./useResourcePermissions";
import useCurrentUser from "../useCurrentUser";
import Resource from "../../types/resource";

/**
 * @module hooks/useFolderPermissions
 * @category Hooks
 */

export interface UseFolderPermissionsValue {
  /**
   * Whether current user can create a document in the folder(s).
   * @returns Whether permission is granted.
   */
  canCreateDocument: () => boolean;

  /**
   * Whether current user can create a sub folder in the folder(s).
   * @returns Whether permission is granted.
   */
  canCreateFolder: () => boolean;

  /**
   * Whether current user can delete the folder(s).
   * @returns Whether permission is granted.
   */
  canDelete: () => boolean;

  /**
   * Whether current user can undelete the folder(s).
   * @returns Whether permission is granted.
   */
  canUndelete: () => boolean;

  /**
   * Whether current user can read properties of the folder(s).
   * @returns Whether permission is granted.
   */
  canReadProperties: () => boolean;

  /**
   * Whether current user can write metadata of the folder(s).
   * @returns Whether permission is granted.
   */
  canWriteProperties: () => boolean;

  /**
   * Whether current user can start a workflow of the folder(s)
   * @returns Whether permission is granted.
   */
  canStartWorkflow: () => boolean;

  /**
   * Whether current user has administrator rights over the folder(s).
   * @returns Whether permission is granted.
   */
  isAdministrator: () => boolean;

  /**
   * Whether current user can export metadata of folder(s).
   * @returns Whether permission is granted.
   */
  canExport: () => boolean;

  /**
   * Whether current user can create a reference in the folder(s).
   * @returns Whether permission is granted.
   */
  canCreateReference: () => boolean;

  /**
   * Whether current user can execute import within the folder(s).
   * @returns Whether permission is granted.
   */
  canImport: () => boolean;
  canControl: () => boolean;
  isPrivateResource: () => boolean;
  isMaintainer: () => boolean;
  isTeamMember: () => boolean;
}

/**
 * Hook providing permissions to the provided folder of current user.
 *
 * @param input - Folder resource or list of folders
 * @returns
 */
const useFolderPermissions = (
  input?: Resource | Resource[]
): UseFolderPermissionsValue => {
  const [objectclassNames, setObjectclassNames] = useState<string[]>([]);
  const { permissions } = useCurrentUser();

  const { isMaintainer, canResourcePermission, isPrivateResource } =
    useResourcePermissions(input);

  const folders = useMemo(() => {
    if (Array.isArray(input)) {
      return input;
    }

    return _.compact([input]);
  }, [input]);

  const { canControl } = useMaintainerPermission(folders);

  useEffect(() => {
    setObjectclassNames((oldObjectclassNames) => {
      const newObjectclassNames = folders
        .filter((x) => x != null && x.resourcetype === 1)
        .map((d) => d.objectclass as string)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

      return _.isEqual(oldObjectclassNames.sort(), newObjectclassNames.sort())
        ? oldObjectclassNames
        : newObjectclassNames;
    });
  }, [folders]);

  const hidden = useMemo(
    () =>
      folders
        .filter((x) => x != null)
        .map((d) => d.hidden)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        }),
    [folders]
  );

  const permissionObject = useMemo(() => {
    const prms = _.compact(
      objectclassNames
        .filter((x) => x != null && x !== "")
        .map<Record<string, any>>((ocn) => {
          return permissions.folderPermissions?.[ocn];
        })
    );

    return prms.reduce((acc, cur) => {
      return Object.entries(cur).reduce((innerAcc, [key, value]) => {
        return {
          ...innerAcc,
          [key]: acc[key] && value,
        };
      }, {});
    }, prms[0]);
  }, [objectclassNames, permissions.folderPermissions]);

  const allAreFolders = useMemo(
    () => folders.every((f) => f != null && f.resourcetype === 1),
    [folders]
  );

  const isDeleted = useCallback(() => hidden.every((c) => !!c), [hidden]);

  const can = useCallback(
    (permission: string): boolean =>
      permissionObject != null
        ? allAreFolders && permissionObject[permission]
        : false,
    [permissionObject, allAreFolders]
  );

  const canCustom = useCallback(
    (permission: string): boolean => {
      if (
        permissionObject != null &&
        permissionObject.customPermissions != null &&
        Array.isArray(permissionObject.customPermissions)
      ) {
        return permissionObject.customPermissions.includes(permission);
      }

      return false;
    },
    [permissionObject]
  );

  const canUpdate = useCallback(
    (): boolean => canResourcePermission("update") && canControl(),
    [canControl, canResourcePermission]
  );

  return {
    canCreateDocument: () =>
      can("createDocument") &&
      (canControl() || canCustom("administrator")) && // || isMaintainer()) &&
      canResourcePermission("create"),
    canCreateFolder: () => can("createFolder") && canUpdate(),
    canDelete: () =>
      can("delete") && (canCustom("administrator") || isMaintainer()),
    canUndelete: () => isDeleted() && can("delete"),
    canReadProperties: () =>
      can("readProperties") && canResourcePermission("read"),
    canWriteProperties: () => can("writeProperties") && canUpdate(),
    canStartWorkflow: useCallback(
      () => canCustom("startworkflow"),
      [canCustom]
    ),
    isAdministrator: () => canCustom("administrator"),
    canExport: () => canCustom("export"),
    canCreateReference: () => can("createLink") && canUpdate(),
    canImport: () => canCustom("import"),
    canControl,
    isPrivateResource,
    isMaintainer,
    isTeamMember: () => canResourcePermission("member"),
  };
};

export default useFolderPermissions;
