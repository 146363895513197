import React, { useState } from "react";
import { Button, Modal, CloseButton, Form } from "react-bootstrap";
import { LoadingOverlay, PancoArea } from "cosmos-components";

declare type PermissionRequestModalProps = {
  show?: boolean;
  onClose?: () => void;
  onRequestPermission?: (reason?: string) => void;
  loading?: boolean;
};

const PermissionRequestModal = ({
  show,
  onClose,
  onRequestPermission,
  loading,
}: PermissionRequestModalProps) => {
  const [reason, setReason] = useState<string | undefined>();

  const handleClose = () => {
    if (onClose != null) {
      onClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Request a Permission</Modal.Title>
        <CloseButton onClick={handleClose} className="close-icon" />
      </Modal.Header>
      <Modal.Body>
        <LoadingOverlay loading={loading}>
          <p>
            Please let us know what is the reason to grant you a permission to
            the document.
          </p>

          <Form.Group>
            <Form.Label>Reason:</Form.Label>
            <PancoArea
              onChange={({ value }) => {
                setReason(value || undefined);
              }}
            />
          </Form.Group>
        </LoadingOverlay>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right">
          <Button
            disabled={loading}
            onClick={() => {
              if (onRequestPermission != null) {
                onRequestPermission(reason);
              }
            }}
          >
            Request a Permission
          </Button>
          <Button className="ml-2" variant="link" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PermissionRequestModal;
