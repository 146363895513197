import { faSave } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowDown,
  faArrowUp,
  faCheck,
  faEllipsis,
  faLock,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useContext, useMemo, useState } from "react";
import { Table, Button, Dropdown } from "react-bootstrap";
import { propertyType } from "cosmos-config/generator";
import _ from "lodash";
import ProjectPropertyForm from "./ProjectPropertyForm";
import ProjectEditorPropertiesContext from "../contexts/ProjectEditorPropertiesContext";
import styled, { useTheme } from "styled-components";
import ResourceTypeContext from "../contexts/ResourceTypeContext";
import propertyTypeIconMap from "../constants/propertyTypeIconMap";
import { Property } from "cosmos-config/lib/property/property";
import { ConditionalProperty } from "cosmos-config/lib/property/conditionalProperty";

type PropertyColumn = {
  label: string;
  mask: Partial<Property>;
  disabled?: (property: Property) => boolean;
  system?: boolean;
};

const propColumns: PropertyColumn[] = [
  {
    label: "Editable",
    mask: {
      editable: true,
    },
  },
  {
    label: "Mandatory",
    mask: {
      required: true,
    },
  },
  {
    label: "Filter",
    mask: {
      filterable: true,
    },
  },
  {
    label: "Sortable",
    mask: {
      sortable: true,
    },
    disabled: (p: Property) => !p.tableColumn,
  },
  {
    label: "Preset",
    mask: {
      usePreset: true,
    },
  },
  {
    label: "Inform",
    mask: {
      information: true,
    },
  },
  {
    label: "Insertable",
    mask: {
      addingOptionsAllowed: true,
    },
    disabled: (p: Property) =>
      ![propertyType.SELECT, propertyType.MEMBER_SELECT].includes(p.type),
  },
];

const NoArrowDropdownToggle = styled(Dropdown.Toggle)`
  &:after {
    display: none;
  }
`;

const negateMask = (mask: Partial<Property>) => {
  return _(mask)
    .map((value, key) => ({
      key,
      value: _.isBoolean(value) ? !value : null,
    }))
    .mapKeys("key")
    .mapValues("value")
    .value();
};

type PropertyRowProps = {
  property: Property;
  renderPositionButton: (down: boolean) => React.ReactNode;
  renderPositioningOptions: () => React.ReactNode;
  resourceType: 1 | 2;
};

const PropertyRow = ({
  property,
  renderPositionButton,
  renderPositioningOptions,
  resourceType,
}: PropertyRowProps) => {
  const theme = useTheme();
  const { updateProperty, deleteProperty, canManipulate } = useContext(
    ProjectEditorPropertiesContext
  );

  const { changeType, moveProperty, editProperty } =
    useContext(ResourceTypeContext);

  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <tr
        key={property.name}
        style={{
          backgroundColor: !collapsed ? "lightyellow" : theme.background,
        }}
      >
        <td>{renderPositionButton(true)}</td>
        <td>{renderPositionButton(false)}</td>
        <td>{renderPositioningOptions()}</td>
        <td style={{ verticalAlign: "middle" }}>
          <FontAwesomeIcon
            icon={propertyTypeIconMap[property.type]}
            size="sm"
            className={classNames({
              "text-info": (property as ConditionalProperty).conditional,
            })}
          />
        </td>
        <td style={{ verticalAlign: "middle" }}>
          <div
            className="d-flex align-items-center h-100"
            onClick={() => {
              setCollapsed((c) => !c);
              // editProperty(property.name);
            }}
            role="button"
            style={{
              userSelect: "none",
            }}
          >
            <span
              className={classNames({
                "font-weight-bold": !collapsed,
                "text-muted": property.disabled,
              })}
            >
              {property.label}
              {property.protectedProperty && (
                <FontAwesomeIcon
                  className="ml-2 text-danger"
                  icon={faLock}
                  size="xs"
                />
              )}
            </span>

            <small className="ml-2 text-muted ml-auto">({property.name})</small>
          </div>
        </td>
        {propColumns.map((c) => {
          const getDisabled = () => {
            const disabledByDefinition =
              c.disabled != null ? c.disabled(property) : false;
            return (
              c.system || property.protectedProperty || disabledByDefinition
            );
          };

          const positive = _.isMatch(property, c.mask);
          const disabled = getDisabled();

          return (
            <td className="text-center" style={{ width: "75px" }}>
              {collapsed && (
                <Button
                  disabled={disabled}
                  variant="link"
                  className={classNames({
                    "text-warning": !positive && !disabled,
                    "text-success": positive && !disabled,
                  })}
                  onClick={() => {
                    const propertyToUpdate = {
                      ...(positive ? negateMask(c.mask) : c.mask),
                      resourceType,
                    };

                    updateProperty(property.name, propertyToUpdate);
                  }}
                >
                  <FontAwesomeIcon icon={positive ? faCheck : faTimes} />
                </Button>
              )}
            </td>
          );
        })}
        <td>
          {canManipulate() && (
            <Dropdown>
              <NoArrowDropdownToggle variant="link">
                <FontAwesomeIcon icon={faEllipsis} />
              </NoArrowDropdownToggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  disabled={property.protectedProperty}
                  onClick={() => changeType(property.name)}
                >
                  Change Type
                </Dropdown.Item>
                <Dropdown.Item onClick={() => moveProperty(property.name)}>
                  Move
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => deleteProperty(property.name)}
                  disabled={property.protectedProperty}
                  className="text-danger"
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </td>
      </tr>
      {!collapsed && (
        <tr style={{}}>
          <td colSpan={13} className="p-0">
            <ProjectPropertyForm
              property={property}
              onUpdate={() => setCollapsed(true)}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export interface PropertiesTableProps {
  properties: Property[];
  resourceType: 1 | 2;
  onSelect: (propertyName: string) => void;
}

type ArtificialPosition = {
  propertyName: string;
  after: string;
};

const PropertiesTable = ({
  properties,
  resourceType,
}: PropertiesTableProps) => {
  const { moveProperty } = useContext(ProjectEditorPropertiesContext);
  const [artificialPosition, setArtificialPosition] =
    useState<ArtificialPosition | null>(null);

  const sortedProperties = useMemo(() => {
    if (artificialPosition == null) {
      return properties;
    }

    const { propertyName, after } = artificialPosition;
    const propertiesMap = _.keyBy(properties, "name");

    const toSort = properties.filter((p) => p.name !== propertyName);
    const property = propertiesMap[propertyName];

    if (after == null) {
      return [property, ...toSort];
    }

    const leftSide = _.takeWhile(toSort, (p) => p.name !== after);
    const rightSide = _.takeRightWhile(toSort, (p) => p.name !== after);
    const afterProperty = propertiesMap[after];

    return _.compact(_.concat(leftSide, afterProperty, property, rightSide));
  }, [properties, artificialPosition]);

  return (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th style={{ width: 30 }}></th>
          <th style={{ width: 30 }}></th>
          <th></th>
          <th style={{ width: 30 }}></th>
          <th>Label</th>
          {propColumns.map((c) => (
            <th className="text-center">{c.label}</th>
          ))}
          <th style={{ width: 30 }}></th>
        </tr>
      </thead>
      <tbody>
        {sortedProperties.map((childProp, idx, arr) => (
          <PropertyRow
            key={childProp.name}
            property={childProp}
            resourceType={resourceType}
            renderPositionButton={(down) => {
              const apName = artificialPosition?.propertyName;
              const disabled = !(apName === childProp.name || apName == null);

              const direction = down ? 1 : -2;
              const afterProp = arr[idx + direction];

              if ((down && idx + 1 < arr.length) || (!down && idx > 0)) {
                return (
                  <Button
                    disabled={disabled}
                    variant="link"
                    onClick={() => {
                      setArtificialPosition({
                        propertyName: childProp.name,
                        after: afterProp?.name,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={down ? faArrowDown : faArrowUp} />
                  </Button>
                );
              }
            }}
            renderPositioningOptions={() => {
              if (artificialPosition?.propertyName === childProp.name) {
                return (
                  <>
                    <Button
                      variant="link"
                      onClick={() => setArtificialPosition(null)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <Button
                      variant="link"
                      onClick={() => {
                        setArtificialPosition(null);
                        moveProperty(childProp.name, artificialPosition.after);
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  </>
                );
              }
            }}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default PropertiesTable;
