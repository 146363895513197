import { getContext, call } from 'redux-saga/effects';

export function* addURLtoResources(resources) {
    const projectService = yield getContext('projectService');
    const project = yield call(projectService.getOpenedProject);
  
    const getResourceUrl = (resource) => {
      let parts = [];
  
      const projectCode = project?.code;
      if (projectCode != null) {
        parts = [...parts, 'module', projectCode];
      }
  
      switch (resource.resourcetype) {
        case 1:
          parts = [...parts, 'folder', resource.identifier];
          break;
        case 2:
          parts = [...parts, 'document', resource.identifier];
          break;
        default:
          break;
      }
  
      return window.location.origin + '/' + parts.join('/');
    };
  
    return {
      resourcesWithUrls: resources.map((r) => ({
        ...r,
        url: getResourceUrl(r),
      })),
      project
    };
  }