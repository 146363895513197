import { useEffect, useState, createElement as h, useCallback } from "react";
import {
  PancoSelectGeneric,
  PancoSelectGenericProps,
  PancoSelectOnChangeParam,
  PancoSelectOption,
} from "cosmos-components";
import useDebounce from "../../hooks/useDebounce";
import useDomain from "../../hooks/useDomain";
import useCoreSelector from "../../hooks/useCoreSelector";
import { CosmosCoreRootState } from "../../store";

interface EmailSelectOption extends PancoSelectOption {
  instant: boolean;
  id?: string;
}

export interface EmailSelectProps
  extends Omit<PancoSelectGenericProps<string, EmailSelectOption>, "onSelect"> {
  principalType: 1 | 2;
  onSelect: (
    param: PancoSelectOnChangeParam<string, EmailSelectOption>
  ) => void;
  domainNames: string[];
}

const EmailSelect = ({
  value,
  principalType,
  onSelect,
  onChange,
  domainNames,
  ...props
}: EmailSelectProps) => {
  const [searchQuery, setSearchQuery] = useState(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 200);
  const [options, setOptions] = useState<EmailSelectOption[]>([]);
  const loading = useCoreSelector<CosmosCoreRootState, boolean>(
    (state) => state.usermanagement.loading
  );

  const { searchPrincipals } = useDomain(domainNames);

  useEffect(() => {
    if (debouncedSearchQuery != null && debouncedSearchQuery !== "") {
      if (onChange != null) {
        onChange({ value: [debouncedSearchQuery] });
      }

      searchPrincipals(debouncedSearchQuery, principalType, (usrs) => {
        setOptions((opts) => {
          return [
            ...opts.filter((o) => o.instant),
            ...usrs.map((u) => ({
              label: u.commonname,
              value: u.email || "",
              id: u.principalid,
              instant: false,
            })),
          ];
        });
      });
    }
  }, [debouncedSearchQuery, principalType, searchPrincipals]);

  const handleFilter = useCallback((filter: any) => {
    setSearchQuery(filter);

    setOptions((opts) => {
      const regularOptions = opts.filter((o) => !o.instant);

      if (
        filter != null &&
        regularOptions.findIndex((o) => o.label === filter) === -1
      ) {
        return [
          { label: filter, value: filter, instant: true },
          ...regularOptions,
        ];
      }

      return opts;
    });
  }, []);

  return h(PancoSelectGeneric<string, EmailSelectOption>, {
    ...props,
    onFilter: handleFilter,
    manualFilter: true,
    editableFilter: true,
    onChange: onSelect,
    loading,
    options,
    value,
  });
};

EmailSelect.defaultProps = {
  domainNames: ["Users"],
};

export default EmailSelect;
