import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ResourceReminderContext from "../contexts/ResourceReminderContext";
import React, { useMemo } from "react";
import {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from "cosmos-config/utils";
import remindersApi from "../apis/remindersApi";
import reminderProperties from "../constants/reminderProperties";
import { Reminder } from "../constants/reminder";
import Resource from "../../../types/resource";
import { LoadingOverlay } from "cosmos-components";
import { useCurrentUser } from "../../../hooks";

export interface ReminderProviderProps {
  resource?: Resource;
  resourceName?: string;
  children?: React.ReactNode;
}

const ReminderProvider = ({
  resource,
  resourceName,
  children,
}: ReminderProviderProps) => {
  const queryClient = useQueryClient();
  const { authenticated } = useCurrentUser();

  const resourceId = useMemo(() => resource?.id, [resource]);
  const queryKey = useMemo(() => ["reminders", resourceId], [resourceId]);

  const resourceType = useMemo(() => resource?.resourcetype, [resource]);

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => remindersApi.getReminders(resourceId || ""),
    enabled: resourceId != null && authenticated,
  });

  const reminders = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.map((reminder: Reminder) =>
      resourceCorrectionOfGateway(reminder, reminderProperties)
    );
  }, [data]);

  const saveMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (reminder: Reminder) => {
      if (resourceId == null) {
        throw new Error("Resource id not specified!");
      }

      if (reminder.id == null) {
        return remindersApi.createReminder(resourceId, reminder);
      } else {
        return remindersApi.updateReminder(resourceId, reminder.id, reminder);
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const cancelMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (reminderId: string) => {
      if (resourceId == null) {
        throw new Error("Resource id not specified!");
      }

      return remindersApi.deleteReminder(resourceId, reminderId);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const loading = useMemo(
    () => isLoading || saveMutation.isPending || cancelMutation.isPending,
    [cancelMutation.isPending, isLoading, saveMutation.isPending]
  );

  return (
    <ResourceReminderContext.Provider
      value={{
        loading,
        reminders,
        resourceName,
        resourceType: resourceType || null,
        save: (reminder) => {
          const correctReminder = resourceCorrectionForGateway(
            reminder,
            reminderProperties
          );
          return saveMutation.mutateAsync(correctReminder as Reminder);
        },
        cancel: (reminder) => {
          if (reminder.id == null) {
            return Promise.resolve("Not real reminder.");
          }

          return cancelMutation.mutateAsync(reminder.id);
        },
      }}
    >
      <LoadingOverlay loading={loading}>{children}</LoadingOverlay>
    </ResourceReminderContext.Provider>
  );
};

export default ReminderProvider;
