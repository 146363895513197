import React, { useMemo } from "react";
import _ from "lodash";
import useProperties from "../../../hooks/useProperties";
import useQueryWidgets from "../hooks/useQueryWidgets";
import GenericDashboard from "./GenericDashboard";
import { CosmosWidgetOnSelectType } from "../types/CosmosWidget";
import { OnOpenResourceCallback } from "../../../types/callbacks";

export interface FolderDashboardProps {
  resourceId?: string;
  filter?: Record<string, any>;
  onOpenResource?: OnOpenResourceCallback;
  onFilterChange?: (propertyName: string, value: any) => void;
}

const FolderDashboard = ({
  resourceId,
  filter,
  onOpenResource,
  onFilterChange,
}: FolderDashboardProps) => {
  const { widgets } = useProperties();

  const { queryWidgets } = useQueryWidgets();

  const documentWidgets = useMemo(
    () => widgets.filter((w) => w.resourceType === 2),
    [widgets]
  );

  const handleSelect = (
    type: CosmosWidgetOnSelectType,
    value: any,
    propertyName?: string
  ) => {
    if (type === "document") {
      if (onOpenResource != null) {
        onOpenResource(2, value);
      }
    } else if (type === "filterset") {
      // dispatch(selectFilterset(value));
    } else if (type === "filter") {
      if (
        value != null &&
        value !== "" &&
        onFilterChange != null &&
        propertyName != null
      ) {
        onFilterChange(propertyName, value);
      }
    } else if (type === "process") {
      // navigate(`${routes.PROCESS_ROUTE}/${value}`);
    }
  };

  return (
    <GenericDashboard
      resourceId={resourceId}
      onSelect={handleSelect}
      filter={filter}
      widgets={useMemo(
        () => _.concat(documentWidgets, queryWidgets),
        [documentWidgets, queryWidgets]
      )}
    />
  );
};

export default FolderDashboard;
