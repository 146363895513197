import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import AccessManagementContext from "../contexts/AccessManagementContext";
import ExtendedMemberSelect from "../../../components/form/ExtendedMemberSelect";

export interface AccessGrantInputProps {
  permissionsPrincipalIds: string[];
  disabled?: boolean;
  className?: string;
}

const AccessGrantInput = ({
  permissionsPrincipalIds,
  disabled,
  className,
}: AccessGrantInputProps) => {
  const { grantPermission } = useContext(AccessManagementContext);
  const [principalIds, setPrincipalIds] = useState<string[]>([]);
  const { t } = useTranslation("module");

  const alreadyPermitted = permissionsPrincipalIds.some((principalId) =>
    principalIds.includes(principalId)
  );

  return (
    <div className={classNames("d-flex", className)}>
      <ExtendedMemberSelect
        className="flex-grow-1"
        value={principalIds}
        onChange={({ value }) => {
          if (Array.isArray(value) && value.length > 0) {
            setPrincipalIds(value);
          }
        }}
        multiple
        disabled={disabled}
        boxed
        flattenGroups={false}
      />
      <Button
        disabled={principalIds.length === 0 || alreadyPermitted}
        onClick={() => {
          if (principalIds.length > 0) {
            grantPermission(principalIds).then(() => setPrincipalIds([]));
          }
        }}
      >
        {t("repository.grant_permission", {
          defaultValue: "Grant Permission",
        })}
      </Button>
    </div>
  );
};

AccessGrantInput.defaultProps = {
  permissionsPrincipalIds: [],
};

export default AccessGrantInput;
