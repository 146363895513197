import React, { createContext, useCallback, useMemo } from "react";
import RootCommentEntry from "./RootCommentEntry";
import _ from "lodash";
import Resource from "../../../types/resource";
import { Comment } from "../../../apis/commentApi";
import { DateTime } from "luxon";

export type PostCommentOptions = {
  nodeId: string | null;
  parameter: string | null;
  mentions: string[];
};

export interface CommentsListContextValue {
  focusComment: (commentId: string) => void;
  postComment: (
    resourceId: string,
    message: string,
    options: PostCommentOptions
  ) => Promise<void>;
  updateComment: (commentId: string, comment: Comment) => Promise<void>;
  documents: Resource[];
  disabled: boolean;
}

export const CommentsListContext = createContext<CommentsListContextValue>({
  focusComment: () => {},
  postComment: () => Promise.reject("Method not implemented!"),
  updateComment: () => Promise.reject("Method not implemented!"),
  documents: [],
  disabled: false,
});

export interface CommentsListProps {
  comments: Comment[];

  onFocusComment?: (commentId: string) => void;
  onPostComment?: (
    resourceId: string,
    message: string,
    options: PostCommentOptions
  ) => void;
  onUpdateComment?: (commentId: string, comment: Comment) => void;
  documents?: Resource[];
  disabled?: boolean;
  commentClassName?: string;
}

const CommentsList = ({
  comments,
  onFocusComment,
  onPostComment,
  onUpdateComment,
  documents,
  disabled,
  commentClassName,
}: CommentsListProps) => {
  const sortedComments = useMemo(() => {
    return _.orderBy(
      comments,
      [
        "position.pageNumber",
        "position.y",
        (c) => {
          if (c.createdAt == null) {
            return "";
          }

          return DateTime.fromMillis(c.createdAt).toMillis();
        },
      ],
      ["asc", "asc", "desc"]
    );
  }, [comments]);

  return (
    <div className="position-relative">
      <div className="comments-list">
        <CommentsListContext.Provider
          value={{
            focusComment: useCallback(
              (commentId) => {
                if (onFocusComment != null) {
                  onFocusComment(commentId);
                }
              },
              [onFocusComment]
            ),
            postComment: useCallback(
              (resourceId, message, options) => {
                if (onPostComment != null) {
                  onPostComment(resourceId, message, options);
                  return Promise.resolve();
                }

                return Promise.reject("Method not implemented!");
              },
              [onPostComment]
            ),
            updateComment: useCallback(
              (commentId, comment) => {
                if (onUpdateComment != null) {
                  onUpdateComment(commentId, comment);
                  return Promise.resolve();
                }

                return Promise.reject("Method not implemented!");
              },
              [onUpdateComment]
            ),
            documents: documents || [],
            disabled: !!disabled,
          }}
        >
          {sortedComments.map((comment, idx) => (
            <RootCommentEntry
              key={comment.id}
              comment={comment}
              defaultCollapsed={idx !== 0}
              className={commentClassName}
            />
          ))}
        </CommentsListContext.Provider>
      </div>
    </div>
  );
};

CommentsList.defaultProps = {
  parameter: null,
};

export default CommentsList;
