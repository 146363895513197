import { Property } from "cosmos-config/lib/property/property";
import {
  getOpenedProject,
  getProjectMembers,
  getProjects,
} from "../selectors/projectSelector";
import store from "../store";
import { TableColumn } from "../providers/ColumnsProvider";
import { Folder } from "cosmos-config/lib/group/folder";
import { Group } from "cosmos-config/lib/group/group";
import workflowProperties from "../contants/worklow-properties";
import defaultTask from "../contants/worklow-properties/defaultTask";
import Resource from "../types/resource";

let properties: Property[] = [];
let folderProperties: Property[] = [];
let folders: Folder[] = [];
let taskHistoryProperties: Property[] = [];
let tableColumns: TableColumn<Resource>[] = [];
let workflows: any[] = [];
let businessProcesses: any[] = [];
let businessProcessPropertiesMap: Record<string, Group[]> = {};

const projectService = {
  getMembers: () => {
    const state: any = store.getState();
    return getProjectMembers(state);
  },
  getProjects: () => {
    const state = store.getState();
    return getProjects(state);
  },
  getOpenedProject: () => {
    const state = store.getState();
    return getOpenedProject(state);
  },
  setProperties: (props: Property[]) => {
    properties = props;
  },
  getProperties: () => {
    return properties;
  },
  setFolderProperties: (props: Property[]) => {
    folderProperties = props;
  },
  getFolderProperties: () => {
    return folderProperties;
  },
  setFolders: (props: Folder[]) => {
    folders = props;
  },
  getFolders: () => {
    return folders;
  },
  setTaskHistoryProperties: (props: Property[]) => {
    taskHistoryProperties = props;
  },
  getTaskHistoryProperties: () => {
    return taskHistoryProperties;
  },
  setTableColumns: (columns: TableColumn<Resource>[]) => {
    tableColumns = columns;
  },
  getTableColumns: () => {
    return tableColumns;
  },
  setWorkflows: (ws: any[]) => {
    workflows = ws;
  },
  getWorkflows: () => workflows,
  setBusinessProcesses: (processes: any[]) => {
    businessProcesses = processes;
  },
  getBusinessProcesses: () => {
    return businessProcesses;
  },
  setBusinessProcessPropertiesMap: (propertiesMap: Record<string, Group[]>) => {
    businessProcessPropertiesMap = propertiesMap;
  },
  getBusinessProcessProperties: (processDefinitionKey: string) => {
    return businessProcessPropertiesMap[processDefinitionKey];
  },
  getNscaleWorkflowProperties: (processDefinitionKey: string) => {
    return workflowProperties[processDefinitionKey] != null
      ? [...defaultTask, ...workflowProperties[processDefinitionKey]]
      : defaultTask;
  },
};

export default projectService;
