import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import systemProperties from "../contants/systemProperties";
import PropertiesContext, {
  StructuresMap,
} from "../contexts/PropertiesContext";
import projectService from "../services/projectService";
import { groupType } from "cosmos-config/generator";
import taskHistoryProperties from "../contants/taskHistoryProperties";
import { buildDedicatedGroups, buildGroups, buildProperties } from "../utils";
import { Property } from "cosmos-config/lib/property/property";
import { Group } from "cosmos-config/lib/group/group";
import { Structure } from "cosmos-config/lib/structure/structure";
import { Folder } from "cosmos-config/lib/group/folder";
import { PermissionsGroup } from "cosmos-config/lib/group/permissionsGroup";
import workflowProperties from "../contants/worklow-properties";
import defaultTask from "../contants/worklow-properties/defaultTask";

const cloneProperty = (prop: Property, name: string) => ({
  ...prop,
  name,
  accessor: name,
});

const generateReferenceProperties = (
  propertiesMap: Property[],
  referenceId: string,
  referenceScope = 1
) => {
  return propertiesMap.map((prop) => {
    return cloneProperty(prop, prop.name).resolveReference(
      referenceId,
      referenceScope,
      prop.name
    );
  });
};

interface PropertiesProviderProps {
  projectProperties: Group[];
  children: React.ReactNode;
  bpPropertiesMap: Record<string, Group[]>;
}

const PropertiesProvider = ({
  projectProperties,
  children,
  bpPropertiesMap,
}: PropertiesProviderProps) => {
  const properties: Property[] = useMemo(() => {
    return _(buildProperties(projectProperties))
      .concat(systemProperties)
      .uniqBy("name")
      .value();
  }, [projectProperties]);

  useEffect(() => {
    projectService.setProperties(properties);
  }, [properties]);

  const propertiesMap = useMemo(
    () => _.keyBy(properties, "name"),
    [properties]
  );

  const structures: StructuresMap = useMemo(() => {
    return properties
      .filter((p) => Array.isArray(p.structures) && p.structures.length > 0)
      .reduce((acc, cur: Property) => {
        return cur.structures.reduce(
          (innerAcc, { type, level, resourceType }: Structure) => ({
            ...innerAcc,
            [type]: {
              ...(acc[type] || {}),
              [level]: {
                level,
                name: cur.name,
                property: cur,
                resourceType,
              },
            },
          }),
          acc
        );
      }, {} as StructuresMap);
  }, [properties]);

  const groups = useMemo(() => {
    return buildGroups(projectProperties);
  }, [projectProperties]);

  const getFolderGroups = useCallback(
    (folderType?: string): Group[] => {
      const groups = buildGroups(projectProperties, (g) =>
        g.folderGroup != null ? g.folderGroup : g
      );

      return groups.map((g) => ({
        ...g,
        properties: g.properties.map((p) => {
          const fp = p.folderProperty;

          if (
            fp == null ||
            (fp?.folderType != null &&
              fp.folderType !== folderType &&
              folderType != null)
          ) {
            return p;
          }

          return fp;
        }),
      }));
    },
    [projectProperties]
  );

  const folderGroups = useMemo(() => {
    return getFolderGroups();
  }, [getFolderGroups]);

  const permissionsGroup = useMemo(() => {
    const [permissionsGroup] = groups.filter(
      (g) => g.type === groupType.PERMISSIONS
    );
    return permissionsGroup as PermissionsGroup;
  }, [groups]);

  const folders = useMemo(() => {
    return (
      projectProperties
        .filter((prop) => {
          // @ts-ignore
          return prop.folder;
        })
        .map((f) => f as Folder) || []
    );
  }, [projectProperties]);

  useEffect(() => {
    projectService.setFolders(folders);
  }, [folders]);

  const folderProperties = useMemo(() => {
    return _(folderGroups)
      .flatMap("properties")
      .concat(systemProperties)
      .uniqBy("name")
      .value();
  }, [folderGroups]);

  const getFolderProperties = useCallback(
    (folderType: string): Property[] => {
      const groups = getFolderGroups(folderType);

      return _(groups)
        .flatMap("properties")
        .concat(systemProperties)
        .uniqBy("name")
        .value();
    },
    [getFolderGroups]
  );

  useEffect(() => {
    projectService.setFolderProperties(folderProperties);
  }, [folderProperties]);

  const extendedTaskHistoryProperties = useMemo(() => {
    const objectify = (acc: Record<string, Property>, cur: Property) => ({
      ...acc,
      [cur.name]: cur,
    });

    const folderPropertiesMap: Record<string, Property> = folderProperties
      .filter((prop) => prop.taskHistory && prop.tableColumn)
      .map((prop) => ({ ...prop, sortable: false }))
      .reduce(objectify, {});

    const documentPropertiesMap = properties
      .filter(
        (dprop) =>
          dprop.taskHistory &&
          (dprop.information || folderPropertiesMap[dprop.name] == null)
      )
      .map((prop) => ({ ...prop, sortable: false }))
      .reduce(objectify, {});

    const mergedProperties: Property[] = Object.values({
      ...folderPropertiesMap,
      ...documentPropertiesMap,
    });

    const referenceProperties = generateReferenceProperties(
      mergedProperties,
      "resourceId"
    );

    return [...taskHistoryProperties, ...referenceProperties];
  }, [folderProperties, properties]);

  useEffect(() => {
    projectService.setTaskHistoryProperties(extendedTaskHistoryProperties);
  }, [extendedTaskHistoryProperties]);

  const getBusinessProcessGroups = useCallback(
    (processDefinitionKey: string, taskDefinitionKey?: string): Group[] => {
      if (processDefinitionKey == null) {
        return [];
      }

      const groups = buildGroups(bpPropertiesMap[processDefinitionKey] || []);

      if (taskDefinitionKey != null) {
        return buildDedicatedGroups(groups, taskDefinitionKey);
      }

      return groups;
    },
    [bpPropertiesMap]
  );

  const getBusinessProcessProperties = useCallback(
    (processDefinitionKey: string, taskDefinitionKey?: string) => {
      const groups = getBusinessProcessGroups(
        processDefinitionKey,
        taskDefinitionKey
      );

      return buildProperties(groups);
    },
    [getBusinessProcessGroups]
  );

  const getNscaleWorkflowGroups = useCallback(
    (processDefinitionKey: string, taskName?: string) => {
      if (processDefinitionKey == null) {
        return [];
      }

      const groups =
        workflowProperties[processDefinitionKey] != null
          ? [...defaultTask, ...workflowProperties[processDefinitionKey]]
          : defaultTask;

      if (taskName != null) {
        return buildDedicatedGroups(groups, taskName);
      }

      return groups;
    },
    []
  );

  const getNscaleWorkflowProperties = useCallback(
    (processDefinitionKey: string, taskName?: string) => {
      const groups = getNscaleWorkflowGroups(processDefinitionKey, taskName);

      return buildProperties(groups);
    },
    [getNscaleWorkflowGroups]
  );

  const tableProperties = useMemo(() => {
    const filterTableProps = (prps: Property[]) =>
      _(prps)
        .filter((p) => !p.system && p.tableColumn)
        .uniqBy("accessor")
        .value();

    const filteredFolderProperties = filterTableProps(folderProperties);

    const folderPropertyNames = _.map(filteredFolderProperties, "name");

    const docProps = filterTableProps(properties).filter(
      (p) => p.information || !folderPropertyNames.includes(p.name)
    );

    return _(filteredFolderProperties)
      .concat(docProps)
      .uniqBy("accessor")
      .value();
  }, [folderProperties, properties]);

  return (
    <PropertiesContext.Provider
      value={{
        properties,
        tableProperties,
        propertiesMap,
        filterFolders: useMemo(
          () => properties.filter((prop) => prop.virtualFolder),
          [properties]
        ),
        structures,
        widgets: useMemo(() => {
          const folderWidgets = _(properties)
            .flatMap(_.property("folderProperty.widgets"))
            .filter({ resourceType: 1 })
            .value();

          return _(properties).flatMap("widgets").concat(folderWidgets).value();
        }, [properties]),
        groups,
        folderGroups,
        permissionsGroup,
        folders,
        folderProperties,
        extendedTaskHistoryProperties,
        getFolderGroups,
        getFolderProperties,
        getBusinessProcessGroups,
        getBusinessProcessProperties,
        getNscaleWorkflowGroups,
        getNscaleWorkflowProperties,
      }}
    >
      {children}
    </PropertiesContext.Provider>
  );
};

PropertiesProvider.propTypes = {
  projectProperties: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node,
};

PropertiesProvider.defaultProps = {
  projectProperties: [],
  children: null,
};

export default PropertiesProvider;
