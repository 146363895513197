import React, { useState } from "react";
import { InputGroup, Button, Form } from "react-bootstrap";
import { DatePicker, NumericInput, PancoSelect } from "cosmos-components";
import { Duration } from "luxon";

const ValuePickerType = {
  TEXT: "TEXT",
  DURATION: "DURATION",
  DATE: "DATE",
  NUMBER: "NUMBER",
};

export interface UniversalValuePickerProps {
  onChange?: (millis: string | number | null) => void;
  className?: string;
}

const UniversalValuePicker = ({
  onChange,
  className,
}: UniversalValuePickerProps) => {
  const [type, setType] = useState<string | null>(null);
  const [units, setUnits] = useState<string | null>(null);
  const [innerValue, setInnerValue] = useState<number | string | null>(null);

  const handleSet = () => {
    if (onChange == null) {
      return;
    }

    switch (type) {
      case ValuePickerType.DURATION:
        if (units != null) {
          onChange(Duration.fromObject({ [units]: innerValue }).toMillis());
        }
        break;
      default:
        onChange(innerValue);
        break;
    }
  };

  const renderFormControl = () => (
    <Form.Control onChange={(e) => setInnerValue(e.target.value)} />
  );

  const renderValuePicker = () => {
    if (type === ValuePickerType.TEXT) {
      return <Form.Group>{renderFormControl()}</Form.Group>;
    }

    if (type === ValuePickerType.DURATION) {
      return (
        <Form.Group>
          <InputGroup>
            {renderFormControl()}
            <InputGroup.Append>
              <PancoSelect
                placeholder="Units"
                boxed
                options={[
                  {
                    label: "Years",
                    value: "years",
                  },
                  {
                    label: "Months",
                    value: "months",
                  },
                  {
                    label: "Days",
                    value: "days",
                  },
                  {
                    label: "Hours",
                    value: "hours",
                  },
                  {
                    label: "Minutes",
                    value: "minutes",
                  },
                  {
                    label: "Seconds",
                    value: "seconds",
                  },
                ]}
                onChange={({ value }) => {
                  const [v] = value || [];
                  setUnits(v);
                }}
              />
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      );
    }

    if (type === ValuePickerType.DATE) {
      return (
        <Form.Group>
          <DatePicker
            value={innerValue as number}
            boxed
            onChange={({ value }) => setInnerValue(value)}
          />
        </Form.Group>
      );
    }

    if (type === ValuePickerType.NUMBER) {
      return (
        <Form.Group>
          <NumericInput onChange={({ value }) => setInnerValue(value)} />
        </Form.Group>
      );
    }
  };

  return (
    <div className={className}>
      <Form.Group>
        <PancoSelect
          boxed
          options={[
            {
              label: "Text",
              value: ValuePickerType.TEXT,
            },
            {
              label: "Duration",
              value: ValuePickerType.DURATION,
            },
            {
              label: "Date",
              value: ValuePickerType.DATE,
            },
            {
              label: "Number",
              value: ValuePickerType.NUMBER,
            },
          ]}
          onChange={({ value }) => {
            const [v] = value || [];
            setType(v);
          }}
        />
      </Form.Group>

      {renderValuePicker()}

      <Button block onClick={handleSet}>
        Set
      </Button>
    </div>
  );
};

export default UniversalValuePicker;
