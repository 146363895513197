/* eslint-disable no-underscore-dangle */
import conditionalElement, { ConditionalElement } from "../conditionalElement";
import { ConditionalProperty } from "../property/conditionalProperty";
import { Property } from "../property/property";
import group, { Group } from "./group";

/**
 * @interface
 * @category Group
 * @extends Group
 */
interface ConditionalGroup extends Group, ConditionalElement {
  hiddenChildrenUntil(child: Property, conditionalValue: any): ConditionalGroup;
}

/**
 * Group Generator.
 * @category Group
 * @author Patrik Pancisin
 * @class conditionalGroup
 * @classdesc Basic building block of cosmos.
 * @extends group
 * @extends conditionalElement
 * @param {string} label - The label of the group displayed in cosmos.
 * @param {string} name - The name of the group.
 */
export default (label: string, name: string): ConditionalGroup => ({
  ...group(label, name),
  ...conditionalElement(),
  hiddenChildrenUntil(child, conditionalValue) {
    this._children = [
      child,
      ...this._children.map((c) => {
        if (!(c as ConditionalProperty).conditional) {
          return {
            ...c,
            isHidden(filter: Record<string, any> = {}) {
              const hidden =
                filter == null ||
                filter[child.name.toString()] !== conditionalValue;
              return hidden;
            },
          };
        }

        return c;
      }),
    ];
    return this;
  },
});
