import * as actions from '../Actions/types';

const initialState = {
  documentMatches: {},
};

const versionImport = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case actions.versionImport.SET_DOCUMENT_MATCH:
      return {
        ...state,
        documentMatches: {
          ...state.documentMatches,
          [payload.fileIdentifier]: payload.documentMatch,
        },
      };
    case actions.versionImport.SET_DOCUMENT_MATCHES:
      return {
        ...state,
        documentMatches: payload.documentMatches,
      };
    default:
      return state;
  }
};

export default versionImport;
