import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const ENABLE_I18N = process.env.REACT_APP_ENABLE_I18N === "true";
const configProviderUrl = process.env.REACT_APP_CONFIG_PROVIDER_URL;

const supportedLanguages = ENABLE_I18N
  ? ["en", "de", "fr", "it", "es", "nl"]
  : ["en"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: supportedLanguages,
    defaultNS: "module",
    fallbackLng: "en",
    debug: false,
    saveMissing: true,
    returnEmptyString: false,

    react: {
      useSuspense: false,
    },

    backend: {
      loadPath: `${configProviderUrl}/locales/{{lng}}/{{ns}}`,
      addPath: `${configProviderUrl}/locales/add/{{lng}}/{{ns}}`,
      requestOptions: {
        cache: "no-cache",
      },
    },

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
