import Axios, { AxiosHeaders } from 'axios';
import { InternalCacheRequestConfig, buildStorage, setupCache } from 'axios-cache-interceptor';
import authInterceptor from './authInterceptor';
import errorResponseInterceptor from './errorResponseInterceptor';
import { clear, del, get, set, keys, createStore, delMany } from 'idb-keyval';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ENABLE_REQUEST_CACHING =
  process.env.REACT_APP_ENABLE_REQUEST_CACHING === 'true';
const CACHE_MAX_AGE = 2 * 60 * 60 * 1000;
const CACHE_NAME = 'cosmos-cache';

const cosmosCacheStore = createStore(CACHE_NAME, 'data');

const indexedDbStorage = buildStorage({
  async find(key) {
    const value = await get(key, cosmosCacheStore);

    if (!value) {
      return;
    }

    return value;
  },

  async set(key, value) {
    await set(key, value, cosmosCacheStore);
  },

  async remove(key) {
    await del(key, cosmosCacheStore);
  },
});

const CacheApi = {
  build(baseUrl = BASE_URL) {
    const instance = Axios.create({
      baseURL: baseUrl,
    });
    const axios = setupCache(instance, {
      methods: ['get', 'put'],
      interpretHeader: false,
      ttl: CACHE_MAX_AGE,
      storage: indexedDbStorage,
    });

    axios.interceptors.request.use(authInterceptor);
    axios.interceptors.response.use(
      null,
      errorResponseInterceptor
    );

    return axios;
  },
  removeItem(uuid: string) {
    return indexedDbStorage.remove(uuid);
  },
  clearCache(prefix: string) {
    if (prefix == null) {
      return clear(cosmosCacheStore);
    }

    return keys(cosmosCacheStore).then((keys) => {
      const filteredKeys = keys.filter((k) => {
        if (Array.isArray(prefix)) {
          return prefix.findIndex((p) => String(k).startsWith(p)) !== -1;
        }

        return String(k).startsWith(prefix);
      });

      return delMany(filteredKeys, cosmosCacheStore);
    });
  },
  options(options: Partial<InternalCacheRequestConfig>, exclude = false, cacheKey: string): InternalCacheRequestConfig {
    if (exclude || !ENABLE_REQUEST_CACHING) {
      return {
        ...options,
        cache: false,
        headers: options.headers || new AxiosHeaders(),
      }
    }

    return {
      ...options,
      id: cacheKey,
      headers: options?.headers || new AxiosHeaders(),
    };
  },
};

export default CacheApi;
