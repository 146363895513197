import _ from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { createElement as h } from "react";
import { LoadingOverlay } from "cosmos-components";
import { propertyType } from "cosmos-config/generator";
import usePropertyRender from "../../../../hooks/usePropertyRender";
import useProjectQueries from "../../../../hooks/useProjectQueries";
import useRepositoryQuery from "../../../../hooks/useRepositoryQuery";
import { ChartDataEntry } from "../../types/CosmosChart";
import Resource from "../../../../types/resource";
import DashboardContext from "../../contexts/DashboardContext";
import useQueryRunner from "../../../../hooks/useQueryRunner";

const generateAssertionKeys = (resultsCount = 5) => [
  {
    name: "No Document",
    predicate: (c: any) => c.identifier === 0,
  },
  {
    name: `Less Than ${resultsCount} documents`,
    predicate: (c: any) => c.identifier < resultsCount,
  },
  {
    name: `${resultsCount} documents`,
    predicate: (c: any) => c.identifier === resultsCount,
  },
  {
    name: `More than ${resultsCount} documents`,
    predicate: (c: any) => c.identifier > resultsCount,
  },
];

export type ChildrenParam = {
  data: ChartDataEntry[];
  keys: string[];
  keyProperty?: string;
};

export interface ProjectQueryDataProps {
  queryId?: string;
  children?: (param: ChildrenParam) => React.ReactNode;
}

const ProjectQueryChart = ({ queryId, children }: ProjectQueryDataProps) => {
  const { resourceId } = useContext(DashboardContext);

  const [keys, setKeys] = useState(["value"]);

  const { loading, queries } = useProjectQueries();
  const queriesMap = _(queries).keyBy("id").value();

  const query = useMemo(() => {
    if (queryId == null) {
      return null;
    }

    return queriesMap[queryId];
  }, [queriesMap, queryId]);

  const { columnProperties } = useRepositoryQuery({ query: query?.query });

  const { data, loading: loadingRunner } = useQueryRunner({
    resourceId,
    nqlQuery: query?.query,
  });

  const keyProperty = useMemo(() => {
    return columnProperties.filter(
      (p) => p != null && p.name !== "identifier"
    )[0];
  }, [columnProperties]);

  const derivedProperty = useMemo(() => {
    return _(columnProperties)
      .xorBy([keyProperty], "name")
      .filter((p) => p?.type === propertyType.SELECT)
      .first();
  }, [columnProperties, keyProperty]);

  const render = usePropertyRender(
    useMemo(
      () => _.compact([keyProperty, derivedProperty]),
      [keyProperty, derivedProperty]
    )
  );

  const parseResults = useCallback(
    (dataResults: Resource[]): ChartDataEntry[] => {
      if (keyProperty == null) {
        if (derivedProperty == null) {
          return [];
        }

        return dataResults.map((r, idx) => {
          const v = r[derivedProperty?.name];

          return {
            id: idx,
            label: derivedProperty?.format(v) as string,
            value: r.identifier,
            key: "",
          };
        });
      } else {
        if (query != null && query.assertResults > 0) {
          setKeys(_.map(generateAssertionKeys(query.assertResults), "name"));
        } else if (derivedProperty?.name != null) {
          setKeys(
            _(dataResults)
              .flatMap(derivedProperty?.name)
              .uniq()
              .map(
                (value) =>
                  render(derivedProperty?.name, value) || "Not Spefified"
              )
              .value()
          );
        } else {
          setKeys(["value"]);
        }

        const generateDataKeys = (items: Resource[]) => {
          if (query != null && query.assertResults > 0) {
            return _(generateAssertionKeys(query.assertResults))
              .keyBy("name")
              .mapValues((k) => _(items).countBy(k.predicate).get(0) || 0)
              .value();
          } else if (derivedProperty?.name != null) {
            return _(items)
              .keyBy(
                (item) =>
                  render(derivedProperty?.name, item[derivedProperty?.name]) ||
                  "Not Spefified"
              )
              .mapValues("identifier")
              .value();
          } else {
            return { value: items.map((i) => i.identifier)[0] };
          }
        };

        return _(dataResults)
          .groupBy(_.property(keyProperty?.name))
          .map((items, key) => {
            return {
              id: key,
              label: render(keyProperty?.name, key) || key,
              ...generateDataKeys(items),
            } as ChartDataEntry;
          })
          .value();
      }
    },
    [keyProperty, render, derivedProperty, query]
  );

  const parsedData = useMemo(() => {
    return parseResults(data);
  }, [data, parseResults]);

  return h(
    LoadingOverlay,
    { loading: loading || loadingRunner },
    children != null
      ? children({
          data: parsedData,
          keys,
          keyProperty: keyProperty?.name,
        })
      : []
  );
};

export default ProjectQueryChart;
