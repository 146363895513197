import { put, spawn } from 'redux-saga/effects';
import {
  requestDocuments,
  setRepositoryLoading,
} from '../../Actions/repository';
import callApi from '../Effects/callApi';
import resourceApi from '../../Api/resource';
import complete from '../Effects/complete';
import { notify } from '../../Actions/ui';

/**
 * Toggle documents hidden.
 *
 * @param {string[]} documentIds - list of document ids that are going to be hidden/unhidden.
 * @param {boolean} hide - Whether hide or unhide documents.
 * @param {object} action - Redux action object.
 * @param {function} action.callback - Callback when the operation is done.
 */
export default function* toggleHideDocuments(documentIds, hide = true, action) {
  let updatedDocumentsIds = [];
  try {
    yield put(setRepositoryLoading(true, 'Deleting documents'));

    if (hide) {
      yield callApi(resourceApi.deleteResources, documentIds);
    } else {
      yield callApi(resourceApi.revertResources, documentIds);
    }
  } catch (err) {
    console.error(err);
    yield put(notify(err.message, 'error'));
    return null;
  } finally {
    yield put(setRepositoryLoading(false));
  }

  if (action?.callback != null) {
    yield spawn(action?.callback, updatedDocumentsIds);
  }

  yield complete(action, updatedDocumentsIds);

  if (hide) {
    yield put(requestDocuments());
  }

  return updatedDocumentsIds;
}
