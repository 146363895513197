import { createSlice } from "@reduxjs/toolkit";

export interface UserManagementState {
  loading: boolean;
  selectedGroup: string | null;
  selectedUsers: any[];
}

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: {
    loading: false,
    selectedGroup: null,
    selectedUsers: [],
  } as UserManagementState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload == null ? true : action.payload;
    },
    selectUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    selectGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    updateSelectedUser: (state, action) => {
      const { principalId, propertyName, value } = action.payload;
      state.selectedUsers = state.selectedUsers.map((user) => {
        if (user.id === principalId) {
          return {
            ...user,
            [propertyName]: value,
          };
        }
        return user;
      });
    },
  },
});

export default userManagementSlice;
