import useCoreSelector from "../../../hooks/useCoreSelector";
import React, { useContext, useMemo } from "react";
import UsersTable from "./UsersTable";
import UserManagementContext from "../contexts/UserManagementContext";
import { PancoInput } from "cosmos-components";
import { CosmosCoreRootState } from "../../../store";
import _ from "lodash";

const GroupUsers = () => {
  const { getUsers, search, searchQuery } = useContext(UserManagementContext);

  const selectedGroup = useCoreSelector<CosmosCoreRootState, string | null>(
    (state) => state.usermanagement.selectedGroup
  );

  const users = useMemo(
    () => (selectedGroup != null ? getUsers(selectedGroup) : []),
    [getUsers, selectedGroup]
  );

  return (
    <div className="h-100 d-flex flex-column">
      <div className="mb-3">
        <PancoInput
          value={searchQuery}
          className="w-100"
          boxed
          onChange={({ value }) => {
            const arr = Array.isArray(value) ? value : _.compact([value]);
            search(arr[0]);
          }}
          placeholder="Search"
          clearable
        />
      </div>
      <UsersTable users={users} />
    </div>
  );
};

export default GroupUsers;
