import styled from "styled-components";
import { ContentLayoutContainer } from "./ContentLayout";

const PageContent = styled.div`
  padding-top: 35px;
  background-color: ${(props) => props.theme.background};

  @media (min-width: 768px) {
    & {
      height: 100vh;
      overflow: hidden;

      & > ${ContentLayoutContainer} {
        height: calc(100% - 45px);
      }
    }
  }

  @media (max-width: 767.98px) {
    & {
      height: 100%;
      padding-top: 60px;
    }
  }
`;

export default PageContent;
