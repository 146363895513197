export const REPORTING_GROUPS_SET = 'REPORTING_GROUPS_SET';
export const REPORTING_RESULTS_SET = 'REPORTING_RESULTS_SET';
export const REPORTING_RUN_QUERY = 'REPORTING_RUN_QUERY';
export const REPORTING_DOCUMENTS_SET = 'REPORTING_DOCUMENTS_SET';
export const REPORTING_SET_LOADING = 'REPORTING_SET_LOADING';
export const REPORTING_EXPORT_TO_XLS = 'REPORTING_EXPORT_TO_XLS';
export const REPORTING_RESULT_ADD_FIELD = 'REPORTING_RESULT_ADD_FIELD';
export const REPORTING_RESULT_ADD_FUNCTION = 'REPORTING_RESULT_ADD_FUNCTION';
export const REPORTING_RESULT_UPDATE = 'REPORTING_RESULT_UPDATE';
export const REPORTING_RESULT_DELETE = 'REPORTING_RESULT_DELETE';
export const REPORTING_RESULT_MOVE = 'REPORTING_RESULT_MOVE';
export const REPORTING_CONDITION_ADD = 'REPORTING_CONDITION_ADD';
export const REPORTING_CONDITION_UPDATE = 'REPORTING_CONDITION_UPDATE';
export const REPORTING_CONDITION_DELETE = 'REPORTING_CONDITION_DELETE';
export const REPORTING_GROUP_ADD = 'REPORTING_GROUP_ADD';
export const REPORTING_GROUP_DELETE = 'REPORTING_GROUP_DELETE';
