import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { resourceCorrectionOfGateway, trimUINumber } from "cosmos-config/utils";
import ReminderEditor from "./ReminderEditor";
import _ from "lodash";
import reminderProperties from "../constants/reminderProperties";
import useCurrentUser from "../../../hooks/useCurrentUser";
import ResourceReminderContext from "../contexts/ResourceReminderContext";
import { Reminder } from "../constants/reminder";
import Resource from "../../../types/resource";
import ReminderProvider from "./ReminderProvider";
import { UserInfo } from "../../../contexts/UserDataContext";

const generateReminder = (userdata?: UserInfo | null) => {
  const dateTime = DateTime.local();
  const cron = `0 0 0 ${dateTime.day} ${dateTime.month} *`;

  const user =
    userdata != null
      ? {
          principalId: userdata.defaultPositionId,
          name: trimUINumber(userdata.commonName),
          email: _.first(userdata.emails),
        }
      : null;

  const reminder = {
    name: "New Reminder",
    cron,
    triggerType: "RECURRENT",
    users: _.compact([user]),
  };

  return resourceCorrectionOfGateway(reminder, reminderProperties);
};

interface RemindersListProps {
  disabled?: boolean;
}

const RemindersList = ({ disabled }: RemindersListProps) => {
  const { reminders } = useContext(ResourceReminderContext);

  return (
    <>
      {reminders.map((reminder) => (
        <ReminderEditor
          className="mb-3"
          key={reminder.id}
          reminder={reminder}
          disabled={disabled}
        />
      ))}
    </>
  );
};

export interface ResourceRemindersProps {
  resource?: Resource;
  folderName?: string;
  disabled?: boolean;
}

const ResourceReminders = ({
  resource,
  folderName,
  disabled,
}: ResourceRemindersProps) => {
  const { t } = useTranslation();

  const { userData } = useCurrentUser();

  const resourceType = useMemo(() => resource?.resourcetype, [resource]);

  const resourceName = useMemo(() => {
    const defaultName = resource?.displayname;
    if (resourceType === 1 && folderName != null && folderName !== "") {
      return folderName;
    }

    return defaultName;
  }, [folderName, resource?.displayname, resourceType]);

  const [creatingReminder, setCreatingReminder] = useState<Reminder | null>(
    null
  );

  return (
    <>
      <ReminderProvider resource={resource} resourceName={resourceName}>
        <RemindersList disabled={disabled} />

        {creatingReminder != null && (
          <ReminderEditor
            reminder={creatingReminder}
            className="mb-3"
            onUpdated={() => setCreatingReminder(null)}
          />
        )}
      </ReminderProvider>

      {creatingReminder == null && (
        <Card
          border="primary"
          className="text-center text-primary cursor-pointer"
          body
          onClick={() => {
            setCreatingReminder(generateReminder(userData));
          }}
        >
          <FontAwesomeIcon icon={faPlus} size="lg" className="mr-2" />
          {t("repository.resource_create", {
            defaultValue: "Create Reminder",
          })}
        </Card>
      )}
    </>
  );
};

ResourceReminders.propTypes = {
  resource: PropTypes.shape({
    id: PropTypes.string,
    resourcetype: PropTypes.number,
    displayname: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default ResourceReminders;
