/* eslint-disable func-names */
import { put, spawn, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  fetchProjectMessages,
  setLoadingProject,
  setProjectMessages,
} from '../../Actions/project';
import {
  CREATE_PROJECT_CONTACT_MESSAGE,
  CREATE_PROJECT_MESSAGE,
  CREATE_SYSTEM_MESSAGE,
  DELETE_PROJECT_MESSAGE,
  FETCH_PROJECT_MESSAGES,
  UPDATE_PROJECT_MESSAGE,
  UPDATE_SYSTEM_MESSAGE,
  DELETE_SYSTEM_MESSAGE,
} from '../../Actions/types/message';
import projectApi from '../../Api/project';
import systemApi from '../../Api/message';
import propertiesMap from '../../Constants/gateway/message';
import {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from 'cosmos-config/utils';
import callApi from '../Effects/callApi';

function* fetchMessages(projectCode) {
  try {
    yield put(setLoadingProject(true, 'Loading announcements.'));

    const results = yield callApi(projectApi.getProjectMessages, projectCode);
    const messages = results.map((r) =>
      resourceCorrectionOfGateway(r, propertiesMap)
    );

    yield put(setProjectMessages(messages));
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setLoadingProject(false));
  }
}

export default function* messageSaga(projectCode) {
  yield takeLatest(FETCH_PROJECT_MESSAGES, fetchMessages, projectCode);
  yield takeLeading(
    [
      CREATE_PROJECT_MESSAGE,
      CREATE_PROJECT_CONTACT_MESSAGE,
      CREATE_SYSTEM_MESSAGE,
    ],
    function* ({ message, callback, type }) {
      try {
        yield put(setLoadingProject(true, 'Creating new announcement.'));
        const preparedMessage = resourceCorrectionForGateway(
          message,
          propertiesMap
        );

        if (type === CREATE_SYSTEM_MESSAGE) {
          yield callApi(systemApi.createSystemMessage, preparedMessage);
        } else {
          const apiFunc =
            type === CREATE_PROJECT_MESSAGE
              ? projectApi.createProjectMessage
              : projectApi.createContactMessage;

          yield callApi(apiFunc, projectCode, preparedMessage);
        }

        yield spawn(callback);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setLoadingProject(false));
        yield put(fetchProjectMessages());
      }
    }
  );

  yield takeLeading(
    [UPDATE_SYSTEM_MESSAGE, UPDATE_PROJECT_MESSAGE],
    function* ({ messageId, message, callback, type }) {
      try {
        yield put(setLoadingProject(true, 'Updating announcement.'));
        const preparedMessage = resourceCorrectionForGateway(
          message,
          propertiesMap
        );

        if (type === UPDATE_SYSTEM_MESSAGE) {
          yield callApi(
            systemApi.updateSystemMessage,
            messageId,
            preparedMessage
          );
        } else {
          yield callApi(
            projectApi.updateProjectMessage,
            projectCode,
            messageId,
            preparedMessage
          );
        }

        yield spawn(callback);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setLoadingProject(false));
        yield put(fetchProjectMessages());
      }
    }
  );

  yield takeLeading(
    [DELETE_PROJECT_MESSAGE, DELETE_SYSTEM_MESSAGE],
    function* ({ messageId, callback, type }) {
      try {
        yield put(setLoadingProject(true, 'Deleting announcement.'));

        if (type === DELETE_PROJECT_MESSAGE) {
          yield callApi(
            projectApi.deleteProjectMessage,
            projectCode,
            messageId
          );
        } else {
          yield callApi(systemApi.deleteSystemMessage, messageId);
        }
        yield spawn(callback);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setLoadingProject(false));
        yield put(fetchProjectMessages());
      }
    }
  );

  yield put(fetchProjectMessages());
}
