import store from "../store";
import sessionSlice from "../slices/sessionSlice";

let authToken: string | null;
let domainName: string | null;

const authService = {
  getAuthToken: () => authToken,
  getDomainName: () => domainName,
  update: (token: string | null, domain: string | null) => {
    authToken = token;
    domainName = domain;
  },
  invalidateSession: () => {
    // console.log("---- auth service invalidate", store);
    // store.dispatch({
    //   type: "test",
    // });
    // console.log(sessionSlice.actions.invalidate());
  },
};

export default authService;
