import { Button, CloseButton, Col, Modal, Row } from "react-bootstrap";
import SimpleUniversalForm from "../../../../components/SimpleUniversalForm";
import createProperty from "../../constants/createProperty";
import { resourceCorrectionForGateway } from "cosmos-config/utils";
import PropertyTypeSelector from "../property-editor/PropertyTypeSelector";
import { PancoSelect } from "cosmos-components";
import ProjectEditorPropertiesContext from "../../contexts/ProjectEditorPropertiesContext";
import _ from "lodash";
import SimpleFormGroup from "../../../../components/SimpleFormGroup";
import { propertyType } from "cosmos-config/generator";
import { PropertyEditorModalProps } from "../../types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Property } from "cosmos-config/lib/property/property";

export interface CreatePropertyModalProps extends PropertyEditorModalProps {
  onCreate?: (property: Partial<Property>) => void;
}

const CreatePropertyModal = ({
  show,
  onClose,
  onCreate,
}: CreatePropertyModalProps) => {
  const [resource, setResource] = useState<Partial<Property>>({});
  const { propertyDefinitions } = useContext(ProjectEditorPropertiesContext);

  useEffect(() => {
    if (show) {
      setResource({});
    }
  }, [show]);

  const attributesOptions = useMemo(() => {
    const { type, handleAsMulti, disabled } = resource;
    if (type == null) {
      return [];
    }

    return _(propertyDefinitions)
      .filter((pd) => {
        const { multiValue, readOnly } = pd;
        const {
          booleanType,
          dateType,
          integerType,
          longType,
          doubleType,
          stringType,
          timestampType,
        } = pd.type;

        const applicable = (cond: boolean) =>
          // @ts-ignore
          !(!handleAsMulti ^ !multiValue) &&
          cond &&
          !(!disabled && readOnly) &&
          (![
            propertyType.PRINCIPAL_SELECT,
            propertyType.MEMBER_SELECT,
          ].includes(type) ||
            pd.type.length >= 50);

        switch (type) {
          case propertyType.NUMERIC:
            return applicable(integerType || longType || doubleType);
          case propertyType.YESNO:
          case propertyType.CHECKBOX:
            return applicable(booleanType);
          case propertyType.DATE:
            return applicable(dateType || timestampType);
          default:
            return applicable(stringType);
        }
      })
      .map((pd) => ({
        value: pd.name,
        label: `${pd.system ? "[S]" : ""} ${pd.displayName} (${
          pd.type?.length
        })`,
      }))
      .value();
  }, [resource, propertyDefinitions]);

  return (
    <Modal show={show} size="xl" onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Create Property</Modal.Title>
        <CloseButton className="close-icon" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="6">
            <PropertyTypeSelector
              resource={resource}
              onChange={(v) => setResource((r) => ({ ...r, ...v }))}
            />
          </Col>
          <Col md="6">
            <SimpleFormGroup label="Backend Attribute" inline>
              <PancoSelect
                options={attributesOptions}
                value={_.compact([resource.name])}
                onChange={({ value }) =>
                  setResource((r) => ({ ...r, name: _.first(value) }))
                }
              />
            </SimpleFormGroup>

            <SimpleUniversalForm
              properties={createProperty}
              resource={resource}
              inline
              onUpdateResource={(id, name, value) => {
                setResource((r) => ({
                  ...r,
                  [name]: value,
                }));
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right">
          <Button
            onClick={() => {
              if (onCreate != null) {
                onCreate(
                  resourceCorrectionForGateway(resource, createProperty)
                );
              }
            }}
            disabled={resource?.name == null}
          >
            Create
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreatePropertyModal;
