import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { PancoTooltip } from "cosmos-components";
import classNames from "classnames";
import { trimUINumber } from "cosmos-config/utils";
import useCurrentUser from "../../../hooks/useCurrentUser";
import Resource from "../../../types/resource";
import PermissionLockSymbol from "../../../modules/access-management/components/PermissionLockSymbol";
import _ from "lodash";
import { Row } from "react-table";

declare type AccessManagementCellProps = {
  row: Row<Resource>;
};

const AccessManagementCell = ({ row }: AccessManagementCellProps) => {
  const { principalId } = useCurrentUser();
  const resource = row.original;

  if (resource?.lockowner != null && resource.lockowner !== "-2") {
    return (
      <div
        className={classNames({
          "text-success": resource.lockowner === principalId,
          "text-warning": resource.lockowner !== principalId,
        })}
      >
        <PancoTooltip
          text={`Document locked by ${trimUINumber(
            resource.lockownercommonname || ""
          )}`}
        >
          <FontAwesomeIcon icon={faUserLock} />
        </PancoTooltip>
      </div>
    );
  }

  return <PermissionLockSymbol resource={resource} />;
};

AccessManagementCell.displayName = "AccessManagementCell";

export default React.memo(AccessManagementCell, (prevProps, nextProps) => {
  const resourceIdPath = "row.original.id";
  return _.isEqual(
    _.get(prevProps, resourceIdPath),
    _.get(nextProps, resourceIdPath)
  );
});
