import { useCallback, useMemo } from "react";
import useRepositoryTable, {
  UseRepositoryTableProps,
} from "./useRepositoryTable";
import Resource from "../types/resource";
import { TableColumnOrder } from "../providers/ColumnsProvider";
import { RepositoryTableProps } from "./repository-table/RepositoryTable";

export interface UseAdaptiveRepositoryTableProps
  extends UseRepositoryTableProps {
  adaptColumnOrder?: (
    columnOrder: TableColumnOrder<Resource>[]
  ) => TableColumnOrder<Resource>[];
  adaptData?: (data: Resource[]) => Resource[];
}

const useAdaptiveRepositoryTable = ({
  resourceId,
  filter,
  searchQuery,
  subtree,
  adaptColumnOrder,
  adaptData,
  onSelect,
  pageSize,
}: UseAdaptiveRepositoryTableProps) => {
  const { getTableProps, getPaginatorProps, loading, refetch } =
    useRepositoryTable({
      resourceId,
      filter,
      searchQuery,
      subtree,
      onSelect,
      pageSize,
    });

  const repositoryTableProps = useMemo(() => getTableProps(), [getTableProps]);

  const resources = useMemo(() => {
    const { data } = repositoryTableProps;
    if (adaptData != null) {
      return adaptData(data || []);
    }

    return data;
  }, [adaptData, repositoryTableProps]);

  const columnOrder = useMemo(() => {
    const { columnOrder } = repositoryTableProps;
    if (adaptColumnOrder != null) {
      return adaptColumnOrder(columnOrder || []);
    }

    return columnOrder;
  }, [adaptColumnOrder, repositoryTableProps]);

  return {
    getTableProps: useCallback(
      (
        props?: Partial<RepositoryTableProps>
      ): Partial<RepositoryTableProps> => ({
        ...repositoryTableProps,
        ...(props || {}),
        data: resources,
        columnOrder,
      }),
      [columnOrder, repositoryTableProps, resources]
    ),
    getPaginatorProps,
    loading,
    refetch,
  };
};

export default useAdaptiveRepositoryTable;
