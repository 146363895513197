import _ from "lodash";
import Api from "./conf/Api";
import GatewayApi from "./conf/GatewayApi";
import { ResourcePermission, UserInfo } from "../contexts/UserDataContext";

const gatewayApi = GatewayApi.build();
const api = Api.build();

const login = (authToken: string, domainName: string) => {
  return gatewayApi
    .get("/login", {
      headers: {
        Authorization: authToken,
      },
      params: {
        domain: domainName,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const logout = () => {
  return gatewayApi.get("/logout").then((response) => {
    return Promise.resolve(response.data);
  });
};

const userdataProperties = [
  "firstName",
  "commonName",
  "domainName",
  "lastName",
  "name",
  "office",
  "organizations",
  "roles",
  "titles",
  "userInfoId",
  "department",
  "departmentTeam",
  "defaultPositionId",
  "principalId",
  "emails",
];

const getUserdata = (principalId: string) => {
  return api
    .get<UserInfo>(`/usermanagement/principal/${principalId}/userinfo`)
    .then((response) => {
      const { data } = response;
      const userData = _.pick(data, userdataProperties);

      let department = null;
      let departmentTeam = null;

      if (userData.office != null) {
        const officeCode = String(userData.office.split("/")[0]).trim();
        const [dep, ...rest] = officeCode.split("-");
        department = dep;
        departmentTeam = rest.length > 0 ? rest.join("-") : null;
      }

      return Promise.resolve({
        ...userData,
        department,
        departmentTeam,
      } as UserInfo);
    });
};

const getUsersResourcePermissions = () => {
  return gatewayApi
    .get<ResourcePermission[]>(`/user/resource-permissions`)
    .then((response) => Promise.resolve(response.data));
};

const getUserAlerts = () => {
  return gatewayApi.get("/user/alerts").then((response) => {
    return Promise.resolve(response.data);
  });
};

const userApi = {
  login,
  logout,
  getUserdata,
  getUsersResourcePermissions,
  getUserAlerts,
};

export default userApi;
