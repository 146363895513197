import {
  filterToOperation,
  orOperation,
  operation,
  existsOperation,
  queryOperand,
  andOperation,
} from "cosmos-config/nql";
import _ from "lodash";
import { propertyType } from "cosmos-config/generator";
import { Property } from "cosmos-config/lib/property/property";
import { FilterValue } from "cosmos-config/lib/nql/filter";
import { Operation } from "cosmos-config/lib/nql/operation";
import { generateStringHash } from "./cryptoUtils";

export interface SearchOperationOptions {
  substructure?: boolean;
  searchQuery?: string | null;
  fulltextSearch?: boolean;
  searchProperties?: string[];
  signatureStatus?: "IN_PROGRESS" | "COMPLETE" | "ALL";
}

const parseOptions = (
  options?: SearchOperationOptions
): Required<SearchOperationOptions> => ({
  substructure: options?.substructure || false,
  searchQuery: options?.searchQuery || null,
  fulltextSearch: options?.fulltextSearch || false,
  searchProperties: options?.searchProperties || [],
  signatureStatus: options?.signatureStatus || "ALL",
});

function* generateSearchOperations(
  filter: Record<string, FilterValue>,
  properties: Property[],
  options?: SearchOperationOptions
): Generator<Operation> {
  const {
    substructure,
    searchQuery,
    fulltextSearch,
    searchProperties,
    signatureStatus,
  } = parseOptions(options);

  yield operation("eq", queryOperand("deletestate"), queryOperand("$none"));

  if (filter != null && Object.keys(filter).length > 0) {
    yield filterToOperation(filter, properties);
  }

  const documentsSearch =
    (Array.isArray(filter.resourcetype) && filter.resourcetype.includes(2)) ||
    filter.resourcetype === 2 ||
    (_.isNil(filter.resourcetype) && _.isNil(filter.foldertype));

  if (documentsSearch) {
    if (!(_.has(filter, "parentdocumentid") || substructure)) {
      yield orOperation(
        operation("isNull", queryOperand("parentdocumentid")),
        // operation(
        //   "eq",
        //   queryOperand("parentdocumentid"),
        //   operation(
        //     "concat",
        //     "RWE$NOTSET$",
        //     operation("toString", queryOperand("identifier")),
        //     "$2$NOTSET"
        //   )
        // )
        operation(
          "eq",
          queryOperand("parentdocumentid"),
          queryOperand("Resourceid_Copy")
        )
      );
    }

    if (!(_.has(filter, "ParentEmailId") || substructure)) {
      yield operation("isNull", queryOperand("ParentEmailId"));
    }

    if (signatureStatus === "COMPLETE") {
      yield orOperation(
        operation("isNull", queryOperand("signaturestatus")),
        operation("eq", queryOperand("signaturestatus"), "COMPLETE")
      );
    }

    if (signatureStatus === "IN_PROGRESS") {
      yield operation("noteq", queryOperand("signaturestatus"), "COMPLETE");
    }
  }

  if (searchQuery?.trim().length && !fulltextSearch) {
    const propertiesMap = _.keyBy(properties, "name");

    const operations = [
      ...(searchProperties || []),
      "filename",
      "displayname",
    ].map((propertyName) => {
      const property = propertiesMap[propertyName];

      const wildcardSearchQuery = `*${searchQuery}*`;

      if (property?.type === propertyType.SELECT) {
        return existsOperation(
          operation(
            "likeIgnoreCase",
            queryOperand("BasicKeywords"),
            wildcardSearchQuery
          )
        );
      }

      return operation(
        "likeIgnoreCase",
        queryOperand(propertyName),
        wildcardSearchQuery
      );
    });

    if (operations.length === 1) {
      return operations[0];
    }

    if (operations.length > 0) {
      yield orOperation(...operations);
    }
  }
}

export const getSearchOperation = (
  filter: Record<string, FilterValue>,
  properties: Property[],
  options?: SearchOperationOptions
): Operation => {
  const defaultFilter = filter || {};
  const defaultProperties = properties || [];

  const operations = [
    ...generateSearchOperations(defaultFilter, defaultProperties, options),
  ];

  if (operations.length <= 1) {
    return operations[0];
  }

  return andOperation(...operations);
};

export const generateObjectMap = (obj: any): string => {
  if (obj == null) {
    return "";
  }

  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      return `[${obj.map((inner) => generateObjectMap(inner))}]`;
    }

    if (obj._type === "cosmos-operation") {
      return obj.toString();
    }

    return _(obj)
      .map((value, key) => `${key}(${generateObjectMap(value)})`)
      .sort()
      .join("&");
  }

  return JSON.stringify(obj);
};

/**
 * Generate hash code of search options to decide whether the cache could be used.
 *
 * @public
 * @param resourceId - Id of the folder resource which is being searched.
 * @param searchOptions - Repository search options.
 * @returns Search options hash code.
 */
export const generateSearchHash = (resourceId: string, searchOptions: any) => {
  const map = generateObjectMap(searchOptions);
  return generateStringHash(`@${resourceId}{${map}}`);
};
