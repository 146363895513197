import GatewayApi from './conf/GatewayApi';

const gatewayApi = GatewayApi.build();

const getRepositoryQuery = (queryId) => {
  return gatewayApi
    .get(`/repository-query/${queryId}`)
    .then((response) => Promise.resolve(response.data));
};

const updateRepositoryQuery = (queryId, repositoryQuery) => {
  return gatewayApi
    .put(`/repository-query/${queryId}`, repositoryQuery)
    .then((response) => Promise.resolve(response.data));
};

const deleteRepositoryQuery = (queryId) => {
  return gatewayApi
    .delete(`/repository-query/${queryId}`)
    .then((response) => Promise.resolve(response.data));
};

export default {
  getRepositoryQuery,
  updateRepositoryQuery,
  deleteRepositoryQuery,
};
