import React from "react";
import { Timeline } from "cosmos-components";
import classNames from "classnames";
import { AccessLevelEditorProps } from "./AccessLevelEditor";
import useAccessLevelOptions from "../../hooks/useAccessLevelOptions";

const AccessLevelSlider = ({
  accessLevel,
  disabled,
  onChange,
}: AccessLevelEditorProps) => {
  const accessLevelOptions = useAccessLevelOptions();

  const renderAccessLevelStep = (option: any) => {
    const active = accessLevel === option.value;

    return (
      //@ts-ignore
      <Timeline.Step
        active={active}
        key={option.value}
        onClick={() => {
          if (!disabled && onChange != null) {
            onChange(option.value);
          }
        }}
        className={classNames({
          "text-warning": option.value > 8 && active,
          "text-info": option.value > 5 && option.value <= 8 && active,
          "text-danger": option.value === 0 && active,
        })}
        subtitle={active ? option.description : null}
      >
        {option.label}
        {/* @ts-ignore */}
      </Timeline.Step>
    );
  };

  return (
    // @ts-ignore
    <Timeline selectable={!disabled} className="mt-4">
      {accessLevelOptions.map((option) => renderAccessLevelStep(option))}
    </Timeline>
  );
};

export default AccessLevelSlider;
