import { Col } from "react-bootstrap";
import React, { useContext, useEffect, useMemo } from "react";
import classNames from "classnames";
import { uuidv4 } from "cosmos-config/utils";
import ContentLayoutContext, { componentType } from "./ContentLayoutContext";
import sidebarGridMapping from "./sidebarGridMapping";

export interface ContentLayoutBlockerProps {
  className?: string;
  hideXs?: boolean;
}

const ContentLayoutBlocker = ({
  className,
  hideXs,
}: ContentLayoutBlockerProps) => {
  const { registerComponent, unregisterComponent } =
    useContext(ContentLayoutContext);

  const uuid = useMemo(() => uuidv4(), []);

  useEffect(() => {
    registerComponent(uuid, componentType.BLOCKER);

    return () => unregisterComponent(uuid);
  }, [registerComponent, unregisterComponent, uuid]);

  return (
    <Col
      {...sidebarGridMapping}
      className={classNames(className, {
        "d-none": hideXs,
        "d-sm-block": hideXs,
      })}
    ></Col>
  );
};

export default ContentLayoutBlocker;
