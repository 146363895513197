const alertType = {
  INVALID_DOCUMENT: "INVALID_DOCUMENT",
  WORKFLOW_TASK: "WORKFLOW_TASK",
  MESSAGE: "MESSAGE",
  PROJECT_ACCESS_REQUEST: "PROJECT_ACCESS_REQUEST",
  BUSINESS_PROCESS_TASK: "BUSINESS_PROCESS_TASK",
  MODULE_SUMMARY: "MODULE_SUMMARY",
} as const;

type UserAlertKeys = keyof typeof alertType;
export type UserAlertType = (typeof alertType)[UserAlertKeys];

export default alertType;
