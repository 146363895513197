import { useCallback, useContext } from "react";
import CosmosCoreContext from "../contexts/CosmosCoreContext";
import { Group } from "cosmos-config/lib/group/group";
import { Project } from "cosmos-config/lib/project/project";

const CONFIG_PROVIDER_URL = process.env.REACT_APP_CONFIG_PROVIDER_URL;

interface ProjectPropertiesResponse {
  properties: Group[];
  projectCode: string;
}

export interface BusinessProcessPropertiesResponse {
  properties: Group[];
  processDefinitionKey: string;
  projectCode?: string;
}

const useConfigProvider = () => {
  const { callImport } = useContext(CosmosCoreContext);

  const dynamicLoad = useCallback((url: string) => {
    const key = Math.random().toString(36).slice(2);
    return callImport(`${CONFIG_PROVIDER_URL}/${url}?k=${key}`)
      .then((result) => Promise.resolve(result.default))
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const loadProjectSpec = useCallback(
    (projectCode: string): Promise<Project> => {
      return dynamicLoad(`project/${projectCode}/spec`);
    },
    [dynamicLoad]
  );

  const loadProjectProperties = useCallback(
    (projectCode: string): Promise<ProjectPropertiesResponse> => {
      if (projectCode == null) {
        return Promise.reject("Project code is not specified!");
      }

      return dynamicLoad(`project/${projectCode}/properties`).then((res) =>
        Promise.resolve({ properties: res, projectCode })
      );
    },
    [dynamicLoad]
  );

  const loadProjectTemplates = useCallback(
    (projectCode: string): Promise<ProjectPropertiesResponse> => {
      if (projectCode == null) {
        return Promise.reject("Project code is not specified!");
      }

      return dynamicLoad(`project/${projectCode}/templates`).then((res) =>
        Promise.resolve({ properties: res, projectCode })
      );
    },
    [dynamicLoad]
  );

  const loadBusinessProcessProperties = useCallback(
    (
      projectCode: string,
      processDefinitionKey: string
    ): Promise<BusinessProcessPropertiesResponse> => {
      return dynamicLoad(
        `project/${projectCode}/business-process/${processDefinitionKey}/properties`
      ).then((res) =>
        Promise.resolve({ properties: res, projectCode, processDefinitionKey })
      );
    },
    [dynamicLoad]
  );

  const loadBusinessProcessSpecimen = useCallback(
    (
      processDefinitionKey: string
    ): Promise<BusinessProcessPropertiesResponse> => {
      return dynamicLoad(
        `business-process/${processDefinitionKey}/properties`
      ).then((res) =>
        Promise.resolve({ properties: res, processDefinitionKey })
      );
    },
    [dynamicLoad]
  );

  return {
    loadProjectSpec,
    loadProjectProperties,
    loadProjectTemplates,
    loadBusinessProcessProperties,
    loadBusinessProcessSpecimen,
  };
};

export default useConfigProvider;
