import Api from "./api";

const api = Api.build();

const getAllTypeTranslations = (translationsType, name) => {
  return api
    .get(`/locales/all/${translationsType}/${name}`)
    .then((response) => Promise.resolve(response.data));
};

const updateTypeTranslations = (translationsType, name, translations) => {
  return api
    .put(`/locales/all/${translationsType}/${name}`, translations)
    .then((response) => Promise.resolve(response.data));
};

const updateLanguageTranslation = (
  languageCode,
  translationType,
  name,
  translation
) => {
  return api
    .put(`/locales/${languageCode}/${translationType}/${name}`, translation)
    .then((response) => Promise.resolve(response.data));
};

const getLocalesInfo = () => {
  return api
    .get(`/locales-info`)
    .then((response) => Promise.resolve(response.data));
};

const deleteTranslationKey = (translationType, name, translationKey) => {
  return api
    .delete(`/translation/${translationType}/${name}/${translationKey}`)
    .then((response) => Promise.resolve(response.data));
};

const localeApi = {
  getAllTypeTranslations,
  updateTypeTranslations,
  updateLanguageTranslation,
  getLocalesInfo,
  deleteTranslationKey,
};

export default localeApi;
