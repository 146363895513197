import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { startCosmos } from '../../Actions/ui';
import { OAUTH_REDIRECT_ROUTE } from '../../Constants/routes';
import OauthRedirect from '../Authorization/OauthRedirect';
import SplashScreen from './SplashScreen';

const AppRouter = React.lazy(() => import('./AppRouter'));

const motionProps = (key) => ({
  key,
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  },
});

const CosmosEntry = ({ children }) => {
  const dispatch = useDispatch();

  const showSplashScreen = useSelector((state) => state.ui.showSplashScreen);

  useEffect(() => {
    dispatch(startCosmos());
  }, [dispatch]);

  if (showSplashScreen) {
    return (
      <AnimatePresence>
        <motion.div {...motionProps('splash')}>
          <SplashScreen />
        </motion.div>
      </AnimatePresence>
    );
  }

  return children;
};

CosmosEntry.propTypes = {
  children: PropTypes.node,
};

CosmosEntry.defaultPropt = {
  children: null,
};

const BaseRouter = () => {
  return (
    <Routes>
      <Route path={OAUTH_REDIRECT_ROUTE} element={<OauthRedirect />} />

      <Route
        path="*"
        element={
          <CosmosEntry>
            <Suspense fallback={<SplashScreen />}>
              <AppRouter />
            </Suspense>
          </CosmosEntry>
        }
      />
    </Routes>
  );
};

export default BaseRouter;
