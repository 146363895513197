import {
  valuesetSource,
  property,
  systemProperty,
} from "cosmos-config/generator";

const systemProperties = [
  systemProperty("objectclass"),
  systemProperty("parentresourceid"),
  systemProperty("resourcetype"),
  systemProperty("refresourceid"),
  systemProperty("refresourcetype"),
  systemProperty("refresourcecontenttype"),
  property("File Type", "fileextension")
    .excludeFilter()
    .excludeForm()
    .excludePreset()
    .translate("common.file_type")
    .disableSorting(),
  systemProperty("itemdisplayname").multiAttribute("itemdisplayname"),
  systemProperty("itemlength").multiAttribute("itemlength"),
  systemProperty("contentitemcount"),
  systemProperty("lockowner"),
  systemProperty("deletestate"),
  systemProperty("Keywords", "BasicKeywords", true).multiAttribute(
    "BasicKeywords"
  ),
  systemProperty("hidden").booleanAttribute("hidden"),
  systemProperty("creatorcommonname"),
  systemProperty("creationdate"),
  systemProperty("version"),
  systemProperty("versionidentifier"),
  systemProperty("refresourceversionidentifier"),
  systemProperty("refresourcecontentitemcount"),
  property("Identifier", "identifier")
    .excludeForm()
    .excludeFilter()
    .excludePreset()
    .includeKeyword()
    .translate("common.identifier"),
  systemProperty("Checksum", "itemhash", true).multiAttribute("itemhash"),
  systemProperty("ProjectSector"),
  systemProperty("DocumentNumber"),
  systemProperty("contentlength").numericAttribute("contentlength"),
  systemProperty("foldertype", "foldertype", true),
  systemProperty("parentfoldertype"),
  systemProperty("pathidentifiers").multiAttribute("pathidentifiers"),
  systemProperty("filename", "filename", true),
  systemProperty("lockownercommonname"),
  systemProperty("envelopeId"),
  systemProperty("Filetype", "contenttype"),
  systemProperty("Linked Documents").multiAttribute("LinkedDocs"),
  systemProperty("targeturl", "targeturl", true),
  systemProperty("placeholder", "placeholder", true),
  systemProperty("signaturerequestor"),
  property("eSign Status", "signaturestatus")
    .selectableAttribute(
      "signaturestatus",
      "global_signature_status",
      valuesetSource.VALUESET
    )
    .excludeForm()
    .excludeFilter()
    .excludePreset(),

  systemProperty(
    "parentdocumentid",
    "parentdocumentid",
    true
  ).selectableResourceAttribute("parentdocumentid"),
  systemProperty("valid", "valid", true),
  systemProperty("ParentEmailId", "ParentEmailId", true),
  systemProperty("private", "private", true),
  systemProperty("accesslevel", "accesslevel", true),
  systemProperty("parentaccesslevel", "parentaccesslevel", true),
  systemProperty("accesslevelexternal", "accesslevelexternal", true),
  systemProperty(
    "parentaccesslevelexternal",
    "parentaccesslevelexternal",
    true
  ),
  systemProperty("initialcreator"),
  systemProperty("initialcreatorposition"),
  systemProperty("initialcreatorcommonname"),
  systemProperty("obsolete", "obsolete", true),
];

export default systemProperties;
