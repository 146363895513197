import { useEffect, useState, createElement as h } from "react";
import { PancoSelect } from "cosmos-components";
import useDebounce from "../../hooks/useDebounce";
import useCoreSelector from "../../hooks/useCoreSelector";
import useDomain from "../../hooks/useDomain";
import { principalType } from "cosmos-config/generator";
import { CosmosCoreRootState } from "../../store";
import { PancoSelectOption, PancoSelectProps } from "cosmos-components";

export interface PrincipalSelectProps extends PancoSelectProps {
  domainNames: string[];
  principalType: number;
}

const PrincipalSelect = ({
  value,
  domainNames,
  principalType,
  ...props
}: PrincipalSelectProps) => {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [options, setOptions] = useState<PancoSelectOption[]>([]);
  const [previousValue, setPreviousValue] = useState<string[]>([]);

  const loading = useCoreSelector<CosmosCoreRootState, boolean>(
    (state) => state.domain.loading
  );

  const debouncedSearchQuery = useDebounce(searchQuery, 200);

  const { fetchPrincipalsByIds, searchPrincipals } = useDomain(domainNames);

  useEffect(() => {
    if (
      value != null &&
      Array.isArray(previousValue) &&
      Array.isArray(value) &&
      previousValue.join() !== value.join()
    ) {
      if (value.some((v) => options.findIndex((o) => o.value === v) === -1)) {
        fetchPrincipalsByIds(value, principalType, (users) => {
          const usersOptions = users.map((u) => ({
            label: u.commonname,
            value: u.principalid,
          }));

          setOptions(usersOptions);
        });

        setPreviousValue(value);
      }
    }
  }, [value, principalType, previousValue, options, fetchPrincipalsByIds]);

  useEffect(() => {
    if (debouncedSearchQuery != null && debouncedSearchQuery !== "") {
      searchPrincipals(debouncedSearchQuery, principalType, (users) => {
        const usersOptions = users.map((u) => ({
          label: u.commonname,
          value: u.principalid,
        }));

        setOptions(usersOptions);
      });
    }
  }, [debouncedSearchQuery, principalType, searchPrincipals, domainNames]);

  return h(PancoSelect, {
    ...props,
    onFilter: setSearchQuery,
    manualFilter: true,
    loading,
    options,
    value,
  });
};

PrincipalSelect.defaultProps = {
  value: [],
  domainNames: ["Users"],
  principalType: principalType.POSITION,
};

export default PrincipalSelect;
