import React, { useState } from "react";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import ValuePickerWrapper, {
  ValuesetPickerWrapperProps,
} from "../value-picker-wrapper/ValuePickerWrapper";
import Calendar, { CalendarBoundary } from "../calendar/Calendar";
import {
  InputValueHandler,
  PancoInputElementProps,
} from "../../types/PancoInputElemenProps";
import _ from "lodash";

export interface DatePickerProps
  extends Omit<ValuesetPickerWrapperProps<number>, "children">,
    PancoInputElementProps,
    InputValueHandler<number> {
  zone?: string;
  boundary?: CalendarBoundary;
}

const DatePicker = ({
  value,
  zone,
  onChange,
  boundary,
  ...props
}: DatePickerProps) => {
  const [inputValue, setInputValue] = useState<string | null>(null);

  const formatValue = (date: number | null | string | undefined) => {
    let millis = _.isNumber(date) ? date : parseInt(String(date), 10);

    if (_.isNaN(millis)) {
      return "";
    }

    return DateTime.fromMillis(date as number, { zone })
      .setLocale("de-DE")
      .toFormat("dd.MM.yyyy");
  };

  const clearSelection = () => {
    if (onChange != null) {
      onChange({ value: null });
    }
  };

  const handleSelection = (v: null | number) => {
    if (v != null && onChange != null) {
      onChange({ value: v as number });
    }
  };

  const handleBlur = () => {
    if (inputValue != null && inputValue !== "") {
      let dateTime;

      if (/([1-3]?[0-9])\.(1?[0-9])\.([1-3]?[0-9])/.test(inputValue)) {
        dateTime = DateTime.fromFormat(inputValue, "d.M.yy");
      } else if (
        /([1-3]?[0-9])\.(1?[0-9])\.([0-3]?[0-9]{0,3})/.test(inputValue)
      ) {
        dateTime = DateTime.fromFormat(inputValue, "d.M.yyyy");
      } else if (
        /([0-3]?[0-9])\.([0-1]?[0-9])\.([0-3]?[0-9])/.test(inputValue)
      ) {
        dateTime = DateTime.fromFormat(inputValue, "dd.MM.yy");
      } else if (
        /([0-3]?[0-9])\.([0-1]?[0-9])\.([0-3]?[0-9]{1,3})/.test(inputValue)
      ) {
        dateTime = DateTime.fromFormat(inputValue, "dd.MM.yyyy");
      } else if (
        /([0-3]?[0-9])([0-1]?[0-9])([0-3]?[0-9]{1,3})/.test(inputValue)
      ) {
        dateTime = DateTime.fromFormat(inputValue, "ddMMyyyy");
      }

      if (dateTime != null && dateTime.isValid && onChange != null) {
        onChange({ value: dateTime.toMillis() });
      }
    }
  };

  return (
    <ValuePickerWrapper
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      filterPlaceholder="dd.mm.yyyy"
      onClear={clearSelection}
      content={() => formatValue(value)}
      selection={value != null}
      actionIcon={() => <FontAwesomeIcon icon={faCalendar} />}
      onSelect={handleSelection}
      filterable
      onFilterChange={(v) => setInputValue(v)}
      onBlur={handleBlur}
    >
      {({ onSelect }) => (
        <Calendar
          onSelect={onSelect}
          value={value}
          zone={zone}
          boundary={boundary}
        />
      )}
    </ValuePickerWrapper>
  );
};

DatePicker.defaultProps = {
  zone: "local",
};

export default DatePicker;
