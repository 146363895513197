/* eslint-disable func-names */
import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects';
import documentSaga from './document';
import repositorySaga from './repository';
import uiSaga, { applicationStatusFlow, notificationFlow } from './uiSaga';
import userdataSaga, { authenticate } from './userdataSaga';
import filterFolderSaga from './filterFolderSaga';
import workflowSaga from './workflowSaga';
import { showSplashScreen } from '../Actions/ui';
import * as actions from '../Actions/types';
import collaborationSaga from './collaboration';
import sharepointSaga from './sharepointSaga';
import projectSaga from './project';
import websocketSaga from './websocketSaga';
import userSettingsSaga from './userSettingsSaga';
import complete from './Effects/complete';
import messageApi from '../Api/message';

export default function* rootSaga() {
  yield take(actions.ui.START_COSMOS);

  yield put(showSplashScreen(true));
  yield fork(notificationFlow);
  yield fork(applicationStatusFlow);
  console.info('Notification flow ready.');

  yield takeLatest(actions.message.FETCH_SYSTEM_MESSAGES, function* (action) {
    try {
      const messages = yield call(messageApi.getSystemMessages);
      yield complete(action, messages);
    } catch (err) {
      console.error(err);
    }
  });

  yield authenticate(function* () {
    yield put(showSplashScreen(true));
    console.info('Authenticated & userdata loaded!');

    yield all([
      fork(userdataSaga),
      fork(sharepointSaga),
      fork(repositorySaga),
      fork(documentSaga),
      fork(filterFolderSaga),
      fork(workflowSaga),
      fork(uiSaga),
      fork(userSettingsSaga),
      fork(collaborationSaga),
      fork(projectSaga),
      fork(websocketSaga),
    ]);

    yield take(actions.ui.READY);
    console.info('UI flow prepared.');

    yield put(showSplashScreen(false));
  });
}
