import { createContext } from "react";

export interface DashboardContextValue {
  resourceId: string | null;
  filter: Record<string, any>;
}

const DashboardContext = createContext<DashboardContextValue>({
  resourceId: null,
  filter: {},
});

export default DashboardContext;
