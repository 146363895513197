import React from "react";
import DashboardContext from "../contexts/DashboardContext";
import CosmosWidgetComponent from "./CosmosWidgetComponent";
import {
  DashboardGrid,
  DashboardWidget,
  StaticDashboard,
  WidgetBody,
} from "./widgets/dashboardFragments";
import { Widget } from "cosmos-config/lib/widget/widget";
import { CosmosWidgetOnSelectCallback } from "../types/CosmosWidget";

export interface GenericDashboardProps {
  resourceId?: string;
  widgets: Widget[];
  filter?: Record<string, any>;
  onSelect?: CosmosWidgetOnSelectCallback;
  columns?: number;
}

const GenericDashboard = ({
  widgets,
  filter,
  resourceId,
  onSelect,
  columns,
}: GenericDashboardProps) => {
  return (
    <StaticDashboard columns={columns || 2}>
      <DashboardContext.Provider
        value={{ filter: filter || {}, resourceId: resourceId || null }}
      >
        <DashboardGrid>
          {widgets.map((w) => (
            <DashboardWidget key={w.id}>
              {w.title != null && !w.hideTitle && <h5>{w.title}</h5>}

              <WidgetBody>
                <CosmosWidgetComponent widget={w} onSelect={onSelect} />
              </WidgetBody>
            </DashboardWidget>
          ))}
        </DashboardGrid>
      </DashboardContext.Provider>
    </StaticDashboard>
  );
};

export default GenericDashboard;
