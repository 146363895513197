import _ from 'lodash';
import { createSelector } from 'reselect';
import { widgetType } from 'cosmos-config/generator';

export const getProjectQueries = (state) => state.project.queries;

export const getQueryWidgets = createSelector(
  [getProjectQueries],
  (projectQueries) => {
    const queryWidgets = projectQueries
      .filter((q) => !q.folderQuery)
      .map((q) => ({
        chart: false,
        hideTitle: false,
        name: `PROJECT_QUERY:${q.id}`,
        position: {
          x: 0,
          y: 0,
          w: 5,
          h: 4,
        },
        resourceType: 2,
        table: false,
        title: q.name,
        type: widgetType.aggregate.BAR_CHART,
        query: q.id,
      }));

    return _.uniqBy(queryWidgets, 'name');
    // return _(baseDashboardWidgets).concat(queryWidgets).uniqBy('name').value();
  }
);

export const getWidgetsState = (state) => state.project.widgets;

export const getFolderQueries = createSelector(getProjectQueries, (queries) =>
  queries.filter((q) => q.folderQuery)
);
