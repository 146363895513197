import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { shadeColor } from "../../utils";

const ThemedModal = styled(Modal)`
  .modal-content {
    background-color: ${(props) => props.theme.background};
    border-color: ${(props) => shadeColor(0.15, props.theme.background)};

    .modal-header {
      border-color: ${(props) => shadeColor(0.15, props.theme.background)};
    }
  }
`;

export default ThemedModal;
