export const START_BUSINESS_PROCESS = 'businessProcess/start';
export const SUBMIT_BUSINESS_PROCESS_TASK = 'businessProcess/submitTask';
export const FETCH_USER_BUSINESS_PROCESS_TASKS =
  'businessProcess/fetchUserTasks';
export const OPEN_BUSINESS_PROCESS_TASK = 'businessProcess/openTask';
export const OPEN_BUSINESS_PROCESS = 'businessProcess/open';
export const SET_BUSINESS_PROCESS_TASKS = 'businessProcess/setTasks';
export const FETCH_BUSINESS_PROCESS_DEFINITIONS =
  'businessProcess/fetchDefinitions';
export const SET_BUSINESS_PROCESS_DEFINITIONS =
  'businessProcess/setDefinitions';
export const SET_BUSINESS_PROCESS_LOADING = 'businessProcess/setLoading';
export const FETCH_BUSINESS_PROCESS_TASK_HISTORY =
  'businessProcess/fetchTaskHistory';
export const FETCH_BUSINESS_PROCESSES = 'businessProcesses/fetch';
export const CANCEL_BUSINESS_PROCESS = 'businessProcess/cancel';
export const SET_BUSINESS_PROCESSES = 'businessProcesses/set';
export const UPDATE_BUSINESS_PROCESS = 'businessProcess/update';
export const SET_BUSINESS_PROCESS_VALIDATION_ERRORS =
  'businessProcess/setValidationErrors';
