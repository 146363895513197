import * as actions from './types';

/**
 * Business process action generators.
 * @module actions/userdata
 * @category Actions
 */

/**
 * Action to set user data.
 * @function
 * @param {} data -
 */
export const setUserdata = (data) => ({
  type: actions.userdata.SET_USERDATA,
  data,
});

/**
 * Action for setting principal id.
 * @function
 * @param {} principalId -
 * @param {} defaultPositionId -
 */
export const setPrincipalId = (principalId, defaultPositionId) => ({
  type: actions.userdata.SET_PRINCIPAL_ID,
  principalId,
  defaultPositionId,
});

/**
 * Action to set authenticated status.
 * @function
 * @param {} authenticated -
 */
export const setAuthenticated = (authenticated) => ({
  type: actions.userdata.AUTHENTICATED,
  authenticated,
});

/**
 * Action for setting filter sets.
 * @function
 * @param {} filtersets -
 */
export const setFiltersets = (filtersets) => ({
  type: actions.userdata.SET_FILTERSETS,
  filtersets,
});

/**
 * Action for setting user permissions.
 * @function
 * @param {} permissions -
 */
export const setUserPermissions = (permissions) => ({
  type: actions.userdata.SET_USER_PERMISSIONS,
  permissions,
});

export const setUserGroupNames = (groupNames) => ({
  type: actions.userdata.SET_USER_GROUP_NAMES,
  payload: {
    groupNames,
  },
});

/**
 * Action for setting delegated principals.
 * @function
 * @param {} delegatedPrincipals -
 */
export const setDelegatedPrincipals = (delegatedPrincipals) => ({
  type: actions.userdata.SET_DELEGATED_PRINCIPALS,
  delegatedPrincipals,
});

/**
 * Action for setting delegates.
 * @function
 * @param {} delegates -
 */
export const setDelegates = (delegates) => ({
  type: actions.userdata.SET_DELEGATES,
  delegates,
});

/**
 * Action to create delegate.
 * @function
 * @param {} delegate -
 * @param {function} callback - action results callback
 */
export const createDelegate = (delegate, callback = () => {}) => ({
  type: actions.userdata.CREATE_DELEGATE,
  delegate,
  callback,
});

/**
 * Action for deactivate delegates.
 * @function
 * @param {function} callback - action results callback
 */
export const deactivateDelegates = (callback = () => {}) => ({
  type: actions.userdata.DEACTIVATE_DELEGATES,
  callback,
});

/**
 * Action to start business process.
 * @function
 * @param {boolean} loading - status flag of loading business process
 * @param {string} message - message text
 */
export const setUserDataLoading = (loading, message) => ({
  type: actions.userdata.SET_USERDATA_LOADING,
  loading,
  message,
});

/**
 * Action to log out.
 * @function
 */
export const logout = () => ({
  type: actions.userdata.LOGOUT,
});

/**
 * Action to log in.
 * @function
 * @param {} authToken -
 * @param {string} domain -
 */
export const login = (authToken, domain = 'Users') => ({
  type: actions.userdata.LOGIN,
  authToken,
  domain,
});

/**
 * Action to log in ADFS.
 * @function
 */
export const loginADFS = () => ({
  type: actions.userdata.LOGIN_ADFS,
});

/**
 * Action to log in as external ADFS.
 * @function
 */
export const loginExternalADFS = () => ({
  type: actions.userdata.LOGIN_EXTERNAL_ADFS,
});

/**
 * Action to log in ADFS attempt.
 * @function
 */
export const loginADFSAttempt = () => ({
  type: actions.userdata.LOGIN_ADFS_ATTEMPT,
});

/**
 * Action to refresh ADFS.
 * @function
 */
export const refreshADFS = () => ({
  type: actions.userdata.REFRESH_ADFS,
});

/**
 * Action to refresh ADFS attempt.
 * @function
 */
export const refreshADFSAttempt = () => ({
  type: actions.userdata.REFRESH_ADFS_ATTEMPT,
});

/**
 * Action for success login ADFS.
 * @function
 */
export const loginADFSSuccess = () => ({
  type: actions.userdata.LOGIN_ADFS_SUCCESS,
});

/**
 * Action for login error ADFS.
 * @function
 */
export const loginADFSError = () => ({
  type: actions.userdata.LOGIN_ADFS_ERROR,
});

/**
 * Action for fetching filter sets.
 * @function
 */
export const fetchFiltersets = () => ({
  type: actions.userdata.FETCH_FILTERSETS,
});

/**
 * Action for saving filter set.
 * @function
 * @param {} name -
 * @param {} filter -
 * @param {function} callback - action results callback
 */
export const saveFilterset = ({ name, filter }, callback) => ({
  type: actions.userdata.SAVE_FILTERSET,
  name,
  filter,
  callback,
});

/**
 * Action for selecting filter set.
 * @function
 * @param {string} filtersetId - filter set id
 */
export const selectFilterset = (filtersetId) => ({
  type: actions.userdata.SELECT_FILTERSET,
  filtersetId,
});

/**
 * Action for deleting filter set.
 * @function
 * @param {string} filtersetId - filter set id
 */
export const deleteFilterset = (filtersetId) => ({
  type: actions.userdata.DELETE_FILTERSET,
  filtersetId,
});

/**
 * Action to start business process.
 * @function
 */
export const fetchNotificationPreferences = () => ({
  type: actions.userSettings.FETCH_NOTIFICATION_PREFERENCES,
  payload: {},
});

/**
 * Action for setting notification preferences.
 * @function
 * @param {} notificationPreferences -
 */
export const setNotificationPreferences = (notificationPreferences) => ({
  type: actions.userSettings.SET_NOTIFICATION_PREFERENCES,
  payload: {
    notificationPreferences,
  },
});

/**
 * Action to toggle notification preference.
 * @function
 * @param {string} name -
 * @param {} value -
 * @param {} options - additional options
 * @param {function} options.callback - action results callback
 */
export const toggleNotificationPreference = (name, value, options = {}) => ({
  type: actions.userSettings.TOGGLE_NOTIFICATION_PREFERENCE,
  payload: {
    name,
    value,
    options: {
      callback: options?.callback || (() => {}),
    },
  },
});

/**
 * Action to toggle all notification preferences.
 * @function
 * @param {} value -
 * @param {object} options - additional options
 * @param {function} options.callback - action results callback
 */
export const toggleAllNotificationPreferences = (value, options = {}) => ({
  type: actions.userSettings.TOGGLE_NOTIFICATION_PREFERENCE,
  payload: {
    all: true,
    value,
    options: {
      callback: options?.callback || (() => {}),
    },
  },
});
