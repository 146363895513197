import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import masterdataApi from "../apis/masterdataApi";
import { useCallback, useMemo } from "react";
import documentHistoryProperties from "../contants/documentHistoryProperties";
import useProject from "../hooks/useProject";
import { isNotNull } from "cosmos-config/nql";
import _ from "lodash";
import { resourceCorrectionOfGateway } from "cosmos-config/utils";

const propertyQueries = _.compact(
  documentHistoryProperties.map((prop) => prop.query)
);

export interface DocumentHistoryEntry {
  id?: string | null;
  resourceId: string;
  principalId: string;
  type: string;
  projectCode: string;

  identifier?: number;
  resourcetype?: number;
  displayname?: string;
  deletestate?: number;
  fileextension?: string;
  createdAt?: number;
}

export interface UseDocumentHistoryProps {
  scope: "favourite" | "visited";
  principalId?: string;
  paging?: number;
}

const useDocumentHistory = ({
  scope,
  principalId,
  paging,
}: UseDocumentHistoryProps) => {
  const queryClient = useQueryClient();
  const { project } = useProject();

  const projectCode = useMemo(() => project?.code, [project]);

  const queryKey = useMemo(
    () => ["documents-history", scope, principalId, projectCode],
    [principalId, projectCode, scope]
  );

  const whereClause = useMemo(() => {
    return {
      principalId,
      type: scope,
      projectCode: projectCode,
    };
  }, [principalId, projectCode, scope]);

  const { data, isLoading } = useQuery<DocumentHistoryEntry[]>({
    queryKey,
    queryFn: () => {
      return masterdataApi.getMasterData(
        "RWE",
        "DOCUMENTS_HISTORY",
        propertyQueries,
        {
          where: {
            ...whereClause,
            createdAt: isNotNull(),
          },
          orderBy: { createdAt: "desc" },
          paging: { number: 1, size: paging || 20 },
        }
      );
    },
    enabled: principalId != null && scope != null,
    refetchOnWindowFocus: false,
  });

  const parsedData = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.map((d: any) => {
      const item = _(d.properties).keyBy("name").mapValues("value").value();
      return resourceCorrectionOfGateway(
        item,
        documentHistoryProperties
      ) as DocumentHistoryEntry;
    });
  }, [data]);

  const createMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (entry: DocumentHistoryEntry) => {
      return masterdataApi.postMasterDataEntry(
        "RWE",
        "DOCUMENTS_HISTORY",
        entry
      );
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (masterdataId: string) => {
      return masterdataApi.removeMasterDataEntry(
        "RWE",
        "DOCUMENTS_HISTORY",
        masterdataId
      );
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  return {
    data: parsedData,
    loading: useMemo(
      () => isLoading || createMutation.isPending || deleteMutation.isPending,
      [createMutation.isPending, deleteMutation.isPending, isLoading]
    ),
    createEntry: useCallback(
      (resourceId: string | null) => {
        if (principalId == null || projectCode == null) {
          throw new Error(
            "PrincipalId and project code cannot be null while creating document history entry!"
          );
        }

        if (resourceId == null) {
          throw new Error("Resource id cannot be null!");
        }

        const entry: DocumentHistoryEntry = {
          principalId,
          resourceId,
          type: scope,
          projectCode,
        };

        return createMutation.mutateAsync(entry);
      },
      [createMutation, principalId, projectCode, scope]
    ),
    deleteEntry: useCallback(
      (masterdataEntryId: string) => {
        return deleteMutation.mutateAsync(masterdataEntryId);
      },
      [deleteMutation]
    ),
  };
};

export default useDocumentHistory;
