export const SET_TEAMSPACES = 'SET_TEAMSPACES';
export const SET_SELECTED_TEAMSPACE = 'SET_SELECTED_TEAMSPACE';
export const FETCH_TEAMSPACES = 'FETCH_TEAMSPACES';
export const UPDATE_SELECTED_TEAMSPACE = 'UPDATE_SELECTED_TEAMSPACE';
export const PERSIST_SELECTED_TEAMSPACE = 'PERSIST_SELECTED_TEAMSPACE';
export const OPEN_TEAMSPACE_FOLDER = 'OPEN_TEAMSPACE_FOLDER';
export const SET_TEAMSPACE_FOLDERS = 'SET_TEAMSPACE_FOLDERS';
export const SET_TEAMSPACES_LOADING = 'SET_TEAMSPACES_LOADING';
export const RELOAD_TEAMSPACES = 'RELOAD_TEAMSPACES';
export const FETCH_TEAMSPACE_MEMBERS = 'FETCH_TEAMSPACE_MEMBERS';
export const ADD_TEAMSPACE_MEMBERS = 'ADD_TEAMSPACE_MEMBERS';
export const DELETE_TEAMSPACE = 'DELETE_TEAMSPACE';
export const DELETE_TEAMSPACE_MEMBERS = 'DELETE_TEAMSPACE_MEMBERS';
export const SET_TEAMSPACE_MEMBERS = 'SET_TEAMSPACE_MEMBERS';
export const SET_TEAMSPACE_MEMBERS_LOADING = 'SET_TEAMSPACE_MEMBERS_LOADING';
export const CLEAR_TEAMSPACE_DATA = 'CLEAR_TEAMSPACE_DATA';
export const FETCH_TEAMSPACE_FOLDERS = 'FETCH_TEAMSPACE_FOLDERS';
