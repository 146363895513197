import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import AccessManagementContext from "../contexts/AccessManagementContext";
import {
  Button,
  Dropdown,
  Form,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import AccessGrantInput from "./AccessGrantInput";
import accessLevelValueset from "../constants/acessLevelValueset";
import _ from "lodash";
import { LoadingOverlay, PancoTooltip } from "cosmos-components";
import styled from "styled-components";
import { Permission } from "../constants/Permission";
import { trimUINumber } from "cosmos-config/utils";
import classNames from "classnames";

const accessLevelMap = _(accessLevelValueset)
  .keyBy("value")
  .mapValues("label")
  .value();

const LevelCode = styled.div`
  border: 2px solid ${(props) => props.theme.base};
  border-radius: 100%;
  font-size: 8px;
  padding: 3px;
  font-weight: bold;
  color: ${(props) => props.theme.base};

  width: 23px;
  height: 23px;
  white-space: nowrap;
  text-align: center;
`;

type AccessLevelBadgeProps = {
  accessLevel?: number | number[];
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLAnchorElement>) => void;
};

const AccessLevelBadge = React.forwardRef<
  HTMLAnchorElement,
  AccessLevelBadgeProps
>(({ accessLevel, disabled, onClick }, ref) => {
  const level = Array.isArray(accessLevel) ? _.first(accessLevel) : accessLevel;

  if (level == null) {
    return null;
  }

  const code = _.take((accessLevelMap[level] || "").split(" "), 2)
    .map((w) => _.first(w))
    .filter((w) => w != null && /[a-zA-Z]/.test(w))
    .join("")
    .toUpperCase();

  if (disabled) {
    return (
      <PancoTooltip text={accessLevelMap[level]} placement="bottom">
        <LevelCode>{code}</LevelCode>
      </PancoTooltip>
    );
  }

  return (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <LevelCode>{code}</LevelCode>
    </a>
  );
});

type AccessLevelMenuItemProps = {
  permission: Permission;
  accessLevel: number;
  label: string;
};

const AccessLevelMenuItem = ({
  permission,
  accessLevel,
  label,
}: AccessLevelMenuItemProps) => {
  const { updatePermission } = useContext(AccessManagementContext);

  const active = Array.isArray(permission.accessLevel)
    ? permission.accessLevel.includes(accessLevel)
    : permission.accessLevel === accessLevel;

  return (
    <Dropdown.Item
      active={active}
      onClick={() => {
        if (permission.id != null && accessLevel !== permission.accessLevel) {
          updatePermission(permission.id, {
            ...permission,
            accessLevel: accessLevel,
          });
        }
      }}
    >
      {label}
    </Dropdown.Item>
  );
};

type SimpleAccessTableProps = {
  disabled?: boolean;
  selectable?: boolean;
  onSelectionChanged?: (permissions: Permission[]) => void;
  className?: string;
};

const SimpleAccessTable = ({
  disabled,
  selectable,
  onSelectionChanged,
  className,
}: SimpleAccessTableProps) => {
  const { resourcePermissions, revokePermission, loading } = useContext(
    AccessManagementContext
  );

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedIds(_.compact(resourcePermissions.map((rp) => rp.id)));
  }, [resourcePermissions]);

  const toggleSelected = useCallback(
    (permissionId: string | null) => {
      if (permissionId != null && selectable) {
        setSelectedIds((si) => {
          const selected = si.includes(permissionId);

          if (selected) {
            return si.filter((id) => id !== permissionId);
          } else {
            return _.compact([...si, permissionId]);
          }
        });
      }
    },
    [selectable]
  );

  useEffect(() => {
    if (onSelectionChanged != null && selectable) {
      onSelectionChanged(
        resourcePermissions.filter(
          (rp) => rp.id != null && selectedIds.includes(rp.id)
        )
      );
    }
  }, [selectedIds, onSelectionChanged, resourcePermissions, selectable]);

  return (
    <div className={classNames(className, "position-relative")}>
      <LoadingOverlay loading={loading}>
        <ListGroup>
          {_.sortBy(resourcePermissions, "permittedusercommonname")
            .filter((permission) => permission.active)
            .map((permission) => (
              <ListGroupItem
                className="py-2"
                onClick={() => {
                  toggleSelected(permission.id);
                }}
              >
                <div className="d-flex align-items-center">
                  {selectable && (
                    <Form.Check
                      type="checkbox"
                      id={permission.id || ""}
                      className="mr-2"
                      checked={selectedIds.includes(permission.id || "")}
                      onChange={(e) => {
                        // toggleSelected(permission.id);
                      }}
                    />
                  )}

                  <Dropdown>
                    <Dropdown.Toggle
                      disabled={disabled}
                      as={AccessLevelBadge}
                      id="access-level-badge-switch"
                      accessLevel={permission.accessLevel}
                    />

                    <Dropdown.Menu>
                      {accessLevelValueset.map((item) => (
                        <AccessLevelMenuItem
                          permission={permission}
                          accessLevel={item.value as number}
                          label={item.label}
                        />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <div className="ml-2">
                    {trimUINumber(permission.permittedusercommonname)}
                  </div>

                  {!disabled && (
                    <Button
                      variant="link"
                      className="p-0 text-danger ml-auto"
                      onClick={() => {
                        if (permission.id != null) {
                          revokePermission([permission.id]);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  )}
                </div>
              </ListGroupItem>
            ))}
        </ListGroup>

        {!disabled && <AccessGrantInput className="mt-2" />}
      </LoadingOverlay>
    </div>
  );
};

export default SimpleAccessTable;
