import {
  SET_FILTER_FOLDER_PROPERTIES,
  SET_FILTER_FOLDER,
} from '../Actions/types/filterFolder';

const initialState = {
  filterFolder: null,
  properties: [],
  filterFolderId: null,
};

const filterFolder = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_FOLDER:
      return {
        ...state,
        filterFolder: action.filterFolder,
      };
    case SET_FILTER_FOLDER_PROPERTIES:
      return {
        ...state,
        properties: action.properties,
        filterFolderId: action.filterFolderId,
      };
    default:
      return state;
  }
};

export default filterFolder;
