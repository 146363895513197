import React, { useMemo } from "react";
import { MouseEventHandler, ResponsivePie } from "@nivo/pie";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { CosmosChart, CosmosChartProps } from "../../types/CosmosChart";

const Rectangle = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 7px;
  background-color: ${(props) => props.color || "red"};
`;

const ChartWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ChartOverlay = styled.div`
  position: absolute;
  top: 45%;
  width: 100%;
  left: 0;
  text-align: center;
  transform: translateY(-45%);
  font-size: 4rem;
  font-weight: bold;
  color: ${(props) => props.theme.base};
  pointer-events: none;
`;

const PieChart = ({ data, onClick }: CosmosChartProps): CosmosChart => {
  const margin = 35;

  const docCount = useMemo(() => {
    if (data == null) {
      return 0;
    }

    return data.reduce((acc, cur) => acc + cur.value, 0);
  }, [data]);

  return (
    <ChartWrapper>
      <ResponsivePie
        data={data || []}
        isInteractive
        activeOuterRadiusOffset={8}
        animate
        arcLinkLabel="label"
        margin={{
          top: margin,
          right: margin / 2,
          bottom: margin,
          left: margin / 2,
        }}
        innerRadius={0.7}
        padAngle={0.7}
        cornerRadius={5}
        colors={{ scheme: "paired" }}
        onClick={(e) => {
          if (onClick != null) {
            onClick(e.data);
          }
        }}
        tooltip={({ datum: { color, label, value } }) => (
          <Card body>
            <Rectangle color={color} />
            {label}: <b>{value}</b>
          </Card>
        )}
        legends={[
          {
            anchor: "right",
            direction: "column",
            translateY: -70,
            itemWidth: 100,
            itemHeight: 25,
            symbolSize: 18,
            symbolShape: "circle",
          },
        ]}
      />
      <ChartOverlay>{docCount}</ChartOverlay>
    </ChartWrapper>
  );
};

export default PieChart;
