import * as actions from './types';

export const findDocumentMatches = (files) => ({
  type: actions.versionImport.FIND_DOCUMENT_MATCHES,
  payload: {
    files,
  },
});

export const setDocumentMatch = (fileIdentifier, documentMatch) => ({
  type: actions.versionImport.SET_DOCUMENT_MATCH,
  payload: {
    fileIdentifier,
    documentMatch,
  },
});

export const setDocumentMatches = (documentMatches) => ({
  type: actions.versionImport.SET_DOCUMENT_MATCHES,
  payload: {
    documentMatches,
  },
});
