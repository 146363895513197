/* eslint-disable func-names */
import { takeLatest, takeLeading, spawn, put } from 'redux-saga/effects';
import * as actions from '../../Actions/types';
import callApi from '../Effects/callApi';
import projectApi from '../../Api/project';
import {
  fetchWidgets,
  setLoadingProject,
  setWidgets,
} from '../../Actions/project';

export default function* widgetSaga(projectCode) {
  yield takeLatest(actions.project.FETCH_WIDGETS, function* ({ callback }) {
    try {
      yield put(setLoadingProject(true, 'Loading project level widgets.'));
      const widgets = yield callApi(projectApi.getWidgets, projectCode);
      yield put(setWidgets(widgets));
      yield spawn(callback);
    } catch (err) {
      console.error(err);
    } finally {
      yield put(setLoadingProject(false));
    }
  });

  yield takeLeading(
    actions.project.SAVE_ALL_WIDGETS,
    function* ({ widgets, callback }) {
      try {
        yield put(setLoadingProject(true, 'Saving dashboard layout.'));
        yield callApi(projectApi.saveAllWidgets, projectCode, widgets);
        yield spawn(callback);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setLoadingProject(false));
        yield put(fetchWidgets());
      }
    }
  );

  yield takeLeading(
    actions.project.RESTORE_DEFAULT_WIDGETS,
    function* ({ callback }) {
      try {
        yield put(
          setLoadingProject(true, 'Restoring default dashboard layout.')
        );
        yield callApi(projectApi.removeAllWidgets, projectCode);
        yield spawn(callback);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setLoadingProject(false));
        yield put(fetchWidgets());
      }
    }
  );
}
