import { property } from "cosmos-config/generator";
import { trimUINumber } from "cosmos-config/utils";

const masterValuesetItem = [
  property("Label", "label"),
  property("Key", "value"),
  property("Reference", "filter"),
  property("Creator", "createdBy")
    .tableCell((v) => trimUINumber(v))
    .excludeForm(),
  property("Creation Date").dateAttribute("createdAt").excludeForm(),
];

export default masterValuesetItem;
