import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useAccessLevelOptions = () => {
  const { t } = useTranslation("module");

  return useMemo(
    () => [
      {
        label: t("accessmanagement.level_public", { defaultValue: "Internal" }),
        value: 10,
        description: t("accessmanagement.level_public_desc", {
          defaultValue: "Everybody can see and update.",
        }),
      },
      {
        label: t("accessmanagement.level_protected", {
          defaultValue: "Confidential",
        }),
        value: 7,
        description: t("accessmanagement.level_protected_desc", {
          defaultValue: "Everybody can see only. Nobody can update.",
        }),
      },
      {
        label: t("accessmanagement.level_readonly", {
          defaultValue: "Read Only",
        }),
        value: 5,
        description: t("accessmanagement.level_readonly_desc", {
          defaultValue: "Everybody can see only. No public contributions.",
        }),
      },
      {
        label: t("accessmanagement.level_strictly_confidential", {
          defaultValue: "Strictly Confidential",
        }),
        value: 3,
        description: t("accessmanagement.level_strictly_confidential_desc", {
          defaultValue: "Nobody can access. Strictly confidential.",
        }),
      },
      {
        label: t("accessmanagement.level_unlisted", {
          defaultValue: "Unlisted",
        }),
        value: 0,
        description: t("accessmanagement.level_unlisted_desc", {
          defaultValue: "Invisible. Not searchable.",
        }),
      },
    ],
    [t]
  );
};

export default useAccessLevelOptions;
