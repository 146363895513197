import { NumericInput, PancoSelect } from "cosmos-components";
import PropTypes from "prop-types";
import { structure, structureType } from "cosmos-config/generator";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useCallback } from "react";

const structureTypeOptions = [
  {
    label: "Main Structure (Project Explorer)",
    value: structureType.MAIN,
  },
  {
    label: "Folder Structure (Folder Explorer)",
    value: structureType.FOLDER,
  },
];

const resourceTypeOptions = [
  {
    label: "Display Documents",
    value: 2,
  },
  {
    label: "Display Folders",
    value: 1,
  },
];

const PropertyStructures = ({ structures, onChange, className }) => {
  const handleStructureChange = useCallback(
    (idx, updateCallback = (s) => s) => {
      if (idx === structures.length) {
        onChange(
          [
            ...structures,
            updateCallback(structure(0, structureType.MAIN)),
          ].filter((w) => w != null)
        );
      } else {
        onChange(
          structures
            .map((w, i) => {
              if (idx === i) {
                return updateCallback(w);
              }

              return w;
            })
            .filter((w) => w != null)
        );
      }
    },
    [structures, onChange]
  );

  return (
    <div className={className}>
      {structures.map((s, idx) => (
        <Row>
          <Col sm={5}>
            <PancoSelect
              value={[s.type]}
              options={structureTypeOptions}
              onChange={({ value }) => {
                const [v] = value;
                handleStructureChange(idx, (s) => ({ ...s, type: v }));
              }}
            />
          </Col>

          <Col sm={4}>
            <PancoSelect
              value={[s.resourceType]}
              options={resourceTypeOptions}
              onChange={({ value }) => {
                const [v] = value;
                handleStructureChange(idx, (s) => ({ ...s, resourceType: v }));
              }}
            />
          </Col>

          <Col sm={2}>
            <NumericInput
              value={s.level}
              onChange={({ value }) => {
                handleStructureChange(idx, (s) => ({ ...s, level: value }));
              }}
            />
          </Col>

          <Col sm={1} className="text-center">
            <Button
              variant="link"
              className="text-danger"
              onClick={() => {
                handleStructureChange(idx, () => null);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </Col>
        </Row>
      ))}

      <Button
        variant="link"
        onClick={() => {
          handleStructureChange(structures.length);
        }}
        className="mt-2"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        Add Structure
      </Button>
    </div>
  );
};

PropertyStructures.propTypes = {
  structures: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

PropertyStructures.defaultProps = {
  structures: [],
  onChange: () => {},
  className: null,
};

export default PropertyStructures;
