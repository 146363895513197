import React, { useMemo } from "react";
import { CosmosWidgetOnSelectCallback } from "../../types/CosmosWidget";
import SimpleTable from "../../../../components/SimpleTable";
import { property } from "cosmos-config/generator";
import _ from "lodash";
import DocumentIcon from "../../../../components/DocumentIcon";

export type DocumentLike = {
  fileextension?: string;
  createdAt?: number;
} & Record<string, any>;

export interface DocumentsTableProps {
  documents?: DocumentLike[];
  onSelect?: CosmosWidgetOnSelectCallback;
  dateLabel?: string;
  includeDate?: boolean;
}

const columnProperties = [
  property("", "fileextension").includeTable(true, 30),
  property("Name", "displayname"),
];

const DocumentsTable = ({
  documents,
  onSelect,
  dateLabel,
  includeDate,
}: DocumentsTableProps) => {
  const properties = useMemo(() => {
    const dateProperty = includeDate
      ? property(dateLabel || "Opened").dateAttribute("createdAt")
      : null;

    return _.compact([...columnProperties, dateProperty]);
  }, [dateLabel, includeDate]);

  return (
    <SimpleTable<DocumentLike>
      data={documents}
      properties={properties}
      renderCell={(name, value, label, res) => {
        if (name === "fileextension") {
          return <DocumentIcon document={res} />;
        }

        return label;
      }}
      onDoubleClick={(resource) => {
        if (onSelect != null) {
          onSelect("document", resource?.identifier);
        }
      }}
    />
  );
};

export default DocumentsTable;
