import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Flag, { flagCodeType } from "national-flag-icons";
import styled from "styled-components";
import _ from "lodash";

const languages = ["en", "de", "fr", "it", "es", "nl"];

const countryCodesMap = _(languages)
  .keyBy((c) => c)
  .mapValues((v) => {
    switch (v) {
      case "en":
        return "gb" as flagCodeType;
      default:
        return v as flagCodeType;
    }
  })
  .value();

const DropdownToggle = styled(Dropdown.Toggle)`
  &:after {
    display: none;
  }
`;

declare type LanguageSwitcherProps = {
  className?: string;
};

const LanguageSwitcher = ({ className }: LanguageSwitcherProps) => {
  const { i18n } = useTranslation();

  return (
    <div>
      <Dropdown className={className}>
        <DropdownToggle variant="link">
          <Flag flagCode={countryCodesMap[i18n.language]} height={14} />
        </DropdownToggle>
        <Dropdown.Menu style={{ minWidth: 0 }}>
          {languages.map((lang) => (
            <Dropdown.Item
              title={lang}
              className="p-2"
              key={lang}
              onClick={() => {
                i18n.changeLanguage(lang);
              }}
            >
              <Flag flagCode={countryCodesMap[lang]} height={14} />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LanguageSwitcher;
