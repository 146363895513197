import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import FormProperty from "./form/FormProperty";
import { Property } from "cosmos-config/lib/property/property";
import useCurrentUser from "../hooks/useCurrentUser";
import { OnChangeParam } from "cosmos-components";

export type ResourceType = Record<string, any>;

export type ChildrenParam = {
  submit: () => void;
  disabled?: boolean;
  update: (propertyName: string, value: any) => void;
  resource?: ResourceType;
};

export interface UniversalFormProps {
  onSubmit?: (resourceId?: string) => void;
  resource?: ResourceType;
  disabled?: boolean;
  children?: (param: ChildrenParam) => React.ReactNode;
  onUpdateResource?: (id: string, name: string, value: any) => void;
  inline?: boolean;
}

export interface SimpleUniversalFormProps extends UniversalFormProps {
  properties?: Property[];
}

const SimpleUniversalForm = ({
  properties,
  onSubmit,
  resource,
  disabled,
  children,
  onUpdateResource,
  inline,
}: SimpleUniversalFormProps) => {
  const { defaultPositionId } = useCurrentUser();
  const [editableProperties, setEditableProperties] = useState<Property[]>([]);

  useEffect(() => {
    const newProperties = _(properties)
      .filter((c) => c.editable && !c.isHidden(resource || {}))
      .value();

    setEditableProperties((oldProperties) => {
      return _(oldProperties)
        .map("name")
        .isEqual(_(newProperties).map("name").value())
        ? oldProperties
        : newProperties;
    });
  }, [properties, resource]);

  const updateFuncMap = useMemo(() => {
    return _(properties)
      .keyBy("name")
      .mapValues("update")
      .omitBy((v) => v == null)
      .value();
  }, [properties]);

  const handleOnChange = useCallback(
    ({ name, value }: OnChangeParam<any>) => {
      if (resource != null) {
        const updateFunction = name != null ? updateFuncMap[name] : null;

        const updatedValue =
          updateFunction != null
            ? updateFunction(value, resource, defaultPositionId || undefined)
            : value;

        if (onUpdateResource != null) {
          onUpdateResource(resource.id, name || "", updatedValue);
        }
      }
    },
    [onUpdateResource, resource, updateFuncMap, defaultPositionId]
  );

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {editableProperties.map((property) => (
        <FormProperty
          property={property}
          onChange={handleOnChange}
          value={resource != null ? resource[property.name] : null}
          disabled={disabled}
          inline={inline}
          filter={resource}
        />
      ))}

      <div className="mt-3">
        {children &&
          children({
            submit: () => {
              if (onSubmit != null) {
                onSubmit(resource?.id);
              }
            },
            disabled,
            update: (propertyName: string, value: any) => {
              handleOnChange({
                name: propertyName,
                value,
              });
            },
            resource,
          })}
      </div>
    </Form>
  );
};

SimpleUniversalForm.defaultProps = {
  properties: [],
};

export default SimpleUniversalForm;
