import React, { useMemo } from "react";
import classNames from "classnames";
import { LoadingOverlay, ContextMenu } from "cosmos-components";
import useDocArea from "../../hooks/useDocArea";
import SimpleTable, { RenderCellCallback } from "../../components/SimpleTable";
import { Property } from "cosmos-config/lib/property/property";
import { ValuesetItem } from "../../contants/valuesetItem";

export interface ValuesetTableProps {
  entries?: ValuesetItem[];
  valuesetName?: string;
  propertiesMap?: Property[];
  masterValueset?: boolean;
  onEdit?: (valuesetItem: ValuesetItem) => void;
  renderCell?: RenderCellCallback<ValuesetItem>;
  pageSize?: number;
  onPageChanged?: () => void;
}

const ValuesetTable = ({
  entries,
  valuesetName,
  propertiesMap,
  masterValueset,
  onEdit,
  renderCell,
  pageSize,
  onPageChanged,
}: ValuesetTableProps) => {
  const { loading, removeCustomValuesetItems } = useDocArea();

  const properties = useMemo(
    () =>
      (propertiesMap || []).filter((prop) => !prop.system && prop.tableColumn),
    [propertiesMap]
  );

  const renderMenu = (selectedEntries: ValuesetItem[]) => {
    const multipleSelected = selectedEntries.length > 1;
    const atLeastOneSelected = selectedEntries.length > 0;

    const [first] = selectedEntries;

    return (
      <>
        <ContextMenu.Item
          disabled={!atLeastOneSelected || multipleSelected}
          onClick={() => {
            if (onEdit != null) {
              onEdit(first);
            }
          }}
        >
          Edit
        </ContextMenu.Item>
        <ContextMenu.Item separator />
        <ContextMenu.Item
          disabled={!atLeastOneSelected}
          onClick={() => {
            if (valuesetName == null) {
              throw new Error("Valueset Name cannot be null!");
            }

            removeCustomValuesetItems(
              valuesetName,
              selectedEntries.map((entry) => entry.id),
              masterValueset
            );
          }}
        >
          Remove
        </ContextMenu.Item>
      </>
    );
  };

  return (
    <div className="position-relative">
      <LoadingOverlay loading={loading}>
        <SimpleTable<ValuesetItem>
          properties={properties}
          data={entries}
          renderContextMenu={renderMenu}
          renderCell={(propertyName, value, label, entry) => {
            if (renderCell != null) {
              return renderCell(propertyName, value, label, entry);
            }

            return (
              <span
                className={classNames({
                  "text-muted": (entry?.usedCount || 0) <= 0,
                })}
              >
                {label}
              </span>
            );
          }}
          pageSize={pageSize}
          onPageChanged={onPageChanged}
        />
      </LoadingOverlay>
    </div>
  );
};

export default ValuesetTable;
