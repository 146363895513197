/**
 * @enum {string}
 * @category Property
 * @readonly
 */
enum DependencyType {
  STRICT = "STRICT",
  LOOSE = "LOOSE",
}

export default DependencyType;
