import { property } from 'cosmos-config/generator';

const importActivity = [
  property('Timestamp', 'timestamp').dateAttribute('time').informational(),
  property('Type', 'type').informational(),
  property('Message', 'message').informational(),
];

export const importActivityTypes = {
  ROW_LOADED: 'ROW_LOADED',
  ISSUE_FOUND: 'ISSUE_FOUND',
  UPDATED_IMPORT_COLUMN_MAPPING: 'UPDATED_IMPORT_COLUMN_MAPPING',
  UPDATED_IMPORT_VALUE_MAPPING: 'UPDATED_IMPORT_VALUE_MAPPING',
  AUTO_REMOVED_CONTENT_DUPLICATES: 'AUTO_REMOVED_CONTENT_DUPLICATES',
  AUTO_REMOVED_EMPTY_CONTENT: 'AUTO_REMOVED_EMPTY_CONTENT',
  MANUALLY_REMOVED_CONTENT_DUPLICATE: 'MANUALLY_REMOVED_CONTENT_DUPLICATE',
  ADDED_CUSTOM_VALUESET_ITEM: 'ADDED_CUSTOM_VALUESET_ITEM',
  MANUALLY_REMOVED_EXISTING_DUPLICATES: 'MANUALLY_REMOVED_EXISTING_DUPLICATES',
  MANUALLY_REMOVED_FILE: 'MANUALLY_REMOVED_FILE',
};

export default importActivity;
