import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import Fuse from "fuse.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { Col, Dropdown, Row } from "react-bootstrap";
import { LoadingOverlay } from "cosmos-components";
import useCoreSelector from "../../../hooks/useCoreSelector";
import useProject from "../../../hooks/useProject";
import useCoreDispatch from "../../../hooks/useCoreDispatch";
import userManagementSlice from "../../../slices/userManagementSlice";
import { exportAllGroupMembersExcel } from "../../../actions/userManagementActions";
import UserManagementContext from "../contexts/UserManagementContext";
import userProperties from "../constants/userProperties";
import GroupsList from "./GroupsList";
import GroupUsers from "./GroupUsers";
import AccessRequestsButton from "./AccessRequestsButton";
import { CosmosCoreRootState } from "../../../store";
import { ProjectMember } from "../../../contexts/ProjectContext";
import useProjectCompetence from "../../../hooks/useProjectCompetence";

const UserManagement = () => {
  const dispatch = useCoreDispatch();
  const { loading: projectLoading, members } = useProject();

  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const loading = useCoreSelector<CosmosCoreRootState, boolean>(
    (state) => state.usermanagement.loading
  );

  const { loading: competenceLoading } = useProjectCompetence();

  const handleSelectGroup = (groupName: string) => {
    dispatch(userManagementSlice.actions.selectGroup(groupName));
  };

  const fuse = useRef<any>(null);

  useEffect(() => {
    const users = _(members)
      .valuesIn()
      .flatMap((x) => x)
      .value();

    const keys = _(userProperties).filter("tableColumn").map("name").value();
    fuse.current = new Fuse<ProjectMember>(users, {
      keys,
      shouldSort: true,
      threshold: 0.2,
    });

    // return _.map(fuse.search(value), "item");
  }, [members]);

  return (
    <div className="position-relative overflow-hidden h-100">
      <UserManagementContext.Provider
        value={{
          searchQuery,
          search: (query) => {
            setSearchQuery(query);
          },
          matchingMembers: useMemo(() => {
            if (fuse.current != null && !_.isEmpty(searchQuery)) {
              const results = fuse.current.search(searchQuery);
              return _(results).map("item").groupBy("groupName").value();
            }

            return {};
          }, [searchQuery]),
          getSubGroups: useCallback(
            (groupName) => {
              return _(members[groupName])
                .filter("group")
                .sortBy((g) => String(g.displayName).toLowerCase())
                .value();
            },
            [members]
          ),
          getUsers: useCallback(
            (groupName) => {
              if (groupName != null && members != null) {
                const principals = members[groupName] || [];
                return principals.filter((p) => !p.group);
              }

              return [];
            },
            [members]
          ),
        }}
      >
        <LoadingOverlay
          loading={loading || projectLoading || competenceLoading}
        >
          <Row className="h-100">
            <Col lg={4} xl={3} className="h-100 d-flex flex-column">
              <AccessRequestsButton />
              <div className="d-flex align-items-center">
                <h5 className="mt-3 mr-auto">Groups</h5>
                <Dropdown className="d-inline-block" alignRight>
                  <Dropdown.Toggle variant="link" className="p-0">
                    <FontAwesomeIcon icon={faEllipsis} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => dispatch(exportAllGroupMembersExcel())}
                    >
                      Export All Members (xls)
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="flex-grow-1 overflow-auto">
                <GroupsList onSelect={handleSelectGroup} />
              </div>
            </Col>
            <Col lg={8} xl={9} className="h-100">
              <GroupUsers />
            </Col>
          </Row>
        </LoadingOverlay>
      </UserManagementContext.Provider>
    </div>
  );
};

export default UserManagement;
