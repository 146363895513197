import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useTable,
  useSortBy,
  useResizeColumns,
  useColumnOrder,
  useRowSelect,
  useExpanded,
} from "react-table";
import _ from "lodash";
import useRepositoryTableActions from "./useRepositoryTableActions";
import useDebounce from "../../hooks/useDebounce";
import useTableColumnOrder from "../../hooks/table/useTableColumnOrder";
import useTableSelectMultiple from "../../hooks/table/useTableSelectMultiple";
import {
  Body,
  BodyOverlay,
  Table,
  TableHeader,
  TableWrapper,
} from "../tableFragments";
import systemColumns from "./systemColumns";
import { TableColumnOrder } from "../../providers/ColumnsProvider";
import Resource from "../../types/resource";
import RepositoryTableBody, { RenderCellCallback } from "./RepositoryTableBody";
import RepositoryTableHeader, { OrderBy } from "./RepositoryTableHeader";
import { RepositoryTableReactiveProps } from "./RepositoryTableRow";
import { Property } from "cosmos-config/lib/property/property";
import { useColumns } from "../../hooks";
import { Column } from "react-table";
import useResizeObserver from "@react-hook/resize-observer";

const getColumnPropertyName = (columnId: string) =>
  columnId === "fileextensionicon" ? "contenttype" : columnId;

const getPropertyColumnId = (propertyName: string) =>
  "contenttype" === propertyName ? "fileextensionicon" : propertyName;

export type RepositoryTableHandle = {
  toggleAllRowsExpanded: (value?: boolean) => void;
};

export interface RepositoryTableProps extends RepositoryTableReactiveProps {
  properties?: Property[];
  columnOrder?: TableColumnOrder<Resource>[];
  onColumnReorder?: (columnOrder: TableColumnOrder<Resource>[]) => void;
  data?: Resource[];
  onSelect?: (resources: Resource[]) => void;
  orderBy?: OrderBy;
  setOrderBy?: (orderBy: OrderBy) => void;
  children?: React.ReactNode;
  multiSelection?: boolean;
  simplify?: boolean;
  contextMenu?: React.ReactNode;
  overlay?: React.ReactNode;
  renderCell?: RenderCellCallback;
}

const RepositoryTable = forwardRef<RepositoryTableHandle, RepositoryTableProps>(
  (
    {
      properties,
      columnOrder,
      onColumnReorder,
      data,
      onSelect,
      orderBy,
      setOrderBy,
      children,
      multiSelection,
      simplify,
      editMode,
      contextMenu,
      overlay,
      renderCell,
      watchedColumns,
    },
    ref
  ) => {
    const [bodyWidth, setBodyWidth] = useState<number>(0);
    const { prepareCustomProperties } = useColumns();

    const [columns, setColumns] = useState<Column<Resource>[]>([]);

    const wrapperRef = useRef<HTMLDivElement | null>(null);

    useResizeObserver<HTMLDivElement>(
      wrapperRef,
      useCallback(({ target }) => {
        const newBodyWidth = target.scrollLeft + target.clientWidth;
        setBodyWidth((cbw) => (newBodyWidth !== cbw ? newBodyWidth : cbw));
      }, [])
    );

    const {
      headerGroups,
      prepareRow,
      rows,
      allColumns,
      selectedFlatRows,
      toggleEditMode,
      setSortBy,
      toggleAllRowsExpanded,
    } = useTable<Resource>(
      {
        columns,
        data: React.useMemo(() => data || [], [data]),
        initialState: {
          pageIndex: 0,
          sortBy: _(orderBy)
            .map((v, id) => ({ id, desc: v === "desc" }))
            .value(),
          systemColumns,
        },
        manualSortBy: true,
        autoResetExpanded: false,
        multiSelection,
        columnPositions: columnOrder,
        simplify,
        autoResetSelectedRows: true,
        onColumnPositionsChange: useCallback(
          (cp: TableColumnOrder<Resource>[]) => {
            if (onColumnReorder != null && _.compact(cp).length > 0) {
              onColumnReorder(cp);
            }
          },
          []
        ),
      },
      useSortBy,
      useResizeColumns,
      useColumnOrder,
      useExpanded,
      useRowSelect,
      useRepositoryTableActions,
      useTableSelectMultiple,
      useTableColumnOrder
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          toggleAllRowsExpanded,
        };
      },
      [toggleAllRowsExpanded]
    );

    useEffect(() => {
      if (Array.isArray(properties) && properties.length > 0) {
        setColumns(prepareCustomProperties(properties));
      }
    }, [prepareCustomProperties, properties]);

    useEffect(() => {
      setSortBy(
        _(orderBy)
          .map((v, id) => ({
            id: getPropertyColumnId(id),
            desc: v === "desc",
          }))
          .value()
      );
    }, [orderBy, setSortBy]);

    useEffect(() => {
      if (onSelect != null) {
        const documents = selectedFlatRows
          .map((d) => d.original)
          .filter((d) => !d.virtual);
        onSelect(documents);
      }
    }, [selectedFlatRows]);

    const selectedRowIds = useMemo(
      () => selectedFlatRows.map((fr) => fr.id),
      [selectedFlatRows]
    );

    useEffect(() => {
      toggleEditMode(!!editMode);
    }, [editMode, toggleEditMode]);

    const debouncedBodyWidth = useDebounce(bodyWidth, 25);

    const renderTableBody = () => (
      <RepositoryTableBody
        rows={rows}
        prepareRow={prepareRow}
        width={debouncedBodyWidth}
        renderCell={renderCell}
        editMode={editMode}
        watchedColumns={watchedColumns}
        properties={properties}
      >
        {children}
      </RepositoryTableBody>
    );

    return (
      <TableWrapper
        ref={wrapperRef}
        onScroll={({ target }) => {
          //@ts-ignore
          const newBodyWidth = target.scrollLeft + target.offsetWidth;
          setBodyWidth((cbw) => {
            return newBodyWidth !== cbw ? newBodyWidth : cbw;
          });
        }}
      >
        <Table editing={editMode}>
          <TableHeader>
            {headerGroups.map((headerGroup) => (
              <RepositoryTableHeader
                key={"main-header"}
                headerGroup={_.cloneDeep(headerGroup)}
                editMode={editMode}
                onOrderByToggle={(columnName: string) => {
                  const propertyName = getColumnPropertyName(columnName);
                  if (setOrderBy != null) {
                    setOrderBy({
                      [columnName]:
                        orderBy?.[propertyName] === "asc" ? "desc" : "asc",
                    });
                  }
                }}
                allColumns={allColumns}
                selectedRowIds={selectedRowIds}
              />
            ))}
          </TableHeader>
          <Body>
            {React.isValidElement(contextMenu)
              ? React.cloneElement(contextMenu, {}, renderTableBody())
              : renderTableBody()}

            {React.isValidElement(overlay) && (
              <BodyOverlay>{overlay}</BodyOverlay>
            )}
          </Body>
        </Table>
      </TableWrapper>
    );
  }
);

RepositoryTable.displayName = "RepositoryTable";

export default RepositoryTable;
