import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { Project } from "../contexts/ProjectContext";
import { CosmosCoreRootState } from "../store";

export const getProjects = (state: CosmosCoreRootState) =>
  state.project.projects;
export const getOpenedProjectCode = (state: CosmosCoreRootState) =>
  state.project.openedProjectCode;
export const getProjectMembers = (state: CosmosCoreRootState) =>
  state.project.members;
export const getProjectReducerState = (state: CosmosCoreRootState) =>
  state.project.state;
export const getProjectSpocs = (state: CosmosCoreRootState) =>
  state.project.spocs;
export const getOpenedProject = createSelector(
  [getProjects, getOpenedProjectCode],
  (projects, openedProjectCode) => {
    if (openedProjectCode == null) {
      return null;
    }

    return projects.find((p: Project) => p.code === openedProjectCode) || null;
  }
);

export const getResourceFolderId = createSelector(
  getOpenedProject,
  (project) => project?.resourceId
);

export const getMemberGroups = createSelector(
  [getProjectMembers],
  (members) => {
    return _(members)
      .valuesIn()
      .flatMap((m) => m)
      .filter("group")
      .value();
  }
);
