import React, { useEffect, useState } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import PropertyTypeSelector from "../property-editor/PropertyTypeSelector";
import { Property } from "cosmos-config/lib/property/property";
import { PropertyEditorModalProps } from "../../types";

export interface ChangePropertyTypeModalProps extends PropertyEditorModalProps {
  propertyName?: string;
  onChange?: (property: Partial<Property>) => void;
}

const ChangePropertyTypeModal = ({
  propertyName,
  show,
  onClose,
  onChange,
}: ChangePropertyTypeModalProps) => {
  const [resource, setResource] = useState<Partial<Property>>({});

  useEffect(() => {
    if (!show) {
      setResource({});
    }
  }, [show]);

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>Change Property Type</Modal.Title>
        <CloseButton className="close-icon" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <PropertyTypeSelector
          propertyName={propertyName}
          resource={resource}
          onChange={(resource) => setResource(resource)}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right">
          <Button
            variant="danger"
            onClick={() => {
              if (onChange != null) {
                onChange(resource);
              }
            }}
          >
            Change Type
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePropertyTypeModal;
