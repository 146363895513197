import {
  faArrowRight,
  faFileExport,
  faFileImport,
  faFolderTree,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, useState } from "react";
import { Button, CloseButton, Form, Modal } from "react-bootstrap";
import AccessManagementContext from "../contexts/AccessManagementContext";
import ModuleFolderPermissionsImporter from "./ModuleFolderPermissionsImporter";
import useFolderProperty from "../../../hooks/useFolderProperty";

type FolderPermissionsImporterFeatureProps = {
  disabled?: boolean;
  folderType?: string;
  className?: string;
};

const FolderPermissionsImporterFeature = ({
  disabled,
  folderType,
  className,
}: FolderPermissionsImporterFeatureProps) => {
  const [showFolderImportModal, setShowFolderImportModal] =
    useState<boolean>(false);

  const { getFolderTypeLabel } = useFolderProperty(folderType);

  if (folderType == null) {
    return null;
  }

  return (
    <div className={className}>
      <Button
        variant="link"
        disabled={disabled}
        onClick={() => setShowFolderImportModal(true)}
      >
        <FontAwesomeIcon icon={faFolderTree} className="mr-2" />
        Import from other {getFolderTypeLabel(false)}
      </Button>

      <Modal
        show={showFolderImportModal}
        onClose={() => setShowFolderImportModal(false)}
      >
        <Modal.Header>
          <Modal.Title>
            Import from other {getFolderTypeLabel(false)}
          </Modal.Title>
          <CloseButton
            className="close-icon"
            onClick={() => setShowFolderImportModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <ModuleFolderPermissionsImporter
            folderType={folderType}
            onImportComplete={() => setShowFolderImportModal(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export interface PermissionsImporterProps {
  disabled?: boolean;
  folderType?: string | null;
}

const PermissionsImporter = ({
  disabled,
  folderType,
}: PermissionsImporterProps) => {
  const { exportPermissions, importPermissionsFiles } = useContext(
    AccessManagementContext
  );
  const files = useRef<FileList | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [filesCount, setFilesCount] = useState(0);

  const handleFileImport = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (files.current != null) {
      const fls = Array.from(files.current);
      importPermissionsFiles(fls).then(() => {
        if (fileInputRef.current != null) {
          fileInputRef.current.value = "";
        }

        if (files.current != null) {
          files.current = null;
        }

        setFilesCount(0);
      });
    }
  };

  return (
    <div className="d-flex align-items-center">
      <FolderPermissionsImporterFeature
        disabled={disabled}
        folderType={folderType || undefined}
        className="border-right"
      />

      <Button
        className="mr-2"
        variant="link"
        disabled={disabled}
        onClick={() => {
          exportPermissions();
        }}
      >
        <FontAwesomeIcon icon={faFileExport} className="mr-2" />
        Export (Excel)
      </Button>

      <FontAwesomeIcon icon={faArrowRight} className="mr-3 text-dark" />

      <Form onSubmit={handleFileImport} inline>
        <Form.File
          multiple={false}
          ref={fileInputRef}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            files.current = e.target.files;
            if (files.current != null) {
              setFilesCount(files.current.length);
            }
          }}
        />

        <Button type="submit" disabled={filesCount <= 0} variant="link">
          <FontAwesomeIcon icon={faFileImport} className="mr-2" />
          Import (Excel)
        </Button>
      </Form>
    </div>
  );
};

export default PermissionsImporter;
