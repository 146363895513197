import { ProjectPortal } from "../../contants/projectPortal";
import GatewayApi from "../conf/GatewayApi";

const gatewayApi = GatewayApi.build();

const getProjectPortals = (projectCode: string) => {
  return gatewayApi
    .get<ProjectPortal[]>(`/project/${projectCode}/portals`)
    .then((response) => Promise.resolve(response.data));
};

const createProjectPortal = (
  projectCode: string,
  projectPortal: Partial<ProjectPortal>
) => {
  return gatewayApi
    .post(`/project/${projectCode}/portals`, projectPortal)
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const updateProjectPortal = (
  projectCode: string,
  projectPortal: Partial<ProjectPortal>
) => {
  return gatewayApi
    .put(`/project/${projectCode}/portals/${projectPortal.id}`, projectPortal)
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const deleteProjectPortal = (projectCode: string, projectPortalId: string) => {
  return gatewayApi
    .delete(`/project/${projectCode}/portals/${projectPortalId}`)
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const projectPortalsApi = {
  getProjectPortals,
  createProjectPortal,
  updateProjectPortal,
  deleteProjectPortal,
};

export default projectPortalsApi;
