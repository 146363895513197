import React, { useMemo } from "react";
import { ResponsiveChoropleth } from "@nivo/geo";
import { CosmosChart, CosmosChartProps } from "../../types/CosmosChart";
import geoFeatures from "./geoFeatures.json";

export type ChoroplethChartScope = "EU" | "NA" | "SA" | "AF";

export interface ChoroplethChartProps extends CosmosChartProps {
  height?: number;
  scope?: ChoroplethChartScope;
}

type ScopeCoordsProps = {
  projectionTranslation: [number, number];
  projectionRotation: [number, number, number];
  projectionScale: number;
};

const ChoroplethChart = ({
  data,
  height,
  onClick,
  scope,
}: ChoroplethChartProps): CosmosChart => {
  const domainMax = useMemo(() => {
    if (data == null) {
      return 0;
    }

    return Math.max(...data.map((v) => v.value || 0));
  }, [data]);

  const scopeCoords: ScopeCoordsProps = useMemo(() => {
    switch (scope) {
      default:
      case "EU":
        return {
          projectionTranslation: [0.5, 0.5],
          projectionRotation: [-15, -48, 0],
          projectionScale: 800,
        };
      case "NA":
        return {
          projectionTranslation: [0.5, 1],
          projectionRotation: [105, 0, 0],
          projectionScale: 160,
        };
      case "SA":
        return {
          projectionTranslation: [1, 0.2],
          projectionRotation: [0, 0, 0],
          projectionScale: 200,
        };
      case "AF":
        return {
          projectionTranslation: [1, 0.2],
          projectionRotation: [0, 0, 0],
          projectionScale: 200,
        };
    }
  }, [scope]);

  return (
    <div style={{ height: height || 300 }}>
      <ResponsiveChoropleth
        data={data}
        features={geoFeatures}
        colors="blues"
        domain={[0, domainMax]}
        unknownColor="#eee"
        label="properties.name"
        projectionType="mercator"
        graticuleLineColor="#eee"
        borderWidth={0.5}
        borderColor="#152538"
        onClick={onClick}
        enableGraticule
        {...scopeCoords}
      />
    </div>
  );
};

export default ChoroplethChart;
