import React, { useEffect, useMemo, useState } from "react";

import SimpleUniversalForm from "../../../../components/SimpleUniversalForm";
import {
  ProjectPortal,
  ProjectPortalRule,
} from "../../../../contants/projectPortal";
import useForm from "../../../../hooks/useForm";
import projectPortalProperties from "../../../../contants/projectPortalProperties";
import { Button } from "react-bootstrap";
import { useProject, useProperties } from "../../../../hooks";
import { PancoSelect } from "cosmos-components";
import SimpleFormGroup from "../../../../components/SimpleFormGroup";
import PortalRuleEditor from "./PortalRuleEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Property } from "cosmos-config/lib/property/property";
import _ from "lodash";

export interface ProjectPortalFormProps {
  portal?: Partial<ProjectPortal>;
  onSubmit?: (res: Partial<ProjectPortal>) => void;
}

const ProjectPortalForm = ({ portal, onSubmit }: ProjectPortalFormProps) => {
  const { projects } = useProject();
  const { getProjectProperties } = useProperties();

  const [targetProjectCode, setTargetProjectCode] = useState<string | null>(
    null
  );
  const [targetProperties, setTargetProperties] = useState<Property[]>([]);

  const formProps = useForm<Partial<ProjectPortal>>({
    resource: portal,
    properties: projectPortalProperties,
    onSubmit,
  });

  useEffect(() => {
    const projectCode = formProps.resource?.targetProjectCode;

    setTargetProjectCode((cpc) => {
      if (cpc !== projectCode) {
        return projectCode;
      }

      return cpc;
    });
  }, [formProps.resource]);

  useEffect(() => {
    if (targetProjectCode != null) {
      getProjectProperties(targetProjectCode).then((prps) =>
        setTargetProperties(prps)
      );
    }
  }, [getProjectProperties, targetProjectCode]);

  const projectOptions = useMemo(() => {
    return projects.map((p) => ({
      label: p.name,
      value: p.code,
    }));
  }, [projects]);

  return (
    <SimpleUniversalForm {...formProps}>
      {({ submit, update, resource }) => (
        <div>
          <SimpleFormGroup label="Source Module" inline>
            <PancoSelect
              options={projectOptions}
              boxed
              placeholder="Select a Portal Source Module"
              value={_.compact([formProps.resource?.["targetProjectCode"]])}
              onChange={({ value }) => {
                update("targetProjectCode", _.first(value));
              }}
            />
          </SimpleFormGroup>

          <h5>Rules</h5>

          {resource?.rules != null &&
            resource?.rules.map(
              (portalRule: ProjectPortalRule, idx: number) => (
                <PortalRuleEditor
                  portalRule={portalRule}
                  targetProperties={targetProperties}
                  onUpdate={(propertyName, value) => {
                    const newRules = resource?.rules.map(
                      (r: ProjectPortalRule, id: number) => {
                        if (id === idx) {
                          return {
                            ...r,
                            [propertyName]: value,
                          };
                        }

                        return r;
                      }
                    );

                    update("rules", newRules);
                  }}
                  onRemove={() => {
                    const rules = resource?.rules || [];
                    const keepIndexes = _.without(_.range(rules.length), idx);
                    update("rules", _.pullAt(resource?.rules, keepIndexes));
                  }}
                />
              )
            )}

          <Button
            variant="link"
            onClick={() => {
              update("rules", [
                ...(resource?.rules || []),
                { operandType: "PROPERTY" },
              ]);
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Rule
          </Button>

          <div className="mt-3">
            <div className="text-right">
              <Button onClick={submit}>Save</Button>
            </div>
          </div>
        </div>
      )}
    </SimpleUniversalForm>
  );
};

export default ProjectPortalForm;
