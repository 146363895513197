import { useCallback } from "react";
import {
  exportValuesetFile,
  fetchMasterValuesets,
  importCustomValuesetFile,
  addCustomValuesetItem,
  removeCustomValuesetItems,
} from "../actions/docareaActions";
import { saveMasterValuesetItem } from "../actions/docareaActions";
import {
  getDocareaName,
  getDocareaUpdatedAt,
} from "../selectors/docareaSelector";
import useCoreDispatch from "./useCoreDispatch";
import useCoreSelector from "./useCoreSelector";
import useValueset from "./useValueset";

/**
 * @module hooks/useDocArea
 * @category Hooks
 */

/**
 * @callback valuesetIssuesCallback
 * @return {Promise}
 * @param {string} propertyName - Name of the property which issues are fetched.
 * @param {string} valuesetName - Name of the valueset of which issues are fetched.
 */

/**
 * @typedef {object} UseDocAreaHook
 * @property {string} name Name of the document area.
 * @property {array} valuesets All loaded valuesets.
 * @property {array} masterValuesets All loaded master valuesets.
 * @property {boolean} loading Indicator of loading of valuesets in progress.
 * @property {function} createProject Function to initiate project creation.
 * @property {function} getMasterValuesets Function to fetch all master valuesets.
 * @property {function} exportValuesetFile - Export valueset into excel file.
 * @property {function} importCustomValuesetFile - Import custom valueset file.
 * @property {function} saveMasterValuesetItem - Save master valueset item.
 * @property {function} addCustomValuesetItem - Create new custom valueset item.
 * @property {function} removeCustomValuesetItems - Remove custom valueset item.
 */

/**
 * Docarea Hook
 * @category Hooks
 * @returns {UseDocAreaHook}
 */
const useDocArea = () => {
  const dispatch = useCoreDispatch();
  const name = useCoreSelector(getDocareaName);
  const updatedAt = useCoreSelector(getDocareaUpdatedAt);
  const { valuesets, masterValuesets, loading } = useValueset();

  return {
    name,
    updatedAt,
    valuesets,
    masterValuesets,
    loading,
    createProject: useCallback(() => {}, []),
    getMasterValuesets: useCallback(() => {
      dispatch(fetchMasterValuesets());
    }, [dispatch]),
    exportValuesetFile: useCallback(
      (
        { valuesetName, masterValueset, exportingProps }: any,
        callback = () => {}
      ) => {
        return dispatch(
          exportValuesetFile({
            valuesetName,
            masterValueset,
            exportingProps,
          })
          // @ts-ignore
        ).then(callback);
      },
      [dispatch]
    ),
    importCustomValuesetFile: useCallback(
      (
        valuesetName: string,
        files: Array<File>,
        masterValueset: string,
        callback = () => {}
      ) => {
        return dispatch(
          importCustomValuesetFile({
            valuesetName,
            files,
            masterValueset,
          })
          // @ts-ignore
        ).then(callback);
      },
      [dispatch]
    ),
    saveMasterValuesetItem: useCallback(
      (valuesetName: string, valuesetItem: {}, callback = () => {}) => {
        return dispatch(
          saveMasterValuesetItem({
            valuesetName,
            valuesetItem,
          })
          // @ts-ignore
        ).then(callback);
      },
      [dispatch]
    ),
    addCustomValuesetItem: useCallback(
      (
        valuesetName: string,
        valuesetItem: {},
        generateKey: boolean,
        callback = () => {}
      ) => {
        return dispatch(
          addCustomValuesetItem({
            valuesetName,
            valuesetItem,
            generateKey,
          })
          // @ts-ignore
        ).then(callback);
      },
      [dispatch]
    ),
    removeCustomValuesetItems: useCallback(
      (
        valuesetName: string,
        itemIds: Array<string>,
        masterValueset?: boolean
      ) => {
        return dispatch(
          removeCustomValuesetItems({
            valuesetName,
            itemIds,
            masterValueset: !!masterValueset,
          })
        );
      },
      [dispatch]
    ),
  };
};

export default useDocArea;
