export const SET_HOME_ACCOUNT_ID = 'SET_HOME_ACCOUNT_ID';
export const LOGIN_SHAREPOINT = 'LOGIN_SHAREPOINT';
export const LOGOUT_SHAREPOINT = 'LOGOUT_SHAREPOINT';
export const SEARCH_FILES_SHAREPOINT = 'SEARCH_FILES_SHAREPOINT';
export const SET_SEARCH_RESULTS_SHAREPOINT = 'SET_SEARCH_RESULTS_SHAREPOINT';
export const SET_RENDITION_SHAREPOINT = 'SET_RENDITION_SHAREPOINT';
export const SELECT_DOCUMENTS_SHAREPOINT = 'SELECT_DOCUMENTS_SHAREPOINT';
export const IMPORT_FROM_SHAREPOINT = 'IMPORT_FROM_SHAREPOINT';
export const DOWNLOAD_FROM_SHAREPOINT = 'DOWNLOAD_FROM_SHAREPOINT';
export const SET_LOADING_SHAREPOINT = 'SET_LOADING_SHAREPOINT';
export const SET_LOADING_RENDITION_SHAREPOINT =
  'SET_LOADING_RENDITION_SHAREPOINT';
export const FETCH_SHAREPOINT_DRIVES = 'FETCH_SHAREPOINT_DRIVES';
export const SET_SHAREPOINT_DRIVES = 'SET_SHAREPOINT_DRIVES';
export const FETCH_SHAREPOINT_ITEM_CHILDREN = 'FETCH_SHAREPOINT_ITEM_CHILDREN';
export const DOWNLOAD_SHAREPOINT_DRIVE_ITEMS =
  'DOWNLOAD_SHAREPOINT_DRIVE_ITEMS';
export const SEARCH_SHAREPOINT_SITES = 'SEARCH_SHAREPOINT_SITES';
export const GET_SHAREPOINT_SITE_DRIVE = 'GET_SHAREPOINT_SITE_DRIVE';
