import { useCallback, useState } from "react";

const getScreenHeight = () =>
  window.innerHeight || document.documentElement.clientHeight;

const useViewPlacement = ({ placement: initialPlacement, height }) => {
  const [placement, setPlacement] = useState(initialPlacement);
  const [offset, setOffset] = useState(0);

  const calculatePlacement = useCallback(
    (origin, currentPlacement) => {
      const top = origin - height;
      const down = getScreenHeight() - (origin + height);

      if (top < 0 && down < 0) {
        setOffset(() => {
          const newOffset =
            top > down
              ? Math.min(getScreenHeight() - origin, Math.abs(top))
              : Math.max(origin, Math.abs(down));

          return newOffset;
        });

        return top > down ? "top" : "bottom";
      }

      setOffset(0);

      if (down < 0) {
        if (currentPlacement === "bottom" && top > 0) {
          return "top";
        }
      } else if (top < 0) {
        return "bottom";
      }

      return currentPlacement;
    },
    [height]
  );

  const recalculatePlacement = useCallback(
    (origin) => {
      setPlacement((currentPlacement) =>
        calculatePlacement(origin, currentPlacement)
      );
    },
    [calculatePlacement]
  );

  return {
    recalculatePlacement,
    placement,
    offset,
  };
};

export default useViewPlacement;
