import { useCallback, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import resourcePermissionsApi from "../apis/resourcePermissionsApi";
import permissionConstant from "../constants/resourcePermissions";
import { resourceCorrectionOfGateway } from "cosmos-config/utils";
import { Permission } from "../constants/Permission";
import _ from "lodash";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { DateTime } from "luxon";

export interface UseResourceAccessPermissionsProps {
  resourceId?: string;
}

const properties = _.compact(permissionConstant.map((p) => p.query));

const useResourceAccessPermissions = ({
  resourceId,
}: UseResourceAccessPermissionsProps) => {
  const { defaultPositionId, authenticated } = useCurrentUser();
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => ["permissions", resourceId], [resourceId]);

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () =>
      resourcePermissionsApi.getPermissions(
        "RWE",
        resourceId || "",
        properties
      ),
    enabled: resourceId != null && authenticated,
  });

  const resourcePermissions: Permission[] = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.map((entry: any) => {
      const item = _(entry.properties).keyBy("name").mapValues("value").value();

      return resourceCorrectionOfGateway(
        item,
        permissionConstant
      ) as Permission;
    });
  }, [data]);

  const getCurrentUserLevel = useCallback(
    (withInactive?: boolean) => {
      return (
        _(resourcePermissions)
          .filter((rp) => {
            const millisNow = DateTime.now().toMillis();
            return (
              rp.principalId === defaultPositionId &&
              (withInactive ||
                (rp.active &&
                  (rp.beginsAt == null || rp.beginsAt < millisNow) &&
                  (rp.expiresAt == null || rp.expiresAt > millisNow)))
            );
          })
          .map("accessLevel")
          .max() || 0
      );
    },
    [defaultPositionId, resourcePermissions]
  );

  const requestMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (reason?: string) => {
      return resourcePermissionsApi.requestPermission(resourceId || "", reason);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const requestUserPermission = useCallback(
    (reason?: string) => {
      return requestMutation.mutateAsync(reason);
    },
    [requestMutation]
  );

  return {
    loading: useMemo(
      () => isLoading || requestMutation.isPending,
      [isLoading, requestMutation.isPending]
    ),
    resourcePermissions,
    getCurrentUserLevel,
    requestUserPermission,
  };
};

export default useResourceAccessPermissions;
