import * as actions from '../Actions/types';
import { printIssueText } from '../Constants/importIssueType';
import { importActivityTypes } from '../Constants/importActivity';

const initialState = {
  initialized: false,
  data: [],
  issues: [],
  stats: [],
  columnMapping: {},
  valueMapping: {},
  currentSchema: {},
  inProgress: false,
  isUnzipping: false,
  log: [],
};

const updateStats = (stats, importId, data) => {
  const existing = stats.find((r) => r.id === importId);

  if (existing != null) {
    return stats.map((r) => {
      if (r.id === importId) {
        return {
          ...r,
          ...data,
        };
      }

      return r;
    });
  }

  return [...stats, { id: importId, ...data }];
};

const importReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.repositoryImport.SET_IMPORT_DATA:
      return {
        ...state,
        initialized: true,
        data: action.importData,
        issues: action.importIssues,
        log: [
          ...state.log,
          ...(!state.initialized
            ? action.importData.map((d) => ({
                time: new Date().getTime(),
                type: importActivityTypes.ROW_LOADED,
                message: d.filename
                  ? `File ${d._path || ''}/${d.filename} loaded`
                  : `${d.displayname || 'Unknown Row'} loaded`,
              }))
            : []),
          ...action.importIssues
            .filter((i) => {
              // only return issues which contentHash is not already in the issues array
              return !state.issues.some(
                (issue) => issue.contentHash === i.contentHash
              );
            })
            .map((i) => ({
              time: new Date().getTime(),
              type: `${importActivityTypes.ISSUE_FOUND}::${i.type}`,
              message: printIssueText(i),
            })),
        ],
      };
    case actions.repositoryImport.CLEAN_IMPORT:
    case actions.repositoryImport.CANCEL_IMPORT:
      return initialState;
    case actions.repositoryImport.UPDATE_IMPORT_COLUMN_MAPPING:
      return {
        ...state,
        columnMapping: {
          ...state.columnMapping,
          [action.payload.columnName]: action.payload.propertyName,
        },
        log: [
          ...state.log,
          {
            time: new Date().getTime(),
            type: importActivityTypes.UPDATED_IMPORT_COLUMN_MAPPING,
            message: `Map column "${action.payload.columnName}" to "${action.payload.propertyName}"`,
          },
        ],
      };
    case actions.repositoryImport.UPDATE_IMPORT_VALUE_MAPPING:
      return {
        ...state,
        valueMapping: {
          ...state.valueMapping,
          [action.payload.propertyName]: {
            ...(state.valueMapping[action.payload.propertyName] || {}),
            [action.payload.value]: [
              ...((state.valueMapping[action.payload.propertyName] || {})[
                action.payload.value
              ] || []),
              action.payload.label,
            ],
          },
        },
        log: [
          ...state.log,
          {
            time: new Date().getTime(),
            type: importActivityTypes.UPDATED_IMPORT_VALUE_MAPPING,
            message: `Added "${action.payload.label}" to the value mapping of "${action.payload.value}" in "${action.payload.propertyName}"`,
          },
        ],
      };
    case actions.repositoryImport.SET_IMPORT_COMPLETE:
      return {
        ...initialState,
        stats: action.payload.stats,
        log: state.log,
      };
    case actions.repositoryImport.UPDATE_IMPORT_STATS:
      return {
        ...state,
        inProgress: true,
        stats: updateStats(
          state.stats,
          action.payload.importId,
          action.payload.data
        ),
      };
    case actions.repositoryImport.SET_IS_UNZIPPING:
      return {
        ...state,
        isUnzipping: action.isUnzipping,
      };
    case actions.repositoryImport.SET_CURRENT_SCHEMA:
      return {
        ...state,
        currentSchema: action.payload.schema,
      };
    case actions.repositoryImport.ADD_LOG_ACTIVITY:
      return {
        ...state,
        log: [
          ...state.log,
          {
            time: new Date().getTime(),
            type: action.payload.type,
            message: action.payload.message,
          },
        ],
      };
    default:
      return state;
  }
};

export default importReducer;
