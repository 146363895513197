import { useCallback, useMemo } from "react";
import useProject from "./useProject";
import _ from "lodash";
import useCoreSelector from "./useCoreSelector";
import { CosmosCoreRootState } from "../store";
import { principalType as PrincipalType } from "cosmos-config/generator";

export interface UseProjectMembersProps {
  groupNames?: string[];
  groupsOnly?: boolean;
}

const useProjectMembers = () => {
  const loading = useCoreSelector<CosmosCoreRootState, boolean>(
    (state) => state.usermanagement.loading
  );

  const { members, project } = useProject();

  const getDirectMembersOf = useCallback(
    (relevantGroupNames: string[] = [], groupsOnly?: boolean) => {
      const membersByGroup = _(members)
        .entriesIn()
        .filter(
          ([key]) =>
            relevantGroupNames.length === 0 || relevantGroupNames.includes(key)
        )
        .flatMap(([, v]) => v)
        .value();

      if (relevantGroupNames.length === 0 && groupsOnly) {
        return [...membersByGroup, ...(project?.groups || [])];
      }

      return membersByGroup;
    },
    [members, project?.groups]
  );

  const getRelevantGroupNames = useCallback(
    (groupNames?: string[]) => {
      if (groupNames == null || groupNames.length === 0) {
        return [];
      }

      return _(getDirectMembersOf(groupNames))
        .filter("group")
        .map("name")
        .concat(groupNames)
        .value();
    },
    [getDirectMembersOf]
  );

  const groupsMap = useMemo(() => {
    return _(getDirectMembersOf())
      .filter("group")
      .keyBy("principalId")
      .mapValues("name")
      .value();
  }, [getDirectMembersOf]);

  return {
    loading,
    getHierarchicalMembers: useCallback(
      (groupNames?: string[], principalType?: number | null) => {
        const relevantGroupNames = getRelevantGroupNames(groupNames);
        return _(
          getDirectMembersOf(
            relevantGroupNames,
            principalType === PrincipalType.GROUP
          )
        )
          .uniqBy("principalId")
          .filter((u) => {
            switch (principalType) {
              case PrincipalType.GROUP:
                return u.group;
              case PrincipalType.USER:
                return !u.group;
              case PrincipalType.POSITION:
              default:
                return true;
            }
          })
          .orderBy(
            ["group", (u) => String(u.commonname).toLowerCase()],
            ["desc", "asc"]
          )
          .value();
      },
      [getDirectMembersOf, getRelevantGroupNames]
    ),
    getPrincipalIds: useCallback(
      (principalIds: string[] | null, flattenGroups?: boolean) => {
        return _(principalIds)
          .map((principalId) => {
            if (Object.keys(groupsMap).includes(principalId) && flattenGroups) {
              const groupName = groupsMap[principalId];
              return (members[groupName] || []).map((p) => p.principalId);
            }

            return [principalId];
          })
          .flatMap()
          .uniq()
          .value();
      },
      [groupsMap, members]
    ),
  };
};

export default useProjectMembers;
