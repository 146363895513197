import useProperties from "./useProperties";
import _ from "lodash";
import { useMemo } from "react";
import { getSearchOperation } from "../utils/searchUtils";
import { FilterValue } from "cosmos-config/lib/nql/filter";
import useProject from "./useProject";
import { ESignatureType } from "cosmos-config/generator";
import { Property } from "cosmos-config/lib/property/property";

export interface UseNqlQueryProps {
  filter?: Record<string, FilterValue>;
  searchQuery?: string;
  fulltextSearch?: boolean;
  substructure?: boolean;
  signatureStatus?: "COMPLETE" | "IN_PROGRESS" | "ALL";
  properties?: Property[];
}

const useNqlQuery = ({
  filter,
  searchQuery,
  fulltextSearch,
  substructure,
  signatureStatus,
  properties,
}: UseNqlQueryProps) => {
  const { folders } = useProperties();
  const { configuration } = useProject();

  const searchProperties = useMemo(() => {
    if (filter == null) {
      return [];
    }

    const { foldertype, resourcetype } = filter;

    if (
      Array.isArray(resourcetype) &&
      _.isEqual(resourcetype.sort(), [1, 2, 3].sort())
    ) {
      return _.compact(folders.map((f) => f.titlePropertyName));
    }

    return _.compact(
      folders
        .filter(
          (f) =>
            f.name === foldertype ||
            (Array.isArray(foldertype) && foldertype.includes(f.name))
        )
        .map((f) => f.titlePropertyName)
    );
  }, [filter, folders]);

  const parsedSignatureStatus = useMemo(() => {
    if (signatureStatus == null) {
      return configuration?.eSignature === ESignatureType.RESTRICTED
        ? "COMPLETE"
        : "ALL";
    }

    return "ALL";
  }, [signatureStatus, configuration]);

  const operation = useMemo(
    () =>
      getSearchOperation(filter || {}, properties || [], {
        substructure,
        fulltextSearch,
        searchProperties,
        searchQuery,
        signatureStatus: parsedSignatureStatus,
      }),
    [
      filter,
      fulltextSearch,
      properties,
      searchProperties,
      searchQuery,
      substructure,
      parsedSignatureStatus,
    ]
  );

  return {
    operation,
    nqlQuery: useMemo(
      () => (operation != null ? operation.toNql() : null),
      [operation]
    ),
  };
};

export default useNqlQuery;
