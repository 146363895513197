import React from "react";
import { Form } from "react-bootstrap";
import {
  InputValueHandler,
  PancoInputElementProps,
} from "../../types/PancoInputElemenProps";
import { PancoSelectOption } from "../panco-select/PancoSelect";

export interface RadioSelectProps
  extends PancoInputElementProps,
    InputValueHandler<string[]> {
  options?: PancoSelectOption[];
}

const RadioSelect = ({
  name,
  value,
  options,
  disabled,
  onChange,
}: RadioSelectProps) => {
  return (
    <div>
      {options != null &&
        options.map((option) => (
          <Form.Check
            key={option.value}
            disabled={disabled}
            type="radio"
            label={option.label}
            id={`radio-${option.label}`}
            checked={value != null && value.includes(option.value)}
            onChange={(e) => {
              if (e.target.checked && onChange != null) {
                onChange({ name, value: [option.value] });
              }
            }}
          />
        ))}
    </div>
  );
};

export default RadioSelect;
