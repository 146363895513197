import { useContext, useState } from "react";
import { faCog, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Nav, Container, Row, Col } from "react-bootstrap";
import useProject from "../../../hooks/useProject";
import useProperties from "../../../hooks/useProperties";
import {
  faBuilding,
  faFile,
  faFolder,
} from "@fortawesome/free-regular-svg-icons";
import CreateFolderModal from "./CreateFolderModal";
import ResourceTypeProperties from "./ResourceTypeProperties";
import { useEffect } from "react";
import ProjectSpec from "./ProjectSpec";
import ResourcePropertiesProvider from "../providers/ResourcePropertiesProvider";
import BusinessProcessPropertiesProvider from "../providers/BusinessProcessPropertiesProvider";
import ProjectEditorContext from "../contexts/ProjectEditorContext";

const BusinessProcessPropertiesTab = ({ processDefinitionKey }) => {
  const [groups, setGroups] = useState([]);
  const { getBusinessProcessGroups } = useProperties();

  useEffect(() => {
    const grps = getBusinessProcessGroups(processDefinitionKey, null);
    setGroups(grps);
  }, [getBusinessProcessGroups, processDefinitionKey]);

  return (
    <Tab.Pane
      eventKey={processDefinitionKey}
      className="h-100"
      mountOnEnter
      unmountOnExit
    >
      <BusinessProcessPropertiesProvider
        processDefinitionKey={processDefinitionKey}
      >
        <ResourceTypeProperties groups={groups} />
      </BusinessProcessPropertiesProvider>
    </Tab.Pane>
  );
};

const ProjectProperties = () => {
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [activeTab, setActiveTab] = useState();

  const { createFolder } = useContext(ProjectEditorContext);

  const { groups, folders, folderGroups } = useProperties();
  const { businessProcesses, projectName } = useProject();

  useEffect(() => {
    setActiveTab((tab) =>
      tab == null && folders.length > 0 ? folders[0].name : tab
    );
  }, [folders]);

  return (
    <div className="position-relative">
      <Tab.Container
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key)}
      >
        <Nav variant="tabs" className="mb-3">
          <Nav.Item>
            <Nav.Link eventKey="module" active={"module" === activeTab}>
              <FontAwesomeIcon icon={faBuilding} className="mr-2" />
              {projectName}
            </Nav.Link>
          </Nav.Item>
          {folders.map((folder) => (
            <Nav.Item>
              <Nav.Link eventKey={folder.name}>
                <FontAwesomeIcon icon={faFolder} className="mr-2" />
                {folder.label}
              </Nav.Link>
            </Nav.Item>
          ))}
          <Nav.Item>
            <Nav.Link
              onClick={() => {
                setShowCreateFolderModal(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Folder Type
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="document"
              active={[null, "document"].includes(activeTab)}
            >
              <FontAwesomeIcon icon={faFile} className="mr-2" />
              Document
            </Nav.Link>
          </Nav.Item>
          {businessProcesses.map((businessProcess) => (
            <Nav.Item>
              <Nav.Link eventKey={businessProcess.processDefinitionKey}>
                <FontAwesomeIcon icon={faCog} className="mr-2" />
                {businessProcess.processDefinitionKey}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>

        <Tab.Content className="overflow-auto flex-grow-1">
          <Tab.Pane
            eventKey="module"
            className="h-100"
            active={"module" === activeTab}
            mountOnEnter
            unmountOnExit
          >
            <Container fluid>
              <Row>
                <Col md={{ span: 9, offset: 1 }}>
                  <ProjectSpec />
                </Col>
              </Row>
            </Container>
          </Tab.Pane>
          <Tab.Pane
            eventKey="document"
            className="h-100"
            active={[null, "document"].includes(activeTab)}
            mountOnEnter
            unmountOnExit
          >
            <ResourcePropertiesProvider>
              <ResourceTypeProperties groups={groups} />
            </ResourcePropertiesProvider>
          </Tab.Pane>
          {folders.map((folder) => (
            <Tab.Pane
              eventKey={folder.name}
              className="h-100"
              mountOnEnter
              unmountOnExit
            >
              <ResourcePropertiesProvider folder={folder} resourceType={1}>
                <ResourceTypeProperties groups={folderGroups} />
              </ResourcePropertiesProvider>
            </Tab.Pane>
          ))}
          {businessProcesses.map((businessProcess) => (
            <BusinessProcessPropertiesTab
              processDefinitionKey={businessProcess.processDefinitionKey}
            />
          ))}
        </Tab.Content>
      </Tab.Container>

      <CreateFolderModal
        show={showCreateFolderModal}
        onClose={() => setShowCreateFolderModal(false)}
        onSubmit={(folder) => {
          createFolder(folder);
          setShowCreateFolderModal(false);
        }}
      />
    </div>
  );
};

export default ProjectProperties;
