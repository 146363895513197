import {
  faCaretDown,
  faCaretRight,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SlideDown } from "cosmos-components";
import PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";
import { Card, Button, Col, Tab, Nav, Badge } from "react-bootstrap";
import PropertiesTable from "./PropertiesTable";
import { groupType } from "cosmos-config/generator";
import SimpleUniversalForm from "../../../components/SimpleUniversalForm";
import projectGroup from "../constants/projectGroup";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import PropertyVisibility from "./property-editor/PropertyVisibility";
import classNames from "classnames";
import useProjectElementEditing from "../hooks/useProjectElementEditing";
import ProjectEditorPropertiesContext from "../contexts/ProjectEditorPropertiesContext";
import { useTranslation } from "react-i18next";
import ResourceTypeContext from "../contexts/ResourceTypeContext";
import SimpleFormGroup from "../../../components/SimpleFormGroup";
import PropertyTranslations from "./property-editor/PropertyTranslations";

const PropertiesGroup = ({ group, resourceType, folderType }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();

  const {
    formResource,
    updateElement,
    updateElementMask,
    visibilityUpdateMask,
    getSubmitResource,
  } = useProjectElementEditing({
    resource: group,
    properties: projectGroup,
  });

  const { deleteProperty, updateProperty, canManipulate, translationPathBase } =
    useContext(ProjectEditorPropertiesContext);

  const { addProperty, createProperty } = useContext(ResourceTypeContext);

  const properties = useMemo(() => {
    const isDocumentProperty = (p) => {
      if (p.resourceType === 2) {
        if (p.folderProperty != null) {
          const { editable, filterable, information, tableColumn } =
            p.folderProperty;
          return !editable && !filterable && !information && !tableColumn;
        }
      }

      return false;
    };

    if (Array.isArray(group.properties)) {
      return group.properties.filter((p) => {
        if (resourceType === 1 && p.resourceType === 2) {
          return !isDocumentProperty(p);
        }

        if (resourceType === 2) {
          return (
            p.editable ||
            p.filterable ||
            p.information ||
            p.tableColumn ||
            isDocumentProperty(p)
          );
        }

        return (
          (p.folderType == null || p.folderType === folderType) &&
          (p.editable || p.filterable || p.information || p.tableColumn)
        );
      });
    }

    return [];
  }, [group, resourceType, folderType]);

  const propertiesGroup = useMemo(
    () =>
      [groupType.PROPERTIES, groupType.MULTI_ATTRIBUTE].includes(group.type),
    [group]
  );

  useEffect(() => {
    if (group.editable && group.type === groupType.PROPERTIES) {
      setCollapsed(false);
    }
  }, [group]);

  return (
    <>
      <Card key={group.name} className="mb-3">
        <Tab.Container
          defaultActiveKey={propertiesGroup ? "properties" : "edit"}
        >
          <Card.Header>
            <div
              onClick={() => {
                setCollapsed((c) => !c);
              }}
              role="button"
              className="d-flex align-items-center"
            >
              <FontAwesomeIcon
                className="mr-3"
                icon={collapsed ? faCaretRight : faCaretDown}
              />
              <b
                className={classNames({
                  "text-info": group.conditional,
                })}
              >
                {group.dedicated != null
                  ? `[${group.dedicated}]${group.label}`
                  : group.label}
              </b>

              {group.protectedGroup && (
                <FontAwesomeIcon
                  className="ml-2 text-danger"
                  icon={faLock}
                  size="xs"
                />
              )}

              <small className="ml-auto text-muted">({group.type})</small>
            </div>

            {!collapsed && (
              <Nav variant="tabs" className="mt-3">
                <Nav.Item>
                  {propertiesGroup && (
                    <Nav.Link eventKey="properties">
                      <FontAwesomeIcon icon={faFile} className="mr-2" />
                      Properties
                      <Badge pill variant="info" className="ml-1">
                        {properties.length}
                      </Badge>
                    </Nav.Link>
                  )}
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="edit">Edit</Nav.Link>
                </Nav.Item>
              </Nav>
            )}
          </Card.Header>
          <SlideDown collapsed={collapsed}>
            <Card.Body>
              <Tab.Content className="overflow-auto flex-grow-1">
                {propertiesGroup && (
                  <Tab.Pane eventKey="properties" mountOnEnter unmountOnExit>
                    <PropertiesTable
                      properties={properties}
                      resourceType={resourceType}
                    />
                  </Tab.Pane>
                )}
                <Tab.Pane eventKey="edit" mountOnEnter unmountOnExit>
                  <Col md={{ span: 9, offset: 1 }}>
                    <SimpleUniversalForm
                      disabled={group.protectedGroup}
                      properties={projectGroup}
                      resource={formResource}
                      onUpdateResource={(id, name, value) => {
                        updateElement(name, value);
                        // setUpdateMask((m) => ({
                        //   ...m,
                        //   [name]: value,
                        // }));
                      }}
                      onSubmit={() => {
                        updateProperty(group.name, getSubmitResource());
                      }}
                      inline
                    >
                      {({ submit }) => (
                        <div>
                          <div className="mb-3">
                            <PropertyVisibility
                              property={visibilityUpdateMask}
                              onChange={(prop) => {
                                updateElementMask(prop);
                                // setUpdateMask((m) => ({
                                //   ...m,
                                //   ...prop,
                                // }));
                              }}
                            />
                          </div>

                          <SimpleFormGroup label="Translation" inline>
                            <PropertyTranslations
                              basePath={translationPathBase}
                              translationPath={formResource.translation}
                              onChange={(translation) => {
                                updateElement("translation", translation);
                              }}
                            />
                          </SimpleFormGroup>

                          <div className="text-right">
                            <Button
                              size="lg"
                              onClick={submit}
                              disabled={group.protectedGroup}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      )}
                    </SimpleUniversalForm>
                  </Col>
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
            {canManipulate() && (
              <Card.Footer>
                {propertiesGroup && (
                  <>
                    <Button
                      variant="link"
                      onClick={() => {
                        addProperty(group.name);
                      }}
                    >
                      {t("metadata.add_property", {
                        defaultValue: "Add Property",
                      })}
                    </Button>

                    <Button
                      variant="link"
                      className="text-warning"
                      onClick={() => {
                        createProperty(group.name);
                      }}
                    >
                      Create Property
                    </Button>
                  </>
                )}

                {!group.protectedGroup && (
                  <Button
                    onClick={() => {
                      deleteProperty(group.name);
                    }}
                    variant="link"
                    className="text-danger"
                  >
                    Delete Group
                  </Button>
                )}
              </Card.Footer>
            )}
          </SlideDown>
        </Tab.Container>
      </Card>
    </>
  );
};

PropertiesGroup.propTypes = {
  group: PropTypes.shape({}),
  resourceType: PropTypes.number,
  folderType: PropTypes.string,
};

PropertiesGroup.defaultProps = {
  group: {},
  resourceType: 2,
  folderType: null,
};

export default PropertiesGroup;
