import { group } from "cosmos-config/generator";
import { DateTime } from "luxon";
import _ from "lodash";
import { hashCode } from "cosmos-config/utils";
import { ValuesetItem } from "../contants/valuesetItem";
import { Group } from "cosmos-config/lib/group/group";
import { Property } from "cosmos-config/lib/property/property";

export { generateStringHash, progressiveChecksum } from "./cryptoUtils";
export {
  flatMapEmails,
  resolveContentType,
  getFileIdentifier,
} from "./fileUtils";
export {
  validateResources,
  getKeywords,
  propertyEquals,
  parseResourceId,
} from "./resourceUtils";
export { getSearchOperation, generateSearchHash } from "./searchUtils";

export const generateValuesetItemKey = (
  { label, filter }: ValuesetItem,
  existingKeys: string[]
) => {
  if (label != null) {
    let key = label.replace(/[^a-zA-Z0-9]|[aeiou]/gi, "");

    if (filter != null) {
      key = `${filter}_${key}`;
    }

    key = String(key).trim().toUpperCase();

    if (existingKeys.includes(key)) {
      let newKey = key;
      for (let i = 1; existingKeys.includes(newKey); i += 1) {
        newKey = `${key}_${i}`;
      }
      key = newKey;
    }

    return key;
  }

  return null;
};

export const generateValuesetHash = (valuesetData: ValuesetItem[]) => {
  if (Array.isArray(valuesetData)) {
    const stringRepresentation = valuesetData
      .map((valuesetItem) => `${valuesetItem.value}(${valuesetItem.label})`)
      .sort()
      .join("&");

    return hashCode(stringRepresentation);
  }

  return null;
};

interface ColumnValues {
  values: Record<string, string>;
}

export const stringSortType = (
  { values: avalues }: ColumnValues,
  { values: bvalues }: ColumnValues,
  columnId: string
) => String(avalues[columnId]).localeCompare(bvalues[columnId]);

export const timestampToDate = (timestamp: number) => {
  return timestamp != null && typeof timestamp === "number"
    ? DateTime.fromMillis(timestamp)
        .setLocale("de-DE")
        .toLocaleString(DateTime.DATETIME_SHORT)
    : null;
};

export const buildGroups = (
  properties: Array<Group | Property>,
  mapFunction = (g: Group) => g
): Group[] => {
  const defaultGroup = group("Default Group", "default-group")
    .children(
      properties
        .filter((p) => {
          // @ts-ignore
          return !p.structure && !p.group && !p.folder;
        })
        .map((p) => p as Property)
    )
    .build();

  const groups: Group[] = properties
    .filter((p) => {
      // @ts-ignore
      return !p.structure && p.group && !p.folder;
    })
    .map((g) => g as Group)
    .map(mapFunction)
    .map((g) => g.build());

  if (defaultGroup.properties.length > 0) {
    return [...groups, defaultGroup];
  }

  return groups;
};

export const buildDedicatedGroups = (
  groups: Group[],
  taskDefinitionKey: string
) => {
  return groups.filter(
    (gp) => gp.dedicated == null || gp.dedicated === taskDefinitionKey
  );
};

export const buildProperties = (
  groups: Array<Group | Property>
): Property[] => {
  return _(groups)
    .filter((p) => {
      // @ts-ignore
      return !p.structure && !p.folder;
    })
    .flatMap((p) => {
      const group = p as Group;
      if (group.group) {
        return group.buildChildren();
      }

      return [p as Property];
    })
    .uniqBy("name")
    .value();
};

export const generateRenditionId = (
  identifier: number,
  itemIndex?: number,
  versionIdentifier?: number
) => {
  function* idgen() {
    yield identifier;

    if (itemIndex != null) {
      yield itemIndex;

      if (versionIdentifier != null) {
        yield versionIdentifier;
      }
    }
  }

  return [...idgen()].join(":");
};