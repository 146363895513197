import { DateTime } from "luxon";
import propertyType from "../property/propertyType";
import { Property } from "../property/property";
import { Group } from "../group/group";

type IterateeFunction = (value: any, key: string) => any;

const mapValues = (obj: Object, iteratee: IterateeFunction) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: iteratee(value, key),
    }),
    {}
  );

type FilterCallback = (prop: Property | Group) => boolean;

const filteredPropertyNames = (
  properties: Array<Property | Group>,
  filterCallback: FilterCallback
): string[] => {
  return properties
    .map((prop) => {
      const propGroup = prop as Group;
      if (propGroup.group) {
        return propGroup.buildChildren();
      }

      return [prop];
    })
    .reduce((acc, cur) => [...acc, ...cur], [])
    .filter(filterCallback)
    .map((prop: Property | Group) => prop.name);
};

const selectablePropertyTypes: string[] = [
  propertyType.SELECT,
  propertyType.MEMBER_SELECT,
  propertyType.RADIO,
  propertyType.SUGGEST,
  propertyType.RESOURCE_SELECT,
  propertyType.PRINCIPAL_SELECT,
  propertyType.MODULE_ELEMENT_SELECT,
];

const selectablePropertyFilter: FilterCallback = (prop) => {
  const p = prop as Property;
  return (
    selectablePropertyTypes.includes(prop.type) &&
    !(p.multiple || p.handleAsMulti)
  );
};

const resourceCorrectionForGateway = (
  resource: any,
  properties: Array<Property>
) => {
  const selectablePropertyNames = filteredPropertyNames(
    properties,
    selectablePropertyFilter
  );

  return mapValues(resource, (value, key) => {
    if (selectablePropertyNames.includes(key) && Array.isArray(value)) {
      const valueArray = value.filter((v) => v != null && v !== "");

      if (valueArray.length === 0) {
        return null;
      }

      return valueArray[0];
    }

    return value;
  });
};

const resourceCorrectionOfGateway = (
  resource: any,
  propertiesMap: Array<Property>
) => {
  const selectablePropertyNames = filteredPropertyNames(
    propertiesMap,
    selectablePropertyFilter
  );

  const parseObject = (obj: any, path?: String): any =>
    mapValues(obj, (value, key) => {
      const keyPath = path != null ? `${path}.${key}` : key;

      if (selectablePropertyNames.includes(keyPath)) {
        return [value].filter((x) => x != null);
      }

      if (value != null && typeof value === "object" && !Array.isArray(value)) {
        return parseObject(value, keyPath);
      }

      return value;
    });

  return parseObject(resource);
};

export { resourceCorrectionForGateway, resourceCorrectionOfGateway };
