import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { ValuesetItem } from "../contants/valuesetItem";

export interface Valueset {
  name: string;
  data: ValuesetItem[];
}

export interface DocAreaState {
  name: string;
  valuesets: Valueset[];
  masterValuesets: Valueset[];
  loadingValueset: boolean;
  updatedAt: number;
}

const docareaSlice = createSlice({
  name: "docarea",
  initialState: {
    name: "RWE",
    valuesets: [],
    masterValuesets: [],
    loadingValueset: false,
    updatedAt: DateTime.now().toMillis(),
  } as DocAreaState,
  reducers: {
    valuesetsLoading: (state, action) => {
      const loading = action.payload;
      state.loadingValueset = loading;
    },
    addValueset: (state, action) => {
      const valueset = action.payload;
      state.valuesets = [
        ...state.valuesets.filter((vs) => vs.name !== valueset.name),
        valueset,
      ];
      state.updatedAt = DateTime.now().toMillis();
    },
    addMasterValueset: (state, action) => {
      const masterValueset = action.payload;
      state.masterValuesets = [
        ...state.masterValuesets.filter(
          (vs) => vs.name !== masterValueset.name
        ),
        masterValueset,
      ];
      state.updatedAt = DateTime.now().toMillis();
    },
  },
});

export default docareaSlice;
