import group, { Group } from "./group";
import groupType from "./groupType";
import property from "../property/property";

/**
 * @interface
 * @category Group
 * @extends Group
 */
export interface PermissionsGroup extends Group {
  tableCell(cellRender: Function): PermissionsGroup;
  children(children: Array<any>): PermissionsGroup;
}

const generatePropertyPrivate = (label: string) => ({
  ...property(label)
    .booleanAttribute("private")
    .useYesNo()
    // @ts-ignore
    .default(false)
    .excludeFilter()
    // .informational()
    .excludePreset()
    .asFolderProperty((p: any) => p.excludeInformational().excludeForm()),
});

export default (
  label: string,
  propertyLabel: string,
  topic = "property:common.confidentiality"
): PermissionsGroup => ({
  ...group(label, "permissions", topic).ownerRestricted(),
  type: groupType.PERMISSIONS,
  tableCell(cellRender: () => any): PermissionsGroup {
    this._children = [
      generatePropertyPrivate(propertyLabel).tableCell(cellRender),
    ];
    return this;
  },
  _children: [generatePropertyPrivate(propertyLabel)],
  getDefaultCollapsed(filter: any): boolean {
    return !filter?.private;
  },
  children(children: Array<any>): PermissionsGroup {
    this._children = children;
    return this;
  },
});
