export const uploadErrorType = {
  DOC_CONTENT_EXISTS: 'DOC_CONTENT_EXISTS',
  REFERENCE: 'REFERENCE',
  DOC_CONTENT_EXISTS_MULTI: 'DOC_CONTENT_EXISTS_MULTI',
  FILE_CONTENT_DUPLICATE: 'FILE_CONTENT_DUPLICATE',
  // TODO: Type for same title can be added here
};

// low number means high priority
export const uploadErrorTypePriority = {
  DOC_CONTENT_EXISTS: 1,
  FILE_CONTENT_DUPLICATE: 2,
  REFERENCE: 3,
  DOC_CONTENT_EXISTS_MULTI: 4,
};
