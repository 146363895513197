import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { PancoSelect } from "cosmos-components";
import styled from "styled-components";
import systemColumns from "./systemColumns";
import useProperties from "../../hooks/useProperties";
import _ from "lodash";

const ColumnPicker = styled(PancoSelect)`
  z-index: 1;
  min-width: 200px;
`;

declare type ColumnInserterBaseProps = {
  className?: string;
  onInsert?: (columnId: string) => void;
  columnNames?: string[];
};

const ColumnInserterBase = ({
  className,
  onInsert,
  columnNames,
}: ColumnInserterBaseProps) => {
  const { t } = useTranslation("property");
  const [show, setShow] = useState(false);

  const { properties } = useProperties();
  const propertiesMap = useMemo(
    () => _.keyBy(properties, "name"),
    [properties]
  );

  const options = useMemo(() => {
    return (columnNames || [])
      .filter((c) => !systemColumns.includes(c))
      .map((name) => propertiesMap[name])
      .sort((a, b) => String(a.Header).localeCompare(b.Header))
      .map((property) => ({
        value: property.name,
        label: property.translation ? t(property.translation) : property.Header,
      }));
  }, [columnNames, t, propertiesMap]);

  const renderPopover = () => (
    <Popover id="popover-basic" title="test">
      <Popover.Content>
        <div className="d-flex">
          <ColumnPicker
            options={options}
            onChange={({ value }) => {
              if (onInsert != null) {
                const toInsert = value == null ? null : value[0];

                if (toInsert != null) {
                  onInsert(toInsert);
                }

                setShow(false);
              }
            }}
            placeholder="Select Column"
            boxed
          />
          <Button variant="link" onClick={() => setShow(false)}>
            {t("module:button.cancel", { defaultValue: "Cancel" })}
          </Button>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className={className}>
      <OverlayTrigger show={show} placement="auto" overlay={renderPopover()}>
        <FontAwesomeIcon
          icon={faPlusSquare}
          size="lg"
          onClick={() => setShow((s) => !s)}
        />
      </OverlayTrigger>
    </div>
  );
};

ColumnInserterBase.propTypes = {
  className: PropTypes.string,
  onInsert: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
};

ColumnInserterBase.defaultProps = {
  className: null,
  onInsert: () => {},
  columns: [],
};

const ColumnInserter = styled(ColumnInserterBase)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%) translateY(-100%);
  cursor: pointer;
`;

export default ColumnInserter;
