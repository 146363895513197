import { property, principalType, group } from 'cosmos-config/generator';

const SignOff_AddViewers = [
  group('Basic Information')
    .translate('common.basic_information')
    .dedicatedTo('START')
    .children([
      property('Viewers')
        .selectableMemberAttribute(
          'floatingfileprincipalid',
          principalType.POSITION
        )
        .multiAttribute('floatingfileprincipalid'),
    ]),
];

export default SignOff_AddViewers;
