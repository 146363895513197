import { property, systemProperty } from "cosmos-config/generator";

const documentHistoryProperties = [
  systemProperty("id"),
  systemProperty("resourceId"),
  systemProperty("identifier").resolveReference("resourceId", 1, "identifier"),
  systemProperty("resourcetype").resolveReference(
    "resourceId",
    1,
    "resourcetype"
  ),
  property("displayname").resolveReference("resourceId", 1, "displayname"),
  systemProperty("deletestate").resolveReference(
    "resourceId",
    1,
    "deletestate"
  ),
  property("fileextension").resolveReference("resourceId", 1, "fileextension"),
  property("createdAt"),
];

export default documentHistoryProperties;
