import Api from './conf/Api';
import GatewayApi from './conf/GatewayApi';
import repositoryApi from './repository';

const api = Api.build();
const gatewayApi = GatewayApi.build();

const clearCachePassthrough = (response) => {
  return repositoryApi.clearCache().then(() => Promise.resolve(response));
};

const clearCacheGetData = (response) => {
  return repositoryApi.clearCache().then(() => {
    return Promise.resolve(response.data);
  });
};

const getResourceProperties = (resourceId, properties) => {
  return gatewayApi
    .get(`/repository/res/${resourceId}`, {
      params: {
        properties: properties.join(','),
      },
    })
    .then((response) => Promise.resolve(response.data.properties));
};

const updateResource = (resourceId, properties) => {
  return gatewayApi
    .put(`/repository/res/${resourceId}`, properties)
    .then(() => Promise.resolve(resourceId))
    .then(clearCachePassthrough);
};

const updateResources = (resources) => {
  return gatewayApi
    .put('/repository/update-all', { resources })
    .then(() => Promise.resolve())
    .then(clearCachePassthrough);
};

const deleteResources = (resourceIds) => {
  return gatewayApi
    .put(`/repository/delete-all`, {
      resourceIds,
    })
    .then(() => Promise.resolve(resourceIds))
    .then(clearCachePassthrough);
};

const revertResources = (resourceIds) => {
  return gatewayApi
    .put(`/repository/revert-all`, {
      resourceIds,
    })
    .then(() => Promise.resolve(resourceIds))
    .then(clearCachePassthrough);
};

const deleteResource = (resourceId, physicalDelete = false) => {
  return api
    .delete(`/repository/res/${resourceId}`, {
      params: {
        type: physicalDelete ? 'DeletePhysical' : 'DeleteLogical',
      },
    })
    .then(() => Promise.resolve(resourceId))
    .then(clearCachePassthrough);
};

const undeleteResource = (resourceId) => {
  return api
    .put(`/repository/res/${resourceId}/command/undelete`)
    .then(() => Promise.resolve(resourceId))
    .then(clearCachePassthrough);
};

const lockResource = (resourceId) => {
  return api
    .put(`/repository/res/${resourceId}/command/lock`)
    .then(clearCacheGetData);
};

const unlockResource = (resourceId) => {
  return api
    .put(`/repository/res/${resourceId}/command/unlock`)
    .then(clearCacheGetData);
};

const getResourceVersions = (resourceId, properties) => {
  return gatewayApi
    .get(`/repository/res/${resourceId}/versions`, {
      params: {
        properties: properties.join(','),
      },
    })
    .then((response) => Promise.resolve(response.data));
};

const linkDocuments = (resourceIds) => {
  return gatewayApi
    .put(`/repository/link`, null, {
      params: {
        resourceIds: resourceIds.join(','),
      },
    })
    .then(clearCacheGetData);
};

const unlinkDocuments = (resourceIds) => {
  return gatewayApi
    .put(`/repository/unlink`, null, {
      params: {
        resourceIds: resourceIds.join(','),
      },
    })
    .then(clearCacheGetData);
};

const moveResource = (resourceId, folderId) => {
  return api
    .put(`/repository/res/${resourceId}/command/move/${folderId}`)
    .then(clearCacheGetData);
};

const copyResource = (resourceId, folderId) => {
  return api
    .put(`/repository/res/${resourceId}/command/copy/${folderId}`)
    .then(clearCacheGetData);
};

const getReminders = (resourceId) => {
  return gatewayApi
    .get(`/repository/res/${resourceId}/reminders`)
    .then((response) => Promise.resolve(response.data));
};

const createReminder = (resourceId, reminder) => {
  return gatewayApi
    .post(`/repository/res/${resourceId}/reminders`, reminder)
    .then((response) => Promise.resolve(response.data));
};

const updateReminder = (resourceId, reminderId, reminder) => {
  return gatewayApi
    .put(`/repository/res/${resourceId}/reminders/${reminderId}`, reminder)
    .then((response) => Promise.resolve(response.data));
};

const deleteReminder = (resourceId, reminderId) => {
  return gatewayApi
    .delete(`/repository/res/${resourceId}/reminders/${reminderId}`)
    .then((response) => Promise.resolve(response.data));
};

export default {
  getResourceProperties,
  updateResource,
  updateResources,
  deleteResources,
  revertResources,
  deleteResource,
  undeleteResource,
  lockResource,
  unlockResource,
  getResourceVersions,
  linkDocuments,
  unlinkDocuments,
  moveResource,
  copyResource,
  getReminders,
  createReminder,
  updateReminder,
  deleteReminder,
};
