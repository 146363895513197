import groupType from "./groupType";
import group from "./group";
import { Group } from "./group";

/**
 * Group Generator.
 * @category Group
 * @author Patrik Pancisin
 * @class structureGroup
 * @classdesc Basic building block of cosmos.
 * @extends group
 * @param {string} label - The label of the group displayed in cosmos.
 * @param {string} name - The name of the group.
 */
export default (label: string, name: string): Group => ({
  ...group(label, name, "structure"),
  type: groupType.STRUCTURE,
  getDefaultCollapsed() {
    return false;
  },
});
