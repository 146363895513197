import React, { useMemo, useState, useEffect } from "react";
import useProject from "../../../hooks/useProject";
import projectApi from "../apis/project.api";
import ProjectEditorPropertiesContext from "../contexts/ProjectEditorPropertiesContext";
import docareaApi from "../../../apis/docareaApi";
import { Group } from "cosmos-config/lib/group/group";

export interface ResourcePropertiesProviderProps {
  folder: any;
  resourceType: 1 | 2;
  children?: React.ReactNode;
}

const ResourcePropertiesProvider = ({
  folder,
  resourceType,
  children,
}: ResourcePropertiesProviderProps) => {
  const [loading, setLoading] = useState(false);
  const [propertyDefinitions, setPropertyDefinitions] = useState([]);

  const { project, reload } = useProject();

  const projectCode = useMemo(() => project?.code, [project]);

  useEffect(() => {
    docareaApi
      .getPropertyDefinitions("RWE")
      .then((defs) => setPropertyDefinitions(defs));
  }, []);

  return (
    <ProjectEditorPropertiesContext.Provider
      value={{
        resourceType,
        folder,
        translationPathBase: projectCode,
        propertyDefinitions,
        loading,
        canManipulate: () => true,
        deleteProperty: (propertyName) => {
          if (projectCode == null) {
            throw new Error("Project code cannot by null!");
          }

          setLoading(true);
          projectApi
            .deleteProperty(projectCode, propertyName)
            .then(reload)
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
        createGroup: (group: Group) => {
          if (projectCode == null) {
            throw new Error("Project code cannot by null!");
          }

          setLoading(true);
          projectApi
            .createGroup(projectCode, group)
            .then(reload)
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
        createProperty: (groupName, property, createNew = false) => {
          if (projectCode == null) {
            throw new Error("Project code cannot by null!");
          }

          setLoading(true);
          projectApi
            .createProperty(projectCode, groupName, property, createNew)
            .then(reload)
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
        updateProperty: (propertyName, property = {}) => {
          if (projectCode == null) {
            throw new Error("Project code cannot by null!");
          }

          setLoading(true);
          projectApi
            .updateProperty(projectCode, propertyName, property)
            .then(reload)
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
        moveProperty: (propertyName, after) => {
          if (projectCode == null) {
            throw new Error("Project code cannot by null!");
          }

          setLoading(true);
          projectApi
            .moveProperty(projectCode, propertyName, after)
            .then(reload)
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
        groupMoveProperty: (propertyName, groupName, after) => {
          if (projectCode == null) {
            throw new Error("Project code cannot by null!");
          }

          setLoading(true);
          projectApi
            .groupMoveProperty(projectCode, propertyName, groupName, after)
            .then(reload)
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
        updateFolder: (folderType, folder = {}) => {
          if (projectCode == null) {
            throw new Error("Project code cannot by null!");
          }

          setLoading(true);
          projectApi
            .updateFolder(projectCode, folderType, folder)
            .then(reload)
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
        },
      }}
    >
      {children}
    </ProjectEditorPropertiesContext.Provider>
  );
};

export default ResourcePropertiesProvider;
