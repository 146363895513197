import { createContext } from "react";
import { TableColumn, TableColumnOrder } from "../providers/ColumnsProvider";
import { Property } from "cosmos-config/lib/property/property";
import Resource from "../types/resource";

export type PreparePropertiesOptions = {
  alternate?: boolean;
  filter?: boolean;
};

export interface ColumnsContextValue {
  tableColumns: TableColumn<Resource>[];
  documentTableColumns: TableColumn<Resource>[];
  documentTableColumnOrder: TableColumnOrder<Resource>[];
  prepareCustomProperties: <D extends object>(
    customProperties: Property[],
    options?: PreparePropertiesOptions
  ) => TableColumn<D>[];
  taskHistoryColumns: TableColumn<Resource>[];
  defaultColumnOrder: TableColumnOrder<Resource>[];
}

const ColumnsContext = createContext<ColumnsContextValue>({
  tableColumns: [],
  documentTableColumns: [],
  documentTableColumnOrder: [],
  prepareCustomProperties: () => [],
  taskHistoryColumns: [],
  defaultColumnOrder: [],
});

export default ColumnsContext;
