import React, { useContext, useMemo } from "react";
import { LoadingOverlay } from "cosmos-components";
import { DateTime } from "luxon";
import useRepositorySearch from "../../../../hooks/useRepositorySearch";
import DashboardContext from "../../contexts/DashboardContext";
import AlertsTable from "../AlertsTable";
import { CosmosWidget, CosmosWidgetProps } from "../../types/CosmosWidget";

const ExpiringDocuments = ({
  propertyName,
  onSelect,
}: CosmosWidgetProps): CosmosWidget => {
  const { resourceId } = useContext(DashboardContext);

  const filter = useMemo(() => {
    if (propertyName == null) {
      return {};
    }

    return {
      resourcetype: 2,
      [propertyName]: [
        DateTime.now().minus({ weeks: 2 }).toMillis(),
        DateTime.now().plus({ month: 1 }).toMillis(),
      ],
    };
  }, [propertyName]);

  const { data, loading } = useRepositorySearch({
    resourceId: resourceId || "",
    filter,
    subtree: true,
    paginator: {
      number: 0,
      size: 50,
    },
  });

  return (
    <LoadingOverlay loading={loading}>
      <AlertsTable
        documents={data}
        propertyName={propertyName}
        onSelect={onSelect}
      />
    </LoadingOverlay>
  );
};

export default ExpiringDocuments;
