import { authService } from 'cosmos-core';

export default (request) => {
  const authToken = authService.getAuthToken();
  const domain = authService.getDomainName();

  if (authToken != null) {
    request.headers.Authorization = authToken;
    request.headers.Accept = 'application/json';

    if (authToken.includes('Bearer')) {
      request.params = {
        ...request.params,
        domain,
      };
    }
  }

  request.params = {
    ...request.params,
    autoclose: true,
  };

  return request;
};
