import { faArrowRight, faFileImport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import { Button, Form } from "react-bootstrap";
import LocaleEditorContext from "../contexts/LocaleEditorContext";
import classNames from "classnames";

export interface TranslationsExportImportProps {
  translationsType?: string;
  name?: string;
  className?: string;
}

const TranslationsExportImport = ({
  translationsType,
  name,
  className,
}: TranslationsExportImportProps) => {
  const [importFiles, setImportFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const { exportTranslationsExcel, importTranslationsExcel } =
    useContext(LocaleEditorContext);

  useEffect(() => {
    if (inputRef.current != null) {
      inputRef.current.value = "";
    }
  }, [translationsType, name]);

  return (
    <div className={classNames("d-flex align-items-center", className)}>
      <Button
        onClick={() => {
          if (translationsType != null && name != null) {
            exportTranslationsExcel(translationsType, name);
          }
        }}
      >
        Export (excel)
      </Button>

      <FontAwesomeIcon icon={faArrowRight} className="mx-3 text-dark" />

      <Form.File
        multiple={false}
        ref={inputRef}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files != null) {
            setImportFiles(Array.from(e.target.files));
          }
        }}
      />

      <Button
        disabled={importFiles.length !== 1}
        variant="link"
        onClick={() => {
          const [file] = Array.from(importFiles);
          if (file != null && translationsType != null && name != null) {
            importTranslationsExcel(file, translationsType, name);

            if (inputRef.current != null) {
              inputRef.current.value = "";
            }
          }
        }}
      >
        <FontAwesomeIcon icon={faFileImport} className="mr-2" />
        Import (Excel)
      </Button>
    </div>
  );
};

export default TranslationsExportImport;
