import { useEffect, useMemo, useState } from "react";
import { query as parseQuery } from "cosmos-config/nql";
import useProperties from "../hooks/useProperties";
import useProject from "./useProject";
import _ from "lodash";
import { hashCode } from "cosmos-config/utils";
import repositorySearchApi from "../apis/repositorySearchApi";
import { useQuery } from "@tanstack/react-query";

type UseQueryRunnerState = {
  plainQuery: string | null;
  aggregate: boolean;
};

export interface UseQueryRunnerProps {
  resourceId?: string | null;
  nqlQuery?: string;
}

const useQueryRunner = ({ resourceId, nqlQuery }: UseQueryRunnerProps) => {
  const [state, setState] = useState<UseQueryRunnerState>({
    plainQuery: null,
    aggregate: false,
  });

  const { properties } = useProperties();
  const { project } = useProject();

  const ready = useMemo(
    () => project != null && resourceId != null && state.plainQuery != null,
    [project, resourceId, state.plainQuery]
  );

  useEffect(() => {
    if (nqlQuery == null) {
      return;
    }

    const parsedQuery = parseQuery(nqlQuery);
    const plainQuery = parsedQuery.select(
      _.compact(properties.map((p) => p.query))
    );

    const { results } = parsedQuery;

    setState({
      plainQuery,
      aggregate: results.some((r: any) => r.type === "FUNCTION"),
    });
  }, [nqlQuery, properties]);

  const queryKey = useMemo(
    () => ["repository-query", resourceId, hashCode(nqlQuery || "")],
    [resourceId, nqlQuery]
  );

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => {
      if (state.plainQuery == null || resourceId == null) {
        throw new Error("Query cannot be null!");
      }

      if (state.aggregate) {
        return repositorySearchApi.aggregateSearchDocuments(
          resourceId,
          state.plainQuery,
          project?.code
        );
      } else {
        return repositorySearchApi.queryFolderSearch(
          resourceId,
          state.plainQuery,
          null,
          project?.code
        );
      }
    },
    enabled: ready,
  });

  const parsedData = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.items.map((e: any) => {
      return _(e.properties).keyBy("name").mapValues("value").value();
    });
  }, [data]);

  return {
    count: data?.count,
    data: parsedData,
    loading: isLoading,
  };
};

export default useQueryRunner;
