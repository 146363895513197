import { createContext } from "react";
import { RequestAccessModalProps } from "../components/docarea/RequestAccessModal";
import { GroupEditModalProps } from "../components/docarea/GroupEditModal";

export interface ProjectCommandModalProps {
  show?: boolean;
  onClose?: (...args: any[]) => void;
}

export interface GroupAddMemberModalProps extends ProjectCommandModalProps {
  groupName?: string;
  domainName?: string;
  allowInsert?: boolean;
}

export interface GroupImportMembersModalProps extends ProjectCommandModalProps {
  groupName?: string;
}

export interface ProjectCommandContextValue {
  getAddProjectMemberModalProps: () => GroupAddMemberModalProps;
  getImportProjectMembersModalProps: () => GroupImportMembersModalProps;
  getRequestProjectAccessModalProps: () => RequestAccessModalProps;
  getGroupEditModalProps: () => GroupEditModalProps;

  addProjectMember: (groupName: string, domainName: string) => Promise<string>;
  importProjectMembers: (
    groupName: string,
    domainName: string
  ) => Promise<void>;
  requestProjectAccess: (projectCode: string) => Promise<void>;
  editProjectGroup: (groupName: string, domainName: string) => Promise<string>;
  createProjectGroup: (
    parentGroupName?: string,
    domainName?: string
  ) => Promise<string>;
}

const defaultModalProps = {
  show: false,
};

export const projectCommandContextDefaultValue = {
  getAddProjectMemberModalProps: () => defaultModalProps,
  getImportProjectMembersModalProps: () => defaultModalProps,
  getRequestProjectAccessModalProps: () => defaultModalProps,
  getGroupEditModalProps: () => defaultModalProps,
  addProjectMember: () => {
    throw new Error("Context not implemented!");
  },
  importProjectMembers: () => {
    throw new Error("Context not implemented!");
  },
  requestProjectAccess: () => {
    throw new Error("Context not implemented!");
  },
  editProjectGroup: () => {
    throw new Error("Context not implemented!");
  },
  createProjectGroup: () => {
    throw new Error("Context not implemented!");
  },
};

const ProjectCommandContext = createContext<ProjectCommandContextValue>(
  projectCommandContextDefaultValue
);

export default ProjectCommandContext;
