import { useMemo } from "react";
import { uuidv4 } from "cosmos-config/utils";
import { Property } from "cosmos-config/lib/property/property";
import usePropertyRender from "../../../hooks/usePropertyRender";

export interface UseTitleTemplateProps {
  template?: string;
  properties?: Property[];
}

declare type PatternVariable = {
  uuid: string;
  propertyName?: string;
  template: string;
  escapedTemplate: string;
  value?: string;
  index: number;
};

const useTitleTemplate = ({ template, properties }: UseTitleTemplateProps) => {
  const variables = useMemo(() => {
    const items = String(template).match(/\$\{[\w.]+(:[^}]*)?\}/g);

    if (template == null || items == null) {
      return [];
    }

    return items.map((temp) => {
      const matches = temp.match(
        /\$\{(?<propertyName>[\w.]+)(:(?<propertyValue>[^}]*))?\}/
      );
      const escapedTemplate = temp.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
      const index = template.search(new RegExp(escapedTemplate, "g"));

      return {
        uuid: uuidv4(),
        propertyName: matches?.groups?.["propertyName"],
        template: temp,
        escapedTemplate,
        value: matches?.groups?.["propertyValue"],
        index,
      } as PatternVariable;
    });
  }, [template]);

  const relevantProperties = useMemo(() => {
    if (properties == null) {
      return [];
    }

    return properties.filter((prop) =>
      variables.map((v) => v.propertyName).includes(prop.name)
    );
  }, [properties, variables]);

  const render = usePropertyRender(relevantProperties);

  const title = useMemo(() => {
    return variables
      .sort((a, b) => b.index - a.index)
      .reduce(
        (acc, cur) => {
          const [firstPart, ...rest] = acc;

          if (firstPart == null) {
            return rest;
          }

          return [
            firstPart.substring(0, cur.index),
            cur.uuid,
            ...firstPart
              .substring(cur.index)
              .split(new RegExp(cur.escapedTemplate, "g")),
            ...rest,
          ];
        },
        [template]
      )
      .filter((part) => part != null && part !== "")
      .map((part) => {
        const vrbl = variables.find((v) => v.uuid === part);

        if (vrbl != null && vrbl.propertyName != null) {
          return render(vrbl.propertyName, vrbl.value);
        }

        return part;
      });
  }, [render, template, variables]);

  return {
    title,
  };
};

export default useTitleTemplate;
