import { createSlice } from "@reduxjs/toolkit";
import { Project, ProjectMember } from "../contexts/ProjectContext";
import { AccessRequest } from "../types/accessRequest";

export interface ProjectState {
  projects: Project[];
  state: string;
  openedProjectCode: string | null;
  members: Record<string, ProjectMember[]>;
  accessRequests: AccessRequest[];
  spocs: any[];
  loading: boolean;
  import: {
    validationErrors: string[];
  };
}

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: [],
    state: "INITIAL",
    openedProjectCode: null,
    members: {},
    accessRequests: [],
    spocs: [],
    loading: false,
    import: {
      validationErrors: [],
    },
  } as ProjectState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setProjects: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.projects = action.payload;
        state.state = "LOADED";
      }
    },
    openProject: (state, action) => {
      if (action.payload != null) {
        state.openedProjectCode = action.payload;
        state.state = "READY";
      }
    },
    setProjectMembers: (state, action) => {
      state.members = action.payload;
    },
    setAccessRequests: (state, action) => {
      state.accessRequests = action.payload;
    },
    setSpocs: (state, action) => {
      state.spocs = action.payload;
    },
    setImportValidationErrors: (state, action) => {
      state.import.validationErrors = action.payload;
    },
  },
});

export default projectSlice;
