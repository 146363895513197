import { all, fork } from "redux-saga/effects";
import docareaSaga from "./docareaSaga";
import domainSaga from "./domainSaga";
import projectSaga from "./projectSaga";

function* rootSaga() {
  yield all([fork(docareaSaga), fork(projectSaga), fork(domainSaga)]);
}

export default rootSaga;
