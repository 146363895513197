import { PancoSelect } from "cosmos-components";
import { propertyType, valuesetSource } from "cosmos-config/generator";
import { createElement as h, useMemo } from "react";
import useProperties from "../../hooks/useProperties";
import useValueset from "../../hooks/useValueset";
import { PancoSelectProps } from "cosmos-components";
import {
  Property,
  SelectableAttributeProperty,
} from "cosmos-config/lib/property/property";
import { Folder } from "cosmos-config/lib/group/folder";

const toValuesetItem = (item: Property | Folder) => ({
  label: item.label,
  value: item.name,
});

export interface ModuleElementSelectProps extends PancoSelectProps {
  elementType: string;
  elementName: string;
}

const ModuleElementSelect = ({
  name,
  value,
  elementType,
  elementName,
  ...props
}: ModuleElementSelectProps) => {
  const { properties, folders } = useProperties();
  const { getValuesetByName } = useValueset();

  const options = useMemo(() => {
    switch (elementType) {
      case "custom-valueset":
        return properties
          .filter(
            (p) =>
              p.type === propertyType.SELECT &&
              (p as SelectableAttributeProperty).valuesetSource ===
                valuesetSource.CUSTOM_VALUESET
          )
          .map((p) => p as SelectableAttributeProperty)
          .filter((p) => p.valuesetSource === valuesetSource.CUSTOM_VALUESET)
          .map((property) => ({
            label: property.label,
            value: property.valuesetName,
          }));
      case "valueset-item":
        return getValuesetByName(elementName).map((item) => ({
          label: item.label,
          value: item.id,
        }));
      case "selectable-property":
        return properties
          .filter((p) => p.type === propertyType.SELECT)
          .map(toValuesetItem);
      case "property":
        return properties.map(toValuesetItem);
      case "foldertype":
        return folders.map(toValuesetItem);
      default:
        return [];
    }
  }, [elementType, properties, folders, elementName, getValuesetByName]);

  return h(PancoSelect, {
    ...props,
    value,
    options,
  });
};

export default ModuleElementSelect;
