import { DateTime } from "luxon";
import { ProcessInstance, Version } from "../../../apis/repositoryApi";

export const filterVersionsWithin = (
  versions: Version[],
  fromMillis: number,
  toMillis: number | null
) => {
  return versions.filter((version) => {
    const { lastModifiedDate } = version;
    const offset = 3000;
    return (
      lastModifiedDate + offset >= fromMillis &&
      (toMillis == null || lastModifiedDate - offset <= toMillis)
    );
  });
};

export const renderTimeInformation = (processInstance?: ProcessInstance) => {
  if (processInstance == null) {
    return [];
  }

  const { endTime, startTime } = processInstance;

  const startedAt =
    startTime != null
      ? DateTime.fromMillis(startTime).toLocaleString(DateTime.DATETIME_MED)
      : null;
  let endedAt =
    endTime != null
      ? DateTime.fromMillis(endTime).toLocaleString(DateTime.DATETIME_MED)
      : null;

  const relativeTime = endTime != null ? endTime : startTime;
  const humanDiff = DateTime.fromMillis(relativeTime).toRelative();

  if (endedAt != null) {
    return [startedAt, " - ", endedAt, " (", humanDiff, ")"];
  }

  return [startedAt, " (", humanDiff, ")"];
};

export interface MergedVersion extends Version {
  resourceVersion: boolean;
}

export function* generateVersionList(
  reviewVersions: Version[],
  resourceVersions: Version[]
): Generator<MergedVersion> {
  if (
    reviewVersions.length > 0 &&
    resourceVersions.length > 0 &&
    resourceVersions[0].lastModifiedDate < reviewVersions[0].lastModifiedDate
  ) {
    yield { ...resourceVersions[0], resourceVersion: false };
  }

  for (let i = 0; i < reviewVersions.length; i++) {
    const reviewVersion = reviewVersions[i];

    const conflictVersion = resourceVersions.find(
      (rv) => rv.lastModifiedDate === reviewVersion.lastModifiedDate
    );

    if (conflictVersion == null) {
      yield { ...reviewVersion, resourceVersion: false };
    }

    for (const rv of resourceVersions) {
      if (
        rv.lastModifiedDate === reviewVersion.lastModifiedDate ||
        (rv.lastModifiedDate > reviewVersion.lastModifiedDate &&
          (i + 1 === reviewVersions.length ||
            rv.lastModifiedDate < reviewVersions[i + 1]?.lastModifiedDate))
      ) {
        yield {
          ...rv,
          resourceVersion: true,
          resource: {
            ...reviewVersion.resource,
            ...rv.resource,
          },
        } as MergedVersion;
      }
    }
  }
}
