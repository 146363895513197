import { RingLoader } from 'react-spinners';
import { CosmosLogo } from 'cosmos-components';
import styled, { useTheme } from 'styled-components';

export const SplashScreenWrapper = styled.div`
  background-color: ${(props) => props.theme.base};
  height: 100%;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const SplashScreenLoader = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:before {
    content: '';
    background-color: white;
    width: 150px;
    height: 150px;
    position: absolute;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0px 0px 15px #333;
  }
`;

const SplashScreen = () => {
  const theme = useTheme();
  return (
    <SplashScreenWrapper>
      <CosmosLogo large />
      <SplashScreenLoader>
        <RingLoader color={theme?.complementary} />
      </SplashScreenLoader>
    </SplashScreenWrapper>
  );
};

export default SplashScreen;
