import React from "react";
import {
  principalType,
  property,
  valuesetSource,
} from "cosmos-config/generator";
import { DateTime } from "luxon";
import { ReviewStatusIndicator } from "cosmos-components";

const defaultColumnWidth = 140;

const versionReviewProperies = [
  // property("Updated By", "creatorCommonName")
  //   .excludeUINumber()
  //   .excludeForm()
  //   .readonly()
  //   .includeTable(true, defaultColumnWidth),
  property("Updated By")
    .selectablePrincipalAttribute("ReviewContributor", principalType.POSITION)
    .excludeUINumber()
    // .excludeForm()
    .readonly()
    .includeTable(true, 175),
  property("Updated At", "lastModifiedDate")
    .dateAttribute("lastModifiedDate")
    .excludeForm()
    .includeTable(true, defaultColumnWidth)
    .readonly(),
  property("Content Changed")
    .booleanAttribute("contentChanged")
    .useYesNo()
    .excludeForm(),
  property("Review Status", "Review_Status")
    .selectableAttribute(
      "Review_Status",
      "global_review_status",
      valuesetSource.VALUESET
    )
    .excludeFilter()
    .includeTable(true, 75)
    .tableCell((value: any, label: string) =>
      React.createElement(ReviewStatusIndicator, {
        status: value,
        title: label || "Not Reviewed",
      })
    )
    .readonly(),
  property("Review Date")
    .dateAttribute("ReviewDate")
    .excludeFilter()
    .includeTable(true, defaultColumnWidth)
    .readonly(),
  property("Decision Date")
    .dateAttribute("DecisionDate")
    .excludeFilter()
    .includeTable(true, defaultColumnWidth)
    .readonly(),
  property("Review Due Date")
    .dateAttribute("ResourceReviewDueDate")
    .excludeFilter()
    .includeTable(true, defaultColumnWidth)
    .tableCell((value: any, label: string) => {
      if (value != null) {
        const daysDiff = DateTime.fromMillis(value).diffNow("days").get("days");

        if (Math.abs(daysDiff) <= 4) {
          return React.createElement(
            "span",
            {
              className: "text-danger",
            },
            label
          );
        }

        return label;
      }
    })
    .readonly(),
  property("Decision Due Date")
    .dateAttribute("DecisionDueDate")
    .excludeFilter()
    .includeTable(true, defaultColumnWidth)
    .tableCell((value: any, label: string) => {
      if (value != null) {
        const daysDiff = DateTime.fromMillis(value).diffNow("days").get("days");

        if (Math.abs(daysDiff) <= 4) {
          return React.createElement(
            "span",
            {
              className: "text-danger",
            },
            label
          );
        }

        return label;
      }
    })
    .readonly(),
  property("Reviewers")
    .selectableMemberAttribute("Reviewers_RV", 2)
    .multiAttribute("Reviewers_RV")
    .readonly()
    .additionalFilter()
    .includeTable(true, defaultColumnWidth)
    .excludeUINumber(),
  property("Reviewers Completed")
    .selectableMemberAttribute("ReviewersCompleted", 2)
    .multiAttribute("ReviewersCompleted")
    .readonly()
    .includeTable(true, defaultColumnWidth)
    .excludeUINumber(),
  property("Lead Reviewer")
    .selectableMemberAttribute("LeadReviewer", 2)
    .readonly()
    .includeTable(true, defaultColumnWidth)
    .includeTable(true, defaultColumnWidth),
  property("Review Remarks", "ReviewRemarks")
    .includeTable(true, defaultColumnWidth)
    .textArea(1000),
];

export default versionReviewProperies;
