import { useEffect, useMemo, useState } from "react";
import useProject from "./useProject";
import useProperties from "./useProperties";
import repositoryApi from "../apis/repositoryApi";
import _ from "lodash";
import systemProperties from "../contants/systemProperties";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { resourceCorrectionOfGateway } from "cosmos-config/utils";
import useSearchOptions from "./useSearchOptions";
import { Property } from "cosmos-config/lib/property/property";
import { Project } from "../contexts/ProjectContext";
import { Group } from "cosmos-config/lib/group/group";
import Resource from "../types/resource";
import { useCurrentUser } from ".";

interface useResourceProps {
  resourceId?: string;
  projectResourceId?: string;
  autoFetch?: boolean;
}

const useResource = ({
  resourceId,
  projectResourceId,
  autoFetch,
}: useResourceProps) => {
  const { groups, getProjectGroups } = useProperties();
  const { project, projects } = useProject();
  const { authenticated } = useCurrentUser();

  const [resourceGroups, setResourceGroups] = useState<Group[]>([]);
  const [enableFetching, setEnableFetching] = useState(!!autoFetch);
  const [resourceUpdates, setResourceUpdates] = useState<Record<string, any>>(
    {}
  );

  useEffect(() => {
    if (autoFetch) {
      setEnableFetching(autoFetch);
    }
  }, [autoFetch]);

  const resourceProject: Project | null = useMemo(() => {
    if (projectResourceId != null) {
      return projects.find((p) => p.resourceId === projectResourceId) || null;
    }

    return project;
  }, [project, projectResourceId, projects]);

  useEffect(() => {
    if (resourceProject == null || project == null) {
      return;
    }

    if (project.code === resourceProject.code) {
      setResourceGroups(groups);
    } else {
      getProjectGroups(resourceProject.code).then((gs) => {
        setResourceGroups(gs);
      });
    }
  }, [getProjectGroups, groups, project, resourceProject]);

  const properties = useMemo(() => {
    const projectProps: Property[] = _.flatMap(
      resourceGroups,
      (p: Group | Property) => {
        const group = p as Group;
        if (group.group) {
          return group.buildChildren();
        }

        return [p as Property];
      }
    );
    return _.concat(projectProps, systemProperties);
  }, [resourceGroups]);

  const requestProperties = useMemo(() => {
    return _.compact(
      properties.map((p) => p.folderProperty?.query || p.query).sort()
    );
  }, [properties]);

  const { propertiesHash } = useSearchOptions({
    resourceId: resourceId || "",
    properties,
  });

  const { data, isLoading } = useQuery({
    queryKey: ["resource", resourceId, propertiesHash],
    queryFn: () => {
      if (resourceId == null) {
        throw new Error("Resource Id cannot be null!");
      }

      return repositoryApi.getResourceProperties(resourceId, requestProperties);
    },
    enabled: enableFetching && authenticated,
    refetchOnWindowFocus: false,
  });

  const resource: Resource | undefined = useMemo(() => {
    if (data != null && Array.isArray(properties)) {
      const { id, properties: resourceProperties } = data;
      const corrected = resourceCorrectionOfGateway(
        {
          id,
          ..._(resourceProperties).keyBy("name").mapValues("value").value(),
        },
        properties
      );

      return {
        ...corrected,
        ...resourceUpdates,
      };
    }

    return undefined;
  }, [data, properties, resourceUpdates]);

  return {
    properties,
    resource,
    requestProperties,
    loading: isLoading,
    groups: resourceGroups,
    projectName: resourceProject?.name,
    projectCode: resourceProject?.code,
    fetchResource: useCallback(() => setEnableFetching(true), []),
    updateResource: useCallback((propertyName: string, value: any) => {
      setResourceUpdates((ru) => ({
        ...ru,
        [propertyName]: value,
      }));
    }, []),
  };
};

export default useResource;
