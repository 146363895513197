export const END_PROCESS = 'workflow/endProcess';
export const FETCH_PROCESSES = 'workflow/fetchProcesses';
export const SET_PROCESSES = 'workflow/setProcesses';
export const CREATE_PROCESS = 'workflow/createProcess';
export const CREATE_PROCESS_INSTANT_START =
  'workflow/createProcessInstantStart';
export const SET_PROCESS_DEFINITIONS = 'workflow/setProcessDefinitions';
export const RELOAD_PROCESS = 'workflow/reloadProcess';
export const SET_WORKFLOW_LOADING = 'workflow/setLoading';
export const RESUME_PROCESS = 'workflow/resumeProcess';
export const SUSPEND_PROCESS = 'workflow/suspendProcess';
export const CANCEL_PROCESS = 'workflow/cancelProcess';
export const OPEN_PROCESS = 'workflow/openProcess';
export const CLOSE_PROCESS = 'workflow/closeProcess';
export const SET_WORKFLOW_PROCESS = 'workflow/setWorkflowProcess';
export const UPDATE_TASK = 'workflow/updateTask';
export const FULFIL_TASK = 'workflow/fulfilTask';
export const SET_TASK_LOADING = 'workflow/setTaskLoading';
export const SET_PROCESS_TASKS = 'workflow/setProcessTasks';
export const UPDATE_PROCESS_TASK = 'workflow/updateProcessTask';
export const UPDATE_ALL_PROCESS_TASKS = 'workflow/updateAllProcessTasks';
export const FETCH_TASK_HISTORY = 'workflow/fetchTaskHistory';
export const FETCH_PROCESS_HISTORY = 'workflow/fetchProcessHistory';
export const SET_TASK_HISTORY = 'workflow/setTaskHistory';
export const SET_OPENED_PROCESS_ID = 'workflow/setOpenedProcessId';
export const FETCH_PROCESS_TASKS = 'workflow/fetchProcessTasks';
export const FETCH_INITIATED_PROCESSES = 'workflow/fetchInitiatedProcesses';

export const PRELOAD_PROCESS = 'workflow/preloadProcess';
export const SET_PROCESS_PRELOAD = 'workflow/setProcessPreload';
export const SET_NEXT_PROCESS_ID = 'workflow/setNextProcessId';
export const CREATE_FOLDER_PROCESS = 'workflow/createFolderProcess';
export const FETCH_FOLDER_WORKFLOW_STATUS =
  'workflow/fetchFolderWorkflowStatus';

export const SET_WORKFLOW_STATUS = 'workflow/setStatus';
export const EXPORT_WORKFLOW_STATUS_EXCEL = 'workflow/exportStatusExcel';
