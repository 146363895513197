import { useCallback, useMemo } from "react";
import _ from "lodash";
import useMaintainerPermission from "./useMaintainerPermission";
import useResourcePermissions from "./useResourcePermissions";
import {
  parseResourceId,
  isMsOfficeDocument,
  propertyEquals,
} from "../../utils/resourceUtils";
import useProject from "../useProject";
import useCurrentUser from "../useCurrentUser";
import Resource from "../../types/resource";

declare type PermissionsCheckOptions = {
  ignoreSigning?: boolean;
};

export interface UseDocumentPermissionsValue {
  /**
   * Whether all resources on input are documents.
   */
  allAreDocuments: boolean;
  /**
   * Whether current user can logically delete the document(s).
   * @returns Whether permission is granted.
   */
  canDelete: () => boolean;
  /**
   * Whether current user can retrieve the document(s) from hidden state.
   * @returns Whether permission is granted.
   */
  canUndelete: () => boolean;
  /**
   * Whether current user can export the metadata and content of the document(s).
   * @returns Whether permission is granted.
   */
  canExport: () => boolean;
  /**
   * Whether current user can read document(s) metadata.
   * @returns Whether permission is granted.
   */
  canReadProperties: () => boolean;
  /**
   * Whether current user can read document(s) rendition(s).
   * @returns Whether permission is granted.
   */
  canReadRendition: () => boolean;
  /**
   * Whether current user can write metadata of documents(s).
   * @returns Whether permission is granted.
   */
  canWriteProperties: () => boolean;
  /**
   * Whether current user can read the content of the document(s).
   * @returns Whether permission is granted.
   */
  canReadContent: () => boolean;
  /**
   * Whether current user can link documents.
   * @returns Whether permission is granted.
   */
  canLink: () => boolean;
  /**
   * Whether current user can unlink document.
   * @returns Whether permission is granted.
   */
  canUnLink: () => boolean;
  /**
   * Whether current user can start a workflow including document(s).
   * @returns Whether permission is granted.
   */
  canStartWorkflow: () => boolean;
  /**
   * Whether document(s) is(are) locked.
   * @returns Whether permission is granted.
   */
  isLocked: () => boolean;
  isMaintainer: () => boolean;
  /**
   * Whether current user can write content of the document(s).
   * @returns Whether permission is granted.
   */
  canWriteContent: () => boolean;
  /**
   * Whether current user can share document(s).
   * @returns Whether permission is granted.
   */
  canShare: () => boolean;
  /**
   * Whether current user can check out document(s) for editing.
   * @returns Whether permission is granted.
   */
  canCheckOut: () => boolean;
  /**
   * Whether current user can check in document(s) changes.
   * @returns Whether permission is granted.
   */
  canCheckIn: () => boolean;
  /**
   * Whether current user can request a signature for the document(s).
   * @returns Whether permission is granted.
   */
  canRequestSignature: () => boolean;
  /**
   * Whether document(s) is(are) beying currently in the signature process.
   * @returns Whether permission is granted.
   */
  isSigning: () => boolean;
  /**
   * Whether current user can move the document(s).
   * @returns Whether permission is granted.
   */
  canMove: () => boolean;
  /**
   * Whether current user can duplicate the document(s).
   * @returns Whether permission is granted.
   */
  canDuplicate: () => boolean;
  /**
   * Whether document(s) is(are) of type editable by microsoft office.
   * @returns Whether permission is granted.
   */
  isMsOffice: () => boolean;
  isTeamMember: () => boolean;
}

/**
 * Base hook for calculation of current user permissions to the document
 * or array of documents provided in the hook parameter.
 *
 * @param input - Document or array of documents of which
 * permissions are going to be calculated.
 */
const useDocumentPermissions = (
  input?: Resource | Resource[]
): UseDocumentPermissionsValue => {
  const { principalId, permissions } = useCurrentUser();
  const { project } = useProject();

  const documentPermissions = permissions.documentPermissions;
  const linkPermissions = permissions.linkPermissions;

  const { isMaintainer, isTeamMember, canResourcePermission } =
    useResourcePermissions(input);

  const documents = useMemo(() => {
    if (Array.isArray(input)) {
      return input;
    }

    return _.compact([input]);
  }, [input]);

  const { canControl } = useMaintainerPermission(input);

  const objectclassNames = useMemo(
    () => _(documents).flatMap("objectclass").uniq().value(),
    [documents]
  );

  const hidden = useMemo(
    () => _(documents).flatMap("hidden").uniq().value(),
    [documents]
  );

  const lockowner = useMemo(
    () => _(documents).flatMap("lockowner").uniq().value(),
    [documents]
  );

  const signaturerequestor = useMemo(
    () => _(documents).flatMap("signaturerequestor").uniq().value(),
    [documents]
  );

  const signing = useCallback(() => {
    return (
      documents.length > 0 &&
      documents
        .filter((x) => x != null)
        .map(
          (d) =>
            propertyEquals(d, "signaturestatus", "ACTIVE") ||
            propertyEquals(d, "signaturestatus", "PARTIAL")
        )
        .every((d) => d)
    );
  }, [documents]);

  const projectIdentifier = useMemo(() => {
    return _(documents)
      .filter((d) => d != null && d.pathidentifiers != null)
      .map((d) => {
        return d.pathidentifiers[1];
      })
      .uniq()
      .value();
  }, [documents]);

  const permissionObject = useMemo(() => {
    const permissions = objectclassNames
      .filter((x) => x != null && x !== "")
      .map((ocn) => documentPermissions[ocn] || linkPermissions[ocn])
      .filter((x) => x != null);

    return permissions.reduce((acc, cur) => {
      return Object.entries(cur).reduce((innerAcc, [key, value]) => {
        return {
          ...innerAcc,
          [key]: acc[key] && value,
        };
      }, {});
    }, permissions[0]);
  }, [objectclassNames, documentPermissions, linkPermissions]);

  const allAreDocuments = useMemo(
    () =>
      documents.length > 0 &&
      documents.every((d) => d != null && d.resourcetype >= 2),
    [documents]
  );

  const allAreStubs = useMemo(
    () =>
      documents.length > 0 &&
      documents.every((d) => d != null && d.contentitemcount === 0),
    [documents]
  );

  const can = useCallback(
    (permission: string): boolean =>
      permissionObject != null
        ? allAreDocuments && permissionObject[permission]
        : false,
    [permissionObject, allAreDocuments]
  );

  const canCustom = useCallback(
    (permission: string): boolean => {
      if (
        permissionObject != null &&
        permissionObject.customPermissions != null &&
        Array.isArray(permissionObject.customPermissions)
      ) {
        return permissionObject.customPermissions.includes(permission);
      }

      return false;
    },
    [permissionObject]
  );

  const locked = useCallback(
    () => lockowner.some((l) => l != null),
    [lockowner]
  );

  const isDeleted = useCallback(() => hidden.every((c) => !!c), [hidden]);

  const isPortaled = useCallback(() => {
    const { identifier } = parseResourceId(project?.resourceId || "");

    return (
      projectIdentifier.length > 0 &&
      !projectIdentifier.every((i) => i === identifier)
    );
  }, [projectIdentifier, project?.resourceId]);

  const isMsOffice = useCallback(() => {
    return documents.some((d) => {
      return (
        String(d.filename).endsWith("wopitest") ||
        isMsOfficeDocument(d.contenttype) ||
        isMsOfficeDocument(d.refresourcecontenttype)
      );
    });
  }, [documents]);

  const canUpdate = useCallback(
    ({ ignoreSigning } = {} as PermissionsCheckOptions) => {
      return (
        // (isOwner() || isAdministrator()) &&
        canResourcePermission("update") &&
        (!locked() || lockowner.every((lo) => lo === principalId)) &&
        (ignoreSigning || !signing()) &&
        // (canControl() || project?.administrator || isMaintainer())
        canControl()
        // || isMaintainer())
      );
    },
    [canResourcePermission, locked, lockowner, signing, canControl, principalId]
  );

  const canWriteContent = (options?: PermissionsCheckOptions): boolean =>
    can("writeContent") &&
    canUpdate(options) &&
    !isPortaled() &&
    canResourcePermission("update") &&
    isTeamMember(false);
  const canWriteProperties = (options?: PermissionsCheckOptions): boolean =>
    can("writeProperties") &&
    canUpdate(options) &&
    canResourcePermission("update") &&
    isTeamMember(false);
  const canDelete = () =>
    // (isMaintainer() || !!project?.administrator) &&
    // isMaintainer() &&
    canResourcePermission("delete") &&
    can("delete") &&
    !locked() &&
    !isPortaled() &&
    canControl() &&
    isTeamMember(true);

  return {
    allAreDocuments,
    canDelete,
    canUndelete: () => canDelete() && isDeleted(),
    canExport: () => canCustom("export"),
    canReadProperties: () =>
      can("readProperties") && canResourcePermission("read"),
    canReadRendition: () =>
      can("readRendition") && canResourcePermission("read"),
    canWriteProperties,
    canReadContent: () => can("readContent") && canResourcePermission("read"),
    canLink: () => canWriteProperties() && !isPortaled(),
    canUnLink: () => {
      const linkedIds = _(documents)
        .flatMap((d) => _.concat(d.LinkedDocs, d.parentdocumentid))
        .uniq()
        .value();

      return linkedIds.length > 0;
    },
    canStartWorkflow: () =>
      canWriteProperties() && canCustom("startworkflow") && !isPortaled(),
    isLocked: locked,
    isMaintainer,
    canWriteContent,
    canShare: () => !locked() && canResourcePermission("share") && !signing(),
    canCheckOut: () => !isMsOffice() && canWriteContent(),
    canCheckIn: () => canWriteContent() && locked(),
    canRequestSignature: () =>
      canWriteContent({ ignoreSigning: true }) &&
      (!signing() || signaturerequestor.every((sr) => sr === principalId)),
    isSigning: signing,
    canMove: () =>
      canUpdate() && canResourcePermission("move") && !isPortaled(),
    canDuplicate: () => allAreStubs,
    isMsOffice,
    isTeamMember: useCallback(() => isTeamMember(false), [isTeamMember]),
  };
};

export default useDocumentPermissions;
