import { takeEvery, put, call } from "redux-saga/effects";
import * as actions from "../contants/domainConstants";
import { trimUINumber } from "cosmos-config/utils";
import domainApi, { PrincipalEntity } from "../apis/domainApi";
import domainSlice from "../slices/domainSlice";
import complete from "./effects/complete";
import {
  FetchPrincipalsByIdsPayload,
  SearchPrincipalsPayload,
} from "../actions/domainActions";
import { Action } from "@reduxjs/toolkit";

interface ActionOf<T> extends Action {
  payload: T;
}

function* domainSaga() {
  yield takeEvery<ActionOf<SearchPrincipalsPayload>>(
    actions.SEARCH_PRINCIPALS,
    function* (action) {
      const { searchQuery, domainNames, principalType } = action.payload;
      try {
        yield put(domainSlice.actions.setLoading(true));
        //@ts-ignore
        const users: PrincipalEntity[] = yield call(
          domainApi.searchPrincipals,
          searchQuery,
          domainNames,
          [principalType]
        );

        const filteredUsers = users.map((u) => ({
          ...u,
          commonname: trimUINumber(u.commonname),
        }));

        yield complete(action, filteredUsers);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(domainSlice.actions.setLoading(false));
      }
    }
  );

  yield takeEvery<ActionOf<FetchPrincipalsByIdsPayload>>(
    actions.FETCH_PRINCIPALS_BY_IDS,
    function* (action) {
      const { principalIds, principalType, domainNames } = action.payload;
      try {
        yield put(domainSlice.actions.setLoading(true));
        //@ts-ignore
        const users: PrincipalEntity[] = yield call(
          domainApi.getPrincipalsByIds,
          principalIds,
          domainNames,
          [principalType]
        );

        const filteredUsers = users.map(
          (u) =>
            ({
              ...u,
              commonname: trimUINumber(u.commonname),
            } as PrincipalEntity)
        );
        yield complete(action, filteredUsers);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(domainSlice.actions.setLoading(false));
      }
    }
  );
}

export default domainSaga;
