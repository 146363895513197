import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from '../../Actions/ui';

const Notifications = () => {
  const notifications = useSelector((state) => state.ui.notifications);
  const dispatch = useDispatch();

  const getClass = (notificationType) => {
    switch (notificationType) {
      case 'success':
        return 'bg-success text-light';
      case 'system-error':
      case 'user-error':
      case 'error':
        return 'bg-danger text-light';
      case 'info':
        return 'bg-info text-light';
      case 'warning':
        return 'bg-warning text-light';
      default:
        return '';
    }
  };

  return (
    <div className="notifications-container">
      {notifications.map((notification, idx) => (
        <Toast
          key={`notification-${idx}`}
          className={getClass(notification.type)}
          onClose={() => {
            dispatch(removeNotification(notification.id));
          }}
        >
          <Toast.Header>
            <strong className="mr-auto text-capitalize">
              {notification.type}
            </strong>
          </Toast.Header>
          <Toast.Body>{notification.message}</Toast.Body>
        </Toast>
      ))}
    </div>
  );
};

export default Notifications;
