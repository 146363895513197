import {
  select,
  put,
  fork,
  takeEvery,
  takeLatest,
  takeLeading,
  all,
  call,
} from "redux-saga/effects";
import { writeFile, utils } from "xlsx";
import _ from "lodash";
import projectApi from "../apis/projectApi";
import userManagementSlice from "../slices/userManagementSlice";
import { fetchAllProjectMembers } from "../actions/projectActions";
import { getProjectMembers } from "../selectors/projectSelector";
import * as actions from "../contants/userManagementConstants";
import { getSelectedGroup } from "../selectors/usermanagement";
import complete from "./effects/complete";

// function* userSelectionFlow(domainName, groupName, projectCode) {
//   yield takeLatest(
//     userManagementSlice.actions.selectUsers.type,
//     function* ({ payload: users }) {
//       yield takeLeading(actions.GROUP_DELETE_MEMBER, function* () {
//         try {
//           yield put(userManagementSlice.actions.setLoading(true));

//           const deleteSagas = users.map((principal) =>
//             call(function* () {
//               if (principal.group) {
//                 yield call(
//                   projectApi.deleteProjectSubGroup,
//                   projectCode,
//                   domainName,
//                   principal.name
//                 );
//               } else {
//                 yield call(
//                   projectApi.removeProjectMember,
//                   projectCode,
//                   groupName,
//                   principal.principalId
//                 );
//               }
//             })
//           );

//           yield all(deleteSagas);
//         } catch (err) {
//           console.error(err);
//           // yield put(notify(`Failed with reason: ${err.message}`, "error"));
//         } finally {
//           yield put(userManagementSlice.actions.setLoading(false));
//           yield put(fetchAllProjectMembers());
//         }
//       });
//     }
//   );
// }

function* userManagementSaga(projectCode) {
  yield takeLatest(
    userManagementSlice.actions.selectGroup.type,
    function* ({ payload }) {
      const groupName = payload;

      const group = yield select(getSelectedGroup);

      if (group == null) {
        return;
      }

      const { domainName } = group;

      // yield takeLeading(actions.CREATE_GROUP, function* (action) {
      //   const { group } = action.payload;

      //   let name = group.commonname.replace(/[^a-zA-Z0-9]/gi, "_");
      //   name = `${groupName}_${String(name).trim().toUpperCase()}`;

      //   try {
      //     yield put(userManagementSlice.actions.setLoading(true));
      //     const result = yield call(
      //       projectApi.createProjectSubGroup,
      //       projectCode,
      //       domainName,
      //       groupName,
      //       {
      //         ...group,
      //         name,
      //       }
      //     );

      //     yield complete(action, result);
      //   } catch (err) {
      //     console.error(err);
      //     // yield put(notify(`Failed with reason: ${err.message}`, "error"));
      //   } finally {
      //     yield put(userManagementSlice.actions.setLoading(false));
      //     yield put(fetchAllProjectMembers());
      //   }
      // });

      // yield takeEvery(actions.GROUP_ADD_MEMBER, function* (action) {
      //   const { principalId } = action.payload;
      //   try {
      //     yield put(userManagementSlice.actions.setLoading(true));
      //     yield call(
      //       projectApi.addProjectMember,
      //       projectCode,
      //       groupName,
      //       principalId
      //     );

      //     yield complete(action);
      //   } catch (err) {
      //     console.error(err);
      //     // yield put(notify(`Failed with reason: ${err.message}`, "error"));
      //   } finally {
      //     yield put(userManagementSlice.actions.setLoading(false));
      //     yield put(fetchAllProjectMembers());
      //   }
      // });

      // yield takeLeading(actions.DELETE_GROUP, function* (action) {
      //   try {
      //     yield put(userManagementSlice.actions.setLoading(true));
      //     yield call(
      //       projectApi.deleteProjectSubGroup,
      //       projectCode,
      //       domainName,
      //       groupName
      //     );

      //     yield complete(action);
      //   } catch (err) {
      //     console.error(err);
      //     // yield put(notify(`Failed with reason: ${err.message}`, "error"));
      //   } finally {
      //     yield put(userManagementSlice.actions.setLoading(false));
      //     yield put(fetchAllProjectMembers());
      //   }
      // });

      // yield takeLeading(actions.UPDATE_GROUP, function* (action) {
      //   try {
      //     yield put(userManagementSlice.actions.setLoading(true));

      //     const { group, groupName } = action.payload;

      //     yield call(
      //       projectApi.updateProjectSubGroup,
      //       projectCode,
      //       domainName,
      //       groupName,
      //       group
      //     );

      //     yield complete(action);
      //   } catch (err) {
      //     console.error(err);
      //     // yield put(notify(`Failed with reason: ${err.message}`, "error"));
      //   } finally {
      //     yield put(userManagementSlice.actions.setLoading(false));
      //     yield put(fetchAllProjectMembers());
      //   }
      // });

      yield takeEvery(actions.EXPORT_GROUP_MEMBERS_EXCEL, function* () {
        try {
          yield put(
            userManagementSlice.actions.setLoading(
              true,
              "Generating xls export of users."
            )
          );

          const projectMembers = yield select(getProjectMembers);
          const principals = projectMembers[groupName] || [];

          const users = principals.filter((p) => !p.group);

          const wb = utils.book_new();
          const ws = utils.json_to_sheet(users);

          utils.book_append_sheet(wb, ws, "users_export.xlsx");
          writeFile(wb, "users_export.xlsx");
        } catch (err) {
          console.error(err);
        } finally {
          yield put(userManagementSlice.actions.setLoading(false));
        }
      });

      // yield fork(userSelectionFlow, domainName, groupName, projectCode);
    }
  );
  yield takeEvery(actions.EXPORT_ALL_GROUP_MEMBERS_EXCEL, function* () {
    try {
      yield put(
        userManagementSlice.actions.setLoading(
          true,
          "Generating xls export of all users"
        )
      );

      const projectMembers = yield select(getProjectMembers);
      const allPrincipals =
        _.unionBy(...Object.values(projectMembers), "principalId") || [];
      const allUsers = allPrincipals.filter((p) => !p.group);

      const wb = utils.book_new();
      const ws = utils.json_to_sheet(allUsers);

      const xlsxName = "all_users_export.xlsx";
      utils.book_append_sheet(wb, ws, xlsxName);
      writeFile(wb, xlsxName);
    } catch (err) {
      console.error(err);
    } finally {
      yield put(userManagementSlice.actions.setLoading(false));
    }
  });
}

export default userManagementSaga;
