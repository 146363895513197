import React from "react";
import { Button, Row, Col, FormCheck, Alert } from "react-bootstrap";
import useProject from "../../hooks/useProject";
import {
  LoadingOverlay,
  PancoPagination,
  PancoSelect,
} from "cosmos-components";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { replaceAllValues } from "../../actions/docareaActions";
import { ValuesetIssue } from "./ValuesetIssues";
import _ from "lodash";
import Resource from "../../types/resource";
import styled from "styled-components";
import { TableColumnOrder } from "../../providers/ColumnsProvider";
import useValueset from "../../hooks/useValueset";
import useCoreDispatch from "../../hooks/useCoreDispatch";
import useAdaptiveRepositoryTable from "../../components/useAdaptiveRepositoryTable";
import RepositoryTable from "../../components/repository-table/RepositoryTable";
import useCoreSelector from "../../hooks/useCoreSelector";
import { CosmosCoreRootState } from "../../store";
import { getLoadingValueset } from "../../selectors/docareaSelector";
import { TableAllScreen } from "../../components/tableFragments";

function* generateColumnOrder(
  columnOrder: TableColumnOrder<Resource>[],
  propertyName: string
) {
  const [first, ...rest] = columnOrder;
  let order = 0;

  yield {
    ...first,
    order: order++,
  };

  const columnIndex = columnOrder.findIndex((co) => co.id === propertyName);
  if (columnIndex === -1) {
    yield {
      id: propertyName,
      width: 500,
      order: order++,
    } as TableColumnOrder<Resource>;
  }

  for (let i = 0; i < rest.length; i++) {
    const co = rest[i];

    yield {
      ...co,
      width: co.id === propertyName ? 500 : co.width,
      order: order++,
    };
  }
}

interface IssueResolverContextValue {
  value: string | null;
  propertyName: string | null;
  resourceIds: string[];
  replacementValue: any;
  setReplacementValue: (value: any) => void;
  executeReplace: () => void;
}

const IssueResolverContext = createContext<IssueResolverContextValue>({
  value: null,
  propertyName: null,
  resourceIds: [],
  replacementValue: null,
  setReplacementValue: () => {},
  executeReplace: () => {},
});

interface IssueResolveProps {
  disabled?: boolean;
}

const IssueResolve = ({ disabled }: IssueResolveProps) => {
  const { getValuesetByProperty } = useValueset();

  const {
    value,
    propertyName,
    resourceIds,
    replacementValue,
    setReplacementValue,
    executeReplace,
  } = useContext(IssueResolverContext);

  const options = useMemo(() => {
    if (propertyName == null) {
      return [];
    }

    return getValuesetByProperty(propertyName);
  }, [propertyName, getValuesetByProperty]);

  return (
    <>
      <Alert variant="warning">
        Replace value <b>"{value}"</b> in <b>{resourceIds.length || "All"}</b>{" "}
        documents by the following valueset item:
      </Alert>

      <PancoSelect
        boxed
        options={options}
        disabled={disabled}
        clearable
        onChange={(item) => {
          if (Array.isArray(item.value)) {
            setReplacementValue(item.value[0]);
          } else {
            setReplacementValue(item.value);
          }
        }}
      />

      <div className="text-right mt-3">
        <Button
          variant="danger"
          disabled={disabled || replacementValue == null}
          onClick={() => {
            executeReplace();
          }}
        >
          Replace
        </Button>
      </div>
    </>
  );
};

export interface IssueDetailsProps {
  propertyName: string;
  issue: ValuesetIssue;
  onResolve?: () => void;
}

const ColumnLeftSeparator = styled(Col)`
  border-left: 2px solid ${(props) => props.theme.base};
`;

const IssueDetails = ({
  propertyName,
  issue,
  onResolve,
}: IssueDetailsProps) => {
  const dispatch = useCoreDispatch();
  const valuesetLoading =
    useCoreSelector<CosmosCoreRootState>(getLoadingValueset);

  const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>([]);
  const [replacementValue, setReplacementValue] = useState<any>();
  const { project } = useProject();

  const handleAdaptData = useCallback(
    (data: Resource[]) => {
      return data.map((resource) => {
        if (
          replacementValue != null &&
          (selectedResourceIds.length <= 0 ||
            selectedResourceIds.includes(resource.id))
        ) {
          const oldValue = resource[propertyName];
          let newValue = replacementValue;
          if (Array.isArray(oldValue)) {
            newValue = _.concat(
              oldValue.filter((v) => v !== issue?.value),
              replacementValue
            );
          }

          return {
            ...resource,
            [propertyName]: newValue,
          };
        }

        return resource;
      });
    },
    [propertyName, selectedResourceIds, replacementValue, issue]
  );

  const handleAdaptColumnOrder = useCallback(
    (columnOrder: TableColumnOrder<Resource>[]) => {
      return [...generateColumnOrder(columnOrder, propertyName)];
    },
    [propertyName]
  );

  const handleSelect = useCallback((resources: Resource[]) => {
    const ids = _.map(resources, (r) => r.id);
    setSelectedResourceIds(ids);
  }, []);

  const resourceId = useMemo(() => project?.resourceId || "", [project]);

  const {
    getTableProps,
    getPaginatorProps,
    loading: repositoryLoading,
    refetch,
  } = useAdaptiveRepositoryTable({
    resourceId,
    filter: {
      [propertyName]: issue?.value,
    },
    subtree: true,
    adaptColumnOrder: handleAdaptColumnOrder,
    adaptData: handleAdaptData,
    onSelect: handleSelect,
    pageSize: 20,
  });

  return (
    <IssueResolverContext.Provider
      value={{
        propertyName,
        value: issue?.value,
        resourceIds: selectedResourceIds,
        replacementValue,
        setReplacementValue,
        executeReplace: () => {
          const value = issue?.value;
          if (propertyName != null && value != null) {
            dispatch(
              replaceAllValues({
                propertyName,
                value,
                newValue: replacementValue,
                resourceIds: selectedResourceIds,
              })
              // @ts-ignore
            ).then(() => {
              if (onResolve != null) {
                setReplacementValue(null);
                refetch();
                onResolve();
              }
            });
          }
        },
      }}
    >
      <LoadingOverlay loading={repositoryLoading || !!valuesetLoading}>
        <Row>
          <Col md={9}>
            <div style={{ height: "80vh" }}>
              <TableAllScreen>
                {/* @ts-ignore */}
                <RepositoryTable
                  {...getTableProps({
                    simplify: true,
                    multiSelection: true,
                    watchedColumns: [propertyName],
                  })}
                />

                <div className="d-flex justify-content-center">
                  <PancoPagination {...getPaginatorProps()} />
                </div>
              </TableAllScreen>
            </div>
          </Col>
          <ColumnLeftSeparator md={3}>
            <FormCheck
              name="replace-all-check"
              className="mb-3"
              checked={selectedResourceIds.length <= 0}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedResourceIds([]);
                }
              }}
              label="Replace all occurrences"
            />

            <IssueResolve />
          </ColumnLeftSeparator>
        </Row>
      </LoadingOverlay>
    </IssueResolverContext.Provider>
  );
};

export default IssueDetails;
