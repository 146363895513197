import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { createElement } from "react";
import {
  valuesetSource,
  property,
  systemProperty,
} from "cosmos-config/generator";
import accessLevelValueset from "./acessLevelValueset";
import _ from "lodash";

type SortRow = {
  values: Record<string, any>;
};

const resourcePermission = [
  systemProperty("id"),
  systemProperty("resourceId"),
  systemProperty("principalId").informational(),
  property("Ext", "domainName")
    .informational()
    .queryProperty(null)
    .excludeForm()
    .includeTable(true, 30)
    .tableCell((value) => {
      if (value === "ExternalUsers") {
        return createElement(FontAwesomeIcon, {
          icon: faCheck,
          className: "text-success",
        });
      } else {
        return "";
      }
    }),
  property("User", "permittedusercommonname")
    .resolveUsername("principalId")
    .readonly()
    .informational(),
  property("Member Of", "membership")
    .informational()
    .queryProperty(null)
    .excludeForm(),

  property("Active")
    .booleanAttribute("active")
    .useYesNo()
    .tableCell((value) =>
      createElement(FontAwesomeIcon, {
        icon: value ? faCheck : faCircle,
        className: value ? "text-success" : "text-muted",
      })
    )
    .optional()
    .informational(),
  property("Access Level", "accessLevel")
    .selectableAttribute(
      "accessLevel",
      "accessLevel",
      valuesetSource.STATIC_VALUESET
    )
    .valueset(accessLevelValueset)
    .sortWith((a, b, index) => {
      const getLevel = (input: SortRow) => {
        const val = input.values[index];
        const decrement =
          input.values["domainName"] === "ExternalUsers" ? 1 : 0;
        return _.get(val, "[0]", val) - decrement;
      };

      return getLevel(a) - getLevel(b);
    })
    .informational(),
  property("Begins At")
    .dateAttribute("beginsAt")
    .minValue(() => {
      return DateTime.now().minus({ days: 1 }).toMillis();
    })
    .excludeTime()
    .optional()
    .informational(),
  property("Expires At")
    .dateAttribute("expiresAt")
    .minValue(() => {
      return DateTime.now().toMillis();
    })
    .excludeTime()
    .optional()
    .informational(),
];

export default resourcePermission;
