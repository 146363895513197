import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import useProperties from "../../../hooks/useProperties";
import SimpleGroupedUniversalForm from "../../SimpleGroupedUniversalForm";
import { CosmosFormGroupProps, MayUpdateResource } from "./CosmosFormGroup";

export interface ModulePropertiesGroupProps
  extends CosmosFormGroupProps,
    MayUpdateResource {}

const ModulePropertiesGroup = ({
  group,
  resource,
  onUpdateResource,
}: ModulePropertiesGroupProps) => {
  const { groups, getFolderGroups } = useProperties();
  const [editedResource, setEditedResource] = useState({});

  const groupedProperties = useMemo(() => {
    //@ts-ignore
    if (group?.resourceType === 1 && resource?.folderType != null) {
      const resourceType = Array.isArray(resource.folderType)
        ? resource.folderType[0]
        : resource.folderType;
      return getFolderGroups(resourceType);
    }

    return groups;
  }, [group, resource, groups, getFolderGroups]);

  useEffect(() => {
    setEditedResource((er) => {
      //@ts-ignore
      if (group?.propertyName == null) {
        return {};
      }

      //@ts-ignore
      const newEr = resource?.[group.propertyName] || {};

      if (_.isEqual(er, newEr)) {
        return er;
      }

      return newEr;
    });
  }, [resource, group]);

  return (
    <SimpleGroupedUniversalForm
      groups={groupedProperties}
      onUpdateResource={(id, name, value) => {
        if (onUpdateResource != null) {
          //@ts-ignore
          const propertyName = `${group?.propertyName}.${name}`;
          onUpdateResource(id, propertyName, value);
        }
      }}
      resource={editedResource}
      columns={2}
      validation={false}
      grouping
    />
  );
};

export default ModulePropertiesGroup;
