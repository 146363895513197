import React, {
  useEffect,
  createElement as h,
  useRef,
  useReducer,
} from "react";
import PancoSelectBadge from "../panco-select/PancoSelectBadge";
import {
  InputValueHandler,
  PancoInputElementProps,
} from "../../types/PancoInputElemenProps";
import FormControl, {
  FormControlContent,
  FormControlField,
  FormControlProps,
} from "../FormControl";

const INITIALIZE_INPUT = "INITIALIZE_INPUT";
const SELECT_VALUE = "SELECT_VALUE";
const CLEAR_SELECTION = "CLEAR_SELECTION";
const UPDATE_VALUE = "UPDATE_VALUE";

declare type PancoInputState = {
  selectedItems: string[];
  filterValue: string | null;
  inputFieldValue: string | null;
  multiple: boolean;
  changeCounter: number;
};

const initialState = {
  selectedItems: [],
  filterValue: null,
  inputFieldValue: null,
  multiple: false,
  changeCounter: 0,
};

const reducer = (state: PancoInputState, action: any) => {
  switch (action.type) {
    case INITIALIZE_INPUT:
      return {
        ...state,
        multiple: action.multiple,
        selectedItems: Array.isArray(action.value)
          ? action.value
          : [action.value].filter((x) => x != null && x !== ""),
        inputFieldValue:
          action.multiple || action.value == null
            ? null
            : action.value.toString(),
        changeCounter: 0,
      };
    case SELECT_VALUE:
      if (state.selectedItems.includes(action.value)) {
        return state;
      }

      return {
        ...state,
        selectedItems: state.multiple
          ? [...state.selectedItems, action.value]
          : [action.value],
        inputFieldValue: null,
        changeCounter: state.changeCounter + 1,
      };
    case CLEAR_SELECTION:
      return {
        ...state,
        selectedItems:
          action.item != null
            ? state.selectedItems.filter((i) => i !== action.item)
            : [],
        changeCounter: state.changeCounter + 1,
      };
    case UPDATE_VALUE:
      return {
        ...state,
        inputFieldValue: action.value,
      };
    default:
      return state;
  }
};

interface PancoInputProps
  extends PancoInputElementProps,
    InputValueHandler<string | string[]>,
    FormControlProps {
  numeric?: boolean;
}

const PancoInput = ({
  name,
  value,
  onChange,
  multiple,
  disabled,
  numeric,
  placeholder,
  ...props
}: PancoInputProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch({ type: INITIALIZE_INPUT, value, multiple });
  }, [value, dispatch, multiple]);

  useEffect(() => {
    if (state.changeCounter > 0 && onChange != null) {
      onChange({ name, value: state.selectedItems });
    }
  }, [state.changeCounter, state.selectedItems, name, onChange]);

  const renderSelectedContent = (items: string[]) => {
    if (!multiple) {
      return "";
    }

    return items
      .filter((x) => x != null)
      .map((v) =>
        h(
          PancoSelectBadge,
          {
            key: `multi-option-${v}`,
            onClear: () => {
              dispatch({ type: CLEAR_SELECTION, item: v });
            },
            disabled,
            clearable: true,
          },
          v
        )
      );
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (multiple && e.key === "Enter" && inputRef.current != null) {
      const inputValue = inputRef.current.value;
      dispatch({ type: SELECT_VALUE, value: inputValue });
      inputRef.current.value = "";
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: UPDATE_VALUE, value: e.target.value });
    if (!multiple && onChange != null) {
      onChange({ name, value: e.target.value });
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = state.inputFieldValue;
    if (!multiple) {
      // onChange({ name, value: inputValue });
    } else if (inputValue != null && inputValue !== "") {
      dispatch({ type: SELECT_VALUE, value: inputValue });
      if (inputRef.current != null) {
        inputRef.current.value = "";
      }
    }
  };

  return (
    <FormControl {...props}>
      <FormControlContent
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={() => {
          if (inputRef.current != null) {
            inputRef.current.focus();
          }
        }}
      >
        {renderSelectedContent(state.selectedItems)}
        <FormControlField
          ref={inputRef}
          type={numeric ? "number" : "text"}
          className="has-focus"
          onKeyPress={handleKeyPress}
          onChange={handleChange}
          onBlur={handleBlur}
          value={state.inputFieldValue || ""}
          disabled={disabled}
          placeholder={placeholder}
          style={{
            width:
              state.inputFieldValue != null
                ? `${state.inputFieldValue.length + 3}ch`
                : "2ch",
          }}
        />
      </FormControlContent>
    </FormControl>
  );
};

export default PancoInput;
