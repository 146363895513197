import * as actions from '../Actions/types';

const initialState = {
  projects: [],
  openedProjectCode: null,
  properties: [],
  messages: [],
  loading: false,
  widgets: [],
  queries: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.project.OPEN_PROJECT:
      return {
        ...state,
        openedProjectCode: action.projectCode,
        properties: [],
      };
    case actions.project.SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case actions.project.UPDATE_PROJECT:
      return {
        ...state,
        projects: state.projects.map((p) => {
          if (p.id === state.openedProjectCode) {
            return {
              ...p,
              [action.name]: action.value,
            };
          }

          return p;
        }),
      };
    case actions.project.SET_PROJECT_PROPERTIES:
      return {
        ...state,
        properties: action.properties,
      };
    case actions.message.SET_PROJECT_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
    case actions.document.SET_LOADING_PROJECT:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.project.SET_WIDGETS:
      return {
        ...state,
        widgets: action.widgets,
      };
    case actions.project.PROJECT_QUERIES_SET:
      return {
        ...state,
        queries: action.queries,
      };
    case actions.filterFolder.CLEAR_STORES:
      return initialState;
    default:
      return state;
  }
};
