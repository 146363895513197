import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal, ModalDialog } from "react-bootstrap";
import useCoreSelector from "../../../hooks/useCoreSelector";
import AccessRequests from "../AccessRequests";
import styled from "styled-components";

const FullscreenDialog = styled(ModalDialog)`
  @media (min-width: 1200px) {
    max-width: 98vw !important;
  }
`;

const AccessRequestsButton = () => {
  const [showAccessRequests, setShowAccessRequests] = useState(false);

  const accessRequests = useCoreSelector(
    (state) => state.project.accessRequests
  );

  useEffect(() => {
    if (accessRequests.length === 0) {
      setShowAccessRequests(false);
    }
  }, [accessRequests]);

  return (
    <>
      {accessRequests.length > 0 && (
        <Button onClick={() => setShowAccessRequests(true)} block>
          Access Requests ({accessRequests.length})
        </Button>
      )}
      <Modal
        size="xl"
        dialogAs={FullscreenDialog}
        show={showAccessRequests}
        onHide={() => setShowAccessRequests(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Access Requests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AccessRequests />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccessRequestsButton;
