import _ from "lodash";
import { useCallback, useEffect } from "react";
import {
  ActionType,
  ColumnInstance,
  HeaderGroup,
  Meta,
  TableInstance,
  UseTableHooks,
} from "react-table";
import { TableState } from "react-table";
import { TableColumnOrder } from "../../providers/ColumnsProvider";

export type UseTableColumnOrderState<D extends object> = {
  editMode: boolean;
  systemColumns: string[];
  columnPositions: TableColumnOrder<D>[];
  initialPositions: TableColumnOrder<D>[];
  columnNames: string[];
};

export type UseTableColumnOrderColumnProps<D extends object> = {
  columnWidth?: number;
  replacePosition: (columnId: string) => void;
};

export interface UseTableColumnOrderInstanceProps<D extends object> {
  toggleEditMode: (editMode: boolean) => void;
  reorderColumns: (source: string, target: string) => void;
  columnPositions: TableColumnOrder<D>[];
}

export type UseTableColumnOrderOptions<D extends object> = {
  onColumnPositionsChange?: (columnPositions: TableColumnOrder<D>[]) => void;
};

const actions = {
  INITIALIZE_POSITIONS: "useTable/initialize",
  TOGGLE_EDIT_MODE: "useTable/toggleEditMode",
  INITIALIZE_COLUMNS: "useTable/initializeColumns",
  INSERT_COLUMN: "useTable/insertColumn",
};

const getHiddenColumns = <D extends object>(state: TableState<D>) => {
  const { columnNames, columnOrder, systemColumns } = state;
  const excludedColumns = state.editMode
    ? columnOrder
    : _.concat(columnOrder, systemColumns);
  return _.uniq(_.without(columnNames, ...excludedColumns));
};

const calculatePositions = <D extends object>(
  state: TableState<D>
): TableColumnOrder<D>[] => {
  const initialStateWidthMap = _(state.initialPositions)
    .keyBy("id")
    .mapValues("width")
    .value();

  return _(state.columnOrder)
    .without(...(state.hiddenColumns || []))
    .map((id, order) => {
      return {
        id,
        width: _.get(
          state,
          `columnResizing.columnWidths.${id}`,
          initialStateWidthMap[id] || 200
        ),
        order,
      };
    })
    .value();
};

function reducer<D extends object>(state: TableState<D>, action: ActionType) {
  const getUpdatedPositionsState = (newState: TableState<D>): TableState<D> => {
    return {
      ...newState,
      columnPositions: calculatePositions(newState),
    };
  };

  const getUpdatedHiddenState = (newState: TableState<D>): TableState<D> => {
    return {
      ...newState,
      hiddenColumns: getHiddenColumns(newState),
    };
  };

  if (action.type === actions.TOGGLE_EDIT_MODE) {
    if (!action.payload) {
      const hiddenColumns = _(state.hiddenColumns)
        .without(...state.systemColumns)
        .uniq()
        .compact()
        .value();

      return getUpdatedPositionsState({
        ...state,
        editMode: false,
        hiddenColumns,
      });
    }

    const hiddenColumns = _(state.hiddenColumns)
      .concat(state.systemColumns)
      .uniq()
      .compact()
      .value();

    return {
      ...state,
      editMode: true,
      hiddenColumns,
      columnPositions: [],
    };
  }

  if (action.type === actions.INITIALIZE_POSITIONS) {
    return getUpdatedHiddenState({
      ...state,
      initialPositions: action.payload,
      columnOrder: _.orderBy(action.payload, "order").map((o) => o.id),
      columnPositions: [],
    });
  }

  if (action.type === actions.INITIALIZE_COLUMNS) {
    const columnNames = _(action.payload).map("id").sort().value();

    if (_.isEqual(columnNames, state.columnNames)) {
      return state;
    }

    return getUpdatedHiddenState({
      ...state,
      columnNames,
    });
  }

  if (
    action.type === "columnDoneResizing" ||
    action.type === "toggleHideColumn" ||
    action.type === "setColumnOrder"
  ) {
    return getUpdatedPositionsState(state);
  }

  if (action.type === actions.INSERT_COLUMN) {
    const { source, target } = action.payload;

    if (source === target || source == null || target == null) {
      return state;
    }

    const targetIdx = state.columnOrder.indexOf(target);
    const columnOrder = state.columnOrder.filter((c) => c !== source);
    columnOrder.splice(targetIdx, 0, source);

    return getUpdatedPositionsState({
      ...state,
      hiddenColumns: _.without(state.hiddenColumns, source),
      columnOrder,
    });
  }

  return state;
}

const mapColumnWidth = <D extends object>(
  column: ColumnInstance<D>,
  columnOrderMap: Record<string, TableColumnOrder<D>>,
  excludedColumnIds: string[] = []
): ColumnInstance<D> => {
  if (excludedColumnIds.includes(column.id)) {
    return column;
  }

  const orderElement = columnOrderMap[column.id];
  if (orderElement != null) {
    column.width = orderElement.width;
  } else {
    column.width = column.columnWidth;
  }

  return column;
};

function useInstance<D extends object>(instance: TableInstance<D>) {
  const {
    allColumns,
    dispatch,
    state,
    columnPositions,
    onColumnPositionsChange,
  } = instance;

  const columnOrderMap = _(columnPositions).mapKeys("id").value();

  useEffect(() => {
    dispatch({
      type: actions.INITIALIZE_POSITIONS,
      payload: columnPositions,
    });
  }, [dispatch, columnPositions]);

  useEffect(() => {
    dispatch({
      type: actions.INITIALIZE_COLUMNS,
      payload: allColumns,
    });
  }, [dispatch, allColumns]);

  const toggleEditMode = useCallback(
    (em: boolean) => dispatch({ type: actions.TOGGLE_EDIT_MODE, payload: em }),
    [dispatch]
  );

  const reorderColumns = useCallback(
    (source: string, target: string) => {
      dispatch({
        type: actions.INSERT_COLUMN,
        payload: {
          source,
          target,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      onColumnPositionsChange != null &&
      _.compact(state.columnPositions).length > 0
    ) {
      onColumnPositionsChange(state.columnPositions);
    }
  }, [state.columnPositions, onColumnPositionsChange]);

  useEffect(() => {
    allColumns.map((column) =>
      mapColumnWidth(column, columnOrderMap, state.systemColumns)
    );
  }, [allColumns, columnOrderMap, state.systemColumns]);

  Object.assign(instance, {
    toggleEditMode,
    reorderColumns,
  });
}

function columnsWidthMapping<D extends object>(
  allColumns: Array<ColumnInstance<D>>,
  meta: Meta<D>
) {
  const { instance } = meta;
  const { columnPositions, state } = instance;

  const columnOrderMap = _(columnPositions).mapKeys("id").value();

  return allColumns.map((column) =>
    mapColumnWidth(column, columnOrderMap, state.systemColumns)
  );
}

function headersPreparation<D extends object>(
  headerGroups: Array<HeaderGroup<D>>,
  instance: TableInstance<D>
) {
  const { reorderColumns } = instance;

  return headerGroups.map((hg) => {
    return Object.assign(hg, {
      headers: hg.headers.map((header) => {
        return Object.assign(header, {
          replacePosition: (columnId: string) => {
            reorderColumns(columnId, header.id);
          },
        });
      }),
    });
  });
}

const useTableColumnOrder = <D extends object>(hooks: UseTableHooks<D>) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.allColumns.push(columnsWidthMapping);
  //@ts-ignore
  hooks.headerGroups.push(headersPreparation);
};

useTableColumnOrder.pluginName = "useTableColumnOrder";

export default useTableColumnOrder;
