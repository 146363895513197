import {
  conditionalGroup,
  conditionalProperty,
  dependencyType,
  filterGroup,
  group,
  property,
  propertyType,
  systemProperty,
  valuesetSource,
} from "cosmos-config/generator";

const projectProperty = [
  group("Basic Settings", "basic").children([
    property("Label", "label").describe(
      "Set the label of the property how it appears across Cosmos."
    ),
    property("Description", "description")
      .textArea(1000)
      .describe(
        "Set the description of the property to inform users better about how it should be used."
      ),
    property("Column Width")
      .numericAttribute("columnWidth")
      .describe("Set the default with of the property column in Cosmos."),
    // property("Translation Path", "translation"),

    conditionalProperty("Type", "type", 0)
      .hiddenUntil(
        "type",
        propertyType.TEXT,
        propertyType.SUGGEST,
        propertyType.TEXTAREA,
        propertyType.RICH_TEXTAREA
      )
      .selectableAttribute("type", "type", valuesetSource.STATIC_VALUESET)
      .valueset([
        {
          label: "Free Text",
          value: propertyType.TEXT,
        },
        {
          label: "Text Area",
          value: propertyType.TEXTAREA,
        },
        {
          label: "Rich Text Area",
          value: propertyType.RICH_TEXTAREA,
        },
        {
          label: "Suggestive",
          value: propertyType.SUGGEST,
        },
      ]),

    conditionalProperty("Character Limit", "characterLimit", 0)
      .hiddenUntil("type", propertyType.TEXTAREA, propertyType.RICH_TEXTAREA)
      .numericAttribute("characterLimit"),

    conditionalProperty("Multiple Selection", "multiple", 0)
      .hiddenUntil("handleAsMulti", true)
      .describe(
        "Database attribute is multi-attribute that means that it allows to store multiple values. But it is possible to limit it to single value from user perspective. If you set this option to true property will look like regular single attribute."
      )
      .booleanAttribute("multiple")
      .useYesNo(),

    property("Default Sorted")
      .booleanAttribute("defaultSort")
      .describe(
        "Set property to be sorted by default. Make sure that there is not other property set to be sorted by default!"
      )
      .useYesNo(),

    // property("Default Filter Value", "defaultFilterValue"),

    conditionalProperty("Group Names", "groupNames", 0)
      // .selectableMemberAttribute("groupNames", 3)
      .hiddenUntil("type", propertyType.MEMBER_SELECT)
      .multiAttribute("groupNames"),

    conditionalProperty("Neutral", "neutral", 0)
      .hiddenUntil("type", propertyType.YESNO)
      .booleanAttribute("neutral")
      .useYesNo(),

    conditionalProperty("Decimal Places", "decimalPlaces", 0)
      .hiddenUntil("type", propertyType.NUMERIC)
      .numericAttribute("decimalPlaces"),

    conditionalProperty("Invalidating Options", "invalidatingOptions", 0)
      .hiddenUntil("valuesetSource", valuesetSource.CUSTOM_VALUESET)
      .booleanAttribute("invalidatingOptions")
      .useYesNo(),

    conditionalProperty("Dependency Type", "dependencyType", 0)
      .hiddenWhile("dependency", null)
      .selectableAttribute(
        "dependencyType",
        "dependencyType",
        valuesetSource.STATIC_VALUESET
      )
      .valueset([
        {
          label: "Loose",
          value: dependencyType.LOOSE,
        },
        {
          label: "Strict",
          value: dependencyType.STRICT,
        },
      ])
      .describe(
        "Dependency type is strict by default. That means that you have to select main property and the dependency list is shown afterwards. With loose dependency type user is able to select from a list even if main property is not selected yet."
      ),

    conditionalProperty("Contains Username", "containsUsername", 0)
      .hiddenUntil("type", propertyType.TEXT)
      .booleanAttribute("containsUsername")
      .useYesNo()
      .describe(
        "If selected yes there is formatting function applied which erase UI/R number from the value."
      ),

    property("Task History").booleanAttribute("taskHistory").useYesNo(),

    conditionalProperty("Use Fuzzy Search", "useFuzzySearch", 0)
    .hiddenUntil("type", propertyType.TEXT, propertyType.SUGGEST, propertyType.SELECT)
    .booleanAttribute("fuzzySearch")
    .useYesNo(),

    property("Include Import Template")
      .booleanAttribute("importable")
      .useYesNo()
      .describe("Set if property should be used within import templates."),
  ]),

  conditionalGroup("Date Settings", "date")
    .hiddenUntil("type", propertyType.DATE)
    .children([
      property("Date Only").booleanAttribute("dateOnly").useYesNo(),
      property("Date Range Filter").booleanAttribute("filterRange").useYesNo(),
      property("Reminder Creator")
        .booleanAttribute("reminderCreator")
        .useYesNo(),
    ]),
  group("Filter Settings", "filter").children([
    property("Filter")
      .booleanAttribute("filterable")
      .useYesNo()
      .describe(
        "Set if property should be available in the filter sidebar and additionaly select on which level by filter group settings."
      ),

    conditionalProperty("Filter Group", "filterGroup", 0)
      .hiddenUntil("filterable", true)
      .selectableAttribute(
        "filterGroup",
        "filterGroup",
        valuesetSource.STATIC_VALUESET
      )
      .valueset([
        {
          label: "Home",
          value: filterGroup.MAIN,
        },
        {
          label: "Home (Additional)",
          value: filterGroup.ADDITIONAL,
        },
        {
          label: "Folder",
          value: filterGroup.FOLDER,
        },
        {
          label: "Folder (Additional)",
          value: filterGroup.FOLDER_ADDITIONAL,
        },
        {
          label: "Home & Folder",
          value: filterGroup.MAIN_FOLDER,
        },
        {
          label: "Home & Folder (Additional)",
          value: filterGroup.ADDITIONAL_FOLDER_ADDITIONAL,
        },
      ]),
  ]),
  group("System Properties", "system_props")
    .excludeForm()
    .children([
      systemProperty("Label Left", "labelLeft", true),
      systemProperty("Label Right", "labelRight", true),
      systemProperty("Transaltion Path", "translation", true),
      systemProperty("Default Value", "defaultValue", true),
      systemProperty("Dependency", "dependency", true),
      systemProperty("Valueset Source", "valuesetSource", true),
    ]),
];

export default projectProperty;
