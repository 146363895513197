import { useEffect, useMemo, useReducer, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Row,
  Col,
  FormLabel,
  Card,
  Tab,
  Nav,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faFileExport,
  faFileImport,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Fuse from "fuse.js";
import { PancoSelect, SearchAndReplace } from "cosmos-components";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ValuesetTable from "./ValuesetTable";
import valuesetItem from "../../contants/valuesetItem";
import { propertyType, valuesetSource } from "cosmos-config/generator";
import useDocArea from "../../hooks/useDocArea";
import useProperties from "../../hooks/useProperties";
import useValueset from "../../hooks/useValueset";
import useRepositorySearchDistinct from "../../hooks/useRepositorySearchDistinct";
import useProject from "../../hooks/useProject";
import _ from "lodash";
import ValuesetIssues from "./ValuesetIssues";
import BulkEditValuesetTable from "./BulkEditValuesetTable";
import { updateCustomValuesetItems } from "../../actions/docareaActions";
import useCoreDispatch from "../../hooks/useCoreDispatch";
import usePropertyRender from "../../hooks/usePropertyRender";
import useCurrentUser from "../../hooks/useCurrentUser";

const defaultPageOption = { label: "20 items per page", value: 20 };
const pageOptions = [
  defaultPageOption,
  { label: "50 items per page", value: 50 },
  { label: "100 items per page", value: 100 },
  { label: "200 items per page", value: 200 },
];

const actions = {
  SET_SELECTED_VALUESET_NAME: "SET_SELECTED_VALUESET_NAME",
  SET_FILES: "SET_FILES",
  SET_UPDATED_VALUESETS: "SET_UPDATED_VALUESETS",
  TOGGLE_BULK_EDIT_MODE: "TOGGLE_BULK_EDIT_MODE",
  SET_SEARCH_VALUE: "SET_SEARCH_VALUE",
  SET_SEARCH_CONFIGURATION: "SET_SEARCH_CONFIGURATION",
  SET_TABLE_OPTIONS: "SET_TABLE_OPTIONS",
};

const initialState = {
  selectedValuesetName: null,
  files: [],
  bulkEditMode: false,
  updatedValuesets: [],
  searchValue: "",
  searchConfiguration: {
    matchCase: false,
  },
  tableOptions: {
    currentPageIndex: 0,
    currentPageSize: defaultPageOption.value,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_SELECTED_VALUESET_NAME:
      return {
        ...state,
        selectedValuesetName: action.valuesetName,
      };
    case actions.SET_FILES:
      return {
        ...state,
        files: action.files,
      };
    case actions.SET_UPDATED_VALUESETS:
      return {
        ...state,
        updatedValuesets: action.updatedValuesets,
      };
    case actions.TOGGLE_BULK_EDIT_MODE:
      return {
        ...state,
        bulkEditMode: !state.bulkEditMode,
      };
    case actions.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.searchValue,
      };
    case actions.SET_SEARCH_CONFIGURATION:
      return {
        ...state,
        searchConfiguration: action.searchConfiguration,
      };
    case actions.SET_TABLE_OPTIONS:
      return {
        ...state,
        tableOptions: {
          ...state.tableOptions,
          ...action.tableOptions,
        },
      };
    default:
      return state;
  }
};

const ValuesetEditor = () => {
  const { t } = useTranslation("module");
  const [localState, localDispatch] = useReducer(reducer, initialState);
  const { valuesets, importCustomValuesetFile, exportValuesetFile } =
    useDocArea();

  const dispatch = useCoreDispatch();
  const { editValuesetItem, addValuesetItem } = useValueset();
  const { properties } = useProperties();
  const { project } = useProject();
  const { systemAdmin } = useCurrentUser();

  const customValuesetProperties = useMemo(() => {
    return properties
      .filter(
        (attr) =>
          [propertyType.SELECT, propertyType.RADIO].includes(attr.type) &&
          attr.valuesetSource === valuesetSource.CUSTOM_VALUESET
      )
      .sort((a, b) => String(a.label).localeCompare(b.label));
  }, [properties]);

  const customValuesets = useMemo(() => {
    return customValuesetProperties
      .map(({ valuesetName }) => {
        return valuesets.find((vs) => vs.name === valuesetName);
      })
      .filter((x) => x != null);
  }, [customValuesetProperties, valuesets]);

  useEffect(() => {
    if (customValuesets.length > 0 && localState.selectedValuesetName == null) {
      const valuesetName = customValuesets.sort((a, b) =>
        String(a.label).localeCompare(b.label)
      )[0].name;
      localDispatch({ type: actions.SET_SELECTED_VALUESET_NAME, valuesetName });
    }
  }, [customValuesets, localState.selectedValuesetName]);

  const selectedValueset = useMemo(() => {
    return customValuesets.find(
      (cvs) => cvs.name === localState.selectedValuesetName
    );
  }, [customValuesets, localState.selectedValuesetName]);

  const selectedValuesetProperty = useMemo(() => {
    return customValuesetProperties.find(
      (cvp) => cvp.valuesetName === localState.selectedValuesetName
    );
  }, [customValuesetProperties, localState.selectedValuesetName]);

  const valuesetItemProperties = useMemo(() => {
    return valuesetItem.filter(
      (itemProperty) =>
        itemProperty.name !== "obsolete" ||
        selectedValuesetProperty?.invalidatingOptions
    );
  }, [selectedValuesetProperty]);

  const renderProperty = usePropertyRender(valuesetItemProperties);

  const valuesetsMap = useMemo(
    () => _(customValuesets).keyBy("name").mapValues("data").value(),
    [customValuesets]
  );

  const { data, loading, refetch } = useRepositorySearchDistinct({
    resourceId: project?.resourceId,
    propertyName: selectedValuesetProperty?.name,
    enabled: project != null && selectedValueset?.name != null,
    subtree: true,
    substructure: true,
  });

  const issues = useMemo(() => {
    const values = _.map(valuesetsMap[selectedValueset?.name], "value");
    return data
      .filter((item) => item.value != null && !values.includes(item.value))
      .map((issue) => ({
        ...issue,
        resourceType: selectedValuesetProperty?.folderProperty != null ? 1 : 2,
        folderType: selectedValuesetProperty?.folderType,
      }));
  }, [data, selectedValueset?.name, valuesetsMap, selectedValuesetProperty]);

  const handleFileImport = (e) => {
    e.preventDefault();
    importCustomValuesetFile(
      localState.selectedValuesetName,
      localState.files,
      false,
      () => {
        // fileImportRef.current.value = null;
      }
    );
  };

  const handleFileExport = () => {
    const columns = valuesetItemProperties.filter(
      (prop) => !prop.system && prop.tableColumn
    );
    exportValuesetFile({
      valuesetName: localState.selectedValuesetName,
      exportingProps: columns,
    });
  };

  const renderIssuesBadge = () => {
    if (loading) {
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="xs"
          className="ml-2 text-dark"
        />
      );
    }

    if (issues.length > 0) {
      return (
        <Badge pill variant="danger" className="ml-1">
          {issues.length}
        </Badge>
      );
    }

    return "";
  };

  const usedValuesMap = useMemo(
    () => _(data).keyBy("value").mapValues("count").value(),
    [data]
  );

  const valuesetEntries = useMemo(() => {
    return _.map(selectedValueset?.data, (entry) => {
      return { ...entry, usedCount: usedValuesMap[entry.value] || 0 };
    });
  }, [usedValuesMap, selectedValueset?.data]);

  const valuesetEntriesFilterResults = useMemo(() => {
    const fuse = new Fuse(valuesetEntries, {
      keys: _.valuesIn(valuesetItemProperties)
        .filter((prop) => prop.type === "text")
        .map((prop) => prop.name),
      includeMatches: true,
      threshold: 0.3,
    });

    return fuse.search(localState.searchValue?.trim() || "");
  }, [localState.searchValue, valuesetEntries, valuesetItemProperties]);

  const filteredValuesetEntries = useMemo(() => {
    if (!localState.searchValue?.trim().length) {
      return valuesetEntries;
    }

    return valuesetEntriesFilterResults.map((result) => result.item);
  }, [valuesetEntriesFilterResults, valuesetEntries, localState.searchValue]);

  useEffect(() => {
    if (valuesetEntries) {
      localDispatch({
        type: actions.SET_UPDATED_VALUESETS,
        updatedValuesets: [...valuesetEntries],
      });
    }
  }, [valuesetEntries]);

  const currentPageEntries = useMemo(() => {
    const startIndex =
      localState.tableOptions.currentPageIndex *
      localState.tableOptions.currentPageSize;
    const endIndex = startIndex + localState.tableOptions.currentPageSize;
    return filteredValuesetEntries.slice(startIndex, endIndex);
  }, [
    filteredValuesetEntries,
    localState.tableOptions.currentPageSize,
    localState.tableOptions.currentPageIndex,
  ]);

  const handleChangeEntry = (entryId, attributeId, newValue) => {
    const updatedValuesets = localState.updatedValuesets.map((entry) => {
      if (entry.id === entryId) {
        return { ...entry, [attributeId]: newValue, changed: true };
      }

      return entry;
    });

    localDispatch({ type: actions.SET_UPDATED_VALUESETS, updatedValuesets });
  };

  const handleChangeSelection = (attributeId, selectedIds, newValue) => {
    const updatedValuesets = localState.updatedValuesets.map((entry) => {
      if (selectedIds.includes(entry.id)) {
        return {
          ...entry,
          [attributeId]: Array.isArray(entry[attributeId])
            ? Array.from(new Set([...entry[attributeId], ...newValue]))
            : newValue,
          changed: true,
        };
      }

      return entry;
    });

    localDispatch({ type: actions.SET_UPDATED_VALUESETS, updatedValuesets });
  };

  const handleUpdateValuesets = () => {
    localDispatch({ type: actions.TOGGLE_BULK_EDIT_MODE });
    const changedEntries = localState.updatedValuesets
      .map((entry) => ({
        ...entry,
        label: entry.label?.trim() ?? null,
        keywords: Array.isArray(entry.keywords)
          ? [...entry.keywords].map((keyword) => keyword.trim())
          : [],
      }))
      .filter((entry) => {
        if (entry.changed) {
          delete entry.changed;
          return true;
        }
        return false;
      });
    dispatch(
      updateCustomValuesetItems({
        valuesetName: selectedValuesetProperty.valuesetName,
        valuesetItems: changedEntries,
      })
    );
  };

  const handleUpdateSearch = (newSearchValue) => {
    localDispatch({
      type: actions.SET_SEARCH_VALUE,
      searchValue: newSearchValue,
    });
    setMarker(0);
  };

  const generateCellId = (rowId, columnId) => {
    return `cell-${rowId}-${columnId}`;
  };

  const matchingCellIds = useMemo(() => {
    const newMatchingCellIds = [];

    currentPageEntries.forEach((entry) => {
      _.valuesIn(valuesetItemProperties).forEach((property) => {
        if (localState.searchValue?.trim().length > 0) {
          const cellId = generateCellId(entry.id, property.name);

          // check if valuesetEntriesFilterResults contains the entry
          const cellMatched = valuesetEntriesFilterResults.some((result) => {
            return (
              result.item.id === entry.id &&
              result.matches.some((match) => {
                return match.key === property.name;
              })
            );
          });

          if (cellMatched) {
            newMatchingCellIds.push(cellId);
          }
        }
      });
    });

    return newMatchingCellIds;
  }, [
    localState.searchValue,
    valuesetEntriesFilterResults,
    valuesetItemProperties,
    currentPageEntries,
  ]);

  const [marker, setMarker] = useState(0);
  const focusNextSearchableCell = (reverse) => {
    if (reverse) {
      setMarker(
        (prevIndex) =>
          (prevIndex - 1 + matchingCellIds.length) % matchingCellIds.length
      );
      return;
    }

    setMarker((prevIndex) => (prevIndex + 1) % matchingCellIds.length);
  };

  const handleChangeCurrentPageIndex = (newPageIndex) => {
    localDispatch({
      type: actions.SET_TABLE_OPTIONS,
      tableOptions: { currentPageIndex: newPageIndex },
    });
  };

  const renderTableCell = (propertyName, label, entry) => {
    const cellId = generateCellId(entry.id, propertyName);

    return (
      <span
        id={cellId}
        className={classNames({
          "text-muted": entry?.usedCount <= 0,
          "bg-success": matchingCellIds.find((cId) => cId === cellId),
          "bg-warning": cellId === matchingCellIds[marker],
        })}
      >
        {label}
      </span>
    );
  };

  return (
    <div>
      <Card className="mb-3 border-dark">
        <Card.Body>
          <h6>Select a Valueset to Edit</h6>
          <FormGroup as={Row}>
            <FormLabel column sm={2}>
              Valueset
            </FormLabel>
            <Col sm={4}>
              <PancoSelect
                options={customValuesetProperties.map((cvp) => ({
                  label: cvp.label,
                  value: cvp.valuesetName,
                }))}
                onChange={({ value }) => {
                  localDispatch({
                    type: actions.SET_SELECTED_VALUESET_NAME,
                    valuesetName: value[0],
                  });
                }}
                value={[localState.selectedValuesetName]}
              />
            </Col>
          </FormGroup>
        </Card.Body>
        <Card.Footer className="d-flex">
          <Button
            onClick={() => {
              addValuesetItem({
                propertyName: selectedValuesetProperty.name,
                advanced: true,
              });
            }}
            className="mr-2"
            variant="link"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Valueset Entry
          </Button>

          <div className="ml-auto d-flex align-items-center">
            <Button onClick={handleFileExport} className="mr-2" variant="link">
              <FontAwesomeIcon icon={faFileExport} className="mr-2" />
              Export (Excel)
            </Button>

            {(selectedValuesetProperty?.masterValuesetName == null ||
              systemAdmin) && (
              <>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="mr-3 text-dark"
                />

                <Form onSubmit={handleFileImport} inline>
                  <Form.File
                    multiple={false}
                    onChange={(e) => {
                      localDispatch({
                        type: actions.SET_FILES,
                        files: e.target.files,
                      });
                    }}
                  />

                  <Button
                    type="submit"
                    disabled={localState.files.length !== 1}
                    variant="link"
                  >
                    <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                    Import (Excel)
                  </Button>
                </Form>
              </>
            )}
          </div>
        </Card.Footer>
      </Card>

      <Tab.Container defaultActiveKey="data">
        <Nav variant="tabs" className="mb-3">
          <Nav.Item>
            <Nav.Link eventKey="data">Data</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="issues">
              Issues
              {renderIssuesBadge()}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="data">
            {selectedValueset != null && (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center mr-auto">
                    <Form.Check
                      type="switch"
                      id="toggle-valueset-bulk-edit"
                      label={t("common.edit_all", { defaultValue: "Edit All" })}
                      checked={localState.bulkEditMode}
                      onChange={() =>
                        localDispatch({ type: actions.TOGGLE_BULK_EDIT_MODE })
                      }
                      className="mr-3"
                    />
                    {localState.bulkEditMode && (
                      <Button
                        onClick={() => handleUpdateValuesets()}
                        disabled={
                          !localState.updatedValuesets.filter(
                            (entry) => entry.changed
                          ).length
                        }
                      >
                        {t("button.save")}
                      </Button>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <SearchAndReplace
                      replace={false}
                      searchValue={localState.searchValue}
                      onSearch={(v) => handleUpdateSearch(v)}
                      onFind={(reverse) => focusNextSearchableCell(reverse)}
                      matches={matchingCellIds.length}
                      currentMatch={matchingCellIds.length ? marker + 1 : 0}
                      options={localState.searchConfiguration}
                      onUpdateOptions={(updatedOptions) => {
                        localDispatch({
                          type: actions.SET_SEARCH_CONFIGURATION,
                          searchConfiguration: updatedOptions,
                        });
                      }}
                      disabled={localState.bulkEditMode}
                    />
                    <PancoSelect
                      boxed
                      options={pageOptions}
                      value={[localState.tableOptions.currentPageSize]}
                      onChange={(item) => {
                        localDispatch({
                          type: actions.SET_TABLE_OPTIONS,
                          tableOptions: { currentPageSize: item.value },
                        });
                      }}
                    />
                  </div>
                </div>
                {localState.bulkEditMode ? (
                  <BulkEditValuesetTable
                    entries={localState.updatedValuesets}
                    onChangeEntry={(entryId, attributeId, newValue) =>
                      handleChangeEntry(entryId, attributeId, newValue)
                    }
                    onChangeSelection={(attributeId, selectedIds, newValue) =>
                      handleChangeSelection(attributeId, selectedIds, newValue)
                    }
                    property={selectedValuesetProperty}
                    propertiesMap={valuesetItemProperties}
                    pageSize={localState.tableOptions.currentPageSize}
                    onPageChanged={handleChangeCurrentPageIndex}
                    renderCell={(propertyName, label, entry) =>
                      renderTableCell(propertyName, label, entry)
                    }
                  />
                ) : (
                  <ValuesetTable
                    entries={filteredValuesetEntries}
                    valuesetName={localState.selectedValuesetName}
                    propertiesMap={valuesetItemProperties}
                    pageSize={localState.tableOptions.currentPageSize}
                    onPageChanged={handleChangeCurrentPageIndex}
                    onEdit={(valuesetEntry) => {
                      editValuesetItem({
                        propertyName: selectedValuesetProperty.name,
                        valuesetEntry,
                        advanced: true,
                      });
                    }}
                    renderCell={(propertyName, value, label, entry) =>
                      renderTableCell(propertyName, label, entry)
                    }
                  />
                )}
              </>
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="issues">
            <ValuesetIssues
              issues={issues}
              propertyName={selectedValuesetProperty?.name}
              onResolve={() => {
                refetch();
              }}
              loading={loading}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default ValuesetEditor;
