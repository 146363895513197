import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, FormCheck, FormControl} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronRight,
    faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

const SearchAndReplace = ({
  searchValue,
  onSearch,
  onFind,
  onReplace,
  onReplaceAll,
  matches,
  currentMatch,
  options,
  onUpdateOptions,
  replace,
  disabled,
  replaceDisabled,
  replaceAllDisabled,
  findDisabled,
}) => {
    const {t} = useTranslation('module');

    const [sAndROpen, setSAndROpen] = useState(false);
    const [replaceValue, setReplaceValue] = useState('');
    const searchRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.ctrlKey && (event.key === 'f' || event.key === 'g')) {
            event.preventDefault();
            searchRef.current.focus();
        }

        if (event.ctrlKey && event.key === 'h' && !sAndROpen) {
            event.preventDefault();
            setSAndROpen(true);
            searchRef.current.focus();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleSearchKeyUp = (event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            onFind(false);
        }
    };

    const handleReplaceKeyUp = (event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            onReplace(replaceValue);
        }
    };

    return (
        <div className="position-relative h-100 mr-3">
            <div className="h-100 d-flex align-items-center border">
                <Button
                    data-testid="search-and-replace-ui-toggle"
                    className="h-100 border-right shadow-none"
                    onClick={() => setSAndROpen((prev) => !prev)}
                >
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{
                            transition: 'transform 0.25s ease-in-out',
                            transform: sAndROpen ? 'rotate(90deg)' : 'initial',
                        }}
                    />
                </Button>
                <FormControl
                    data-testid="search-and-replace-search-input"
                    className="shadow-none border-0 pr-0"
                    ref={searchRef}
                    value={searchValue}
                    onChange={(e) => onSearch(e.target.value)}
                    onKeyUp={(e) => handleSearchKeyUp(e)}
                    placeholder={t('common.search', {defaultValue: 'Search'})}
                    disabled={disabled}
                />
                <span
                    data-testid="search-and-replace-matches-indicator"
                    className="text-secondary px-3 border-right"
                >
                    {`${currentMatch}/${matches}`}
                </span>
                <span className="d-flex align-items-center">
          <Button
              data-testid="search-and-replace-find-previous-btn"
              variant="light"
              className="shadow-none border-0 px-1 py-0 ml-3 rounded"
              disabled={!searchValue?.trim().length || matches === 0}
              onClick={() => onFind(true)}
          >
            <FontAwesomeIcon icon={faChevronUp}/>
          </Button>
          <Button
              data-testid="search-and-replace-find-next-btn"
              variant="light"
              className="shadow-none border-0 px-1 py-0 mx-3 rounded"
              disabled={!searchValue?.trim().length || matches === 0}
              onClick={() => onFind(false)}
          >
            <FontAwesomeIcon icon={faChevronDown}/>
          </Button>
        </span>
            </div>
            <div
                className={`position-absolute h-100 w-100 bg-white ${
                    sAndROpen ? 'd-block' : 'd-none'
                }`}
                style={{
                    top: '100%',
                    left: 0,
                    zIndex: 1000,
                }}
            >
                {replace && <div className="h-100 d-flex align-items-center border border-top-0">
                    {/*Placeholder*/}
                    <Button
                        className="h-100 bg-white border-right shadow-none"
                        style={{cursor: 'default'}}
                        tabIndex={-1}
                    >
                        <FontAwesomeIcon icon={faChevronRight} className="invisible"/>
                    </Button>
                    {/*End Placeholder*/}
                    <FormControl
                        data-testid="search-and-replace-replace-input"
                        className="shadow-none border-0"
                        value={replaceValue}
                        onChange={(e) => setReplaceValue(e.target.value)}
                        onKeyUp={(e) => handleReplaceKeyUp(e)}
                        placeholder={t('common.replace', {defaultValue: 'Replace'})}
                        tabIndex={sAndROpen ? 0 : -1}
                        disabled={disabled}
                    />
                </div>}
                <div
                    className="d-flex flex-column p-3 bg-white shadow border border-top-0"
                    style={{gap: '1rem'}} // Utility class exists in newer bootstrap 5.x - we are using 4.x
                >
                    <div
                        className="d-flex flex-column"
                        style={{gap: '0.5rem'}} // Utility class exists in newer bootstrap 5.x - we are using 4.x
                    >
                        <FormCheck
                            type="checkbox"
                            id="sandr-capitalize-check"
                            label="Match Case"
                            checked={options?.matchCase}
                            onChange={() =>
                                onUpdateOptions({matchCase: !options?.matchCase})
                            }
                        />
                    </div>
                    <div
                        className="d-flex align-items-center flex-wrap"
                        style={{gap: '0.5rem'}} // Utility class exists in newer bootstrap 5.x - we are using 4.x
                    >
                        <Button
                            data-testid="search-and-replace-find-btn"
                            variant="light"
                            className="mr-auto"
                            onClick={() => onFind(false)}
                            disabled={
                                !searchValue?.trim().length || matches === 0 || !sAndROpen || findDisabled
                            }
                        >
                            {t('common.find', {defaultValue: 'Find'})}
                        </Button>
                        {replace && <div
                            className="d-flex align-items-center flex-wrap"
                            style={{gap: '0.5rem'}} // Utility class exists in newer bootstrap 5.x - we are using 4.x
                        >
                            <Button
                                data-testid="search-and-replace-replace-btn"
                                variant="light"
                                className="mr-2"
                                onClick={() => onReplace(replaceValue)}
                                disabled={
                                    !searchValue?.trim().length || matches === 0 || !sAndROpen || replaceDisabled
                                }
                            >
                                {t('common.replace', {defaultValue: 'Replace'})}
                            </Button>
                            <Button
                                data-testid="search-and-replace-replace-all-btn"
                                variant="light"
                                onClick={() => onReplaceAll(replaceValue)}
                                disabled={
                                    !searchValue?.trim().length || matches === 0 || !sAndROpen || replaceAllDisabled
                                }
                            >
                                {t('placeholder.replace_all', {defaultValue: 'Replace All'})}
                            </Button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

SearchAndReplace.propTypes = {
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
    onFind: PropTypes.func,
    onReplace: PropTypes.func,
    onReplaceAll: PropTypes.func,
    matches: PropTypes.number,
    currentMatch: PropTypes.number,
    options: PropTypes.shape({matchCase: PropTypes.bool}),
    onUpdateOptions: PropTypes.func,
    replace: PropTypes.bool,
    disabled: PropTypes.bool,
    replaceDisabled: PropTypes.bool,
    replaceAllDisabled: PropTypes.bool,
    findDisabled: PropTypes.bool,
};

SearchAndReplace.defaultProps = {
    searchValue: '',
    onSearch: (v) => {
    },
    onFind: (reverse) => {
    },
    onReplace: (newContent) => {
    },
    onReplaceAll: (newContent) => {
    },
    matches: 0,
    currentMatch: 0,
    options: {matchCase: false},
    onUpdateOptions: (newOptions) => {
    },
    replace: true,
    disabled: false,
    replaceDisabled: false,
    replaceAllDisabled: false,
    findDisabled: false,
};

export default SearchAndReplace;
