import { PancoSelect } from "cosmos-components";
import React, { useMemo } from "react";
import useProperties from "../../../../hooks/useProperties";
import { propertyType } from "cosmos-config/generator";
import _ from "lodash";

export interface PropertyDependencyProps {
  dependency?: string;
  onChange?: (param: { dependency?: string; dependencyLabel?: string }) => void;
}

const PropertyDependency = ({
  dependency,
  onChange,
}: PropertyDependencyProps) => {
  const { properties, propertiesMap } = useProperties();

  const propertiesOptions = useMemo(
    () =>
      properties
        .filter((p) => p.type === propertyType.SELECT)
        .map((p) => ({
          value: p.name,
          label: `${p.label} (${p.name})`,
        })),
    [properties]
  );

  return (
    <div>
      <PancoSelect
        options={propertiesOptions}
        value={_.compact([dependency])}
        clearable
        onChange={({ value }) => {
          if (onChange != null) {
            if (value == null) {
              onChange({});
            } else {
              const [v] = value;
              const property = propertiesMap[v];

              onChange({
                dependency: property?.name,
                dependencyLabel: property?.label,
              });
            }
          }
        }}
      />
    </div>
  );
};

export default PropertyDependency;
