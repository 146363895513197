import * as actions from '../Actions/types';

const initialState = {
  processes: [],
  processDefinitions: [],
  loading: false,
  process: null,
  preload: null,
  openedProcessId: null,
  nextProcessId: null,
  taskLoading: false,
  tasks: [],
  taskHistory: [],
  processDefinition: null,
  workflowStatus: [],
};

const workflow = (state = initialState, action) => {
  switch (action.type) {
    case actions.workflow.SET_PROCESS_PRELOAD:
      return {
        ...state,
        preload: action.preload,
      };
    case actions.workflow.SET_PROCESSES:
      return {
        ...state,
        processes: action.processes,
      };
    case actions.workflow.SET_PROCESS_DEFINITIONS:
      return {
        ...state,
        processDefinitions: action.processDefinitions,
      };
    case actions.workflow.SET_WORKFLOW_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.workflow.SET_WORKFLOW_PROCESS:
      return {
        ...state,
        process: action.process,
        processDefinition: action.process.processdefinition,
      };
    case actions.workflow.SET_TASK_LOADING:
      return {
        ...state,
        taskLoading: action.loading,
      };
    case actions.filterFolder.CLEAR_STORES:
      return {
        ...initialState,
      };
    case actions.workflow.CLOSE_PROCESS:
      // case actions.workflow.RELOAD_PROCESS:
      return {
        ...initialState,
        preload: state.preload,
        processes: state.processes,
        processDefinitions: state.processDefinitions,
        processDefinition: state.processDefinition,
      };
    case actions.workflow.SET_PROCESS_TASKS:
      return {
        ...state,
        tasks: action.tasks,
      };
    case actions.workflow.UPDATE_PROCESS_TASK:
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (task.id === action.taskId) {
            return {
              ...task,
              [action.propertyName]: action.value,
            };
          }

          return task;
        }),
      };
    case actions.workflow.UPDATE_ALL_PROCESS_TASKS:
      return {
        ...state,
        tasks: state.tasks.map((task) => ({
          ...task,
          [action.propertyName]: action.value,
        })),
      };
    case actions.workflow.SET_TASK_HISTORY:
      return {
        ...state,
        taskHistory: action.taskHistory,
      };
    case actions.workflow.SET_OPENED_PROCESS_ID:
      return {
        ...state,
        openedProcessId: action.openedProcessId,
      };
    case actions.workflow.SET_NEXT_PROCESS_ID:
      return {
        ...state,
        nextProcessId: action.processId,
      };
    case actions.workflow.SET_WORKFLOW_STATUS:
      return {
        ...state,
        workflowStatus: action.payload.status,
      };
    default:
      return state;
  }
};

export default workflow;
