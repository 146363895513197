import { useMemo } from "react";
import useProject from "./useProject";
import _ from "lodash";

export interface UseProjectMemberProps {
  principalId: string;
}

const useProjectMember = ({ principalId }: UseProjectMemberProps) => {
  const { members } = useProject();

  const user = useMemo(() => {
    return _(members)
      .valuesIn()
      .flatMap()
      .find((u) => u.principalId === principalId);
  }, [members, principalId]);

  return {
    name: user?.formattedName,
    email: user?.email,
  };
};

export default useProjectMember;
