import { useContext, useEffect, useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { uuidv4 } from "cosmos-config/utils";

import { WizardContext } from "./Wizard";

export const WizardStep = ({ children, id, title, className, order }) => {
  const { navigate, registerStep, unregisterStep, isActive, restart } =
    useContext(WizardContext);

  const uuid = useMemo(() => (id != null ? String(id) : uuidv4()), [id]);

  useEffect(() => {
    registerStep(uuid, title, order);

    return () => unregisterStep(uuid);
  }, [registerStep, unregisterStep, uuid, title, order]);

  const active = useMemo(() => isActive(uuid), [uuid, isActive]);

  if (active) {
    return (
      <div
        className={classNames("wizard-step-content", className)}
        id={`wizard_step_${uuid}`}
      >
        {typeof children === "function"
          ? children({
            proceed: (payload) => {
              navigate(1, payload);
            },
            revert: () => {
              navigate(-1);
            },
            restart,
          })
          : children}
      </div>
    );
  }

  return null;
};

WizardStep.propTypes = {
  children: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  className: PropTypes.string,
  order: PropTypes.number,
};

WizardStep.defaultProps = {
  children: () => { },
  id: null,
  title: null,
  className: null,
  order: -1,
};