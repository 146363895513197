/* eslint-disable func-names */
// eslint func-names: ["error", "always", { "generators": "as-needed" }]

import {
  put,
  call,
  takeLatest,
  select,
  takeEvery,
  take,
  fork,
  all,
  cancel,
  spawn,
  takeLeading,
  getContext,
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import {
  attachLinkedDocuments,
  requestDocuments,
  selectDocuments,
  setBulkUpdateProgress,
  setRepositoryLoading,
  setSelectedDocuments,
  setValidationErrors,
  toggleValidationError,
  setThumbnail,
  highlightDocs,
} from '../../Actions/repository';
import {
  CHECK_OUT_DOCUMENT,
  CHECK_IN_DOCUMENT,
  DOWNLOAD_DOCUMENT,
} from '../../Actions/types/document';
import {
  DELETE_DOCUMENTS,
  CLEAR_SELECTION,
  LINK_DOCUMENTS,
  UNLINK_DOCUMENTS,
  FETCH_LINKED_DOCUMENTS,
  EXPORT_DOCUMENTS_EXCEL,
  EXPORT_URLS_EXCEL,
  EXPORT_DOCUMENTS_ZIP,
  PRESERVE_SELECTED_DOCUMENTS,
  CALCULATE_PRESET,
  UPDATE_PRESET,
  UPDATE_SELECTED_DOCUMENT,
  UNDELETE_DOCUMENTS,
  FETCH_SUBFOLDER_DOCUMENTS,
  VIRTUAL_MOVE_DOCUMENTS,
  CREATE_DOCUMENT_REFERENCE,
  FETCH_CHILDREN_DOCUMENTS,
  DELETE_VERSION,
  UNLOCK_DOCUMENTS,
} from '../../Actions/types/repository';
import {
  parseGatewayResources,
  prepareResourceForGateway,
} from '../../Utils/documentUtils';
import {
  validateResources,
  getSearchOperation,
  getKeywords,
} from 'cosmos-core';
import repository from '../../Api/repository';
import {
  fetchDocumentVersions,
  reloadDocument,
  setDocument,
  setDocumentPresets,
} from '../../Actions/document';
import callApi from '../Effects/callApi';
import callBatch from '../Effects/callBatch';
import { notify } from '../../Actions/ui';
import { resourceToProperties } from '.';
import resourceApi from '../../Api/resource';
import documentApi from '../../Api/document';
import { exportDocumentsExcel, exportTitleUrlExcel } from './exportExcelSaga';
import _ from 'lodash';
import { toQueryProperties } from '../../Utils/projectUtils';
import { CREATE_DOCUMENT_DUPLICATE } from '../../Actions/types/repository';
import { resourceCorrectionForGateway } from 'cosmos-config/utils';
import complete from '../Effects/complete';
import toggleHideDocuments from './toggleHideDocumentsSaga';
import downloadDocumentsSaga from './downloadDocumentsSaga';
import { addURLtoResources } from './utils';

/**
 * @module saga/documentSelection
 * @category Sagas
 */

/**
 * Toggle resource deleted.
 *
 * @param {string} resourceId - Id of the resource.
 * @param {object} action - Redux action object.
 * @param {function} action.callback - Callback when the operation is done.
 */
function* toggleDeleteResource(resourceId, { callback }) {
  try {
    yield put(setRepositoryLoading(true, 'Deleting documents'));
    yield callApi(resourceApi.deleteResource, resourceId, true);
  } catch (err) {
    console.error(err);
    yield put(notify(err.message, 'error'));
  } finally {
    yield put(setRepositoryLoading(false));
  }

  yield spawn(callback);
}

/**
 * Link documents.
 *
 * @param {Document[]} documents - List of documents to be linked.
 * @param {object} action - Redux action object.
 * @param {function} action.callback - Callback when the operation is done.
 */
function* linkDocuments(action) {
  try {
    yield put(setRepositoryLoading(true, 'Linking selected documents'));

    const { documents } = action.payload;
    yield callApi(
      resourceApi.linkDocuments,
      documents.map((d) => d.id)
    );

    yield put(requestDocuments());

    yield complete(action);
  } catch (err) {
    yield put(notify(`Error while linking documents: ${err.message}`, 'error'));
    console.error(err);
  } finally {
    yield put(setRepositoryLoading(false));
  }
}

/**
 * Unlink documents.
 *
 * @param {Document[]} documents - List of documents to be unlinked.
 * @param {object} action - Redux action object.
 * @param {function} action.callback - Callback when the operation is done.
 */
function* unlinkDocuments(action) {
  try {
    yield put(setRepositoryLoading(true, 'Unlinking selected documents'));

    const { documents } = action.payload;
    yield callApi(
      resourceApi.unlinkDocuments,
      documents.map((d) => d.id)
    );

    yield put(requestDocuments());

    yield complete(action);
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setRepositoryLoading(false));
  }
}

function* unlockDocuments(documents, action) {
  try {
    yield put(setRepositoryLoading(true, 'Unlocking selected documents'));

    const sagas = documents.map((d) =>
      callApi(resourceApi.unlockResource, d.id)
    );
    yield callBatch(10, sagas);

    yield put(requestDocuments());

    yield complete(action);
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setRepositoryLoading(false));
  }
}

/**
 * Retrieve all linked documents to the one provided.
 *
 * @param {Document} document - Document which is part of a group of linked documents.
 * @param {string[]} properties - Which properties to be retrieved for linked documents.
 * @param {object} action - Redux action object.
 * @param {function} action.callback - Callback when the operation is done.
 * @returns Linked documents.
 */
export function* fetchLinkedDocuments(document, properties, action) {
  try {
    yield put(setRepositoryLoading(true, 'Loading linked documents'));

    const requestedProps = toQueryProperties(properties);

    const items = yield callApi(
      repository.getDocumentsByIds,
      // 'RWE$NOTSET$-1$1$NOTSET',
      document.parentresourceid,
      document.LinkedDocs,
      requestedProps,
      true
    );

    const linkedDocuments = parseGatewayResources(items, properties);
    yield put(attachLinkedDocuments(document.id, linkedDocuments));

    yield complete(action, linkedDocuments);
    return linkedDocuments;
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setRepositoryLoading(false));
  }

  return [];
}

/**
 * Retrieve children documents if the current one is selected as main document.
 *
 * @param {Document} document - Main document.
 * @param {string[]} properties - Project properties to fetch of children documents.
 * @param {object} action - Redux action object.
 * @param {function} action.callback - Callback when the operation is done.
 * @returns - List of children documents of the main document.
 */
function* fetchSiblingDocuments(projectCode, document, properties, action) {
  try {
    yield put(
      setRepositoryLoading(true, 'Loading documents of Main document.')
    );

    const requestedProps = toQueryProperties(properties);

    const { propertyName, queryPropertyName, attachToParent } = action.payload;

    const filter = {
      [queryPropertyName || propertyName]: document[propertyName],
    };

    const searchOptions = {
      orderBy: {
        displayname: 'asc',
      },
      operation: getSearchOperation(filter, properties),
    };

    const { items } = yield call(
      repository.searchRepository,
      projectCode,
      document.parentresourceid,
      requestedProps,
      searchOptions
    );

    const documents = parseGatewayResources(items, properties);
    const sortedDocuments = documents.reduce((acc, cur) => {
      if (_.first(cur[propertyName]) === cur.id) {
        return [cur, ...acc];
      }

      return [...acc, cur];
    }, []);

    if (attachToParent) {
      const v = document[propertyName];
      const parentId = Array.isArray(v) ? _.first(v) : v;
      yield put(attachLinkedDocuments(parentId, sortedDocuments));
    }

    yield complete(action, sortedDocuments);

    return sortedDocuments;
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setRepositoryLoading(false));
  }
}

/**
 * Update documents metadata.
 *
 * @param {Document[]} resources - Documents to be updated.
 * @returns List of ids of successfuly updated documents.
 */
export function* updateDocuments(resources) {
  try {
    const projectService = yield getContext('projectService');
    const documentProperties = yield call(projectService.getProperties);
    const folderProperties = yield call(projectService.getFolderProperties);

    const docareaService = yield getContext('docareaService');
    const valuesetsMap = yield select(docareaService.getValuesetsMap);

    const correctedResources = resources.map((resource) => {
      const resourceProperties =
        resource.resourcetype === 1 ? folderProperties : documentProperties;
      const correctedResource = resourceCorrectionForGateway(
        resource,
        resourceProperties
      );

      const editableProperties = resourceProperties
        .filter((p) => (p.editable && p.updatable) || p.systemUpdatable)
        .map((p) => p.name);

      return _.pick(
        {
          ...correctedResource,
          BasicKeywords: getKeywords(
            resource,
            resourceProperties,
            valuesetsMap
          ),
        },
        [...editableProperties, 'id']
      );
    });

    yield callApi(resourceApi.updateResources, correctedResources);

    yield put(notify(`All documents have been saved.`, 'success'));

    return resources.map((r) => r.identifier);
  } catch (err) {
    yield put(
      notify(
        `There were errors while saving documents. ${err.message}`,
        'error'
      )
    );
    console.error(err);
    return [];
  }
}

/**
 * Update documents metadata but making individual request for each one.
 *
 * @deprecated
 * @param {Document[]} documents - List of documents to be updated.
 * @param {function} callback - Callback when the operation is done.
 * @returns List of ids of successfuly updated documents.
 */
function* updateDocumentsIndividually(documents, callback) {
  let updatedIdentifiers = [];

  function* ud(d, properties) {
    try {
      yield callApi(resourceApi.updateResource, d.id, properties);
      updatedIdentifiers = [...updatedIdentifiers, d.identifier];
      yield put(setDocument(documents[0]));
      yield spawn(
        callback,
        ((updatedIdentifiers.length / documents.length) * 100).toFixed(2)
      );
    } catch (err) {
      yield put(
        notify(
          `Update of document ${d.displayname} has failed. Reason: ${err.message}`,
          'error'
        )
      );
    }
  }

  const projectService = yield getContext('projectService');
  const documentProperties = yield call(projectService.getProperties);
  const folderProperties = yield call(projectService.getFolderProperties);

  const docareaService = yield getContext('docareaService');
  const valuesetsMap = yield select(docareaService.getValuesetsMap);

  const requests = documents.map((resource) => {
    const resourceProperties =
      resource.resourcetype === 1 ? folderProperties : documentProperties;

    const properties = resourceToProperties(
      resource,
      resourceProperties,
      valuesetsMap
    );
    return ud(resource, properties);
  });

  yield callBatch(5, requests);

  if (updatedIdentifiers.length) {
    yield put(
      notify(
        `${updatedIdentifiers.length} documents have been saved.`,
        'success'
      )
    );
  }
  return updatedIdentifiers;
}

/**
 * Preserve current state of metadata of documents. Document selection flow provides
 * all selected documents to this function but it could be specified to update only
 * part of them. Validation is running before updating documents metadata which
 * could be disabled by an action.
 *
 * @param {Document[]} documents - All selected documents.
 * @param {object} action - Redux action object.
 * @param {string[]} action.documentIds - List of document ids to be updated.
 * @param {boolean} action.validation - Whether metadata validation should be running
 * before updating documents.
 * @param {function} action.callback - Callback when the operation is done.
 * @returns List of the document ids which were updated successfuly.
 */
function* preserveSelectedDocuments(documents, action) {
  const documentIds = action?.documentIds || [];
  const validation = !!action?.validation;

  let documentsToSave =
    Array.isArray(documentIds) && documentIds.length > 0
      ? documents.filter((d) => documentIds.includes(d.id))
      : documents;

  const projectService = yield getContext('projectService');
  const properties = yield call(projectService.getProperties);

  if (validation) {
    const folderProperties = yield call(projectService.getFolderProperties);

    const validationErrors = _.concat(
      validateResources(
        documentsToSave.filter((r) => r.resourcetype !== 1),
        properties
      ),
      validateResources(
        documentsToSave.filter((r) => ![2, 3].includes(r.resourcetype)),
        folderProperties
      )
    );

    if (validationErrors.length > 0) {
      yield put(
        notify(
          'You have errors in some of your documents. Please check all required attributes and resubmit.',
          'user-error'
        )
      );
      yield put(setValidationErrors(validationErrors));
      return;
    }

    yield put(setValidationErrors([]));
  }

  documentsToSave = documentsToSave.map((d) => ({ ...d, valid: true }));

  const updateProgressChannel = yield channel();
  const updateProgressTask = yield fork(function* (chan) {
    while (true) {
      const action = yield take(chan);
      yield put(action);
    }
  }, updateProgressChannel);

  yield put(setRepositoryLoading(true, 'Updating opened document properties'));

  const updatedIdentifiers = yield call(
    updateDocuments,
    documentsToSave,
    (progress) => {
      updateProgressChannel.put(setBulkUpdateProgress(progress));
    }
  );

  yield put(setRepositoryLoading(false));

  updateProgressTask.cancel();

  if (updatedIdentifiers.length) {
    yield complete(action, updatedIdentifiers);

    if (updatedIdentifiers.length === 1) {
      const updatedSelection = updateDocumentsAsPreset(
        documents,
        properties,
        documentsToSave[0]
      );

      yield put(selectDocuments(updatedSelection));
    } else {
      yield put(setDocumentPresets({}));
    }
  }
}

/**
 * Calculate presets of the documents.
 *
 * @param {Documentp[]} documents - List of all selected documents.
 * @param {object} action - Redux action object.
 * @param {string[]} action.documentIds - List of document ids which should be included.
 */
function* calculatePresets(documents, { documentIds }) {
  const affectedDocuments = documents.filter(
    (d) =>
      !Array.isArray(documentIds) ||
      documentIds.length === 0 ||
      documentIds.includes(d.id)
  );

  const presets = affectedDocuments.reduce(
    (acc, cur) => {
      return _(acc)
        .toPairs()
        .map(([key, value]) => {
          if (Array.isArray(value) && cur[key] != null) {
            if (!_.isEqual(value.sort(), cur[key]?.sort())) {
              return [key, []];
            }
          } else if (value !== cur[key]) {
            return [key, null];
          }

          return [key, value];
        })
        .fromPairs()
        .value();
    },
    { ...affectedDocuments[0] }
  );

  yield put(setDocumentPresets(presets));
}

const updateDocumentsAsPreset = (documents, properties, document) => {
  const presetableProperties = properties
    .filter((p) => p.usePreset)
    .map((p) => p.name);

  const preset = Object.entries(document).reduce((arr, [key, value]) => {
    if (presetableProperties.includes(key)) {
      return { ...arr, [key]: value };
    }

    return arr;
  }, {});

  const newSelectedDocuments = documents.map((d) => ({ ...d, ...preset }));
  // yield put(selectDocuments(newSelectedDocuments));

  return newSelectedDocuments;
};

/**
 * Fetch all document of a project subfolder.
 *
 * @param {string} folderId - Project sub folder resource id.
 * @param {string[]} properties - Project properties that should be fetched.
 * @param {object} action - Redux action object.
 * @param {function} action.callback - Callback when the operation is done.
 * @returns {Document[]} List of children documents of a folder.
 */
function* fetchSubfolderDocuments(folderId, properties, { callback }) {
  try {
    yield put(setRepositoryLoading(true, 'Loading document from subfolder'));

    const requestedProps = toQueryProperties(properties);

    const { items } = yield call(
      repository.searchRepository,
      null,
      folderId,
      requestedProps
    );

    const documents = parseGatewayResources(items, properties);
    yield put(attachLinkedDocuments(folderId, documents));

    if (callback != null) {
      yield spawn(callback, documents);
    }

    return documents;
  } catch (err) {
    console.warn(err);
  } finally {
    yield put(setRepositoryLoading(false));
  }

  return [];
}

/**
 * Function is more about applying changes to resources. Params documents and propeties
 * should be fed into function by saga which take care about documents selection so by
 * default all documents are updated.
 * @param {Document[]} documents - All selected documents.
 * @param {string[]} properties - Project properties.
 * @param {object} action - Redux action object.
 * @param {string|Object} action.propertyName Name of the property which is being updated.
 * Could be also object mask for updating multiple properties at once.
 * @param {string|Array} action.value Value that is being applied into documents. Might
 * be null when updating via propertyName object.
 * @param {string[]} action.documentIds Specifies which document should be updated. Others
 * are untouched or might be empty array to update all selected.
 * @param {boolean} action.updatePreset Set if preset should also be updated. Otherwise
 * only document are.
 */
function* updatePresetProperty(properties, action) {
  const { propertyName, value, documentIds, options, documents } =
    action.payload;
  const { updatePreset, instantSave } = options;

  const presets = yield select((state) => state.document.presets);

  if (updatePreset) {
    if (typeof propertyName === 'string') {
      yield put(
        setDocumentPresets({
          ...presets,
          [propertyName]: value,
        })
      );
    } else {
      yield put(setDocumentPresets({ ...presets, ...propertyName }));
    }
  }

  const skipDocument = (document) => {
    return (
      Array.isArray(documentIds) &&
      documentIds.length > 0 &&
      !documentIds.includes(document.id)
    );
  };

  const propertiesMap = _.keyBy(properties, 'name');
  const docareaService = yield getContext('docareaService');
  const valuesetsMap = yield select(docareaService.getValuesetsMap);

  const dependencyMap = _(properties)
    .filter((prop) => prop.isDependant())
    .keyBy('name')
    .mapValues('dependency')
    .value();

  const applyChange = (document, propName, v) => {
    const depMask = _(dependencyMap)
      .entriesIn()
      .filter(([, masterName]) => masterName === propName)
      .keyBy(([slaveName]) => slaveName)
      .mapValues(() => null)
      .value();

    return _.assign(document, depMask, { [propName]: v });
  };

  const applyChangeIf = (document, propName, v, condition = true) => {
    return condition ? applyChange(document, propName, v) : document;
  };

  const checkAndApply = (document, propName, v) => {
    const applyIf = _.curry(applyChangeIf, 4)(document, propName, v);

    const property = propertiesMap[propName];

    if (property != null && property.isDependant()) {
      const propertyValueset = valuesetsMap[property.valuesetName];

      const documentValue = document[property.dependency];
      const valuesetItem = _(propertyValueset).keyBy('value').get(v);

      if (valuesetItem != null) {
        if (Array.isArray(documentValue)) {
          return applyIf(documentValue.every((v) => v === valuesetItem.filter));
        }

        return applyIf(documentValue === valuesetItem.filter);
      }
    }

    return applyIf(true);
  };

  let updatedDocuments = documents;

  if (typeof propertyName === 'string') {
    updatedDocuments = documents.map((d) => {
      if (skipDocument(d)) {
        return d;
      }

      return checkAndApply(d, propertyName, value);
    });
  } else {
    updatedDocuments = documents.map((d) => {
      if (skipDocument(d)) {
        return d;
      }

      return Object.entries(propertyName).reduce((acc, cur) => {
        const [propName, v] = cur;
        return checkAndApply(acc, propName, v);
      }, d);
    });
  }

  if (instantSave) {
    yield call(preserveSelectedDocuments, updatedDocuments);
    // yield put(requestDocuments());
  } else {
    yield put(selectDocuments(updatedDocuments));
  }

  yield complete(action, updatedDocuments);
}

const getPresetValue = (documents, propertyName, value) => {
  const valuesDistribution = _(documents)
    .flatMap((doc) => {
      const v = doc[propertyName];
      return Array.isArray(v) ? v : [v];
    })
    .uniq()
    .value();

  const isBooleanValue = (d) =>
    d[propertyName] === true || d[propertyName] === false;

  if (
    documents.some(
      (d) =>
        !Array.isArray(d[propertyName]) ||
        d[propertyName].length !== valuesDistribution.length
    )
  ) {
    if (documents.every(isBooleanValue)) {
      if (
        !(valuesDistribution.length === 1 && valuesDistribution[0] === value)
      ) {
        return false;
      }
    } else {
      return null;
    }
  }

  return value;
};

/**
 * Update metadata of the document of selected.
 *
 * @param {Document[]} documents - List of all selected documents.
 * @param {Property[]} properties - List of project propeties.
 * @param {object} action - Redux action object.
 * @param {string} action.documentId - Resource id of a document which is updated.
 * @param {string} action.propertyName - Name of the property which is changed.
 * @param {any} action.value - Value of a property that should be set.
 * @param {boolean} action.validation - Whether validation should be running
 * before setting a new value to the resource.
 */
function* updateSelectedDocument(
  documents,
  properties,
  { documentId, propertyName, value, validation }
) {
  const propertiesMap = _.keyBy(properties, 'name');
  const property = propertiesMap[propertyName];

  if (validation && property?.validate != null) {
    const valid = property.validate(value);
    yield put(toggleValidationError(documentId, propertyName, !valid));
  }

  const selectedDocuments = documents.map((d) => {
    if (d.id === documentId) {
      return {
        ...d,
        [propertyName]: value,
      };
    }

    return d;
  });

  const presets = yield select((state) => state.document.presets);

  const newPresets = {
    ...presets,
    [propertyName]: getPresetValue(selectedDocuments, propertyName, value),
  };

  yield put(setDocumentPresets(newPresets));
  yield put(selectDocuments(selectedDocuments));
}

/**
 * Update document metadata so that in the virtual folder structure
 * it looks like that it was moved. Virtual folder structure is
 * based on the document metadata.
 *
 * @param {Document[]} documents - Documents that are moved.
 * @param {object} action - Redux action object.
 * @param {object} action.filter - New mask that should be applied
 * to the document metadata.
 * @param {function} action.callback - Callback when the operation is done.
 */
function* virtualMoveDocuments(documents, { filter, callback }) {
  const documentIds = documents.map((d) => d.id);
  const movedDocuments = documents.map((d) => {
    return {
      ...d,
      ...Object.entries(filter).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value != null ? [value] : [],
        }),
        {}
      ),
    };
  });

  yield call(updateDocuments, movedDocuments);
  yield put(requestDocuments());
  yield put(highlightDocs(documentIds));
  yield spawn(callback);
}

/**
 * Check out document for editing.
 *
 * @param {Document[]} documents - Selected documents (only first is taken).
 * @param {object} action - Redux action object.
 * @param {function} action.callback - Callback when the operation is done.
 */
function* checkOutDocument(documents, { callback }) {
  const [document] = documents;

  try {
    yield put(setRepositoryLoading(true, 'Checking out document.'));

    const { data, contentType } = yield callApi(
      documentApi.checkOutDocument,
      document.id
    );

    const blob = new Blob([data], { type: contentType });

    const objectUrl = URL.createObjectURL(blob);

    yield put(requestDocuments());
    yield spawn(callback, objectUrl, document.displayname);
  } catch (err) {
    console.error(err);
    yield put(
      notify(
        'Error while checking out document. Please try it again later.',
        'error'
      )
    );
  } finally {
    yield put(setRepositoryLoading(false));
    yield put(requestDocuments());
  }
}

/**
 * Check in document after it was edited.
 *
 * @param {Document[]} documents - Selected documents (only first is taken).
 * @param {object} action - Redux action object.
 * @param {string} action.file - Updated document content to be uploaded.
 * @param {function} action.callback - Callback when the operation is done.
 */
function* checkInDocument(documents, { file, callback }) {
  const [document] = documents;
  const { id } = document;

  try {
    yield put(setRepositoryLoading(true, 'Checking in document.'));

    yield callApi(documentApi.checkInDocument, id, file, callback);

    yield put(requestDocuments());
    yield put(reloadDocument());

    yield spawn(callback);
  } catch (err) {
    console.error(err);
    yield put(
      notify(
        'Error while checking in document. Please try it again later.',
        'error'
      )
    );
  } finally {
    yield put(setRepositoryLoading(false));
    yield put(requestDocuments());
  }
}

function* createDocumentReference(
  folderId,
  documents,
  properties,
  { payload }
) {
  const { options } = payload;
  const { callback, targetFolderId } = options;

  function* createReference(document) {
    const documentProperties = prepareResourceForGateway(document, properties);
    const target = targetFolderId || folderId;

    try {
      const { id } = yield callApi(
        repository.createReference,
        target,
        document.id,
        documentProperties
      );

      return id;
    } catch (err) {
      console.error(err);
      yield put(
        notify(`Document reference was not created. ${err.message}`, 'error')
      );
    }
  }

  const referenceIds = yield all(
    documents.map((document) => call(createReference, document))
  );

  if (referenceIds.length > 0) {
    yield put(notify('Shortcut was created!', 'success'));
  }

  if (targetFolderId === folderId) {
    yield put(highlightDocs(referenceIds));

    if (referenceIds.filter((r) => r != null).length > 0) {
      yield put(requestDocuments());
    }
  } else {
    // const { identifier } = parseResourceId(targetFolderId);
    // yield put(openFolder(identifier));
  }

  if (callback != null) {
    yield spawn(callback, referenceIds);
  }
}

/**
 * Create duplicate of the document.
 *
 * @param {string} folderId - Folder resource id where duplicate should be placed.
 * @param {string} documentId - Resource id of a document that is duplicated.
 * @param {object} action - Redux action object.
 * @param {object} action.payload - Redux action payload object.
 * @param {object} action.payload.options - Additional options passes by the action.
 * @param {function} action.payload.options.callback - Callback when the operation is done.
 */
function* createDocumentDuplicate(folderId, documentId, { payload }) {
  const { options } = payload;

  try {
    yield put(setRepositoryLoading(true, 'Creating document duplicate.'));
    const id = yield callApi(repository.copyResource, documentId, folderId);

    yield put(requestDocuments());
    yield put(highlightDocs([id]));

    if (options.callback != null) {
      yield spawn(options.callback);
    }
  } catch (err) {
    yield put(notify(err.message, 'error'));
  } finally {
    yield put(setRepositoryLoading(false));
  }
}

/**
 * Base saga for document selection.
 *
 * @param {string} folderId - Currently opened folder id so that selection happens inside.
 * @param {object} action - Redux action object.
 * @param {Document[]} action.document - Selected documents.
 * @param {object[]} action.followingActions - Actions that should follow
 * after selection flow is ready.
 */
export default function* documentSelectionSaga(
  projectCode,
  folderId,
  { documents, followingActions }
) {
  if (documents.length > 0) {
    const documentIds = documents.map((d) => d?.id).filter((x) => x != null);

    const projectService = yield getContext('projectService');
    const properties = yield call(projectService.getProperties);

    const tasks = yield all([
      takeLatest(DELETE_DOCUMENTS, toggleHideDocuments, documentIds, true),
      takeLatest(DELETE_VERSION, function* (action) {
        yield call(toggleDeleteResource, action.versionId, action);
        yield put(fetchDocumentVersions());
      }),
      takeLatest(UNDELETE_DOCUMENTS, toggleHideDocuments, documentIds, false),
      takeLatest(LINK_DOCUMENTS, linkDocuments),
      takeLatest(UNLINK_DOCUMENTS, unlinkDocuments),
      takeLatest(UNLOCK_DOCUMENTS, unlockDocuments, documents),
      takeEvery(FETCH_LINKED_DOCUMENTS, function* (action) {
        yield spawn(fetchLinkedDocuments, documents[0], properties, action);
      }),
      takeEvery(FETCH_CHILDREN_DOCUMENTS, function* (action) {
        const { documentId } = action.payload;
        const document =
          documentId != null
            ? documents.find((d) => d.id === documentId)
            : documents[0];

        yield spawn(
          fetchSiblingDocuments,
          projectCode,
          document || documents[0],
          properties,
          action
        );
      }),

      takeLatest(EXPORT_DOCUMENTS_EXCEL, function* (action) {
        const { options } = action.payload;
        yield call(exportDocumentsExcel, documents, options.columns || []);
        yield complete(action);
      }),

      takeLatest(EXPORT_URLS_EXCEL, function* ({ allDocuments }) {
        const {resourcesWithUrls} = yield call(addURLtoResources, documents);
        if (!allDocuments) {
          yield call(exportTitleUrlExcel, resourcesWithUrls);
        }
      }),

      takeLatest(
        [EXPORT_DOCUMENTS_ZIP, DOWNLOAD_DOCUMENT],
        downloadDocumentsSaga,
        documents
      ),

      takeLatest(
        PRESERVE_SELECTED_DOCUMENTS,
        preserveSelectedDocuments,
        documents
      ),
      takeLatest(CALCULATE_PRESET, calculatePresets, documents),
      takeLatest(UPDATE_PRESET, updatePresetProperty, properties),
      takeLatest(
        UPDATE_SELECTED_DOCUMENT,
        updateSelectedDocument,
        documents,
        properties
      ),
      // takeLatest(
      //   SET_DOCUMENT_AS_PRESET,
      //   documentAsPreset,
      //   documents,
      //   properties
      // ),
      takeEvery(FETCH_SUBFOLDER_DOCUMENTS, function* (action) {
        yield spawn(
          fetchSubfolderDocuments,
          documents[0].id,
          properties,
          action
        );
      }),
      takeLeading(CHECK_OUT_DOCUMENT, checkOutDocument, documents),
      takeLeading(CHECK_IN_DOCUMENT, checkInDocument, documents),
      takeLeading(VIRTUAL_MOVE_DOCUMENTS, virtualMoveDocuments, documents),
      takeLeading(
        CREATE_DOCUMENT_REFERENCE,
        createDocumentReference,
        folderId,
        documents,
        properties
      ),
      takeLeading(
        CREATE_DOCUMENT_DUPLICATE,
        createDocumentDuplicate,
        folderId,
        documents[0]?.id
      ),
    ]);

    yield put(setSelectedDocuments(documents));

    yield* followingActions.map((a) => put(a));

    yield take(CLEAR_SELECTION);
    yield cancel(tasks);
  }

  yield put(setThumbnail(null));
  yield put(setSelectedDocuments([]));
}
