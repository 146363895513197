export const PROJECT_QUERIES_FETCH = 'PROJECT_QUERIES_FETCH';
export const PROJECT_QUERIES_SET = 'PROJECT_QUERIES_SET';
export const PROJECT_QUERY_CREATE = 'PROJECT_QUERY_CREATE';
export const PROJECT_QUERY_UPDATE = 'PROJECT_QUERY_UPDATE';
export const PROJECT_QUERY_DELETE = 'PROJECT_QUERY_DELETE';
export const PROJECT_QUERY_OVERWRITE = 'PROJECT_QUERY_OVERWRITE';

export const FETCH_WIDGETS = 'FETCH_WIDGETS';
export const SET_WIDGETS = 'SET_WIDGETS';
export const SAVE_ALL_WIDGETS = 'SAVE_ALL_WIDGETS';
export const RESTORE_DEFAULT_WIDGETS = 'RESTORE_DEFAULT_WIDGETS';

export const SET_PROJECTS = 'SET_PROJECTS';
export const OPEN_PROJECT = 'OPEN_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const FETCH_PROJECT_PROPERTIES = 'FETCH_PROJECT_PROPERTIES';
export const SET_PROJECT_PROPERTIES = 'SET_PROJECT_PROPERTIES';
export const CREATE_PROPERTIES_GROUP = 'CREATE_PROPERTIES_GROUP';
export const CREATE_PROPERTIES_GROUP_PROPERTY =
  'CREATE_PROPERTIES_GROUP_PROPERTY';
export const DELETE_PROJECT_PROPERTY = 'DELETE_PROJECT_PROPERTY';
export const UPDATE_PROJECT_PROPERTY = 'UPDATE_PROJECT_PROPERTY';
