import { createContext } from "react";
import { Reminder } from "../constants/reminder";

export interface ResourceReminderContextValue {
  loading: boolean;
  reminders: Reminder[];
  save: (reminder: Reminder) => Promise<any>;
  cancel: (reminder: Reminder) => Promise<any>;
  resourceType: number | null;
  resourceName?: string | null;
}

const ResourceReminderContext = createContext<ResourceReminderContextValue>({
  loading: false,
  reminders: [],
  save: () => Promise.reject("Function not implemented!"),
  cancel: () => Promise.reject("Function not implemented!"),
  resourceType: 2,
  resourceName: null,
});

export default ResourceReminderContext;
