import property, { Property } from "./property";

/**
 * Property Generator.
 * @category Property
 * @author Patrik Pancisin
 * @class systemProperty
 * @classdesc Basic building block of cosmos.
 * @extends property
 * @param {string} label - The label of the group displayed in cosmos.
 * @param {string} name - The name of the group.
 * @param {boolean} systemUpdatable
 */
export default (
  label: string,
  name?: string,
  systemUpdatable = false
): Property => ({
  ...property(label, name)
    .excludeTable()
    .excludeFilter()
    .excludeForm()
    .excludePreset()
    .readonly(),
  system: true,
  systemUpdatable,
});
