import { useMemo } from "react";
import { query as parseQuery } from "cosmos-config/nql";
import { useState } from "react";
import { useEffect } from "react";
import useProperties from "./useProperties";
import { queryResultsToProperties } from "../utils/reportingUtils";
import { QueryResult } from "cosmos-config/lib/nql/query";

export interface useRepositoryQueryProps {
  query?: string;
}

const useRepositoryQuery = ({ query }: useRepositoryQueryProps) => {
  const [results, setResults] = useState<QueryResult[]>([]);
  const { properties } = useProperties();

  useEffect(() => {
    if (query == null) {
      return;
    }

    const { results } = parseQuery(query);

    setResults(results);
  }, [query]);

  return {
    columnProperties: useMemo(() => {
      return queryResultsToProperties(results, properties);
    }, [properties, results]),
  };
};

export default useRepositoryQuery;
