import type {
  Property,
  BooleanAttributeProperty,
  NumericAttributeProperty,
  SelectableModuleElementProperty,
  PrincipalAttributeProperty,
  DateAttributeProperty,
} from "cosmos-config/lib/property/property";
import type { ConditionalProperty } from "cosmos-config/lib/property/conditionalProperty";
import _ from "lodash";
import {
  DatePickerProps,
  NumbericInputProps,
  OnChangeParam,
  PancoSwitchProps,
} from "cosmos-components";
import { PrincipalSelectProps } from "./PrincipalSelect";
import { MemberSelectProps } from "./MemberSelect";
import { ModuleElementSelectProps } from "./ModuleElementSelect";

export interface BaseProps {
  //extends PancoInputElementProps {
  className: string;
  name: string;
  clearable: boolean;
  multiple: boolean;
  disabled: boolean;

  id: string;
  readOnly: boolean;
  key: string;
  value: any;
  placement: "top" | "bottom";
  options: any[];
  limit: number;
  boundary: any;
  onChange: (param: OnChangeParam<any>) => void;
}

export const getNumericProps = (
  baseProps: BaseProps,
  property: NumericAttributeProperty
): NumbericInputProps => {
  return {
    ...baseProps,
    min: property.boundary.min,
    max: property.boundary.max,
    decimalPlaces: property.decimalPlaces,
  };
};

export const getDependencyValue = (
  property: Property,
  filter: Record<string, any>
) => {
  const conditionalProperty = property as ConditionalProperty;

  if (
    !conditionalProperty.isDependant() ||
    conditionalProperty.dependency == null
  ) {
    return null;
  }

  const filterValue = filter[conditionalProperty.dependency];
  return Array.isArray(filterValue) ? _.first(filterValue) : filterValue;
};

export const getElementSelectProps = (
  baseProps: BaseProps,
  property: Property,
  filter: Record<string, any>
): ModuleElementSelectProps => {
  return {
    ...baseProps,
    elementType: (property as SelectableModuleElementProperty).elementType,
    elementName: getDependencyValue(property, filter),
  };
};

export const getPrincipalSelectProps = (
  baseProps: BaseProps,
  property: PrincipalAttributeProperty
): PrincipalSelectProps => {
  return {
    ...baseProps,
    principalType: property.principalType,
    value: baseProps.value || [],
    domainNames: ["Users"],
  };
};

export const getMemberSelectProps = (
  baseProps: BaseProps,
  property: PrincipalAttributeProperty
): MemberSelectProps => {
  return {
    ...baseProps,
    principalType: property.principalType,
    groupNames: property.groupNames,
    value: baseProps.value || [],
  };
};

export const getDateProps = (
  baseProps: BaseProps,
  property: DateAttributeProperty
): DatePickerProps => {
  return {
    ...baseProps,
    zone: property.dateZone,
    placeholder: "dd.mm.yyyy",
  };
};
