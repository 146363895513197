import { useQuery } from "@tanstack/react-query";
import repositorySearchApi from "../apis/repositorySearchApi";
import { useMemo } from "react";
import _ from "lodash";
import { resourceCorrectionOfGateway } from "cosmos-config/utils";
import useSearchOptions from "./useSearchOptions";
import useDebounce from "./useDebounce";
import Resource from "../types/resource";
import useCurrentUser from "./useCurrentUser";
import useProject from "./useProject";
import useProperties from "./useProperties";
import useRepositoryFolder from "./useRepositoryFolder";
import { Property } from "cosmos-config/lib/property/property";

export interface SearchOptions {
  substructure?: boolean;
  fulltextSearch?: boolean;
  subtree?: boolean;
  signatureStatus?: "COMPLETE" | "IN_PROGRESS" | "ALL";
}

export interface UseRepositorySearchProps {
  resourceId?: string;
  filter?: any;
  orderBy?: any;
  paginator?: {
    number: number;
    size: number;
  };
  searchQuery?: string;
  substructure?: boolean;
  subtree?: boolean;
  enabled?: boolean;
  fulltextSearch?: boolean;
  signatureStatus?: "COMPLETE" | "IN_PROGRESS" | "ALL";
  properties?: Property[];
}

const useRepositorySearch = ({
  resourceId,
  orderBy,
  paginator,
  searchQuery,
  subtree,
  enabled,
  properties,

  substructure,
  filter,
  fulltextSearch,
  signatureStatus,
}: UseRepositorySearchProps) => {
  const { project } = useProject();
  const { properties: projectProperties } = useProperties();

  const { resourceId: folderId } = useRepositoryFolder();

  const repositoryFolderId = resourceId || folderId || undefined;

  const prpts = useMemo(() => {
    if (properties != null && properties.length > 0) {
      return properties;
    }

    return projectProperties;
  }, [projectProperties, properties]);

  const requestProperties = useMemo(
    () =>
      _.compact(prpts.map((p) => p.folderProperty?.query || p.query).sort()),
    [prpts]
  );

  // const { requestProperties } = useResource({});
  // {
  //   projectResourceId: '',
  // });

  const { authenticated } = useCurrentUser();

  const queryCapable = useMemo(
    () =>
      authenticated &&
      repositoryFolderId != null &&
      repositoryFolderId.length > 0 &&
      requestProperties.length > 0 &&
      (enabled === undefined || enabled),
    [requestProperties.length, repositoryFolderId, enabled, authenticated]
  );

  const debouncedSearchQuery = useDebounce(searchQuery, 250);

  const { searchOptions, searchHash, propertiesHash } = useSearchOptions({
    properties: prpts,
    resourceId: repositoryFolderId,
    filter,
    orderBy,
    paginator,
    searchQuery: debouncedSearchQuery,
    substructure,
    subtree,
    fulltextSearch,
    signatureStatus,
  });

  const { isLoading, data, error, refetch } = useQuery({
    queryKey: useMemo(
      () => ["repository-search", searchHash, propertiesHash],
      [propertiesHash, searchHash]
    ),
    queryFn: () => {
      if (repositoryFolderId == null) {
        throw new Error("Resource ID cannot be null!");
      }

      return repositorySearchApi.searchRepository(
        project?.code || null,
        repositoryFolderId,
        requestProperties,
        searchOptions
      );
    },
    enabled: queryCapable,
    refetchOnWindowFocus: false,
  });

  const parsedData: Resource[] = useMemo(() => {
    return _(data?.items)
      .map((item) => {
        const { id, properties } = item;

        return {
          id,
          ..._(properties).keyBy("name").mapValues("value").value(),
        };
      })
      .map((item) => resourceCorrectionOfGateway(item, prpts))
      .value();
  }, [data, prpts]);

  return {
    loading: isLoading,
    data: parsedData,
    error,
    count: data?.count || 0,
    refetch,
  };
};

export default useRepositorySearch;
