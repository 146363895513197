import _ from "lodash";
import { useEffect, useReducer } from "react";
import { uuidv4 } from "cosmos-config/utils";
import { Widget } from "cosmos-config/lib/widget/widget";

const actions = {
  INITIALIZE_LAYOUT: "INITIALIZE_LAYOUT",
  TOGGLE_EDIT_MODE: "TOGGLE_EDIT_MODE",
  REMOVE_WIDGET: "REMOVE_WIDGET",
  UPDATE_LAYOUT: "UPDATE_LAYOUT",
  ADD_WIDGET: "ADD_WIDGET",
  TOGGLE_TOUCHED: "TOGGLE_TOUCHED",
};

type UseDashboardState = {
  editMode: boolean;
  layout: Widget[];
  initialLayout: string;
  touched: boolean;
};

const initialState = {
  editMode: false,
  layout: [],
  touched: false,
  initialLayout: "",
} as UseDashboardState;

const calculateNextPosition = (layout: Widget[]) => {
  if (!Array.isArray(layout) || layout.length === 0) {
    return {
      x: 0,
      y: 0,
    };
  }

  return layout
    .map(({ position }) => ({
      x: position.x + position.w,
      y: position.y + position.h,
    }))
    .reduce(
      (acc, cur) =>
        cur.y >= acc.y ? { ...cur, x: cur.x >= 10 ? 0 : cur.x } : acc,
      {
        x: 0,
        y: 0,
      }
    );
};

const initializePositions = (initialLayout: Widget[]) => {
  return initialLayout.reduce(
    (acc, cur) => [
      ...acc,
      {
        ...cur,
        position: {
          ...cur.position,
          ...calculateNextPosition(acc),
        },
      },
    ],
    [] as Widget[]
  );
};

const reducer = (state: UseDashboardState, action: any): UseDashboardState => {
  if (action.type === actions.INITIALIZE_LAYOUT) {
    return {
      ...state,
      layout: action.layout == null ? [] : initializePositions(action.layout),
      initialLayout: JSON.stringify(action.layout),
      touched: false,
    };
  }

  if (action.type === actions.TOGGLE_EDIT_MODE) {
    const resultState =
      action.editMode != null ? action.editMode : !state.editMode;
    return {
      ...state,
      editMode: resultState,
      touched: false,
      layout: !resultState ? JSON.parse(state.initialLayout) : state.layout,
    };
  }

  if (action.type === actions.REMOVE_WIDGET) {
    return {
      ...state,
      touched: true,
      layout: state.layout.filter((w) => w.id !== action.widgetId),
    };
  }

  if (action.type === actions.UPDATE_LAYOUT) {
    const widgetMap = _(state.layout).keyBy("id").value();

    if (action.layout == null) {
      return {
        ...state,
        layout: [],
      };
    }

    const layout = action.layout
      //@ts-ignore
      .map(({ i: id, x, y, w, h }) => {
        const widget = widgetMap[id];
        if (widget != null) {
          return {
            ...widget,
            position: {
              x,
              y,
              w,
              h,
            },
          };
        }

        return null;
      })
      .filter((x: any) => x != null);

    return {
      ...state,
      layout,
    };
  }

  if (action.type === actions.ADD_WIDGET) {
    return {
      ...state,
      layout: [
        ...state.layout,
        {
          ...action.widget,
          id: uuidv4(),
          position: {
            ...calculateNextPosition(state.layout),
            x: 0,
            w: 5,
            h: 3,
          },
        },
      ],
      touched: true,
    };
  }

  if (action.type === actions.TOGGLE_TOUCHED) {
    return {
      ...state,
      touched: action.touched,
    };
  }

  return state;
};

export interface UseDashboardProps {
  initialLayout?: Widget[];
  widgets?: Widget[];
}

const useDashboard = ({ initialLayout, widgets }: UseDashboardProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (initialLayout != null) {
      dispatch({ type: actions.INITIALIZE_LAYOUT, layout: initialLayout });
    }
  }, [initialLayout]);

  return {
    touched: state.touched,
    layout: state.layout,
    editMode: state.editMode,
    addWidget: (widgetName: string) => {
      const widget = (widgets || []).find((w) => w.name === widgetName);
      dispatch({ type: actions.ADD_WIDGET, widget });
    },
    toggleEditMode: (editMode: boolean) => {
      dispatch({ type: actions.TOGGLE_EDIT_MODE, editMode });
    },
    updateLayout: (layout: Widget[]) => {
      dispatch({ type: actions.UPDATE_LAYOUT, layout });
    },
    removeWidget: (widgetId: string) => {
      dispatch({
        type: actions.REMOVE_WIDGET,
        widgetId,
      });
    },
    toggleTouched: (touched: boolean) => {
      dispatch({ type: actions.TOGGLE_TOUCHED, touched });
    },
  };
};

export default useDashboard;
