import { Property } from "cosmos-config/lib/property/property";
import _ from "lodash";

export const generateTitleTemplate = (
  resource: Record<string, any>,
  properties: Property[]
) => {
  return properties
    .filter((p) => !p.system && p.information)
    .map((prop) => {
      const value = _.get(resource, prop.name);

      if (value == null) {
        return `\${${prop.name}}`;
      }

      return `\${${prop.name}:${value}}`;
    })
    .join(" / ");
};
