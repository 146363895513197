export default class ForbiddenError extends Error {
  static FORBIDDEN_STRICLY_CONFIDENTIAL = 'strictly-confidential';
  static FORBIDDEN_ACCESS_DENIED = 'access-denied';

  constructor(type, message) {
    super(message);
    this.code = 403;
    this.type = type;
  }

  static strictlyConfidential(message) {
    return new ForbiddenError(
      ForbiddenError.FORBIDDEN_STRICLY_CONFIDENTIAL,
      message
    );
  }

  static accessDenied(message) {
    return new ForbiddenError(ForbiddenError.FORBIDDEN_ACCESS_DENIED, message);
  }
}
