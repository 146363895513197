import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import SimpleTable from "../../../components/SimpleTable";
import userProperties from "../constants/userProperties";
import { ContextMenu } from "cosmos-components";
import classNames from "classnames";
import UserManagementContext from "../contexts/UserManagementContext";
import _ from "lodash";
import { ProjectMember } from "../../../contexts/ProjectContext";
import useProjectCompetence from "../../../hooks/useProjectCompetence";

export interface UsersTableProps {
  users?: ProjectMember[];
}

const UsersTable = ({ users }: UsersTableProps) => {
  const { removeProjectMembers } = useProjectCompetence();

  const data = useMemo(
    () =>
      (users || []).sort((a, b) =>
        String(a.commonname).localeCompare(b.commonname)
      ),
    [users]
  );

  const renderMenu = useCallback(
    (selectedUsers: ProjectMember[]) => {
      const atLeastOneSelected = selectedUsers?.length > 0;
      return (
        <>
          <ContextMenu.Item
            disabled={!atLeastOneSelected}
            onClick={() => {
              removeProjectMembers(selectedUsers);
            }}
          >
            <span
              className={classNames({
                "text-danger": atLeastOneSelected,
              })}
            >
              Remove Member
            </span>
          </ContextMenu.Item>
        </>
      );
    },
    [removeProjectMembers]
  );

  return (
    <UserManagementContext.Consumer>
      {({ searchQuery }) => (
        <SimpleTable
          properties={userProperties}
          data={data}
          renderContextMenu={renderMenu}
          searchQuery={searchQuery || undefined}
        />
      )}
    </UserManagementContext.Consumer>
  );
};

UsersTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
};

UsersTable.defaultProps = {
  users: [],
};

export default UsersTable;
