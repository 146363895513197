import classNames from "classnames";
import { ContextMenu } from "cosmos-components";
import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import reminderUserProperties from "../constants/reminderUserProperties";
import EmailSelect from "../../../components/form/EmailSelect";
import SimpleTable from "../../../components/SimpleTable";
import { Reminder, ReminderUser } from "../constants/reminder";

export interface ReminderUsersEditorProps {
  reminder?: Reminder;
  className?: string;
  onChange?: (reminderUsers: ReminderUser[]) => void;
}

const ReminderUsersEditor = ({
  reminder,
  className,
  onChange,
}: ReminderUsersEditorProps) => {
  const renderMenu = (selectedItems: ReminderUser[]) => {
    const selectedItem = _.first(selectedItems);
    return (
      <>
        <ContextMenu.Item
          disabled={selectedItems.length > 1}
          onClick={() => {
            if (onChange != null && reminder != null && selectedItem != null) {
              onChange(
                reminder.users.filter((u) => {
                  return (
                    (u.email == null || u.email !== selectedItem.email) &&
                    (u.principalId == null ||
                      u.principalId !== selectedItem.principalId)
                  );
                })
              );
            }
          }}
        >
          Remove
        </ContextMenu.Item>
      </>
    );
  };

  return (
    <div className={classNames(className)}>
      <EmailSelect
        boxed
        value={[]}
        placeholder="Search for Recipients"
        className="mb-3"
        principalType={2}
        onSelect={({ value, label, id }) => {
          if (onChange != null && reminder != null && value != null) {
            const [email] = value;
            onChange([
              ...reminder.users,
              {
                email,
                name: label || "",
                principalId: id || "",
              },
            ]);
          }
        }}
      />
      <SimpleTable
        properties={reminderUserProperties}
        data={reminder?.users}
        renderContextMenu={renderMenu}
      />
    </div>
  );
};

ReminderUsersEditor.propTypes = {
  reminder: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default ReminderUsersEditor;
