const getValueQueryRepr = (value: any, date = false): any => {
  if (value === null) {
    return "null";
  }

  if (date) {
    return `toDate(${value})`;
  }

  if (Array.isArray(value)) {
    return getValueQueryRepr(value[0], date);
  }

  if (Number.isInteger(value)) {
    return value;
  }

  if (value === true || value === false) {
    return value;
  }

  return `"${value}"`;
};

export default getValueQueryRepr;
