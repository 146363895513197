import React from "react";
import { useTranslation } from "react-i18next";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import useProject from "../../../../hooks/useProject";
import { CosmosWidget } from "../../types/CosmosWidget";
import styled from "styled-components";

const GreetingStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-left: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
`;

const Greeting = (): CosmosWidget => {
  const { userData } = useCurrentUser();
  const { projectName } = useProject();

  const { t } = useTranslation("module");

  return (
    <GreetingStyled>
      <h1>
        {t("common.greeting")} {userData?.commonName},
      </h1>
      <p className="text-dark">
        {t("common.welcome")} {projectName}!
      </p>
    </GreetingStyled>
  );
};

export default Greeting;
