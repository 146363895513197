import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faKey,
  faLock,
  faLockOpen,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import Resource from "../../../types/resource";
import useAccessLevelOptions from "../hooks/useAccessLevelOptions";
import useResourcePermissions from "../../../hooks/permissions/useResourcePermissions";
import { useTranslation } from "react-i18next";
import useFolderProperty from "../../../hooks/useFolderProperty";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useUserRelation } from "../../user-relation";
import { useProject } from "../../../hooks";

type LockSymbolProps = {
  icon?: IconProp;
  className?: string;
  tooltipText?: string;
  renderTooltip?: () => React.ReactNode;
};

const LockSymbol = ({ icon, className, renderTooltip }: LockSymbolProps) => {
  const [render, setRender] = React.useState<boolean>(false);

  if (icon == null) {
    return null;
  }

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 500, hide: 500 }}
      onToggle={setRender}
      overlay={(props) => {
        if (render && renderTooltip != null) {
          return (
            <Tooltip id={"d"} {...props}>
              {renderTooltip()}
            </Tooltip>
          );
        }

        return <></>;
      }}
    >
      <div>
        <FontAwesomeIcon icon={icon} className={className} />
      </div>
    </OverlayTrigger>
  );
};

type TooltipTextResolverProps = {
  translationPath?: string;
  defaultValue?: string;
  accessLevel?: number;
  resource?: Resource;
};

const TooltipTextResolver = ({
  translationPath,
  defaultValue,
  resource,
}: TooltipTextResolverProps) => {
  const { t } = useTranslation("module");
  const { accessLevel } = useResourcePermissions(resource);
  const { getFolderTypeLabel } = useFolderProperty(
    useMemo(() => resource?.foldertype || undefined, [resource?.foldertype])
  );
  const { projectName } = useProject();

  const resourceName = useMemo(() => {
    if (resource == null) {
      return "unknown";
    }

    const { resourcetype, foldertype } = resource;

    if (resourcetype === 1 && foldertype != null) {
      return getFolderTypeLabel(false);
    }

    return t("common.document", { defaultValue: "Document" });
  }, [getFolderTypeLabel, resource, t]);

  const accessLevelOptions = useAccessLevelOptions();

  const accessLevelOption = useMemo(
    () => accessLevelOptions.find((o) => o.value === accessLevel),
    [accessLevelOptions, accessLevel]
  );

  if (translationPath == null) {
    return null;
  }

  return (
    <>
      {t(translationPath, {
        defaultValue,
        resourceName,
        accessLevel: accessLevelOption?.label,
        projectName,
      })}
    </>
  );
};

export interface PermissionsLockSymbolProps {
  resource?: Resource;
}

const PermissionLockSymbol = ({ resource }: PermissionsLockSymbolProps) => {
  const {
    isDocumentController,
    isPrivateResource,
    canResourcePermission,
    isMaintainer,
  } = useResourcePermissions(resource);
  const { systemAdmin } = useCurrentUser();
  const { moduleAdmin } = useUserRelation();

  if (!resource?.private && !isPrivateResource()) {
    return null;
  }

  if (systemAdmin) {
    return (
      <LockSymbol
        icon={faWrench}
        className="text-muted"
        renderTooltip={() => (
          <TooltipTextResolver
            translationPath="document.permission_system_admin"
            defaultValue="You are eligible to edit the {{resourceName}} because you are system Administrator."
            resource={resource}
          />
        )}
      />
    );
  }

  if (moduleAdmin) {
    return (
      <LockSymbol
        icon={faKey}
        className="text-info"
        renderTooltip={() => (
          <TooltipTextResolver
            translationPath="document.permission_module_admin"
            defaultValue="You are eligible to edit the {{resourceName}} because you are administartor of the {{projectName}}."
            resource={resource}
          />
        )}
      />
    );
  }

  if (isMaintainer() || isDocumentController()) {
    return (
      <LockSymbol
        icon={faLockOpen}
        className="text-info"
        renderTooltip={() => (
          <TooltipTextResolver
            translationPath="document.permission_document_controller"
            defaultValue="You are eligible to edit the {{resourceName}} because you are document controller or maintainer."
            resource={resource}
          />
        )}
      />
    );
  }

  if (canResourcePermission("update")) {
    return (
      <LockSymbol
        icon={faLockOpen}
        className="text-success"
        renderTooltip={() => (
          <TooltipTextResolver
            translationPath="document.permission_update"
            defaultValue="You are eligible to edit the {{resourceName}} because you are owner of the {{resourceName}} or you were explicitly granted a permission."
            resource={resource}
          />
        )}
      />
    );
  }

  if (canResourcePermission("read")) {
    return (
      <LockSymbol
        icon={faLockOpen}
        className="text-warning"
        renderTooltip={() => (
          <TooltipTextResolver
            translationPath="document.permission_read"
            defaultValue="You can open the {{resourceName}} even though it is {{accessLevel}} because you were explicitly granted a permission."
            resource={resource}
          />
        )}
      />
    );
  }

  return (
    <LockSymbol
      icon={faLock}
      className="text-danger"
      renderTooltip={() => (
        <TooltipTextResolver
          translationPath="document.permission_view"
          defaultValue="The {{resourceName}} is set as {{accessLevel}} and you were not granted a permission."
          resource={resource}
        />
      )}
    />
  );

  // return (
  //   <LockSymbol
  //     icon={faEyeSlash}
  //     className="text-muted"
  //     renderTooltip={() => (
  //       <TooltipTextResolver
  //         translationPath="document.permission_unlisted"
  //         defaultValue="The {{resourceName}} is set as {{accessLevel}} and you were not granted a permission."
  //         resource={resource}
  //       />
  //     )}
  //   />
  // );
};

export default PermissionLockSymbol;
