import _ from "lodash";
import { useCallback, useMemo } from "react";
import useProperties from "./useProperties";
import usePropertyRender from "./usePropertyRender";
import { Folder } from "cosmos-config/lib/group/folder";
import Resource from "../types/resource";
import { useTranslation } from "react-i18next";

/**
 * @module hooks/useFolderProperty
 * @category Hooks
 */

/**
 * @typedef UseFolderPropertyHook
 * @type {object}
 * @property {Property} folderProperty - Folder type property definition.
 * @property {Property} folderTypeLabel - Label of the folder type per definition and translation.
 * @property {Property} renderFolderName - Render folder type sub-folder name.
 * @property {Property} properties - Project folder properties related to folder type.
 * @property {Property} groupedProperties - Project grouped folder properties related to folder type.
 */

/**
 * Hook to quickly access properties of specific project folder type.
 *
 * @param {string} folderType - Type of a project folder.
 * @returns {UseFolderPropertyHook}
 */
const useFolderProperty = (folderType?: string) => {
  const { t } = useTranslation("property");
  const { folders, getFolderProperties, getFolderGroups, render } =
    useProperties();

  const folderProperty = useMemo((): Folder | null => {
    if (folderType == null) {
      return folders[0];
    }

    const folderPropsMap = _.keyBy(folders, "name");
    return folderPropsMap[folderType];
  }, [folders, folderType]);

  const titlePropertyName = useMemo(
    () => folderProperty?.titlePropertyName || null,
    [folderProperty]
  );

  const properties = useMemo(
    () =>
      folderProperty != null ? getFolderProperties(folderProperty.name) : [],
    [getFolderProperties, folderProperty]
  );

  const groupedProperties = useMemo(
    () => (folderProperty != null ? getFolderGroups(folderProperty.name) : []),
    [getFolderGroups, folderProperty]
  );

  const renderFolderName = useCallback(
    (resource: Resource) => {
      if (titlePropertyName != null) {
        const value = resource[titlePropertyName];
        return render(
          titlePropertyName,
          Array.isArray(value) ? _.first(value) : value
        );
      }

      return resource.displayname || "";
    },
    [render, titlePropertyName]
  );

  return {
    folderProperty,
    getFolderTypeLabel: useCallback(
      (plural: boolean) => {
        if (folderProperty == null) {
          return "";
        }

        const translationPath = folderProperty.translation;
        if (translationPath != null && translationPath.length > 0) {
          return t(translationPath, {
            count: plural ? 2 : 1,
            defaultValue: folderProperty.label,
          });
        }

        return folderProperty.label;
      },
      [folderProperty, t]
    ),
    renderFolderName,
    properties,
    groupedProperties,
  };
};

export default useFolderProperty;
