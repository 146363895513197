import conditionalElement, { ConditionalElement } from "../conditionalElement";
import property, { Property } from "./property";

/**
 * @interface
 * @category Property
 */
export interface ConditionalProperty extends Property, ConditionalElement {
  level: number;
}

/**
 * Property Generator.
 * @category Property
 * @author Patrik Pancisin
 * @class conditionalProperty
 * @classdesc Basic building block of cosmos.
 * @extends property
 * @extends conditionalElement
 * @param {string} label - The label of the group displayed in cosmos.
 * @param {string} name - The name of the group.
 * @param {number} level
 */
export default (
  label: string,
  name?: string,
  level = 1
): ConditionalProperty => ({
  ...property(label, name),
  ...conditionalElement(),
  level,
});
