import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import SlideDown from "../slide-down/SlideDown";
import useClickOutside from "../../hooks/useClickOutside";
import classNames from "classnames";
import "./drawer.scss";

export interface DrawerProps {
  title?: string;
  children?: React.ReactNode;
  opened?: boolean;
}

const Drawer = ({ title, children, opened }: DrawerProps) => {
  const [show, setShow] = useState<boolean>(!!opened);
  const target = useRef<HTMLDivElement>(null);

  useClickOutside(target, () => {
    setShow(false);
  });

  useEffect(() => {
    setShow(!!opened);
  }, [opened]);

  const icon = show ? faAngleDoubleDown : faAngleDoubleUp;

  return (
    <div className={classNames("drawer", { opened: show })} ref={target}>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={() => setShow((s) => !s)}
        className="drawer-handle"
      >
        <FontAwesomeIcon icon={icon} className="mr-auto" />
        {title}
        <FontAwesomeIcon icon={icon} className="ml-auto" />
      </div>

      <div className="drawer-content">
        <SlideDown collapsed={!show}>
          <div className="p-2">{children}</div>
        </SlideDown>
      </div>
    </div>
  );
};

export default Drawer;
