import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { CosmosCoreRootState } from "../store";

export const getValuesets = (state: CosmosCoreRootState) =>
  state.docarea.valuesets;
export const getMasterValuesets = (state: CosmosCoreRootState) =>
  state.docarea.masterValuesets;
export const getLoadingValueset = (state: CosmosCoreRootState) =>
  state.docarea.loadingValueset;
export const getDocareaName = (state: CosmosCoreRootState) =>
  state.docarea.name;
export const getDocareaUpdatedAt = (state: CosmosCoreRootState) =>
  state.docarea.updatedAt;

export const getValuesetsMap = createSelector(
  [getValuesets, getMasterValuesets],
  (valuesets, masterValuesets) => {
    return _(valuesets)
      .concat(masterValuesets)
      .keyBy("name")
      .mapValues("data")
      .value();
  }
);
