import { uuidv4 } from "../utils";

export type QueryResult = {
  id: number;
  type: "FIELD" | "FUNCTION";
  name: string;
  parameter: string | null;
  axis: string | null;
  alias: string | null;
};

export type QueryGroupCondition = {
  id: string;
  operator: string;
  name: string;
  value: string;
};

export type QueryGroup = {
  id: string;
  operator: string;
  conditions: Array<QueryGroupCondition>;
};

export type ParsedQuery = {
  groups: Array<QueryGroup>;
  results: Array<QueryResult>;
  select(properties: string[]): string;
};

/**
 * NQL query parser service.
 * ### Project Queries
 * Cosmos has an option to design queries and store them as project related queries.
 * These are stored in the nql query format. It has to be transitioned back and forth
 * so that editor receives list of query properties and conditions and user is able
 * to adjust it.
 *
 * @category NqlQuery
 * @author Patrik Pancisin
 * @param query - Nql query string.
 * @returns {ParsedQuery} Parsed nql query.
 */
const query = (query: String): ParsedQuery => {
  const regex =
    /select ([\w\s,()=]+)(?:\swhere\s(\(+\w+!?=.+\)+))? scope subtree/;

  const match = query.match(regex);

  if (match != null && match.length > 2) {
    const results = String(match[1])
      .split(",")
      .map((prop) => prop.trim())
      .map((name, id): QueryResult => {
        const fieldMatch = String(name).match(/(?:(\w+)=)?(\w+)\((\w+)\)/);

        if (fieldMatch == null) {
          return {
            id,
            type: "FIELD",
            name,
            axis: null,
            parameter: null,
            alias: null,
          };
        }

        return {
          id,
          type: "FUNCTION",
          alias: fieldMatch[1],
          name: fieldMatch[2],
          parameter: fieldMatch[3],
          axis: null,
        };
      });

    const [, ...groups] =
      String(match[2])
        .match(/\(((\w+(=|!=|>|<|>=|<=|\sis\snull)[^\s)]*)|\s|\||&)+\)/g)
        ?.map((group) => String(group).replace(/^\(+|\)+$|"|toDate\(/g, ""))
        .map((group): QueryGroup => {
          return {
            id: uuidv4(),
            operator: "&",
            conditions: String(group)
              .split(/[|&]/)
              .map((expression): QueryGroupCondition => {
                const operatorRegex = /=|!=|>|<|>=|<=|\sis\snull/g;
                const operator = expression.trim().match(operatorRegex);
                const [key, value] = expression.trim().split(operatorRegex);

                return {
                  id: uuidv4(),
                  operator:
                    operator != null && operator[0] != null
                      ? operator[0].trim()
                      : "=",
                  name: key,
                  value,
                };
              }),
          };
        })
        .filter((x) => x != null) || [];

    return {
      groups,
      results,
      select: (properties: string[]) => {
        return query.replace(
          /select[\w,\s]+where/g,
          `select ${properties.join(",")} where`
        );
      },
    };
  }

  throw Error("Query is not valid!");
};

export default query;
