import React, { useEffect, useRef, useState, useCallback } from "react";
import { isString } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import useViewPlacement from "../../hooks/useViewPlacement";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import FormControl, {
  FormControlAction,
  FormControlContent,
  FormControlFilter,
  FormControlProps,
} from "../FormControl";
import { shadeColor } from "../../utils";

const ValuePickerComponent = styled(FormControl)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .value-picker-content {
    flex-grow: 1;
  }

  .value-picker-action {
    margin-left: auto;

    cursor: pointer;
    color: ${(props) => props.theme.primary};
    height: 100%;
    z-index: 1;

    .close {
      color: inherit;
      font-weight: 100;
      font-size: 14 * 1.7;
      line-height: 1.2rem;
    }
  }
`;

type ValuePickerWrapperContainerProps = {
  collapsed?: boolean;
};

const ValuePickerWrapperContainer = styled.div<ValuePickerWrapperContainerProps>`
  max-height: 56vh;
  position: absolute;
  color: ${(props) => props.theme.base};
  background-color: ${(props) => props.theme.background};
  border: 1px solid ${(props) => shadeColor(0.15, props.theme.background)};
  z-index: 1051;
  min-width: 100%;
  left: 0;
  flex-direction: row;

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

  & > div {
    width: 100%;
  }

  ${(props) =>
    props.collapsed &&
    css`
      display: none;
    `}
`;

declare type ChildrenCallbackParam<T> = {
  onSelect: (v: T | null, idx?: number) => void;
};

export interface ValuesetPickerWrapperProps<T>
  extends Omit<
      React.HtmlHTMLAttributes<HTMLDivElement>,
      "children" | "content" | "onSelect" | "onChange"
    >,
    FormControlProps {
  selection?: boolean;
  children?: (param: ChildrenCallbackParam<T>) => React.ReactNode;
  placeholder?: string;
  filterPlaceholder?: string;
  editableFilter?: boolean;
  clearable?: boolean;
  placement?: "top" | "bottom";
  onClear?: () => void;
  boxed?: boolean;
  actionIcon?: () => React.ReactNode;
  content?: () => React.ReactNode;
  className?: string;
  onSelect?: (value: T | null, idx?: number) => void;
  onBlur?: () => void;
  onFilterChange?: (value: string | null) => void;
  filterable?: boolean;
  disabled?: boolean;
  actionOptions?: React.ReactNode;
}

function ValuePickerWrapper<T>({
  selection,
  children,
  placeholder,
  filterPlaceholder,
  editableFilter,
  clearable,
  placement,
  onClear,
  boxed,
  actionIcon,
  content,
  className,
  onSelect,
  onBlur,
  onFilterChange,
  filterable,
  disabled,
  actionOptions,
  ...props
}: ValuesetPickerWrapperProps<T>) {
  const [focus, setFocus] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);
  const filterRef = useRef<HTMLInputElement | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const dialogRef = useRef<HTMLDivElement | null>(null);

  const { placement: calculatedPlacement, recalculatePlacement } =
    useViewPlacement({ placement: "bottom", height });

  const closeWrapper = useCallback(() => {
    if (filterable) {
      if (filterRef.current != null) {
        filterRef.current.value = "";
      }

      if (!editableFilter && onFilterChange != null) {
        onFilterChange(null);
      }
    }

    if (onBlur != null) {
      onBlur();
    }
    setFocus(false);
  }, [filterable, onBlur, onFilterChange, editableFilter]);

  const openWrapper = useCallback(() => {
    setFocus((oldFocus) => {
      if (!oldFocus) {
        const value = content != null ? content() : null;
        if (isString(value) && editableFilter) {
          if (filterRef.current != null) {
            filterRef.current.value = value;
          }

          if (onFilterChange != null) {
            onFilterChange(value);
          }
        }
      }

      return true;
    });

    setTimeout(() => {
      const bounding = dialogRef.current?.getBoundingClientRect();
      setHeight((current) => {
        const h = bounding?.height || 0;
        return h !== current ? h : current;
      });
    }, 10);
  }, [setFocus, content, editableFilter, onFilterChange]);

  useEffect(() => {
    if (focus) {
      const bounding = elementRef.current?.getBoundingClientRect();
      recalculatePlacement(bounding?.y);
    }
  }, [focus, recalculatePlacement]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !(
          (elementRef.current &&
            elementRef.current.contains(event.target as Node)) ||
          (dialogRef.current &&
            dialogRef.current.contains(event.target as Node))
        )
      ) {
        closeWrapper();
      }
    };

    if (focus) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [elementRef, focus, closeWrapper]);

  const handleSelection = (v: T | null, idx?: number) => {
    closeWrapper();
    if (onSelect != null) {
      onSelect(v, idx);
    }
  };

  const [wrapperStyle, setWrapperStyle] = useState({});

  useEffect(() => {
    if (elementRef.current != null) {
      const rect = elementRef.current.getBoundingClientRect();

      if (calculatedPlacement === "top") {
        setWrapperStyle({
          left: rect.left,
          top: rect.top,
          transform: "translateY(-100%)",
          minWidth: rect.width,
          position: "fixed",
        });
      } else {
        setWrapperStyle({
          left: rect.left,
          top: rect.bottom,
          minWidth: rect.width,
          position: "fixed",
        });
      }
    }
  }, [calculatedPlacement, focus]);

  const renderActionIcon = (): React.ReactNode => {
    if (disabled) {
      return null;
    }

    if (clearable && selection) {
      return <FontAwesomeIcon icon={faTimes} />;
    }

    if (actionIcon == null) {
      return <FontAwesomeIcon icon={faChevronDown} />;
    }

    return actionIcon();
  };

  const renderActionOptions = (): React.ReactNode => {
    return actionOptions ?? "";
  };

  const renderListPortal = () => {
    return ReactDOM.createPortal(
      <ValuePickerWrapperContainer
        ref={dialogRef}
        style={wrapperStyle}
        collapsed={!focus}
      >
        {children != null ? children({ onSelect: handleSelection }) : null}
      </ValuePickerWrapperContainer>,
      document.body
    );
  };

  return (
    <ValuePickerComponent
      {...props}
      boxed={boxed}
      disabled={disabled}
      className={className}
      ref={elementRef}
    >
      <FormControlContent
        onClick={() => {
          if (!disabled) {
            if (filterable && filterRef.current != null) {
              filterRef.current.focus();
            }

            openWrapper();
          }
        }}
      >
        {filterable && (
          <FormControlFilter
            className={classNames({
              "has-focus": focus,
            })}
            placeholder={filterPlaceholder}
            ref={filterRef}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.charCode === 13) {
                handleSelection(null, 0);
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (onFilterChange != null) {
                onFilterChange(e.target.value);
              }
            }}
          />
        )}

        {selection && !focus && content ? (
          content()
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
      </FormControlContent>
      <span className="value-picker-action-options">
        {renderActionOptions()}
      </span>
      <FormControlAction
        onClick={() => {
          if (!disabled) {
            if (clearable && selection) {
              if (onClear != null) {
                onClear();
              }

              if (onBlur != null) {
                onBlur();
              }
            } else {
              openWrapper();
            }
          }
        }}
      >
        {renderActionIcon()}
      </FormControlAction>
      {renderListPortal()}
    </ValuePickerComponent>
  );
}

ValuePickerWrapper.defaultProps = {
  filterPlaceholder: "Start typing",
  placement: "bottom",
};

export default ValuePickerWrapper;
