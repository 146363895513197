import Axios from "axios";
import authInterceptor from "./authInterceptor";
import errorResponseInterceptor from "./errorResponseInterceptor";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Api = {
  build() {
    const axios = Axios.create({
      baseURL: BASE_URL,
    });

    axios.interceptors.request.use(authInterceptor);
    axios.interceptors.response.use(
      (response) => response,
      errorResponseInterceptor
    );

    return axios;
  },
};

export default Api;
