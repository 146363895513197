/* eslint-disable func-names */
import {
  all,
  call,
  cancel,
  fork,
  getContext,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as actions from '../Actions/types';
import {
  fetchProcesses,
  reloadProcess,
  setOpenedProcessId,
  setProcess,
  setProcessDefinitions,
  setProcesses,
  setProcessPreload,
  setProcessTasks,
  setTaskHistory,
  setTaskLoading,
  setWorkflowLoading,
  updateAllProcessTasks,
  preloadProcess as preloadProcessAction,
  setNextProcessId,
  openProcess as openProcessAction,
  closeProcess,
  setWorkflowStatus,
} from '../Actions/workflow';
import workflowApi from '../Api/workflow';
import { initiateResource, prepareResource } from '../Utils/documentUtils';
import { requestDocuments, setProxyFolderId } from '../Actions/repository';
import { getProcessTasks, getWorkflowStatus } from '../Selectors/workflow';
import folderApi from '../Api/repository';
import {
  getSelectedDocuments,
  getSelectedFolder,
} from '../Selectors/repository';
import callApi from './Effects/callApi';
import {
  closeDocument,
  openDocument,
  preloadDocument,
} from '../Actions/document';
import taskHistoryApi from '../Api/taskHistory';
import callSequence from './Effects/callSequence';
import { notify, setBackgroundLoading } from '../Actions/ui';
import { getDefaultPositionId } from '../Selectors/userdata';
import { exportData } from './repository/exportExcelSaga';
import _ from 'lodash';
import { resourceCorrectionOfNscale } from 'cosmos-config/utils';
import { validateResources } from 'cosmos-core';

function* getDefaultProcessName() {
  const projectService = yield getContext('projectService');
  const projectWorkflows = yield call(projectService.getWorkflows);

  const [defaultWorkflow] = projectWorkflows.filter((wf) => wf.default);

  if (defaultWorkflow != null) {
    return defaultWorkflow.processName;
  }

  const [firstWorkflow] = projectWorkflows;
  return firstWorkflow?.processName;
}

function* fetchAssignedTasks() {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  const processName = yield call(getDefaultProcessName);

  const localPropertiesService = yield getContext('localPropertiesService');

  const propertiesMap = yield call(
    localPropertiesService.getWorkflowProperties,
    processName
  );

  if (propertiesMap.length <= 0) {
    return [];
  }

  try {
    const requestedProps = propertiesMap.map((prop) => prop.query);
    const { items } = yield callApi(
      workflowApi.getAssignedTasks,
      docareaName,
      processName,
      requestedProps
    );
    const tasks = resourceCorrectionOfNscale(items, propertiesMap).filter(
      (obj, pos, arr) => {
        return (
          arr.map((p) => p.processidentifier).indexOf(obj.processidentifier) ===
          pos
        );
      }
    );
    return tasks;
  } catch (err) {
    console.error(err);
    return [];
  }
}

function* fetchProcessTasks(processId, processDefinition) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  const localPropertiesService = yield getContext('localPropertiesService');
  const properties = yield call(
    localPropertiesService.getWorkflowProperties,
    processDefinition
  );

  const requestedProps = properties
    .map((p) => p.query)
    .filter((p) => p != null);

  try {
    const { items } = yield callApi(
      workflowApi.getProcessTasks,
      docareaName,
      processId,
      requestedProps
    );

    const tasks = resourceCorrectionOfNscale(items, properties);
    return tasks;
  } catch (err) {
    yield put(notify('Failed to load process tasks.', 'error'));
  }

  return [];
}

function* fetchProcess(processId) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  const localPropertiesService = yield getContext('localPropertiesService');
  const propertiesMap = yield call(
    localPropertiesService.getWorkflowProperties
  );

  const requestedProps = propertiesMap.map((prop) => prop.query);
  const result = yield callApi(
    workflowApi.getProcess,
    docareaName,
    processId,
    requestedProps
  );

  const process = {
    ...resourceCorrectionOfNscale(result, propertiesMap),
    id: processId,
  };

  return process;
}

function* endProcess({ processId, callback }) {
  try {
    const docareaService = yield getContext('docareaService');
    const docareaName = yield call(docareaService.getDocareaName);
    yield put(setWorkflowLoading(true, 'Loading user tasks'));
    // yield callApi(workflowApi.endProcessPeacefully, docAreaName, processId);
    yield callApi(workflowApi.endProcess, docareaName, processId);
    yield put(notify('The task has been deleted.', 'success'));
    yield spawn(callback);
  } catch (err) {
    yield put(
      notify(`Deleting process failed with reason: ${err.message}`, 'error')
    );
  } finally {
    yield put(fetchProcesses());
    yield put(setWorkflowLoading(false));
  }
}

function* prepareTaskProperties(task) {
  const localPropertiesService = yield getContext('localPropertiesService');
  const propertiesGroups = yield call(
    localPropertiesService.getWorkflowGroups,
    task.processdefinition
  );
  const propertiesMap = propertiesGroups
    .filter((pg) => pg.dedicated == null || pg.dedicated === task.taskname)
    .map((pg) => pg.properties)
    .reduce((acc, cur) => [...acc, ...cur], []);

  const validationErrors = yield call(validateResources, [task], propertiesMap);
  if (validationErrors.length > 0) {
    throw new Error(
      'There were validation errors in task attributes. Check task attributes, please and resubmit the form.'
    );
  }

  if (task != null) {
    const taskProperties = prepareResource(task, propertiesMap);

    const requiredProperties = propertiesMap
      .filter(
        (p) =>
          (p.editable && p.updatable) ||
          p.name === 'dispatchers' ||
          p.name === 'taskstate'
      )
      .map((p) => p.name);

    return taskProperties.filter((tp) => requiredProperties.includes(tp.name));
  }

  throw new Error('Task submitted to update is null!');
}

function* updateTask(taskId, task, checkout = true) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);
  let localTask = task;

  if (checkout) {
    const defaultPositionId = yield select(getDefaultPositionId);
    localTask = {
      ...localTask,
      dispatchers: [...localTask.dispatchers, defaultPositionId],
      taskstate: ['parcial'],
    };
  } else {
    localTask = {
      ...localTask,
      taskstate: ['paused'],
    };
  }

  const properties = yield call(prepareTaskProperties, localTask);
  yield callApi(workflowApi.updateTask, docareaName, taskId, properties);
  return true;
}

function* endTask(taskId, task) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);
  const properties = yield call(prepareTaskProperties, task);
  yield callApi(workflowApi.endTask, docareaName, taskId, properties);
  return true;
}

function* fetchProcessHistory(documentNumber, { callback }) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);
  const projectService = yield getContext('projectService');
  const taskHistoryProperties = yield call(
    projectService.getTaskHistoryProperties
  );

  const result = yield callApi(
    taskHistoryApi.getByDocumentNumber,
    docareaName,
    documentNumber,
    taskHistoryProperties.map((p) => p.query)
  );

  const history = resourceCorrectionOfNscale(result, taskHistoryProperties);
  yield spawn(callback, history);
}

function* preloadProcess(processIdentifier) {
  // const docAreaName = yield select(getDocareaName);
  // const processId = `${docAreaName}$NOTSET$${processIdentifier}$${processIdentifier}$NOTSET$1`;
  const processId = processIdentifier;

  yield put(setBackgroundLoading(true, 'Preloading following workflow'));
  const process = yield call(fetchProcess, processId);
  const tasks = yield call(
    fetchProcessTasks,
    processId,
    process.processdefinition
  );

  yield put(
    setProcessPreload({
      identifier: processIdentifier,
      process,
      tasks,
    })
  );

  const { WFD_DocumentNumber: documentNumber } = process;
  if (documentNumber != null) {
    const [dn] = String(documentNumber).split('-');
    const documentIdentifier = parseInt(dn, 10);
    yield put(preloadDocument(documentIdentifier));
  }
  yield put(setBackgroundLoading(false));
}

function* performPreload(currentId) {
  const nextProcesses = yield call(fetchAssignedTasks);

  const identifiers = nextProcesses.map((p) => p.processinstanceid);
  const idxCurr = identifiers.indexOf(currentId);

  let nextProcessIdentifier;
  if (idxCurr > -1 && identifiers.length > idxCurr + 1) {
    nextProcessIdentifier = identifiers[idxCurr + 1];
  } else {
    const filteredIndent = identifiers.filter((p) => p !== currentId);
    if (filteredIndent.length > 0) {
      [nextProcessIdentifier] = filteredIndent;
    }
  }

  if (nextProcessIdentifier != null) {
    yield put(setNextProcessId(nextProcessIdentifier));
    yield put(preloadProcessAction(nextProcessIdentifier));
    return nextProcessIdentifier;
  }

  yield put(setProcessPreload(null));
  return null;
}

function* openProcess({
  processIdentifier,
  type,
  openBasket,
  usePreload,
  ...action
}) {
  if (type === actions.workflow.CLOSE_PROCESS) {
    const { closeBasket } = action;

    if (closeBasket) {
      yield put(closeDocument());
      yield put(setProxyFolderId(null));
    }
    return;
  }

  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);
  // const processId = `${docAreaName}$NOTSET$${processIdentifier}$${processIdentifier}$NOTSET$1`;
  const processId = processIdentifier;
  const preload = yield select((state) => state.workflow.preload);

  yield takeLatest(actions.workflow.RELOAD_PROCESS, function* ({ force }) {
    try {
      let process;
      if (
        preload != null &&
        // preload.identifier === parseInt(processIdentifier, 10) &&
        preload.identifier === processIdentifier &&
        !force
      ) {
        const { process: preloadedProcess, tasks } = preload;
        process = preloadedProcess;
        yield put(setProcess(preloadedProcess));
        yield put(setProcessTasks(tasks));
      } else {
        yield put(setTaskLoading(true, 'Loading workflow data'));
        process = yield call(fetchProcess, processId);
        yield put(setProcess(process));
        const tasks = yield call(
          fetchProcessTasks,
          processId,
          process.processdefinition
        );
        yield put(setProcessTasks(tasks));
        yield put(setTaskLoading(false));
      }

      if (usePreload) {
        yield fork(performPreload, processIdentifier);
      }

      yield fork(function* () {
        while (true) {
          const { payload } = yield take(actions.repository.SET_DOCUMENTS);
          const { documents } = payload;
          const [firstResource] = documents;

          if (firstResource != null) {
            const { refresourcetype, refresourceid, identifier, resourcetype } =
              firstResource;
            if (documents.length === 1 && refresourcetype === 1) {
              yield put(setProxyFolderId(refresourceid));
            } else if (refresourcetype > 1) {
              const documentIdentifier = parseInt(
                String(refresourceid).split('$')[2],
                10
              );
              yield put(openDocument(documentIdentifier));
            } else if (refresourcetype == null && resourcetype === 2) {
              yield put(openDocument(identifier));
            }
          }
        }
      });

      const {
        basketresourceid,
        processsuspended,
        WFD_DocumentNumber: documentNumber,
        basketresourcedisplaynames,
        processdefinition,
      } = process;

      if (openBasket) {
        yield put(setProxyFolderId(basketresourceid));
      }

      // if (WFD_DocumentNumber != null) {
      //   const [dn] = new String(WFD_DocumentNumber).split('-');
      //   const documentIdentifier = parseInt(dn);
      //   yield put(openDocument(documentIdentifier));
      // }

      yield takeLatest(
        actions.workflow.UPDATE_TASK,
        function* ({ taskId, callback: updateCallback }) {
          const tasks = yield select(getProcessTasks);
          const task = tasks.find((t) => t.id === taskId);

          try {
            yield put(setTaskLoading(true, 'Updating task properties'));

            yield call(updateTask, taskId, task, false);
            yield put(
              notify(
                'The task has been saved. You could finish the workflow later.',
                'success'
              )
            );
            yield spawn(updateCallback);
          } catch (err) {
            yield put(
              notify(`Error while submiting task ${err.message}`, 'error')
            );
          } finally {
            yield put(setTaskLoading(false));
          }
        }
      );

      yield takeLatest(
        actions.workflow.FULFIL_TASK,
        function* ({ taskId, callback: taskCallback }) {
          const tasks = yield select(getProcessTasks);
          const task = tasks.find((t) => t.id === taskId);

          const { assignmentorgentityid, dispatchers, processinstanceid } =
            task;

          if (processId !== processinstanceid) {
            return;
          }

          const defaultPositionId = yield select(getDefaultPositionId);
          const isProxy = !assignmentorgentityid.includes(defaultPositionId);

          try {
            yield put(setTaskLoading(true, 'Loading task submission'));
            if (
              !isProxy &&
              assignmentorgentityid.filter(
                (id) => id !== defaultPositionId && !dispatchers.includes(id)
              ).length > 0
            ) {
              yield call(updateTask, taskId, task);
            } else {
              yield call(endTask, taskId, task);
            }

            const nextProcessId = yield select(
              (state) => state.workflow.nextProcessId
            );
            if (task.processinstanceid === nextProcessId) {
              yield put(setNextProcessId(null));
            }

            yield put(
              notify(
                `Your part of workflow ${basketresourcedisplaynames?.join()} has been finished!`,
                'success'
              )
            );

            yield spawn(taskCallback, nextProcessId);
            yield put(reloadProcess(true));
          } catch (err) {
            yield put(
              notify(
                `Failure while submitting your task, reason: ${err.message}`,
                'error'
              )
            );
            console.error(err);
          } finally {
            yield put(setTaskLoading(false));
          }
        }
      );

      if (processsuspended) {
        yield takeEvery(actions.workflow.RESUME_PROCESS, function* () {
          yield callApi(workflowApi.resumeProcess, docareaName, processId);
          yield put(fetchProcesses());
          yield put(reloadProcess());
        });
      } else {
        yield takeEvery(actions.workflow.SUSPEND_PROCESS, function* () {
          yield callApi(workflowApi.suspendProcess, docareaName, processId);
          yield put(fetchProcesses());
          yield put(reloadProcess());
        });
      }

      yield takeLatest(
        actions.workflow.FETCH_PROCESS_HISTORY,
        fetchProcessHistory,
        documentNumber
      );
      yield takeLatest(
        actions.workflow.FETCH_PROCESS_TASKS,
        function* ({ callback: tasksCallback }) {
          const tasks = yield call(
            fetchProcessTasks,
            processId,
            processdefinition
          );
          yield put(setProcessTasks(tasks));
          yield spawn(tasksCallback, tasks);
        }
      );
      // yield takeEvery(CANCEL_PROCESS, function* () {
      //   try {
      //     yield callApi(workflowApi.endProcess, docareaName, processId);
      //     yield put(fetchProcesses());
      //     yield put(reloadProcess());
      //   } catch (err) {
      //     yield put(
      //       notify(`Failed to abort process, reason: ${err.message}`, 'error')
      //     );
      //   }
      // });

      yield put(setOpenedProcessId(process.processidentifier));
    } catch (err) {
      yield put(notify(`Error: ${err.message}`, 'error'));
    } finally {
      yield put(setTaskLoading(false));
    }
  });

  yield put(reloadProcess());
}

function* processAddReferencesBasket(processId, documents) {
  const process = yield call(fetchProcess, processId);
  // yield put(setProcess(process));
  const { basketresourceid } = process;

  const projectService = yield getContext('projectService');
  const propertiesMap = yield call(projectService.getProperties);

  const editableProperties = propertiesMap
    .filter((p) => p.editable && p.updatable)
    .map((p) => p.name);

  yield all(
    documents.map((document) => {
      const properties = prepareResource(document, propertiesMap);
      const filteredProperties = properties.filter((p) =>
        editableProperties.includes(p.name)
      );

      try {
        folderApi.createReference(
          basketresourceid,
          document.id,
          filteredProperties
        );
      } catch (err) {
        console.error(err);
      }

      return null;
    })
  );
}

function* createProcess(processDefinition, process = {}) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  const localPropertiesService = yield getContext('localPropertiesService');
  const propertiesMap = yield call(
    localPropertiesService.getWorkflowProperties,
    processDefinition
  );
  const properties = prepareResource(process, propertiesMap);

  try {
    yield put(setWorkflowLoading(true, 'Initiating workflow'));
    const processId = yield callApi(
      workflowApi.postProcess,
      docareaName,
      processDefinition,
      properties
    );

    return processId;
  } catch (err) {
    console.error(err);
    yield put(
      notify(`Creating new process failed with reason: ${err.message}`, 'error')
    );
    return null;
  } finally {
    yield put(setWorkflowLoading(false));
  }
}

function* taskFulfilmentFlow(...processIds) {
  const endTaskTask = yield fork(function* () {
    let ended = false;
    while (!ended) {
      const { callback: taskCallback } = yield take(
        actions.workflow.FULFIL_TASK
      );
      yield put(setTaskLoading(true, 'Loading task submission'));
      const ts = yield select(getProcessTasks);
      const endTasks = ts.map((t) => call(endTask, t.id, t));

      try {
        yield callSequence(endTasks);
        yield spawn(taskCallback);

        ended = true;
        yield put(requestDocuments());
        yield put(notify(`Workflow has been initiated.`, 'success'));
      } catch (err) {
        console.error(err);
        yield put(
          notify(`Error while submiting task ${err.message}`, 'user-error')
        );
      } finally {
        yield put(setTaskLoading(false));
      }
    }
  });

  const action = yield take([
    actions.workflow.OPEN_PROCESS,
    actions.workflow.CANCEL_PROCESS,
  ]);

  if (action.type === actions.workflow.CANCEL_PROCESS) {
    try {
      yield put(setWorkflowLoading(true, 'Cancelling workflow.'));

      const sagas = processIds.map((processId) => {
        const [docarea] = processId.split('$');
        return callApi(workflowApi.endProcess, docarea, processId);
      });

      yield all(sagas);

      if (action.callback != null) {
        yield spawn(action.callback);
      }
    } catch (err) {
      yield put(
        notify(`Failed to abort process, reason: ${err.message}`, 'error')
      );
    } finally {
      yield put(setWorkflowLoading(false, 'Initiating workflow'));
    }
  }

  yield cancel(endTaskTask);
}

function* createProcessInstantStart({
  processDefinition,
  callback,
  autoStart,
}) {
  const selectedDocuments = yield select(getSelectedDocuments);

  const createProcesses = selectedDocuments.map(function* (d) {
    const id = yield call(createProcess, processDefinition, {
      folderId: d.id,
    });
    return { id, documents: [d] };
  });

  const processes = yield all(createProcesses);
  const processIds = processes.map((proc) => proc.id);

  const referenceProcessBaskets = processes.map(({ id, documents }) =>
    // eslint-disable-next-line redux-saga/yield-effects
    call(processAddReferencesBasket, id, documents)
  );

  if (processIds.length === 0) {
    return;
  }

  if (autoStart) {
    yield spawn(callback);
  } else {
    yield put(
      setTaskLoading(true, 'Loading available tasks of created workflows')
    );
    const tasks = yield call(fetchProcessTasks, processIds, processDefinition);
    yield put(setTaskLoading(false));

    if (tasks.length > 0) {
      yield put(setProcessTasks(tasks));
      const [task] = tasks;
      yield spawn(callback, task);

      yield takeEvery(
        actions.workflow.UPDATE_PROCESS_TASK,
        function* ({ propertyName, value }) {
          yield put(updateAllProcessTasks(propertyName, value));
        }
      );

      yield all(referenceProcessBaskets);
      yield call(taskFulfilmentFlow, ...processIds);
    } else {
      yield put(
        'Workflow has been initiated but there is not a start task for you.',
        'warning'
      );
    }
  }
}

function* createFolderProcess({ processDefinition, callback, autoStart }) {
  const selectedFolder = yield select(getSelectedFolder);

  const { id: folderId, Providers } = selectedFolder;

  let processData = {
    folderId,
  };

  const isSignOff = processDefinition === 'SignOff_Main';
  if (isSignOff) {
    processData = {
      ...processData,
      basketresourceid: folderId,
      WF_Providers: Providers,
    };
  }

  const id = yield call(createProcess, processDefinition, processData);

  if (id == null) {
    return;
  }

  if (autoStart) {
    yield spawn(callback);
  } else {
    yield put(
      setTaskLoading(true, 'Loading available tasks of created workflows')
    );
    const tasks = yield call(fetchProcessTasks, [id], processDefinition);
    yield put(setTaskLoading(false));

    if (tasks.length > 0) {
      yield put(setProcessTasks(tasks));
      const [task] = tasks;
      yield spawn(callback, task);

      yield call(taskFulfilmentFlow, id);
    } else {
      yield put(
        'Workflow has been initiated but there is not a start task for you.',
        'warning'
      );
    }
  }
}

function* fetchUserFinishedTasks({ page, size, orderBy, callback }) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  const projectService = yield getContext('projectService');

  const taskHistoryProperties = yield call(
    projectService.getTaskHistoryProperties
  );

  const processName = yield call(getDefaultProcessName);

  if (processName == null) {
    return [];
  }

  try {
    const [totalCount, items] = yield all([
      callApi(
        taskHistoryApi.getUserFinishedTasksCount,
        docareaName,
        processName
      ),
      callApi(
        taskHistoryApi.getUserFinishedTasks,
        docareaName,
        processName,
        taskHistoryProperties.map((p) => p.query),
        { number: page, size },
        orderBy
      ),
    ]);

    const tasks = resourceCorrectionOfNscale(items, taskHistoryProperties);
    yield put(setTaskHistory(tasks));
    yield spawn(callback, tasks, totalCount);
    return tasks;
  } catch (err) {
    console.error(err);
    return [];
  }
}

function* fetchInitiatedProcesses() {
  const processName = yield call(getDefaultProcessName);

  if (processName == null) {
    return [];
  }

  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);
  const localPropertiesService = yield getContext('localPropertiesService');
  const propertiesMap = yield call(
    localPropertiesService.getWorkflowProperties,
    processName
  );

  try {
    yield put(setWorkflowLoading(true, 'Loading processes initiated by user.'));
    const [processesResult] = yield all([
      callApi(
        workflowApi.getUserProcesses,
        docareaName,
        processName,
        propertiesMap.map((p) => p.query)
      ),
      // callApi(
      //   taskHistoryApi.getUserInitiatedTasks,
      //   docarea,
      //   processDefinition,
      //   taskHistory.map((p) => p.query),
      //   { size: 10, number: 1 }
      // ),
    ]);

    // const historyTasks = historyResult.map((r) =>
    //   buildDocument(r, taskHistoryParseFunctions)
    // );

    const processes = resourceCorrectionOfNscale(
      processesResult,
      propertiesMap
    );

    // yield put(setTaskHistory(historyTasks));
    yield put(setProcesses(processes));
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setWorkflowLoading(false));
  }
}

function* workflowFlow() {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);
  const processDefinitions = yield callApi(
    workflowApi.getProcessDefinitions,
    docareaName
  );

  yield put(
    setProcessDefinitions(
      processDefinitions.sort((a, b) =>
        String(a.displayNameId).localeCompare(b.displayNameId)
      )
    )
  );
}

function* fetchFolderTasks(folderId, properties = [], parameter) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);
  const requestedProps = properties
    .map((prop) => prop.query)
    .filter((name) => name != null);

  yield put(
    setWorkflowLoading(true, 'Loading workflows related to opened folder.')
  );

  yield put(closeProcess(false));

  try {
    const { items } = yield call(
      workflowApi.getTasksByFolder,
      docareaName,
      folderId,
      parameter,
      requestedProps
    );

    const tasks = resourceCorrectionOfNscale(items, properties);
    return tasks;
  } catch (err) {
    console.warn(err);
  } finally {
    yield put(setWorkflowLoading(false));
  }

  return null;
}

export default function* workflowSaga() {
  yield fork(workflowFlow);
  yield takeLatest(actions.workflow.END_PROCESS, endProcess);
  yield takeLatest(
    [actions.workflow.OPEN_PROCESS, actions.workflow.CLOSE_PROCESS],
    openProcess
  );
  yield takeLatest(
    [actions.workflow.PRELOAD_PROCESS, actions.workflow.OPEN_PROCESS],
    function* ({ processIdentifier, type }) {
      if (type === actions.workflow.PRELOAD_PROCESS) {
        yield call(preloadProcess, processIdentifier);
      }
    }
  );
  yield takeEvery(actions.workflow.CREATE_PROCESS, function* ({ payload }) {
    const { processDefinition, callback, options } = payload;
    const { autoStart, activeFolderBasket } = options;

    if (activeFolderBasket) {
      return yield call(
        createFolderProcess({
          processDefinition,
          autoStart,
          callback,
        })
      );
    } else {
      return yield call(createProcessInstantStart, {
        processDefinition,
        autoStart,
        callback,
      });
    }
  });
  yield takeEvery(
    actions.workflow.CREATE_PROCESS_INSTANT_START,
    createProcessInstantStart
  );
  yield takeLeading(
    actions.workflow.CREATE_FOLDER_PROCESS,
    createFolderProcess
  );

  yield takeEvery(
    actions.workflow.FETCH_INITIATED_PROCESSES,
    fetchInitiatedProcesses
  );
  yield takeEvery(actions.workflow.FETCH_TASK_HISTORY, fetchUserFinishedTasks);
  yield takeLatest(actions.workflow.FETCH_PROCESSES, function* ({ callback }) {
    const processName = yield call(getDefaultProcessName);

    if (processName == null) {
      yield put(setProcesses([]));
      yield spawn(callback, []);
      return;
    }

    yield put(setWorkflowLoading(true, 'Loading user tasks'));
    const processes = yield call(fetchAssignedTasks, processName);
    yield put(setWorkflowLoading(false));

    yield put(setProcesses(processes));
    yield spawn(callback, processes);
  });

  yield takeLatest(
    actions.repository.SET_PROXY_FOLDER_ID,
    function* ({ folderId, contextOnly }) {
      if (folderId == null || contextOnly) {
        return;
      }

      const docareaService = yield getContext('docareaService');
      const docareaName = yield call(docareaService.getDocareaName);
      // const identifier = parseInt(folderId.split('$')[2], 10);

      const projectService = yield getContext('projectService');
      const taskHistoryProperties = yield call(
        projectService.getTaskHistoryProperties
      );

      yield takeEvery(
        actions.workflow.FETCH_FOLDER_WORKFLOW_STATUS,
        function* ({ parameter, callback }) {
          try {
            setWorkflowLoading(true, 'Loading workflow statuses of folder.');

            const projectWorkflows = yield call(projectService.getWorkflows);
            const availableProcessDefinitions = projectWorkflows.map(
              (wf) => wf.processName
            );

            const localPropertiesService = yield getContext(
              'localPropertiesService'
            );
            const properties = yield call(
              localPropertiesService.getAllWorkflowsProperties,
              availableProcessDefinitions
            );

            const [result, processes] = yield all([
              callApi(
                taskHistoryApi.getByResourceId,
                docareaName,
                folderId,
                taskHistoryProperties.map((p) => p.query)
              ),
              call(fetchFolderTasks, folderId, properties, parameter),
            ]);

            const taskHistoryEntries = resourceCorrectionOfNscale(
              result,
              taskHistoryProperties
            ).filter(
              (history) =>
                String(history.Task_Name).toLowerCase() !== 'start' &&
                (availableProcessDefinitions.includes(history.Process_Name) ||
                  (history.Decision?.length > 1 &&
                    history.Task_Finisher != null))
            );

            const notReviewedStatuses = _(processes)
              .filter(
                (process) =>
                  String(process.taskname).toLowerCase() !== 'start' &&
                  availableProcessDefinitions.includes(
                    process.processdefinition
                  ) &&
                  taskHistoryEntries.findIndex(
                    (s) => s.Task_Key === process.id
                  ) === -1
              )
              .map((process) => {
                const {
                  responsible,
                  processinstanceid,
                  assignedpositionid,
                  processdefinition,
                } = process;
                return initiateResource(
                  {
                    responsible,
                    Task_Finisher: assignedpositionid,
                    Process_Key: processinstanceid,
                    Process_Name: processdefinition,
                  },
                  taskHistoryProperties
                );
              })
              .value();

            const folderTasks = _(processes)
              .filter(
                (process) =>
                  !(
                    availableProcessDefinitions.includes(
                      process.processdefinition
                    ) ||
                    !Array.isArray(process.assignmentorgentityid) ||
                    process.assignmentorgentityid.length === 0
                  )
              )
              .value();

            if (folderTasks.length > 0) {
              const { processinstanceid } = folderTasks[0];
              yield put(openProcessAction(processinstanceid, false, false));
            }

            const status = [...notReviewedStatuses, ...taskHistoryEntries];

            yield put(setWorkflowStatus(status));

            yield spawn(callback, status);
          } catch (err) {
            console.error(err);
          } finally {
            setWorkflowLoading(false);
          }
        }
      );
    }
  );

  yield takeLeading(
    actions.workflow.EXPORT_WORKFLOW_STATUS_EXCEL,
    function* ({ payload }) {
      const { name } = payload;
      const workflowStatus = yield select(getWorkflowStatus);

      const projectService = yield getContext('projectService');
      const projectWorkflows = yield call(projectService.getWorkflows);

      const localPropertiesService = yield getContext('localPropertiesService');
      const properties = yield call(
        localPropertiesService.getAllWorkflowsProperties,
        projectWorkflows
          .filter((wf) => wf.workflowStatus)
          .map((wf) => wf.processName)
      );

      yield call(
        exportData,
        workflowStatus,
        properties,
        name || 'workflows_status',
        []
      );
    }
  );
}
