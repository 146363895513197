import { DateTime } from "luxon";
import dependencyType from "./dependencyType";
import filterGroup from "./filterGroup";
import PropertyType from "./propertyType";
import valuesetSource from "./valuesetSource";
import PrincipalType from "./principalType";
import structure, { Structure } from "../structure/structure";
import structureType from "../structure/structureType";
import { trimUINumber } from "../utils";
import widget, { Widget } from "../widget/widget";
import conditionalElement from "../conditionalElement";
import { ConditionalProperty } from "./conditionalProperty";
import { propertyType } from "../generator";

/**
 * @typedef
 * @category Property
 */
export type ValuesetOption = {
  label: string;
  filter?: string;
  value: string | number;
  obsolete?: boolean;
};

type SortRow = {
  values: Record<string, any>;
};

export interface RenderCallback {
  (value: any, label: string, ...args: any[]): any;
}

export interface AnticipatedValue {
  value: string | number;
  watermark: boolean;
  listingColor: string;
}

export type SortTypeCallback = (
  a: SortRow,
  b: SortRow,
  columnId: string
) => number;

/**
 * @interface
 * @category Property
 */
export interface Property {
  type: PropertyType;
  disabled: boolean;
  updatable: boolean;
  filterable: boolean;
  editable: boolean;
  editableReference: boolean;
  usePreset: boolean;
  required: boolean;
  information: boolean;
  label: string;
  name: string;
  query: string | null;
  accessor: string;
  Header: string;
  dependency: string | null;
  dependencyLabel: string | null;
  dependencyType: dependencyType;
  cellRender: RenderCallback;
  render?: null | RenderCallback;
  optionsFilterProperty: string | null;
  system: boolean;
  defaultValue: any;
  defaultFilterValue: any;
  tableColumn: boolean;
  columnWidth: number;
  taskHistory: boolean;
  fuzzySearch: boolean;
  description: string | null;
  customClassName: string | null;
  filterGroup: filterGroup;
  sortable: boolean;
  defaultSort: boolean;
  keyword: boolean;
  addingOptionsAllowed: boolean;
  addingOptions: Array<ValuesetOption>;
  guide: boolean;
  guideText: string | null;
  characterLimit: number;
  folderProperty: Property | null;
  folder: boolean;
  writePermissionProperty: string | null;
  readPermissionProperty: string | null;
  virtualFolder: boolean;
  alternateName: string | null;
  alternateLabel: string | null;
  structures: Array<Structure>;
  systemUpdatable: boolean;
  boundary: any;
  widgets: Array<Widget>;
  identifier: boolean;
  resourceType: number;
  translation: string | null;
  protectedProperty: boolean;
  handleAsMulti: boolean;
  multiple: boolean;
  containsUsername: boolean;
  importable: boolean;
  folderType: string | null;
  update(v: any, resource: any, defaultPositionId?: string): any;
  updateImpact(
    v: any,
    resource: any,
    defaultPositionId: string
  ): Array<Record<string, any>>;
  filter(name: string, value: any): any;
  sortType: SortTypeCallback;
  format(value: any): any;
  filterOptionsByFolder(propertyName: string): Property;

  /**
   * Sets render method of the property for table view.
   */
  tableCell(render: RenderCallback): Property;
  buildOptions(
    options: Array<ValuesetOption>,
    filter: Record<string, any>,
    contextResourceId: string
  ): Array<ValuesetOption>;
  getTooltip(): string;
  isHidden(filter: Record<string, any>): boolean;
  isDependant(): boolean;
  useAlternateProperty(alternateLabel: string, alternateName: string): Property;
  canWrite(defaultPositionId: string, filter: Record<string, any>): boolean;
  canRead(defaultPositionId: string, filter: Record<string, any>): boolean;
  prepareValue(value: any): any;
  parseValue(value: any): any;
  optionBuilder(optionBuilder: () => Array<ValuesetOption>): Property;

  /**
   * Sets property as readonly. Property is muted and it is not possible to update it.
   * @param {boolean} updatable
   * @param {boolean} disabled
   * */
  readonly(updatable?: boolean, disabled?: boolean): Property;

  /**
   * Excludes property from presets calculation and presets user settings.
   * @param {boolean} excludePreset
   * */
  excludePreset(excludePreset?: boolean): Property;

  /**
   * Excludes property from repository filters.
   * @param {boolean} exclude
   * */
  excludeFilter(exclude?: boolean): Property;

  /**
   * Excludes property from editing forms.
   * @param {boolean} exclude
   * */
  excludeForm(exclude?: boolean): Property;
  excludeReferenceForm(exclude?: boolean): Property;

  /**
   * Adds property into keywords of the target resource.
   * @param {boolean} keyword
   * */
  includeKeyword(keyword?: boolean): Property;
  includeTable(tableColumn?: boolean, columnWidth?: number): Property;

  /**
   * Excludes property from the table view.
   * @param {boolean} tableColumn
   * */
  excludeTable(tableColumn?: boolean): Property;

  /**
   * Excludes property from the task history view in users tasks.
   * Task history entry stores resource id. Based on that there are additional
   * columns shown as resolved references.
   * @param {boolean} taskHistory
   * */
  excludeTaskHistory(taskHistory?: boolean): Property;

  /**
   * Decides, if property should be searched by a fuzzy searching algorithm.
   * If false, property will be searched by a strict search.
   * @param {boolean} fuzzySearch
   * */
  useFuzzySearch(fuzzySearch?: boolean): Property;

  /**
   * Includes property in initial project/cosmos guide.
   * That means if user enters cosmos for the first time he is required
   * to complete guide with specified properties as selection questions.
   * @param {string} guideText Additional text in the guide.
   * */
  includeGuide(guideText?: string): Property;

  /**
   * Includes property in folder view repository filters.
   * @param {boolean} exclusively If false property is used also for root folder repository filters.
   * */
  includeFolderFilter(exclusively?: boolean): Property;
  includeVirtualFolder(virtualFolder?: boolean): Property;

  /**
   * Sets property as optional in the resource editing form.
   * @param {boolean} required
   * */
  optional(required?: boolean): Property;

  /**
   * Sets property as informations. That means that property
   * is displayed in the sidebar when resource is selected and also as
   * a default table column.
   * */
  informational(): Property;

  /**
   * Sets property as identifier holder when using import
   * and distributing resources into folders.
   * @param {boolean} identifier
   * */
  identifiesResource(identifier: boolean): Property;

  /**
   * Excludes property from sidebar information of the resource
   * and also from default table view column.
   * @param {boolean} exclude
   * */
  excludeInformational(exclude: boolean): Property;

  /**
   * Creates property dependency.
   * @param {string} propertyName The name of property to which current property depends.
   * @param {string} propertyLabel Label of the property to depend on.
   * @param {string} depTyp Dependency type - could be strict or loose. Strict dependency
   * will not displayed any values when dependency is empty. Loose type will display
   * all values when dependency is empty.
   * */
  dependingOn(
    propertyName: string,
    propertyLabel?: string,
    depType?: dependencyType
  ): Property;
  // formatted(formatter: (param: any) => any): Property;
  validate(value: any): boolean;

  /**
   * @deprecated
   * Sets attribute name of nscale backend property.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   * */
  attribute(attributeName: string): Property;

  /**
   * Provides an callback function when resource property is being updated.
   * @param {callback} update The callback function when update event occurs on the resource property.
   */
  onUpdate(
    update: (v: any, resource: any, defaultPositionId: string) => any
  ): Property;

  /**
   * Provides an callback function when resource property is being updated resulting
   * into mutliple resource properties update.
   * @param {callback } update The callback function when update event occurs on the resource property.
   */
  onUpdateImpact(
    updateImpact: (
      v: any,
      resource: any,
      defaultPositionId: string
    ) => Array<Record<string, any>>
  ): Property;

  /**
   * Provides an callback function when repository is filtered using this property.
   * @param {callback} filter The callback function when filter event occurs on the resource.
   */
  onFilter(filter: (v: any) => any): Property;

  /**
   * Changes type of property to selectable. By using valueset options will
   * be provided to user to select from.
   * @param {string} attributeName Name of the attribute in the nscale backend.
   * @param {string} valuesetName Name of the valueset.
   * @param {string} source Valueset source (custom, valueset...).
   */
  selectableAttribute(
    attributeName: string,
    valuesetName: string,
    source: valuesetSource
  ): SelectableAttributeProperty;

  /**
   * Changes type of property into suggestive similar to selectable attribute.
   * With option to enter own custom values. Value does not need to be selected from valueset.
   * @see {@link selectableAttribute} See Similar approach.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   */
  suggestiveAttribute(attributeName: string): SelectableAttributeProperty;

  /**
   * Changes type of property into selectable principal. Property will hold
   * principal position id and provides search in active directory.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   * @param {string} principalType Type of the principal to fetch.
   */
  selectablePrincipalAttribute(
    attributeName: string,
    principalType: PrincipalType
  ): PrincipalAttributeProperty;

  /**
   * Changes type of property into selectable project member.
   * Property will hold principal position id and provides search in project members.
   * Could be limited to specific group names of the project.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   * @param {string} [principalType=PrincipalType.USER] Type of the principal to fetch.
   * @param {...string} [groupNames] Names of groups which would be used to search.
   */
  selectableMemberAttribute(
    attributeName: string,
    principalType?: PrincipalType,
    ...groupNames: Array<string>
  ): PrincipalAttributeProperty;

  /**
   * Changes type of property into boolean handling component.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   */
  booleanAttribute(attributeName: string): BooleanAttributeProperty;

  /**
   * Changes type of property into multi value attribute.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   * @param {boolean} [allowMultiSelection=true] Sets property to mimick single value
   * when false even when stored as multi value attribute.
   */
  multiAttribute(
    attributeName: string,
    allowMultiSelection?: boolean
  ): Property;

  /**
   * Changes type of property into selectable resource. Which
   * allows to aggregate search in repository folder for values of property.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   */
  selectableResourceAttribute(
    attributeName: string
  ): SelectableAttributeProperty;

  /**
   * Changes type of property into selectable module element. Which then provides
   * seletable component for module document/folder properties, valueset items
   * or folder types.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   */
  selectableModuleElementAttribute(
    attributeName: string,
    elementType: string
  ): SelectableModuleElementProperty;

  permittedUsersAttribute(attributeName: string): Property;

  /**
   * Sets property as a structural for repository root of project explorer/tree explorer.
   * @param {number} level Level number in the folder structure.
   * @param {callback} [structureCallback] Callback to update structure behaviour.
   */
  structured(
    level: number,
    structureCallback: (s: Structure) => Structure
  ): Property;

  /**
   * Sets property as a structural for folder explorer/tree explorer.
   * @param {number} level Level number in the folder structure.
   * @param {callback} [structureCallback] Callback to update structure behaviour.
   */
  folderStructured(
    level: number,
    structureCallback: (s: Structure) => Structure
  ): Property;

  /**
   * Changes type of the property to text area.
   * @param {number} [characterLimit] Number of characters which is allowed to enter into field.
   */
  textArea(characterLimit: number): Property;

  /**
   * Changes type of the property to rich text area.
   * @param {number} [characterLimit] Number of characters which is allowed to enter into field.
   */
  richTextArea(characterLimit: number): Property;

  /**
   * Sets a character limit when using textarea or richtext.
   * @param {number} [characterLimit] Number of characters which is allowed to enter into field.
   */
  limit(characterLimit: number): Property;

  /**
   * Sets default value for a property when resource is being saved.
   * @param {string} value Default value.
   */
  default(value: any): Property;

  getDefaultValue(...args: any[]): any;

  /**
   * Sets default filter value for a property.
   * @param {string} value Value of a filter.
   */
  defaultFilter(value: any): Property;

  /**
   * Value of the property will be set from current user data when document created.
   * @param {string} propertyName Property name of user data which will be used for value.
   * @param {string} fallback Fallback value when user data do not contains property.
   */
  defaultByUserProperty(propertyName: string, fallback: any): Property;

  /**
   * Changes property type to numeric.
   * @param {string} attributeName Name of the attribute in the nscale backend.
   * @param {number} min Minimal value of the numeric input component.
   * @param {number} max Maximal value of the numeric input component.
   * @param {number} decimalPlaces Decimal places of the numeric input component.
   */
  numericAttribute(
    attributeName: string,
    min?: number,
    max?: number,
    decimalPlaces?: number
  ): NumericAttributeProperty;

  /**
   * Sets property to execute NQL pidResolve. Resolves user name from provided
   * principal id.
   * @param {string} resolveProperty Property name which contains principal id as a value.
   */
  resolveUsername(...resolveProperty: Array<string>): Property;

  /**
   * Sets property to execute NQL pidResolve. Resolves
   * principal id from provided principal id.
   * @param {string} resolveProperty Property name which contains principal id as a value.
   */
  resolvePositionId(resolveProperty: string): Property;

  /**
   * Value of the propertie will be trimmed of UIxxxxxx number. Use for user names.
   */
  excludeUINumber(containsUsername?: boolean): Property;

  /**
   * Sets property to execute NQL refResolve as an value of it.
   * @param {string} resolveProperty Property from referenced resource that will be fetched (eg. displayname).
   * @param {string} referenceType Type of the reference.
   * @param {string} referenceProperty Property which contains reference key to resource (eg. resourceid).
   */
  resolveReference(
    resolveProperty: string,
    referenceType: number,
    referenceProperty: string
  ): Property;

  /**
   * Property would hold group name based on the attribute value provided.
   * @param {string} resolveProperty Property name which contains principal id of the group.
   */
  groupResolve(resolveProperty: string): Property;

  /**
   * Sets specific query for retrieving property value from nscale backend.
   * @param {string} query Query that should be used to retrieve property value using NQL.
   */
  queryProperty(query: string | null): Property;

  /**
   * Sets description of the property. Could be react node component as well.
   * @param {string} description Text or React component.
   */
  describe(description: string): Property;

  /**
   * Adds separator below property in repository filters.
   */
  separatorBelow(): Property;

  /**
   * Adds property into additional filter of repository filters.
   * User has to expand additional filters to see it instead of directly approaching it.
   */
  additionalFilter(): Property;

  /**
   * Disables sorting of the property in the table view.
   */
  disableSorting(): Property;

  /**
   * Enable sorted by this property by default.
   */
  defaultSorted(): Property;

  /**
   * Sets property as an alias for different attribute.
   * @param {string} attribue Name of the attribute in the nscale backend.
   */
  attributeAlias(attribute: string): Property;

  /**
   * Describes by a callback how property behaves when used
   * in folder resource type form.
   * @param {callback} build Callback to update property behaviour for folder.
   */
  asFolderProperty(build: (folderProp: Property) => Property): Property;

  /**
   * Changes type of the property into date attribute.
   * @param {string} attributeName Name of the attribute in the nscale backend.
   * @param {string} dateZone Time zone.
   */
  dateAttribute(
    attributeName: string,
    dateZone?: string
  ): DateAttributeProperty;

  /**
   * Allow system to update property in the background
   * if the property is set as system/non editable.
   */
  systemUpdates(): Property;

  /**
   * Adds widget based on the property aggregate query.
   * @param {string} title Title of the widget in the dashboad.
   * @param {widgetType} type Type of the widget.
   */
  widget(title: string, type: string): Property;

  /**
   * Sets property to be translated by translation path.
   * @param {string} translation Translation path.
   */
  translate(translation: string): Property;

  /**
   * Sets property to be protected. The editing functionalities in project editor are limited then.
   * @param {boolean} protectedProperty Sets property to be protected or not.
   */
  protect(protectedProperty: boolean): Property;

  /**
   * This decorator turns regular property into conditional without using "conditionalProperty" generator.
   */
  controlVisibility(): ConditionalProperty;

  /**
   * Set if the property should appear in the documents/folders import template.
   * @param {boolean } include
   */
  includeImportTemplate(include: boolean): Property;

  sortWith(sortType: SortTypeCallback): Property;
}

/**
 * @interface
 * @category Property
 * @extends Property
 */
export interface SelectableAttributeProperty extends Property {
  multiple: boolean;
  valuesetName: string;
  valuesetSource: string;
  masterValuesetName: string | null;
  options: Array<ValuesetOption>;
  invalidatingOptions: boolean;
  anticipatedValues: AnticipatedValue[];

  insertable(
    addingOptionsAllowed: boolean,
    options: Array<ValuesetOption>
  ): SelectableAttributeProperty;
  slaveOf(masterValuesetName: string): SelectableAttributeProperty;
  valueset(options: Array<ValuesetOption>): SelectableAttributeProperty;
  radio(r: boolean): SelectableAttributeProperty;
  invalidatableOptions(
    invalidatingOptions: boolean
  ): SelectableAttributeProperty;
  anticipate(
    value: string,
    watermark: boolean,
    listingColor: string
  ): SelectableAttributeProperty;
}

export interface SelectableModuleElementProperty extends Property {
  elementType: string;
  type: propertyType.MODULE_ELEMENT_SELECT;
}

/**
 * @interface
 * @category Property
 * @extends Property
 */
export interface PrincipalAttributeProperty extends Property {
  principalType: PrincipalType;
  groupNames: Array<string>;
  insertable(addingOptionsAllowed: boolean): PrincipalAttributeProperty;
}

/**
 * @interface
 * @category Property
 * @extends Property
 */
export interface BooleanAttributeProperty extends Property {
  neutral: boolean;
  labelLeft: string | null;
  labelRight: string | null;

  useYesNo(): BooleanAttributeProperty;
  useYesNoNeutral(): BooleanAttributeProperty;
  useCustom(leftLabel: string, rightLabel: string): BooleanAttributeProperty;
}

/**
 * @interface
 * @category Property
 * @extends Property
 */
export interface NumericAttributeProperty extends Property {
  decimalPlaces: number;
}

/**
 * @interface
 * @category Property
 * @extends Property
 */
export interface DateAttributeProperty extends Property {
  filterRange: boolean;
  dateZone: string;
  dateOnly: boolean;
  reminderCreator: boolean;
  minValueResolve: null | (() => number);

  excludeTime(): DateAttributeProperty;
  minValue(callback: () => any): DateAttributeProperty;
  dateRangeFilter(): DateAttributeProperty;
  useReminderCreator(reminderCreator: boolean): DateAttributeProperty;
}

/**
 * Property Generator.
 * @category Property
 * @author Patrik Pancisin
 * @class property
 * @classdesc Basic building block of cosmos.
 * @param {string} label - The label of the property displayed in cosmos.
 * @param {string} name - The name of property which is loaded from nscale backend.
 * @param {number} resourceType - Target resource type of the property could be 1 or 2.
 */
const property = (
  label: string,
  name?: string,
  resourceType: number = 2
): Property => /** @lends property */ ({
  type: PropertyType.TEXT,
  disabled: false,
  updatable: true,
  filterable: true,
  editable: true,
  editableReference: true,
  usePreset: true,
  required: true,
  information: false,
  label,
  name: name || label,
  query: name || label,
  accessor: name || label,
  Header: label,
  dependency: null,
  dependencyLabel: null,
  dependencyType: dependencyType.STRICT,
  cellRender(value, label, ...args) {
    if (this.render != null) {
      const formattedLabel = this.format(label);
      return this.render(value, formattedLabel, ...args);
    }

    return this.format(label);
  },
  render: null,
  optionsFilterProperty: null,
  system: false,
  defaultValue: null,
  defaultFilterValue: null,
  tableColumn: true,
  columnWidth: 200,
  taskHistory: false,
  fuzzySearch: false,
  description: null,
  customClassName: null,
  filterGroup: filterGroup.MAIN,
  sortable: true,
  defaultSort: false,
  keyword: true,
  addingOptionsAllowed: false,
  addingOptions: [],
  guide: false,
  guideText: null,
  characterLimit: 0,
  folderProperty: null,
  folder: false,
  writePermissionProperty: null,
  readPermissionProperty: null,
  virtualFolder: false,
  alternateName: null,
  alternateLabel: null,
  structures: [],
  systemUpdatable: false,
  boundary: null,
  widgets: [],
  identifier: false,
  resourceType: resourceType || 2,
  translation: null,
  protectedProperty: false,
  handleAsMulti: false,
  multiple: false,
  containsUsername: false,
  importable: false,
  folderType: null,
  update: (v) => v,
  updateImpact: () => [],
  filter: (name: string, value: any) => ({ filter: { [name]: value } }),
  sortType: (a: SortRow, b: SortRow, columnId: string) => {
    const aVal = a.values[columnId];
    const bVal = b.values[columnId];

    const compare = (comA: any, comB: any) => {
      if (isNaN(comA) || isNaN(comB)) {
        return String(comA).localeCompare(comB);
      }

      return comA - comB;
    };

    if (Array.isArray(aVal) && Array.isArray(bVal)) {
      if (
        aVal.length >= 1 &&
        aVal[0] != null &&
        bVal.length >= 1 &&
        bVal[0] != null
      ) {
        return compare(aVal[0], bVal[0]);
      }
    }

    return compare(aVal, bVal);
  },
  format(value: any): any {
    return value;
  },
  filterOptionsByFolder(propertyName: string): Property {
    this.optionsFilterProperty = propertyName;
    return this;
  },
  /**
   * @instance
   * @description Sets render method of the property for table view.
   */
  tableCell(render): Property {
    this.render = render;
    return this;
  },
  buildOptions(
    options: Array<ValuesetOption>,
    filter = {}
  ): Array<ValuesetOption> {
    const filteredOptions = options.filter(
      (o) => o.label != null && o.label != ""
    );

    if (this.isDependant() && this.dependency != null) {
      const dependencyValue = filter?.[this.dependency.toString()] || [];

      const getOptionsByFilter = (f: any) =>
        filteredOptions
          .filter((o: ValuesetOption) => {
            if (Array.isArray(f)) {
              return f.includes(o.filter as never) && o.label != null;
            }

            return f == o.filter && o.label != null;
          })
          .map((o: ValuesetOption) => ({
            ...o,
            label: o.label
              .replace(`${o.filter} - `, "")
              .replace(`${o.value} - `, ""),
          }));

      if (this.dependencyType === dependencyType.STRICT) {
        return getOptionsByFilter(dependencyValue);
      }

      if (this.dependencyType === dependencyType.LOOSE) {
        return dependencyValue.length <= 0
          ? filteredOptions
          : getOptionsByFilter(dependencyValue);
      }
    }

    return filteredOptions || [];
  },
  getTooltip(): string {
    return this.isDependant()
      ? `This property is depending on ${this.dependencyLabel}, please select that property first.`
      : this.label;
  },
  isHidden(filter: Record<string, any>): boolean {
    return false;
  },
  isDependant(): boolean {
    return this.dependency != null && this.dependency !== "";
  },
  useAlternateProperty(
    alternateLabel: string,
    alternateName: string
  ): Property {
    this.alternateLabel = alternateLabel;
    this.alternateName = alternateName;
    return this;
  },
  canWrite(defaultPositionId: string, filter): boolean {
    if (this.writePermissionProperty != null && filter != null) {
      const userIds = filter["" + this.writePermissionProperty];
      return userIds.includes(defaultPositionId) && !this.isHidden(filter);
    }

    return !this.isHidden(filter);
  },
  canRead(defaultPositionId: string, filter): boolean {
    if (this.readPermissionProperty != null && filter != null) {
      const userIds = filter["" + this.readPermissionProperty];
      return userIds.includes(defaultPositionId);
    }

    return true;
  },
  prepareValue: (value: any) => value,
  parseValue: (value: any) => value,
  optionBuilder(optionBuilder: () => Array<ValuesetOption>): Property {
    this.buildOptions = optionBuilder;
    return this;
  },
  /**
   * @instance
   * @description Sets property as readonly. Property is muted and it is not possible to update it.
   * @param {boolean} updatable
   * @param {boolean} disabled
   * */
  readonly(updatable: boolean = false, disabled: boolean = true): Property {
    this.disabled = disabled;
    this.updatable = updatable;
    this.keyword = false;
    return this;
  },
  /**
   * @instance
   * @description Excludes property from presets calculation and presets user settings.
   * @param {boolean} excludePreset
   * */
  excludePreset(excludePreset: boolean = true): Property {
    this.usePreset = !excludePreset;
    return this;
  },
  /**
   * @instance
   * @description Excludes property from repository filters.
   * @param {boolean} exclude
   * */
  excludeFilter(exclude: boolean = true): Property {
    this.filterable = !exclude;
    return this;
  },
  /**
   * @instance
   * @description Excludes property from editing forms.
   * @param {boolean} exclude
   * */
  excludeForm(exclude: boolean = true): Property {
    this.editable = !exclude;
    this.keyword = !exclude;
    return this;
  },
  excludeReferenceForm(exclude: boolean = true): Property {
    this.editableReference = !exclude;
    return this;
  },
  /**
   * @instance
   * @description Adds property into keywords of the target resource.
   * @param {boolean} keyword
   * */
  includeKeyword(keyword: boolean = true): Property {
    this.keyword = keyword;
    return this;
  },
  includeTable(tableColumn: boolean = true, columnWidth: number): Property {
    this.tableColumn = tableColumn;
    this.columnWidth = columnWidth;
    return this;
  },
  /**
   * @instance
   * @description Excludes property from the table view.
   * @param {boolean} tableColumn
   * */
  excludeTable(tableColumn: boolean = false): Property {
    this.tableColumn = tableColumn;
    return this;
  },
  /**
   * @instance
   * @description Excludes property from the task history view in users tasks.
   * Task history entry stores resource id. Based on that there are additional
   * columns shown as resolved references.
   * @param {boolean} taskHistory
   * */
  excludeTaskHistory(taskHistory: boolean = true): Property {
    this.taskHistory = !taskHistory;
    return this;
  },
  /**
   * @instance
   * @description Decides, if property should be searched by a fuzzy searching algorithm.
   * If false, property will be searched by a strict search. 
   * @param {boolean} fuzzySearch
   */
  useFuzzySearch(fuzzySearch: boolean = false): Property {
    this.fuzzySearch = fuzzySearch;
    return this;
  },
  /**
   * @instance
   * @description Includes property in initial project/cosmos guide.
   * That means if user enters cosmos for the first time he is required
   * to complete guide with specified properties as selection questions.
   * @param {string} guideText Additional text in the guide.
   * */
  includeGuide(guideText: string): Property {
    this.guide = true;
    this.guideText = guideText;
    return this;
  },
  /**
   * @instance
   * @description Includes property in folder view repository filters.
   * @param {boolean} exclusively If false property is used also for root folder repository filters.
   * */
  includeFolderFilter(exclusively: boolean = false): Property {
    this.filterable = true;

    if (this.filterGroup === filterGroup.ADDITIONAL) {
      this.filterGroup = exclusively
        ? filterGroup.FOLDER_ADDITIONAL
        : filterGroup.ADDITIONAL_FOLDER_ADDITIONAL;
    } else {
      this.filterGroup = exclusively
        ? filterGroup.FOLDER
        : filterGroup.MAIN_FOLDER;
    }

    return this;
  },
  includeVirtualFolder(virtualFolder: boolean = true): Property {
    this.virtualFolder = virtualFolder;
    return this;
  },
  /**
   * @instance
   * @description Sets property as optional in the resource editing form.
   * @param {boolean} required
   * */
  optional(required: boolean = false): Property {
    this.required = required;
    return this;
  },
  /**
   * @instance
   * @description Sets property as informations. That means that property
   * is displayed in the sidebar when resource is selected and also as
   * a default table column.
   * */
  informational(): Property {
    this.information = true;
    return this;
  },
  /**
   * @instance
   * @description Sets property as identifier holder when using import
   * and distributing resources into folders.
   * @param {boolean} identifier
   * */
  identifiesResource(identifier = true): Property {
    this.identifier = identifier;
    return this;
  },
  /**
   * @instance
   * @description Excludes property from sidebar information of the resource
   * and also from default table view column.
   * @param {boolean} exclude
   * */
  excludeInformational(exclude = true): Property {
    this.information = !exclude;
    return this;
  },
  /**
   * @instance
   * @description Creates property dependency.
   * @param {string} propertyName The name of property to which current property depends.
   * @param {string} propertyLabel Label of the property to depend on.
   * @param {string} depTyp Dependency type - could be strict or loose. Strict dependency
   * will not displayed any values when dependency is empty. Loose type will display
   * all values when dependency is empty.
   * */
  dependingOn(
    propertyName,
    propertyLabel,
    depType = dependencyType.STRICT
  ): Property {
    this.dependency = propertyName;
    this.dependencyLabel = propertyLabel || propertyName;
    this.dependencyType = depType;
    return this;
  },
  // formatted(formatter: (param: any) => any): Property {
  //   if (formatter != null) {
  //     this.format = formatter;
  //   }
  //   return this;
  // },
  validate(value: any): boolean {
    if (this.required && !this.disabled) {
      if (value == null || value === "") {
        return false;
      }

      if (Array.isArray(value) && (value.length <= 0 || value[0] == null)) {
        return false;
      }
    }

    if (value != null && this.characterLimit > 0) {
      if (Array.isArray(value)) {
        if (value.some((v) => v.length > this.characterLimit)) {
          return false;
        }
      } else if (value.length > this.characterLimit) {
        return false;
      }
    }

    return true;
  },
  /**
   * @instance
   * @deprecated
   * @description Sets attribute name of nscale backend property.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   * */
  attribute(attributeName: string): Property {
    this.name = attributeName;
    this.query = attributeName;
    this.accessor = attributeName;

    return this;
  },
  /**
   * @instance
   * @description Provides an callback function when resource property is being updated.
   * @param {callback} update The callback function when update event occurs on the resource.
   */
  onUpdate(update = (v: any) => v): Property {
    this.update = update;
    // this.update = (...args) => {
    //   return update(this.name, ...args);
    // };
    return this;
  },
  /**
   * Provides an callback function when resource property is being updated resulting
   * into mutliple resource properties update.
   * @param {callback } update The callback function when update event occurs on the resource property.
   */
  onUpdateImpact(updateImpact = () => []): Property {
    this.updateImpact = updateImpact;
    return this;
  },
  /**
   * @instance
   * @description Provides an callback function when repository is filtered using this property.
   * @param {callback} filter The callback function when filter event occurs on the resource.
   */
  onFilter(filter = (v: any) => v): Property {
    this.filter = filter;
    return this;
  },
  /**
   * @instance
   * @description Changes type of property to selectable. By using valueset options will
   * be provided to user to select from.
   * @param {string} attributeName Name of the attribute in the nscale backend.
   * @param {string} valuesetName Name of the valueset.
   * @param {string} source Valueset source (custom, valueset...).
   */
  selectableAttribute(
    attributeName: string,
    valuesetName: string,
    source = valuesetSource.VALUESET
  ): SelectableAttributeProperty {
    const sp = this as SelectableAttributeProperty;

    sp.name = attributeName;
    sp.query = attributeName;
    sp.accessor = attributeName;
    sp.type = PropertyType.SELECT;
    sp.multiple = false;
    sp.valuesetName = valuesetName;
    sp.valuesetSource = source;
    sp.masterValuesetName = null;
    sp.options = [];
    sp.invalidatingOptions = false;
    sp.anticipatedValues = [];

    sp.insertable = (
      addingOptionsAllowed: boolean = true,
      options: Array<ValuesetOption> = []
    ): SelectableAttributeProperty => {
      if (source === valuesetSource.CUSTOM_VALUESET) {
        sp.addingOptionsAllowed = addingOptionsAllowed;
        sp.addingOptions = options;
      }

      return sp;
    };

    sp.slaveOf = (masterValuesetName: string): SelectableAttributeProperty => {
      if (source === valuesetSource.CUSTOM_VALUESET) {
        sp.masterValuesetName = masterValuesetName;
      }

      return sp;
    };

    sp.valueset = (
      options: Array<ValuesetOption>
    ): SelectableAttributeProperty => {
      if (source === valuesetSource.STATIC_VALUESET) {
        sp.options = options;
      }

      return sp;
    };

    sp.prepareValue = (value): any => {
      if (Array.isArray(value)) {
        const [firstValue] = value.filter((x) => x != null);
        return firstValue || null;
      }

      return value || null;
    };

    sp.parseValue = (value): any => {
      return [value].filter((x) => x != null);
    };

    sp.radio = (r = true): SelectableAttributeProperty => {
      if (r) {
        sp.type = PropertyType.RADIO;
      }

      return sp;
    };

    sp.invalidatableOptions = (invalidatingOptions = true) => {
      sp.invalidatingOptions = invalidatingOptions;
      return sp;
    };

    sp.anticipate = (value, watermark, listingColor) => {
      sp.anticipatedValues = [
        ...sp.anticipatedValues,
        {
          value,
          watermark,
          listingColor,
        },
      ];
      return sp;
    };

    return sp;
  },
  /**
   * @instance
   * @description Changes type of property into suggestive similar to selectable attribute.
   * With option to enter own custom values. Value does not need to be selected from valueset.
   * @see {@link selectableAttribute} See Similar approach.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   */
  suggestiveAttribute(attributeName: string): SelectableAttributeProperty {
    const sp = this.selectableAttribute(
      attributeName,
      attributeName,
      valuesetSource.AGGREGATE
    );

    sp.type = PropertyType.SUGGEST;

    return sp;
  },
  /**
   * @instance
   * @description Changes type of property into selectable principal. Property will hold
   * principal position id and provides search in active directory.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   * @param {string} principalType Type of the principal to fetch.
   */
  selectablePrincipalAttribute(
    attributeName: string,
    principalType = PrincipalType.USER
  ): PrincipalAttributeProperty {
    const pa = this as PrincipalAttributeProperty;

    pa.excludeUINumber();

    pa.name = attributeName;
    pa.query = attributeName;
    pa.accessor = attributeName;
    pa.principalType = principalType;
    pa.type = PropertyType.PRINCIPAL_SELECT;

    pa.groupNames = [];
    pa.insertable = (): PrincipalAttributeProperty => {
      return pa;
    };

    pa.prepareValue = ([value]) => {
      return value || null;
    };

    pa.parseValue = (value) => {
      return [value].filter((x) => x != null);
    };

    return pa;
  },
  /**
   * @instance
   * @description Changes type of property into selectable project member.
   * Property will hold principal position id and provides search in project members.
   * Could be limited to specific group names of the project.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   * @param {string} [principalType=PrincipalType.USER] Type of the principal to fetch.
   * @param {...string} [groupNames] Names of groups which would be used to search.
   */
  selectableMemberAttribute(
    attributeName: string,
    principalType = PrincipalType.USER,
    ...groupNames: Array<string>
  ): PrincipalAttributeProperty {
    const pa = this.selectablePrincipalAttribute(attributeName, principalType);
    pa.groupNames = groupNames;
    pa.type = PropertyType.MEMBER_SELECT;

    pa.insertable = (
      addingOptionsAllowed: boolean = true
    ): PrincipalAttributeProperty => {
      pa.addingOptionsAllowed = addingOptionsAllowed;
      return pa;
    };

    return pa;
  },
  /**
   * @instance
   * @description Changes type of property into boolean handling component.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   */
  booleanAttribute(attributeName: string): BooleanAttributeProperty {
    const ba = this as BooleanAttributeProperty;
    ba.name = attributeName;
    ba.query = attributeName;
    ba.accessor = attributeName;
    ba.type = PropertyType.CHECKBOX;
    ba.multiple = false;
    ba.defaultValue = false;
    ba.neutral = false;
    ba.keyword = false;
    ba.columnWidth = 75;

    ba.labelLeft = null;
    ba.labelRight = null;

    ba.prepareValue = (value) => value;

    ba.parseValue = (value) => value;

    ba.format = (value) => {
      if (value == null) {
        return "";
      }

      return value ? "Yes" : "No";
    };

    ba.useYesNo = (): BooleanAttributeProperty => {
      ba.type = PropertyType.YESNO;
      ba.defaultValue = false;
      return ba;
    };

    ba.useYesNoNeutral = (): BooleanAttributeProperty => {
      ba.type = PropertyType.YESNO;
      ba.defaultValue = null;
      ba.neutral = true;
      return ba;
    };

    ba.useCustom = (
      leftLabel: string,
      rightLabel: string
    ): BooleanAttributeProperty => {
      ba.labelLeft = leftLabel;
      ba.labelRight = rightLabel;
      return ba;
    };

    return ba;
  },
  /**
   * @instance
   * @description Changes type of property into multi value attribute.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   * @param {boolean} [allowMultiSelection=true] Sets property to mimick single value
   * when false even when stored as multi value attribute.
   */
  multiAttribute(
    attributeName: string,
    allowMultiSelection: boolean = true
  ): Property {
    this.name = attributeName || this.name;
    this.query = attributeName || this.name;
    this.accessor = attributeName || this.name;
    this.sortable = false;
    this.handleAsMulti = true;
    this.multiple = allowMultiSelection;

    this.parseValue = (value) => {
      if (value != null && value.list != null) {
        return value.list || [];
      }

      return [];
    };

    this.prepareValue = (value) => {
      if (value != null) {
        if (Array.isArray(value)) {
          return { list: value };
        }

        return { list: [value] };
      }

      return null;
    };

    this.validate = (value) => {
      if (this.required && !this.disabled) {
        if (value == null || value.length <= 0) {
          return false;
        }

        if (Array.isArray(value) && (value.length <= 0 || value[0] == null)) {
          return false;
        }
      }

      return true;
    };

    return this;
  },
  /**
   * @instance
   * @description Changes type of property into selectable resource. Which
   * allows to aggregate search in repository folder for values of property.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   */
  selectableResourceAttribute(
    attributeName: string
  ): SelectableAttributeProperty {
    const sa = this.selectableAttribute(
      attributeName,
      attributeName,
      valuesetSource.AGGREGATE_RESOURCE
    );

    sa.type = PropertyType.RESOURCE_SELECT;
    sa.buildOptions = (
      options: Array<ValuesetOption>,
      filter: Record<string, any>,
      contextResourceId: string
    ) => {
      if (contextResourceId == null || !Array.isArray(options)) {
        return options;
      }

      return options.filter((o) => o.filter === contextResourceId);
    };

    return sa;
  },
  /**
   * @instance
   * @description Changes type of property into selectable module element. Which then provides
   * seletable component for module document/folder properties, valueset items
   * or folder types.
   * @param {string} attributeName The name of the attribute in the nscale backend.
   */
  selectableModuleElementAttribute(attributeName, elementType) {
    const sam = this as SelectableModuleElementProperty;

    sam.elementType = elementType;
    sam.name = attributeName;
    sam.query = attributeName;
    sam.accessor = attributeName;
    sam.type = PropertyType.MODULE_ELEMENT_SELECT;

    return sam;
  },

  permittedUsersAttribute(attributeName) {
    this.name = attributeName;
    this.query = attributeName;
    this.accessor = attributeName;
    this.type = propertyType.ACCESS_MANAGEMENT;
    return this;
  },

  /**
   * @instance
   * @description Sets property as a structural for repository root of project explorer/tree explorer.
   * @param {number} level Level number in the folder structure.
   * @param {callback} [structureCallback] Callback to update structure behaviour.
   */
  structured(level: number, structureCallback = (s: Structure) => s): Property {
    this.structures = [
      ...this.structures,
      structureCallback(structure(level, structureType.MAIN)),
    ];
    return this;
  },
  /**
   * @instance
   * @description Sets property as a structural for folder explorer/tree explorer.
   * @param {number} level Level number in the folder structure.
   * @param {callback} [structureCallback] Callback to update structure behaviour.
   */
  folderStructured(
    level: number,
    structureCallback = (s: Structure) => s
  ): Property {
    this.structures = [
      ...this.structures,
      structureCallback(structure(level, structureType.FOLDER)),
    ];
    this.systemUpdatable = true;
    return this;
  },
  /**
   * @instance
   * @description Changes type of the property to text area.
   * @param {number} [characterLimit] Number of characters which is allowed to enter into field.
   */
  textArea(characterLimit: number = 0): Property {
    this.type = PropertyType.TEXTAREA;
    this.characterLimit = characterLimit;
    return this;
  },
  /**
   * @instance
   * @description Changes type of the property to rich text area.
   * @param {number} [characterLimit] Number of characters which is allowed to enter into field.
   */
  richTextArea(characterLimit: number = 0): Property {
    this.type = PropertyType.RICH_TEXTAREA;
    this.characterLimit = characterLimit;

    // this.render = (value) => {
    // return value;
    // return h("div", {
    //   dangerouslySetInnerHTML: {
    //     __html: value,
    //   },
    // });
    // };

    return this;
  },
  /**
   * @instance
   * @description Sets a character limit when using textarea or richtext.
   * @param {number} [characterLimit] Number of characters which is allowed to enter into field.
   */
  limit(characterLimit: number = 0): Property {
    this.characterLimit = characterLimit;
    return this;
  },
  /**
   * @instance
   * @description Sets default value for a property when resource is being saved.
   * @param {string} value Default value.
   */
  default(value: any): Property {
    this.defaultValue = value;
    return this;
  },
  getDefaultValue(...args): any {
    if (this.defaultValue != null) {
      if (typeof this.defaultValue === "function") {
        const resolvedDefault = this.defaultValue(...args);
        return this.parseValue(resolvedDefault);
      }

      return this.parseValue(this.defaultValue);
    }

    return null;
  },
  /**
   * @instance
   * @description Sets default filter value for a property.
   * @param {string} value Value of a filter.
   */
  defaultFilter(value: any): Property {
    this.defaultFilterValue = value;
    return this;
  },
  /**
   * @instance
   * @description Value of the property will be set from current user data when document created.
   * @param {string} propertyName Property name of user data which will be used for value.
   * @param {string} fallback Fallback value when user data do not contains property.
   */
  defaultByUserProperty(propertyName: string, fallback = null): Property {
    this.defaultValue = (userdata: Record<string, any>) => {
      return userdata?.[propertyName.toString()] || fallback;
    };
    return this;
  },
  /**
   * @instance
   * @description Changes property type to numeric.
   * @param {string} attributeName Name of the attribute in the nscale backend.
   * @param {number} min Minimal value of the numeric input component.
   * @param {number} max Maximal value of the numeric input component.
   * @param {number} decimalPlaces Decimal places of the numeric input component.
   */
  numericAttribute(
    attributeName,
    min,
    max,
    decimalPlaces = 0
  ): NumericAttributeProperty {
    const na = this as NumericAttributeProperty;

    na.name = attributeName || this.name;
    na.accessor = this.name;
    na.query = attributeName;
    na.type = PropertyType.NUMERIC;
    na.boundary = { min, max };
    na.decimalPlaces = decimalPlaces;
    na.keyword = false;

    na.validate = (value) => {
      if (na.required && !na.disabled) {
        if (value == null || value === "") {
          return false;
        }

        if (min != null && value < min) {
          return false;
        }

        if (max != null && value > max) {
          return false;
        }
      }

      return true;
    };

    na.format = (value) =>
      value != null ? String(value).replace(".", ",") : "";

    return na;
  },
  /**
   * @instance
   * @description Sets property to execute NQL pidResolve. Resolves user name from provided
   * principal id.
   * @param {string} resolveProperty Property name which contains principal id as a value.
   */
  resolveUsername(...resolveProperty: Array<string>): Property {
    if (resolveProperty.length === 1) {
      this.query = `${this.name}=pidResolve(${resolveProperty})`;
    } else {
      const resolves = resolveProperty
        .map((prop) => `pidResolve(${prop})`)
        .join(",");
      this.query = `${this.name}=++(${resolves})`;
    }

    this.excludeUINumber();
    return this;
  },
  /**
   * @instance
   * @description Sets property to execute NQL pidResolve. Resolves
   * principal id from provided principal id.
   * @param {string} resolveProperty Property name which contains principal id as a value.
   */
  resolvePositionId(resolveProperty: string): Property {
    this.query = `${this.name}=pidResolve(${resolveProperty}, 7)`;
    return this;
  },
  /**
   * @instance
   * @description Value of the propertie will be trimmed of UIxxxxxx number. Use for user names.
   */
  excludeUINumber(containsUsername = true): Property {
    this.containsUsername = containsUsername;
    this.format = trimUINumber;
    return this;
  },
  /**
   * @instance
   * @description Sets property to execute NQL refResolve as an value of it.
   * @param {string} resolveProperty Property from referenced resource that will be fetched (eg. displayname).
   * @param {string} referenceType Type of the reference.
   * @param {string} referenceProperty Property which contains reference key to resource (eg. resourceid).
   */
  resolveReference(
    resolveProperty: string,
    referenceType: number,
    referenceProperty: string
  ): Property {
    this.query = `${this.name}=refResolve(${resolveProperty}, ${referenceType}, "${referenceProperty}")`;
    return this;
  },
  /**
   * @instance
   * @description Property would hold group name based on the attribute value provided.
   * @param {string} resolveProperty Property name which contains principal id of the group.
   */
  groupResolve(resolveProperty: string): Property {
    this.query = `${this.name}=pidResolve(groupResolve(${resolveProperty}, true), 0)`;
    return this;
  },
  /**
   * @instance
   * @description Sets specific query for retrieving property value from nscale backend.
   * @param {string} query Query that should be used to retrieve property value using NQL.
   */
  queryProperty(query): Property {
    this.query = query;
    return this;
  },
  /**
   * @instance
   * @description Sets description of the property. Could be react node component as well.
   * @param {string} description Text or React component.
   */
  describe(description: string): Property {
    this.description = description;
    return this;
  },
  /**
   * @instance
   * @description Adds separator below property in repository filters.
   */
  separatorBelow(): Property {
    const classArray = String(this.customClassName).split(" ");
    this.customClassName = [...classArray, "mb-2"].join(" ");
    return this;
  },
  /**
   * @instance
   * @description Adds property into additional filter of repository filters.
   * User has to expand additional filters to see it instead of directly approaching it.
   */
  additionalFilter(): Property {
    this.filterable = true;

    switch (this.filterGroup) {
      default:
      case filterGroup.MAIN:
        this.filterGroup = filterGroup.ADDITIONAL;
        break;
      case filterGroup.FOLDER:
        this.filterGroup = filterGroup.FOLDER_ADDITIONAL;
        break;
      case filterGroup.MAIN_FOLDER:
        this.filterGroup = filterGroup.ADDITIONAL_FOLDER_ADDITIONAL;
        break;
    }

    return this;
  },
  /**
   * @instance
   * @description Disables sorting of the property in the table view.
   */
  disableSorting(): Property {
    this.sortable = false;
    return this;
  },
  /**
   * @instance
   * @description Enable sorted by this property by default.
   */
  defaultSorted(): Property {
    this.defaultSort = true;
    return this;
  },
  /**
   * @instance
   * @description Sets property as an alias for different attribute.
   * @param {string} attribue Name of the attribute in the nscale backend.
   */
  attributeAlias(attribute: string): Property {
    this.query = `${this.name}=${attribute}`;
    return this;
  },
  /**
   * @instance
   * @description Describes by a callback how property behaves when used
   * in folder resource type form.
   * @param {callback} build Callback to update property behaviour for folder.
   */
  asFolderProperty(build: (folderProp: Property) => Property): Property {
    const folderProp = { ...this, resourceType: 1 };
    this.folderProperty = build(folderProp);
    return this;
  },
  /**
   * @instance
   * @description Changes type of the property into date attribute.
   * @param {string} attributeName Name of the attribute in the nscale backend.
   * @param {string} dateZone Time zone.
   */
  dateAttribute(
    attributeName: string,
    dateZone = "local"
  ): DateAttributeProperty {
    const da = this as DateAttributeProperty;

    da.type = PropertyType.DATE;
    da.name = attributeName;
    da.query = attributeName;
    da.accessor = attributeName;
    da.columnWidth = 125;
    da.filterRange = false;
    da.dateZone = dateZone;
    da.dateOnly = false;
    da.reminderCreator = false;
    da.minValueResolve = null;

    da.validate = (value) => {
      if (!da.required || da.disabled) {
        return true;
      }

      if (!da.disabled && (value == null || value === "")) {
        return false;
      }

      const date = DateTime.fromMillis(value);
      return value == null || date.isValid;
    };

    da.format = (value) => {
      let dateTime;

      if (value != null) {
        try {
          dateTime = DateTime.fromMillis(value, {
            zone: dateZone,
          });
        } catch (err) {
          dateTime = DateTime.fromISO(value, { zone: dateZone });
        }
      }

      if (dateTime != null && dateTime.isValid) {
        return dateTime
          .setLocale("de-DE")
          .toLocaleString(DateTime.DATETIME_SHORT);
      }

      return value;
    };

    da.sortType = (a: SortRow, b: SortRow, columnId: string) => {
      const aVal = a.values[columnId];
      const bVal = b.values[columnId];

      if (aVal == null || aVal === "") {
        return -1;
      }

      if (bVal == null || bVal === "") {
        return 1;
      }

      return aVal - bVal;
    };

    da.dateRangeFilter = (): DateAttributeProperty => {
      // da.filter = (rows, columns, value) => {
      //   const [from, to] = value;
      //   const [column] = columns;

      //   const fromDate = from != null ? DateTime.fromMillis(from) : null;
      //   const toDate = to != null ? DateTime.fromMillis(to) : null;

      //   return rows.filter((item) => {
      //     const date = DateTime.fromMillis(item.values[column]);
      //     return date <= toDate && date >= fromDate;
      //   });
      // };

      // da.valuesetName = this.name;
      // da.valuesetSource = valuesetSource.AGGREGATE_MINMAX;
      da.filterRange = true;

      return da;
    };

    da.excludeTime = (dateOnly = true): DateAttributeProperty => {
      da.dateOnly = dateOnly;
      da.format = (value) => {
        let dateTime;

        if (value != null) {
          try {
            dateTime = DateTime.fromMillis(value, {
              zone: dateZone,
            });
          } catch (err) {
            dateTime = DateTime.fromISO(value, { zone: dateZone });
          }
        }

        if (dateTime != null && dateTime.isValid) {
          const format =
            dateZone === "local" ? "dd.MM.yyyy" : "dd.MM.yyyy ZZZZ";

          return (
            dateTime
              .setLocale("de-DE")
              // .toLocaleString(DateTime.DATE_SHORT);
              .toFormat(format)
          );
        }

        return value;
      };
      return da;
    };

    da.minValue = (callback = () => {}): DateAttributeProperty => {
      da.minValueResolve = callback;
      da.boundary = {
        ...(this.boundary || {}),
        min: callback(),
      };

      return da;
    };

    da.useReminderCreator = (reminderCreator = true) => {
      da.reminderCreator = reminderCreator;
      return da;
    };

    return da;
  },
  /**
   * @instance
   * @description Allow system to update property in the background
   * if the property is set as system/non editable.
   */
  systemUpdates(): Property {
    this.systemUpdatable = true;
    return this;
  },
  /**
   * @instance
   * @description Adds widget based on the property aggregate query.
   * @param {string} title Title of the widget in the dashboad.
   * @param {widgetType} type Type of the widget.
   */
  widget(title: string, type: string): Property {
    this.widgets = [
      ...this.widgets,
      widget(title, type).repository(this.name, this.resourceType),
    ];
    return this;
  },
  /**
   * @instance
   * @description Sets property to be translated by translation path.
   * @param {string} translation Translation path.
   */
  translate(translation: string): Property {
    this.translation = translation;
    return this;
  },

  /**
   * @instance
   * @description Sets property to be protected. The editing functionalities in project editor are limited then.
   * @param {boolean} protectedProperty Sets property to be protected or not.
   */
  protect(protectedProperty: boolean = true): Property {
    this.protectedProperty = protectedProperty;
    return this;
  },

  /**
   *  @instance
   * @description This decorator turns regular property into conditional without using "conditionalProperty" generator.
   */
  controlVisibility() {
    Object.assign(this, conditionalElement());
    return this as ConditionalProperty;
  },

  /**
   * @instance
   * @description Set if the property should appear in the documents/folders import template.
   * @param include
   */
  includeImportTemplate(include = true): Property {
    this.importable = include;
    return this;
  },

  sortWith(sortType) {
    this.sortType = sortType;
    return this;
  },
});

export default property;
