import { createContext } from "react";
import { ProjectGroup, ProjectMember } from "./ProjectContext";

export interface ProjectCompetenceContextValue {
  loading: boolean;
  createProjectGroup: (
    parentGroupName: string,
    domainName: string,
    group: Partial<ProjectMember>
  ) => Promise<ProjectMember>;
  updateProjectGroup: (
    groupName: string,
    domainName: string,
    group: Partial<ProjectMember>
  ) => Promise<ProjectMember>;
  removeProjectMembers: (members: ProjectMember[]) => Promise<void>;
  manageableGroups: ProjectGroup[];
}

const ProjectCompetenceContext = createContext<ProjectCompetenceContextValue>({
  loading: false,
  createProjectGroup: () => Promise.reject("not implemented"),
  updateProjectGroup: () => Promise.reject("not implemented"),
  removeProjectMembers: () => Promise.reject("not implemented"),
  manageableGroups: [],
});

export default ProjectCompetenceContext;
