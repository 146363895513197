import GatewayApi from './conf/GatewayApi';

const gatewayApi = GatewayApi.build();

const getSignatureRequest = (envelopeId) => {
  return gatewayApi
    .get(`/signature-request/${envelopeId}`)
    .then((response) => Promise.resolve(response.data));
};

const updateSignatureRequest = (envelopeId, signRequest) => {
  return gatewayApi
    .put(`/signature-request/${envelopeId}`, signRequest)
    .then((response) => Promise.resolve(response.data));
};

const cancelSignatureRequest = (envelopeId) => {
  return gatewayApi
    .delete(`/signature-request/${envelopeId}`)
    .then((response) => Promise.resolve(response.data));
};

export default {
  getSignatureRequest,
  updateSignatureRequest,
  cancelSignatureRequest,
};
