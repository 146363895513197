export const SET_COMMENTS = 'comments/set';
export const FETCH_COMMENTS = 'comments/fetch';
export const POST_COMMENT = 'comment/post';
export const CLOSE_COMMENTS = 'comments/close';
export const RELOAD_COMMENTS = 'comments/reload';

export const FETCH_LATEST_CHAT_COMMENTS = 'comments/fetchLatestChat';
export const SET_LATEST_CHAT_COMMENTS = 'comments/setLatestChat';
export const UPDATE_COMMENT = 'comment/update';
export const DELETE_COMMENT = 'comment/delete';

export const EXPORT_COMMENTS_EXCEL = 'comments/exportExcel';
