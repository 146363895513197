const uuidv4 = () => {
  return ([1e7].toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c: any) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
  );
};

const trimUINumber = (commonName: String): String => {
  if (commonName == null) {
    return commonName;
  }

  return String(commonName)
    .replace(/\(((UI|R)\d{6}|(RE)\d{5})\)/gi, "")
    .trim();
};

const hashCode = (value: String): Number => {
  let hash = 0;
  if (value.length === 0) {
    return hash;
  }
  for (let i = 0; i < value.length; i += 1) {
    const char = value.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash;
  }
  return hash;
};

export { uuidv4, trimUINumber, hashCode };
export {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from "./resourceUtils";
