/**
 * @enum {string}
 * @readonly
 */
export default {
  /** Agregate query based charts. */
  aggregate: {
    PIE_CHART: "PIE_CHART",
    BAR_CHART: "BAR_CHART",
  },
  data: {
    EXPIRING_DOCUMENTS: "EXPIRING_DOCUMENTS",
    CHOROPLETH_CHART: "CHOROPLETH_CHART",
  },
  general: {
    RECENTLY_OPENED_TABLE: "RECENTLY_OPENED_TABLE",
    RECENTLY_UPLOADED_TABLE: "RECENTLY_UPLOADED_TABLE",
    FAVOURITE_TABLE: "FAVOURITE_TABLE",
    GREETING: "GREETING",
    TASKS_TABLE: "TASKS_TABLE",
  },
};
