import { Container, Row } from "react-bootstrap";
import React, { useCallback, useState } from "react";
import _ from "lodash";
import ContentLayoutContext, { componentType } from "./ContentLayoutContext";
import ContentLayoutSidebar from "./ContentLayoutSidebar";
import ContentLayoutContent from "./ContentLayoutContent";
import ContentLayoutBlocker from "./ContentLayoutBlocker";
import sidebarGridMapping, { GridMapping } from "./sidebarGridMapping";
import styled from "styled-components";

const ContentLayoutRow = styled(Row)``;

export const ContentLayoutContainer = styled(Container)`
  height: 100%;
  background-color: ${(props) => props.theme.background};

  @media (max-width: 767.98px) {
    padding-bottom: 45px !important;
  }

  & > ${ContentLayoutRow} {
    height: 100%;
  }
`;

type Column = {
  collapsed: boolean;
  sidebar: boolean;
};

export interface ContentLayoutProps {
  children?: React.ReactNode;
  fluid?: boolean;
}

const ContentLayout = ({ children, fluid }: ContentLayoutProps) => {
  const [columns, setColumns] = useState<Record<string, Column>>({});

  return (
    <ContentLayoutContainer fluid={fluid == null ? true : fluid}>
      <ContentLayoutRow>
        <ContentLayoutContext.Provider
          value={{
            getComponentProps: useCallback(
              (id) => {
                return Object.entries(sidebarGridMapping).reduce(
                  (acc, [key, value]) => {
                    const sidebarArea =
                      _(columns).valuesIn().filter("sidebar").value().length *
                      value;

                    return {
                      ...acc,
                      [key]:
                        (12 - sidebarArea) /
                        _(columns).valuesIn().reject("sidebar").value().length,
                    };
                  },
                  {}
                ) as GridMapping;
              },
              [columns]
            ),
            registerComponent: useCallback((id, type) => {
              return new Promise((resolve) => {
                setColumns((columns) => {
                  resolve(Object.keys(columns).length);
                  return {
                    ...columns,
                    [id]: {
                      sidebar: type !== componentType.CONTENT,
                      collapsed: false,
                    },
                  };
                });
              });
            }, []),
            unregisterComponent: useCallback((id) => {
              setColumns((columns) => _(columns).omit(id).value());
            }, []),
            toggleCollapse: useCallback((id) => {
              setColumns((columns) => {
                const column = columns[id];
                return {
                  ...columns,
                  [id]: {
                    ...column,
                    collapsed: !column.collapsed,
                  },
                };
              });
            }, []),
            isCollapsed: useCallback(
              (id) => {
                return !!columns[id]?.collapsed;
              },
              [columns]
            ),
          }}
        >
          {React.Children.toArray(children)}
        </ContentLayoutContext.Provider>
      </ContentLayoutRow>
    </ContentLayoutContainer>
  );
};

ContentLayout.Sidebar = ContentLayoutSidebar;
ContentLayout.Content = ContentLayoutContent;
ContentLayout.Blocker = ContentLayoutBlocker;

export default ContentLayout;
