import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Card } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PrimaryCardProps {
  className?: string;
  icon?: IconProp;
  active?: boolean;
  onClick?: () => void;
}

const PrimaryCard = ({
  className,
  icon,
  active,
  onClick,
}: PrimaryCardProps) => (
  <Card
    body
    onKeyDown={() => {}}
    role="button"
    onClick={onClick}
    className={classNames(
      "text-primary border-primary text-center",
      className,
      {
        "bg-primary": active,
        "text-light": active,
      }
    )}
  >
    {icon != null && <FontAwesomeIcon icon={icon} size="2x" />}
  </Card>
);

export default PrimaryCard;
