import {
  faAsterisk,
  faExclamationTriangle,
  faLink,
  faM,
  faRightFromBracket,
  faS,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { PancoTooltip } from "cosmos-components";
import React, { ReactElement, createElement as h, useMemo } from "react";
import mfe from "mime-file-extension";
import extenstionIconMapping from "../contants/extenstionIconMapping";
import extensionIconMappingOffice from "../contants/extensionIconMappingOffice";
import useProject from "../hooks/useProject";
import { parseResourceId, isMsOfficeDocument } from "../utils/resourceUtils";
import Resource from "../types/resource";

type CornerIconProps = {
  secondary?: boolean;
};

const CornerIcon = styled(FontAwesomeIcon).attrs<CornerIconProps>(() => ({
  size: "xs",
}))`
  position: absolute;
  background: ${(props) => props.theme.background};
  border-radius: 10px;

  ${(props: CornerIconProps) =>
    props.secondary ? "right: 0; bottom: -2px;" : "left: 0; top: -2px;"}
`;

const DocumentIconElement = styled.span`
  width: 17.5px;
  text-align: center;
  display: inline-block;
  position: relative;
`;

const MSOfficeIcon = styled.img`
  width: 100%;
`;

export interface DocumentIconProps {
  document?: Partial<Resource>;
  className?: string;
}

const DocumentIcon = ({ document, className }: DocumentIconProps) => {
  const { t } = useTranslation("module");
  const { project } = useProject();
  const folderId = project?.resourceId;

  const portalDocument = useMemo(() => {
    if (folderId == null) {
      return false;
    }

    const { identifier } = parseResourceId(folderId);

    return (
      Array.isArray(document?.pathidentifiers) &&
      identifier != null &&
      !document?.pathidentifiers.includes(identifier)
    );
  }, [document, folderId]);

  function renderDocumentIcon() {
    if (document == null) {
      return null;
    }

    const {
      contenttype,
      refresourcecontenttype,
      parentdocumentid,
      id,
      resourcetype,
      LinkedDocs,
      contentitemcount,
    } = document;
    let fileextension = document.fileextension;

    if (contenttype == null && refresourcecontenttype != null) {
      const referencedContentType = _.first(
        String(refresourcecontenttype).split(";")
      );

      if (referencedContentType != null) {
        try {
          fileextension = mfe.getFileExtensions(referencedContentType)[0];
        } catch (err) {
          console.error(err);
        }
      }
    }

    function* generateBaseIcon() {
      let descriptions: string[] = [];
      const describe = (translationPath: string, defaultValue: string) => {
        descriptions = [...descriptions, t(translationPath, { defaultValue })];
      };

      const msOfficeDocument =
        isMsOfficeDocument(contenttype) ||
        isMsOfficeDocument(refresourcecontenttype);
      if (msOfficeDocument && fileextension != null) {
        const iconProps = extensionIconMappingOffice(fileextension);
        yield h(MSOfficeIcon, iconProps);
      } else if (document?.targeturl != null) {
        describe("document.resource_type_url", "URL");
        yield h(FontAwesomeIcon, { icon: faGlobe, size: "lg" });
      } else {
        const iconProps = extenstionIconMapping(fileextension);
        yield h(FontAwesomeIcon, { ...iconProps, size: "lg" });
      }

      if (Array.isArray(parentdocumentid) && parentdocumentid.length > 0) {
        if (_.first(parentdocumentid) === id) {
          describe("document.resource_type_main", "Main Document");
          yield h(CornerIcon, {
            secondary: true,
            icon: faM,
          });
        } else {
          describe(
            "document.resource_type_subordinate",
            "Subordinate Document"
          );
          yield h(CornerIcon, {
            secondary: true,
            icon: faS,
          });
        }
      }

      if (Array.isArray(LinkedDocs) && LinkedDocs.length > 0) {
        describe("document.resource_type_link", "Linked Document");
        yield h(CornerIcon, { icon: faLink });
      }

      if (resourcetype === 3) {
        describe("document.resource_type_asterisk", "Shortcut");
        yield h(CornerIcon, { icon: faAsterisk });
      }

      if (resourcetype === 2 && !contentitemcount) {
        describe(
          "document.resource_type_no_content",
          "Placeholder/Stub(no content)"
        );
        yield h(CornerIcon, {
          icon: faExclamationTriangle,
          className: "text-danger bg-white",
        });
      }

      if (portalDocument) {
        describe(
          "document.resource_type_portal_document",
          "Document from another module"
        );
        yield h(CornerIcon, { icon: faRightFromBracket });
      }

      yield descriptions.map((d) => h("div", { key: d }, d));
    }

    const baseIcon = [...generateBaseIcon()];
    const descriptions = baseIcon.pop() as ReactElement[];

    if (descriptions.length > 0) {
      return h(
        PancoTooltip,
        {
          text: descriptions.map((d, i) => h("div", { key: String(i) }, d)),
        },
        baseIcon
      );
    } else {
      return baseIcon;
    }
  }

  return (
    <DocumentIconElement className={className}>
      {renderDocumentIcon()}
    </DocumentIconElement>
  );
};

export default DocumentIcon;
