import { createAction } from "@reduxjs/toolkit";
import * as actions from "../contants/userManagementConstants";
import { ProjectMember } from "../contexts/ProjectContext";

export const groupDeleteMember = createAction(actions.GROUP_DELETE_MEMBER);

export type CreateGroupActionPayload = {
  group: Partial<ProjectMember>;
};

export const createGroup = createAction<CreateGroupActionPayload>(
  actions.CREATE_GROUP
);
export const groupAddMember = createAction(actions.GROUP_ADD_MEMBER);

export type UpdateGroupActionPayload = {
  groupName: string;
  group: Partial<ProjectMember>;
};

export const updateGroup = createAction<UpdateGroupActionPayload>(
  actions.UPDATE_GROUP
);
export const deleteGroup = createAction(actions.DELETE_GROUP);
export const exportGroupMembersExcel = createAction(
  actions.EXPORT_GROUP_MEMBERS_EXCEL
);
export const exportAllGroupMembersExcel = createAction(
  actions.EXPORT_ALL_GROUP_MEMBERS_EXCEL
);
