import { property, valuesetSource } from "cosmos-config/generator";

const project = [
  property("Project Name", "name").translate("common.project_name"),
  property("Project Code", "code").translate("common.project_code"),
  property("Sector")
    .selectableAttribute(
      "sector",
      "project_sector",
      valuesetSource.STATIC_VALUESET
    )
    .valueset([{ value: "LEGAL", label: "Legal" }])
    .translate("common.project_sector"),
];

export default project;
