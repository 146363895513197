import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, CloseButton } from "react-bootstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { LoadingOverlay, ContextMenu } from "cosmos-components";
import spocProperties from "../../contants/spoc";
import {
  createProjectSpoc,
  createProjectSpocRequest,
  deleteProjectSpocs,
  exportProjectSpocsExcel,
  toggleActiveProjectSpoc,
  updateProjectSpoc,
} from "../../actions/projectActions";
import SimpleUniversalForm from "../../components/SimpleUniversalForm";
import useCoreDispatch from "../../hooks/useCoreDispatch";
import useCoreSelector from "../../hooks/useCoreSelector";
import SimpleTable from "../../components/SimpleTable";
import useOrganization from "../../hooks/useOrganization";

const initialSpocState = {
  allDepartments: false,
};

const PropertySPOCEditModal = ({ show, onClose, spoc, requestOnly }) => {
  const dispatch = useCoreDispatch();
  const loading = useCoreSelector((state) => state.usermanagement.loading);

  const [innerSpoc, setInnerSpoc] = useState(initialSpocState);

  useEffect(() => {
    setInnerSpoc((s) => spoc || s);
  }, [spoc]);

  useEffect(() => {
    if (show) {
      setInnerSpoc((s) => (s == null ? initialSpocState : s));
    }
  }, [show]);

  const handleSubmit = () => {
    const { allDepartments, department, ...rest } = innerSpoc;
    const data = {
      ...rest,
      department: allDepartments ? "$ALL$" : innerSpoc.department,
    };

    if (requestOnly) {
      dispatch(
        createProjectSpocRequest({
          spoc: data,
        })
      ).then(() => onClose());
    } else if (innerSpoc.id != null) {
      dispatch(
        updateProjectSpoc({
          spocId: innerSpoc.id,
          spoc: data,
        })
      ).then(() => onClose());
    } else {
      dispatch(
        createProjectSpoc({
          spoc: data,
        })
      ).then(() => onClose());
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Edit Spoc</Modal.Title>
        <CloseButton onClick={onClose} className="close-icon" />
      </Modal.Header>

      <Modal.Body>
        <LoadingOverlay loading={loading}>
          <SimpleUniversalForm
            inline
            properties={spocProperties}
            resource={innerSpoc}
            onUpdateResource={(id, propertyName, propertyValue) => {
              setInnerSpoc((s) => ({ ...s, [propertyName]: propertyValue }));
            }}
          />
        </LoadingOverlay>
      </Modal.Body>
      <Modal.Footer className="text-right">
        <Button variant="link" onClick={onClose} className="mr-2">
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};

PropertySPOCEditModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  property: PropTypes.shape({
    buildOptions: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  spoc: PropTypes.shape({
    id: PropTypes.string,
  }),
  requestOnly: PropTypes.bool,
};

PropertySPOCEditModal.defaultProps = {
  show: false,
  onClose: () => {},
  spoc: null,
  requestOnly: false,
};

const SpocsTable = ({ requestOnly }) => {
  const dispatch = useCoreDispatch();

  const loading = useCoreSelector((state) => state.usermanagement.loading);
  const projectSpocs = useCoreSelector((state) => state.project.spocs);

  const { getPositionInfo } = useOrganization();

  const [showEditSPOCModal, setShowEditSPOCModal] = useState(false);
  const [selectedPropertySPOC, setSelectedPropertySPOC] = useState(null);

  const data = useMemo(
    () =>
      projectSpocs.map((spoc) => {
        const { segment } = getPositionInfo(spoc?.department);

        return {
          ...spoc,
          segment: segment.includes("$") ? "All" : segment,
        };
      }),
    [projectSpocs]
  );

  const renderMenu = (selectedSPOCs) => {
    const onlyOneSelected = selectedSPOCs.length === 1;
    const atLeastOneSelected = selectedSPOCs.length > 0;
    const [spoc] = selectedSPOCs;

    return (
      <>
        <ContextMenu.Item
          onClick={() => {
            setSelectedPropertySPOC(null);
            setShowEditSPOCModal(true);
          }}
        >
          Add SPOC
        </ContextMenu.Item>
        <ContextMenu.Item separator />
        <ContextMenu.Item
          disabled={!onlyOneSelected}
          onClick={() => {
            setSelectedPropertySPOC({
              ...spoc,
              allDepartments:
                spoc.department != null && spoc.department.includes("$ALL$"),
            });
            setShowEditSPOCModal(true);
          }}
        >
          Edit
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={!onlyOneSelected}
          onClick={() => {
            dispatch(toggleActiveProjectSpoc({ spocId: spoc.id }));
          }}
        >
          {spoc != null && spoc.active ? "Deactivate" : "Activate"}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={!atLeastOneSelected}
          onClick={() => {
            dispatch(
              exportProjectSpocsExcel({
                spocIds: selectedSPOCs.map((s) => s.id),
              })
            );
          }}
        >
          Export Selected Rows (Excel)
        </ContextMenu.Item>
        <ContextMenu.Item separator />
        <ContextMenu.Item
          disabled={!atLeastOneSelected}
          onClick={() => {
            dispatch(
              deleteProjectSpocs({
                spocIds: selectedSPOCs.map((s) => s.id),
              })
            );
          }}
        >
          Delete
        </ContextMenu.Item>
      </>
    );
  };

  return (
    <div>
      <div className="mb-3">
        <Button
          onClick={() => {
            setSelectedPropertySPOC(null);
            setShowEditSPOCModal(true);
          }}
        >
          {requestOnly ? "Request Spoc" : "Add Spoc"}
        </Button>
        <Button
          onClick={() => {
            dispatch(exportProjectSpocsExcel());
          }}
          variant="link"
        >
          <FontAwesomeIcon icon={faFileImport} className="mr-2" />
          Export All (Excel)
        </Button>
      </div>

      <LoadingOverlay loading={loading}>
        <SimpleTable
          properties={spocProperties}
          data={data}
          disableContextMenu={requestOnly}
          renderContextMenu={renderMenu}
          renderCell={(column, value, label) => {
            if (column === "department" && _.isEqual(value, ["$ALL$"])) {
              return "All";
            }

            return label;
          }}
        />
      </LoadingOverlay>

      <PropertySPOCEditModal
        show={showEditSPOCModal}
        onClose={() => setShowEditSPOCModal(false)}
        spoc={selectedPropertySPOC}
        requestOnly={requestOnly}
      />
    </div>
  );
};

export default SpocsTable;
