import { BarLegendProps, ResponsiveBar } from "@nivo/bar";
import React, { useMemo } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { CosmosChart, CosmosChartProps } from "../../types/CosmosChart";

interface RectangleProps {
  color?: string;
}

const Rectangle = styled.span<RectangleProps>`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 7px;
  background-color: ${(props) => props.color || "gray"};
`;

const ChartWrapper = styled.div`
  height: 100%;
`;

export interface BarChartProps extends CosmosChartProps {
  keys?: string[];
}

const BarChart = ({ data, keys, onClick }: BarChartProps): CosmosChart => {
  const margin = 35;
  const horizontalLayout = (data || []).length > 10;

  const legends = useMemo(() => {
    if (keys != null && keys.length > 1) {
      return [
        {
          dataFrom: "keys",
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 100,
          itemsSpacing: 2,
          itemWidth: 150,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        } as BarLegendProps,
      ];
    }

    return [];
  }, [keys]);

  return (
    <ChartWrapper>
      <ResponsiveBar
        data={data || []}
        keys={keys || ["value"]}
        animate
        groupMode="grouped"
        minValue="auto"
        maxValue="auto"
        layout={horizontalLayout ? "horizontal" : "vertical"}
        colors={{ scheme: "paired" }}
        margin={{
          top: margin / 2,
          right: margin,
          bottom: horizontalLayout ? margin : margin * 4,
          left: horizontalLayout ? margin * 6 : margin,
        }}
        labelTextColor={{ theme: "background" }}
        colorBy={keys != null && keys.length > 1 ? "id" : "indexValue"}
        borderRadius={3}
        padding={0.3}
        labelSkipWidth={12}
        labelSkipHeight={12}
        indexBy="label"
        valueScale={{
          type: "symlog",
        }}
        axisBottom={{
          tickRotation: -45,
        }}
        onClick={(e) => {
          if (onClick != null) {
            onClick(e.data);
          }
        }}
        tooltip={({ indexValue, value, color, label, id }) => (
          <Card body>
            <Rectangle color={color} />
            {keys != null && keys.length > 1 ? label : indexValue}:{" "}
            <b>{value}</b>
          </Card>
        )}
        legends={legends}
      />
    </ChartWrapper>
  );
};

export default BarChart;
