import useProject from "../../../hooks/useProject";
import React, { useMemo } from "react";
import GroupListItem from "./GroupListItem";
import styled from "styled-components";
import useCoreSelector from "../../../hooks/useCoreSelector";
import _ from "lodash";
import { CosmosCoreRootState } from "../../../store";

const List = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
`;

export interface GroupsListProps {
  onSelect?: (groupName: string) => void;
}

const GroupsList = ({ onSelect }: GroupsListProps) => {
  const { project } = useProject();
  const projectGroups = useMemo(
    () => _.sortBy(project?.groups, (g) => String(g.displayName).toLowerCase()),
    [project]
  );

  const selectedGroup = useCoreSelector<CosmosCoreRootState, string | null>(
    (state) => state.usermanagement.selectedGroup
  );

  return (
    <List>
      {projectGroups.map((group) => (
        <GroupListItem
          key={group.name}
          group={{
            ...group,
            protected: true,
          }}
          onSelect={(groupName) => {
            if (onSelect != null) {
              onSelect(groupName);
            }
          }}
          activeGroupName={selectedGroup}
        />
      ))}
    </List>
  );
};

export default GroupsList;
