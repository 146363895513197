import GatewayApi from "./conf/GatewayApi";

const gatewayApi = GatewayApi.build();

export type commentStatusType =
  | "open"
  | "closed"
  | "archived"
  | "deleted"
  | "information";

export const commentStatus: Record<string, commentStatusType> = {
  OPEN: "open",
  CLOSED: "closed",
  ARCHIVED: "archived",
  DELETED: "deleted",
  INFORMATION: "information",
};

export type CommentPosition = {
  pageNumber: number;
};

export interface Comment {
  id: string | null;
  resourceId?: string;
  createdAt: number;
  comments: Comment[];
  mentions: string[];

  authorcommonname?: string;
  message: string;
  parameter: string | null;

  position: CommentPosition | null;
  author?: string;
  number?: number;
  status?: commentStatusType;
  principalId?: string;
}

const postComment = (
  resourceId: string,
  parameter: string | null,
  comment: Comment,
  nodeId?: string
) => {
  const url =
    nodeId === null
      ? `/repository/res/${resourceId}/comments`
      : `/repository/res/${resourceId}/comments/${nodeId}`;

  return gatewayApi
    .post(url, comment)
    .then((response) => Promise.resolve(response.data));
};

const getComments = (resourceId: string, parameter?: string) => {
  return gatewayApi
    .get<Comment[]>(`/repository/res/${resourceId}/comments`)
    .then((response) => Promise.resolve(response.data));
};

const getUserLatestChatComments = () => {
  return gatewayApi
    .get<Comment[]>(`/user/comments`)
    .then((response) => Promise.resolve(response.data));
};

const updateComment = (commentId: string, comment: Comment) => {
  return gatewayApi
    .put(`/comment/${commentId}`, comment)
    .then((response) => Promise.resolve(response.data));
};

const deleteComment = (commentId: string) => {
  return gatewayApi
    .delete(`/comment/${commentId}`)
    .then((response) => Promise.resolve(response.data));
};

const commentApi = {
  postComment,
  getComments,
  getUserLatestChatComments,

  updateComment,
  deleteComment,
};

export default commentApi;
