/* eslint-disable func-names */
import { DateTime } from 'luxon';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as actions from '../Actions/types';
import { notify } from '../Actions/ui';
import { setUserSettings } from '../Actions/userSettings';
import masterdata from '../Api/masterdata';
import callApi from './Effects/callApi';
import { getPrincipalId } from '../Selectors/userdata';
import callNotify from './Effects/callNotify';
import {
  fetchNotificationPreferences,
  setNotificationPreferences,
  setUserDataLoading,
} from '../Actions/userdata';
import userApi from '../Api/user';
import userSettingsScope from '../Constants/userSettingsScope';
import _ from 'lodash';
import { getAppearance } from '../Selectors/ui';

function* saveUserSettings(
  projectCode,
  id,
  settingsScope,
  settingsData = null
) {
  const principalId = yield select(getPrincipalId);
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  let entry = {
    principalId,
    settingsScope,
    settingsData: btoa(JSON.stringify(settingsData)),
  };

  if (projectCode != null) {
    entry = {
      ...entry,
      projectCode,
    };
  }

  const cacheKey =
    projectCode != null
      ? `${settingsScope}:${principalId}:${projectCode}`
      : `${settingsScope}:${principalId}`;

  if (id != null) {
    return yield callApi(
      masterdata.updateMasterDataEntry,
      docareaName,
      'USERS_SETTINGS',
      id,
      entry,
      cacheKey
    );
  }

  return yield callApi(
    masterdata.postMasterDataEntry,
    docareaName,
    'USERS_SETTINGS',
    entry,
    cacheKey
  );
}

function* fetchUserSettings(settingsScope, projectCode) {
  const principalId = yield select(getPrincipalId);
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  const where =
    projectCode != null
      ? { principalId, settingsScope, projectCode }
      : { principalId, settingsScope };

  const cacheKey =
    projectCode != null
      ? `${settingsScope}:${principalId}:${projectCode}`
      : `${settingsScope}:${principalId}`;

  const [result] = yield callApi(
    masterdata.getMasterData,
    docareaName,
    'USERS_SETTINGS',
    ['*'],
    {
      where,
      orderBy: {
        createdAt: 'desc',
      },
    },
    cacheKey
  );

  if (result == null) {
    console.info(
      `There is not saved any users setting in scope ${settingsScope} yet.`
    );
    return null;
  }

  return result.properties.reduce((acc, cur) => {
    if (cur.name === 'settingsData') {
      acc[cur.name] = JSON.parse(atob(cur.value));
    } else {
      acc[cur.name] = cur.value;
    }

    return acc;
  }, {});
}

export function* fetchAndStoreSettings(projectCode, settingsScope) {
  const userSettings = yield callNotify(
    setUserDataLoading,
    'Loading User Settings.',
    fetchUserSettings,
    settingsScope,
    projectCode
  );

  if (userSettings != null) {
    const { id, settingsData } = userSettings;

    yield put(setUserSettings(settingsScope, id, settingsData));
    return settingsData;
  } else {
    yield put(setUserSettings(settingsScope, null));
  }

  return [];
}

export function* saveAndFetchSettings(
  projectCode,
  settingsScope,
  settingsData
) {
  const userSettings = yield select((state) => state.ui.userSettings);

  const id = _.get(userSettings, `${settingsScope}.id`);

  const result = yield callNotify(
    setUserDataLoading,
    'Saving User Settings.',
    saveUserSettings,
    projectCode,
    id,
    settingsScope,
    settingsData
  );

  yield call(fetchAndStoreSettings, projectCode, settingsScope);

  if (result != null) {
    yield put(notify('User settings have been saved!', 'success'));
  }
}

export function* updateAppearance(updatedAppearance) {
  const appearance = yield select(getAppearance);
  yield call(saveAndFetchSettings, null, userSettingsScope.APPEARANCE, {
    ...appearance,
    ...updatedAppearance,
  });
}

function* userSettingsSaga() {
  yield takeLatest(
    actions.userSettings.SAVE_USER_GLOBAL_SETTINGS,
    function* (action) {
      const { scope, data } = action.payload;
      yield call(saveAndFetchSettings, null, scope, data);
    }
  );

  yield takeLatest(actions.userSettings.SET_SKIP_GUIDE, function* ({ skip }) {
    yield call(updateAppearance, {
      skipGuide: !!skip,
    });
  });
  yield takeLatest(actions.userSettings.SET_SKIP_GDPR, function* ({ skip }) {
    yield call(updateAppearance, {
      skipGDPR: !!skip,
      GDPRConfirmedAt: DateTime.now().toMillis(),
    });
  });
  yield takeLatest(
    actions.userSettings.TOGGLE_METADATA_EDITOR,
    function* ({ metadataEditor }) {
      yield call(updateAppearance, {
        metadataEditor,
      });
    }
  );

  yield takeLatest(
    actions.userSettings.FETCH_NOTIFICATION_PREFERENCES,
    function* () {
      const notificationPreferences = yield callNotify(
        setUserDataLoading,
        'Loading Notification Preferences.',
        userApi.getNotificationPreferences
      );

      yield put(setNotificationPreferences(notificationPreferences));
    }
  );
  yield takeLeading(
    actions.userSettings.TOGGLE_NOTIFICATION_PREFERENCE,
    function* ({ payload }) {
      const { name, value, all } = payload;

      if (all && name == null) {
        yield callNotify(
          setUserDataLoading,
          'Updating Notification Preference.',
          userApi.toggleAllNotificationPreferences,
          value
        );
      } else {
        yield callNotify(
          setUserDataLoading,
          'Updating Notification Preference.',
          userApi.toggleNotificationPreference,
          name,
          value
        );
      }

      yield put(notify('Email Preferences were Updated.', 'success'));

      yield put(fetchNotificationPreferences());
    }
  );
}

export default userSettingsSaga;
