import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContextMenu } from "cosmos-components";
import _ from "lodash";
import AccessEditModal from "./AccessEditModal";
import useProject from "../../../hooks/useProject";
import resourcePermissions from "../constants/resourcePermissions";
import useValueset from "../../../hooks/useValueset";
import SimpleTable from "../../../components/SimpleTable";
import useOrganization from "../../../hooks/useOrganization";
import AccessManagementContext from "../contexts/AccessManagementContext";
import { Permission } from "../constants/Permission";
import styled from "styled-components";
import { ProjectMember } from "../../../contexts/ProjectContext";
import useProjectCompetence from "../../../hooks/useProjectCompetence";
import { useCurrentUser } from "../../../hooks";

interface UserDataPermission extends Permission {
  domainName: string;
  membership: string | null;
  group: boolean;
  subRows: UserDataPermission[];
  name: string;
  inherited: boolean;
  parentGroupName: string | null;
  creator?: string;
}

const getMembersMap = (members: Record<string, ProjectMember[]>) =>
  _(members)
    .flatMap((x) => x)
    .keyBy("principalId")
    .value();

const DivButton = styled.div`
  color: ${(props) => props.theme.primary};
  display: inline-block;

  &:hover {
    text-decoration: underline !important;
  }
`;

export type ResourceFilter = {
  Category?: string;
};

export interface ResourceAccessTableProps {
  disabled?: boolean;
}

const ResourceAccessTable = ({ disabled }: ResourceAccessTableProps) => {
  const {
    resourcePermissions: permissions,
    revokePermission,
    togglePermissionActive,
  } = useContext(AccessManagementContext);
  const { t } = useTranslation("property");
  const { members, memberGroups, addProjectMember } = useProject();
  const { getPositionInfo } = useOrganization();
  const { removeProjectMembers } = useProjectCompetence();
  const { principalId } = useCurrentUser();

  const [showEditPermissionModal, setShowEditPermissionModal] = useState(false);
  const [editedPermission, setEditedPermission] =
    useState<UserDataPermission | null>(null);

  const { registerProperties } = useValueset();
  useMemo(() => {
    const propertiesMap = _.keyBy(resourcePermissions, "name");
    registerProperties(propertiesMap);
  }, [registerProperties]);

  const membersMap = useMemo(() => getMembersMap(members), [members]);
  const memberGroupsMap = useMemo(
    () => _.keyBy(memberGroups, "name"),
    [memberGroups]
  );

  const generateMembership = useCallback(
    (user: ProjectMember) => {
      if (user == null) {
        return null;
      }

      const memberGroup = memberGroupsMap[user?.groupName];

      const isExternal = user.domainName === "ExternalUsers";
      if (user.group) {
        return isExternal ? "External Users Domain" : "Internal Users Domain";
      }

      const suffixDomain = (input: string) => {
        return isExternal ? `${input} (ext)` : input;
      };

      if (memberGroup != null) {
        return suffixDomain(memberGroup.formattedName);
      }

      if (user.departmentTeam != null) {
        const { department } = getPositionInfo(user.departmentTeam);
        return suffixDomain(department);
      }

      if (isExternal && user.email != null) {
        return user.email;
      }

      return suffixDomain(user.domainName);
    },
    [getPositionInfo, memberGroupsMap]
  );

  const memberToPermission = useCallback(
    (member: ProjectMember, permission: Permission, parent: ProjectMember) => {
      const getName = () => {
        if (member.domainName === "ExternalUsers") {
          return `${member.formattedName} (${member.email})`;
        }

        return member.formattedName;
      };

      return {
        ...permission,
        principalId: member.principalId,
        permittedusercommonname: getName(),
        inherited: true,
        parentGroupName: parent.name,
        creator: parent.creatorPrincipalId,

        id: null,

        domainName: member.domainName,
        membership: generateMembership(member),
        group: member.group,
        subRows: [],
        name: member.name,
      } as UserDataPermission;
    },
    [generateMembership]
  );

  const userDataPermissions: UserDataPermission[] = useMemo(() => {
    return permissions
      .filter((permission) => permission.principalId != null)
      .map((permission) => {
        const user = membersMap[permission.principalId];

        return {
          ...permission,
          domainName: user?.domainName,
          group: !!user?.group,
          membership: generateMembership(user),
          name: user?.name,
          parentGroupName: null,
          subRows: user?.group
            ? _.map(members[user.name], (m) =>
                memberToPermission(m, permission, user)
              )
            : [],
          inherited: false,
          creator: user?.creatorPrincipalId,
        };
      });
  }, [
    generateMembership,
    memberToPermission,
    members,
    membersMap,
    permissions,
  ]);

  const renderMenu = (selectedItems: UserDataPermission[]) => {
    const wrongSelection = selectedItems.some((p) => p.id == null);

    const activeDistribution = _(selectedItems)
      .map("active")
      .filter((x) => x != null)
      .uniq()
      .value();
    const selectionActive = _.every(activeDistribution);

    const isGroupMenu = _.get(selectedItems, "[0].group", false);

    const isOwningItems = selectedItems.every(
      (si) => si.creator === principalId
    );

    return (
      <>
        {isGroupMenu && (
          <>
            <ContextMenu.Item
              disabled={selectedItems.length > 1 || !isOwningItems}
              onClick={() => {
                console.log(selectedItems);
                const { name, domainName } = selectedItems[0];
                addProjectMember(name, domainName);
              }}
            >
              Add Group Member
            </ContextMenu.Item>
            <ContextMenu.Item separator />
          </>
        )}

        <ContextMenu.Item
          disabled={activeDistribution.length > 1 || wrongSelection}
          onClick={() => {
            togglePermissionActive(
              _.compact(selectedItems.map((p) => p.id)),
              !selectionActive
            );
          }}
        >
          {selectionActive ? t("common.deactivate") : t("common.activate")}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={selectedItems.length !== 1 || wrongSelection}
          onClick={() => {
            setEditedPermission(selectedItems[0]);
            setShowEditPermissionModal(true);
          }}
        >
          {t("common.edit")}
        </ContextMenu.Item>
        <ContextMenu.Item separator />
        <ContextMenu.Item
          disabled={
            selectedItems.length === 0 ||
            !(
              selectedItems.every((p) => p.id != null) ||
              (selectedItems.every((p) => p.inherited) && isOwningItems) ||
              (selectedItems.some((p) => p.id != null) && isOwningItems)
            )
          }
          onClick={() => {
            const principalIds = _.compact(_.map(selectedItems, "principalId"));

            if (selectedItems.every((p) => p.inherited)) {
              const parentGroupNames = _.map(selectedItems, "parentGroupName");

              if (
                parentGroupNames.length === 0 ||
                parentGroupNames.length > 1 ||
                parentGroupNames[0] == null
              ) {
                throw new Error(
                  "Cannot remove users across multiple project groups!"
                );
              }

              const membersList = members[parentGroupNames[0]];

              removeProjectMembers(
                _.compact(
                  membersList.filter((m) =>
                    principalIds.includes(m.principalId)
                  )
                )
              );
            } else if (
              (selectedItems.some((p) => p.id != null) && isOwningItems) ||
              selectedItems.every((p) => p.id !== null)
            ) {
              const permissionIds = _.compact(_.map(selectedItems, "id"));
              revokePermission(permissionIds);
            }
          }}
        >
          {t("common.delete")}
        </ContextMenu.Item>
      </>
    );
  };

  return (
    <>
      <SimpleTable<UserDataPermission>
        structurable
        condensed
        data={userDataPermissions}
        properties={resourcePermissions}
        renderContextMenu={renderMenu}
        disableContextMenu={disabled}
        renderCell={(propertyName, value, label, item) => {
          let renderLabel = label;

          if (propertyName === "accessLevel") {
            const accessLevel = Array.isArray(item.accessLevel)
              ? _.first(item.accessLevel)
              : item.accessLevel;

            if (item.domainName === "ExternalUsers" && accessLevel === 7) {
              renderLabel = "Ext-" + renderLabel;
            }
          } else if (propertyName === "permittedusercommonname") {
            if (item.group && item.creator === principalId) {
              return <DivButton>{renderLabel}</DivButton>;
              // return (
              //   <PrincipalInfoButton permission={item} label={renderLabel} />
              // );
            }

            // if (item.group && item?.creator != null) {
            //   const author = membersMap[item.creator];
            //   return `${label} (${author?.formattedName})`;
            // }
          }

          if (item.id == null) {
            return <span className="text-info">{renderLabel}</span>;
          }

          return renderLabel;
        }}
      />

      <AccessEditModal
        show={showEditPermissionModal}
        onClose={() => setShowEditPermissionModal(false)}
        permission={editedPermission}
      />
    </>
  );
};

export default ResourceAccessTable;
