import { PancoSelect } from "cosmos-components";
import { groupType } from "cosmos-config/generator";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import SimpleFormGroup from "../../../../components/SimpleFormGroup";
import useProperties from "../../../../hooks/useProperties";
import { PropertyEditorModalProps } from "../../types";

export interface MovePropertyModalProps extends PropertyEditorModalProps {
  onChange?: (param: MoveChange) => void;
}

export interface MoveChange {
  targetGroup?: string;
  after?: string;
}

const MovePropertyModal = ({
  show,
  onClose,
  onChange,
}: MovePropertyModalProps) => {
  const [state, setState] = useState<MoveChange>({});
  const { groups } = useProperties();

  useEffect(() => {
    if (!show) {
      setState({});
    }
  }, [show]);

  const groupOptions = useMemo(
    () =>
      groups
        .filter((g) => g.type === groupType.PROPERTIES)
        .map((g) => ({ label: g.label, value: g.name })),
    [groups]
  );

  const afterPropertyOptions = useMemo(() => {
    const group = groups.find((g) => g.name === state.targetGroup);
    const properties = group?.properties || [];
    return properties.map((c) => ({
      label: `${c.label} (${c.name})`,
      value: c.name,
    }));
  }, [groups, state.targetGroup]);

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>Move Property</Modal.Title>
        <CloseButton className="close-icon" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <SimpleFormGroup label="Target Group" inline>
          <PancoSelect
            options={groupOptions}
            value={_.compact([state.targetGroup])}
            onChange={({ value }) => setState({ targetGroup: _.first(value) })}
          />
        </SimpleFormGroup>

        {state.targetGroup != null && (
          <SimpleFormGroup label="Move After" inline>
            <PancoSelect
              value={_.compact([state.after])}
              onChange={({ value }) =>
                setState((s) => ({ ...s, after: _.first(value) }))
              }
              options={afterPropertyOptions}
              clearable
            />
          </SimpleFormGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right">
          <Button
            onClick={() => {
              if (onChange != null) {
                onChange(state);
              }
            }}
            disabled={state.targetGroup == null}
          >
            Move
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MovePropertyModal;
