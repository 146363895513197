import * as actions from './types';

/**
 * Reporting action generators.
 * @module actions/reporting
 * @category Actions
 */

/**
 * Action for setting reporting results.
 * @function
 * @param {} results -
 * @param {} axis -
 */
export const setReportingResults = (results, axis) => ({
  type: actions.reporting.REPORTING_RESULTS_SET,
  results,
  axis,
});

/**
 * Action to run reporting query.
 * @function
 * @param {function} callback - action results callback
 */
export const runReportingQuery = (callback = () => {}) => ({
  type: actions.reporting.REPORTING_RUN_QUERY,
  callback,
});

/**
 * Action for setting reporting documents.
 * @function
 * @param {} documents -
 */
export const setReportingDocuments = (documents) => ({
  type: actions.reporting.REPORTING_DOCUMENTS_SET,
  documents,
});

/**
 * Action for setting reporting loading status.
 * @function
 * @param {boolean} loading -
 * @param {string} message - message text
 */
export const setReportingLoading = (loading = true, message) => ({
  type: actions.reporting.REPORTING_SET_LOADING,
  loading,
  message,
});

/**
 * Action for exporting reporting to XLS.
 * @function
 * @param {function} callback - action results callback
 */
export const exportReportingToXls = (callback = () => {}) => ({
  type: actions.reporting.REPORTING_EXPORT_TO_XLS,
  callback,
});

/**
 * Action for setting reporting groups.
 * @function
 * @param {} groups -
 */
export const setReportingGroups = (groups) => ({
  type: actions.reporting.REPORTING_GROUPS_SET,
  groups,
});
