/* eslint-disable func-names */
import { eventChannel } from 'redux-saga';
import { take, put, takeLeading, delay, race, call } from 'redux-saga/effects';
import {
  LOGIN_ADFS,
  LOGIN_ADFS_ATTEMPT,
  REFRESH_ADFS,
  REFRESH_ADFS_ATTEMPT,
} from '../../Actions/types/userdata';
import {
  login,
  loginADFSError,
  loginADFSSuccess,
} from '../../Actions/userdata';
import { generateUrlParams } from '../../Utils';
import { generateAuthorizeUrl } from './oauthUrlHelper';

/**
 * @module saga/adfs
 * @category Sagas
 */

const TokenType = {
  CODE: 'code',
  REFRESH_TOKEN: 'refresh_token',
};

const redirectUri = process.env.REACT_APP_ADAL_REDIRECT_URI;
const BASE_URL = process.env.REACT_APP_GATEWAY_URL;

/* Making a POST request to the server and returning the response. */
function* requestAccessToken(token, tokenType = TokenType.CODE) {
  const response = yield call(fetch, `${BASE_URL}adfs/oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: generateUrlParams({
      grant_type:
        tokenType === TokenType.CODE ? 'authorization_code' : 'refresh_token',
      [tokenType]: token,
      redirect_uri: redirectUri,
    }),
  });

  const responseBody = yield call([response, response.json]);
  const {
    access_token: accessToken,
    id_token: idToken,
    refresh_token: refreshToken,
    token_type: tknType,
  } = responseBody;

  return {
    accessToken,
    idToken,
    refreshToken,
    tokenType: tknType,
  };
}

/* The above code is a saga that is listening to the LOGIN_ADFS action. When the action is dispatched,
it opens a new window and waits for the tokenChannel to emit an event. When the event is emitted, it
closes the window and dispatches the login action. */
export default function* adfsSaga() {
  const tokenChannel = yield eventChannel((emitter) => {
    const messageListener = (event) => {
      const { data } = event;
      if (data != null && data.accessToken != null) {
        emitter({ type: 'ADFS_TOKEN_RECEIVED', token: data.accessToken });
      }
    };

    window.addEventListener('message', messageListener, false);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  });

  yield takeLeading(LOGIN_ADFS, function* () {
    const windowReference = window.open(
      generateAuthorizeUrl(),
      'RWE ADFS',
      'toolbar=no, menubar=no, width=500, height=700, top=100, left=100'
    );

    function* checkWindowClose() {
      while (true) {
        if (windowReference.closed) {
          break;
        }

        yield delay(1000);
      }
    }

    try {
      const [action] = yield race([take(tokenChannel), call(checkWindowClose)]);
      const code = action?.token;

      if (code == null) {
        throw Error('Access code is not present!');
      }

      const { idToken, refreshToken } = yield call(requestAccessToken, code);

      if (idToken == null) {
        throw Error('Access token is not retrieved!');
      }

      if (refreshToken != null) {
        localStorage.setItem('refreshToken', refreshToken);
      }

      localStorage.setItem('authToken', `Bearer ${idToken}`);
      localStorage.setItem('domain', 'Users');
      yield put(loginADFSSuccess());
    } catch (err) {
      console.error(err);
      yield put(loginADFSError());
    } finally {
      windowReference.close();
    }
  });

  yield takeLeading(
    [LOGIN_ADFS_ATTEMPT, REFRESH_ADFS_ATTEMPT],
    function* (action) {
      const { type } = action;

      const iframe = document.createElement('iframe');

      iframe.src = generateAuthorizeUrl();
      iframe.name = 'RWE ADFS';
      iframe.height = 0;
      iframe.width = 0;
      iframe.tabIndex = -1;
      iframe.classList.add('d-none');
      iframe.loading = 'eager';
      iframe.referrerPolicy = 'origin';
      document.body.appendChild(iframe);

      try {
        const [action] = yield race([take(tokenChannel), delay(3000)]);
        const code = action?.token;

        if (code == null) {
          throw Error('Access code is not present!');
        }

        const { idToken, refreshToken } = yield call(requestAccessToken, code);

        if (refreshToken != null) {
          localStorage.setItem('refreshToken', refreshToken);
        }

        if (type === LOGIN_ADFS_ATTEMPT) {
          localStorage.setItem('authToken', `Bearer ${idToken}`);
          localStorage.setItem('domain', 'Users');
        } else {
          localStorage.setItem('authToken', `Bearer ${idToken}`);
        }

        yield put(loginADFSSuccess());
      } catch (err) {
        console.error(err);
        yield put(loginADFSError());
      } finally {
        document.body.removeChild(iframe);
      }
    }
  );

  yield takeLeading(REFRESH_ADFS, function* () {
    const currentRefreshToken = localStorage.getItem('refreshToken');

    try {
      if (currentRefreshToken == null) {
        throw Error('Refresh token not present!');
      }

      const { idToken, refreshToken } = yield call(
        requestAccessToken,
        currentRefreshToken,
        TokenType.REFRESH_TOKEN
      );

      if (refreshToken != null) {
        localStorage.setItem('refreshToken', refreshToken);
      }

      if (idToken != null) {
        localStorage.setItem('authToken', `Bearer ${idToken}`);
        yield put(loginADFSSuccess());
      }
    } catch (err) {
      console.error(err);
    }

    yield put(loginADFSError());
  });
}
