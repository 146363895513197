import { useCallback, useMemo } from "react";
import Resource from "../types/resource";
import useRepositorySearch, {
  UseRepositorySearchProps,
} from "./useRepositorySearch";
import useProperties from "./useProperties";
import _ from "lodash";

export interface UseResourceBreadcrumbsProps {
  resource?: Resource;
}

const useResourceBreadcrumbs = ({ resource }: UseResourceBreadcrumbsProps) => {
  const { render, folders } = useProperties();

  const searchProps: UseRepositorySearchProps = useMemo(() => {
    if (resource == null) {
      return {
        resourceId: "",
        enabled: false,
      };
    }

    const { pathidentifiers } = resource;
    const projectResourceId = `RWE$NOTSET$${pathidentifiers[1]}$1$NOTSET`;

    if (pathidentifiers.length <= 3) {
      return {
        resourceId: projectResourceId,
        enabled: false,
      };
    }

    return {
      resourceId: projectResourceId,
      enabled: true,
      filter: {
        identifier: _.dropRight(pathidentifiers),
      },
      subtree: true,
      substructure: true,
    };
  }, [resource]);

  const { data, loading } = useRepositorySearch(searchProps);
  const folderPropsMap = useMemo(() => _.keyBy(folders, "name"), [folders]);

  const getResourceLabel = useCallback(
    (res: Resource) => {
      const getResourceDefault = () => ({
        identifier: res.identifier,
        label: res.displayname,
      });

      if (res.foldertype == null || res.resourcetype === 2) {
        return getResourceDefault();
      }

      const folderProp = folderPropsMap[res.foldertype];
      const titlePropertyName = folderProp?.titlePropertyName;

      if (titlePropertyName == null) {
        return getResourceDefault();
      }

      return {
        identifier: res.identifier,
        label: render(titlePropertyName, res[titlePropertyName]),
      };
    },
    [folderPropsMap, render]
  );

  const breadcrumbs = useMemo(() => {
    if (resource == null) {
      return [];
    }

    const resourceLabel = getResourceLabel(resource);

    if (
      resource?.pathidentifiers != null &&
      resource.pathidentifiers.length <= 3
    ) {
      return [resourceLabel];
    }

    return _.concat(data.map(getResourceLabel), resourceLabel);
  }, [data, getResourceLabel, resource]);

  return {
    loading,
    breadcrumbs,
  };
};

export default useResourceBreadcrumbs;
