import React, { createElement as h, useState } from "react";
import DistinctPropertyData from "../providers/DistinctPropertyData";
import ChoroplethChart, {
  ChoroplethChartScope,
} from "../renderers/ChoroplethChart";
import { CosmosWidget, CosmosWidgetProps } from "../../types/CosmosWidget";

const DocumentsPerCountry = ({
  propertyName,
  resourceType,
  onSelect,
}: CosmosWidgetProps): CosmosWidget => {
  const [scope, setScope] = useState<ChoroplethChartScope>("NA");
  const scopes = {
    EU: "Europe",
    NA: "North America",
    SA: "South America",
  };

  return h("div", {}, [
    h(
      "select",
      {
        className: "form-control",
        onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
          //@ts-ignore
          setScope(e.target.value);
        },
        defaultValue: scope,
      },
      Object.entries(scopes).map(([key, label]) =>
        h("option", { value: key, key: `option-${key}` }, label)
      )
    ),
    h(DistinctPropertyData, {
      propertyName,
      resourceType,
      children: ({ data }) => {
        return h(ChoroplethChart, { data, scope });
      },
    }),
  ]);
};

export default DocumentsPerCountry;
