import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PancoSelect, PancoSwitch } from "cosmos-components";
import {
  AnticipatedValue,
  Property,
} from "cosmos-config/lib/property/property";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import FormProperty from "../../../../components/form/FormProperty";
import _ from "lodash";

export interface PropertyAnticipatedValuesProps {
  property?: Property;
  anticipatedValues?: AnticipatedValue[];
  onChange?: (property: Partial<AnticipatedValue>[]) => void;
  className?: string;
}

const listingColorOptions = [
  {
    value: "#6c757d",
    label: "Muted",
  },
  {
    value: "#e7343f",
    label: "Danger",
  },
  {
    value: "#ffcc00",
    label: "Warning",
  },
  {
    value: "#5ab88f",
    label: "Success",
  },
];

const PropertyAnticipatedValues = ({
  property,
  onChange,
  className,
  anticipatedValues,
}: PropertyAnticipatedValuesProps) => {
  const handleChange = (
    key: string | number,
    updateCallback: (
      av: Partial<AnticipatedValue>
    ) => Partial<AnticipatedValue> | null
  ) => {
    if (onChange == null) {
      return;
    }

    const values = _.cloneDeep(anticipatedValues) || [];
    const itemIndex = values.findIndex((av) => av.value === key);

    if (itemIndex === -1) {
      onChange(_.compact([...values, updateCallback({ value: key })]));
    } else {
      onChange(
        _.compact(
          values.map((av) => {
            return av.value === key ? updateCallback(av) : av;
          })
        )
      );
    }
  };

  const renderFormProperty = (
    value: string | null | number,
    disabled: boolean
  ) => {
    if (property != null) {
      return (
        <FormProperty
          id={`creator-${anticipatedValues?.length || 0}`}
          disabled={disabled}
          controlOnly
          property={property as Property}
          value={_.compact([value])}
          onChange={({ value }) => {
            const [v] = value || [];
            handleChange(v, (av) => {
              console.log(av);
              return av;
            });
          }}
        />
      );
    }
  };

  return (
    <div className={className}>
      {(anticipatedValues || []).map((av) => (
        <Row key={av.value} className="mb-3">
          <Col sm={4}>{renderFormProperty(av.value, true)}</Col>

          <Col sm={4}>
            <div className="d-flex align-items-center">
              Document Watermark:
              <PancoSwitch
                disabled={property?.resourceType === 1}
                value={av.watermark}
                onChange={({ value }) => {
                  handleChange(av.value, (av) => ({
                    ...av,
                    watermark: !!value,
                  }));
                }}
              />
            </div>
          </Col>

          <Col sm={3}>
            <PancoSelect
              value={_.compact([av.listingColor])}
              options={listingColorOptions}
              onChange={({ value }) => {
                const [v] = value || [];
                handleChange(av.value, (av) => ({ ...av, listingColor: v }));
              }}
            />
          </Col>

          <Col sm={1} className="text-center">
            <Button
              variant="link"
              className="text-danger"
              onClick={() => {
                handleChange(av.value, () => null);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </Col>
        </Row>
      ))}

      <Row>
        <Col md={4}>{renderFormProperty(null, false)}</Col>
      </Row>
    </div>
  );
};

export default PropertyAnticipatedValues;
