import { property, groupType, valuesetSource } from "cosmos-config/generator";

const projectGroupCreate = [
  property("Label", "label"),
  property("Type")
    .selectableAttribute("type", "group_type", valuesetSource.STATIC_VALUESET)
    .valueset(
      Object.entries(groupType).map(([key, value]) => ({
        value,
        label: key,
      }))
    ),
];

export default projectGroupCreate;
