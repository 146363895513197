import React, { useMemo } from "react";
import { ProjectPortalRule } from "../../../../contants/projectPortal";
import { Button, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { PancoInput, PancoSelect } from "cosmos-components";
import { Property } from "cosmos-config/lib/property/property";
import _ from "lodash";
import { useProperties } from "../../../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FormProperty } from "../../../../components/form";

export interface PortalRuleEditorProps {
  portalRule?: ProjectPortalRule;
  onUpdate?: (propertyName: string, value: any) => void;
  targetProperties?: Property[];
  onRemove?: () => void;
}

const operandTypes = {
  PROPERTY: "Property",
  VALUE: "Value",
  MULTIVALUE: "Multi Value",
};

const mapPropertiesToOptions = (properties?: Property[]) => {
  if (properties != null) {
    return properties.map((p) => ({
      value: p.name,
      label: `${p.label} (${p.name})`,
    }));
  }

  return [];
};

const PortalRuleEditor = ({
  portalRule,
  onUpdate,
  targetProperties,
  onRemove,
}: PortalRuleEditorProps) => {
  const { properties } = useProperties();

  const operandTypeTitle =
    portalRule?.operandType != null
      ? operandTypes[portalRule.operandType]
      : "Operand Type";

  const triggerUpdate = (propertyName: string, value: any) => {
    if (onUpdate != null) {
      onUpdate(propertyName, value);
    }
  };

  const targetPropertiesOptions = useMemo(
    () => mapPropertiesToOptions(targetProperties),
    [targetProperties]
  );

  const projectPropertiesOptions = useMemo(
    () => mapPropertiesToOptions(properties),
    [properties]
  );

  const targetPropertiesMap = useMemo(
    () => _.keyBy(targetProperties, "name"),
    [targetProperties]
  );

  const renderOperand = () => {
    if (portalRule?.operandType === "VALUE") {
      const property = targetPropertiesMap[portalRule.targetProperty];
      if (property != null) {
        return (
          <div className="flex-grow-1">
            <FormProperty
              property={property}
              controlOnly
              boxed
              onChange={({ value }) => {
                triggerUpdate("operand", _.first(value));
              }}
              value={_.compact([portalRule.operand])}
            />
          </div>
        );
      }

      return (
        <PancoInput
          className="flex-grow-1"
          onChange={({ value }) => {
            triggerUpdate("operand", value);
          }}
          boxed
        />
      );
    }

    return (
      <PancoSelect
        className="flex-grow-1"
        options={projectPropertiesOptions}
        boxed
        value={_.compact([portalRule?.operand])}
        onChange={({ value }) => {
          triggerUpdate("operand", _.first(value));
        }}
      />
    );
  };

  return (
    <div className="mb-3">
      <InputGroup>
        <DropdownButton
          as={InputGroup.Prepend}
          variant="outline"
          title={operandTypeTitle}
          id="input-group-dropdown-1"
        >
          {Object.entries(operandTypes).map(([value, label]) => (
            <Dropdown.Item onClick={() => triggerUpdate("operandType", value)}>
              {label}
            </Dropdown.Item>
          ))}
        </DropdownButton>

        {renderOperand()}

        <InputGroup.Append>
          <InputGroup.Text id="basic-addon2">=</InputGroup.Text>
        </InputGroup.Append>

        <PancoSelect
          options={targetPropertiesOptions}
          boxed
          value={_.compact([portalRule?.targetProperty])}
          onChange={({ value }) => {
            triggerUpdate("targetProperty", _.first(value));
            // triggerUpdate("operand", null);
          }}
        />

        <InputGroup.Append>
          <Button
            variant="outline-danger"
            onClick={() => {
              if (onRemove != null) {
                onRemove();
              }
            }}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};

export default PortalRuleEditor;
