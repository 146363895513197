import React, { useMemo } from "react";
import ProjectCompetenceContext from "../contexts/ProjectCompetenceContext";
import { useMutation } from "@tanstack/react-query";
import useCoreDispatch from "../hooks/useCoreDispatch";
import { fetchAllProjectMembers } from "../actions/projectActions";
import projectApi from "../apis/projectApi";
import { useProject } from "../hooks";
import { ProjectMember } from "../contexts/ProjectContext";
import _ from "lodash";
import useCoreSelector from "../hooks/useCoreSelector";
import { CosmosCoreRootState } from "../store";

type GroupMutationPayload = {
  groupName: string;
  group?: Partial<ProjectMember>;
  type: "create" | "update" | "delete";
  domainName: string;
};

type MemberMutationPayload = {
  principalId: string;
  type: "remove";
  groupName: string;
};

export interface ProjectCompetenceProviderProps {
  children?: React.ReactNode;
}

const ProjectCompetenceProvider = ({
  children,
}: ProjectCompetenceProviderProps) => {
  const dispatch = useCoreDispatch();
  const { project } = useProject();
  const projectLoading = useCoreSelector<CosmosCoreRootState, boolean>(
    (state) => state.project.loading
  );

  const groupMutation = useMutation({
    mutationKey: [],
    mutationFn: ({
      groupName,
      group,
      type,
      domainName,
    }: GroupMutationPayload) => {
      if (project?.code == null || groupName == null) {
        throw new Error("Project code cannot be null!");
      }

      if (type === "update") {
        return projectApi.updateProjectSubGroup(
          project.code,
          domainName,
          groupName,
          group
        );
      }

      if (type === "create") {
        let name = (group?.commonname || "").replace(/[^a-zA-Z0-9]/gi, "_");
        name = `${groupName}_${String(name).trim().toUpperCase()}`;

        return projectApi.createProjectSubGroup(
          project.code,
          domainName,
          groupName,
          {
            ...group,
            name,
          }
        );
      }

      if (type === "delete") {
        return projectApi.deleteProjectSubGroup(
          project.code,
          domainName,
          groupName
        );
      }

      throw new Error("Activity not supported!");
    },
    onSuccess: () => {
      dispatch(fetchAllProjectMembers());
      // localDispatch({
      //   type: actions.RESET,
      // });
    },
  });

  const memberMutation = useMutation({
    mutationKey: [],
    mutationFn: ({ type, principalId, groupName }: MemberMutationPayload) => {
      if (project?.code == null) {
        throw new Error("Project code cannot be null!");
      }

      if (type === "remove") {
        return projectApi.removeProjectMember(
          project?.code,
          groupName,
          principalId
        );
      }

      return Promise.resolve();
    },
    // onSuccess: () => {
    //   dispatch(fetchAllProjectMembers());
    // },
  });

  return (
    <ProjectCompetenceContext.Provider
      value={{
        loading: useMemo(
          () =>
            groupMutation.isPending ||
            memberMutation.isPending ||
            projectLoading,
          [groupMutation.isPending, memberMutation.isPending, projectLoading]
        ),
        createProjectGroup: (
          parentGroupName: string,
          domainName: string,
          group: Partial<ProjectMember>
        ) => {
          return groupMutation.mutateAsync({
            type: "create",
            groupName: parentGroupName,
            group,
            domainName,
          });
        },
        updateProjectGroup: (
          groupName: string,
          domainName: string,
          group: Partial<ProjectMember>
        ) => {
          return groupMutation.mutateAsync({
            type: "update",
            groupName,
            group,
            domainName,
          });
        },
        removeProjectMembers: (members: ProjectMember[]) => {
          const groupNames = _(members).map("groupName").uniq().value();

          if (groupNames.length > 1) {
            throw new Error(
              "Cannot remove users across multiple project groups!"
            );
          }

          const chunks = _.chunk(members, 10).map((members) =>
            members.map((member) => {
              if (member.group) {
                return groupMutation.mutateAsync({
                  type: "delete",
                  groupName: member.name,
                  domainName: "Users",
                });
              }

              return memberMutation.mutateAsync({
                principalId: member.principalId,
                type: "remove",
                groupName: groupNames[0],
              });
            })
          );

          return new Promise(async (resolve) => {
            for (let chunk in chunks) {
              await Promise.all(chunk);
            }

            resolve(null);
          }).then(() => {
            dispatch(fetchAllProjectMembers());
          });
        },
        manageableGroups: useMemo(() => {
          if (project == null) {
            return [];
          }

          return _(project?.projectGroups)
            .filter("userGroup")
            .map("name")
            .map((gn) => project.groups.find((g) => g.name === gn))
            .compact()
            .value();
        }, [project]),
      }}
    >
      {children}
    </ProjectCompetenceContext.Provider>
  );
};

export default ProjectCompetenceProvider;
