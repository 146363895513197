import SimpleTable from "./components/SimpleTable";
import LanguageSwitcher from "./components/LanguageSwitcher";
import SimpleUniversalForm from "./components/SimpleUniversalForm";
import SimpleFormGroup from "./components/SimpleFormGroup";
import DocumentIcon from "./components/DocumentIcon";
import FolderTypeResourcePicker from "./components/FolderTypeResourcePicker";
import SimpleRepositoryTable from "./components/SimpleRepositoryTable";
import ProjectSelection from "./components/docarea/ProjectSelection";
import ProjectSwitcher from "./components/docarea/ProjectSwitcher";
import { CommentsList, CommentArea } from "./components/resource/comments";
import ReviewHistory from "./components/resource/review-history/ReviewHistory";
import FilesList from "./components/upload/FilesList";

import CosmosCoreProvider from "./providers/CosmosCoreProvider";
import RepositoryFolderProvider from "./providers/RepositoryFolderProvider";

import i18n from "./i18n";

import Resource from "./types/resource";

import repositorySearchApi, {
  RepositorySearchOptions,
} from "./apis/repositorySearchApi";
import GlobalStyle from "./globalStyle";

const { searchRepository } = repositorySearchApi;

export * from "./hooks";

export * from "./components/repository-table";
export * from "./components/form";
export * from "./components/form/group";
export * from "./components/FolderTreeView";

export * from "./modules/access-management";
export * from "./modules/dashboard";
export * from "./modules/locale-editor";
export * from "./modules/project-editor";
export * from "./modules/reminder-management";
export * from "./modules/user-management";
export * from "./modules/user-relation";
export * from "./modules/valueset-editor";
export * from "./modules/authentication";

export * from "./services";
export * from "./utils";

export {
  SimpleTable,
  ProjectSelection,
  ProjectSwitcher,
  LanguageSwitcher,
  SimpleUniversalForm,
  SimpleFormGroup,
  DocumentIcon,
  FolderTypeResourcePicker,
  SimpleRepositoryTable,
  CommentsList,
  CommentArea,
  ReviewHistory,
  FilesList,
};
export { CosmosCoreProvider, RepositoryFolderProvider };
export { i18n };
export { Resource };
export { searchRepository, RepositorySearchOptions };
export { GlobalStyle };
