import { RingLoader } from "react-spinners";
import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { motion, useAnimationControls } from "framer-motion";
import styled from "styled-components";
import { blendColor } from "../../utils";
import { transparentize } from "polished";

const SpinnerWrapper = styled.div``;

const LoadingOverlayContainer = styled(motion.div)`
  min-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  & > ${SpinnerWrapper} {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: ${(props) =>
      transparentize(0.7, blendColor(0.1, props.theme.background))};
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`;

export interface LoadingOverlayProps {
  children?: React.ReactNode;
  loading?: boolean;
  progress?: number;
}

const LoadingOverlay = ({
  children,
  loading,
  progress,
}: LoadingOverlayProps) => {
  const [display, setDisplay] = useState(false);

  const controlPresence = useAnimationControls();

  useEffect(() => {
    controlPresence.stop();

    if (loading) {
      setDisplay(true);
    } else {
      controlPresence
        .start({
          opacity: 0,
        })
        .then(() => setDisplay(false));
    }
  }, [controlPresence, loading]);

  useEffect(() => {
    if (loading && display) {
      controlPresence.start({
        opacity: 1,
      });
    }
  }, [controlPresence, display, loading]);

  return (
    <>
      {children}
      {display && (
        <LoadingOverlayContainer
          initial={{ opacity: 0 }}
          animate={controlPresence}
          transition={{
            duration: 0.3,
            ease: "easeInOut",
          }}
        >
          <SpinnerWrapper>
            <div>
              <RingLoader color="#1d4376" />
            </div>

            {progress !== 0 && !isNaN(progress || 0) && progress != null && (
              <div style={{ margin: "0 auto" }}>
                <ProgressBar
                  now={progress}
                  label={`${Math.round(progress)}%`}
                  style={{ width: "35vh" }}
                  className="mt-3"
                />
              </div>
            )}
          </SpinnerWrapper>
        </LoadingOverlayContainer>
      )}
    </>
  );
};

LoadingOverlay.defaultProps = {
  progress: 0,
};

export default LoadingOverlay;
