import { CancelToken } from "axios";
import GatewayApi from "./conf/GatewayApi";

const gatewayApi = GatewayApi.build();

export type GetContentItemOptions = {
  onProgress?: (loadedBytes: number) => void;
  cancelToken?: CancelToken;
  abortSignal?: AbortSignal;
};

const getResourceContentItem = (
  resourceId: string,
  itemIndex: number,
  options?: GetContentItemOptions
) => {
  return gatewayApi
    .get(`/repository/doc/${resourceId}/contentitem/${itemIndex}`, {
      responseType: "blob",
      onDownloadProgress: (e) => {
        if (options?.onProgress != null) {
          options.onProgress(e.loaded);
        }
      },
      signal: options?.abortSignal,
    })
    .then((response) => {
      return Promise.resolve({
        data: response.data,
        contentType: response.headers["content-type"],
      });
    })
};

const resourceContentApi = {
  getResourceContentItem,
};

export default resourceContentApi;
