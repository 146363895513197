import { useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import { propertyType } from "cosmos-config/generator";
import useProject from "./useProject";
import useValueset from "./useValueset";
import {
  Property,
  SelectableAttributeProperty,
} from "cosmos-config/lib/property/property";

/**
 * @module hooks/usePropertyRender
 * @category Hooks
 */

/**
 * @callback renderPropertyValue
 * @param {string} propertyName - Name of a property (which is passed as relevant
 * property in a hook options). Valueset of this property is loaded.
 * @param {string} value - Value of a property which valueset was loaded.
 * @returns {string} Label of the property value.
 */

/**
 * @typedef {object} UsePropertyRenderHook
 * @property {renderPropertyValue} render - Function to render property value into its label.
 */

/**
 * Major hook to cover simple value -> label transition during rendering
 * of properties.
 *
 * @param {Property[]} relevantProperties
 * @returns {function}
 */
const usePropertyRender = (relevantProperties: Property[]) => {
  const { getValuesetByName, registerProperties } = useValueset();

  const propertiesMap = useMemo(
    () => _.keyBy(relevantProperties, "name"),
    [relevantProperties]
  );

  useEffect(() => {
    if (propertiesMap != null && Object.keys(propertiesMap).length > 0) {
      registerProperties(propertiesMap);
    }
  }, [propertiesMap, registerProperties]);

  const { members } = useProject();
  const projectMembersMap = useMemo(
    () => _(members).valuesIn().flatMap().keyBy("principalId").value(),
    [members]
  );

  return useCallback(
    (propertyName: string, value: any) => {
      const prop = propertiesMap[propertyName];
      if (prop == null) {
        return value;
      }

      if (
        [
          propertyType.SELECT,
          propertyType.RESOURCE_SELECT,
          propertyType.RADIO,
        ].includes(prop.type)
      ) {
        const valueset = getValuesetByName(
          (prop as SelectableAttributeProperty).valuesetName
        );

        const getValuesetEntry = (v: string) =>
          valueset == null ? null : valueset.find((vs) => vs.value === v);

        const getLabel = (v: string) => {
          const valuesetEntry = getValuesetEntry(v);
          return valuesetEntry != null ? valuesetEntry.label : v;
        };

        if (Array.isArray(value)) {
          if (prop.cellRender != null) {
            return value.flatMap((v, idx) => {
              const valuesetEntry = getValuesetEntry(v);
              const renderResult = prop.cellRender(
                v,
                valuesetEntry?.label || v,
                !!valuesetEntry?.obsolete
              );

              if (idx !== 0) {
                return [", ", renderResult];
              }

              return renderResult;
            });
          }

          return value.map(getLabel).join(", ");
        }

        if (prop.cellRender != null) {
          const valuesetEntry = getValuesetEntry(value);
          return prop.cellRender(
            value,
            valuesetEntry?.label || value,
            !!valuesetEntry?.obsolete
          );
        }

        return getLabel(value);
      }

      if (
        [propertyType.PRINCIPAL_SELECT, propertyType.MEMBER_SELECT].includes(
          prop.type
        )
      ) {
        if (value == null) {
          return "";
        }

        if (prop.cellRender != null) {
          if (Array.isArray(value)) {
            return value
              .map((v) => prop.cellRender(v, projectMembersMap[v]?.displayName))
              .join(", ");
          }

          return prop.cellRender(value, projectMembersMap[value]?.displayName);
        }
      }

      if (Array.isArray(value)) {
        return value.map((v) => prop.format(v)).join(", ");
      }

      if (prop.cellRender != null) {
        return prop.cellRender(value, value);
      }

      return prop.format(value);
    },
    [propertiesMap, getValuesetByName, projectMembersMap]
  );
};

export default usePropertyRender;
