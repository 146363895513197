/**
 * @enum {string}
 * @category Group
 * @readonly
 */
enum GroupType {
  /**
   * Properties Group.
   */
  PROPERTIES = "properties",
  PERMISSIONS = "permissions",
  STRUCTURE = "structure",
  DATATABLE = "datatable",
  MULTI_ATTRIBUTE = "multi_attribute",
  CONTENT_UPDATE = "content_update",
  WIDGET = "widget",
}

export default GroupType;
