import StructureType from "./structureType";

/**
 * @interface
 */
export interface Structure {
  level: number;
  type: StructureType;
  resourceType: number;

  /** Excludes folders from the structure level view. */
  excludeFolders(): Structure;

  /** Excludes documents from the structure level view. */
  excludeDocuments(): Structure;
}

/**
 * Structure Generator.
 * @author Patrik Pancisin
 * @class structure
 * @classdesc Basic building block of cosmos.
 * @param {string} label - The label of the group displayed in cosmos.
 * @param {string} type - The type of the structure.
 */
export default (
  level: number,
  type: StructureType = StructureType.MAIN
): Structure => /** @lends structure */ ({
  level,
  type,
  resourceType: 2,

  /**
   * @instance
   * @function
   * @description Excludes folders from the structure level view.
   */
  excludeFolders(): Structure {
    this.resourceType = 2;
    return this;
  },

  /**
   * @instance
   * @function
   * @description Excludes documents from the structure level view.
   */
  excludeDocuments(): Structure {
    this.resourceType = 1;
    return this;
  },
});
