import React, { useContext } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import projectProperty from "../constants/projectProperty";
import { propertyType, valuesetSource } from "cosmos-config/generator";
import StaticValuesetOptions from "./valueset-editor/StaticValuesetOptions";
import PropertyWidgets from "./property-editor/PropertyWidgets";
import PropertyStructures from "./property-editor/PropertyStructures";
import PropertyDependency from "./property-editor/PropertyDependency";
import PropertyVisibility from "./property-editor/PropertyVisibility";
import PropertyDefaultValue from "./property-editor/PropertyDefaultValue";
import PropertyTranslations from "./property-editor/PropertyTranslations";
import useProjectElementEditing from "../hooks/useProjectElementEditing";
import ProjectEditorPropertiesContext from "../contexts/ProjectEditorPropertiesContext";
import SimpleFormGroup from "../../../components/SimpleFormGroup";
import {
  Property,
  SelectableAttributeProperty,
} from "cosmos-config/lib/property/property";
import PropertyAnticipatedValues from "./property-editor/PropertyAnticipatedValues";
import SimpleGroupedUniversalForm from "../../../components/SimpleGroupedUniversalForm";
import { buildGroups, buildProperties } from "../../../utils";
import FormGroupWrapper from "./FormGroupWrapper";

export interface ProjectPropertyFormProps {
  property?: Property;
  onUpdate?: () => void;
}

const ProjectPropertyForm = ({
  property,
  onUpdate,
}: ProjectPropertyFormProps) => {
  const { updateProperty, translationPathBase } = useContext(
    ProjectEditorPropertiesContext
  );

  const {
    formResource,
    updateElement,
    updateElementMask,
    visibilityUpdateMask,
    getSubmitResource,
  } = useProjectElementEditing({
    resource: property,
    properties: buildProperties(projectProperty),
  });

  return (
    <Container fluid className="py-3 px-0">
      <Row>
        <Col>
          <SimpleGroupedUniversalForm
            collapsed={false}
            columns={2}
            disabled={property?.protectedProperty}
            grouping={true}
            groups={buildGroups(projectProperty)}
            resource={formResource}
            onUpdateResource={(id, name, value) => {
              updateElement(name, value);
            }}
            inline
          />
        </Col>

        {(property as SelectableAttributeProperty)?.valuesetSource ===
          valuesetSource.STATIC_VALUESET && (
          <Col>
            <FormGroupWrapper title="Valueset Options" defaultCollapsed={false}>
              <StaticValuesetOptions
                valueset={
                  formResource.options ||
                  (property as SelectableAttributeProperty)?.options
                }
                onChange={(valueset) => {
                  updateElement("options", valueset);
                }}
              />
            </FormGroupWrapper>
          </Col>
        )}
      </Row>

      <FormGroupWrapper
        title="Other Settings"
        defaultCollapsed={false}
        className="mb-3"
      >
        <SimpleFormGroup label="Translation" inline>
          <PropertyTranslations
            basePath={translationPathBase}
            translationPath={formResource.translation || property?.translation}
            onChange={(translation: string) => {
              updateElement("translation", translation);
            }}
          />
        </SimpleFormGroup>

        <SimpleFormGroup
          label="Default Value"
          description="Select the default value."
          inline
        >
          <PropertyDefaultValue
            property={property}
            defaultValue={
              "defaultValue" in formResource
                ? formResource.defaultValue
                : property?.defaultValue
            }
            onChange={(defaultValue) => {
              updateElement("defaultValue", defaultValue);
            }}
          />
        </SimpleFormGroup>
      </FormGroupWrapper>

      {property?.type === propertyType.SELECT && (
        <FormGroupWrapper title="Select Field Settings" className="mb-3">
          <SimpleFormGroup
            label="Dependency"
            description="Select the property on which is current property depending."
            inline
          >
            <PropertyDependency
              dependency={formResource.dependency}
              onChange={(value) => {
                updateElementMask(value);
              }}
            />
          </SimpleFormGroup>

          <p>Anticipated Values</p>
          <PropertyAnticipatedValues
            className="mb-3"
            property={property}
            anticipatedValues={
              formResource.anticipatedValues ||
              (property as SelectableAttributeProperty).anticipatedValues
            }
            onChange={(value) => {
              updateElement("anticipatedValues", value);
            }}
          />
        </FormGroupWrapper>
      )}

      {property?.type === propertyType.YESNO && (
        <FormGroupWrapper title="Yes/No Settings" className="mb-3">
          <SimpleFormGroup label="Label Left" inline>
            <PropertyTranslations
              basePath={translationPathBase}
              translationPath={formResource.labelLeft}
              onChange={(translation) => {
                updateElement("labelLeft", translation);
              }}
            />
          </SimpleFormGroup>
          <SimpleFormGroup label="Label Right" inline>
            <PropertyTranslations
              basePath={translationPathBase}
              translationPath={formResource.labelRight}
              onChange={(translation) => {
                updateElement("labelRight", translation);
              }}
            />
          </SimpleFormGroup>
        </FormGroupWrapper>
      )}

      <FormGroupWrapper
        title="Widgets"
        className="mb-3"
        activeElements={formResource?.widgets?.length}
      >
        <PropertyWidgets
          widgets={formResource.widgets || property?.widgets}
          onChange={(widgets) => {
            updateElement("widgets", widgets);
          }}
        />
      </FormGroupWrapper>

      <FormGroupWrapper
        title="Structures"
        className="mb-3"
        activeElements={formResource?.structures?.length}
      >
        <PropertyStructures
          structures={formResource.structures || property?.structures}
          onChange={(structures) => {
            updateElement("structures", structures);
          }}
        />
      </FormGroupWrapper>

      <FormGroupWrapper
        title="Conditional Visibility Settings"
        className="mb-3"
      >
        <PropertyVisibility
          property={visibilityUpdateMask}
          onChange={(prop) => {
            updateElementMask(prop);
          }}
        />
      </FormGroupWrapper>

      <div className="text-right">
        <Button
          onClick={() => {
            if (onUpdate != null) {
              onUpdate();
            }

            if (property?.name != null) {
              updateProperty(property.name, getSubmitResource());
            }
          }}
          size="lg"
          disabled={property?.protectedProperty}
        >
          Save
        </Button>
      </div>
    </Container>
  );
};

export default ProjectPropertyForm;
