import { property, valuesetSource } from 'cosmos-config/generator';

const comment = [
  property('ID', 'number').informational(),
  property('Author', 'author').excludeUINumber().informational(),
  property('Created At').dateAttribute('createdAt').informational(),
  property('Comment', 'message').informational(),
  property('Page Number', 'position.pageNumber').informational(),
  property('Status')
    .selectableAttribute(
      'status',
      'comment_status',
      valuesetSource.STATIC_VALUESET
    )
    .valueset([
      { label: 'Open', value: 'open' },
      { label: 'Closed', value: 'closed' },
      {
        label: 'For Information',
        value: 'information',
      },
    ])
    .informational(),
];

export default comment;
