import React, { forwardRef, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import _ from "lodash";
import useProject from "../../../hooks/useProject";
import { CommentsListContext } from "./CommentsList";
import { PancoRichTextArea } from "cosmos-components";

export interface CommentAreaProps {
  className?: string;
  onCancel?: () => void;
  replyTo?: string;
  onSubmit?: (message: string, mentions: string[]) => void;
}

export interface CommentAreaComponent {
  focus: () => void;
}

const CommentArea = forwardRef<CommentAreaComponent, CommentAreaProps>(
  ({ className, onCancel, replyTo, onSubmit }, ref) => {
    const [message, setMessage] = useState<string | null>(null);
    const [mentions, setMentions] = useState<string[]>([]);
    const { t } = useTranslation("module");

    const { members } = useProject();
    const { documents } = useContext(CommentsListContext);

    const disabled = message == null || message.length <= 0;

    const handleCancel = () => {
      setMessage(null);

      if (onCancel != null) {
        onCancel();
      }
    };

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();

      if (disabled) {
        return;
      }

      if (onSubmit != null && message != null) {
        onSubmit(message, mentions);
        handleCancel();
      }
    };

    const projectMembers = useMemo(
      () => _(members).valuesIn().flatMap().value(),
      [members]
    );

    return (
      <div className={`comment-area ${className}`}>
        <Form onSubmit={handleSubmit}>
          <PancoRichTextArea
            ref={ref}
            onChange={({ value }) => setMessage(value)}
            onMentionChange={setMentions}
            resources={documents}
            users={projectMembers}
            replyTo={replyTo}
            value={message}
          />

          <div className="text-right mt-2">
            <Button type="button" variant="link" onClick={handleCancel}>
              {t("button.cancel", { defaultValue: "Cancel" })}
            </Button>
            <Button disabled={disabled} type="submit">
              {t("repository.place_comment", {
                defaultValue: "Place a Comment",
              })}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
);

CommentArea.displayName = "CommentArea";

export default CommentArea;
