import * as actions from '../Actions/types';
import {
  LOGIN_ADFS,
  LOGIN_ADFS_SUCCESS,
  LOGIN_EXTERNAL_ADFS,
  LOGOUT,
} from '../Actions/types/userdata';

const initialState = {
  internalLoginPass: false,
  defaultPositionId: null,
  principalId: null,
  authenticated: false,
  filtersets: [],
  selectedFiltersetId: null,
  permissions: {},
  delegatedPrincipals: [],
  delegates: [],
  loading: false,
  notificationPreferences: [],
  groupNames: [],
};

const userdata = (state = initialState, action) => {
  switch (action.type) {
    case 'INTERNAL_LOGIN':
    case LOGIN_ADFS_SUCCESS:
      return {
        ...state,
        internalLoginPass: true,
      };
    case LOGOUT:
    case LOGIN_ADFS:
    case LOGIN_EXTERNAL_ADFS:
      return {
        ...state,
        internalLoginPass: false,
      };
    case actions.userdata.SET_USERDATA:
      return {
        ...state,
        ...action.data,
      };
    case actions.userdata.SET_PRINCIPAL_ID:
      return {
        ...state,
        principalId: action.principalId,
        defaultPositionId: action.defaultPositionId,
      };
    case actions.userdata.AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
      };
    case actions.userdata.SET_FILTERSETS:
      return {
        ...state,
        filtersets: action.filtersets,
      };
    case actions.userdata.SET_FILTERSET_ID:
      return {
        ...state,
        selectedFiltersetId: action.filtersetId,
      };
    case actions.userdata.SET_USER_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case actions.userdata.SET_USER_GROUP_NAMES:
      return {
        ...state,
        groupNames: action.payload.groupNames,
      };
    case actions.userdata.SET_DELEGATED_PRINCIPALS:
      return {
        ...state,
        delegatedPrincipals: action.delegatedPrincipals,
      };
    case actions.userdata.SET_DELEGATES:
      return {
        ...state,
        delegates: action.delegates,
      };
    case actions.userSettings.SET_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        notificationPreferences: action.payload.notificationPreferences,
      };
    case actions.userdata.SET_USERDATA_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.filterFolder.CLEAR_STORES:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default userdata;
