import { property } from 'cosmos-config/generator';

const uploadStatistic = [
  property('Display Name', 'displayName').informational(),
  property('File Name', 'fileName').informational(),
  property('File Path', 'filePath').informational(),
  property('Resource Id', 'resourceId').informational(),
  property('Complete').booleanAttribute('complete').informational(),
  property('Error').booleanAttribute('error').informational(),
  property('Timestamp').dateAttribute('startMillis').informational(),
  property('Completed at').dateAttribute('endMillis').informational(),
  property('Size', 'size').informational(),
  property('Time', 'time').informational(),
  property('Error Message', 'message').informational(),
];

export default uploadStatistic;
