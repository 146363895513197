import React, {
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import RepositoryFolderContext, {
  DuplicateAnalyzeResult,
} from "../contexts/RepositoryFolderContext";
import UploadAdvisorContext, {
  UploadAdvisorStatus,
  UploadAdvisorDuplicateIssue,
  UploadAdvisorIssue,
  UploadOptions,
} from "../contexts/UploadAdvisorContext";
import Resource from "../types/resource";
import { getFileIdentifier, trimFileExtension } from "../utils/fileUtils";
import { getResourcePreset } from "../utils/resourceUtils";
import useProperties from "../hooks/useProperties";
import _ from "lodash";

interface ResourceWithFile extends Partial<Resource> {
  fileIdentifier: string;
}

const actions = {
  INITIALIZE: "uploadAdvisor/initialize",
  ANALYZE_FILES: "uploadAdvisor/analyzeFiles",
  ANALYZE_COMPLETE: "uploadAdvisor/analyzeComplete",
  SET_PRESET: "uploadAdvisor/setPreset",
  UPLOADING: "uploadAdvisor/uploading",
  FINALIZE: "uploadAdvisor/finalize",
} as const;

type UploadAdvisorState = {
  fileIdentifiers: string[];
  status: UploadAdvisorStatus;
  preset: Partial<Resource>;
  issues: UploadAdvisorIssue[];
  resources: ResourceWithFile[];
  progress: number;
  uploadSize: number;
  options: UploadOptions;
};

const initialState: UploadAdvisorState = {
  fileIdentifiers: [],
  status: UploadAdvisorStatus.READY,
  preset: {},
  issues: [],
  resources: [],
  progress: 0,
  uploadSize: 0,
  options: {
    multipleFiles: false,
    singleDocument: false,
  },
};

type ActionKeys = keyof typeof actions;
type UploadAdvisorReducerAction = {
  type: (typeof actions)[ActionKeys];
  payload?: any;
};

const uploadAdvisorReducer = (
  state: UploadAdvisorState,
  action: UploadAdvisorReducerAction
) => {
  const generateResources = (
    fileResourceMap?: {
      fileName: string;
      fileIdentifier: string;
    }[]
  ): ResourceWithFile[] => {
    if (fileResourceMap == null) {
      return [];
    }

    return fileResourceMap.map((file) => ({
      ...state.preset,
      fileIdentifier: file.fileIdentifier,
      filename: file.fileName,
      displayname: trimFileExtension(file.fileName),
      valid: false,
    }));
  };

  const updateResources = (
    resources: ResourceWithFile[],
    results: DuplicateAnalyzeResult[]
  ) => {
    const reultsMap = _.keyBy(results, "fileIdentifier");

    return resources.map((resource) => {
      if (resource.fileIdentifier == null) {
        return resource;
      }

      const data = reultsMap[resource.fileIdentifier];

      return {
        ...state.preset,
        ...resource,
        itemhash: _.compact([data?.checksum]),
      };
    });
  };

  switch (action.type) {
    case actions.INITIALIZE:
      return initialState;
    case actions.ANALYZE_FILES:
      return {
        ...initialState,
        fileIdentifiers: _.map(
          action.payload?.fileResourceMap,
          "fileIdentifier"
        ),
        status:
          action.payload?.fileResourceMap?.length > 0
            ? UploadAdvisorStatus.PROCESSING
            : UploadAdvisorStatus.READY,
        resources: generateResources(action.payload?.fileResourceMap),
        uploadSize: action.payload.uploadSize || 0,
        options: {
          ...state.options,
          ...(action.payload.options || {}),
        },
      };
    case actions.ANALYZE_COMPLETE:
      return {
        ...state,
        status: UploadAdvisorStatus.ANALYZED,
        issues: _.compact<UploadAdvisorDuplicateIssue>(
          action.payload.results.map((r: DuplicateAnalyzeResult) => {
            if (r.level !== "info") {
              return {
                ...r,
                level: r.level,
                type: "duplicate",
              };
            }

            return null;
          })
        ),
        resources: updateResources(state.resources, action.payload.results),
      };
    case actions.SET_PRESET:
      return {
        ...state,
        preset: action.payload.preset,
      };
    case actions.UPLOADING:
      return {
        ...state,
        status: UploadAdvisorStatus.UPLOADING,
        progress: _.clamp(
          _.round(action.payload.loadedBytes / state.uploadSize, 2),
          0,
          1
        ),
      };
    case actions.FINALIZE:
      return {
        ...initialState,
        status: UploadAdvisorStatus.FINALIZED,
      };
    default:
      return state;
  }
};

export interface UploadAdvisorProps {
  children: React.ReactNode;
}

const UploadAdvisor = ({ children }: UploadAdvisorProps) => {
  const [localState, localDispatch] = useReducer<
    Reducer<UploadAdvisorState, UploadAdvisorReducerAction>
  >(uploadAdvisorReducer, initialState);
  const { checkDuplicates, createDocument } = useContext(
    RepositoryFolderContext
  );

  const { properties } = useProperties();

  const filesRef = useRef<File[]>([]);

  useEffect(() => {
    if (localState.fileIdentifiers.length > 0) {
      checkDuplicates(filesRef.current).then((results) => {
        localDispatch({
          type: actions.ANALYZE_COMPLETE,
          payload: {
            results,
          },
        });
      });
    }
  }, [checkDuplicates, localState.fileIdentifiers]);

  const setFiles = useCallback(
    (files: File[], options?: Partial<UploadOptions>) => {
      const filesToAdd = options?.multipleFiles ? files : _.take(files);
      filesRef.current = filesToAdd;
      localDispatch({
        type: actions.ANALYZE_FILES,
        payload: {
          fileResourceMap: filesToAdd.map((f) => ({
            fileName: f.name,
            fileIdentifier: getFileIdentifier(f),
          })),
          uploadSize: _.sumBy(filesToAdd, "size"),
          options,
        },
      });
    },
    []
  );

  return (
    <UploadAdvisorContext.Provider
      value={{
        status: localState.status,
        files: filesRef.current,
        reset: useCallback(() => {
          localDispatch({
            type: actions.INITIALIZE,
          });
        }, []),
        addFiles: useCallback(
          (files, options) => {
            setFiles(files, options);
          },
          [setFiles]
        ),
        executeUpload: useCallback(() => {
          const resourceMap = _.keyBy(localState.resources, "fileIdentifier");

          const logProgress = (loadedBytes: number) => {
            localDispatch({
              type: actions.UPLOADING,
              payload: {
                loadedBytes,
              },
            });
          };

          logProgress(0);

          const createDocumentHelper = (fls: File[]) => {
            const mainFile = _.first(fls);
            if (mainFile) {
              const fileIdentifier = getFileIdentifier(mainFile);
              const resource = resourceMap[fileIdentifier] || {};
              return createDocument(resource, fls, logProgress).then(
                (resourceid): Promise<Partial<Resource>> =>
                  Promise.resolve({ ...resource, id: resourceid })
              );
            }

            throw new Error("The upload expects at least one file!");
          };

          const handleResultsHelper = (results: Partial<Resource>[]) => {
            localDispatch({
              type: actions.FINALIZE,
            });
            return Promise.resolve(results);
          };

          const { multipleFiles, singleDocument } = localState.options;

          if (multipleFiles && singleDocument) {
            return createDocumentHelper(filesRef.current).then((result) =>
              handleResultsHelper([result])
            );
          }

          const promises = filesRef.current.map((file) =>
            createDocumentHelper([file])
          );
          return Promise.all(promises).then(handleResultsHelper);
        }, [createDocument, localState.options, localState.resources]),
        uploadAllowed: useMemo(() => {
          const hasErrors =
            localState.issues.filter((i) => i.level === "error").length > 0;
          return (
            localState.status === UploadAdvisorStatus.ANALYZED && !hasErrors
          );
        }, [localState.issues, localState.status]),
        setPresetResource: useCallback(
          (resource: Resource) => {
            const preset = getResourcePreset(resource, properties);

            localDispatch({
              type: actions.SET_PRESET,
              payload: {
                preset,
              },
            });
          },
          [properties]
        ),
        issues: localState.issues,
        removeFile: (fileIdentifier) => {
          setFiles(
            filesRef.current.filter(
              (file) => getFileIdentifier(file) !== fileIdentifier
            )
          );
        },
        progress: localState.progress,
        filesCount: localState.fileIdentifiers.length,
        options: localState.options,
      }}
    >
      {children}
    </UploadAdvisorContext.Provider>
  );
};

export default UploadAdvisor;
