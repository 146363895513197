import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import localeApi from "../apis/locale.api";
import LocaleEditorContext from "../contexts/LocaleEditorContext";
import { writeFile, utils, read } from "xlsx";
import { DateTime } from "luxon";
import { TranslationsLoadingState, TranslationsMap } from "../types";

export interface LocaleEditorProps {
  children: React.ReactNode;
}

const LocaleEditorProvider = ({ children }: LocaleEditorProps) => {
  const [translations, setTranslations] = useState<TranslationsMap>({});
  const [loadingState, setLoadingState] = useState<TranslationsLoadingState>(
    {}
  );

  const setLoading = useCallback(
    (name: string, loading: boolean, error: boolean) => {
      setLoadingState((s) => ({
        ...s,
        [name]: {
          loading,
          error: !!error,
        },
      }));
    },
    []
  );

  const fetchTranslations = useCallback(
    (source = "module", name: string) => {
      const sl = _.curry(setLoading, 2)(name);
      sl(true);

      localeApi
        .getAllTypeTranslations(source, name)
        .then((data) => {
          setTranslations((t) => ({
            ...t,
            [name]: data,
          }));

          sl(false);
        })
        .catch(() => {
          sl(false, true);
        });
    },
    [setLoading]
  );

  return (
    <LocaleEditorContext.Provider
      value={{
        translations,
        loadingState,
        languages: useMemo(() => ["en", "de", "fr", "it", "es", "nl"], []),
        getTranslations: fetchTranslations,
        updateLanguageTranslation: useCallback(
          (languageCode, translationType, name, translation) => {
            const sl = _.curry(setLoading, 2)(name);
            sl(true);

            localeApi
              .updateLanguageTranslation(
                languageCode,
                translationType,
                name,
                translation
              )
              .then((data) => {
                setTranslations((t) => ({
                  ...t,
                  [name]: data,
                }));

                sl(false);
              })
              .catch(() => {
                sl(false, true);
              });
          },
          [setLoading]
        ),
        deleteTranslationKey: useCallback(
          (translationsType, name, translationKey) => {
            const sl = _.curry(setLoading, 2)(name);
            sl(true);

            localeApi
              .deleteTranslationKey(translationsType, name, translationKey)
              .then((data) => {
                setTranslations((t) => ({
                  ...t,
                  [name]: data,
                }));

                sl(false);
              })
              .catch(() => {
                sl(false, true);
              });
          },
          [setLoading]
        ),
        exportTranslationsExcel: useCallback(
          (translationsType, name) => {
            const data = Object.entries(translations[name]).map(
              ([key, translations]) => ({
                key,
                ...translations,
              })
            );

            const wb = utils.book_new();
            const ws = utils.json_to_sheet(data);

            utils.book_append_sheet(wb, ws, name);

            const date = DateTime.local().toSQLDate();

            writeFile(wb, `${translationsType}_${name}_${date}.xlsx`);
          },
          [translations]
        ),
        importTranslationsExcel: useCallback(
          async (file, translationsType, name) => {
            const sl = _.curry(setLoading, 2)(name);
            sl(true);

            const data: any = await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                if (reader.result != null) {
                  const data = new Uint8Array(reader.result as ArrayBuffer);
                  const workbook = read(data, { type: "array" });

                  const sheet = Object.values(workbook.Sheets)[0];
                  const res = utils.sheet_to_json(sheet);

                  resolve(res);
                } else {
                  reject("Empty result!");
                }
              };

              reader.readAsArrayBuffer(file);
            });

            const translations = _(data)
              .keyBy("key")
              .mapValues((v) => _.omit(v, "key"))
              .value();

            localeApi
              .updateTypeTranslations(translationsType, name, translations)
              .then((data) => {
                setTranslations((t) => ({
                  ...t,
                  [name]: data,
                }));
                sl(false);
              })
              .catch((err) => {
                sl(false);
              });
          },
          [setLoading]
        ),
      }}
    >
      {children}
    </LocaleEditorContext.Provider>
  );
};

export default LocaleEditorProvider;
