import { createContext } from "react";
import { DocumentHistoryEntry } from "../hooks/useDocumentHistory";

export interface UserSettingsContextValue {
  favouriteResources: DocumentHistoryEntry[];
  loadingFavouriteResources: boolean;
  toggleResourceFavourite: (resourceId: string) => Promise<any>;
  isResourceUserFavourite: (resourceId: string) => boolean;
  recentlyOpenedResources: DocumentHistoryEntry[];
  logRecentlyOpenedResource: (resourceId: string) => Promise<any>;
}

const UserSettingsContext = createContext<UserSettingsContextValue>({
  favouriteResources: [],
  loadingFavouriteResources: false,
  toggleResourceFavourite: () => Promise.reject("Not implemented!"),
  isResourceUserFavourite: () => {
    throw new Error("Not implemented!");
  },
  recentlyOpenedResources: [],
  logRecentlyOpenedResource: () => Promise.reject("Not implemented!"),
});

export default UserSettingsContext;
