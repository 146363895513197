import { useMemo } from "react";
import useProjectQueries from "../../../hooks/useProjectQueries";
import { projectQueryToWidget } from "../utils";
import _ from "lodash";

const useQueryWidgets = () => {
  const { queries } = useProjectQueries();

  const queryWidgets = useMemo(() => {
    return _(queries)
      .filter((q) => !q.folderQuery)
      .map(projectQueryToWidget)
      .filter((x) => x != null)
      .uniqBy("name")
      .value();
  }, [queries]);

  return {
    queryWidgets,
  };
};

export default useQueryWidgets;
