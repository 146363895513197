import { widget, widgetType } from "cosmos-config/generator";

const baseDashoardWidgets = [
  widget(
    "My Documents (recently opened)",
    widgetType.general.RECENTLY_OPENED_TABLE
  ).defaultSize(5, 3),
  widget(
    "Recently Uploaded/Updated Documents",
    widgetType.general.RECENTLY_UPLOADED_TABLE
  ).defaultSize(5, 3),
  widget("Favourite Documents", widgetType.general.FAVOURITE_TABLE).defaultSize(
    5,
    3
  ),
];

export default baseDashoardWidgets;
