import { createElement as h } from 'react';
import {
  faBuilding,
  faComment,
  faComments,
} from '@fortawesome/free-regular-svg-icons';
import { faBug, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  property,
  systemProperty,
  valuesetSource,
} from 'cosmos-config/generator';
import { DateTime } from 'luxon';

export default [
  systemProperty('id'),
  systemProperty('Receiver', 'receiver'),
  property('Type', 'type')
    .selectableAttribute('type', 'type', valuesetSource.STATIC_VALUESET)
    .valueset([
      { label: 'Contact', value: 'CONTACT' },
      { label: 'Project', value: 'PROJECT' },
      { label: 'Global', value: 'GLOBAL' },
      { label: 'Report', value: 'REPORT' },
      { label: 'Peer to Peer', value: 'P2P' },
    ])
    .excludeForm()
    .tableCell((v) => {
      switch (v) {
        case 'CONTACT':
          return h(FontAwesomeIcon, {
            icon: faComment,
            className: 'text-info',
          });
        case 'REPORT':
          return h(FontAwesomeIcon, { icon: faBug, className: 'text-danger' });
        case 'PROJECT':
          return h(FontAwesomeIcon, {
            icon: faBuilding,
            className: 'text-warning',
          });
        case 'GLOBAL':
          return h(FontAwesomeIcon, {
            icon: faBuilding,
            className: 'text-warning',
          });
        case 'P2P':
          return h(FontAwesomeIcon, { icon: faComments });
        default:
          return h(FontAwesomeIcon, { icon: faMicrophone });
      }
    }),
  property('Message', 'text').richTextArea(1000).translate('message.message'),
  systemProperty('Sender', 'principalId'),
  property('Created At').dateAttribute('createdAt').excludeForm(),
  property('Valid Until')
    .dateAttribute('validUntil')
    .excludeTime()
    .translate('message.valid_until')
    .onUpdate((value) => {
      if (value != null) {
        return DateTime.fromMillis(value).endOf('day').toMillis();
      }
      return value;
    }),
  property('Link', 'link').optional().translate('message.link'),
];
