import {
  createElement as h,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PancoSelect } from "cosmos-components";
import { faStream, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import useDebounce from "../../hooks/useDebounce";
import useRepositorySearch, {
  UseRepositorySearchProps,
} from "../../hooks/useRepositorySearch";
import DocumentIcon from "../DocumentIcon";
import useFolderProperty from "../../hooks/useFolderProperty";
import { PancoSelectOption, PancoSelectProps } from "cosmos-components";
import { parseResourceId } from "../../utils/resourceUtils";
import { OnOpenResourceCallback } from "../../types/callbacks";

export interface ResourcePickerProps extends PancoSelectProps {
  onInsert?: (option: PancoSelectOption) => void;
  folderId?: string;
  resourceType?: 1 | 2 | 3;
  showLink?: boolean;
  onOpenResource?: OnOpenResourceCallback;
  folderType?: string;
}

declare type ResourcePickerOption = {
  resourceType: 1 | 2 | 3;
  fileextension: string;
} & PancoSelectOption;

const ResourcePicker = ({
  onChange,
  onInsert,
  value,
  options,
  name,
  folderId,
  resourceType,
  showLink,
  onOpenResource,
  folderType,
  ...props
}: ResourcePickerProps) => {
  const [filterValue, setFilterValue] = useState<string | null>(null);
  const [innerValue, setInnerValue] = useState<string[] | null | undefined>(
    value
  );
  const [selfOptions, setSelfOptions] = useState<ResourcePickerOption[]>([]);

  useEffect(() => {
    setInnerValue((curVal) => (_.isEqual(curVal, value) ? curVal : value));
  }, [value]);

  const setOptionsAsSelf = useCallback(() => {
    if (options != null && options?.length > 0) {
      setSelfOptions(
        options.map((o) => ({
          ...o,
          resourceType: resourceType || 2,
          fileextension: "",
        }))
      );
    }
  }, [options, resourceType]);

  useEffect(() => {
    setOptionsAsSelf();
  }, [setOptionsAsSelf]);

  const debouncedFilterValue = useDebounce(filterValue, 400);

  const useRepositorySearchProps: UseRepositorySearchProps = useMemo(() => {
    const getFilter = () => {
      if (resourceType === 1 && folderType != null) {
        return {
          resourcetype: 1,
          foldertype: folderType,
        };
      }

      return {
        resourcetype: resourceType || 2,
      };
    };

    if (folderId == null) {
      return {
        resourceId: "",
        enabled: false,
      };
    }

    return {
      resourceId: folderId,
      filter: getFilter(),
      subtree: false,
      paginator: {
        number: 0,
        size: debouncedFilterValue?.length > 2 ? 100 : 10,
      },
      orderBy: {
        initialcreation: "desc",
      },
      enabled: options?.length === 0 || debouncedFilterValue?.length > 2,
      searchQuery:
        debouncedFilterValue?.length > 2 ? debouncedFilterValue : null,
      substructure: true,
      fulltextSearch: false,
    };
  }, [
    debouncedFilterValue,
    folderId,
    folderType,
    options?.length,
    resourceType,
  ]);

  const { data, loading } = useRepositorySearch(useRepositorySearchProps);

  const { renderFolderName } = useFolderProperty(folderType);

  useEffect(() => {
    const parsedOptions: ResourcePickerOption[] = data.map((res) => ({
      label: resourceType === 1 ? renderFolderName(res) : res.displayname,
      value: res.id,
      fileextension: res.fileextension,
      resourceType: res.resourcetype,
    }));

    if (debouncedFilterValue?.length > 2 || options?.length === 0) {
      setSelfOptions(parsedOptions);
    } else {
      setOptionsAsSelf();
    }
  }, [
    data,
    options,
    resourceType,
    debouncedFilterValue,
    renderFolderName,
    setOptionsAsSelf,
  ]);

  const selfOptionsMap = useMemo(
    () => _(selfOptions).keyBy("value").value(),
    [selfOptions]
  );

  const selectedOption = useMemo(() => {
    const resourceId = _.first(innerValue);
    if (resourceId != null) {
      return selfOptionsMap[resourceId];
    }

    return null;
  }, [selfOptionsMap, innerValue]);

  if (selectedOption != null && showLink && !props?.multiple) {
    return h("div", { className: "d-flex" }, [
      h(
        // @ts-ignore
        Button,
        {
          variant: "link",
          className: "mr-auto text-left text-break",
          onClick: () => {
            if (onOpenResource != null) {
              const { resourceType, identifier } = parseResourceId(
                selectedOption.value
              );

              if (resourceType != null && identifier != null) {
                onOpenResource(resourceType, identifier);
              }
            }
          },
        },
        selectedOption.label
      ),
      h(
        // @ts-ignore
        Button,
        {
          variant: "link",
          onClick: () => {
            if (onChange != null) {
              onChange({ name, value: [] });
            }
          },
        },
        h(FontAwesomeIcon, { icon: faTimes })
      ),
    ]);
  }

  return h(PancoSelect, {
    ...props,
    value,
    options: selfOptions,
    loading,
    manualFilter: true,
    onChange: ({ value: val }) => {
      setFilterValue(null);
      if (onChange != null) {
        onChange({ name, value: val });
      }

      if (val != null) {
        const [v] = val;

        if (
          onInsert != null &&
          !_(options).map("value").includes(v) &&
          v != null
        ) {
          onInsert(selfOptionsMap[v]);
        }
      }
    },
    onFilter: (val) => {
      if (val != null) {
        setFilterValue(val);
      }
    },
    optionRow: (option) => {
      const resource = selfOptionsMap[option.value];

      if (resource != null) {
        if (resource.resourceType === 1) {
          return [
            h(FontAwesomeIcon, {
              icon: faStream,
              color: "grey",
              className: "mr-2",
            }),
            option.label,
          ];
        }

        return [
          // @ts-ignore
          h(DocumentIcon, { document: resource, className: "mr-2" }),
          option.label,
        ];
      }

      return null;
    },
  });
};

export default ResourcePicker;
