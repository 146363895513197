import { property, principalType, group } from 'cosmos-config/generator';

const SignOff_SendEndNotificationMail = [
  group('Start Task')
    .translate('common.basic_information')
    .dedicatedTo('START')
    .children([
      property('OPEA Board')
        .booleanAttribute('WFD_ChangeOfControl')
        .default(false)
        .optional(),
      property('OPEA Directors')
        .booleanAttribute('WFD_GroupInternal')
        .default(false)
        .optional(),
      property('Local OPEA Board Office')
        .selectableMemberAttribute(
          'WF_LocalOPEABoardMembers',
          principalType.POSITION,
          'PASSO_LOCALOPEABOARDOFFICE'
        )
        .multiAttribute('WF_LocalOPEABoardMembers')
        .optional(),
      property('Additional Recipients')
        .selectableMemberAttribute(
          'WF_AdditionalRecipients',
          principalType.POSITION
        )
        .multiAttribute('WF_AdditionalRecipients')
        .optional(),
      property('Documents')
        .selectableResourceAttribute('WFD_Linked_Docs')
        .multiAttribute('WFD_Linked_Docs')
        .optional(),
    ]),
];

export default SignOff_SendEndNotificationMail;
