import _ from 'lodash';
import { all } from 'redux-saga/effects';

export default function* callBatch(batchSize = 10, sagas) {
  const chunks = _(sagas).chunk(batchSize).value();
  let result = [];
  for (let i = 0; i < chunks.length; i++) {
    const partial = yield all(chunks[i]);
    result = [...result, ...partial];
  }

  return result;
}
