import GatewayApi from "./conf/GatewayApi";
import Resource from "../types/resource";
import {
  select,
  distinct,
  count,
  min,
  max,
  operation,
  queryOperand,
} from "cosmos-config/nql";
import { Operation } from "cosmos-config/lib/nql/operation";

const gatewayApi = GatewayApi.build();

declare type RepositorySearchResponse = {
  items: Resource[];
  count: number;
};

export interface RepositorySearchOptions {
  searchQuery?: string;
  paginator?: {
    number: number;
    size: number;
  };
  orderBy: Record<string, string>;
  subtree: boolean;
  operation?: Operation;
}

const parseSearchOptions = (
  options = {} as RepositorySearchOptions
): RepositorySearchOptions => ({
  searchQuery: options.searchQuery,
  paginator: options.paginator,
  orderBy: options.orderBy || {},
  subtree: !!options.subtree,
  operation: options.operation,
});

const queryFolderSearch = (
  folderId: string,
  query: string,
  fulltextQuery?: string | null,
  projectCode?: string | null
) => {
  let data: any = { query, projectCode };

  if (fulltextQuery != null && fulltextQuery !== "") {
    data = {
      ...data,
      fulltextQuery,
    };
  }

  // const path = searchVersions ? 'searchversions' : 'search';

  return gatewayApi
    .put(`/repository/folder/${folderId}/search`, data)
    .then((response) => {
      const { items, count: itemsCount } = response.data;
      return Promise.resolve({
        items,
        count: itemsCount,
      } as RepositorySearchResponse);
    });
};

const searchRepository = (
  projectCode: string | null,
  resourceId: string,
  properties: string[],
  options: RepositorySearchOptions
) => {
  const { searchQuery, paginator, orderBy, subtree, operation } =
    parseSearchOptions(options);

  const query = select(properties).subtree(subtree).orderBy(orderBy);

  if (operation != null) {
    query.filter(operation);
  }

  if (paginator != null) {
    query.paging({ ...paginator, number: paginator.number + 1 });
  }

  return queryFolderSearch(
    resourceId,
    query.toString(),
    searchQuery,
    projectCode
  );
};

const aggregateSearchDocuments = (
  folderId: string,
  query: string,
  projectCode?: string | null
) => {
  return gatewayApi
    .put(`/repository/folder/${folderId}/search/aggregate`, {
      projectCode,
      query,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const searchAggregateDistinct = (
  projectCode: string | null,
  folderId: string,
  propertyName: string,
  options?: RepositorySearchOptions
) => {
  const { orderBy, subtree, operation } = parseSearchOptions(options);

  const query = select([
    distinct(propertyName),
    count("identifier"),
    ...Object.keys(orderBy),
  ])
    .subtree(subtree)
    .orderBy(orderBy)
    .isNotDeleted();

  if (operation != null) {
    query.filter(operation);
  }

  return aggregateSearchDocuments(folderId, query.toString(), projectCode).then(
    (data) => {
      return Promise.resolve(data.items);
    }
  );
};

const searchAggregateMinMax = (
  projectCode: string,
  folderId: string,
  property: string,
  options?: RepositorySearchOptions
) => {
  const { operation } = parseSearchOptions(options);

  const query = select([min(property), max(property)]).isNotDeleted();

  if (operation != null) {
    query.filter(operation);
  }

  return aggregateSearchDocuments(folderId, query.toString(), projectCode).then(
    (data) => {
      return Promise.resolve(data.items);
    }
  );
};

const searchResourceRelations = (
  projectCode: string,
  folderId: string,
  properties = [],
  propertyName: string
) => {
  // const leftOperand = `concat("RWE$NOTSET$", toString(identifier), "$2$NOTSET")`;

  const concatOperation = operation(
    "concat",
    "RWE$NOTSET$",
    operation("toString", queryOperand("identifier")),
    "$2$NOTSET"
  );
  const filter = operation(
    "in",
    concatOperation,
    queryOperand(`distinct(${propertyName})\\`)
  );

  const query = select(properties)
    // .where({
    //   [leftOperand]: () => `in (distinct(${propertyName})\\)`,
    // })
    .filter(filter)
    .subtree(true)
    .toString();

  return queryFolderSearch(folderId, query, null, projectCode).then(
    ({ items }) => Promise.resolve(items)
  );
};

const repositorySearchApi = {
  searchRepository,
  searchAggregateDistinct,
  searchAggregateMinMax,
  searchResourceRelations,

  queryFolderSearch,
  aggregateSearchDocuments,
};

export default repositorySearchApi;
