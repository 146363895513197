import { createSelector } from 'reselect';

export const getProcessDefinitions = (state) =>
  state.workflow.processDefinitions;
export const getProcesses = (state) => state.workflow.processes;
export const getTasks = getProcesses;
export const getWorkflowLoading = (state) => state.workflow.loading;

export const getProcessTasks = (state) => state.workflow.tasks;

export const getWorkflowStatus = createSelector(
  (state) => state.workflow.workflowStatus,
  (workflowStatus) => workflowStatus || []
);
