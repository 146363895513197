/* eslint-disable func-names */
import {
  call,
  getContext,
  put,
  select,
  spawn,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import _ from 'lodash';
import * as actions from '../../Actions/types';
import {
  getReportingColumnProperties,
  getReportingQuery,
  isReportingAggregated,
} from '../../Selectors/reporting';
import callApi from '../Effects/callApi';
import projectApi from '../../Api/project';
import repositoryApi from '../../Api/repository';
import {
  setReportingDocuments,
  setReportingLoading,
} from '../../Actions/reporting';
import { fetchProjectQueries, setProjectQueries } from '../../Actions/project';
import { exportDocumentsExcel } from '../repository/exportExcelSaga';
import repositoryQueryApi from '../../Api/repositoryQuery';
import { resourceCorrectionOfNscale } from 'cosmos-config/utils';

function* runReportingQuery(folderId) {
  const query = yield select(getReportingQuery);
  const aggregatedQuery = yield select(isReportingAggregated);

  const apiFunction = aggregatedQuery
    ? repositoryApi.aggregateSearchDocuments
    : repositoryApi.queryFolderSearch;

  try {
    yield put(setReportingLoading(true, 'Loading reporting data'));
    const { items } = yield callApi(apiFunction, folderId, query);

    const projectService = yield getContext('projectService');
    const propertiesMap = yield call(projectService.getProperties);

    const documents = resourceCorrectionOfNscale(items, propertiesMap);

    return documents;
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setReportingLoading(false));
  }
}

export default function* reportingSaga(projectCode, folderId) {
  yield takeLatest(
    actions.reporting.REPORTING_RUN_QUERY,
    function* ({ callback }) {
      const documents = yield call(runReportingQuery, folderId);

      console.log(documents);

      yield put(setReportingDocuments(documents));
      yield spawn(callback, documents);
    }
  );

  yield takeLatest(
    actions.reporting.REPORTING_EXPORT_TO_XLS,
    function* ({ callback }) {
      const documents = yield call(runReportingQuery, folderId);

      const columns = yield select(getReportingColumnProperties);
      const columnsToExport = columns.map((c) => c.name);

      yield call(exportDocumentsExcel, documents, columnsToExport);

      yield spawn(callback, documents);
    }
  );

  yield takeLatest(actions.project.PROJECT_QUERIES_FETCH, function* () {
    let queriesMap = {};

    try {
      const queries = yield callApi(projectApi.getQueries, projectCode);
      yield put(setProjectQueries(queries));

      queriesMap = _(queries).mapKeys('id').value();
    } catch (err) {
      console.error(err);
      return;
    }

    yield takeLeading(
      actions.project.PROJECT_QUERY_CREATE,
      function* ({ repositoryQuery, callback }) {
        const query = yield select(getReportingQuery);

        try {
          yield callApi(projectApi.createRepositoryQuery, projectCode, {
            ...repositoryQuery,
            query,
          });
          yield put(fetchProjectQueries());
          yield spawn(callback);
        } catch (err) {
          console.error(err);
        }
      }
    );

    yield takeLeading(
      actions.project.PROJECT_QUERY_UPDATE,
      function* ({ queryId, projectQuery, callback }) {
        try {
          yield callApi(
            repositoryQueryApi.updateRepositoryQuery,
            queryId,
            projectQuery
          );
          yield spawn(callback);
          yield put(fetchProjectQueries());
        } catch (err) {
          console.error(err);
        }
      }
    );

    yield takeLeading(
      actions.project.PROJECT_QUERY_DELETE,
      function* ({ queryId, callback }) {
        try {
          yield callApi(repositoryQueryApi.deleteRepositoryQuery, queryId);
          yield spawn(callback);
          yield put(fetchProjectQueries());
        } catch (err) {
          console.error(err);
        }
      }
    );

    yield takeLeading(
      actions.project.PROJECT_QUERY_OVERWRITE,
      function* ({ queryId, callback }) {
        const projectQuery = queriesMap[queryId];
        const query = yield select(getReportingQuery);

        try {
          yield callApi(repositoryQueryApi.updateRepositoryQuery, queryId, {
            ...projectQuery,
            query,
          });
          yield spawn(callback);
          yield put(fetchProjectQueries());
        } catch (err) {
          console.error(err);
        }
      }
    );
  });

  yield put(fetchProjectQueries());
}
