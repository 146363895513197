import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { getSearchOperation } from 'cosmos-core';

import * as actions from '../../Actions/types';
import {
  setDocumentMatch,
  setDocumentMatches,
} from '../../Actions/versionImport';
import { parseGatewayResources } from '../../Utils/documentUtils';
import { toQueryProperties } from '../../Utils/projectUtils';
import repositoryApi from '../../Api/repository';
import { asyncProgressStatusType } from '../../Constants/asyncProgressStatusType';
import callApi from '../Effects/callApi';
import callBatch from '../Effects/callBatch';

function* versionImportSaga(projectCode, resourceId) {
  const projectService = yield getContext('projectService');
  const properties = yield call(projectService.getProperties);

  yield takeLatest(
    actions.versionImport.FIND_DOCUMENT_MATCHES,
    function* (action) {
      const {
        payload: { files },
      } = action;

      const fileMatches = files.reduce((acc, file) => {
        acc[file.fileIdentifier] = {
          status: asyncProgressStatusType.PENDING,
          matches: [],
        };
        return acc;
      }, {});

      yield put(setDocumentMatches(fileMatches));

      const matchingSagas = yield call(
        createMatchingSagas,
        files,
        projectCode,
        resourceId,
        properties
      );

      yield callBatch(5, matchingSagas);
    }
  );
}

// eslint-disable-next-line require-yield
function* createMatchingSagas(files, projectCode, resourceId, properties) {
  return files.map(function* (file) {
    yield call(matchingSaga, file, projectCode, resourceId, properties);
  });
}

function* matchingSaga(file, projectCode, resourceId, properties) {
  const requestedProps = toQueryProperties(properties);

  yield put(
    setDocumentMatch(file.fileIdentifier, {
      status: asyncProgressStatusType.LOADING,
      matches: [],
    })
  );

  const searchOperation = getSearchOperation({ resourcetype: 2 }, properties, {
    substructure: true,
    searchQuery: file.name.replace(/\.[^.]+$/, ''),
    searchProperties: ['filename'],
    fulltextSearch: false,
    signatureStatus: 'ALL',
  });

  try {
    const { items } = yield callApi(
      repositoryApi.searchRepository,
      projectCode,
      resourceId,
      requestedProps,
      { operation: searchOperation, searchQuery: null, subtree: true }
    );

    const documents = parseGatewayResources(items, properties);

    yield put(
      setDocumentMatch(file.fileIdentifier, {
        status: 'FINISHED',
        matches: documents,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      setDocumentMatch(file.fileIdentifier, {
        status: 'ERROR',
        error,
        matches: [],
      })
    );
  }
}

export default versionImportSaga;
