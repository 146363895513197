(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@fortawesome/free-solid-svg-icons"), require("@fortawesome/react-fontawesome"), require("cosmos-components"), require("react"), require("lodash"), require("react-dom"), require("styled-components"), require("xlsx"), require("@fortawesome/free-regular-svg-icons"), require("exceljs"));
	else if(typeof define === 'function' && define.amd)
		define(["@fortawesome/free-solid-svg-icons", "@fortawesome/react-fontawesome", "cosmos-components", "react", "lodash", "react-dom", "styled-components", "xlsx", "@fortawesome/free-regular-svg-icons", "exceljs"], factory);
	else if(typeof exports === 'object')
		exports["cosmosCore"] = factory(require("@fortawesome/free-solid-svg-icons"), require("@fortawesome/react-fontawesome"), require("cosmos-components"), require("react"), require("lodash"), require("react-dom"), require("styled-components"), require("xlsx"), require("@fortawesome/free-regular-svg-icons"), require("exceljs"));
	else
		root["cosmosCore"] = factory(root["@fortawesome/free-solid-svg-icons"], root["@fortawesome/react-fontawesome"], root["cosmos-components"], root["React"], root["_"], root["ReactDOM"], root[undefined], root["xlsx"], root["@fortawesome/free-regular-svg-icons"], root["exceljs"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9943__, __WEBPACK_EXTERNAL_MODULE__5692__, __WEBPACK_EXTERNAL_MODULE__8118__, __WEBPACK_EXTERNAL_MODULE__9787__, __WEBPACK_EXTERNAL_MODULE__6025__, __WEBPACK_EXTERNAL_MODULE__5156__, __WEBPACK_EXTERNAL_MODULE__5466__, __WEBPACK_EXTERNAL_MODULE__9748__, __WEBPACK_EXTERNAL_MODULE__4849__, __WEBPACK_EXTERNAL_MODULE__4674__) => {
return 