import React, { useMemo, useState } from "react";
import { useProject } from "../../../../hooks";
import PortalsTable from "./PortalsTable";
import { Button, CloseButton, Modal } from "react-bootstrap";
import ProjectPortalForm from "./ProjectPortalForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { uuidv4 } from "cosmos-config/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import projectPortalsApi from "../../../../apis/project/projectPortalsApi";
import { ProjectPortal } from "../../../../contants/projectPortal";
import { LoadingOverlay } from "cosmos-components";

type PortalMutationPayload = {
  projectPortal?: Partial<ProjectPortal>;
  delete?: boolean;
  projectPortalId?: string;
};

const PortalsEditor = () => {
  const queryClient = useQueryClient();
  const { portals, project } = useProject();

  const portalsMap = useMemo(() => _.keyBy(portals, "id"), [portals]);

  const [selectedPortalId, setSelectedPortalId] = useState<string | null>(null);

  const queryKey = useMemo(() => ["project-portals", project?.code], [project]);

  const portalMutation = useMutation({
    mutationFn: (payload: PortalMutationPayload) => {
      const { projectPortal } = payload;

      if (project?.code == null) {
        throw new Error("Project code cannot be null!");
      }

      if (payload.delete) {
        if (payload.projectPortalId == null) {
          throw new Error(
            "Portal cannot be delete because portal id is missing!"
          );
        }

        return projectPortalsApi.deleteProjectPortal(
          project.code,
          payload.projectPortalId
        );
      }

      if (projectPortal == null) {
        throw new Error("Project portal object cannot be null!");
      }

      if (projectPortal.id == null) {
        return projectPortalsApi.createProjectPortal(
          project.code,
          projectPortal
        );
      }

      return projectPortalsApi.updateProjectPortal(project.code, projectPortal);
    },
    mutationKey: queryKey,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      setSelectedPortalId(null);
    },
  });

  const loading = useMemo(() => portalMutation.isPending, [portalMutation]);

  return (
    <div>
      <LoadingOverlay loading={loading}>
        <PortalsTable
          portals={portals}
          projectCode={project?.code}
          onEdit={setSelectedPortalId}
          onDelete={(portalId) => {
            portalMutation.mutate({
              delete: true,
              projectPortalId: portalId,
            });
          }}
        />
      </LoadingOverlay>

      <Button
        variant="link"
        onClick={() => {
          setSelectedPortalId(uuidv4());
        }}
        className="mt-2"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        Add Project Portal
      </Button>

      <Modal
        size="lg"
        show={selectedPortalId != null}
        onHide={() => setSelectedPortalId(null)}
      >
        <Modal.Header>
          <Modal.Title>Project Portal</Modal.Title>
          <CloseButton
            className="close-icon"
            onClick={() => setSelectedPortalId(null)}
          />
        </Modal.Header>
        <Modal.Body>
          <LoadingOverlay loading={loading}>
            <ProjectPortalForm
              portal={
                selectedPortalId != null ? portalsMap[selectedPortalId] : {}
              }
              onSubmit={(portal) => {
                portalMutation.mutate({ projectPortal: portal });
              }}
            />
          </LoadingOverlay>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PortalsEditor;
