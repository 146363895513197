import {
  ESignatureType,
  group,
  projectFolderTabs,
  projectMainScope,
  property,
  systemProperty,
  valuesetSource,
} from "cosmos-config/generator";

const module = [
  group("Basic Settings", "basic").children([
    property("Code", "code").readonly(),
    property("Scope")
      .selectableAttribute(
        "scope",
        "project_scope",
        valuesetSource.STATIC_VALUESET
      )
      .valueset([
        {
          label: "Document",
          value: projectMainScope.DOCUMENT,
        },
        {
          label: "Folder",
          value: projectMainScope.FOLDER,
        },
        {
          label: "Workflow",
          value: projectMainScope.WORKFLOW,
        },
      ]),
    property("Folder Tabs")
      .selectableAttribute(
        "enabledFolderTabs",
        "project_folder_tabs",
        valuesetSource.STATIC_VALUESET
      )
      .valueset([
        {
          label: "Metadata",
          value: projectFolderTabs.METADATA,
        },
        {
          label: "Access Management",
          value: projectFolderTabs.ACCESS_MANAGEMENT,
        },
        {
          label: "Spocs",
          value: projectFolderTabs.SPOCS,
        },
        {
          label: "Dashboard",
          value: projectFolderTabs.DASHBOARD,
        },
        {
          label: "Reminders",
          value: projectFolderTabs.REMINDERS,
        },
        {
          label: "Workflow Status",
          value: projectFolderTabs.WORKFLOW_STATUS,
        },
        {
          label: "Chat",
          value: projectFolderTabs.CHAT,
        },
        {
          label: "Documents",
          value: projectFolderTabs.DOCUMENTS,
        },
        {
          label: "Explorer",
          value: projectFolderTabs.EXPLORER,
        },
      ])
      .multiAttribute("enabledFolderTabs"),
    property("Legacy Layout (PASSO)")
      .booleanAttribute("legacyLayout")
      .useYesNo(),

    systemProperty(
      "Business Processes",
      "businessProcesses",
      true
    ).multiAttribute("businessProcesses"),
  ]),
  group("Features", "features").children([
    property("Enable Explorer").booleanAttribute("enableExplorer").useYesNo(),
    property("ESignature")
      .selectableAttribute(
        "eSignature",
        "project_esignature",
        valuesetSource.STATIC_VALUESET
      )
      .valueset([
        {
          label: "None",
          value: ESignatureType.NONE,
        },
        {
          label: "Basic",
          value: ESignatureType.BASIC,
        },
        {
          label: "Restricted",
          value: ESignatureType.RESTRICTED,
        },
      ]),
    property("MS Office online").booleanAttribute("officeOnline").useYesNo(),
  ]),
];

export default module;
