import { fork, spawn } from 'redux-saga/effects';

const complete = (action, ...args) =>
  // eslint-disable-next-line func-names
  fork(function* () {
    if (action?.onComplete != null) {
      yield spawn(action.onComplete, ...args);
    }
  });

export default complete;
