import React, { useMemo } from "react";
import FilesList from "./FilesList";
import useUploadAdvisor from "../../hooks/useUploadAdvisor";
import classNames from "classnames";
import { NavLink } from "react-bootstrap";
import _ from "lodash";
import { UploadAdvisorDuplicateIssue } from "../../contexts/UploadAdvisorContext";
import { useTheme } from "styled-components";

interface ListRowProps {
  fileIdentifier: string;
}

const ListRow = ({ fileIdentifier }: ListRowProps) => {
  const { issues } = useUploadAdvisor();
  const theme = useTheme();

  const fileIssues = useMemo(() => {
    return issues.filter((issue) => issue.fileIdentifier === fileIdentifier);
  }, [fileIdentifier, issues]);

  const duplicatedIssues = fileIssues
    .filter((issue) => issue.type === "duplicate")
    .map((issue) => issue as UploadAdvisorDuplicateIssue);

  const errorLevels = _(fileIssues).map("level").uniq().value();

  return (
    <FilesList.Row
      className={classNames({
        "text-danger": errorLevels.includes("error"),
        "text-warning": errorLevels.includes("warn"),
      })}
    >
      {duplicatedIssues
        .filter((issue) => issue.identifier != null)
        .map((duplicateIssue) => (
          <div key={duplicateIssue.identifier}>
            <span className="mr-2" style={{ color: theme.body }}>
              Uploaded as
            </span>
            <NavLink
              target={"_blank"}
              // to={`${DOCUMENT_ROUTE}/${error.identifier}`}
            >
              {duplicateIssue.resourceName}
            </NavLink>
          </div>
        ))}
    </FilesList.Row>
  );
};

export interface UploadAdvisorFilesListProps {
  className?: string;
}

const UploadAdvisorFilesList = ({ className }: UploadAdvisorFilesListProps) => {
  const { files, removeFile } = useUploadAdvisor();

  return (
    <FilesList
      className={className}
      files={files}
      renderRow={(fileIdentifier) => (
        <ListRow fileIdentifier={fileIdentifier} />
      )}
      editable
      onRemove={removeFile}
    />
  );
};

export default UploadAdvisorFilesList;
