import React, { useState, useEffect, useMemo } from "react";
import {
  InputValueHandler,
  PancoInputElementProps,
} from "../../types/PancoInputElemenProps";
import styled, { css } from "styled-components";
import { shadeColor } from "../../utils";

const areaBaseStyle = css`
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.base};
  border-color: ${(props) => shadeColor(0.2, props.theme.background)};
`;

const PancoAreaContainer = styled.div`
  textarea {
    ${areaBaseStyle}

    &:focus {
      ${areaBaseStyle}
      outline: 0;
    }
  }
`;

export interface PancoAreaProps
  extends PancoInputElementProps,
    InputValueHandler<string> {
  limit?: number;
}

const PancoArea = ({
  name,
  value,
  onChange,
  disabled,
  limit,
  className,
}: PancoAreaProps) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const v = e.target.value;
    setInputValue(v);

    if (onChange != null) {
      onChange({ name, value: v });
    }
  };

  const handleBlur = () => {
    // onChange({ name, value: inputValue });
  };

  const charactersLength = useMemo(
    () => (inputValue != null ? inputValue.length : 0),
    [inputValue]
  );

  return (
    <PancoAreaContainer className={className}>
      <textarea
        className="form-control"
        onChange={handleChange}
        onBlur={handleBlur}
        value={inputValue}
        disabled={disabled}
        rows={4}
      />

      {!disabled && limit != null && limit > 0 && (
        <small
          className={charactersLength > limit ? "text-danger" : "text-muted"}
        >
          {charactersLength}
          <span>/</span>
          {limit}
        </small>
      )}
    </PancoAreaContainer>
  );
};

export default PancoArea;
