import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay } from "cosmos-components";
import { CosmosChart } from "../../types/CosmosChart";
import { CosmosWidget, CosmosWidgetProps } from "../../types/CosmosWidget";
// import { fetchProcesses } from '../../../Actions/workflow';
// import { getTasks } from '../../../Selectors/workflow';
// import TasksTable from '../../Tasks/TasksTable';
// import WorkflowContext from '../../../Contexts/WorkflowContext';

const MyTasks = ({ onSelect }: CosmosWidgetProps): CosmosWidget => {
  const dispatch = useDispatch();

  // const tasks = useSelector(getTasks);
  // const [loading, setLoading] = useState(false);

  // const { defaultProcessName } = useContext(WorkflowContext);

  // useEffect(() => {
  //   setLoading(true);
  //   dispatch(
  //     fetchProcesses(() => {
  //       setLoading(false);
  //     })
  //   );
  // }, [dispatch, defaultProcessName]);

  return (
    <LoadingOverlay loading={false}>
      {/* <TasksTable
      processDefinition={defaultProcessName}
      tasks={tasks}
      onSelect={({ processId }) => onSelect("process", processId)} */}
      {/* /> */}
    </LoadingOverlay>
  );
};

MyTasks.propTypes = {
  onSelect: PropTypes.func,
};

MyTasks.defaultProps = {
  onSelect: () => {},
};

export default MyTasks;
