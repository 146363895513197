import React, { createElement as h, useState, useEffect, useMemo } from "react";
import _, { Dictionary } from "lodash";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  InputValueHandlerGeneric,
  OnChangeParam,
  PancoInputElementProps,
} from "../../types/PancoInputElemenProps";
import { shadeColor } from "../../utils";
import ValuePickerWrapper, {
  ValuesetPickerWrapperProps,
} from "../value-picker-wrapper/ValuePickerWrapper";
import LoadingOverlay from "../loading-overlay/LoadingOverlay";
import PancoSelectBadge from "./PancoSelectBadge";

const forcedColor = "#ffecb4";

const PancoSelectOptionComponent = styled.div`
  padding: 10px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${(props) => shadeColor(0.15, props.theme.background)};
  }

  &.forced-value {
    background-color: ${forcedColor} !important;

    input {
      background-color: ${forcedColor};
    }
  }
`;

declare type PancoSelectValue = number | string;

export interface PancoSelectOptionGeneric<T extends PancoSelectValue> {
  value: T;
  label: string;
  keywords?: string[];
  filter?: string | null;
  obsolete?: boolean;
}

export type PancoSelectOnChangeParam<
  T extends PancoSelectValue,
  R extends PancoSelectOptionGeneric<T>
> = {} & Partial<Omit<R, "value" | "name">> & OnChangeParam<T[]>;

export interface PancoSelectGenericProps<
  T extends PancoSelectValue,
  R extends PancoSelectOptionGeneric<T>
> extends Omit<ValuesetPickerWrapperProps<T>, "children">,
  PancoInputElementProps,
  InputValueHandlerGeneric<T[], PancoSelectOnChangeParam<T, R>> {
  options?: R[];
  onFilter?: (value: string | null) => void;
  optionRow?: (option: R) => React.ReactNode;
  manualFilter?: boolean;
  children?: React.ReactNode;
  placeholder?: string;
  loading?: boolean;
}

export interface PancoSelectManualFilterParams {
  options: PancoSelectOption[];
}

const PancoSelectGeneric = <
  T extends number | string,
  R extends PancoSelectOptionGeneric<T>
>({
  name,
  options,
  value,
  onChange,
  multiple,
  actionOptions,
  className,
  disabled,
  onFilter,
  optionRow,
  manualFilter,
  children,
  placeholder,
  loading,
  clearable,
  ...props
}: PancoSelectGenericProps<T, R>) => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [filterValue, setFilterValue] = useState<T | null>(null);
  const [optionsHeight, setOptionsHeight] = useState<number>(300);

  const selectOptions: R[] = useMemo(() => options || [], [options]);

  const optionsMap: Dictionary<R> = useMemo(
    () => _(selectOptions).keyBy("value").value(),
    [selectOptions]
  );

  useEffect(() => {
    setSelectedItems(value != null && Array.isArray(value) ? value : []);
  }, [value]);

  const filteredOptions = useMemo(() => {
    const searchQuery =
      filterValue != null ? String(filterValue).toLowerCase() : "";

    if (manualFilter || !searchQuery.trim().length) {
      return selectOptions;
    }

    return selectOptions.filter(
      (o) =>
        searchQuery == null ||
        o.label.toString().toLowerCase().includes(searchQuery) ||
        (o.keywords != null &&
          o.keywords.toString().toLowerCase().includes(searchQuery))
    );
  }, [filterValue, selectOptions, manualFilter]);

  const handleSelect = (v: T | null, idx?: number) => {
    const selectedValue =
      v == null && idx != null ? filteredOptions[idx]?.value : v;
    if (selectedValue != null && !selectedItems.includes(selectedValue)) {
      const newSelectedItems = multiple
        ? [...selectedItems, selectedValue]
        : [selectedValue];

      const opt: R = optionsMap[selectedValue] || {};
      // const { filter, label, ...rest } = opt;

      if (onChange != null) {
        onChange({
          ...opt,
          name,
          value: newSelectedItems,
          ref: opt.filter,
          // label: opt.label,
        });
      }
      setSelectedItems(newSelectedItems);
    }
  };

  const clearSelection = (item?: T) => {
    const newSelectedItems =
      item != null ? selectedItems.filter((si) => si !== item) : [];
    if (onChange != null) {
      //@ts-ignore
      onChange({ name, value: newSelectedItems });
    }
    setSelectedItems(newSelectedItems);
  };

  const renderSelectedContent = (items: T[]) => {
    const getLabel = (v: T) => {
      const opt = optionsMap[v];
      return opt != null ? opt.label : v;
    };

    if (!multiple) {
      if (items != null && items.length > 0) {
        return items.map(getLabel).join(", ");
      }

      return "";
    }

    return items.map((v) =>
      h(
        PancoSelectBadge,
        {
          key: `multi-option-${v}`,
          onClear: () => clearSelection(v),
          disabled,
          clearable: true,
        },
        getLabel(v)
      )
    );
  };

  const rowRenderer = (
    index: number,
    onSelect: (value: T | null, idx: number) => void
  ) => {
    return (
      <PancoSelectOptionComponent
        role="option"
        aria-selected={false}
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={() => onSelect(filteredOptions[index].value, index)}
      >
        {optionRow == null
          ? filteredOptions[index].label
          : optionRow(filteredOptions[index])}
      </PancoSelectOptionComponent>
    );
  };

  return (
    <ValuePickerWrapper<T>
      role="listbox"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      disabled={disabled}
      clearable={clearable && !multiple}
      onClear={() => clearSelection()}
      content={() => renderSelectedContent(selectedItems)}
      selection={selectedItems.length > 0}
      className={className}
      onSelect={handleSelect}
      filterable
      placeholder={disabled ? "" : placeholder || "Click and start typing"}
      actionOptions={actionOptions}
      actionIcon={() => (
        <FontAwesomeIcon icon={multiple ? faPlus : faChevronDown} />
      )}
      onFilterChange={(v) => {
        if (onFilter != null) {
          onFilter(v);
        }
        setFilterValue(v as T);
      }}
    >
      {({ onSelect }) => (
        <LoadingOverlay loading={loading}>
          <Virtuoso
            totalCount={filteredOptions.length}
            overscan={20}
            itemContent={(index) => rowRenderer(index, onSelect)}
            style={{ height: `${optionsHeight}px` }}
            totalListHeightChanged={(height) => {
              const finalHeight = height < 300 && height > 0 ? height : 300;
              setOptionsHeight(finalHeight);
            }}
          />
          {children}
        </LoadingOverlay>
      )}
    </ValuePickerWrapper>
  );
};

export interface PancoSelectOption extends PancoSelectOptionGeneric<string> {}

export interface PancoSelectProps
  extends PancoSelectGenericProps<string, PancoSelectOption> {}

const PancoSelect = PancoSelectGeneric<string, PancoSelectOption>;

export { PancoSelectGeneric };

export default PancoSelect;
