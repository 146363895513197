import { propertyType } from "cosmos-config/generator";
import { Property } from "cosmos-config/lib/property/property";
import _ from "lodash";
import { DateTime } from "luxon";
import { QueryResult } from "cosmos-config/lib/nql/query";

const getFormatFunction = (
  functionName: string
): ((v: string) => string | null) => {
  switch (functionName) {
    case "month":
      return (v: string) => {
        const month = parseInt(v, 10);

        if (isNaN(month)) {
          return "";
        }

        return DateTime.fromObject({ month }).monthLong;
      };
    default:
      return (v: any) => v;
  }
};

export const queryResultsToProperties = (
  results: QueryResult[],
  properties: Property[]
) => {
  const propertiesMapObject = _.keyBy(properties, "name");

  return results
    .map((r) => {
      const propertyName = r.type === "FIELD" ? r.name : r.parameter;

      if (propertyName == null) {
        return null;
      }

      const property = propertiesMapObject[propertyName];

      if (r.alias != null) {
        if (r.type !== "FIELD") {
          return {
            ...property,
            name: r.alias,
            accessor: r.alias,
            type: propertyType.TEXT,
            format: getFormatFunction(r.name),
          };
        }

        return {
          ...property,
          name: r.alias,
          accessor: r.alias,
        };
      }

      return property;
    })
    .filter((x) => x != null)
    .filter((x, idx, arr) => arr.indexOf(x) === idx);
};
