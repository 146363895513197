import UnauthorizedError from "./UnauthorizedError";

const errorResponseInterceptor = (error: any) => {
  const { response } = error;

  if (response != null) {
    const { headers } = response;
    const message = headers["al-error-message"];

    if (response.status === 401) {
      return Promise.reject(new UnauthorizedError(message));
    }

    if (message != null) {
      return Promise.reject(new Error(message));
    }
  }

  return Promise.reject(response?.data);
};

export default errorResponseInterceptor;
