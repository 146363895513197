import React from 'react';
import ReactDOM from 'react-dom';

import * as luxon from 'luxon';
import * as cosmos_components from 'cosmos-components';

import * as react_fontawesome from '@fortawesome/react-fontawesome';
import * as free_solid_svg_icons from '@fortawesome/free-solid-svg-icons';
import * as free_regular_svg_icons from '@fortawesome/free-regular-svg-icons';

global.react = React;
global.react_dom = ReactDOM;
global.luxon = luxon;
global.cosmos_components = cosmos_components;
global.fortawesome = {
  react_fontawesome,
  free_solid_svg_icons,
  free_regular_svg_icons,
};
global.DateTime = luxon.DateTime;
