import React, { DragEvent, MouseEvent, useContext } from "react";
import { Cell, Row } from "../tableFragments";
import Resource from "../../types/resource";
import systemColumns from "./systemColumns";
import { Cell as CellType, Row as RowType } from "react-table";
import RepositoryTableContext from "./RepositoryTableContext";
import classNames from "classnames";
import _ from "lodash";
import { PancoSubDropzone, PancoTooltip } from "cosmos-components";

export interface RepositoryTableReactiveProps {
  editMode?: boolean;
  watchedColumns?: string[];
}

export interface RepositoryTableRowProps extends RepositoryTableReactiveProps {
  row: RowType<Resource>;
  textColor?: string;
  renderPropertyValue?: (propertyName: string, value: any) => any;
}

const RepositoryTableRow = React.memo(
  // @ts-ignore
  ({ row, textColor, renderPropertyValue }: RepositoryTableRowProps) => {
    const { onOpenResource } = useContext(RepositoryTableContext);

    const handleOpenResource = (resource: Resource) => {
      const { resourcetype, identifier, foldertype } = resource;

      if (resourcetype === 1 && foldertype != null) {
        onOpenResource(resourcetype, identifier, {
          folderType: foldertype,
        });
      } else {
        onOpenResource(resourcetype, identifier);
      }
    };

    const renderCellContent = (cell: CellType<Resource>): React.ReactNode => {
      const propertyName = cell.column.id;

      if (systemColumns.includes(propertyName) || renderPropertyValue == null) {
        return cell.render("Cell");
      }

      return renderPropertyValue(propertyName, cell.value);
    };

    const renderCell = (cell: CellType<Resource>): React.ReactNode => {
      const cellValue = renderCellContent(cell);

      return (
        <Cell
          systemColumn={systemColumns.includes(cell.column.id)}
          condensed
          key={`${resource.id}-${cell.column.id}`}
          width={cell.column.width}
        >
          <PancoTooltip text={cellValue} placement="bottom-start">
            {cellValue}
          </PancoTooltip>
        </Cell>
      );
    };

    if (row == null) {
      return null;
    }

    const resource = row.original;

    const renderRow = (): React.ReactNode => (
      <Row
        // subrow={row.depth !== 0}
        onDoubleClick={() => {
          if (!resource.virtual) {
            handleOpenResource(resource);
          }
        }}
        textColor={textColor}
        key={resource.id}
        className={classNames({
          "opacity-50": resource.obsolete,
          "text-danger": !resource.valid,
        })}
        onContextMenu={() => row.toggleRowSelected(true)}
        onClick={(e: MouseEvent) => {
          if (!resource.virtual) {
            row.triggerSelection(e.shiftKey);
          } else if (row.canExpand) {
            row.toggleRowExpanded();
          }
        }}
        onDragEnter={(e: DragEvent) => {
          if (resource.virtual && e.dataTransfer.items.length > 0) {
            row.toggleRowExpanded(true);
          }
        }}
        selected={row.isSelected}
        maxRows={1}
      >
        {row.cells.map(renderCell)}
      </Row>
    );

    if (resource.virtual) {
      return (
        <PancoSubDropzone metadata={resource}>{renderRow()}</PancoSubDropzone>
      );
    }

    return renderRow();
  },
  (prevProps, nextProps) => {
    const getTotalWidth = (props: RepositoryTableRowProps) => {
      return _.sumBy(_.get(props, "row.cells", []), "width");
    };

    const compareProps = (path: string) =>
      _.isEqual(_.get(prevProps, path), _.get(nextProps, path));

    const watchedColumnsEquals = !nextProps.watchedColumns?.some(
      (wc) => !compareProps(`row.original.${wc}`)
    );

    return (
      compareProps("row.original.id") &&
      compareProps("row.isSelected") &&
      compareProps("row.isExpanded") &&
      compareProps("row.cells.length") &&
      watchedColumnsEquals &&
      !nextProps.editMode &&
      getTotalWidth(prevProps) === getTotalWidth(nextProps)
    );
  }
);

export default RepositoryTableRow;
