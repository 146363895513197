import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { DateTime, Interval } from "luxon";
import useDebounce from "../../hooks/useDebounce";
import classNames from "classnames";
import "./date-range-slider.scss";
import { Range, getTrackBackground } from "react-range";
import styled from "styled-components";
import _ from "lodash";

const SliderWrapper = styled.div`
  height: 36px;
  display: flex;
  width: 100%;
  padding: 0 15px 25px 15px;
`;

const SliderTrack = styled.div`
  height: 5px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
  background: ${(props) => props.background};
`;

const SliderThumb = styled.div`
  height: 21px;
  width: 21px;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px #aaa;
  position: relative;
`;

const SliderThumbLabel = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 2px;
  white-space: nowrap;
  font-size: 0.8rem;
`;

const SliderThumbIndicator = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background-color: ${(props) => (props.dragged ? "#548BF4" : "#CCC")};
`;

const DateRangeSlider = ({
  onChange,
  value,
  options,
  placeholder,
  autoClear,
}) => {
  const [boundingMin, setBoundingMin] = useState(
    DateTime.utc().minus({ year: 1 }).toMillis()
  );

  const [boundingMax, setBoundingMax] = useState(
    DateTime.utc().plus({ day: 2 }).toMillis()
  );

  const [values, setValues] = useState([null, null]);
  const [internalValues, setInternalValues] = useState([
    boundingMin,
    boundingMax,
  ]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setInternalValues((current) => {
        if (!_.isEqual(value, current)) {
          return [value[0] || boundingMin, value[1] || boundingMax];
        }

        return current;
      });
    }
  }, [value, boundingMin, boundingMax]);

  useEffect(() => {
    setBoundingMin((currentBoundingMin) => {
      const minOption = _(options).map("value").min();
      if (minOption != null && minOption < currentBoundingMin) {
        return minOption;
      }

      return currentBoundingMin;
    });

    setBoundingMax((currentBoundingMax) => {
      const maxOption = _(options).map("value").max();
      if (maxOption != null && maxOption < currentBoundingMax) {
        return maxOption;
      }

      return currentBoundingMax;
    });
  }, [options]);

  const step = useMemo(() => {
    const fromDate = DateTime.fromMillis(boundingMin);
    const toDate = DateTime.fromMillis(boundingMax);

    const range = Interval.fromDateTimes(fromDate, toDate);
    const { milliseconds } = range.toDuration().toObject();

    return parseInt(milliseconds / 100, 10);
  }, [boundingMin, boundingMax]);

  const debouncedValues = useDebounce(values, 500);
  useEffect(() => {
    const [min, max] = debouncedValues;
    if (min != null && max != null) {
      if (autoClear) {
        const from = min <= boundingMin + step ? null : min;
        const to = max >= boundingMax - step ? null : max;

        onChange({
          value: [
            from == null && to != null ? min : from,
            to == null && from != null ? max : to,
          ],
        });
      } else {
        onChange({
          value: [min, max],
        });
      }
    }
  }, [debouncedValues, autoClear, boundingMin, step, boundingMax]);

  const isActive = useMemo(
    () => value != null && value.from != null && value.to != null,
    [value]
  );

  const renderThumbLabel = (val) => {
    if (val == null) {
      return "null";
    }

    const date = DateTime.fromMillis(val);

    if (boundingMax <= val + step) {
      return "Today";
    }

    if (val - step <= boundingMin) {
      return "All";
    }

    return date.toRelative({ style: "short", locale: "en" });
  };

  return (
    <div className={classNames({ active: isActive })}>
      <label>{placeholder}</label>

      <Range
        values={internalValues}
        step={step}
        min={boundingMin}
        max={boundingMax}
        draggableTrack
        onChange={(values) => {
          setValues(values);
          setInternalValues(values);
        }}
        renderTrack={({ props, children }) => (
          <SliderWrapper
            onMouseD
            own={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={props.style}
          >
            <SliderTrack
              ref={props.ref}
              background={getTrackBackground({
                values: internalValues,
                colors: ["#ccc", "#548BF4", "#ccc"],
                min: boundingMin,
                max: boundingMax,
              })}
            >
              {children}
            </SliderTrack>
          </SliderWrapper>
        )}
        renderThumb={({ props, isDragged, value }) => (
          <SliderThumb {...props} style={props.style}>
            <SliderThumbIndicator dragged={isDragged} />
            <SliderThumbLabel>{renderThumbLabel(value)}</SliderThumbLabel>
          </SliderThumb>
        )}
        // renderMark={({ props, index }) => <div>T {props.key}</div>}
      />
    </div>
  );
};

DateRangeSlider.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(PropTypes.shape()),
  placeholder: PropTypes.string,
  autoClear: PropTypes.bool,
};

DateRangeSlider.defaultProps = {
  onChange: () => {},
  value: [null, null],
  options: [],
  placeholder: null,
  autoClear: false,
};

export default DateRangeSlider;
