import * as actions from './types';

/**
 * Collaboration action generators.
 * @module actions/collaboration
 * @category Actions
 */

/**
 * Action for fetching comments.
 * @function
 * @param {string} resourceId - id of resource
 * @param {object} options - additional options
 * @param {} options.parameter -
 * @param {function} options.callback -
 * @param {boolean} options.subscribe -
 */
export const fetchComments = (resourceId, options = {}) => ({
  type: actions.comment.FETCH_COMMENTS,
  payload: {
    resourceId,
    options: {
      parameter: options?.parameter,
      subscribe: options?.subscribe || false,
    },
  },
});

/**
 * Action to setting comments.
 * @function
 * @param {} comments - comments
 */
export const setComments = (comments) => ({
  type: actions.comment.SET_COMMENTS,
  comments,
});

/**
 * Action for posting comments.
 * @function
 * @param {string} resourceId - id of the resource
 * @param {string} message - toast message
 * @param {object} options - additional options
 * @param {} options.mentions -
 * @param {} options.parameter -
 * @param {} options.nodeId -
 * @param {} options.position -
 * @param {function} callback -
 */
export const postComment = (resourceId, message, options) => ({
  type: actions.comment.POST_COMMENT,
  payload: {
    resourceId,
    message,
    options: {
      mentions: options?.mentions || [],
      parameter: options?.parameter,
      nodeId: options?.nodeId || null,
      position: options?.position,
    },
  },
});

/**
 * Action for exporting comments in Excel.
 * @function
 */
export const exportCommentsExcel = () => ({
  type: actions.comment.EXPORT_COMMENTS_EXCEL,
});

/**
 * Action for closing comments.
 * @function
 */
export const closeComments = () => ({
  type: actions.comment.CLOSE_COMMENTS,
});

/**
 * Action for fetching the latest chat comments.
 * @function
 */
export const fetchLatestChatComments = () => ({
  type: actions.comment.FETCH_LATEST_CHAT_COMMENTS,
});

/**
 * Action for setting the latest chat comments.
 * @function
 * @param {} comments -
 */
export const setLatestChatComments = (comments) => ({
  type: actions.comment.SET_LATEST_CHAT_COMMENTS,
  comments,
});

/**
 * Action for updating comment .
 * @function
 * @param {string} commentId - id of the comment
 * @param {} comment -
 */
export const updateComment = (commentId, comment) => ({
  type: actions.comment.UPDATE_COMMENT,
  payload: {
    commentId,
    comment,
  },
});

/**
 * Action to persist selected teamspace.
 * @function
 * @param {function} callback - action results callback
 */
export const persistSelectedTeamspace = (callback = () => {}) => ({
  type: actions.teamspaces.PERSIST_SELECTED_TEAMSPACE,
  callback,
});

/**
 * Action for setting selected teampspace.
 * @function
 * @param {} teamspace -
 */
export const setSelectedTeamspace = (teamspace) => ({
  type: actions.teamspaces.SET_SELECTED_TEAMSPACE,
  teamspace,
});

/**
 * Action for updating selected teampspace.
 * @function
 * @param {string} propertyName - name of the property
 * @param {string} value - value of the property
 */
export const updateSelectedTeamspace = (propertyName, value) => ({
  type: actions.teamspaces.UPDATE_SELECTED_TEAMSPACE,
  propertyName,
  value,
});

/**
 * Action for opening teamspace folder.
 * @function
 * @param {string} folderId - id of the folder
 */
export const openTeamspaceFolder = (folderId) => ({
  type: actions.teamspaces.OPEN_TEAMSPACE_FOLDER,
  folderId,
});

/**
 * Action for setting teamspace folders.
 * @function
 * @param {} folders - team space folders
 */
export const setTeamspaceFolders = (folders) => ({
  type: actions.teamspaces.SET_TEAMSPACE_FOLDERS,
  folders,
});

/**
 * Action for setting teamspaces loading status.
 * @function
 * @param {boolean} loading - status of loading teamspaces
 * @param {string} message - toast message
 */
export const setTeamspacesLoading = (loading, message) => ({
  type: actions.teamspaces.SET_TEAMSPACES_LOADING,
  loading,
  message,
});

/**
 * Action for reloading teamspaces.
 * @function
 */
export const reloadTeamspaces = () => ({
  type: actions.teamspaces.RELOAD_TEAMSPACES,
});

/**
 * Action for adding teamspace members.
 * @function
 * @param {} principalIds -
 * @param {function} callback - action results callback
 */
export const addTeamspaceMembers = (principalIds, callback = () => {}) => ({
  type: actions.teamspaces.ADD_TEAMSPACE_MEMBERS,
  principalIds,
  callback,
});

/**
 * Action for deleting teamspace.
 * @function
 * @param {function} callback - action results callback
 */
export const deleteTeamspace = (callback = () => {}) => ({
  type: actions.teamspaces.DELETE_TEAMSPACE,
  callback,
});

/**
 * Action for deleting teamspace members.
 * @function
 * @param {} principalIds -
 */
export const deleteTeamspaceMembers = (principalIds) => ({
  type: actions.teamspaces.DELETE_TEAMSPACE_MEMBERS,
  principalIds,
});

/**
 * Action for fetching teamspace members.
 * @function
 */
export const fetchTeamspaceMembers = () => ({
  type: actions.teamspaces.FETCH_TEAMSPACE_MEMBERS,
});

/**
 * Action for setting teamspace members loading status.
 * @function
 * @param {boolean} loading - status flag of loading
 * @param {string} message - toast message
 */
export const setTeamspaceMembersLoading = (loading, message) => ({
  type: actions.teamspaces.SET_TEAMSPACE_MEMBERS_LOADING,
  loading,
  message,
});

/**
 * Action for clearing teamspace data.
 * @function
 */
export const clearTeamspaceData = () => ({
  type: actions.teamspaces.CLEAR_TEAMSPACE_DATA,
});

/**
 * Action for setting collaboration loading status.
 * @function
 * @param {boolean} loading - status flag of collaboration loading
 * @param {string} message - toast message
 */
export const setCollaborationLoading = (loading = true, message) => ({
  type: actions.filterFolder.SET_COLLABORATION_LOADING,
  loading,
  message,
});

/**
 * Action for fetching teamspace folders.
 * @function
 * @param {string} folderId - id of the folder
 * @param {object} options - additional options
 */
export const fetchTeamspaceFolders = (folderId, options = {}) => ({
  type: actions.teamspaces.FETCH_TEAMSPACE_FOLDERS,
  payload: {
    folderId,
    options: {
      callback: options?.callback || (() => {}),
      folderType: options?.folderType,
    },
  },
});

export const reloadComments = () => ({
  type: actions.comment.RELOAD_COMMENTS,
});
//
// export const deleteComment = (commentId) => ({
//   type: actions.comment.DELETE_COMMENT,
//   payload: {
//     commentId,
//   },
// });
//
// export const setTeamspaceMembers = (principalIds) => ({
//   type: actions.teamspaces.SET_TEAMSPACE_MEMBERS,
//   principalIds,
// });
//
// export const setTeamspaces = (teamspaces) => ({
//   type: actions.teamspaces.SET_TEAMSPACES,
//   teamspaces,
// });
//
// export const fetchTeamspaces = () => ({
//   type: actions.teamspaces.FETCH_TEAMSPACES,
// });

// /**
//  * Action to toggle comment state.
//  * @function
//  * @param {} commentId -
//  * @param {} close -
//  * @param {function} callback -
//  */
// export const toggleCommentState = (commentId, close, callback = () => {}) => ({
//   type: actions.filterFolder.TOGGLE_COMMENT_STATE,
//   commentId,
//   close,
//   callback,
// });
