/**
 * @enum {string}
 * @category Project
 * @readonly
 */
const projectMainScope = {
  DOCUMENT: "DOCUMENT",
  FOLDER: "FOLDER",
  WORKFLOW: "WORKFLOW",
};

export default projectMainScope;
