import _ from 'lodash';
import * as actions from '../Actions/types';
import { getFileIdentifier } from 'cosmos-core/dist/main';

const uploadOptionsInitial = {
  multiContent: false,
  autoLink: false,
  eSigningUpload: false,
};

const initialState = {
  isUnzipping: false,
  inProgress: false,
  stats: [],
  progress: 0,
  files: [],
  fileHashMap: {},
  loading: false,
  checkLoading: false,
  loadingStats: {
    fileContentHashGeneratedCount: 0,
    totalFilesCount: 0,
  },
  existingDocuments: [],
  duplicates: {},
  uploadedDocumentsIds: [],
  options: uploadOptionsInitial,
};

const getUploadOptions = (options, files) => {
  const parentRelation = files.some((f) => f.parentFileName != null);

  const getMultiFileValue = (v) => {
    return parentRelation || files.length <= 1 ? false : v;
  };

  const getAutoLinkValue = () => {
    if (files.length > 1 && options.eSigningUpload) {
      return true;
    }

    return getMultiFileValue(options.autoLink);
  };

  return {
    ...(options || {}),
    autoLink: getAutoLinkValue(),
    multiContent: getMultiFileValue(options.multiContent),
  };
};

const upload = (state = initialState, action) => {
  const toggleFileProperty = (propertyName, value) => {
    return state.files.map((file) => {
      if (
        file.name === action.fileIdentifiers.name &&
        file.path === action.fileIdentifiers.path
      ) {
        return {
          ...file,
          [propertyName]: value,
        };
      }

      return file;
    });
  };

  const updateUploadStats = (statId, statData) => {
    const existingStatIndex = state.stats.findIndex(
      (stat) => stat.id === statId
    );

    if (existingStatIndex !== -1) {
      const updatedStats = [...state.stats];
      updatedStats[existingStatIndex].data = statData;
      return updatedStats;
    }

    return [...state.stats, { id: statId, data: statData }];
  };

  const reorderUploadFiles = (files, fileIdentifier, amount) => {
    const fileIndex = files.findIndex(
      (f) => fileIdentifier === getFileIdentifier(f)
    );

    return _.concat(
      _(files)
        .reject(['order', fileIndex])
        .slice(0, fileIndex + amount)
        .value(),
      files[fileIndex],
      _(files)
        .reject(['order', fileIndex])
        .slice(fileIndex + amount)
        .value()
    ).map((f, idx) => ({ ...f, order: idx }));
  };

  const updateUploadOptions = (optionName, optionValue) => {
    const currentOptions = state.uploadOptions;
    const filesCount = state.files.length;

    const setInitialize = (options = {}) => ({
      ...currentOptions,
      ...uploadOptionsInitial,
      [optionName]: optionValue,
      ...options,
    });

    if (optionName === 'eSigningUpload') {
      return setInitialize({
        autoLink: filesCount > 1 && optionValue,
      });
    }

    if (['autoLink', 'multiContent'].includes(optionName)) {
      return filesCount < 2 ? currentOptions : setInitialize();
    }

    return {
      ...currentOptions,
      [optionName]: optionValue,
    };
  };

  if (action.type === actions.upload.UPDATE_UPLOAD_FILE_META) {
    const files = state.files.map((file) => {
      if (action.fileName == null || file.name === action.fileName) {
        return {
          ...file,
          ...action.meta,
        };
      }

      return file;
    });

    return {
      ...state,
      files,
      options: getUploadOptions(state.options, files),
    };
  }

  switch (action.type) {
    case actions.upload.SET_UPLOAD_IN_PROGRESS:
      return {
        ...state,
        inProgress: action.inProgress,
      };
    case actions.upload.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };
    case actions.upload.SET_UPLOAD_FILES:
      return {
        ...state,
        files: _(action.files)
          .map((fileMeta) => {
            const previous = state.files.find(
              (f) => f.name === fileMeta.name && f.path === fileMeta.path
            );

            return {
              ...previous,
              ...fileMeta,
              order: previous?.order || fileMeta.order,
              placeholder: previous?.placeholder || fileMeta.placeholder,
              confidential: previous?.confidential || fileMeta.confidential,
            };
          })
          .sortBy('order')
          .map((f, idx) => ({ ...f, order: idx }))
          .value(),
        options: getUploadOptions(state.options, action.files),
        fileHashMap: Object.keys(state.fileHashMap).reduce(
          (acc, key) => {
            if (action.files.some((f) => getFileIdentifier(f) === key)) {
              acc[key] = state.fileHashMap[key];
            }
            return acc;
          },
          { ...state.fileHashMap }
        ),
        existingDocuments: state.existingDocuments.filter((ed) => {
          return (
            action.files.findIndex(
              (f) => f.name === ed.name && f.path === ed.path
            ) !== -1
          );
        }),
        duplicates: _.chain(state.duplicates)
          .mapValues((duplicates) => {
            return duplicates.filter((d) => {
              return (
                action.files.findIndex(
                  (f) => f.name === d.name && f.path === d.path
                ) !== -1
              );
            });
          })
          .pickBy((duplicatedFiles) => {
            return duplicatedFiles.length > 1;
          })
          .value(),
      };
    case actions.upload.UPDATE_UPLOAD_FILE_ORDER:
      return {
        ...state,
        files: reorderUploadFiles(
          state.files,
          action.payload.fileIdentifier,
          action.payload.amount
        ),
      };
    case actions.upload.TOGGLE_UPLOAD_FILE_CONFIDENTIAL:
      return {
        ...state,
        files: toggleFileProperty('confidential', action.confidential),
      };
    case actions.upload.TOGGLE_UPLOAD_FILE_REFERENCE:
      return {
        ...state,
        files: toggleFileProperty('reference', action.reference),
        options: getUploadOptions(
          { ...state.options, multiContent: false },
          state.files
        ),
      };
    case actions.upload.SET_UPLOAD_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.upload.SET_UPLOAD_CHECK_LOADING:
      return {
        ...state,
        checkLoading: action.loading,
        loadingStats: !action.loadingStats
          ? initialState.loadingStats
          : {
              ...state.loadingStats,
              ...action.loadingStats,
            },
      };
    case actions.upload.ADD_EXISTING_DOCUMENTS:
      return {
        ...state,
        existingDocuments: _(state.existingDocuments)
          .union(action.documents)
          .uniqBy((doc) => `${doc.name}_${doc.path}`)
          .value(),
      };
    case actions.upload.SET_DUPLICATES:
      return {
        ...state,
        duplicates: action.duplicates,
      };
    case actions.upload.ADD_UPLOADED_DOCUMENT_ID:
      return {
        ...state,
        uploadedDocumentsIds: [
          ...state.uploadedDocumentsIds,
          action.documentId,
        ],
      };
    case actions.upload.UPDATE_UPLOAD_OPTIONS:
      return {
        ...state,
        options: updateUploadOptions(action.name, action.value),
      };
    case actions.upload.SET_IS_UNZIPPING:
      return {
        ...state,
        isUnzipping: action.isUnzipping,
      };
    case actions.upload.UPDATE_UPLOAD_STATS:
      return {
        ...state,
        stats: updateUploadStats(action.payload.id, action.payload.data),
      };
    case actions.upload.UPDATE_FILE_HASH:
      return {
        ...state,
        fileHashMap: {
          ...state.fileHashMap,
          [action.payload.fileIdentifier]: action.payload.hash,
        },
      };
    case actions.repositoryImport.CANCEL_IMPORT:
    case actions.repositoryImport.CLEAN_IMPORT:
    case actions.filterFolder.CLEAR_STORES:
      // for some reason, the fileHashMap is not empty in the initialState
      return { ...initialState, fileHashMap: {} };
    case actions.upload.CLEAR_UPLOAD_DATA:
      return {
        ...initialState,
        inProgress: action.payload?.soft
          ? state.inProgress
          : initialState.inProgress,
        options: action.payload?.soft ? state.options : initialState.options,
      };
    default:
      return state;
  }
};

export default upload;
