import _ from 'lodash';
import { createSelector } from 'reselect';

export const getComments = (state) => state.collaboration.comments;

export const getCommentsCount = createSelector(getComments, (comments) => {
  const flatten = (item) => {
    const innerComments = _(item.comments).flatMapDeep(flatten).value();
    return [item, ...innerComments];
  };

  return _(comments).flatMapDeep(flatten).value().length;
});
