import { useState, useMemo } from "react";
import Fuse from "fuse.js";

import { PancoSelectManualFilterParams, PancoSelectOption } from "../PancoSelect";

interface PancoSelectManualFilterParamsFuzzy extends PancoSelectManualFilterParams {
    threshold?: number;
}

/**
 * Helper hook for PancoSelect manual filtering with fuzzy search
 * @param param0 
 * @returns 
 */
export const useManualFilterFuzzy = ({ options, threshold = 0.6 }: PancoSelectManualFilterParamsFuzzy) => {
    const [filterValue, setFilterValue] = useState('')

    const handleFilter = (value: string | null) => {
        if (!value) {
            setFilterValue('')
            return;
        }
        setFilterValue(value.trim());
    }

    const filteredOptions: PancoSelectOption[] = useMemo(() => {
        if (!filterValue.length) return options;

        const fuse = new Fuse(options, {
            keys: ['label', 'keywords'],
            threshold: threshold // explicitly define threshhold although it is the default value
        });

        return fuse.search(filterValue).map(({ item }) => item);
    }, [options, filterValue, threshold]);

    return {
        handleFilter,
        filteredOptions
    }
}